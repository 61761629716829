import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import I18n from '../i18n'

import Spacer from '../components/Spacer'

import FactList from '../sections/FactList'
import FooterRegister from '../sections/FooterRegister'
import GraphExample from '../sections/GraphExample'
import MethodologyGrid from '../sections/MethodologyGrid'
import PecoDataSheet from '../sections/PecoDataSheet'
import WeatherLegend from '../sections/WeatherLegend'

function Methodology({ locationsCount }) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.methodology.title')}</title>
      </Helmet>

      <MethodologyGrid />
      <GraphExample />
      <WeatherLegend />
      <FactList />
      <PecoDataSheet />
      <FooterRegister locationsCount={locationsCount} />
      <Spacer light />
    </div>
  )
}

Methodology.propTypes = {
  locationsCount: PropTypes.number.isRequired
}

export default Methodology
