import React, { useState, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'
import useScrollDirection from '../../lib/use-sticky'

import { getFilteredLocations } from '../../lib/use-search'
import I18n, { Trans } from '../../i18n'
import { MagnifyingGlass } from '../Icon'
import { CsrfContext } from '../forms/Form'
import Input from '../forms/Input'
import ListFavs from './ListFavs'
import ListAll from './ListAll'
import CountryList from './CountryList'

export const FAV_TYPE = {
  ADD: 1,
  REMOVE: 2
}

function LocationPicker({
  locations,
  favLocationIds = [],
  onSelect,
  favable = true,
  isInModal = false,
  useUrlParams = false,
  initialQuery = '',
  disabledLocationIds = []
}) {
  const csrfToken = useContext(CsrfContext)
  const urlParams = useRef(
    global.location && new URLSearchParams(global.location.search)
  )
  const [query, setQuery] = useState(initialQuery)
  const [currentFavs, setCurrentFavs] = useState(favLocationIds)
  const [filteredFavs, setFilteredFavs] = useState(() => {
    const [, result] = getFilteredLocations(locations, currentFavs, query)
    return result
  })
  const [filteredLocations, setFilteredLocations] = useState(() => {
    const [result] = getFilteredLocations(locations, [], query)
    return result
  })
  const [isSticky, scrollDirection, stickyRef] = useScrollDirection()

  function getAndSetFilteredLocations({
    newCurrentFavs = currentFavs,
    newQuery = query
  }) {
    const normalizedQuery = normalizeQuery(newQuery)
    const [newFilteredLocations, newFilteredFavs] = getFilteredLocations(
      locations,
      newCurrentFavs,
      normalizedQuery
    )
    setQuery(newQuery)
    setCurrentFavs(newCurrentFavs)
    setFilteredLocations(newFilteredLocations)
    setFilteredFavs(newFilteredFavs)

    // in some use cases we don't want to set/use query params, also
    // history.replaceState breaks storybook reloading
    if (useUrlParams) {
      if (normalizedQuery) {
        urlParams.current.set('q', normalizedQuery)
        history.replaceState('', '', '?' + urlParams.current.toString())
      } else {
        urlParams.current.delete('q')
        history.replaceState('', '', global.location.pathname)
      }
    }
  }

  const handleSearchChange = event => {
    getAndSetFilteredLocations({ newQuery: event.target.value })
  }

  function handleFavChange(locationIds, { type }) {
    let method
    if (type === FAV_TYPE.ADD) method = 'POST'
    else if (type === FAV_TYPE.REMOVE) method = 'DELETE'
    else throw new Error('Unknown FAV_TYPE ' + type)

    // Optimistic UI: mark them as favorite before getting server response
    let newCurrentFavs = []
    if (type === FAV_TYPE.ADD) {
      newCurrentFavs = uniq([...currentFavs, ...locationIds])
    } else if (type === FAV_TYPE.REMOVE) {
      newCurrentFavs = currentFavs.filter(id => !locationIds.includes(id))
    }
    getAndSetFilteredLocations({ newCurrentFavs })

    fetch('/locations/favorites', {
      method,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: locationIds })
    })
      .then(res => res.json())
      .then(responseIds => {
        const responseIsSameAsCurrent = isEqual(
          responseIds.sort(),
          newCurrentFavs.sort()
        )
        if (responseIsSameAsCurrent) return

        getAndSetFilteredLocations({ newCurrentFavs: responseIds })
      })
  }

  return (
    <React.Fragment>
      <StickyContainer
        innerRef={stickyRef}
        isInModal={isInModal}
        isSticky={isSticky}
        scrollDirection={scrollDirection}
      >
        <Input
          name="query"
          onChange={handleSearchChange}
          value={query}
          light
          big
          icon={MagnifyingGlass}
          placeholder={I18n.t('locations.search')}
          autoComplete="off"
        />
      </StickyContainer>
      <CountryList groupedLocations={filteredLocations} />
      {filteredFavs.length > 0 && (
        <ListNarrowContainer>
          <ListFavs
            onFavChange={handleFavChange}
            locations={filteredFavs.map(id => locations[id])}
            onSelect={onSelect}
            favable={favable}
            disabledLocationIds={disabledLocationIds}
          />
        </ListNarrowContainer>
      )}
      <div>
        {filteredLocations.length ? (
          <ListAll
            groupedLocations={filteredLocations}
            onFavChange={handleFavChange}
            favLocationIds={filteredFavs}
            disabledLocationIds={disabledLocationIds}
            onSelect={onSelect}
            favable={favable}
          />
        ) : (
          <NoResultsText>
            <Trans id="locations.no_results" values={{ query }} escape />
          </NoResultsText>
        )}
      </div>
    </React.Fragment>
  )
}

LocationPicker.propTypes = {
  locations: PropTypes.object.isRequired,
  favable: PropTypes.bool,
  isInModal: PropTypes.bool,
  onSelect: PropTypes.func,
  useUrlParams: PropTypes.bool,
  initialQuery: PropTypes.string,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabledLocationIds: PropTypes.arrayOf(PropTypes.number)
}

const NoResultsText = styled.div`
  padding: 2rem;
  font-size: 2rem;
  font-style: italic;
  text-align: center;
`

const ListNarrowContainer = styled.div`
  margin-top: 1.5rem;

  ${p => p.theme.breakpoints.phone} {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

const StickyContainer = styled(ListNarrowContainer)(props => {
  const { isInModal, isSticky, scrollDirection } = props
  const modalPadding = '0.3125rem'
  const cancelModalPadding = `-${modalPadding}`

  return {
    position: 'sticky',
    top: isInModal ? cancelModalPadding : '0',

    marginLeft: isInModal ? cancelModalPadding : '-1.875rem',
    marginRight: isInModal ? cancelModalPadding : '-1.875rem',

    paddingTop: isInModal ? modalPadding : '0.5rem',
    paddingLeft: isInModal ? modalPadding : '1.875rem',
    paddingRight: isInModal ? modalPadding : '1.875rem',
    paddingBottom: isInModal ? modalPadding : '0.5rem',

    backgroundColor: isInModal
      ? props.theme.chroma.white.css()
      : props.theme.chroma.nearlyWhite.css(),
    zIndex: 2,

    [props.theme.breakpoints.phone]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: isInModal ? modalPadding : '0.5rem',
      paddingRight: isInModal ? modalPadding : '0.5rem',
      transform:
        isSticky && scrollDirection === 'down'
          ? 'translateY(-100%)'
          : 'translateY(0)',
      transitionDuration: '0.1s',
      transitionProperty: 'transform'
    }
  }
})

export function normalizeQuery(searchString) {
  return searchString.trim().toLowerCase()
}

export default LocationPicker
