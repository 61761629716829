import { shape, string, bool, arrayOf, array } from 'prop-types'

export const navigationStructure = arrayOf(
  shape({
    name: string.isRequired,
    link: string.isRequired,
    dropdownLink: array,
    isActive: bool,
    isButton: bool,
    isDropdown: bool
  }).isRequired
)
