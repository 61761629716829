import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import MinusIcon from '../components/assets/icons/minus-circled.svg'
import PlusIcon from '../components/assets/icons/plus-circled.svg'

function CollapsibleSection({
  title,
  children,
  openByDefault = false,
  headerRowItems,
  ...passThroughProps
}) {
  const [isOpen, setIsOpen] = useState(openByDefault)
  const handleOpen = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <EntryWrapper {...passThroughProps}>
      <VerticalWrapper>
        <HeaderWrapper onClick={handleOpen}>
          <HeaderLeftWrapper>
            {!isOpen ? (
              <Button>
                <PlusIcon />
              </Button>
            ) : (
              <Button>
                <MinusIcon />
              </Button>
            )}
            <Title>{title}</Title>
          </HeaderLeftWrapper>

          <HeaderItemsWrapper>{headerRowItems}</HeaderItemsWrapper>
        </HeaderWrapper>
        {isOpen ? <ContentWrapper>{children}</ContentWrapper> : <div></div>}
      </VerticalWrapper>
    </EntryWrapper>
  )
}

CollapsibleSection.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  openByDefault: PropTypes.bool,
  headerRowItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default CollapsibleSection

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const EntryWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`

const HeaderWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${p => p.theme.breakpoints.phoneUp} {
    font-size: 1.5rem;
    justify-content: flex-start;
  }
`

const HeaderLeftWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
 
`

const HeaderItemsWrapper = styled.div`
  margin-left: 2rem;
  display: flex;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`

const VerticalWrapper = styled.div`
  width: 100%;
  @media (max-width: 60rem) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Title = styled.h2`
  margin: 1rem;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #183e62;
  white-space: nowrap;
`
