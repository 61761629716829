import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import I18n, { Trans } from '../i18n'

function RankingItem({
  rank,
  percentage,
  locationName,
  cityName,
  pedestriansCount,
  animate
}) {
  return (
    <Row>
      <Rank>{rank}</Rank>
      <Name>
        <small>{cityName}</small>
        <div>{locationName}</div>
      </Name>
      <Amount>
        <div>
          <strong>{I18n.toNumber(pedestriansCount, { precision: 0 })}</strong>
          <Trans id="ranking.pedestrians" />
        </div>
        <motion.div
          className="progress"
          css={{ width: percentage * 100 + '%', transformOrigin: 'left' }}
          initial={animate && { scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ ease: 'easeOut' }}
        />
      </Amount>
    </Row>
  )
}

RankingItem.propTypes = {
  rank: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  locationName: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  pedestriansCount: PropTypes.number.isRequired,
  animate: PropTypes.bool
}

const Rank = styled.div`
  flex-basis: 10%;
  font-size: 2.25rem;
  font-weight: 900;
  color: ${props => props.theme.chroma.base.alpha(0.15).css()};
  min-width: 3.5rem;

  ${p => p.theme.breakpoints.tablet} {
    font-size: 1.5rem;
  }
`

const Name = styled.div`
  font-size: 1rem;
  font-weight: 900;
  flex-basis: 25%;
  padding-right: 1rem;

  ${p => p.theme.breakpoints.tablet} {
    flex-basis: 65%;
    font-size: 0.875rem;
  }

  small {
    font-weight: normal;
    font-size: 0.75rem;
  }
`

const Amount = styled.div`
  flex-basis: 65%;
  color: ${props => props.theme.chroma.coal.brighten(2.5).css()};
  font-weight: 500;

  ${p => p.theme.breakpoints.tablet} {
    flex-basis: 25%;
    font-size: 0.75rem;
  }

  strong {
    font-weight: 900;
    font-size: 1.125rem;
    color: ${props => props.theme.chroma.accent.css()};
    margin-right: 1ch;

    ${p => p.theme.breakpoints.tablet} {
      display: block;
    }
  }

  .progress {
    margin-top: 0.5rem;
    background-color: ${props => props.theme.chroma.accent.css()};
    height: 0.375rem;
    border-radius: 0.25rem;
    min-width: 0.4rem;

    ${p => p.theme.breakpoints.tablet} {
      display: none;
    }
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &:hover {
    background-color: ${p => p.theme.chroma.nearlyWhite.css()};
  }

  &:hover ${Rank} {
    color: ${props => props.theme.chroma.accent.css()};
  }
`

export default RankingItem
