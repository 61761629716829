import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Hide = styled.div`
  ${p => (typeof p.when === 'function' ? p.when(p.theme.breakpoints) : p.when)} {
    display: none;
  }
`

Hide.propTypes = {
  when: PropTypes.oneOfType([
    PropTypes.func, // when={bp => bp.phoneUp}
    PropTypes.string // when="@media ()"
  ]).isRequired
}

export default Hide
