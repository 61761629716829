import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import I18n from '../i18n'
import Markdown from '../components/Markdown'
import Section from '../components/Section'
import Spacer from '../components/Spacer'
import Button from '../components/Button'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'

function UserAnnouncementConsentAccept({ announcementId, content = '', slug }) {
  return (
    <Section>
      <CustomWrapper>
        <Helmet>
          <title>{I18n.t('announcement.title')}</title>
        </Helmet>

        <Markdown content={content} copy />

        <Form
          as="user_announcement_consent"
          action="/announcement"
          method="post"
        >
          <Input name="announcement_id" value={announcementId} type="hidden" />
          <Form.Actions>
            <SubmitButton slug={slug} />
          </Form.Actions>
        </Form>
      </CustomWrapper>
      <Spacer light />
    </Section>
  )
}

function SubmitButton({ slug }) {
  if (slug === 'newsletter_reminder') {
    return (
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start'
        }}
      >
        <Button
          type="submit"
          name="consent_decision[accept]"
          css={{ marginRight: '1rem', marginBottom: '1rem' }}
        >
          {I18n.t('announcement.newsletter.accept_button')}
        </Button>
        <Button type="submit" name="consent_decision[ignore]" outline>
          {I18n.t('announcement.newsletter.ignore_button')}
        </Button>
      </div>
    )
  }

  return <Button type="submit">{I18n.t('announcement.accept_button')}</Button>
}

SubmitButton.propTypes = {
  slug: PropTypes.string.isRequired
}

const CustomWrapper = styled(Section.Wrapper)`
  padding-top: 2rem;
  max-width: 44rem;
  line-height: 1.4;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }

  p {
    margin: 0.7rem 0;
  }
`

UserAnnouncementConsentAccept.propTypes = {
  announcementId: PropTypes.number.isRequired,
  content: PropTypes.string,
  slug: PropTypes.string.isRequired
}

export default UserAnnouncementConsentAccept
