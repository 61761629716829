import { useState, useEffect } from 'react'

export default function useLocalStorage(key, initialValue) {
  if (localStorageKeys[key] === undefined) {
    throw new Error(`Invalid localStorage key: ${key}`)
  }

  if (!['undefined', 'null'].includes(typeof localStorage)) {
    const fromStore = JSON.parse(localStorage.getItem(key))
    if (fromStore != null) initialValue = fromStore
  }

  const [value, setValue] = useState(initialValue)

  const setAndStoreValue = value => {
    localStorage.setItem(key, JSON.stringify(value))
    setValue(value)
  }

  return [value, setAndStoreValue]
}

export const localStorageKeys = {
  selectedLocationIds: 'selectedLocationIds',
  selectedPlanName: 'selectedPlanName',
  selectedFlatrate: 'selectedFlatrate'
}
