import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Button from './Button'
import Report2022 from './assets/images/report_2022.png'
import Close from './assets/icons/close.svg'

function AdvertisementBanner({ cookieName, style }) {
  const [cookies, setCookie] = useCookies([cookieName])
  const [hidden, setHidden] = useState(true)
  function setInvisible() {
    setCookie(cookieName, true)
    setHidden(true)
  }

  useEffect(() => {
    setHidden(cookies[cookieName])
  }, [cookies, cookieName])

  if (hidden) {
    return null
  }

  return (
    <Banner style={style}>
      <CloseButtonWrapper>
        <CustomClose onClick={setInvisible} />
      </CloseButtonWrapper>
      <ContentWrapper>
        <Image
          src={Report2022}
          css={{
            backgroundColor: 'transparent',
            padding: '0 .5rem'
          }}
        />
        <Heading>
          <Trans id="pages.home.frequenzreport.headline" />
        </Heading>
        <Info>
          <Trans id="pages.home.frequenzreport.info" />
        </Info>
      </ContentWrapper>
      <ButtonWrapper>
        <CustomButton href="https://www.frequenzreport.de">
          <Trans id="pages.home.frequenzreport.buttontext" />
        </CustomButton>
      </ButtonWrapper>
    </Banner>
  )
}

AdvertisementBanner.propTypes = {
  cookieName: PropTypes.string.isRequired,
  style: PropTypes.object
}

const Banner = styled.div`
  z-index: 5;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 220px;
  background-color: #e1f1f8;
  background-image: radial-gradient(at top left, #ffffff, #e1f1f8 70%, #4faed9);
  box-shadow: 0px -5px 5px rgba(50, 50, 50, 0.3);
  -webkit-box-shadow: 0px -5px 5px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px -5px 5px rgba(50, 50, 50, 0.3);
  ${p => p.theme.breakpoints.phoneUp} {
    z-index: 5;
    position: absolute;
    top: 300px;
    left: -140px;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: #e1f1f8;
    background-image: radial-gradient(
      at top left,
      #ffffff,
      #e1f1f8 75%,
      #4faed9
    );
    box-shadow: 10px 10px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 5px 5px rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0px 5px 5px rgba(50, 50, 50, 0.3);
  }
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: -15px;
  z-index: 6;
  ${p => p.theme.breakpoints.phoneUp} {
    position: absolute;
    right: 85px;
    top: 25px;
    z-index: 6;
  }
`
const CustomClose = styled(Close)`
  color: ${p => p.theme.chroma.base.css()};
  border-radius: 50%;
  border: 1px solid gray;
  background: ${p => p.theme.chroma.white.css()};
  width: 35px;
  height: 35px;
  box-shadow: 10px 10px rgba(50, 50, 50, 0.3);
  -webkit-box-shadow: 0px 5px 5px rgba(50, 50, 50, 0.3);
  cursor: pointer;
  ${p => p.theme.breakpoints.phoneUp} {
    color: ${p => p.theme.chroma.base.css()};
    border-radius: 50%;
    background: ${p => p.theme.chroma.white.css()};
    width: 45px;
    height: 45px;
    box-shadow: 10px 10px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 5px 5px rgba(50, 50, 50, 0.3);
  }
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  left: 180px;
  ${p => p.theme.breakpoints.phoneUp} {
    position: absolute;
    bottom: 45px;
    left: 195px;
  }
`
const CustomButton = styled(Button.Link)`
  background-color: #0291d8;
  padding: 0.8rem 1.8rem;
  margin-top: 0.3rem;
  text-transform: none;
  font-size: 20px;
  ${p => p.theme.breakpoints.phoneUp} {
    background-color: #0291d8;
    padding: 1.2rem 1.6rem;
    margin-top: 0.3rem;
    text-transform: none;
    font-size: 20px;
    cursor: pointer;
  }
`
const ContentWrapper = styled.div`
  position: absolute;
  margin: 10px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: 'image headline' 'image text';
  grid-column-gap: 1rem;
  ${p => p.theme.breakpoints.phoneUp} {
    display: block;
    position: absolute;
    left: 190px;
    top: 35px;
    white-space: break-spaces;
  }
`
const Heading = styled.h1`
  color: black;
  font-weight: 900;
  font-size: 24px;
  grid-area: headline;
  white-space: break-spaces;
  ${p => p.theme.breakpoints.phoneUp} {
    color: black;
    font-weight: 900;
    font-size: 26px;
    margin: 15px 0px 0px 5px;
  }
`
const Info = styled.p`
  color: black;
  font-size: 16px;
  line-height: 1.6;
  grid-area: text;
  margin: 10px 0px 10px 0px;
  ${p => p.theme.breakpoints.phoneUp} {
    color: black;
    font-size: 17px;
    line-height: 1.6;
    white-space: break-spaces;
    margin: 10px 2px 10px 5px;
  }
`
const Image = styled.img`
  width: auto;
  height: 180px;
  grid-area: image;
  ${p => p.theme.breakpoints.phoneUp} {
    width: auto;
    height: 215px;
    display: block;
  }
`
export default AdvertisementBanner
