import PropTypes from 'prop-types'
import React from 'react'
import I18n from '../../../i18n'
import { calculatePercentage } from '../../../lib/calculate-location-statistics'
import theme from '../../../theme'
import { PercentagePerZoneStatsCardItem } from './LocationStatsCard'
import { NoDataPresentStatsCardItem } from './LocationStatsCardItems/NoDataPresentStatsCardItem'

const getColorProfiles = () => {
  const colorProfiles = []

  theme.chroma.KPICharts.map(colorProfile => {
    colorProfiles.push({
      zoneKeyColor: colorProfile.fill.hex(),
      zoneTextColor: colorProfile.fillText.hex(),
      zoneKeyColorComplement: colorProfile.fillComplement.hex(),
      zoneTextColorComplement: colorProfile.fillComplementText.hex()
    })
  })

  return colorProfiles
}

export const ZoneCard = ({ statObject }) => {
  const colorProfiles = getColorProfiles()
  const neighbouringCardsMinHeight = 235
  return (
    <>
      {statObject.zones.length === 0 && (
        <NoDataPresentStatsCardItem
          message={I18n.t('location.details.data_missing')}
          variant={'cardHeader'}
          minHeight={neighbouringCardsMinHeight}
        ></NoDataPresentStatsCardItem>
      )}

      {statObject.zones &&
        statObject.zones.map((zone, zoneIndex) => {
          const colorProfileForZoneGraph =
            colorProfiles[zoneIndex % statObject.zones.length]
          return (
            <PercentagePerZoneStatsCardItem
              displayDirectionLabels={zoneIndex === 0}
              key={zoneIndex}
              zoneKeyColor={colorProfileForZoneGraph.zoneKeyColor}
              zoneTextColor={colorProfileForZoneGraph.zoneTextColor}
              zoneKeyColorComplement={
                colorProfileForZoneGraph.zoneKeyColorComplement
              }
              zoneTextColorComplement={
                colorProfileForZoneGraph.zoneTextColorComplement
              }
              zoneName={`${I18n.t('location.details.zone')} ${zoneIndex + 1}`}
              percentageOfTotalPedestriansInAllZones={calculatePercentage(
                zone.pedestriansCount,
                statObject.pedestriansCount
              )}
              totalPedestriansInZone={zone.pedestriansCount}
              direction1Reading={{
                directionName: statObject.metadata.rtlLabel,
                percentageOfTotalPedestriansInZone: calculatePercentage(
                  zone.rtlPedestriansCount,
                  zone.pedestriansCount
                ),
                numberOfTotalPedestriansInZone: zone.rtlPedestriansCount
              }}
              direction2Reading={{
                directionName: statObject.metadata.ltrLabel,
                percentageOfTotalPedestriansInZone: calculatePercentage(
                  zone.ltrPedestriansCount,
                  zone.pedestriansCount
                ),
                numberOfTotalPedestriansInZone: zone.ltrPedestriansCount
              }}
            />
          )
        })}
    </>
  )
}

ZoneCard.propTypes = {
  statObject: PropTypes.object
}
