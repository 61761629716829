import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'
import { internationalizedPercentageOrBlank } from '../../../../lib/calculate-location-statistics'
import InfoButton from '../../InfoButton'
import Modal from '../../Modal'
import {
  AbsolutePedestrianCountLabel,
  IncidentItem,
  ItemContainer,
  PedestrianCountDifferenceInPercentagesLabel,
  TimestampLabel,
  TimestampRow
} from './LocationStatsCardItemBase'
import { NoDataPresentStatsCardItem } from './NoDataPresentStatsCardItem'

const DataDetailRow = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}))

const PedestrianCountAndSpecialOccasionInfoContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
}))

const PedestrianCountLabel = styled.p(props => ({
  fontSize: props.theme.fontSizes.l.fontSize,
  lineHeight: props.theme.fontSizes.l.lineHeight,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  fontWeight: 900,
  marginTop: '0.8rem',
  marginBottom: '0.8rem',
  color: props.theme.chroma.base.css()
}))

const PedCountDifferencesContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

const IncidentsModalContainer = styled.div(props => ({
  padding: '2rem'
}))

export function PedestrianCountComparisonStatsCardItem({
  timestamp,
  pedestrianCount,
  pedestrianCountDifferenceInAbsoluteNumbers,
  pedestrianCountDifferenceInPercentages,
  incidents,
  measurementsStartedAt,
  displayNoDataPresentPlaceholder = false,
  displayDataIncompleteNotice = false,
  hideTopBorder = false,
  fullWidth = true,
  hideAbsoluteCountDifference = false,
  hideMeasuredSince = false
}) {
  const [incidentsModalOpen, setIncidentsModalOpen] = useState(false)

  function handleIncidentsButtonClick() {
    setIncidentsModalOpen(true)
  }

  function handleIncidentsModalClose() {
    setIncidentsModalOpen(false)
  }

  if (displayNoDataPresentPlaceholder || displayDataIncompleteNotice) {
    const message = displayDataIncompleteNotice
      ? I18n.t('location.details.data_incomplete')
      : I18n.t('location.details.data_missing')

    return (
      <NoDataPresentStatsCardItem
        message={message}
        askedtimerange={timestamp}
        dataPresentSincetimestamp={
          hideMeasuredSince === false && displayDataIncompleteNotice
            ? measurementsStartedAt
            : null
        }
        hideBorder={hideTopBorder}
        fullWidth={fullWidth}
      ></NoDataPresentStatsCardItem>
    )
  }

  return (
    <ItemContainer hideBorder={hideTopBorder} fullWidth={fullWidth}>
      <TimestampRow>
        <TimestampLabel>{timestamp}</TimestampLabel>

        {incidents && incidents.length !== 0 && (
          <div css={{ paddingLeft: 12, marginTop: 5 }}>
            <InfoButton
              type="info_accent"
              onClick={handleIncidentsButtonClick}
            ></InfoButton>{' '}
            <Modal
              isOpen={incidentsModalOpen}
              showCloseButton
              onRequestClose={handleIncidentsModalClose}
            >
              <IncidentsModalContainer>
                <h2>
                  {I18n.t('location.details.incidents_during_timeperiod')}
                </h2>
                {incidents.map(incident => {
                  return (
                    <IncidentItem
                      key={incident.activeFrom}
                      incident={incident}
                    ></IncidentItem>
                  )
                })}
              </IncidentsModalContainer>
            </Modal>
          </div>
        )}
      </TimestampRow>

      <DataDetailRow>
        <PedestrianCountAndSpecialOccasionInfoContainer>
          <PedestrianCountLabel>
            {I18n.toNumber(pedestrianCount, {
              precision: 0,
              significant: false
            })}
          </PedestrianCountLabel>
        </PedestrianCountAndSpecialOccasionInfoContainer>

        <PedCountDifferencesContainer>
          <div css={{ paddingRight: 5, paddingLeft: 5 }}>
            {!hideAbsoluteCountDifference && (
              <AbsolutePedestrianCountLabel>
                {pedestrianCountDifferenceInAbsoluteNumbers > 0
                  ? `+${I18n.helpers.abbreviateNumber(
                      pedestrianCountDifferenceInAbsoluteNumbers
                    )}`
                  : `${I18n.helpers.abbreviateNumber(
                      pedestrianCountDifferenceInAbsoluteNumbers
                    )}`}
              </AbsolutePedestrianCountLabel>
            )}
          </div>
          <PedestrianCountDifferenceInPercentagesLabel
            percentage={pedestrianCountDifferenceInPercentages}
          >
            {internationalizedPercentageOrBlank(
              pedestrianCountDifferenceInPercentages,
              1,
              8
            )}
          </PedestrianCountDifferenceInPercentagesLabel>
        </PedCountDifferencesContainer>
      </DataDetailRow>
    </ItemContainer>
  )
}

PedestrianCountComparisonStatsCardItem.propTypes = {
  timestamp: PropTypes.string,
  pedestrianCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pedestrianCountDifferenceInAbsoluteNumbers: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  hideAbsoluteCountDifference: PropTypes.bool,
  pedestrianCountDifferenceInPercentages: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.string,
      description: PropTypes.string,
      activeFrom: PropTypes.string,
      activeTo: PropTypes.string
    })
  ),
  measurementsStartedAt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  displayNoDataPresentPlaceholder: PropTypes.bool,
  displayDataIncompleteNotice: PropTypes.bool,
  hideTopBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideMeasuredSince: PropTypes.bool
}
