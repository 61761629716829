import { useCallback, useState } from 'react'

export default function useClientRect(deps = []) {
  const [rect, setRect] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0
  })

  const ref = useCallback(node => {
    function handleWindowChange() {
      if (node === null) return
      setRect(clientRectToJson(node.getBoundingClientRect()))
    }

    if (node !== null) {
      handleWindowChange()

      window.addEventListener('resize', handleWindowChange)

      return () => {
        window.removeEventListener('resize', handleWindowChange)
      }
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps

  return [rect, ref]
}

function clientRectToJson(rect) {
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height
  }
}
