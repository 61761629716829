import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Appendix({ alignRight, mail, title, content }) {
  const Wrapper = styled('div')`
    font-size: 1.125rem;
    line-height: 1.7;
    text-align: ${alignRight ? 'right' : 'left'};
  `

  return (
    <Wrapper>
      <Headline>{title}</Headline>
      <div>{content}</div>
      <Link href={`mailto:${mail}`}>{mail}</Link>
    </Wrapper>
  )
}

const Headline = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
`

const Link = styled.a`
  color: ${p => p.theme.chroma.accent.css()};
  font-style: italic;
  font-weight: 900;
`

Appendix.propTypes = {
  mail: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  alignRight: PropTypes.bool
}

export default Appendix
