import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { ErrorBoundary } from 'react-error-boundary'
import { pick } from 'lodash'
import I18n from '../../i18n'
import { transformLocationsToColoredLocationSelectorOptions } from '../../lib/transformers/location-transformers'
import useFilter, { getResolution } from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import usePlot from '../../lib/use-plot'
import theme from '../../theme'
import BarChartIcon from '../assets/icons/bar-chart.svg'
import LocationGraphIcon from '../assets/icons/location-graph.svg'
import MinusIcon from '../assets/icons/minus.svg'
import PlusIcon from '../assets/icons/plus.svg'
import Logo from '../assets/logo.svg'
import Card from '../Card'
import ErrorFallback from '../ErrorFallback'
import MultiSelect from '../forms/MultiSelect'
import { CurrentLocationGraph } from '../Graph/Graph'
import Hide from '../Hide'
import PartnerLabel from '../PartnerLabel'
import RadioButton from '../RadioButton'
import Spacer from '../Spacer'
import useAuthorization from '../../lib/use-authorization'
import ActionBar from './ActionBar'
import DateTimeReading from './DateTimeReading'
import CountReading from './CountReading'

const getInitialLineChartColors = () => {
  const colors = []
  theme.chroma.KPICharts.map(kpiChartColorProfile => {
    colors.push(kpiChartColorProfile.fill.hex())
    colors.push(kpiChartColorProfile.fillComplement.hex())
  })
  return colors
}

function transformLocationsToSelectableOptions(locations, chartColors) {
  const locationsAsSelectorOptions = transformLocationsToColoredLocationSelectorOptions(
    locations,
    chartColors
  )

  return locationsAsSelectorOptions
}

const isSSR = typeof window === 'undefined'

function GraphCard() {
  const autoScrollAnchorRef = useRef()
  const { location, locations, status } = useLocation()
  const [filter, dispatchFilter] = useFilter()
  const { stackedChartKeys } = usePlot()
  const { permissions } = useAuthorization()
  const [isResolutionOptionsOpen, setIsResolutionOptionsOpen] = useState(false)
  const isMultiLocationComparisonModeActive =
    filter.viewType === 'compare' && filter.compareType === 'multiLocation'

  //#region allow graph hover
  const [reading, setReading] = useState()
  function handleGraphHover({ active, payload }) {
    if (!isMultiLocationComparisonModeActive || !selectedLocations) {
      setReading(active ? payload : undefined)
      return
    }

    let readings = []

    stackedChartKeys.map((stackedChartKey, index) => {
      if (stackedChartKey) {
        readings.push({
          chartKey: stackedChartKey,
          color:
            multiLocationChartColors[index % multiLocationChartColors.length],
          locationName: `${selectedLocations[index]?.value?.city} - ${selectedLocations[index]?.value?.name}`
        })
      }
    })

    setReading({
      ...payload,
      comparePedestrianCounts: readings
    })
  }
  //#endregion

  //#region allow Location-Multi-Selection
  const [selectableLocations, setSelectableLocations] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [multiLocationChartColors, _setMultiLocationChartColors] = useState(
    getInitialLineChartColors()
  )

  const clearSelection = useCallback(() => {
    dispatchFilter({
      type: 'compare/multiLocation',
      locationIds: []
    })
    setSelectedLocations([])

    dispatchFilter({ type: 'none' })
  }, [dispatchFilter])

  // Set selector options whenever locations or current location change.
  useEffect(() => {
    if (!locations || locations.length === 0) {
      return
    }

    const locationsCopy = pick(
      locations,
      permissions.locations.accessibleForComparison
    )

    const selectableLocations = transformLocationsToSelectableOptions(
      locationsCopy,
      multiLocationChartColors
    )

    setSelectableLocations(selectableLocations)

    //setSelectedLocations(currentLocationAsSelectableLocation)
  }, [
    location,
    locations,
    multiLocationChartColors,
    permissions.locations.accessibleForComparison
  ])

  // Clear selection whenever the location compare mode is turned off.
  useEffect(() => {
    if (filter.viewType === 'none') {
      clearSelection()
    }
  }, [clearSelection, dispatchFilter, filter.viewType])

  // Set selected locations from url when the page is refreshed
  useEffect(() => {
    if (filter.compareLocations) {
      // find locations from url,
      // Note: filtering through selectableLocations -
      // which are sorted by location id -
      // loses the order in which the items where selected, which needs
      // to be restored before setting <selectedLocations>.
      const selectedLocationsFromUrlInAnyOrder = selectableLocations.filter(
        selectableLocation => {
          const found =
            filter.compareLocations.findIndex(compareLocationId => {
              return Number(compareLocationId) === selectableLocation.value.id
            }) !== -1

          return found
        }
      )

      const resultObj = {}
      selectedLocationsFromUrlInAnyOrder.map(selectedLocation => {
        resultObj[selectedLocation.value.id] = selectedLocation
      })

      const selectedLocationsInSelectedOrder = []
      filter.compareLocations.map(compareLocationId => {
        selectedLocationsInSelectedOrder.push(resultObj[compareLocationId])
      })

      // set them as selected.
      setSelectedLocations(selectedLocationsInSelectedOrder)
    }
  }, [filter.compareLocations, selectableLocations])

  function handleLocationSelect(newSelectedLocations, triggeredAction) {
    const selectedLocationIds =
      newSelectedLocations && newSelectedLocations.length > 0
        ? newSelectedLocations.map(selectedLocation => {
            return selectedLocation.value.id
          })
        : []

    dispatchFilter({
      type: 'compare/multiLocation',
      locationIds: selectedLocationIds
    })

    setSelectedLocations(newSelectedLocations)
  }
  //#endregion

  //#region allow setting different resolutions
  const [chartType, setChartType] = useState('area')
  const [allowedResolutionOptions, setAllowedResolutionOptions] = useState([])

  useEffect(() => {
    setAllowedResolutionOptions(
      getResolution(
        filter.from,
        filter.to,
        permissions.allowedFrontendResolutionsPerTimerange
      ).allowedResolutionOptions
    )
  }, [
    filter.from,
    filter.to,
    permissions.allowedFrontendResolutionsPerTimerange
  ])

  const handleResolutionToggleClick = () => {
    setIsResolutionOptionsOpen(prev => !prev)
  }

  const handleChartToggleClick = () => {
    if (chartType === 'area') {
      setChartType('bar')
    } else {
      setChartType('area')
    }
  }

  const handleResolutionChange = event => {
    dispatchFilter({
      type: 'set/resolution',
      resolution: event.target.value
    })
    setIsResolutionOptionsOpen(false)
  }
  //#endregion

  //#region handle scroll to autoScrollAnchorRef-element

  const executeScroll = () => autoScrollAnchorRef.current.scrollIntoView()

  useEffect(() => {
    if (isMultiLocationComparisonModeActive) {
      executeScroll()
    }
  }, [isMultiLocationComparisonModeActive])

  //#endregion

  return (
    <CustomCard rounded shy data-test-id="location_details_graph">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => (window.location.href = '/locations/' + location.id)}
      >
        <Hide when={bp => bp.tablet}>
          <ActionBar />
          <div ref={autoScrollAnchorRef} />
        </Hide>
        <CardBody>
          {!isMultiLocationComparisonModeActive && (
            <Readings
              isLoading={status === 'loading'}
              large={isMultiLocationComparisonModeActive}
            >
              <>
                <CountReadingContainer>
                  <CountReading point={reading} />
                </CountReadingContainer>

                <OtherContainer
                  alignRight={
                    selectedLocations?.length === 0 || status === 'loading'
                  }
                >
                  <DateTimeReading
                    point={reading}
                    multiComparisonModeActive={
                      isMultiLocationComparisonModeActive
                    }
                  />

                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <LogoWrapper>
                      <Logo height={42} />
                    </LogoWrapper>

                    <ChartToggle onClick={handleChartToggleClick}>
                      {chartType === 'area' ? (
                        <>
                          <BarChartIcon />
                          <span>{I18n.t('location.chart_type.bar')}</span>
                        </>
                      ) : (
                        <>
                          <LocationGraphIcon width={21} height={16} />
                          <span>{I18n.t('location.chart_type.area')}</span>
                        </>
                      )}
                    </ChartToggle>

                    <ResolutionContainer>
                      <ResolutionToggle onClick={handleResolutionToggleClick}>
                        <ResolutionLabel>
                          {I18n.t('location.resolution_toggle')}
                        </ResolutionLabel>
                        {isResolutionOptionsOpen ? (
                          <MinusIcon width={8} />
                        ) : (
                          <PlusIcon width={8} height={8} />
                        )}
                      </ResolutionToggle>
                      {isResolutionOptionsOpen && (
                        <ResolutionOptions>
                          {['hour', 'day', 'week', 'month'].map(option => (
                            <div
                              css={{
                                marginTop: '0.75rem'
                              }}
                              key={`resolution-${option}`}
                            >
                              <RadioButton
                                id={`resolution-${option}`}
                                name="resolution"
                                value={option}
                                label={I18n.t(
                                  `location.resolution_option.${option}`
                                )}
                                checked={filter.resolution === option}
                                disabled={
                                  !allowedResolutionOptions.includes(option)
                                }
                                onChange={handleResolutionChange}
                              />
                            </div>
                          ))}
                        </ResolutionOptions>
                      )}
                    </ResolutionContainer>
                  </div>
                </OtherContainer>
              </>
            </Readings>
          )}

          {isMultiLocationComparisonModeActive && (
            <>
              <OtherContainer alignRight>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute'
                  }}
                >
                  <LogoWrapper>
                    <Logo height={42} />
                  </LogoWrapper>
                  <ResolutionContainer floating>
                    <ResolutionToggle onClick={handleResolutionToggleClick}>
                      <ResolutionLabel>
                        {I18n.t('location.resolution_toggle')}
                      </ResolutionLabel>
                      {isResolutionOptionsOpen ? (
                        <MinusIcon width={8} />
                      ) : (
                        <PlusIcon width={8} height={8} />
                      )}
                    </ResolutionToggle>
                    {isResolutionOptionsOpen && (
                      <ResolutionOptions>
                        {['hour', 'day', 'week', 'month'].map(option => (
                          <div
                            css={{ marginTop: '0.75rem' }}
                            key={`resolution-${option}`}
                          >
                            <RadioButton
                              id={`resolution-${option}`}
                              name="resolution"
                              value={option}
                              label={I18n.t(
                                `location.resolution_option.${option}`
                              )}
                              checked={filter.resolution === option}
                              disabled={
                                !allowedResolutionOptions.includes(option)
                              }
                              onChange={handleResolutionChange}
                            />
                          </div>
                        ))}
                      </ResolutionOptions>
                    )}
                  </ResolutionContainer>
                </div>
              </OtherContainer>
              <Spacer size="6rem" transparent></Spacer>
            </>
          )}
          <CurrentLocationGraph
            chartType={
              isMultiLocationComparisonModeActive ? 'multiLine' : chartType
            }
            onHover={handleGraphHover}
            chartColors={multiLocationChartColors}
            delayRender={
              isMultiLocationComparisonModeActive &&
              selectedLocations?.length === 0
            }
          />

          {isMultiLocationComparisonModeActive && !isSSR && (
            <MultiSelectContainer>
              <MultiSelect
                css={{ zIndex: 5 }}
                options={selectableLocations}
                placeholder={I18n.t(
                  'location.compare_multiple_locations.choose_locations_to_compare'
                )}
                onChange={handleLocationSelect}
                value={selectedLocations}
                limit={10}
                closeMenuOnSelect={false}
                enableColouredOptions
                optionColors={multiLocationChartColors}
                defaultValue={selectedLocations}
                enableOptionAbbreviations={false}
              />
            </MultiSelectContainer>
          )}
        </CardBody>

        <PartnerLabel city={location.city} />
      </ErrorBoundary>
    </CustomCard>
  )
}

const CustomCard = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  ${props => props.theme.breakpoints.tablet} {
    margin-top: 0.625rem;
  }
`

const CardBody = styled.div`
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 35rem;

  ${props => props.theme.breakpoints.tablet} {
    min-height: 20rem;
  }
`

const Readings = styled.div`
  display: flex;
  justify-content: ${props => (props.isLoading ? 'center' : 'space-between')};
  margin-bottom: 0.75rem;
  margin-left: ${props => (props.isLoading ? 0 : '3.05rem')};
  align-items: flex-start;
  transition: all 400ms;
  opacity: ${p => (p.isLoading ? 0.3 : 1)};
  filter: ${p => (p.isLoading ? 'grayscale(50%)' : undefined)};
  height: ${props => (props.large ? '12rem' : '9.75rem')};
  /* height must be set, so the graph can calculate its height */
  ${props => props.theme.breakpoints.tablet} {
    justify-content: unset;
    margin-left: 0.2rem;
    height: 8rem;
    margin-bottom: 0.8rem;
  }
`

const CountReadingContainer = styled.div`
  min-width: 11rem;
  max-width: 11rem;
  margin-right: 2rem;

  ${props => props.theme.breakpoints.phone} {
    min-width: auto;
  }
  ${props => props.theme.breakpoints.tablet} {
    margin-right: 1rem;
  }
`
const OtherContainer = styled.div`
  display: flex;
  justify-content: ${props =>
    props.alignRight ? 'flex-end' : 'space-between'};
  flex: 1;
`

const LogoWrapper = styled.div`
  display: none;

  ${props => props.theme.breakpoints.desktopUp} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
    margin-bottom: 1.25rem;
  }
`

const ChartToggle = styled.button`
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.chroma.pencil.hex()};
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
    margin-bottom: 1px;
  }
  span {
    display: none;
    font-size: 0.875rem;
    line-height: 0.9;
  }
  ${p => p.theme.breakpoints.desktopUp} {
    span {
      display: inline;
    }
  }
`

const ResolutionContainer = styled.div`
  padding: 0.25rem;
  margin-right: 1rem;
  background-color: ${props => props.theme.chroma.lightGrey.hex()};
  z-index: 1;
`

const ResolutionToggle = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.25rem;
  color: ${props => props.theme.chroma.pencil.hex()};
`

const ResolutionLabel = styled.span`
  font-size: 0.75rem;
  text-transform: uppercase;
  color: currentColor;
  margin-right: 1rem;
`

const ResolutionOptions = styled.div`
  padding: 0.5rem 0.25rem;
`

const MultiSelectContainer = styled.div`
  padding-left: 2rem;
  padding-right: 1.5rem;
`

export default GraphCard
