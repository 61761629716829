import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import NukaCarousel from 'nuka-carousel'
import { CavetRight, CavetLeft } from './Icon'

function Carousel({ children, ...carouselProps }) {
  return (
    <CarouselStyles>
      <NukaCarousel
        heightMode="max"
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <button
            onClick={previousSlide}
            className={currentSlide < 1 ? 'disabled' : ''}
          >
            <CavetLeft />
          </button>
        )}
        renderCenterRightControls={({
          nextSlide,
          slideCount,
          currentSlide
        }) => (
          <button
            onClick={nextSlide}
            className={currentSlide === slideCount - 1 ? 'disabled' : ''}
          >
            <CavetRight />
          </button>
        )}
        renderBottomCenterControls={({
          currentSlide,
          slideCount,
          goToSlide
        }) => (
          <DotRow>
            {[...Array(slideCount)].map((_, i) => (
              <button key={i} onClick={() => goToSlide(i)}>
                <Dot active={currentSlide === i} />
              </button>
            ))}
          </DotRow>
        )}
        renderTopCenterControls={() => null}
        {...carouselProps}
      >
        {children}
      </NukaCarousel>
    </CarouselStyles>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired
}

const DotRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -0.25rem;
`

const Dot = styled.div`
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 50%;
  background-color: ${p =>
    p.active ? p.theme.chroma.base.css() : 'transparent'};
  margin-left: 0.35rem;
  margin-right: 0.35rem;
  border: 0.125rem solid ${p => p.theme.chroma.base.css()};
  cursor: pointer;
`

const CarouselStyles = styled.div`
  width: 100%;

  .slider {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
  }

  .slider-frame {
    flex: 1 0 auto;
    order: 2;
    width: calc(100% - 7rem);

    ${p => p.theme.breakpoints.tablet} {
      width: 100%;
    }
  }

  .slider-control-centerright,
  .slider-control-centerleft {
    flex: 0 0 auto;
    order: 3;
    position: static !important;
    transform: none !important;

    button {
      padding: 1rem;
      width: 3.5rem;
      cursor: pointer;
      outline: none;

      &:focus {
        color: ${p => p.theme.chroma.accent.css()};
      }
    }

    button.disabled {
      opacity: 0.3;
    }

    ${p => p.theme.breakpoints.tablet} {
      display: none;
    }
  }

  .slider-control-centerleft {
    order: 1 !important;
  }
`

export default Carousel
