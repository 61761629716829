import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import dayjs from 'dayjs'

import I18n from '../../i18n'
import useModalState from '../../lib/use-modal-state'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import * as Icon from '../Icon'
import Button from '../Button'
import useAuthorization from '../../lib/use-authorization'
import Modal from './Modal'
import FullHeightCalendar from './FullHeightCalendar'

const tabIndices = {
  average: 0,
  location: 1,
  timerange: 2
}

function ComparisonModal({
  isOpen,
  favLocationIds,
  mapLocations,
  onTimeSubmitClick,
  onAverageSubmitClick,
  ...rest
}) {
  const { location } = useLocation()
  const [filter] = useFilter()
  const { permissions } = useAuthorization()
  const [compareFrom, setCompareFrom] = useState(filter.compareFrom)
  const [forceRerenderKey, setForceRerenderKey] = useState(false)

  const [activeTabIndex, setActiveTabIndex] = useState(
    tabIndices[filter.compareType]
  )
  const [activeModal, { modals }] = useModalState()

  React.useEffect(() => {
    // Sets active tab when modal is opened
    if (activeModal?.startsWith(modals.comparison)) {
      const [_, newTab] = activeModal.split('-')
      if (newTab) {
        setActiveTabIndex(tabIndices[newTab])
      }
    }
  }, [activeModal, modals])

  // Update local state when global state changes
  React.useEffect(() => void setCompareFrom(filter.compareFrom), [
    filter.compareFrom
  ])

  const timespan = dayjs(filter.to).diff(dayjs(filter.from), 'day')
  const compareTo = dayjs(compareFrom)
    .add(timespan, 'days')
    .endOf('day')
    .toDate()

  function handleDateChange({ start }) {
    setCompareFrom(start)
    setForceRerenderKey(state => !state)
  }

  function handleTimeCompareSubmitClick() {
    onTimeSubmitClick({ from: compareFrom })
  }

  return (
    <Modal isOpen={isOpen} {...rest}>
      <Tabs
        css={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        selectedIndex={activeTabIndex}
        onSelect={index => setActiveTabIndex(index)}
      >
        <CustomTabList>
          <CustomTab>
            <Icon.Average width={20} height={20} />
            <TabLabel>{I18n.t('location.comparison.average_short')}</TabLabel>
          </CustomTab>
          <CustomTab>
            <Icon.TimeRange width={20} height={20} />
            <TabLabel>{I18n.t('location.comparison.time')}</TabLabel>
          </CustomTab>
        </CustomTabList>
        <CustomTabPanel>
          <FullHeightFlexContainer>
            <div
              css={{
                display: 'flex',
                flex: '1 1 auto',
                justifyContent: 'center',
                alignItems: 'center',
                overflowY: 'auto',
                fontSize: '1.2rem',
                textAlign: 'center'
              }}
            >
              {I18n.t('location.average_info')}
            </div>
            <SubmitButton baseColor onClick={onAverageSubmitClick}>
              {I18n.t('location.comparison.submit')}
            </SubmitButton>
          </FullHeightFlexContainer>
        </CustomTabPanel>
        <CustomTabPanel>
          <FullHeightFlexContainer>
            <FullHeightCalendar
              key={forceRerenderKey}
              onDateChangeStart={handleDateChange}
              canSafelySetHeight={isOpen}
              startDate={compareFrom}
              endDate={compareTo}
              min={dayjs(location.metadata.earliestMeasurementAt)
                .subtract(3, 'month')
                .toDate()}
              max={dayjs()
                .add(2, 'months')
                .toDate()}
              minDate={dayjs(permissions.earliestAccessibleDate).toDate()}
              maxDate={dayjs(location.metadata.latestMeasurementAt)
                .endOf('day')
                .toDate()}
            />
            <SubmitButton baseColor onClick={handleTimeCompareSubmitClick}>
              {I18n.t('location.comparison.submit')}
            </SubmitButton>
          </FullHeightFlexContainer>
        </CustomTabPanel>
      </Tabs>
    </Modal>
  )
}

const SubmitButton = styled(Button)`
  width: 100%;

  @media (min-height: 640px) {
    margin-top: 2rem;
  }
`

const CustomTab = styled(Tab)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 0.625rem;
  background-color: ${p =>
    p.selected ? p.theme.chroma.lightBlue.css() : 'transparent'};
  color: ${p =>
    p.selected ? p.theme.chroma.white.css() : p.theme.chroma.base.css()};
`

const TabLabel = styled.span`
  display: block;
  margin-top: 0.5rem;
  font-size: 0.75rem;
`

const FullHeightFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CustomTabList = styled(TabList)`
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  margin-bottom: 1rem;
`

const CustomTabPanel = styled(TabPanel)`
  display: ${p => (p.selected ? 'flex' : 'none')};
  flex-direction: column;
  flex: 1;
`

CustomTab.tabsRole = 'Tab'
CustomTabList.tabsRole = 'TabList'
CustomTabPanel.tabsRole = 'TabPanel'

ComparisonModal.propTypes = {
  isOpen: PropTypes.bool,
  onTimeSubmitClick: PropTypes.func.isRequired,
  onAverageSubmitClick: PropTypes.func.isRequired,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  mapLocations: PropTypes.arrayOf(PropTypes.object)
}

export default ComparisonModal
