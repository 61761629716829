import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useMedia } from 'use-media'

import I18n, { Trans } from '../i18n'

import Typography from '../components/Typography'
import Button from '../components/Button'
import * as Icon from '../components/Icon'
import Section from '../components/Section'
import TextBox from '../components/TextBox'
import TextBoxIcon from '../components/TextBoxIcon'

function Intro({ citiesCount, locationsCount, countriesCount, loggedIn }) {
  const iconSize = useMedia({ minWidth: 768 }, true) ? 81 : 61

  return (
    <Section.Wrapper css={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
      <FiguresSection row>
        <FigureWrapper spacingBottom>
          <Figure>
            <Icon.CityScape height={iconSize} />
            <div>
              <FigureNumber>{citiesCount}</FigureNumber>
              <FigureText>
                <Trans id="pages.home.intro.cities" />
              </FigureText>
            </div>
          </Figure>
          <Figure>
            <Icon.Location height={iconSize} />
            <div>
              <FigureNumber>{locationsCount}</FigureNumber>
              <FigureText>
                <Trans id="pages.home.intro.locations" />
              </FigureText>
            </div>
          </Figure>
          <Figure>
            <Icon.Flag height={iconSize} />
            <div>
              <FigureNumber>{countriesCount}</FigureNumber>
              <FigureText>
                <Trans id="pages.home.intro.countries" />
              </FigureText>
            </div>
          </Figure>
        </FigureWrapper>
      </FiguresSection>
      <Section>
        <TextBoxWrapper>
          <FlexTextBox>
            <TextBoxIcon>
              <Icon.Transparency />
            </TextBoxIcon>
            <Typography tag="h4">
              {I18n.t('pages.home.intro.bullet_points.first_headline', {
                locationsCount
              })}
            </Typography>
            <Trans id="pages.home.intro.bullet_points.first" />
            <Button.Link href="/ranking" outline css={{ width: '100%' }}>
              <Trans id="pages.home.intro.button_text.locations" />
            </Button.Link>
          </FlexTextBox>
          <FlexTextBox>
            <TextBoxIcon>
              <Icon.Stakeholders />
            </TextBoxIcon>
            <Typography tag="h4">
              {I18n.t('pages.home.intro.bullet_points.second_headline', {
                locationsCount
              })}
            </Typography>
            <Trans id="pages.home.intro.bullet_points.second" />
            <Button.Link href="hystreet-index" outline css={{ width: '100%' }}>
              <Trans id="pages.home.intro.button_text.hystreet_index" />
            </Button.Link>
          </FlexTextBox>
          <FlexTextBox>
            <TextBoxIcon>
              <Icon.Clock width={40} height={40} />
            </TextBoxIcon>
            <Typography tag="h4">
              {I18n.t('pages.home.intro.bullet_points.third_headline', {
                locationsCount
              })}
            </Typography>
            <Trans id="pages.home.intro.bullet_points.third" />
            <Button.Link href="/methodology" outline css={{ width: '100%' }}>
              <Trans id="pages.home.intro.button_text.methodology" />
            </Button.Link>
          </FlexTextBox>
        </TextBoxWrapper>
      </Section>
    </Section.Wrapper>
  )
}

const FiguresSection = styled(Section)`
  margin-bottom: 3.25rem;

  ${props => props.theme.breakpoints.phone} {
    width: auto;
    align-items: flex-end;
  }

  ${props => props.theme.breakpoints.phoneUp} {
    > div {
      width: 100%;
    }
  }

  ${props => props.theme.breakpoints.tabletUp} {
    margin-bottom: 0;
  }
`

const FigureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.spacingBottom && '1.7rem'};
  width: 100%;

  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
    margin-bottom: ${props => props.spacingBottom && '4.5rem'};
  }
`

const Figure = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.75rem;
  margin-bottom: 1rem;
  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: column;
    + div::before {
      content: '';
      display: block;
      position: absolute;
      top: -0.5rem;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: ${props => props.theme.chroma.base.alpha(0.15).css()};
    }
  }
  svg {
    display: block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    ${props => props.theme.breakpoints.tabletUp} {
      margin-right: 0;
    }
  }
`

const FigureNumber = styled.span`
  font-size: 1.875rem;
  font-weight: 900;
  letter-spacing: 1.5px;
  margin-right: 0.75rem;

  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 2.25rem;
  }
`

const FigureText = styled.span`
  font-size: 1.5rem;
  font-weight: 900;

  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.75rem;
  }
`

const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const FlexTextBox = styled(TextBox)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > :last-child {
    margin-top: auto;
  }
`

Intro.propTypes = {
  citiesCount: PropTypes.number.isRequired,
  countriesCount: PropTypes.number.isRequired,
  locationsCount: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool
}

export default Intro
