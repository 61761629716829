import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import theme from '../../theme'
import I18n from '../../i18n'
import * as Icon from '../Icon'

function ZoneDetailsDirectionButton({ onClick, isActive }) {
  return (
    <div
      css={{
        display: 'block',
        height: '4.1rem',
        overflow: 'visible'
      }}
    >
      <ButtonWrapper>
        <div css={{ display: 'flex', height: '2.85rem', flexShrink: 0 }}>
          <button
            css={{
              display: 'flex',
              flex: '1 0 auto',
              textAlign: 'left',
              alignItems: 'center',
              opacity: isActive ? 1 : 0.5,
              filter: !isActive && 'grayscale(90%)',
              transition: 'all 120ms',
              cursor: 'pointer'
            }}
            onClick={onClick}
          >
            <div
              css={{
                width: '4rem',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Icon.DirectionLeftRight
                height={23}
                width={29}
                color={theme.chroma.lightBlue.css()}
              />
            </div>
            <div css={{ paddingRight: '0.5rem' }}>
              <Label>{I18n.t('location.details.label')}</Label>
              <Title>{I18n.t('location.details.direction')}</Title>
            </div>
          </button>
        </div>
      </ButtonWrapper>
    </div>
  )
}

ZoneDetailsDirectionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  svg: {
    display: block;
  }
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.125rem;
  border-color: ${p => p.theme.chroma.white.css()};
  background-color: ${p => p.theme.chroma.dustyBlue.css()};
  max-width: fit-content;
  margin: 0 0;
  min-width: 15rem;
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${p => p.theme.chroma.base.css()};
  max-width: 11.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Label = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${p => p.theme.chroma.grey.css()};
  margin-bottom: 0.2rem;
`

export default ZoneDetailsDirectionButton
