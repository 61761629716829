import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import I18n, { Trans } from '../i18n'

import { IndexProvider } from '../lib/use-hystreet-index'

import Card from '../components/Card'
import GermanySvg from '../components/GermanySvg'
import Headline from '../components/Headline'
import * as Icon from '../components/Icon'
import Section from '../components/Section'
import Spacer from '../components/Spacer'
import TextBox from '../components/TextBox'
import TextBoxIcon from '../components/TextBoxIcon'

import Caption from '../components/NationalIndex/Caption'
import HeaderBar from '../components/NationalIndex/HeaderBar'
import IndexGraph from '../components/NationalIndex/IndexGraph'

import { getDifferenceAsPercentage } from '../lib/calculate-location-statistics'
import { AuthenticationProvider } from '../lib/use-authentication'
import Contact from '../sections/Contact'
import { indexAPITransformers } from '../lib/api/index/index-api'

const UNAUTHENTICATED_INDEX_TIME_RANGES = {
  lastMonth: false,
  twoMonths: false,
  sixMonths: true,
  oneYear: false,
  twoYears: false,
  max: false
}

const AUTHENTICATED_INDEX_TIMERANGES = {
  lastMonth: false,
  twoMonths: false,
  sixMonths: true,
  oneYear: true,
  twoYears: true,
  max: true
}

const getEnabledTimeRanges = userIsAuthenticated => {
  return userIsAuthenticated
    ? AUTHENTICATED_INDEX_TIMERANGES
    : UNAUTHENTICATED_INDEX_TIME_RANGES
}

const timeRangeToIso = timerange => {
  return Object.assign(
    {},
    {
      from: dayjs(timerange.from).toISOString(),
      to: dayjs(timerange.to).toISOString(),
      resolution: timerange.resolution
    }
  )
}

const calculateIndexVariation = (baseIndexValues, comparedIndexValues) => {
  let stateNameIndexVariants = {}

  for (const stateNameAsKey in baseIndexValues) {
    let index_value_difference = null
    if (
      baseIndexValues[stateNameAsKey].indexValue !== 0 &&
      comparedIndexValues[stateNameAsKey].indexValue !== 0
    ) {
      index_value_difference = getDifferenceAsPercentage(
        baseIndexValues[stateNameAsKey].indexValue,
        comparedIndexValues[stateNameAsKey].indexValue
      )
    }

    stateNameIndexVariants[stateNameAsKey] = {
      ...baseIndexValues[stateNameAsKey],
      index_value: index_value_difference
    }
  }

  return stateNameIndexVariants
}
function HystreetIndex({
  id,
  indexValues,
  maxTimerange,
  originalUrl,
  stateIndicesForPastMonth,
  stateIndicesForTwoMonthsAgo,
  userLoggedIn
}) {
  const indexValueVariationsInPastTwoMonths = calculateIndexVariation(
    stateIndicesForPastMonth,
    stateIndicesForTwoMonthsAgo
  )
  return (
    <AuthenticationProvider initialLoginState={{ loggedIn: userLoggedIn }}>
      <IndexProvider
        originalUrl={originalUrl}
        id={id}
        indexValues={indexValues}
        maxTimerange={timeRangeToIso(maxTimerange)}
        enabledTimeranges={getEnabledTimeRanges(userLoggedIn)}
      >
        <Helmet>
          <title>{I18n.t('pages.hystreet_index.title')}</title>
        </Helmet>
        <div css={{ position: 'relative' }}>
          <Section css={{ paddingTop: '4rem' }}>
            <Section.Wrapper>
              <CustomHeadline center>
                <Trans id="pages.hystreet_index.top.headline" />
              </CustomHeadline>
              <Subline>
                <Trans id="pages.hystreet_index.top.subline" />
              </Subline>
              <Content>
                <TopText center>
                  <Trans id="pages.hystreet_index.top.content.text" />
                </TopText>
              </Content>
            </Section.Wrapper>
          </Section>
          <Section
            mid
            row
            css={{
              marginTop: '3.5rem',
              paddingTop: '2rem',
              paddingBottom: '10rem',
              maxWidth: '100%'
            }}
          >
            <Section.Skew position="top" direction="down" mid height="6rem" />
            <Section.Half basis={56} wrapperWidth="87.5rem">
              <Section.Wrapper>
                <Caption />
                <CustomCard rounded shy>
                  <HeaderBar />
                  <IndexGraph
                    preLoadedPlotData={indexAPITransformers.indexValuesToChartData(
                      indexValues
                    )}
                    hideChangeReading
                  />
                </CustomCard>
              </Section.Wrapper>
            </Section.Half>
            <Section.Half basis={44}>
              <Section.Wrapper>
                <Headline breakout>
                  <Trans id="pages.hystreet_index.main.content.base.headline" />
                </Headline>
                <Text>
                  <Trans
                    id="pages.hystreet_index.main.content.base.text"
                    copy
                  />
                </Text>
              </Section.Wrapper>
            </Section.Half>
          </Section>
          <Section light css={{ padding: '4rem 0' }}>
            <Section.Skew position="top" light direction="up" height="8rem" />
            <Section.Wrapper>
              <Headline breakout>
                <Trans id="pages.hystreet_index.main.content.how.headline" />
              </Headline>
              <Text>
                <Trans id="pages.hystreet_index.main.content.how.text" copy />
              </Text>
            </Section.Wrapper>
          </Section>
          <Section mid css={{ padding: '4rem 0 2rem 0' }}>
            <Section.Wrapper>
              <Headline breakout>
                <Trans id="pages.hystreet_index.main.content.who.headline" />
              </Headline>
              <BulletContainer>
                <Trans
                  id="pages.hystreet_index.main.content.who.bullet_points"
                  copy
                />
              </BulletContainer>
            </Section.Wrapper>
          </Section>
          <Section row light css={{ padding: '4rem 0' }}>
            <Section.Half>
              <Section.Wrapper>
                <Headline breakout>
                  <Trans id="pages.hystreet_index.main.content.what.headline" />
                </Headline>
                <Text>
                  <Trans
                    id="pages.hystreet_index.main.content.what.text"
                    copy
                  />
                </Text>
                <BulletContainer>
                  <Trans
                    id="pages.hystreet_index.main.content.what.bullet_points"
                    copy
                  />
                </BulletContainer>
              </Section.Wrapper>
            </Section.Half>
            <Section.Half>
              <Section.Wrapper>
                <GermanySvg
                  stateIndices={indexValueVariationsInPastTwoMonths}
                />
                <LegendLabel>
                  <Trans id="pages.hystreet_index.map.legend.positive_variation_compared_to_previous_month" />
                </LegendLabel>
              </Section.Wrapper>
            </Section.Half>
          </Section>
          <Section mid css={{ padding: '4rem 0 10rem 0' }}>
            <Section.Wrapper>
              <Headline breakout>
                <Trans id="pages.hystreet_index.outro.headline" />
              </Headline>
              <TextBoxWrapper>
                <TextBox>
                  <TextBoxIcon>
                    <Icon.Login />
                  </TextBoxIcon>
                  <Text>
                    <Trans id="pages.hystreet_index.outro.content_one" copy />
                  </Text>
                </TextBox>
                <TextBox>
                  <TextBoxIcon>
                    <Icon.Report />
                  </TextBoxIcon>
                  <Text>
                    <Trans id="pages.hystreet_index.outro.content_two" copy />
                  </Text>
                </TextBox>
                <TextBox>
                  <TextBoxIcon>
                    <Icon.Graph />
                  </TextBoxIcon>
                  <Text>
                    <Trans id="pages.hystreet_index.outro.content_three" copy />
                  </Text>
                </TextBox>
              </TextBoxWrapper>
            </Section.Wrapper>
          </Section>
          <Contact
            teaser="contact.teaser_developer"
            cta="contact.cta_developer"
          />
          <div css={{ marginTop: '-3rem' }}>
            <Spacer accent />
          </div>
        </div>
      </IndexProvider>
    </AuthenticationProvider>
  )
}

HystreetIndex.propTypes = {
  id: PropTypes.number.isRequired,
  originalUrl: PropTypes.string.isRequired,
  indexValues: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      indexValue: PropTypes.number.isRequired
    }).isRequired
  ),
  maxTimerange: PropTypes.object.isRequired,
  stateIndicesForPastMonth: PropTypes.object.isRequired,
  stateIndicesForTwoMonthsAgo: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired
}

const CustomHeadline = styled(Headline)`
  font-size: 2.25rem;
  text-align: center;
  margin: 0 auto;

  ${props => props.theme.breakpoints.phone} {
    font-size: 1.5rem;
    text-align: left;
    margin: 0;
  }
  span {
    text-transform: lowercase;
  }
`

const CustomCard = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const Subline = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 2rem;

  ${props => props.theme.breakpoints.phone} {
    font-size: 1.3rem;
    text-align: left;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
  }
`

const TopText = styled.div`
  line-height: 1.5;
  padding: 1rem 1.75rem 0;
  margin-bottom: 2.5rem;
  width: 100%;
  text-align: left;
  ${props =>
    props.center &&
    `
      margin: 0 auto 2.5rem auto;
      width: 70%;
      text-align: center;
      ${props.theme.breakpoints.phone} {
        width: 100%;
        text-align: left;
        padding: 1rem 0 0;
      }
    `}
`

const Text = styled.div`
  p {
    line-height: 1.5;

    a {
      white-space: nowrap;
    }

    a::before {
      content: '> ';
    }
  }

  p:last-of-type {
    margin-bottom: 1rem;
  }
`

const BulletContainer = styled.div`
  padding-top: 0.5rem;
  margin-bottom: 3rem;
`

const TextBoxWrapper = styled.div`
  padding: 2.5rem 0;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LegendLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.xxs};
  font-style: italic;
`

export default HystreetIndex
