import dayjs from 'dayjs'
import DatetimeHelpers from './datetime-helpers'

const PlotHelpers = {
  /**
   * "Filters" measurements by setting pedestriansCount (v1 API) and count (v2 API) and nulling the details-attribute if the timestamp is before the earliestAccessibleDate.
   * Note: Measurements are not filtered completely, so that the timerange in measurements in still visible in the chart.
   * @param {Array<Measurement>} measurements - array of measurements
   * @param {Date} earliestAccessibleDate - date after which measurements shall be nulled
   * @returns {Array<Measurement>} - array of measurements with pedestriansCount nulled if timestamp is before earliestAccessibleDate
   */
  filterMeasurementsBeforeEarliestAccessibleDate: (
    measurements,
    earliestAccessibleDate
  ) => {
    if (!measurements) return measurements

    return measurements.map(measurement => {
      if (
        dayjs(measurement.timestamp).isBefore(dayjs(earliestAccessibleDate))
      ) {
        return {
          ...measurement,
          pedestriansCount: 0,
          count: 0,
          details: {}
        }
      }

      return measurement
    })
  },

  /**
   * Finds the index of the first element in an array of measurements where the timezone offset changes.
   * @param {Measurement} cur - current measurement
   * @param {Number} i - index of current measurement
   * @param {Array<Measurement>} measurements - array of measurements
   * @returns {Boolean} - true if the timezone offset changes, false otherwise
   */
  findTimeChange: (cur, i, measurements) => {
    const prev = measurements[i - 1]
    if (!prev) return false

    const curDate = new Date(cur.timestamp)
    const prevDate = new Date(prev.timestamp)
    return curDate.getTimezoneOffset() !== prevDate.getTimezoneOffset()
  },

  /**
   *
   * @param {Array} measurements main measurements
   * @param {Array} unsafeCompareMeasurements compare measurements
   * @returns compare measurements with an additional hour added
   */
  addAdditionalHourToMeasurements: (
    measurements,
    unsafeCompareMeasurements
  ) => {
    // find the index where the time changes. It might be in measurements or unsafeCompareMeasurements.
    let addAtIndex = unsafeCompareMeasurements.findIndex(this.findTimeChange)
    if (addAtIndex === -1) {
      addAtIndex = measurements.findIndex(this.findTimeChange)
    }
    const dup = [...unsafeCompareMeasurements]
    dup.splice(addAtIndex, 0, {
      timestamp: dayjs(unsafeCompareMeasurements[addAtIndex].timestamp)
        .tz(
          DatetimeHelpers.zoneFromTimestamp(
            unsafeCompareMeasurements[addAtIndex].timestamp
          )
        )
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      pedestriansCount: null
    })
    return dup
  },

  /**
   *
   * @param {Array} measurements main measurements
   * @param {Array} unsafeCompareMeasurements compare measurements
   * @returns compare measurements with the additional hour removed
   */
  removeAdditionalHourFromMeasurements: (
    measurements,
    unsafeCompareMeasurements
  ) => {
    let removeAtIndex = measurements.findIndex(this.findTimeChange)
    if (removeAtIndex === -1) {
      removeAtIndex = unsafeCompareMeasurements.findIndex(this.findTimeChange)
    }
    const dup = [...unsafeCompareMeasurements]
    dup.splice(removeAtIndex, 1)
    return dup
  },

  /**
   * Aggregates measurements into a format that the chart can use
   * @param {Array} measurements - array of measurements
   * @returns {Object} - plot and sums
   */
  aggregateObjectTypeMeasurements: (location, measurements) => {
    if (!measurements) return { plot: [], sums: {} }

    const sums = {
      count: 0,
      details: { car: 0, bus: 0, tram: 0, scooter: 0 }
    }

    const plot = measurements.map((measurement, i) => {
      sums.count += measurement.count

      const point = {
        timestamp: measurement.timestamp,
        count: measurement.count,
        minTemperature: measurement.details?.weatherConditions?.minTemperature,
        temperature: measurement.details?.weatherConditions?.temperature,
        weatherCondition: measurement.details?.weatherConditions?.description
      }

      // Don't show count outside of the measuring lifetime
      const mainDate = dayjs(measurement.timestamp).tz(
        DatetimeHelpers.zoneFromTimestamp(measurement.timestamp)
      )
      if (
        mainDate.isBefore(location.metadata.earliestMeasurementAt) ||
        mainDate.isAfter(location.metadata.latestMeasurementAt)
      ) {
        point.count = undefined
      }

      return point
    })

    return { plot, sums }
  },

  /**
   * Returns the keys to be displayed in the chart for a given object type. Currently only supports 'VEHICLES'.
   * @param {String} objectType - object type to get keys for - currently only supports 'VEHICLES'
   * @returns {Array} - keys to be displayed in the chart as array of strings
   */
  getObjectTypeChartKeys: objectType => {
    let keys = [null, 'count']

    if (objectType === 'VEHICLES') {
      keys = [null, 'count']
    }

    return keys
  },

  /**
   * Takes the current and fallback aggregated measurements and returns viewable chart data.
   * @param {Object} aggreatedMeasurements
   * @param {Object} fallbackAggregatedMeasurements
   * @returns {Object} - With a plot attribute, being an array of plotter points, and a sums attribute, being an object with the sums of the plotter points
   */
  getPlottableOrCachedChart: (
    aggreatedMeasurements,
    fallbackAggregatedMeasurements
  ) => {
    // store the result if it's viewable. If the next result isn't viewable, e.g.
    // because it's still fetching, we can use this last result as a fallback.
    if (aggreatedMeasurements.plot.length > 0) {
      fallbackAggregatedMeasurements.current = aggreatedMeasurements
    }

    const plotIsViewable = aggreatedMeasurements.plot.length > 0
    const hasFallbackPlot =
      fallbackAggregatedMeasurements.current.plot.length > 0
    const shouldUseFallbackPlot = !plotIsViewable && hasFallbackPlot

    let plot = shouldUseFallbackPlot
      ? fallbackAggregatedMeasurements.current.plot
      : aggreatedMeasurements.plot
    let sums = shouldUseFallbackPlot
      ? fallbackAggregatedMeasurements.current.sums
      : aggreatedMeasurements.sums

    return { plot, sums }
  }
}

export default PlotHelpers
