import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

function Grid({ children, columns, ...nativeProps }) {
  return (
    <GridContainer columns={columns} {...nativeProps}>
      {children}
    </GridContainer>
  )
}

Grid.propTypes = {
  columns: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const GridContainer = styled.div(props => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.columns}, 1fr)}`,
  gridColumnGap: '12px',
  rowGap: '3px',
  gridTemplateRows: 'auto',
  minHeight: '150px'
}))

function Item({ children, height, width, ...nativeProps }) {
  return (
    <GridItem height={height} width={width} {...nativeProps}>
      {children}
    </GridItem>
  )
}

Item.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const GridItem = styled.div(props => ({
  gridRow: `span ${props.height}`,
  gridColumn: `span ${props.width}`
}))

export default Grid

export { Item }
