import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import I18n from '../i18n'
import BlogPostContent from '../components/BlogPost'
import Link from '../components/Link'
import Section from '../components/Section'

function BlogPost({ post }) {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <Section.Wrapper css={{ maxWidth: '44rem', padding: '4rem 2rem' }}>
        <BlogPostContent {...post} />
        <Link css={{ marginTop: '1rem' }} href="/press/news-archive">
          {I18n.t('pages.blog_post.back')}
        </Link>
      </Section.Wrapper>
    </Section>
  )
}

BlogPost.propTypes = {
  post: PropTypes.object.isRequired
}

export default BlogPost
