import I18n from '../i18n'

export default function useMoney() {
  const toEuros = cents => {
    return new Intl.NumberFormat(I18n.locale, {
      style: 'currency',
      currency: 'EUR'
    }).format(cents / 100)
  }

  return { toEuros }
}
