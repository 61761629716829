import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import clamp from 'lodash/clamp'

const POPOVER_WIDTH = 280
const MARGIN = 10

function calculateCenter(props) {
  const half = POPOVER_WIDTH / 2

  const idealCenter = props.anchorTo.left + props.anchorTo.width / 2
  const lowestCenter = half + MARGIN
  // Even though we use window.innerWidth here, we don't have to attach an EventListener for window resizing. The component receives new props on every resize, so it always rerenders anyway.
  const highestCenter = window.innerWidth - (half + MARGIN)

  const center = clamp(idealCenter, lowestCenter, highestCenter)

  const triangleOffset = clamp(
    half + idealCenter - center,
    2 * MARGIN,
    POPOVER_WIDTH - 2 * MARGIN
  )

  return { center, triangleOffset }
}

const AnchoredPopover = styled.div`
  display: block;
  position: absolute;
  top: ${p => p.anchorTo.top + window.pageYOffset - 8}px;
  left: ${p => calculateCenter(p).center}px;

  width: ${POPOVER_WIDTH}px;

  padding: 1rem;
  padding-top: 0.25rem;
  padding-right: 0.25rem;

  border-radius: 0.25rem;
  background-color: ${props => props.theme.chroma.white.css()};

  box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.12);
  transform: translate(-50%, -100%);

  &:after {
    content: '';
    position: absolute;
    left: ${p => calculateCenter(p).triangleOffset}px;
    top: 100%;

    border-top: 8px solid white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    transform: translateX(-50%);
    z-index: 1;
  }
`

AnchoredPopover.propTypes = {
  anchorTo: PropTypes.object
}

export default AnchoredPopover
