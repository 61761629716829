import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Spacer = styled.div`
  height: ${props => props.size || '5rem'};
  background-color: ${props => {
    if (props.dark) return props.theme.chroma.base.hex()
    if (props.mid) return props.theme.chroma.nearlyWhite.hex()
    if (props.accent) return props.theme.chroma.accent.hex()
    if (props.light) return props.theme.chroma.white.hex()
    if (props.transparent) return props.theme.chroma.transparent.hex()

    return props.theme.chroma.nearlyWhite.hex()
  }};
`

Spacer.propTypes = {
  accent: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  mid: PropTypes.bool,
  transparent: PropTypes.bool,
  size: PropTypes.string
}

const HorizontalSpacer = styled.div`
  width: ${props => props.size || '5rem'};
  background-color: ${props => {
    if (props.dark) return props.theme.chroma.base.hex()
    if (props.mid) return props.theme.chroma.nearlyWhite.hex()
    if (props.accent) return props.theme.chroma.accent.hex()
    if (props.light) return props.theme.chroma.white.hex()
    if (props.transparent) return props.theme.chroma.transparent.hex()

    return props.theme.chroma.nearlyWhite.hex()
  }};
`

HorizontalSpacer.propTypes = {
  accent: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  mid: PropTypes.bool,
  transparent: PropTypes.bool,
  size: PropTypes.string
}

export { HorizontalSpacer }
export default Spacer
