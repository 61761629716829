import React  from 'react'
import PropTypes from 'prop-types'

import Button from '../components/Button'
import Input from '../components/forms/Input'
import { Copy as CopyIcon } from '../components/Icon'

function CopyButton({ value }) {
  const id = `id-${value}` // we can't use random ids cause they will differ in dom (server rendered) and client

  const copyToClipboard = () => {
    const input = document.getElementById(id)
    input.select()
    input.setSelectionRange(0, 99999) /* For mobile devices */
    navigator.clipboard.writeText(input.value)
  }

  return (
    <div css={{ display: 'flex', marginBottom: '1rem' }}>
      <Input name={id} defaultValue={value} readOnly size="30" />
      <Button css={{ padding: '0 0.6rem' }} onClick={copyToClipboard}>
        <CopyIcon />
      </Button>
    </div>
  )
}

CopyButton.propTypes = {
  value: PropTypes.string
}

export default CopyButton
