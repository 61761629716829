import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Star, StarOutline } from './Icon'

function FavStar({ filled }) {
  const Svg = styled(filled ? Star : StarOutline)`
    color: ${p =>
      filled ? p.theme.chroma.base.css() : p.theme.chroma.grey.css()};
    fill: currentColor;
  `

  return <Svg />
}

FavStar.propTypes = {
  filled: PropTypes.bool
}

export default FavStar
