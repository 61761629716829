import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

import Section from '../components/Section'
import header from './assets/partner-header.jpg'

function PartnerHeadImage() {
  return (
    <CustomSection row>
      <HeaderWrapper direction={'horizontal'} image={header} />
      <Section.Skew light direction="down" position="bottom" inset />
    </CustomSection>
  )
}
const CustomSection = styled(Section)`
  @media (max-width: 960px) {
    svg {
      visibility: hidden !important;
    }
  }
`
const HeaderWrapper = styled(Section.Half)`
  min-height: 23rem;

  @media (max-width: 500px) {
    min-height: 10rem;
  }

  @media (max-width: 960px) {
    min-height: 7rem;
  }
`

export default PartnerHeadImage
