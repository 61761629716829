import React from 'react'
import styled from '@emotion/styled'

import { Close } from './Icon'

function CloseButton(props) {
  return (
    <button {...props}>
      <ButtonContent>
        <Close css={{ display: 'block' }} />
      </ButtonContent>
    </button>
  )
}

const ButtonContent = styled.div`
  cursor: pointer;
  padding: 0.25rem;
  color: ${p => p.theme.chroma.base.css()};
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 80ms;

  &:hover {
    background-color: ${p => p.theme.chroma.base.alpha(0.1).css()};
  }
`

export default CloseButton
