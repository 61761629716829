import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n from '../i18n'

import wfmLogo from '../../assets/images/wfm_logo.jpg'
import bocholtLogo from '../../assets/images/stadtmareting-bocholt.png'

const partners = {
  münster: { logo: wfmLogo, label: I18n.t('partner.wfm_label') },
  bocholt: { logo: bocholtLogo, label: I18n.t('partner.bocholt_label') }
}

export default function PartnerLabel({ city }) {
  const partner = partners[city.toLowerCase()]
  if (!partner) return null

  return (
    <Box>
      <img src={partner.logo} height={50} />
      <LabelText>{partner.label}</LabelText>
    </Box>
  )
}

PartnerLabel.propTypes = {
  city: PropTypes.string.isRequired
}

const Box = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  height: 5rem;
  border-top: 2px solid #e4e1e1;
`

const LabelText = styled.div`
  margin-left: 0.375rem;
  font-size: 0.875rem;
  color: #183e62;
  line-height: 1;
`
