import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import Typography from '../components/Typography'
import Card from '../components/Card'
import Checkbox from '../components/forms/Checkbox'
import Input from '../components/forms/Input'
import Form from '../components/forms/Form'
import Button from '../components/Button'
import PlanSummary from '../components/PlanSummary'
import Headline from '../components/Headline'
import Section from '../components/Section'
import TextArea from '../components/forms/TextArea'

import CompleteBusinessData from '../sections/CompleteBusinessData'

import useLocalStorage, { localStorageKeys } from '../lib/use-local-storage'
import I18n, { Trans } from '../i18n'

function SubscriptionNew({ user, plans, locations, subscription, errors }) {
  const [selectedLocationIds, _setSelectedLocationIds] = useLocalStorage(
    localStorageKeys.selectedLocationIds,
    []
  )
  const [selectedPlanName, _setSelectedPlanName] = useLocalStorage(
    localStorageKeys.selectedPlanName,
    null
  )
  const [selectedFlatrate, _setSelectedFlatrate] = useLocalStorage(
    localStorageKeys.selectedFlatrate,
    false
  )

  const selectedLocations = Object.values(locations).filter(l =>
    selectedLocationIds.includes(l.id)
  )

  if (!user.companyName || !user.companyType) {
    return (
      <CompleteBusinessData user={user} redirectPath="/subscriptions/new" />
    )
  }

  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section mid row>
        <Section.Wrapper css={{ paddingTop: '2rem' }}>
          <Headline>
            <Typography tag="p" smaller>
              {sT('step')} 3/3
            </Typography>
            {sT('headline')}
          </Headline>
          <Form
            as="subscription"
            action="/subscriptions"
            method="post"
            css={{ flexDirection: 'column' }}
          >
            <Input
              name="plan_name"
              readOnly
              value={selectedPlanName || ''}
              type="hidden"
            />
            {selectedFlatrate && (
              <Input name="flatrate" readOnly value={true} type="hidden" />
            )}
            <SplitCard>
              <div>
                <Typography styleOverrides={{ paddingBottom: '1rem' }}>
                  <Trans id="pages.subscription_new.billing_headline" />
                </Typography>
                <ExplanationBox>
                  <Typography tag="h4" styleOverrides={{}}>
                    {sT('billing_explanation_headline')}
                  </Typography>
                  <Typography>{sT('billing_explanation_text')}</Typography>
                </ExplanationBox>
                <Typography tag="h3" styleOverrides={{ marginTop: '1rem' }}>
                  {sT('billing_information')}
                </Typography>
                <Input
                  name="selected_location_ids"
                  readOnly
                  required
                  value={
                    selectedFlatrate ? '[]' : selectedLocations.map(l => l.id)
                  }
                  type="hidden"
                />
                <Form.Field>
                  <Input
                    label={sT('billing_first_name')}
                    name="billing_first_name"
                    required
                    defaultValue={
                      subscription?.billingFirstName || user.firstName || ''
                    }
                    errors={errors?.billingFirstName}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={sT('billing_last_name')}
                    name="billing_last_name"
                    required
                    defaultValue={
                      subscription?.billingLastName || user.lastName || ''
                    }
                    errors={errors?.billingLastName}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={sT('billing_company_name')}
                    name="billing_company_name"
                    defaultValue={
                      subscription?.billingCompanyName ||
                      user.companyName ||
                      ''
                    }
                    errors={errors?.billingCompanyName}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={sT('billing_address_line_1')}
                    name="billing_address_line_1"
                    defaultValue={
                      subscription?.billingAddressLine1 ||
                      user.addressLine1 ||
                      ''
                    }
                    required
                    errors={errors?.billingAddressLine1}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={sT('billing_address_line_2')}
                    name="billing_address_line_2"
                    defaultValue={
                      subscription?.billingAddressLine2 ||
                      user.addressLine2 ||
                      ''
                    }
                    required
                    errors={errors?.billingAddressLine2}
                  />
                </Form.Field>
                <Form.Field>
                  <TextArea
                    label={sT('billing_additional_information')}
                    name="billing_additional_information"
                    placeholder={sT(
                      'billing_additional_information_placeholder'
                    )}
                    defaultValue={
                      subscription?.billingAdditionalInformation || ''
                    }
                    errors={errors?.billingAdditionalInformation}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    name="terms"
                    label={sT('terms')}
                    errors={errors?.terms}
                    required
                  />
                </Form.Field>
                <Form.Actions>
                  <Button css={{ width: '100%' }} type="submit">
                    {sT('submit')}
                  </Button>
                </Form.Actions>
              </div>
              <div>
                {selectedPlanName && (
                  <PlanSummary
                    allSelected={selectedFlatrate}
                    plan={plans[selectedPlanName]}
                    locations={selectedLocations}
                    editButtonLink="/location-select"
                    showTotal
                  />
                )}
              </div>
            </SplitCard>
          </Form>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.subscription_new.${key}`)

const ExplanationBox = styled.div`
  background: ${p => p.theme.chroma.lightGrey.hex()};
  padding: 1rem;
`

const SplitCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0;
  width: 100%;
  margin: 2rem auto 4rem;

  > * {
    min-height: 30rem;
    max-height: calc(100vh - 19rem);
    overflow-y: auto;
    padding: 2rem;
    display: flex;
    min-width: 400px;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      flex: 3;
    }

    &:last-child {
      flex: 2;
    }
  }
`

SubscriptionNew.propTypes = {
  user: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  plans: PropTypes.object.isRequired,
  selectedLocationIds: PropTypes.array,
  subscription: PropTypes.object,
  errors: PropTypes.object
}

export default SubscriptionNew
