import React from 'react'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'
import Testimonial from '../components/Testimonial'
import Section from '../components/Section'
import Carousel from '../components/Carousel'
import Card from '../components/Card'

const testimonialIds = [0, 1, 2]

function Testimonials() {
  return (
    <TestimonialsSection mid>
      <Section.Wrapper css={{ padding: '0 0 2rem' }}>
        <Carousel>
          {testimonialIds.map(id => (
            <SlideContainer key={id}>
              <TestimonialCard>
                <Testimonial
                  image={I18n.t(`pages.community.testimonial_${id}.image_url`)}
                  name={I18n.t(`pages.community.testimonial_${id}.name`)}
                >
                  <Trans id={`pages.community.testimonial_${id}.text`} copy />
                </Testimonial>
              </TestimonialCard>
            </SlideContainer>
          ))}
        </Carousel>
      </Section.Wrapper>
    </TestimonialsSection>
  )
}

const TestimonialsSection = styled(Section)`
  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 3.25rem;
  }
`

const SlideContainer = styled.div`
  padding: 1rem;
  outline: 0;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 2rem;
  }
`

const TestimonialCard = styled(Card)`
  width: 100%;
  padding: 1rem 1.75rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 2.8rem 4.6rem;
  }
`

export default Testimonials
