import styled from '@emotion/styled'

// The Icon Component is a helper around imported svg files.
//
// How to add a new Icon:
// 1. Add an icon as svg into `./assets`
// 2. Webpack will do all the optimisation for you ✨
// 3. Add the export into this file

export { default as Adult } from './assets/icons/adult.svg'
export { default as Child } from './assets/icons/child.svg'
export { default as CityScape } from './assets/icons/city.svg'
export { default as Copy } from './assets/icons/copy.svg'
export { default as Location } from './assets/icons/location.svg'
export { default as Graph } from './assets/icons/graph.svg'
export { default as QuotesStart } from './assets/icons/quotes-start.svg'
export { default as QuotesEnd } from './assets/icons/quotes-end.svg'
export { default as CavetUp } from './assets/icons/cavet-up.svg'
export { default as CavetLeft } from './assets/icons/cavet-left.svg'
export { default as CavetRight } from './assets/icons/cavet-right.svg'
export { default as DirectionRight } from './assets/icons/direction-right.svg'
export { default as DirectionLeft } from './assets/icons/direction-left.svg'
export { default as LocationGraph } from './assets/icons/location-graph.svg'
export { default as Download } from './assets/icons/download.svg'
export { default as DownloadNew } from './assets/icons/download-new.svg'
export { default as Edit } from './assets/icons/edit.svg'
export { default as AngleDown } from './assets/icons/angle-down.svg'
export { default as Star } from './assets/icons/star.svg'
export { default as StarOutline } from './assets/icons/star-outline.svg'
export { default as MagnifyingGlass } from './assets/icons/magnifying-glass.svg'
export { default as ArrowLeft } from './assets/icons/arrow-left.svg'
export { default as TimeRange } from './assets/icons/timerange.svg'
export { default as Average } from './assets/icons/average.svg'
export { default as Pin } from './assets/icons/pin.svg'
export { default as Calendar } from './assets/icons/calendar.svg'
export { default as GraphUpwards } from './assets/icons/graph-upwards.svg'
export { default as People } from './assets/icons/people.svg'
export { default as PersonFocus } from './assets/icons/person-focus.svg'
export { default as RealEstate } from './assets/icons/real-estate.svg'
export { default as Refresh } from './assets/icons/refresh.svg'
export { default as Road } from './assets/icons/road.svg'
export { default as Science } from './assets/icons/science.svg'
export { default as Store } from './assets/icons/store.svg'
export { default as TownCouncil } from './assets/icons/town-council.svg'
export { default as Close } from './assets/icons/close.svg'
export { default as Info } from './assets/icons/info.svg'
export { default as Info2 } from './assets/icons/infoWithoutOutline.svg'
export { default as Map } from './assets/icons/map.svg'
export { default as MapLight } from './assets/icons/map-light.svg'
export { default as Lightning } from './assets/icons/lightning.svg'
export { default as Christmas } from './assets/icons/christmas.svg'
export { default as Party } from './assets/icons/party.svg'
export { default as ManualEntry } from './assets/icons/manual-entry.svg'
export { default as Easter } from './assets/icons/easter.svg'
export { default as CloudFog } from './assets/icons/cloud-fog.svg'
export { default as CloudMoon } from './assets/icons/cloud-moon.svg'
export { default as CloudRain } from './assets/icons/cloud-rain.svg'
export { default as CloudSleet } from './assets/icons/cloud-sleet.svg'
export { default as CloudSun } from './assets/icons/cloud-sun.svg'
export { default as Cloud } from './assets/icons/cloud.svg'
export { default as Moon } from './assets/icons/moon.svg'
export { default as Snowflake } from './assets/icons/snowflake.svg'
export { default as Sun } from './assets/icons/sun.svg'
export { default as Wind } from './assets/icons/wind.svg'
export { default as Walking } from './assets/icons/walking.svg'
export { default as BigArrowLeft } from './assets/icons/pfeil_links.svg'
export { default as BigArrowRight } from './assets/icons/pfeil_rechts.svg'
export { default as Clock } from './assets/icons/clock.svg'
export { default as Directions } from './assets/icons/directions.svg'
export { default as Measurement } from './assets/icons/measurement.svg'
export { default as Height } from './assets/icons/height.svg'
export { default as Calendar2 } from './assets/icons/calendar2.svg'
export { default as Weather } from './assets/icons/weather.svg'
export { default as Zone } from './assets/icons/zone.svg'
export { default as Twitter } from './assets/icons/twitter.svg'
export { default as Flag } from './assets/icons/flag.svg'
export { default as Stakeholders } from './assets/icons/stakeholders.svg'
export { default as Transparency } from './assets/icons/transparency.svg'
export { default as ArrowFilledLeft } from './assets/icons/arrow-filled-left.svg'
export { default as ArrowFilledRight } from './assets/icons/arrow-filled-right.svg'
export { default as BarChart } from './assets/icons/bar-chart.svg'
export { default as Lines } from './assets/icons/lines.svg'
export { default as DirectionLeftRight } from './assets/icons/direction-left-right.svg'
export { default as Locations } from './assets/icons/locations.svg'
export { default as Zone2 } from './assets/icons/zone2.svg'
export { default as Report } from './assets/icons/report.svg'
export { default as PartnerNow } from './assets/icons/partner-now.svg'
export { default as ArrowUpards } from './assets/icons/arrow-upwards.svg'
export { default as MinusCircled } from './assets/icons/minus-circled.svg'
export { default as Checkmark } from './assets/icons/checkmark.svg'
export { default as Login } from './assets/icons/login.svg'
export { default as Logout } from './assets/icons/logout.svg'
export { default as Mail } from './assets/icons/mail.svg'
export { default as Phone } from './assets/icons/phone.svg'
export { default as PressMagnifyingGlass } from './assets/icons/press-magnifying-glass.svg'
export { default as PressDownload } from './assets/icons/press-download.svg'
export { default as Car } from './assets/icons/car.svg'
export { default as Tram } from './assets/icons/tram.svg'
export { default as Bus } from './assets/icons/bus.svg'
export { default as NeuesHystreet } from './assets/icons/hystreet_2_0.svg'

// Font Awesome Icons
export { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
export { faMap as FaMap } from '@fortawesome/pro-regular-svg-icons'
export { faFileArrowDown as FaFileArrowDown} from '@fortawesome/pro-regular-svg-icons'
export { faFileChartColumn as FaFileChartColumn} from '@fortawesome/pro-regular-svg-icons'
export { faCalendar as FaCalendar} from '@fortawesome/pro-regular-svg-icons'
export { faCalendarAlt as FaCalendarAlt} from '@fortawesome/pro-regular-svg-icons'
export { faCarBus as FaCarBus } from '@fortawesome/pro-solid-svg-icons'
export { faBusSimple as FaBusSimple } from '@fortawesome/pro-solid-svg-icons'
export { faTrainSubway as FaTrainSubway } from '@fortawesome/pro-solid-svg-icons'
export { faCar as FaCar } from '@fortawesome/pro-solid-svg-icons'
export { faBicycle as FaBicycle } from '@fortawesome/pro-solid-svg-icons'
export { faPersonWalking as FaPersonWalking } from '@fortawesome/pro-solid-svg-icons'
export { faPerson as FaPerson } from '@fortawesome/pro-solid-svg-icons'
export { faChevronUp as FaChevronUp } from '@fortawesome/pro-solid-svg-icons'
export { faChevronDown as FaChevronDown } from '@fortawesome/pro-solid-svg-icons'

export const Circled = styled.span`
  position: relative;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  background-color: ${props => props.theme.chroma.lightGreen.css()};
  color: ${props => props.theme.chroma.darkGreen.css()};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  svg {
    height: 10px;
  }
`
