import dayjs from 'dayjs'
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Button from '../components/Button'
import Typography from '../components/Typography'
import Section from '../components/Section'

import I18n from '../i18n'
import theme from '../theme'
import useProducts from '../lib/use-products'
import useMoney from '../lib/use-money'
import Tooltip from '../components/Tooltip'
import Headline from '../components/Headline'

function Plan({ plan, user, highlight }) {
  const { onSelectPlan, planButtonText } = useProducts({ user })
  const { toEuros } = useMoney()

  const sT = (key, args) =>
    I18n.t(`sections.products_table.${plan.name}.${key}`, args)

  return (
    <PlanBox highlight={highlight}>
      <div css={{ gridRow: 1 }}>
        {plan.discount && (
          <Discount>
            {((1 - plan.discountRate) * 100).toFixed(0)} %{' '}
            {I18n.t('common.discount')}
          </Discount>
        )}
      </div>
      <div css={{ gridRow: 2 }}>
        <Typography tag="h3" styleOverrides={{ marginBottom: '1rem' }}>
          {sT('headline')}
        </Typography>
        {sT('subline')}
      </div>
      <div css={{ gridRow: 3 }}>
        {plan.name === 'free' ? (
          <Typography tag="h4">{sT('free')}</Typography>
        ) : (
          <>
            <Tooltip hoverText={sT('price_tooltip')}>
              <Typography tag="h4" underline>
                {sT('from')}{' '}
                {toEuros((plan.yearlyPricesCents[0] / 12) * plan.discountRate)}
                {sT('perMonth')}
              </Typography>
            </Tooltip>
            <Typography tag="p">
              {sT('invoiced_as')}{' '}
              {plan.discount ? (
                <>
                  <Typography strikeThrough>
                    {toEuros(plan.yearlyPricesCents[0])}
                  </Typography>{' '}
                  <Typography bold>
                    {toEuros(plan.yearlyPricesCents[0] * plan.discountRate)}
                  </Typography>
                </>
              ) : (
                <Typography bold>
                  {toEuros(plan.yearlyPricesCents[0])}
                </Typography>
              )}
            </Typography>
          </>
        )}
      </div>
      <div css={{ gridRow: 4 }}>
        {plan.flatratePossible ? (
          <Typography tag="p" bold>
            {sT('all_locations')}:{' '}
            <Tooltip
              hoverText={sT('flatrate_tooltip', {
                monthly: toEuros(
                  (plan.yearlyPricesCents[plan.yearlyPricesCents.length - 1] /
                    12) *
                    plan.discountRate
                ),
                discounted_price: toEuros(
                  plan.yearlyPricesCents[plan.yearlyPricesCents.length - 1] *
                    plan.discountRate
                )
              })}
            >
              <Link onClick={() => onSelectPlan(plan.name, true)}>
                {sT('flatrate')}
              </Link>
            </Tooltip>
            *
          </Typography>
        ) : null}
      </div>
      <div css={{ gridRow: 5 }}>
        <Button
          onClick={() => onSelectPlan(plan.name)}
          css={{ width: '100%', padding: '0.875rem' }}
          centered
        >
          {planButtonText(plan.name)}
        </Button>
      </div>
      <div css={{ gridRow: 6, paddingTop: '1rem' }}>
        {sT('other_features_plus') && (
          <Typography tag="p" bold styleOverrides={{ marginBottom: '2em' }}>
            {sT('other_features_plus')}
          </Typography>
        )}
        <FeatureList>
          {sT('features')?.map(feature => (
            <li key={feature}>{feature}</li>
          ))}
        </FeatureList>
      </div>
    </PlanBox>
  )
}

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  user: PropTypes.object,
  highlight: PropTypes.bool
}

function ProductsTable({ user, plans, withLink = false }) {
  const sT = (key, args) => I18n.t(`sections.products_table.${key}`, args)

  const discounts = Object.values(plans)
    .map(plan => plan.discount)
    .filter(d => d)
  // looks super complex but it's basicall just a js way to do `discounts.uniqueBy(&:id)`
  const uniqueDiscounts = discounts
    .map(({ id }) => id)
    .filter((id, i, arr) => arr.indexOf(id) === i)
    .map(id => discounts.find(discount => discount.id === id))

  return (
    <Section mid css={{ paddingTop: '4rem', paddingBottom: '5rem' }}>
      <Section.Wrapper>
        <Headline level={1} breakout> {sT('headline')} </Headline>
        <Typography tag="h3" styleOverrides={{ marginBottom: '2rem' }}>
          {sT('subline')}
        </Typography>
        <PlansTable>
          <Plan plan={plans.free} user={user} />
          <Plan plan={plans.basic} user={user} />
          <Plan plan={plans.premium} user={user} highlight />
          <Plan plan={plans.professional} user={user} />
        </PlansTable>
        <Typography
          tag="p"
          styleOverrides={{ marginTop: '1rem', textAlign: 'center' }}
        >
          {sT('vat_info')}
          {uniqueDiscounts.length > 0 &&
            sT('discount_info', {
              date: dayjs(uniqueDiscounts[0].validUntil).format('D.M.YYYY')
            })}
        </Typography>
        {withLink && (
          <>
            <Typography
              tag="h3"
              styleOverrides={{
                marginTop: '4rem',
                marginBottom: '2rem',
                textAlign: 'center'
              }}
            >
              {sT('link_headline')}
            </Typography>
            <div
              css={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              <Button.Link outline href="/products">
                {sT('link')}
              </Button.Link>
            </div>
          </>
        )}
      </Section.Wrapper>
    </Section>
  )
}

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`

const Discount = styled.span`
  background-color: ${theme.chroma.dustyBlue.hex()};
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
`

const FeatureList = styled.ul`
  li {
    display: flex;
    margin-bottom: 0.5rem;
    gap: 1rem;

    &:before {
      content: '✓';
    }
  }
`

const PlanBox = styled.li`
  background-color: ${props => props.theme.chroma.white.hex()};
  padding: 3rem 1rem;
  display: grid;
  grid-row: 1 / 7;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-gap: 1.5rem;

  margin-top: 2rem;

  ${props =>
    props.highlight &&
    `
      border: 3px solid ${props.theme.chroma.base.hex()};
      border-top: 0;
      position: relative;
      box-sizing: border-box;

      &::before {
        content: '${I18n.t('sections.products_table.highlight')}';
        position: absolute;
        text-align: center;
        font-weight: bold;
        display: flex;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        top: -2rem;
        left: -3px;
        right: -3px;
        height: 2rem;
        border-radius: 0.3rem 0.3rem 0 0;
        color: ${props.theme.chroma.white.hex()};
        background-color: ${props.theme.chroma.base.hex()};
      }
    `}
`

const PlansTable = styled.ul`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(7, auto);

  @media (max-width: 1000px) {
    display: block;
  }
`

ProductsTable.propTypes = {
  plans: PropTypes.object.isRequired,
  user: PropTypes.object,
  withLink: PropTypes.bool
}

export default ProductsTable
