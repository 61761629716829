import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Close from './assets/icons/close.svg'

function InfoBanner({ children, cookieName, style }) {
  const [cookies, setCookie] = useCookies([cookieName])
  const [hidden, setHidden] = useState(true)
  function setInvisible() {
    setCookie(cookieName, true)
    setHidden(true)
  }

  useEffect(() => {
    setHidden(cookies[cookieName])
  }, [cookies, cookieName])

  if (hidden) {
    return null
  }

  return (
    <Banner style={style}>
      <ContentWrapper>{children}</ContentWrapper>
      <CloseButtonWrapper>
        <CustomClose onClick={setInvisible} />
      </CloseButtonWrapper>
    </Banner>
  )
}

InfoBanner.propTypes = {
  children: PropTypes.node.isRequired,
  cookieName: PropTypes.string.isRequired,
  style: PropTypes.object
}

const Banner = styled.div`
  display: flex;
  flex: auto 1;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${p => p.theme.chroma.slate.css()};
  border: 1px;
  border-style: solid;
  border-color: ${p => p.theme.chroma.base.css()};
  border-radius: 2px;
  padding: 0.5rem;
  color: ${p => p.theme.chroma.white.css()};
`
const ContentWrapper = styled.div`
  margin: 1rem;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${p => p.theme.breakpoints.phone} {
    width: 100%;
    flex-direction: column;
  }
`

const CloseButtonWrapper = styled.div`
  right: 0;
  top: 0;
`
const CustomClose = styled(Close)`
  color: ${p => p.theme.chroma.base.css()};
  border: 1px solid;
  border-radius: 2rem;
  border-color: ${p => p.theme.chroma.base.css()};
  cursor: pointer;
  background: ${p => p.theme.chroma.white.css()};
`

export default InfoBanner
