import I18n from 'i18n-js'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import adult_background from '../../../assets/images/adult_background.png'
import child_background from '../../../assets/images/child_background.png'
import {
  AbsolutePedestrianCountLabel,
  ComparisonRow,
  ItemContainer,
  PercentageOfTotalPedestriansLabel
} from './LocationStatsCardItemBase'
import { NoDataPresentStatsCardItem } from './NoDataPresentStatsCardItem'

const ChildOrAdultChartContainer = styled.div(props => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: props.rtl ? 'flex-end' : 'flex-start',
    textAlign: props.rtl ? 'right' : 'left',
    padding: '0px 12px',
    minWidth: '50%',
    whiteSpace: 'nowrap'
  }
})

const ChildOrAdultLabel = styled.p(props => {
  return {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    paddingBottom: '1em',
    paddingTop: 8,
    overflow: 'hidden',
    fontSize: '14px'
  }
})

const BarChartContainer = styled.div(() => {
  return {
    height: 102,
    width: 44,
    position: 'relative'
  }
})

const BarChartBackground = styled.div(props => {
  return {
    width: '100%',
    height: '100%',
    backgroundColor: props.theme.chroma.lightGrey.css()
  }
})

const BarChartBackgroundImage = styled.div(props => {
  return {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 96%',
    backgroundSize: props.small ? '60%' : '90%'
  }
})

const BarChartFill = styled.div(props => {
  return {
    width: '100%',
    height: `${props.percentage}%`,
    backgroundColor: '#1778DA',
    position: 'absolute',
    top: `${100 - props.percentage}%`,
    mixBlendMode: 'multiply'
  }
})

export function ChildOrAdultStatsCardItem({
  absoluteAdults,
  percentageOfAdults,
  absoluteChildren,
  percentageOfChildren,
  displayNoDataPresentPlaceholder = false,
  displayDataIncompleteNotice = false
}) {
  if (displayNoDataPresentPlaceholder || displayDataIncompleteNotice) {
    const message = displayDataIncompleteNotice
      ? I18n.t('location.details.data_incomplete')
      : I18n.t('location.details.data_missing')

    const neighbouringCardsHeight = 229

    return (
      <NoDataPresentStatsCardItem
        message={message}
        variant={'cardHeader'}
        minHeight={neighbouringCardsHeight}
      ></NoDataPresentStatsCardItem>
    )
  }

  return (
    <ItemContainer hideBorder fullWidth>
      <ComparisonRow>
        <ChildOrAdultChartContainer rtl>
          <ChildOrAdultLabel>
            {I18n.t('location.details.adults')}{' '}
          </ChildOrAdultLabel>
          <BarChartContainer>
            <BarChartBackground>
              <BarChartBackgroundImage
                backgroundImage={adult_background}
              ></BarChartBackgroundImage>
              <BarChartFill percentage={percentageOfAdults}></BarChartFill>
            </BarChartBackground>
          </BarChartContainer>
          <div css={{ paddingTop: 5, marginBottom: 22 }}>
            <PercentageOfTotalPedestriansLabel color={'#165AAD'}>
              {`${I18n.toNumber(percentageOfAdults, { precision: 0 })}%`}
            </PercentageOfTotalPedestriansLabel>

            <AbsolutePedestrianCountLabel color={'#165AAD'}>
              {`${I18n.toNumber(absoluteAdults, { precision: 0 })}`}
            </AbsolutePedestrianCountLabel>
          </div>
        </ChildOrAdultChartContainer>

        <ChildOrAdultChartContainer>
          <ChildOrAdultLabel>
            {I18n.t('location.details.children')}
          </ChildOrAdultLabel>
          <BarChartContainer>
            <BarChartBackground>
              <BarChartBackgroundImage
                backgroundImage={child_background}
                small
              />
              <BarChartFill percentage={percentageOfChildren}></BarChartFill>
            </BarChartBackground>
          </BarChartContainer>
          <div css={{ paddingTop: 5, marginBottom: 22 }}>
            <PercentageOfTotalPedestriansLabel color={'#165AAD'}>
              {`${I18n.toNumber(percentageOfChildren, { precision: 0 })}%`}
            </PercentageOfTotalPedestriansLabel>

            <AbsolutePedestrianCountLabel color={'#165AAD'}>
              {`${I18n.toNumber(absoluteChildren, { precision: 0 })}`}
            </AbsolutePedestrianCountLabel>
          </div>
        </ChildOrAdultChartContainer>
      </ComparisonRow>
    </ItemContainer>
  )
}

ChildOrAdultStatsCardItem.propTypes = {
  absoluteAdults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentageOfAdults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  absoluteChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentageOfChildren: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  displayNoDataPresentPlaceholder: PropTypes.bool,
  displayDataIncompleteNotice: PropTypes.bool
}
