import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import Headline from '../Headline'
import DirectionLegend from './DirectionLegend'
import Modal from './Modal'
import StreetMap from './StreetMap'

function MapModal({ isOpen, onRequestClose, map, isDirectionActive }) {
  const { location, directions } = useLocation()

  const [filter] = useFilter()
  const globalIsDirectionActive =
    filter.viewType === 'details' && filter.detailsType === 'direction'

  const mapShallDisplayWalkingDirection = isDirectionActive
    ? isDirectionActive
    : globalIsDirectionActive

  const rotation = isDirectionActive ? map?.rotation : 0

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} showCloseButton>
      <SpacingBox>
        <TopBar>
          <div css={{ flex: '1 1 auto' }}>
            <Headline>
              {location.name}
              <small>{location.city}</small>
            </Headline>
          </div>
          {mapShallDisplayWalkingDirection && (
            <DirectionsBox>
              <DirectionLegend
                ltr={directions.ltr.label}
                rtl={directions.rtl.label}
              />
            </DirectionsBox>
          )}
        </TopBar>
        <div
          css={{
            display: 'flex',
            flex: '1 0 auto',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            position: 'relative'
          }}
        >
          <StreetMap {...map} rotation={rotation} />
        </div>
      </SpacingBox>
    </Modal>
  )
}

MapModal.propTypes = {
  isDirectionActive: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  map: PropTypes.object.isRequired
}

const SpacingBox = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',

  [props.theme.breakpoints.tabletUp]: {
    margin: '1rem'
  }
}))

const TopBar = styled.div(props => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '-3.5rem',
  marginBottom: '0.5rem',
  marginRight: '3rem',
  alignItems: 'flex-start',

  [props.theme.breakpoints.tablet]: {
    marginTop: '-2rem',
    flexDirection: 'column'
  }
}))

const DirectionsBox = styled.div(props => ({
  marginRight: '0.5rem',
  paddingRight: '1rem',
  flex: '0 0 auto',
  borderRight: '2px solid',
  borderRightColor: props.theme.chroma.lightGrey.css(),

  [props.theme.breakpoints.tablet]: {
    border: 'none'
  }
}))

export default MapModal
