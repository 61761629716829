import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Dropdown({ dropdownLinks, isActive, link, name }) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <ContentWrapper>
      <DesktopNavigationLink isActive={isActive} href={link}>
        {name}
      </DesktopNavigationLink>
      <MobileDropdownToggle
        isOpen={isOpen}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {name}
      </MobileDropdownToggle>
      <List isOpen={isOpen}>
        {dropdownLinks.map((item, index) => {
          return (
            <SubMenuItem key={index}>
              <a href={item.link}>{item.name}</a>
            </SubMenuItem>
          )
        })}
      </List>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  font-weight: bold;

  ${p => p.theme.breakpoints.desktop} {
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 0.8rem;
    &:hover {
      background-color: ${p => p.theme.chroma.nearlyWhite.css()};

      & ul {
        display: block;
      }
    }
  }
`

const MobileDropdownToggle = styled.button`
  ${p => p.theme.breakpoints.desktopUp} {
    display: none;
  }

  color: ${p => p.theme.chroma.base.hex()};
  display: block;
  padding: 1.75rem 0.75rem;
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
  border: none;
`

const List = styled.ul`
  ${p => !p.isOpen && `display: none;`}

  ${p => p.theme.breakpoints.desktop} {
    width: 100%;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    width: 10rem;
    background-color: ${p => p.theme.chroma.nearlyWhite.css()};
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 0.2);
    position: absolute;
  }
`

const DesktopNavigationLink = styled.a`
  ${p => p.theme.breakpoints.desktop} {
    display: none;
  }

  display: block;
  padding: 1rem;
  color: ${p =>
    p.isActive ? p.theme.chroma.accent.hex() : p.theme.chroma.base.hex()};

  padding: 1.75rem 0.75rem;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.chroma.accent.hex()};
  }
`

const SubMenuItem = styled.li`
  display: block;
  color: ${p => p.theme.chroma.base.hex()};

  ${p => p.theme.breakpoints.desktop} {
    padding: 1.75rem 0.75rem;
    font-weight: normal;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    cursor: pointer;
    &:hover {
      background-color: ${p => p.theme.chroma.white.hex()};
      color: ${p => p.theme.chroma.accent.hex()};
    }

    a {
      display: block;
      padding: 0.75rem;
    }
  }
`

Dropdown.propTypes = {
  dropdownLinks: PropTypes.array,
  isActive: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string
}

export default Dropdown
