import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import centroid from '@turf/centroid'
import * as OpenLayers from '../OpenLayers'

function StreetMap(props) {
  const rotation = props.rotation || 0
  const zoom = props.zoom || 17
  const center = React.useMemo(() => {
    return props.center || calculateCenterFromGeojson(props.geojson)
  }, [props.center, props.geojson])
  const geojson = props.geojson

  return (
    <StyleOverrides>
      <OpenLayers.Base
        view={{ center, zoom, rotation }}
        css={{ flex: '1 1 auto' }}
      >
        <OpenLayers.Vector geometry={geojson} />
      </OpenLayers.Base>
    </StyleOverrides>
  )
}

StreetMap.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  rotation: PropTypes.number,
  geojson: PropTypes.object.isRequired
}

const StyleOverrides = styled.div(props => ({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',

  '.ol-attribution': {
    fontSize: '0.75rem',
    color: props.theme.chroma.pencil.css(),
    borderRadius: 0
  }
}))

function recursiveNumberify(coords) {
  if (Array.isArray(coords)) return coords.map(recursiveNumberify)
  return +coords
}

function calculateCenterFromGeojson(geojson) {
  // coordinates sometimes contains strings (due to rails' BigDecimal),
  // but we need numbers
  const numberifiedGeojson = {
    ...geojson,
    coordinates: geojson.coordinates.map(recursiveNumberify)
  }

  const center = centroid(numberifiedGeojson)
  return center.geometry.coordinates
}

export default StreetMap
