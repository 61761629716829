import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n from '../i18n'

import Button from '../components/Button'
import Section from '../components/Section'

function FooterRegister({ locationsCount }) {
  return (
    <Section mid>
      <WrapperSection>
        <Text>
          {I18n.t('footer.register_text', {
            locations_count: locationsCount
          })}
        </Text>
        <Button.Link href="/products" css={{ width: 'fit-content' }}>
          {I18n.t('footer.to_the_products')}
        </Button.Link>
      </WrapperSection>
    </Section>
  )
}

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.575;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin-bottom: 0.75rem;
`

const WrapperSection = styled(Section.Wrapper)`
  width: fit-content;
  padding: 2rem 0 2.5rem;
  align-items: center;
`

FooterRegister.propTypes = {
  locationsCount: PropTypes.number.isRequired
}

export default FooterRegister
