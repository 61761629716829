import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 100%;
  min-width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;

  @media (min-width: 978px) {
    min-width: 48%;
    max-width: 48%;
  }

  @media (min-width: 1400px) {
    min-width: 24%;
    max-width: 24%;
  }
`

const HeadlineContainer = styled.div(props => ({
  width: '100%',
  backgroundColor: props.theme.chroma.base.css(),
  maxHeight: 28
}))

const Headline = styled.h4(props => ({
  color: props.theme.chroma.white.css(),
  textAlign: 'center',
  fontSize: 14,
  lineHeight: '1.5rem'
}))

const Message = styled.p(props => ({
  marginTop: 25,
  marginBottom: 25,
  fontSize: 14,
  lineHeight: props.theme.fontSizes.l.lineHeight,
  color: '#979797',
  fontWeight: props.bold ? 'bold' : 'normal'
}))
export default function LocationStatsCard({
  headline,
  message = '',
  children
}) {
  return (
    <CardContainer>
      <HeadlineContainer>
        <Headline>{headline}</Headline>
      </HeadlineContainer>
      {message && <Message>{message}</Message>}
      {children}
    </CardContainer>
  )
}

LocationStatsCard.propTypes = {
  headline: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export const statsObjectPropTypes = PropTypes.shape({
  pedestriansCount: PropTypes.number,
  adultLtrPedestriansCount: PropTypes.number,
  adultPedestriansCount: PropTypes.number,
  adultRtlPedestriansCount: PropTypes.number,
  childLtrPedestriansCount: PropTypes.number,
  childPedestriansCount: PropTypes.number,
  childRtlPedestriansCount: PropTypes.number,
  incidents: PropTypes.arrayOf(PropTypes.object),
  ltrPedestriansCount: PropTypes.number,
  rtlPedestriansCount: PropTypes.number,
  measurementsTakenIntoAccount: PropTypes.arrayOf(PropTypes.object),
  metadata: PropTypes.shape({
    earliestMeasurementAt: PropTypes.string,
    latestMeasurementAt: PropTypes.string,
    ltrLabel: PropTypes.string,
    measuredFrom: PropTypes.string,
    measuredTo: PropTypes.string,
    minTemperature: PropTypes.number,
    rtlLabel: PropTypes.string,
    temperature: PropTypes.number,
    weatherCondition: PropTypes.string
  }),
  dataIncompleteForAskedTimerange: PropTypes.bool,
  noDataPresentForAskedTimerange: PropTypes.bool,
  timerangeEnd: PropTypes.string,
  timerangeStart: PropTypes.string,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      pedestriansCount: PropTypes.number,
      ltrPedestriansCount: PropTypes.number,
      rtlPedestriansCount: PropTypes.number
    })
  )
})

export {
  ChildOrAdultStatsCardItem,
  PedestrianCountComparisonStatsCardItem,
  PedestrianCountStatsCardItem,
  PercentagePerZoneStatsCardItem,
  WalkingDirectionComparisonStatsCardItem,
  WalkingDirectionStatsCardItem
} from './LocationStatsCardItems/LocationStatsCardItemBase'
