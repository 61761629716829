import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import NukaCarousel from 'nuka-carousel'
import Logo1 from '../../assets/images/partner_logos/Aachener_Grundvermoegen.jpg'
import Logo2 from '../../assets/images/partner_logos/BMOREP_Partner_Logo.jpg'
import Logo3 from '../../assets/images/partner_logos/Bocholt_Partner_Logo.jpg'
import Logo4 from '../../assets/images/partner_logos/Braunschweig_Partner_Logo.png'
import Logo5 from '../../assets/images/partner_logos/Duesseldorf_Partner_Logo.png'
import Logo6 from '../../assets/images/partner_logos/Krefeld_Partner_Logo.png'
import Logo7 from '../../assets/images/partner_logos/Muenster_Partner_Logo.jpg'
import Logo8 from '../../assets/images/partner_logos/Osnabrueck_Partner_Logo_RGB.jpg'
import Logo9 from '../../assets/images/partner_logos/Bochum_Logo_IBO.jpg'
import Logo10 from '../../assets/images/partner_logos/Frankfurt_WFF_Logo.png'
import Logo11 from '../../assets/images/partner_logos/Biberach_250x250px.jpg'
import Logo12 from '../../assets/images/partner_logos/Biberach_biberCard_Logo.jpg'
import Logo13 from '../../assets/images/partner_logos/kiel-marketing_Partner_Logo.jpg'
import Logo14 from '../../assets/images/partner_logos/Stadtmarketing-Schleswig.jpg'
import { BigArrowLeft, BigArrowRight } from '../components/Icon'
import Section from '../components/Section'
import { Trans } from '../i18n'

const logos = [
  { src: Logo1, alt: 'Aachener Grundvermögen' },
  { src: Logo2, alt: 'BMO Real Estate Partners' },
  { src: Logo3, alt: 'Stadtmarketing Bocholt' },
  { src: Logo4, alt: 'Braunschweig Stadtmarketing' },
  { src: Logo5, alt: 'Forum Stadt-Marketing Düsseldorf' },
  { src: Logo6, alt: 'Stadt Krefeld' },
  { src: Logo7, alt: 'Wirtschaftsförderung Münster GmbH' },
  { src: Logo8, alt: 'Marketing Osnabrück' },
  { src: Logo9, alt: 'Initiative Bochumer City' },
  { src: Logo10, alt: 'Wirtschaftsförderung Frankfurt' },
  { src: Logo11, alt: 'Stadt Biberach' },
  { src: Logo12, alt: 'biberCard' },
  { src: Logo13, alt: 'Kiel-Marketing' },
  { src: Logo14, alt: 'Wikingerstadt Schleswig Stadtmarketing' }
]

function PartnerLogos() {
  const [slidesToShow, setSlidesToShow] = useState(5)

  useEffect(() => {
    function changeSlidesToShow() {
      if (window.innerWidth > 991) {
        setSlidesToShow(5)
        return
      }
      if (window.innerWidth > 767) {
        setSlidesToShow(3)
        return
      }
      if (window.innerWidth > 479) {
        setSlidesToShow(2)
        return
      }
      setSlidesToShow(1)
    }
    changeSlidesToShow()

    window.addEventListener('resize', changeSlidesToShow)
    return () => window.removeEventListener('resize', changeSlidesToShow)
  }, [setSlidesToShow])

  return (
    <Section css={{ padding: '1rem 0' }}>
      <Headline css={{ marginBottom: '2rem' }}>
        <Trans id="partner_page.partner_logos.heading" />
      </Headline>
      <CarouselStyles>
        <NukaCarousel
          autoplay={true}
          wrapAround={true}
          cellSpacing={80}
          defaultControlsConfig={{
            pagingDotsStyle: {
              display: 'none'
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <button
              onClick={previousSlide}
              className={currentSlide < 1 ? 'disabled' : ''}
            >
              <BigArrowLeft />
            </button>
          )}
          renderCenterRightControls={({
            nextSlide,
            slideCount,
            currentSlide
          }) => (
            <button
              onClick={nextSlide}
              className={
                currentSlide === slideCount - slidesToShow ? 'disabled' : ''
              }
            >
              <BigArrowRight />
            </button>
          )}
          slidesToShow={slidesToShow}
        >
          {logos.map((logo, i) => (
            <LogoBox key={i}>
              <img src={logo.src} alt={logo.alt} />
            </LogoBox>
          ))}
        </NukaCarousel>
      </CarouselStyles>
    </Section>
  )
}

const Headline = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  text-align: center;
`

const LogoBox = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`

const CarouselStyles = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  .slider {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
  }

  .slider-frame {
    flex: 1 0 auto;
    order: 2;
    width: calc(100% - 11rem);

    ${p => p.theme.breakpoints.tablet} {
      width: 100%;
    }
  }

  .slider-control-centerright,
  .slider-control-centerleft {
    flex: 0 0 auto;
    order: 3;
    position: static !important;
    transform: none !important;

    button {
      padding: 1rem;
      width: 5.5rem;
      cursor: pointer;
      outline: none;

      &:focus {
        color: ${p => p.theme.chroma.accent.css()};
      }
    }

    button.disabled {
      opacity: 0.3;
    }

    ${p => p.theme.breakpoints.tablet} {
      display: none;
    }
  }

  .slider-control-centerleft {
    order: 1 !important;
  }
`

export default PartnerLogos
