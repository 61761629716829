import styled from '@emotion/styled'

const Headline = styled.h2`
  position: relative;
  font-size: 1.1rem;
  letter-spacing: 0.75px;
  font-weight: 900;
  text-transform: uppercase;
  padding-bottom:  ${p => (p.centered ? '0' : '0.9rem')};
  padding-left: ${p => (p.breakout ? '0' : '1.25rem')};

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }

  ${p =>
    p.center &&
    `
      margin: 0 auto;
      font-size: 1.5rem;
      ${p.theme.breakpoints.tabletUp} {
        font-size: 2.25rem;
      }
    `}

  &:before {
    content: '';
    position: absolute;
    height:  ${p => (p.centered ? '100%' : 'calc(100% - 0.9rem)')};
    width: 4px;
    background-color: ${p => p.theme.chroma.accent.hex()};
    left: ${p => (p.breakout ? '-1.25rem' : '0')};
    -webkit-print-color-adjust: exact; // always print the background-color
  }

  ${p =>
    p.level > 1 &&
    `
      &:before {
        display: none;
      }
    `}

  ${p =>
    p.level === 2 &&
    `
      font-size: 1rem;

      ${p.theme.breakpoints.tabletUp} {
        font-size: 1.3rem;
      }
    `}

  ${p =>
    p.level === 3 &&
    `
      font-size: 0.9rem;

      ${p.theme.breakpoints.tabletUp} {
        font-size: 1.1rem;
      }
    `}

  ${p =>
    p.level === 4 &&
    `
      font-size: 0.8rem;

      ${p.theme.breakpoints.tabletUp} {
        font-size: 1rem;
      }
    `}

  small {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-top: 0.25rem;
  }
`

export default Headline
