import chroma from 'chroma-js'

const theme = {
  chroma: {
    base: chroma('#183e62'),
    accent: chroma('#e73c58'),
    slate: chroma('#a9B9cb'),
    lightBlue: chroma('#0078ec'),
    dustyBlue: chroma('#d0dae6'),
    greyBlue: chroma('#9FB0C3'),
    white: chroma('#ffffff'),
    nearlyWhite: chroma('#f4f5f8'),
    lightGrey: chroma('#eeeeee'),
    lighterGrey: chroma('#e6e6e6'),
    lightRed: chroma('#ffe5ea'),
    grey: chroma('#979797'),
    coal: chroma('#374450'),
    pencil: chroma('#767778'),
    good: chroma('#689F38'),
    bad: chroma('#EF6C00'),
    transparent: chroma('#FFFFFF00'),
    darkerGrey: chroma('#8095A9'),
    lightGreen: chroma('#D5E7D3'),
    darkGreen: chroma('#2B8923'),
    map: {
      stateborder: chroma('#FF8397'),
      countryborder: chroma('#e73c58')
    },
    chartAreas: [
      {
        fill: chroma('#1486E2'),
        hatch: chroma('#1179DE'),
        text: chroma('#0566C5')
      },
      {
        fill: chroma('#4BAEFF'),
        hatch: chroma('#40A2FF'),
        text: chroma('#4BAEFF')
      }
    ],
    zones: [
      {
        fill: chroma('#1486E2')
      },
      {
        fill: chroma('#183E62'),
        fillComplement: chroma('#2AA5D8')
      },
      {
        fill: chroma('#E73C58'),
        fillComplement: chroma('#FF8397')
      },
      {
        fill: chroma('#7A8898'),
        fillComplement: chroma('#AEBFD2')
      },
      {
        fill: chroma('#95A593'),
        fillComplement: chroma('#C8D8C6')
      },
      {
        fill: chroma('#837775'),
        fillComplement: chroma('#C1A9A4')
      }
    ],

    KPICharts: [
      {
        fill: chroma('#183E62'),
        fillText: chroma('#FFFFFF'),
        fillComplement: chroma('#2AA5D8'),
        fillComplementText: chroma('#FFFFFF')
      },

      {
        fill: chroma('#E73C58'),
        fillText: chroma('#FFFFFF'),
        fillComplement: chroma('#FF8397'),
        fillComplementText: chroma('#FFFFFF')
      },

      {
        fill: chroma('#7A8898'),
        fillText: chroma('#FFFFFF'),
        fillComplement: chroma('#AEBFD2'),
        fillComplementText: chroma('#FFFFFF')
      },

      {
        fill: chroma('#95A593'),
        fillText: chroma('#FFFFFF'),
        fillComplement: chroma('#C8D8C6'),
        fillComplementText: chroma('#FFFFFF')
      },

      {
        fill: chroma('#F48530'),
        fillText: chroma('#FFFFFF'),
        fillComplement: chroma('#F7A53D'),
        fillComplementText: chroma('#FFFFFF')
      },
      {
        fill: chroma('#40257B'),
        fillText: chroma('#C6BED7'),
        fillComplement: chroma('#9D74CD'),
        fillComplementText: chroma('#E2D5F0')
      }
    ]
  },

  breakpoints: {
    phone: '@media (max-width: 479px)',
    phoneUp: '@media (min-width: 480px)',
    tablet: '@media (max-width: 767px)',
    tabletUp: '@media (min-width: 768px)',
    desktop: '@media (max-width: 991px)',
    desktopUp: '@media (min-width: 992px)'
  },

  fonts: {
    base: "'Lato', sans-serif"
  },

  fontSizes: {
    xxl: {
      fontSize: '1.875em', // 30px
      lineHeight: '2em' // 32px
    },
    xl: {
      fontSize: '1.5625em', // 25px
      lineHeight: '1.5625em' // 25px
    },
    l: {
      fontSize: '1.375em', // 22px
      lineHeight: '1.5625em' // 25px
    },
    m: {
      fontSize: '1.1125em', // 18px
      lineHeight: '1.5625em' // 25px
    },
    s: {
      fontSize: '0.9375em', // 15px
      lineHeight: '1.5625em' // 25px
    },
    xs: {
      fontSize: '0.875em', // 14px
      lineHeight: '1.5625em' // 25px
    },
    xxs: {
      fontSize: '0.75em', // 12px
      lineHeight: '1.5625em' // 25px
    }
  }
}

export default theme
