import React from 'react'
import styled from '@emotion/styled'
import MissionQuote from '../components/MissionQuote'
import Section from '../components/Section'

function MissionQuoteForMobile() {
  return (
    <CustomSection css={{ marginTop: '4rem' }}>
      <Section.Wrapper>
        <MissionQuote />
      </Section.Wrapper>
    </CustomSection>
  )
}

const CustomSection = styled(Section)`
  margin-top: 4rem;

  ${p => p.theme.breakpoints.phoneUp} {
    display: none;
  }
`

export default MissionQuoteForMobile
