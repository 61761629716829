import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import I18n, { Trans } from '../i18n'
import AlertBox from '../components/AlertBox'
import Section from '../components/Section'
import Headline from '../components/Headline'

function PasswordResetSent({ flash = {} }) {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Section.Wrapper
        css={{ maxWidth: '32rem', marginTop: '3rem', marginBottom: '3rem' }}
      >
        <Helmet>
          <title>{I18n.t('reset_password.sent.headline')}</title>
        </Helmet>
        <Headline>
          <Trans id="reset_password.sent.headline" />
        </Headline>
        {flash.notice && (
          <AlertBox css={{ marginTop: '3rem' }}>{flash.notice}</AlertBox>
        )}
      </Section.Wrapper>
    </Section>
  )
}

PasswordResetSent.propTypes = {
  flash: PropTypes.shape({
    notice: PropTypes.string
  })
}

export default PasswordResetSent
