import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'
import { internationalizedPercentageOrBlank } from '../../../../lib/calculate-location-statistics'
import {
  AbsolutePedestrianCountLabel,
  ComparisonRow,
  ItemContainer,
  PedestrianCountDifferenceInPercentagesLabel,
  TimestampLabel,
  TimestampRow,
  WalkingDirectionContainer
} from './LocationStatsCardItemBase'
import { NoDataPresentStatsCardItem } from './NoDataPresentStatsCardItem'

const PercentagesRow = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: props.rtl ? 'flex-end' : 'flex-start',
  alignItems: 'center',
  width: '100%'
}))

const AbsoluteNumbersRow = styled.div(props => ({
  display: 'flex',
  flexDirection: props.rtl ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export function WalkingDirectionComparisonStatsCardItem({
  timestamp,
  walkingDirectionReading1,
  walkingDirectionReading2,
  displayNoDataPresentPlaceholder = false,
  displayDataIncompleteNotice = false
}) {
  if (displayNoDataPresentPlaceholder || displayDataIncompleteNotice) {
    const message = displayDataIncompleteNotice
      ? I18n.t('location.details.data_incomplete')
      : I18n.t('location.details.data_missing')

    const neighbouringCardsHeight = 100

    return (
      <NoDataPresentStatsCardItem
        message={message}
        variant={'cardItem'}
        minHeight={neighbouringCardsHeight}
      ></NoDataPresentStatsCardItem>
    )
  }

  return (
    <ItemContainer fullWidth>
      <TimestampRow>
        <TimestampLabel>{timestamp}</TimestampLabel>
      </TimestampRow>

      <ComparisonRow>
        <WalkingDirectionContainer>
          <PercentagesRow rtl>
            <PedestrianCountDifferenceInPercentagesLabel
              percentage={
                walkingDirectionReading1.pedestrianCountDifferenceInPercentages
              }
            >
              {`${internationalizedPercentageOrBlank(
                walkingDirectionReading1.pedestrianCountDifferenceInPercentages,
                0,
                8
              )}`}
            </PedestrianCountDifferenceInPercentagesLabel>
          </PercentagesRow>

          <AbsoluteNumbersRow>
            <AbsolutePedestrianCountLabel small>
              {walkingDirectionReading1.pedestrianCountDifferenceInAbsoluteNumbers >
              0
                ? `+${I18n.toNumber(
                    walkingDirectionReading1.pedestrianCountDifferenceInAbsoluteNumbers,
                    { precision: 0 }
                  )}`
                : `${I18n.toNumber(
                    walkingDirectionReading1.pedestrianCountDifferenceInAbsoluteNumbers,
                    { precision: 0 }
                  )}`}
            </AbsolutePedestrianCountLabel>

            <AbsolutePedestrianCountLabel>
              {I18n.toNumber(
                walkingDirectionReading1.numberOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}
            </AbsolutePedestrianCountLabel>
          </AbsoluteNumbersRow>
        </WalkingDirectionContainer>

        <div css={{ marginLeft: 12, marginRight: 12 }}></div>
        <WalkingDirectionContainer>
          <PercentagesRow>
            <PedestrianCountDifferenceInPercentagesLabel
              percentage={
                walkingDirectionReading2.pedestrianCountDifferenceInPercentages
              }
            >
              {`${internationalizedPercentageOrBlank(
                walkingDirectionReading2.pedestrianCountDifferenceInPercentages,
                0,
                8
              )}`}
            </PedestrianCountDifferenceInPercentagesLabel>
          </PercentagesRow>

          <AbsoluteNumbersRow rtl>
            <AbsolutePedestrianCountLabel small>
              {walkingDirectionReading2.pedestrianCountDifferenceInAbsoluteNumbers >
              0
                ? `+${I18n.toNumber(
                    walkingDirectionReading2.pedestrianCountDifferenceInAbsoluteNumbers,
                    { precision: 0 }
                  )}`
                : `${I18n.toNumber(
                    walkingDirectionReading2.pedestrianCountDifferenceInAbsoluteNumbers,
                    { precision: 0 }
                  )}`}
            </AbsolutePedestrianCountLabel>

            <AbsolutePedestrianCountLabel>
              {I18n.toNumber(
                walkingDirectionReading2.numberOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}
            </AbsolutePedestrianCountLabel>
          </AbsoluteNumbersRow>
        </WalkingDirectionContainer>
      </ComparisonRow>
    </ItemContainer>
  )
}

WalkingDirectionComparisonStatsCardItem.propTypes = {
  timestamp: PropTypes.string,

  walkingDirectionReading1: PropTypes.shape({
    numberOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    pedestrianCountDifferenceInPercentages: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    pedestrianCountDifferenceInAbsoluteNumbers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),

  walkingDirectionReading2: PropTypes.shape({
    numberOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    pedestrianCountDifferenceInPercentages: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    pedestrianCountDifferenceInAbsoluteNumbers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),

  displayNoDataPresentPlaceholder: PropTypes.bool,
  displayDataIncompleteNotice: PropTypes.bool
}
