import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n from '../../i18n'
import * as Icon from '../Icon'

function DirectionLegend({ ltr, rtl }) {
  return (
    <div css={{ display: 'flex' }}>
      <Title>{I18n.t('location.details.direction')}</Title>
      <div>
        <Row>
          <Icon.DirectionRight width="1.5rem" height="0.625rem" />
          <div>{ltr}</div>
        </Row>
        <Row secondary>
          <Icon.DirectionLeft width="1.5rem" height="0.625rem" />
          <div>{rtl}</div>
        </Row>
      </div>
    </div>
  )
}

DirectionLegend.propTypes = {
  rtl: PropTypes.string,
  ltr: PropTypes.string
}

const Title = styled.div({
  fontSize: '0.75rem',
  fontWeight: 600,
  marginBottom: '0.875rem',
  textTransform: 'uppercase',
  letterSpacing: '0.05rem',
  marginRight: '1.5rem',
  marginTop: '0.25rem'
})

const Row = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.25rem',
  fontSize: '0.875rem',

  '> svg': {
    marginRight: '0.75rem',
    color: props.secondary
      ? props.theme.chroma.chartAreas[1].text.css()
      : props.theme.chroma.chartAreas[0].text.css()
  }
}))

export default DirectionLegend
