import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/css'

import Typography from '../components/Typography'
import Headline from '../components/Headline'
import Button from '../components/Button'
import Card from '../components/Card'
import Checkbox from '../components/forms/Checkbox'
import Input from '../components/forms/Input'
import Form from '../components/forms/Form'
import Section from '../components/Section'

import I18n from '../i18n'

function ConfirmPersonalUse({ user = { values: {}, errors: {} } }) {
  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section row mid>
        <Section.Wrapper css={{ paddingTop: '4rem' }}>
          <Headline>
            {sT('headline')}
            <Typography tag="p" smaller>
              {sT('subline')}
            </Typography>
          </Headline>
          <Card
            css={{
              padding: '2rem 3rem',
              margin: '2rem 0 4rem',
              width: '42rem',
              maxWidth: '100%'
            }}
          >
            <Form as="user" action="/user" method="patch">
              {Object.keys(user.errors).length > 0 && (
                <AlertBox error css={{ marginBottom: '1rem', width: '100%' }}>
                  {sT('error')}
                </AlertBox>
              )}
              <Input hidden readOnly name="redirect_path" value="/locations" />
              <Form.Field>
                <Checkbox
                  name="personal_use"
                  label={sT('personal_use')}
                  errors={user.errors.personalUse}
                  defaultValue={user.values.personalUse}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="terms"
                  label={sT('terms')}
                  errors={user.errors.terms}
                  required
                />
              </Form.Field>
              <Typography tag="p">
                <span css={translationCss} dangerouslySetInnerHTML={{ __html: sT('to_products') }} />
              </Typography>
              <Form.Actions>
                <Button type="submit">{sT('continue')}</Button>
              </Form.Actions>
            </Form>
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.confirm_personal_use.${key}`)

const translationCss = css`
  a {
    text-decoration: underline;
  }
`

ConfirmPersonalUse.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default ConfirmPersonalUse
