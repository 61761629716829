import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

// Necessary for dynamic import because preset-env isn't aware that using
// import() relies on Promise.
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env
import 'core-js/modules/es.promise'
import 'core-js/modules/es.array.iterator'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

dayjs.Ls.en.weekStart = 1
dayjs.extend(isToday)
