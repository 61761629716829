import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n from '../../i18n'
import { Map as MapIcon } from '../../components/Icon'

function MapButton({ onClick }) {
  return (
    <Button onClick={onClick}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <InfoText>{I18n.t('location.map_button')}</InfoText>
        <MapIcon />
      </div>
    </Button>
  )
}

const Button = styled.button`
  align-self: flex-start;
  color: ${p => p.theme.chroma.base.css()};
  border: 2px solid ${p => p.theme.chroma.base.css()};
  border-radius: 0.25rem;
  padding: 0.5rem;

  &:hover {
    background-color: ${p => p.theme.chroma.base.css()};
    color: ${p => p.theme.chroma.white.css()};
  }
`

const InfoText = styled.span`
  display: none;

  ${p => p.theme.breakpoints.tabletUp} {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }
`

MapButton.propTypes = {
  onClick: PropTypes.func
}

export default MapButton
