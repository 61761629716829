import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'

import dayjs from 'dayjs'
import theme from '../../theme'
import Typography from '../Typography'
import I18n from '../../i18n'
import * as Icon from '../Icon'
import CountFigure from '../LocationDetail/CountFigure'
import WeatherIcon from '../WeatherIcon'
import DatetimeHelpers from '../../lib/helpers/datetime-helpers'

const sortChartKeysByPedestriansCount = (
  chartKeys,
  chartColors,
  tooltipData
) => {
  // Transform tooltip data to array
  let tooltipDataAsArray = []
  for (const key in tooltipData) {
    if (tooltipData[key].pedestriansCount) {
      tooltipDataAsArray.push(tooltipData[key])
    }
  }

  // Sort that array by DESC by pedestrianCount but memorize the old index of the element
  let sortedTooltipDataAsArray = tooltipDataAsArray.sort((a, b) => {
    return b.pedestriansCount - a.pedestriansCount
  })

  // Build sorted versions of chartKeys and colors
  let sortedChartKeys = []
  let sortedColors = []

  sortedTooltipDataAsArray.map(tooltipData => {
    const chartKey = `pedestriansFor${tooltipData.belongsToLocation}`
    sortedChartKeys.push(chartKey)

    let indexOfColorForChartKey = chartKeys.findIndex(chartKeyToCheck => {
      return chartKeyToCheck === chartKey
    })
    sortedColors.push(chartColors[indexOfColorForChartKey])
  })

  return { sortedChartKeys, sortedColors }
}

const CustomTooltip = ({
  active,
  payload: tooltipPayload,
  stackedChartKeys,
  chartColors,
  resolution
}) => {
  if (!tooltipPayload || !tooltipPayload[0]) {
    return null
  }

  const tooltipData = tooltipPayload[0].payload

  const sortResult = sortChartKeysByPedestriansCount(
    stackedChartKeys,
    chartColors,
    tooltipData
  )

  stackedChartKeys = sortResult.sortedChartKeys
  chartColors = sortResult.sortedColors

  // dayjs doesn't parse the timezone from the timestamp. This is a workaround to get it.
  const from = tooltipData.timestamp
  const to = dayjs(tooltipData.timestamp)
    .tz(DatetimeHelpers.zoneFromTimestamp(tooltipData.timestamp)) // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    .add(1, 'hour')
    .format('YYYY-MM-DDTHH:mm:ssZ')

  return (
    <TooltipWrapper>
      <Typography
        styleOverrides={{
          color: theme.chroma.grey.hex(),
          padding: '1rem 0rem',
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        tag="p"
      >
        {resolution === 'week' &&
          `${I18n.t('location.timerange.week.general')} ${dayjs(
            tooltipData.timestamp
          ).isoWeek()}\u2009\u2013\u2009`}
        {I18n.l('date.formats.long_weekday', tooltipData.timestamp)}

        {resolution === 'hour' && from && (
          <div css={{ paddingLeft: '1rem' }}>
            {I18n.helpers.hourRange(from, to)}
          </div>
        )}
      </Typography>
      <div css={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
        {stackedChartKeys.map((chartKey, i) => {
          const dataForChartKey = tooltipData[stackedChartKeys[i]]

          return (
            <React.Fragment key={chartKey}>
              <CountFigure
                small
                color={chartColors[i]}
                icon={<Icon.Walking width="1rem" height="1rem" />}
                value={dataForChartKey.pedestriansCount}
                hint={
                  <CountHintWrapper>
                    <Typography>{dataForChartKey.label}</Typography>
                    <TemperatureWrapper>
                      {dataForChartKey.weatherCondition && (
                        <WeatherIcon
                          type={dataForChartKey.weatherCondition}
                          css={{
                            height: 12,
                            width: 12,
                            color: chartColors[i]
                          }}
                        />
                      )}
                      {dataForChartKey.minTemperature != null && (
                        <TemperatureLabel color={chartColors[i]}>
                          {Math.round(dataForChartKey.minTemperature)}°C –&nbsp;
                        </TemperatureLabel>
                      )}
                      {dataForChartKey.temperature != null && (
                        <TemperatureLabel color={chartColors[i]}>
                          {Math.round(dataForChartKey.temperature)}°C
                        </TemperatureLabel>
                      )}
                    </TemperatureWrapper>
                  </CountHintWrapper>
                }
                textRight={false}
              />
            </React.Fragment>
          )
        })}
      </div>
    </TooltipWrapper>
  )
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  stackedChartKeys: PropTypes.arrayOf(PropTypes.string),
  chartColors: PropTypes.arrayOf(PropTypes.string),
  resolution: PropTypes.string
}

const TooltipWrapper = styled.div`
  background-color: ${theme.chroma.nearlyWhite.hex()};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  min-width: 2.5rem;
  margin: 1rem;
  padding: 0.5rem;
  background-color: ${props => props.theme.chroma.white.css()};
  border: 1px solid #e6e6e6;
`
const TemperatureLabel = styled.div`
  color: ${p => p.color};
`

const TemperatureWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
`

const CountHintWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default CustomTooltip
