import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { indexAPI, indexAPITransformers } from './index-api'

export function useQueryNationalIndex(filter, enabled = true) {
  const queryInfo = useQuery(
    ['index', filter],
    () => indexAPI.getIndex(2, filter),
    {
      refetchOnWindowFocus: false,
      enabled: enabled
    }
  )

  return {
    ...queryInfo,
    data: useMemo(
      () =>
        indexAPITransformers.indexValuesToChartData(
          queryInfo.data?.indexValues || []
        ),
      [queryInfo.data]
    )
  }
}
