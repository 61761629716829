import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'

function BlogPost({ title, publicDate, content, subtitle }) {
  const parsedDate = I18n.strftime(new Date(publicDate), '%d.%m.%Y')
  return (
    <div>
      <PublicationDate>{parsedDate}</PublicationDate>
      <Headline>{title}</Headline>
      <Subtitle>{subtitle}</Subtitle>
      <ReadMore>{content}</ReadMore>
    </div>
  )
}

const ReadMore = ({ children }) => {
  const text = children
  const [readMore, setReadMore] = useState(true)
  const toggleReadMore = () => {
    setReadMore(!readMore)
  }
  return (
    <Text>
      {readMore ? text.slice(0, 610) : text}
      {text.length > 600 ? (
        <ReadOrHide>
          <span onClick={toggleReadMore}>
            {readMore ? (
              <Trans id="pages.press.news.read_more" />
            ) : (
              <Trans id="pages.press.news.show_less" />
            )}
          </span>
        </ReadOrHide>
      ) : (
        ''
      )}
    </Text>
  )
}

const Headline = styled.h3`
  font-weight: 900;
  line-height: 1.8;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 0.1rem;
  }
`

const Subtitle = styled.p`
  line-height: 1.75;
  white-space: pre-wrap;
  font-weight: 700;
  margin-bottom: 2rem;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const PublicationDate = styled.span`
  display: inline-block;
  font-weight: 400;
  margin-bottom: 1.5rem;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
`

const Text = styled.p`
  line-height: 1.75;
  white-space: pre-wrap;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`
const ReadOrHide = styled.button`
  margin-top: 0.5rem;
  font-weight: 700;
  padding-left: 0.7rem;
  color: ${p => p.theme.chroma.accent.css()};
  cursor: pointer;
`
BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  publicDate: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

ReadMore.propTypes = {
  children: PropTypes.string.isRequired
}

export default BlogPost
