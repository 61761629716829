import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Figure({ icon, children, iconOnRight, stackingLayoutMobile }) {
  const spacing = '1.6rem'
  const rowDirection = iconOnRight ? 'row-reverse' : 'row'

  const WrapperBase = styled.div`
    display: flex;
    width: 100%;

    ${p => p.theme.breakpoints.tabletUp} {
      flex-direction: ${rowDirection};
      align-items: center;
    }
  `

  const HorizontalWrapper = styled(WrapperBase)`
    align-items: center;
    flex-direction: ${rowDirection};
  `

  const StackingWrapper = styled(WrapperBase)`
    align-items: ${iconOnRight ? 'flex-end' : 'flex-start'};
    flex-direction: column;
  `

  const HorizontalTextWrapper = styled.div`
    flex: 1;
    text-align: ${iconOnRight ? 'right' : 'left'};
    margin-left: ${iconOnRight ? 0 : spacing};
    margin-right: ${iconOnRight ? spacing : 0};
  `

  const StackingTextWrapper = styled(HorizontalTextWrapper)`
    margin-top: 1.125rem;
    margin-left: 0;
    margin-right: 0;

    ${p => p.theme.breakpoints.tabletUp} {
      margin-top: 0;
      margin-left: ${iconOnRight ? 0 : spacing};
      margin-right: ${iconOnRight ? spacing : 0};
    }
  `

  const Wrapper = stackingLayoutMobile ? StackingWrapper : HorizontalWrapper
  const TextWrapper = stackingLayoutMobile
    ? StackingTextWrapper
    : HorizontalTextWrapper

  return (
    <Wrapper>
      {icon}
      <TextWrapper>{React.Children.only(children)}</TextWrapper>
    </Wrapper>
  )
}

Figure.propTypes = {
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  iconOnRight: PropTypes.bool,
  stackingLayoutMobile: PropTypes.bool
}

const Text = styled.p`
  line-height: 1.7;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

Figure.Text = Text

export default Figure
