import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'
import { Refresh } from './Icon'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Box>
      <h2 css={{ fontSize: '1.3rem', marginBottom: '1rem' }}>
        {I18n.t('app_error.title')}
      </h2>
      <Trans id="app_error.text" copy />
      <pre
        css={{
          marginTop: '1rem',
          marginBottom: '2rem',
          opacity: 0.7,
          maxWidth: '100%',
          whiteSpace: 'normal'
        }}
      >
        {error.message}
      </pre>
      <RetryButton onClick={resetErrorBoundary}>
        <Refresh />
        {I18n.t('app_error.button')}
      </RetryButton>
    </Box>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired
}

const Box = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',

  backgroundColor: props.theme.chroma.white.css(),
  color: props.theme.chroma.accent.css(),
  textAlign: 'center',
  fontSize: '0.875rem',
  fontWeight: 600
}))

const RetryButton = styled.button(props => ({
  color: 'inherit',
  fontWeight: 800,
  display: 'flex',
  alignItems: 'center',
  padding: '0.8rem 1rem',
  marginBottom: '3rem',
  backgroundColor: props.theme.chroma.lightGrey.alpha(0.5).css(),
  borderRadius: '0.25rem',
  transition: 'all 130ms',

  '&:hover': {
    backgroundColor: props.theme.chroma.lightGrey.alpha(0.8).css()
  },

  svg: {
    display: 'block',
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.5rem'
  }
}))

export default ErrorFallback
