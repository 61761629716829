/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select, { components } from 'react-select'
import theme from '../../theme'
import LoadingIndicator from '../Graph/LoadingIndicator'
import * as Icon from '../Icon'

const { Option } = components

const IconOption = props => {
  return (
    <Option {...props}>
      <OptionRow>
        {/* {props.data.icon} */}
        {props.data.label}
      </OptionRow>
    </Option>
  )
}

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`

const CustomValueContainer = props => {
  const selectedOptions = props.getValue()
  const selectedOption = selectedOptions[0] || null
  const WrapperComponent =
    props.selectProps.variant === 'PRIMARY'
      ? PrimaryOuterValueContainer
      : SecondaryOuterValueContainer

  return (
    <components.ValueContainer {...props}>
      <WrapperComponent>
        {selectedOption ? selectedOption.icon : null}
        {props.children}
      </WrapperComponent>
    </components.ValueContainer>
  )
}

const CustomGroupHeading = props => {
  return (
    <components.GroupHeading {...props}>
      <GroupHeading>
        <GroupHeadingIcon>{props.data.icon}</GroupHeadingIcon>
        {props.children}
      </GroupHeading>
    </components.GroupHeading>
  )
}

const GroupHeading = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.3125rem;
  padding-top: 0.625rem;
`

const GroupHeadingIcon = styled.div`
  margin-right: 0.625rem;
`

const PrimaryOuterValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.625rem;
  padding: 0 0.625rem;
`

const SecondaryOuterValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -4px;
`

/**
 * @param {Object} provided -- the component's default styles
 * @param {Object} state -- the component's current state e.g. `isFocused`
 * @returns {Object}
 * @see https://react-select.com/styles
 */
const primaryStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.chroma.dustyBlue.hex(),
      zIndex: 1
    }
  },

  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: '0.125rem',
      borderWidth: '2px',
      borderColor: theme.chroma.white.hex(),
      backgroundColor: theme.chroma.dustyBlue.hex(),
      color: state.isDisabled
        ? theme.chroma.slate.hex()
        : theme.chroma.white.hex(),
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: 800,
      fontSize: '0.875rem',
      minWidth: '15rem',
      height: '3.1rem',

      '&:hover': {
        borderColor: theme.chroma.white.hex()
      }
    }
  },

  menu: (provided, state) => {
    return {
      ...provided,
      width: '15rem',
      backgroundColor: theme.chroma.dustyBlue.hex(),
      border: 'none'
    }
  },

  menuList: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.chroma.dustyBlue.hex(),
      border: `2px solid ${theme.chroma.white.hex()}`,
      borderTop: state.selectProps.menuIsOpen ? 'none' : '2px solid white',
      marginTop: '-10px',
      maxHeight: null // Disable scrollbar
    }
  },

  group: (provided, state) => {
    return {
      ...provided,
      color: theme.chroma.dustyBlue.hex(),
      backgroundColor: theme.chroma.dustyBlue.hex()
    }
  },

  groupHeading: (provided, state) => {
    return {
      ...provided,
      color: theme.chroma.grey.hex(),
      backgroundColor: theme.chroma.dustyBlue.hex()
    }
  },

  indicatorSeperator: (provided, state) => {
    return {
      ...provided
    }
  },

  option: (provided, state) => {
    return {
      ...provided,
      padding: '.625rem 1rem',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      color: state.isDisabled
        ? theme.chroma.slate.hex()
        : theme.chroma.base.hex(),
      backgroundColor: state.isFocused
        ? theme.chroma.dustyBlue.hex()
        : state.isDisabled
        ? theme.chroma.dustyBlue.hex()
        : theme.chroma.dustyBlue.hex(),
      fontSize: '0.875rem',
      letterSpacing: '0.05em',
      textTransform: 'none',
      svg: {
        fill: state.isDisabled
          ? theme.chroma.white.hex()
          : state.isFocused
          ? theme.chroma.white.hex()
          : state.isSelected
          ? theme.chroma.white.hex()
          : theme.chroma.white.hex()
      },

      '&:hover': {
        backgroundColor: state.isDisabled ? undefined : theme.chroma.slate.hex()
      }
    }
  },

  input: (provided, state) => {
    return { color: theme.chroma.base.hex() }
  },

  singleValue: (provided, state) => {
    return {
      color: theme.chroma.base.hex(),
      padding: '0 0.625rem',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      letterSpacing: '0.05em'
    }
  },

  valueContainer: (provided, state) => {
    return {
      color: theme.chroma.base.hex(),
      display: 'flex'
    }
  }
}

const secondaryStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
      backgroundColor: theme.chroma.nearlyWhite.hex(),
      minWidth: '15rem',
      height: '2.5rem',
      maxHeight: '2.5rem'
    }
  },

  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.chroma.nearlyWhite.hex(),
      color: state.isDisabled
        ? theme.chroma.slate.hex()
        : theme.chroma.white.hex(),
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: 800,
      fontSize: '0.875rem',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderRadius: '0.125rem',
      borderColor: theme.chroma.base.hex(),
      boxShadow: 'none',
      height: '2.5rem',
      minHeight: '2.5rem',
      paddingLeft: '0.5rem',

      '&:hover': { borderColor: theme.chroma.base.hex() }, // border style on hover
      border: `2px solid ${theme.chroma.base.hex()}`, // default border color
    }
  },

  menu: (provided, state) => {
    return {
      ...provided,
      position: 'absolute',
      backgroundColor: theme.chroma.white.hex(),
      border: 'none'
    }
  },

  menuList: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.chroma.white.hex(),
      border: `0.125rem solid ${theme.chroma.white.hex()}`
    }
  },

  group: (provided, state) => {
    return {
      ...provided,
      color: theme.chroma.dustyBlue.hex(),
      backgroundColor: theme.chroma.white.hex()
    }
  },

  groupHeading: (provided, state) => {
    return {
      ...provided,
      color: theme.chroma.grey.hex(),
      backgroundColor: theme.chroma.white.hex(),
      paddingLeft: '0.25rem'
    }
  },

  indicatorSeperator: (provided, state) => {
    return {
      ...provided
    }
  },

  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      height: '2.5rem',
      maxHeight: '2.5rem'
    }
  },

  option: (provided, state) => {
    return {
      ...provided,
      padding: '.625rem 1rem',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      color: state.isDisabled
        ? theme.chroma.slate.hex()
        : theme.chroma.base.hex(),
      backgroundColor: state.isFocused
        ? theme.chroma.white.hex()
        : state.isDisabled
        ? theme.chroma.white.hex()
        : theme.chroma.white.hex(),
      fontSize: '0.875rem',
      letterSpacing: '0.05em',
      // textTransform: 'uppercase',
      svg: {
        fill: state.isDisabled
          ? theme.chroma.white.hex()
          : state.isFocused
          ? theme.chroma.white.hex()
          : state.isSelected
          ? theme.chroma.white.hex()
          : theme.chroma.white.hex()
      },

      '&:hover': {
        backgroundColor: state.isDisabled
          ? undefined
          : theme.chroma.nearlyWhite.hex()
      }
    }
  },

  input: (provided, state) => {
    return {
      color: theme.chroma.base.hex()
    }
  },

  singleValue: (provided, state) => {
    return {
      color: theme.chroma.base.hex(),
      padding: '0 0.5rem',
      fontSize: '0.875rem',
      fontWeight: '800',
      letterSpacing: '0.05em',
      textTransform: 'uppercase'
    }
  },

  valueContainer: (provided, state) => {
    return {
      color: theme.chroma.base.hex(),
      display: 'flex'
    }
  }
}

function primaryTheme(defaultReactSelectTheme) {
  const customTheme = {
    ...defaultReactSelectTheme,

    colors: {
      ...defaultReactSelectTheme.colors,
      primary: theme.chroma.base.hex(),
      primary25: theme.chroma.lightGrey.hex(),
      primary50: theme.chroma.dustyBlue.hex(),
      danger: theme.chroma.accent.hex()
    }
  }

  return customTheme
}

function secondaryTheme(defaultReactSelectTheme) {
  const customTheme = {
    ...defaultReactSelectTheme,

    colors: {
      ...defaultReactSelectTheme.colors,
      primary: theme.chroma.white.hex(),
      primary25: theme.chroma.base.hex(),
      primary50: theme.chroma.base.hex(),
      danger: theme.chroma.accent.hex()
    },

    spacing: {
      ...theme.spacing,
      controlHeight: '2.5rem'
    }
  }

  return customTheme
}

function CustomCloseIcon() {
  return (
    <IconWrapper>
      <Icon.Close />
    </IconWrapper>
  )
}

function CustomDropdownIcon(state) {
  const Wrapper =
    state.selectProps.variant === 'PRIMARY'
      ? PrimaryIconWrapper
      : SecondaryIconWrapper

  return (
    <Wrapper>
      {state.selectProps.menuIsOpen ? (
        <Flip>
          <Icon.AngleDown color={theme.chroma.base.hex()} />
        </Flip>
      ) : (
        <Icon.AngleDown color={theme.chroma.base.hex()} />
      )}
    </Wrapper>
  )
}

export default function SingleSelect({
  onChange,
  isOptionDisabled,
  variant = 'PRIMARY',
  ...selectProps
}) {
  const [input, setInput] = useState('')
  const customStyles = variant === 'PRIMARY' ? primaryStyles : secondaryStyles
  const customTheme = variant === 'PRIMARY' ? primaryTheme : secondaryTheme
  return (
    <Select
      variant={variant}
      theme={customTheme}
      styles={customStyles}
      onInputChange={(value, action) => {
        // only set the input when the action that caused the
        // change equals to "input-change" and ignore the other
        // ones like: "set-value", "input-blur", and "menu-close"
        if (action.action === 'input-change') setInput(value) // <---
      }}
      inputValue={input}
      components={{
        ValueContainer: CustomValueContainer,
        DropdownIndicator: CustomDropdownIcon,
        CrossIcon: CustomCloseIcon,
        LoadingIndicator: LoadingIndicator,
        Option: IconOption,
        IndicatorSeparator: null,
        GroupHeading: CustomGroupHeading
      }}
      isOptionDisabled={isOptionDisabled}
      isSearchable={false}
      onChange={(newValue, triggeredAction) => {
        onChange(newValue, triggeredAction)
      }}
      {...selectProps}
    />
  )
}

const PrimaryIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const SecondaryIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-top: -4px;
`

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-top: -4px;
`

const Flip = styled.div`
  transform: rotate(180deg);
`
