/* eslint-disable react/display-name */

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select from 'react-select'
import theme from '../../theme'
import LoadingIndicator from '../Graph/LoadingIndicator'
import * as Icon from '../Icon'

/**
 * @param {Object} provided -- the component's default styles
 * @param {Object} state -- the component's current state e.g. `isFocused`
 * @returns {Object}
 * @see https://react-select.com/styles
 */
const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: '0.125rem'
    }
  },

  menu: (provided, state) => {
    return {
      ...provided,
      borderRadius: '0.125rem'
    }
  },

  menuList: (provided, state) => {
    return {
      ...provided,
      borderRadius: '0 0 0.125rem 0.125rem',
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.28)',
      fontSize: '0.75rem',
      backgroundColor: state.isDisabled
        ? theme.chroma.grey
        : theme.chroma.white.hex(),
      borderColor: theme.chroma.accent.hex(),
      borderWidth: '0.125rem',
      borderStyle: 'solid'
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      padding: '1.25rem 0.75rem',
      cursor: 'pointer',
      color: state.isDisabled
        ? theme.chroma.grey.hex()
        : theme.chroma.base.hex(),
      fontWeight: 700,
      fontFamily: 'Lato',
      fontSize: '15px'
    }
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingRight: '1rem',
      paddingLeft: '1rem',
      color: theme.chroma.base.hex(),
      fontWeight: 700,
      fontFamily: 'Lato',
      fontSize: '15px',
      letterSpacing: '0.5px'
    }
  },
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      padding: '0.5rem 0.5rem',
      cursor: state.isActive ? 'pointer' : null
    }
  }
}

function customTheme(defaultReactSelectTheme) {
  const customTheme = {
    ...defaultReactSelectTheme,

    colors: {
      ...defaultReactSelectTheme.colors,
      primary: theme.chroma.accent.hex(),
      primary25: theme.chroma.lightGrey.hex(),
      primary50: theme.chroma.dustyBlue.hex(),
      danger: theme.chroma.accent.hex()
    }
  }

  return customTheme
}

function CustomCloseIcon() {
  return (
    <IconWrapper>
      <Icon.Close />
    </IconWrapper>
  )
}

function CustomDropdownIcon(state) {
  return (
    <IconWrapper>
      {state.selectProps.menuIsOpen ? (
        <Flip>
          <Icon.AngleDown />
        </Flip>
      ) : (
        <Icon.AngleDown />
      )}
    </IconWrapper>
  )
}

function formatColoredOptionLabels(
  { value, label, abbreviation },
  meta,
  customFormatOptions
) {
  const selectionLimitReached =
    meta.selectValue.length >= customFormatOptions.limit
  const optionIsDisplayedInMenu = meta.context === 'menu'

  const colorIndex = meta.selectValue.findIndex(selectedValue => {
    return selectedValue.value.id === value.id
  })

  let optionColor
  if (optionIsDisplayedInMenu) {
    if (selectionLimitReached) {
      optionColor = theme.chroma.lightGrey
    } else {
      optionColor = theme.chroma.base
    }
  } else {
    optionColor = customFormatOptions.optionColors[colorIndex]
  }

  const customLabel = customFormatOptions.enableOptionAbbreviations
    ? `${abbreviation} - ${label}`
    : label
  return (
    <div
      style={{
        display: 'flex',
        color: optionColor,
        padding: '0.25rem'
      }}
    >
      {customLabel}
    </div>
  )
}

export default function MultiSelect({
  limit,
  onChange,
  enableColouredOptions,
  enableOptionAbbreviations,
  optionColors,
  ...selectProps
}) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [input, setInput] = useState('')
  return (
    <Select
      isMulti
      theme={customTheme}
      styles={customStyles}
      onInputChange={(value, action) => {
        // only set the input when the action that caused the
        // change equals to "input-change" and ignore the other
        // ones like: "set-value", "input-blur", and "menu-close"
        if (action.action === 'input-change') setInput(value) // <---
      }}
      inputValue={input}
      components={{
        DropdownIndicator: CustomDropdownIcon,
        CrossIcon: CustomCloseIcon,
        LoadingIndicator: LoadingIndicator
      }}
      isOptionDisabled={() => selectedOptions?.length >= limit}
      formatOptionLabel={
        enableColouredOptions
          ? (data, formatOptionLabelMeta) => {
              return formatColoredOptionLabels(data, formatOptionLabelMeta, {
                enableOptionAbbreviations: enableOptionAbbreviations,
                limit: limit,
                optionColors: optionColors
              })
            }
          : null
      }
      isSearchable={true}
      onChange={(newValue, triggeredAction) => {
        setSelectedOptions([...newValue])
        onChange(newValue, triggeredAction)
      }}
      {...selectProps}
    />
  )
}

MultiSelect.propTypes = {
  limit: PropTypes.number,
  enableColouredOptions: PropTypes.bool,
  enableOptionAbbreviations: PropTypes.bool
}

const IconWrapper = styled.div`
  padding: 15px;
`

const Flip = styled.div`
  transform: rotate(180deg);
`
