import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'

import AlertBox from './AlertBox'

function Flash({
  success,
  notice,
  alert,
  duration = 10000,
}) {
  const timer = useRef(null)
  const [messages, setMessages] = useState(() =>
    [
      { type: 'alert', value: alert },
      { type: 'notice', value: notice },
      { type: 'success', value: success }
    ].filter(({ value }) => !!value)
  )

  useEffect(() => {
    timer.current = setTimeout(() => {
      setMessages(prevMessages =>
        prevMessages.filter(({ type }) => type === 'alert')
      )
    }, duration)

    return () => clearTimeout(timer.current)
  })

  return (
    <Container>
      <AnimatePresence>
        {messages.map(message => (
          <FlashItem
            key={message.type}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AlertBox error={message.type === 'alert'}>
              {message.value}
            </AlertBox>
          </FlashItem>
        ))}
      </AnimatePresence>
    </Container>
  )
}

Flash.propTypes = {
  success: PropTypes.string,
  notice: PropTypes.string,
  alert: PropTypes.string,
  duration: PropTypes.number,
}

const Container = styled.div`
  position: absolute;
  top: '5.5rem';
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FlashItem = styled(motion.div)`
  margin-bottom: 0.5rem;
  width: 90%;
  max-width: '360px';
  display: flex;
  flex-direction: column;
  align-items: center;
`
FlashItem.propTypes = {
  initial: PropTypes.object,
  exit: PropTypes.object,
}

export default Flash
