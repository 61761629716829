import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { Trans } from '../../i18n'

const APPEAR_DELAY = 0.4
const isSSR = typeof window !== 'object'

function LoadingIndicator({ active, text = 'location.loading_data' }) {
  const fade = {
    visible: {
      opacity: 1,
      transition: {
        delay: APPEAR_DELAY
      }
    },
    hidden: { opacity: 0 }
  }

  const grow = {
    visible: {
      pathLength: [0, 1],
      transition: {
        yoyo: Infinity,
        duration: 2,
        ease: 'easeInOut'
      }
    },
    hidden: {
      pathLength: 0,
      transition: {
        duration: 0.15,
        ease: 'easeInOut'
      }
    }
  }

  return (
    <Container>
      <motion.svg
        width={86.5}
        height={48.5}
        initial={false}
        animate={!active || isSSR ? 'hidden' : 'visible'}
        variants={fade}
      >
        <motion.path
          d="M9.8,44.9c-0.8,1-2,1.6-3.4,1.6c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4c1.2,0,2.3,0.3,3,1.2l17.8-19.2c-0.9-0.8-1.4-1.9-1.4-3.2c0-2.4,2-4.4,4.4-4.4s4.4,2,4.4,4.4c0,1-0.4,2-1,2.7l18.1,14.5c0,0-1,1.7-1,2.7c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4C59.5,35,58,33,58,33L77.4,9.8c0.5,0.5,1.7,1,2.8,1c2.4,0,4.4-2,4.4-4.4S82.6,2,80.2,2c-1.2,0-2.2,0.5-3,1.2"
          fill="transparent"
          stroke="currentColor"
          variants={grow}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </motion.svg>
      <motion.div
        animate={active ? 'visible' : 'hidden'}
        initial={false}
        variants={fade}
        css={{ marginTop: '1rem' }}
      >
        <Trans id={text} />
      </motion.div>
    </Container>
  )
}

LoadingIndicator.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${p => p.theme.chroma.base.css()};
  font-size: 1rem;
  font-weight: bold;
`

export default LoadingIndicator
