import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Headline from '../components/Headline'
import Section from '../components/Section'

import apiDocsImage from './assets/api-docs.png'

function ApiTest({ loggedIn }) {
  const url = loggedIn ? '/profile' : '/login'
  return (
    <Section mid>
      <Section.Skew position="top" mid direction="up" />
      <Section.Wrapper>
        <Content>
          <Headline breakout css={{ marginBottom: '1.25rem' }}>
            {I18n.t('pages.developer.api.test.headline')}
          </Headline>
          <Text>
            <Trans id="pages.developer.api.test.text" />
          </Text>
          <Button.Link outline target="_blank" href={url}>
            {I18n.t('pages.developer.api.test.button_text')}
          </Button.Link>
        </Content>
      </Section.Wrapper>
      <ImageContainer />
    </Section>
  )
}

const Content = styled.div`
  padding: 2rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${props => props.theme.breakpoints.tabletUp} {
    width: 55%;
  }
`

const Text = styled.div`
  line-height: 1.5;
  margin-bottom: 1.5rem;
`

const ImageContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-image: url(${apiDocsImage});
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  width: 45%;
  height: 544px;
  ${props => props.theme.breakpoints.desktopUp} {
    display: block;
  }
`

ApiTest.propTypes = {
  loggedIn: PropTypes.bool
}

export default ApiTest
