import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { lighten, transparentize } from 'polished'
import Downshift from 'downshift'
import * as Icon from './Icon'

function Select({
  onChange,
  onBaseClick,
  activeItem,
  items,
  baseTitle,
  highlighted,
  variant = 'primary',
  icon = null
}) {
  return (
    <Downshift
      onSelect={onChange}
      itemToString={item => (item ? item.value : '')}
      onOuterClick={() => {
        onBaseClick ? onBaseClick() : null
      }}
    >
      {({
        getToggleButtonProps,
        getItemProps,
        getMenuProps,
        isOpen,
        toggleMenu,
        highlightedIndex
      }) => (
        <div
          style={{ width: '100%', position: 'relative', display: 'inline-block' }}
        >
          <ButtonWrapper highlighted={highlighted} variant={variant}>
            <button
              css={{
                flex: '1 0 auto',
                padding: '0.25rem 0.5rem',
                color: 'inherit',
                cursor: 'pointer',
                textAlign: 'left',
                textTransform: variant === 'tertiary' ? 'uppercase' : 'none',
                fontWeight: variant === 'tertiary' ? 800 : 700,
                letterSpacing: variant === 'tertiary' ? '0.05rem' : 'normal'
              }}
              onClick={event =>
                onBaseClick
                  ? onBaseClick(event, { toggleMenu, item: items[0] })
                  : toggleMenu()
              }
            >
              {icon && <span style={{ paddingRight: '0.5rem' }}>{icon}</span>}
              {baseTitle}
            </button>
            <button
              {...getToggleButtonProps()}
              onClick={() => {
                onBaseClick ? onBaseClick() : toggleMenu()
              }}
              css={{
                padding: '0.75rem 1.25rem',
                color: 'inherit',
                cursor: 'pointer'
              }}
            >
              {isOpen ? (
                <Flip>
                  <Icon.AngleDown />
                </Flip>
              ) : (
                <Icon.AngleDown />
              )}
            </button>
          </ButtonWrapper>
          <Dropdown {...getMenuProps()}>
            {isOpen &&
              items.map((item, index) => {
                if (variant === 'tertiary') {
                  return (
                    <TertiaryStyleDropdownItem
                      variant={variant}
                      active={!!activeItem && item.key === activeItem.key}
                      highlighted={highlightedIndex === index}
                      key={item.key}
                      {...getItemProps({
                        key: item.key,
                        index,
                        item
                      })}
                    >
                      {item.label}
                    </TertiaryStyleDropdownItem>
                  )
                } else {
                  return (
                    <DropdownItem
                      variant={variant}
                      active={!!activeItem && item.key === activeItem.key}
                      highlighted={highlightedIndex === index}
                      key={item.key}
                      {...getItemProps({
                        key: item.key,
                        index,
                        item
                      })}
                    >
                      {item.label}
                    </DropdownItem>
                  )
                }
              })}
          </Dropdown>
        </div>
      )}
    </Downshift>
  )
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBaseClick: PropTypes.func,
  activeItem: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  baseTitle: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  isOpen: PropTypes.bool,
  icon: PropTypes.element
}

export default Select

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.125rem;
  border-color: ${p =>
    p.highlighted
      ? p.theme.chroma.lightBlue.css()
      : p.variant === 'secondary'
      ? p.theme.chroma.white.css()
      : p.theme.chroma.base.css()};
  color: ${p =>
    p.highlighted
      ? p.theme.chroma.white.css()
      : p.variant === 'secondary'
      ? p.theme.chroma.white.css()
      : p.theme.chroma.base.css()};
  background-color: ${p =>
    p.highlighted ? p.theme.chroma.lightBlue.css() : 'transparent'};
  font-size: 0.75rem;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 0.875rem;
  }

  text-transform: ${p => (p.variant === 'tertiary' ? 'uppercase' : 'none')};
`

const DropdownItem = styled.li`
  text-transform: none
  display: block;
  color: ${p =>
    p.active
      ? transparentize(0.6, p.theme.chroma.white.hex())
      : p.theme.chroma.white.css()};
  background-color: ${p =>
    p.highlighted
      ? lighten(0.2, p.theme.chroma.base.css())
      : p.theme.chroma.base.css()};
  padding: 1.25rem 0.75rem;
  cursor: pointer;
`

const TertiaryStyleDropdownItem = styled.li`
  text-transform: uppercase;
  display: block;
  color: ${p =>
    p.active
      ? transparentize(0.6, p.theme.chroma.base.hex())
      : p.theme.chroma.base.css()};
  background-color: ${p =>
    p.highlighted && !p.active
      ? p.theme.chroma.nearlyWhite.css()
      : p.theme.chroma.white.css()};
  padding: 1.25rem 0.75rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
`

const Flip = styled.div`
  transform: rotate(180deg);
`

const Dropdown = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  border-radius: 0 0 0.125rem 0.125rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.28);
  font-size: 0.75rem;
  display: block;
`
