import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'

function TextBlog({ heading, content }) {
  return (
    <Wrapper>
      <Headline>{heading}</Headline>
      <Text>
        <Trans id={content} />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 1.125rem;
  line-height: 1.7rem;
  margin: 1rem;
  max-width: 36rem;
`
const Headline = styled.h2`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
`
const Text = styled.div`
  font-size: 1rem;
`

TextBlog.propTypes = {
  heading: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
}

export default TextBlog
