import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import React from 'react'
import * as Icon from '../../Icon'
import I18n from '../../../i18n'
import { determineWeekOfYear } from './dateUtilities'

dayjs.extend(weekday)
dayjs.extend(isLeapYear)
dayjs.extend(weekOfYear)

const getSuggestionsBySpan = (span, agoList, displayFormatter) => {
  return agoList.map(agoAmount => {
    const date = dayjs().subtract(agoAmount, span)
    const from = date.startOf(span).toDate()
    const to = date.endOf(span).toDate()
    const displayFormat = displayFormatter(date, agoAmount)
    const key = date.unix()

    return { key, displayFormat, date: date.toDate(), from, to }
  })
}

const getSuggestedMonths = () => {
  const monthsAgoList = [0, 1, 2, 3, 4, 5]
  const getRelativeMonthName = date => {
    return I18n.strftime(dayjs(date).toDate(), '%B')
  }

  return getSuggestionsBySpan('month', monthsAgoList, getRelativeMonthName)
}

const getSuggestedWeeks = () => {
  const weeksAgoList = [0, 1, 2, 3, 4, 5]
  const getRelativeWeekName = (date, weeksAgo) => {
    if (weeksAgo === 0) return I18n.t('location.timerange.week.this')
    if (weeksAgo === 1) return I18n.t('location.timerange.week.last')
    return `${I18n.t('location.timerange.week.general')} ${determineWeekOfYear(
      date
    )}`
  }

  return getSuggestionsBySpan('week', weeksAgoList, getRelativeWeekName)
}

const getSuggestedDays = () => {
  const daysAgoList = [0, 1, 2, 3, 4, 5, 6]
  const getRelativeDayName = (date, daysAgo) => {
    if (daysAgo === 0) {
      return `${I18n.t('location.timerange.day.this')} (${I18n.strftime(
        dayjs(date).toDate(),
        '%a'
      )})`
    }
    if (daysAgo === 1) {
      return `${I18n.t('location.timerange.day.last')} (${I18n.strftime(
        dayjs(date).toDate(),
        '%a'
      )})`
    }
    return `${I18n.t('location.timerange.day.general')} ${I18n.strftime(
      dayjs(date).toDate(),
      '%A'
    )}`
  }

  return getSuggestionsBySpan('day', daysAgoList, getRelativeDayName)
}

const getSuggestedYears = () => {
  const yearsAgoList = [0, 1, 2, 3, 4]
  const getRealiveYearName = date => {
    return dayjs(date).format('YYYY')
  }

  return getSuggestionsBySpan('year', yearsAgoList, getRealiveYearName)
}

// Note: This can potentially return an empty array and should be handled.
export function onlyFilterValuesWithMeasurements(earliestMeasurement, values) {
  return values.filter(value => {
    return dayjs(value.to).isAfter(
      dayjs(earliestMeasurement).subtract(1, 'day'),
      'day'
    )
  })
}

export const findActiveTimerangeItem = (
  filter,
  options,
  latestAvailableMeasurementDate
) => {
  // Flatten options array with the subItems in each option
  const flatOptions = options
    .map(option => {
      if (option.options) {
        return [option, ...option.options]
      }
      return option
    })
    .flat()

  const customDateRangeAsSelectorItem = flatOptions.find(value => {
    return value.humanReadableKey === 'customDateRange'
  })

  const todayAsSelectorItem = flatOptions.find(value => {
    return (
      value.from &&
      dayjs(value.from).isSame(dayjs(), 'day') &&
      value.humanReadableKey != 'customDateRange'
    )
  })

  const activeFilterIsToday = dayjs(filter.from).isSame(dayjs(), 'day')

  if (activeFilterIsToday) {
    return todayAsSelectorItem
  }

  const activeItem = flatOptions.find(value => {
    return (
      dayjs(value.from).isSame(dayjs(filter.from), 'day') &&
      dayjs(value.to).isSame(dayjs(filter.to), 'day')
    )
  })

  if (!activeItem) {
    return customDateRangeAsSelectorItem
  }

  return activeItem.isDisabled ? customDateRangeAsSelectorItem : activeItem
}

export const predefinedFilters = [
  { key: 'month', values: getSuggestedMonths() },
  { key: 'week', values: getSuggestedWeeks() },
  { key: 'day', values: getSuggestedDays() },
  { key: 'year', values: getSuggestedYears() }
]

export const getTimerangeSelectorItems = (
  location,
  earliestAccessibleDate,
  filter
) => {
  const cleanSelectableTimeranges = predefinedFilters.map(filter => {
    let values = onlyFilterValuesWithMeasurements(
      location.metadata.earliestMeasurementAt,
      filter.values
    )

    function disableUnpermittedTimeranges(earliestAccessibleDate, values) {
      return values.map(value => {
        if (dayjs(value.from).isBefore(earliestAccessibleDate, 'day')) {
          return { ...value, disabled: true }
        }

        return value
      })
    }

    values = disableUnpermittedTimeranges(earliestAccessibleDate, values)

    // Change the structure of the values array to match the SingleSelect component
    values = values.map(timerange => {
      timerange.label = timerange.displayFormat
      timerange.value = { from: timerange.from, to: timerange.to }
      timerange.icon = <Icon.FA icon={Icon.FaCalendar} />
      timerange.isDisabled = timerange.disabled
      return timerange
    })

    return values
  })

  const selectableTimerangeGroups = {
    days: cleanSelectableTimeranges[2],
    weeks: cleanSelectableTimeranges[1],
    months: cleanSelectableTimeranges[0],
    years: cleanSelectableTimeranges[3]
  }

  const groupedTimerangeItemsForReactSelect = [
    {
      label: I18n.t('location.selectable_timeranges.custom'),
      icon: <Icon.FA icon={Icon.FaCalendar} />,
      value: { from: filter.from, to: filter.to },
      humanReadableKey: 'customDateRange'
    },
    {
      label: I18n.t('location.selectable_timeranges.days'),
      icon: <Icon.FA icon={Icon.FaCalendar} />,
      options: selectableTimerangeGroups.days
    },
    {
      label: I18n.t('location.selectable_timeranges.weeks'),
      icon: <Icon.FA icon={Icon.FaCalendar} />,
      options: selectableTimerangeGroups.weeks
    },
    {
      label: I18n.t('location.selectable_timeranges.months'),
      icon: <Icon.FA icon={Icon.FaCalendar} />,
      options: selectableTimerangeGroups.months
    },
    {
      label: I18n.t('location.selectable_timeranges.years'),
      icon: <Icon.FA icon={Icon.FaCalendar} />,
      options: selectableTimerangeGroups.years
    }
  ]

  return groupedTimerangeItemsForReactSelect
}

export default predefinedFilters
