import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import Typography from '../components/Typography'
import Headline from '../components/Headline'
import AlertBox from '../components/AlertBox'
import Button from '../components/Button'
import Card from '../components/Card'
import Checkbox from '../components/forms/Checkbox'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import Section from '../components/Section'
import SimpleSelect from '../components/forms/SimpleSelect'

import I18n from '../i18n'
import useLocalStorage, { localStorageKeys } from '../lib/use-local-storage'

function Signup({ user = {} }) {
  const [selectedPlanName, _setSelectedPlanName] = useLocalStorage(
    localStorageKeys.selectedPlanName,
    null
  )

  if (!selectedPlanName) return null

  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section row mid>
        <Section.Wrapper css={{ paddingTop: '4rem' }}>
          <Headline>
            {selectedPlanName !== 'free' && (
              <Typography tag="p" smaller>
                {sT('step')} 2/3
              </Typography>
            )}
            {sT('headline')}
          </Headline>
          <Card
            css={{
              padding: '2rem 3rem',
              margin: '2rem 0 4rem',
              maxWidth: '42rem'
            }}
          >
            <Form as="user" action="/signup" method="post" idPrefix="register_">
              {Object.keys(user.errors).length > 0 && (
                <AlertBox error css={{ marginBottom: '1rem', width: '100%' }}>
                  {sT('error')}
                </AlertBox>
              )}
              <Form.Field>
                <Input
                  label={sT('fields.first_name')}
                  name="first_name"
                  defaultValue={user.values.firstName || ''}
                  errors={user.errors.firstName}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  label={sT('fields.last_name')}
                  name="last_name"
                  defaultValue={user.values.lastName || ''}
                  errors={user.errors.lastName}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  label={sT('fields.company_name')}
                  name="company_name"
                  defaultValue={user.values.companyName || ''}
                  errors={user.errors.companyName}
                  required={selectedPlanName !== 'free'}
                />
              </Form.Field>
              <Form.Field>
                <SimpleSelect
                  label={sT('fields.company_type')}
                  name="company_type"
                  options={Object.entries(
                    I18n.t('company_types')
                  ).map(([value, label]) => ({ value, label }))}
                  errors={user.errors.companyType}
                  defaultValue={user.values.companyType}
                  required={selectedPlanName !== 'free'}
                />
              </Form.Field>
              <HiddenHoneyField>
                <Input
                  label={sT('fields.honey_user_name')}
                  name="user_name"
                  defaultValue={user.values.userName || ''}
                  errors={user.errors.userName}
                  tabIndex="-1"
                />
              </HiddenHoneyField>
              <Form.Field>
                <Input
                  label={sT('fields.email')}
                  name="email"
                  defaultValue={user.values.email || ''}
                  errors={user.errors.email}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  label={sT('fields.password')}
                  name="password"
                  type="password"
                  defaultValue={user.values.password || ''}
                  errors={user.errors.password}
                  required
                />
              </Form.Field>
              <>
                {selectedPlanName === 'free' && (
                  <Form.Field>
                    <Checkbox
                      name="personal_use"
                      label={sT('fields.personal_use')}
                      errors={user.errors.personal_use}
                      required
                    />
                  </Form.Field>
                )}
              </>
              <Form.Field>
                <Checkbox
                  name="terms"
                  label={sT('fields.accept_terms')}
                  errors={user.errors.terms}
                  required
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="newsletter_optin"
                  label={sT('fields.accept_newsletter')}
                  errors={user.errors.newsletterOptin}
                />
              </Form.Field>
              <Form.Actions>
                <Button type="submit">{sT('button')}</Button>
                <LoginLink href="/login">{sT('already_registered')}</LoginLink>
              </Form.Actions>
            </Form>
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.signup.${key}`)

const LoginLink = styled.a`
  font-size: 0.875rem;
  text-decoration: underline;
  margin-top: 1rem;
  text-align: center;
`

const HiddenHoneyField = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

Signup.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default Signup
