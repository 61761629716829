import React from 'react'
import CollapsibleSection from '../components/CollapsibleSection'
import { AdultOrChildCard } from '../components/LocationDetail/LocationStatsCard/AdultOrChildCard'
import LocationStatsCard, {
  statsObjectPropTypes
} from '../components/LocationDetail/LocationStatsCard/LocationStatsCard'
import { default as I18n } from '../i18n'

function AdultOrChildLocationDataSection({ stats }) {
  return (
    <CollapsibleSection
      css={{ marginTop: '1.3rem', marginBottom: '2rem' }}
      title={I18n.t('location.details.display_distinction_adults_and_children')}
      openByDefault
    >
      <LocationStatsCard headline={I18n.t('location.details.yesterday')}>
        <AdultOrChildCard statsObject={stats?.yesterday}></AdultOrChildCard>
      </LocationStatsCard>
      <LocationStatsCard headline={I18n.t('location.details.past_week')}>
        <AdultOrChildCard statsObject={stats?.lastWeek}></AdultOrChildCard>
      </LocationStatsCard>
      <LocationStatsCard headline={I18n.t('location.details.past_month')}>
        <AdultOrChildCard statsObject={stats?.lastMonth}></AdultOrChildCard>
      </LocationStatsCard>
      <LocationStatsCard
        headline={I18n.t('location.details.current_year_to_date')}
      >
        <AdultOrChildCard
          statsObject={stats?.thisYearToDate}
        ></AdultOrChildCard>
      </LocationStatsCard>
    </CollapsibleSection>
  )
}

AdultOrChildLocationDataSection.propTypes = {
  stats: statsObjectPropTypes
}

export default AdultOrChildLocationDataSection
