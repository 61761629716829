import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import { Trans } from '../i18n'
import Card from '../components/Card'
import Section from '../components/Section'
import Headline from '../components/Headline'
import Graph from '../components/Graph/Graph'

function GraphExample() {
  return (
    <GraphExampleSection mid>
      <Section.Wrapper>
        <GraphExampleCard>
          <Headline breakout>
            <Trans id="pages.methodology.graph_teaser.headline" />
          </Headline>
          <Trans id="pages.methodology.graph_teaser.content" copy />
          <GraphWrapper>
            <Graph plot={fakePlotData} aspectRatio={16 / 9} />
          </GraphWrapper>
        </GraphExampleCard>
      </Section.Wrapper>
    </GraphExampleSection>
  )
}

const GraphExampleSection = styled(Section)`
  padding: 1rem 0;

  ${p => p.theme.breakpoints.phoneUp} {
    padding: 3rem 0;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 5rem 0;
  }
`

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

const GraphExampleCard = styled(Card)`
  width: calc(100% + 2rem);
  padding: 1.375rem 2rem;
  margin: 0 -1rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 3rem 5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1em;

    ${p => p.theme.breakpoints.tabletUp} {
      font-size: 1.125rem;
    }
  }
`

// some fake data to show as example
const fakePedestrianCounts = [
  // prettier-ignore
  [276,129,41,31,40,131,351,513,669,1759,4499,7032,7314,7137,7987,8452,9335,10407,8760,4297,1679,1353,890,296],
  // prettier-ignore
  [1085,574,361,183,184,171,192,303,589,1499,2628,5894,9393,11364,11273,11983,12168,13825,13370,11029,6661,4449,3790,2305],
  // prettier-ignore
  [113,70,54,49,26,56,131,296,598,1092,2234,3882,5147,6080,5872,5869,6716,4738,2967,2583,1668,797,598,357]
]

const visibleCounts =
  fakePedestrianCounts[randomBetween(0, fakePedestrianCounts.length - 1)]

const fakePlotData = visibleCounts.map((pedestriansCount, i) => ({
  pedestriansCount,
  timestamp: dayjs()
    .startOf('day')
    .add(i, 'hour')
    .toISOString()
}))

function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default GraphExample
