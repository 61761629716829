import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Avatar = styled.img`
  display: block;
  width: ${props => props.size || '12.5rem'};
  height: ${props => props.size || '12.5rem'};
  border-radius: 50%;
`

Avatar.propTypes = {
  size: PropTypes.string
}

export default Avatar
