import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import CopyButton from '../components/CopyButton'
import I18n, { Trans } from '../i18n'
import Section from '../components/Section'
import SubscriptionSummary from '../components/SubscriptionSummary'
import Headline from '../components/Headline'
import Button from '../components/Button'
import Card from '../components/Card'
import ProfileForm from '../forms/ProfileForm'

function Profile({
  user,
  publicApiToken,
  subscribedToNewsletter,
  subscription
}) {
  return (
    <Section mid css={{ minHeight: '100%', padding: '3rem 0' }}>
      <ProfileWrapper>
        <Helmet>
          <title>{I18n.t('profile.title')}</title>
        </Helmet>
        <Card>
          <Headline>
            <Trans id="profile.headline" />
          </Headline>
          <ProfileForm
            values={user.values}
            errors={user.errors}
            hasSubscription={!!subscription}
          />
        </Card>
        <CardRow>
          <Card>
            {publicApiToken ? (
              <>
                <Headline css={{ marginBottom: '1rem' }}>
                  <Trans id="profile.api_token.present.headline" />
                </Headline>
                <CopyButton value={publicApiToken} />
                <Trans id="profile.api_token.present.info" copy />
                <ButtonsContainer>
                  <Button.Link
                    href="mailto:info@hystreet.com"
                    css={{ marginTop: '1rem' }}
                  >
                    <Trans id="profile.api_token.present.button" />
                  </Button.Link>
                  <Button.Link
                    outline
                    href="/developer"
                    css={{ marginTop: '1rem' }}
                  >
                    <Trans id="profile.api_token.developer_page" />
                  </Button.Link>
                </ButtonsContainer>
              </>
            ) : (
              <>
                <Headline css={{ marginBottom: '1rem' }}>
                  <Trans id="profile.api_token.generate.headline" />
                </Headline>
                <Trans id="profile.api_token.info" />
                <ButtonsContainer>
                  <Button.Link
                    href="/create_api_token"
                    data-method="put"
                    css={{ marginTop: '1rem' }}
                  >
                    <Trans id="profile.api_token.button" />
                  </Button.Link>
                  <Button.Link
                    outline
                    href="/developer"
                    css={{ marginTop: '1rem' }}
                  >
                    <Trans id="profile.api_token.developer_page" />
                  </Button.Link>
                </ButtonsContainer>
              </>
            )}
          </Card>
          <Card>
            <Headline css={{ marginBottom: '1rem' }}>
              <Trans id="profile.newsletter.headline" />
            </Headline>
            {subscribedToNewsletter ? (
              <>
                <Trans id="profile.newsletter.subscribed" copy />
                <Button.Link
                  outline
                  href="/optout"
                  data-method="delete"
                  data-confirm={I18n.t(
                    'profile.newsletter.unsubscribe_confirmation_text'
                  )}
                  css={{ marginTop: '2rem' }}
                >
                  <Trans id="profile.newsletter.unsubscribe" />
                </Button.Link>
              </>
            ) : (
              <>
                <Trans id="profile.newsletter.not_subscribed" copy />
                <Button.Link
                  href="/optin"
                  data-method="post"
                  css={{ marginTop: '2rem', alignSelf: 'flex-start' }}
                >
                  <Trans id="profile.newsletter.subscribe" />
                </Button.Link>
              </>
            )}
          </Card>
        </CardRow>
        <CardRow>
          <Card style={{ width: '100%' }}>
            <SubscriptionSummary subscription={subscription} />
          </Card>
        </CardRow>
        <CardRow>
          <Card style={{ width: '100%' }}>
            <Headline css={{ marginBottom: '2rem' }}>
              <Trans id="profile.end_account.headline" />
            </Headline>
            {subscription ? (
              <>
                <Trans id="profile.end_account.paid_plan_subline" copy />
                <Button.Link
                  outline
                  href="/contact"
                  css={{ marginTop: '2rem', alignSelf: 'flex-start' }}
                >
                  <Trans id="profile.end_account.paid_plan_button_text" />
                </Button.Link>
              </>
            ) : (
              <>
                <Trans id="profile.end_account.free_plan_subline" copy />
                <Button.Link
                  outline
                  href="/signup"
                  data-method="delete"
                  data-confirm={I18n.t('profile.end_account.confirmation_text')}
                  css={{ marginTop: '2rem', alignSelf: 'flex-start' }}
                >
                  <Trans id="profile.end_account.free_plan_button_text" />
                </Button.Link>
              </>
            )}
          </Card>
        </CardRow>
      </ProfileWrapper>
    </Section>
  )
}

const ProfileWrapper = styled(Section.Wrapper)`
  ${p => p.theme.breakpoints.phone} {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

const CardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    width: calc(50% - 0.5rem);

    ${p => p.theme.breakpoints.tablet} {
      width: 100%;
    }
  }
`

const ButtonsContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1170px) {
    flex-direction: row;
    a {
      width: calc(50% - 0.5rem);
    }
  }
`

Profile.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }).isRequired,
  publicApiToken: PropTypes.string,
  subscribedToNewsletter: PropTypes.bool,
  subscription: PropTypes.object
}

export default Profile
