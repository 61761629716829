import React from 'react'
import { Trans } from '../i18n'
import Quotes from './Quotes'

function MissionQuote() {
  return (
    <Quotes>
      <Quotes.Text css={{ lineHeight: '1.625' }}>
        <Trans id="pages.about.quote" copy />
      </Quotes.Text>
    </Quotes>
  )
}

export default MissionQuote
