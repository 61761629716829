import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import I18n, { Trans } from '../i18n'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import Button from '../components/Button'
import Section from '../components/Section'
import Headline from '../components/Headline'

function PasswordResetNew({ user }) {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Section.Wrapper
        css={{ maxWidth: '32rem', marginTop: '3rem', marginBottom: '3rem' }}
      >
        <Helmet>
          <title>{I18n.t('reset_password.new.headline')}</title>
        </Helmet>
        <Headline>
          <Trans id="reset_password.new.headline" />
        </Headline>
        <Form
          as="user"
          action="/password-reset"
          method="post"
          css={{ width: '100%' }}
        >
          <Form.Field>
            <Input
              label={I18n.t('reset_password.new.email')}
              defaultValue={user.values.email}
              errors={user.errors.email}
              name="email"
              light
              required
            />
          </Form.Field>
          <Form.Actions>
            <Button type="submit">
              {I18n.t('reset_password.new.submit_button_text')}
            </Button>
          </Form.Actions>
        </Form>
      </Section.Wrapper>
    </Section>
  )
}

PasswordResetNew.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default PasswordResetNew
