import { useRef } from 'react'
import { createConsumer } from '@rails/actioncable'

export default function useCsvChannel() {
  const url = '/cable'
  const consumer = useRef()
  const CsvChannel = useRef()

  function connect({ onReceive }) {
    consumer.current = createConsumer(url)

    return new Promise((resolve, reject) => {
      if (CsvChannel.current) {
        resolve(CsvChannel.current)
        return
      }

      CsvChannel.current = consumer.current.subscriptions.create('CsvChannel', {
        connected() {
          resolve(this)
        },

        disconnected() {
          CsvChannel.current = null
        },

        rejected() {
          reject(this)
        },

        received(payload) {
          onReceive(payload)
        },

        requestMultiLocationCsv({ from, to, resolution, locationIds }) {
          this.perform('request_multi_location_csv', {
            from,
            to,
            resolution,
            location_ids: locationIds
          })
        }
      })
    })
  }

  return [connect]
}
