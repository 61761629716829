const DatetimeHelpers = {
  zoneFromTimestamp: timestamp => {
    try {
      return `Etc/GMT-${parseInt(timestamp.match(/.*\+(\d{2}):(\d{2})/)[1])}`
    } catch (_e) {
      return 'UTC'
    }
  }
}

export default DatetimeHelpers
