import styled from '@emotion/styled'

const ListHeadline = styled.h2`
  position: relative;
  color: ${p => p.theme.chroma.grey.css()};
  font-weight: bold;
  font-size: 0.825rem;
  line-height: 2;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 1.5rem;
  margin-bottom: 0.675rem;
`

export default ListHeadline
