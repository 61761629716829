import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Trans } from '../../i18n'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import usePlot from '../../lib/use-plot'
import useZoneDetails from '../../lib/use-zone-details'
import theme from '../../theme'
import * as Icon from '../Icon'
import useObjectTypePlot from '../../lib/use-object-type-plot'
import CompareIcon from './CompareIcon'
import CountFigure from './CountFigure'
import DetailsInfoBox from './DetailsInfoBox'
import InfoButton from './InfoButton'

function CountReading({ point }) {
  const [showDetailsInfo, setShowDetailsInfo] = useState(false)
  const [
    {
      objectType,
      objectSubtype,
      viewType,
      compareType,
      detailsType,
      resolution,
      zone
    }
  ] = useFilter()
  const {
    directions: { swapDirection, ltr, rtl }
  } = useLocation()
  const { plot, sums } = usePlot()
  const { sums: objectTypeSums } = useObjectTypePlot()
  const toggleDetailsInfo = useCallback(() => {
    setShowDetailsInfo(currentValue => !currentValue)
  }, [])

  const isAllowedResolutionForAverage = ['hour', 'day'].includes(resolution)
  const dataKey =
    objectType === 'PERSON' && objectSubtype === 'ALL'
      ? 'pedestriansCount'
      : 'count'

  const CountReadingHandlers = {
    getHeaderCount: (point, objectType, sums, objectTypeSums, dataKey) => {
      if (
        objectType === 'VEHICLE' ||
        objectSubtype === 'ADULT' ||
        objectSubtype === 'CHILD'
      ) {
        return point ? point[dataKey] : objectTypeSums[dataKey]
      }
      return point ? point[dataKey] : sums[dataKey]
    },

    // eslint-disable-next-line react/display-name
    iconForObjectType: (objectType, objectSubtype) => {
      if (objectType === 'VEHICLE') {
        if(objectSubtype === 'ALL') {
          return <Icon.FA icon={Icon.FaCarBus} style={{fontSize: '1.75rem'}} />
        }
        else if (objectSubtype === 'CAR') {
          return <Icon.FA icon={Icon.FaCar} style={{fontSize: '1.75rem'}} />
        } else if (objectSubtype === 'BUS') {
          return <Icon.FA icon={Icon.FaBusSimple} style={{fontSize: '1.75rem'}} />
        } else if (objectSubtype === 'TRAM') {
          return <Icon.FA icon={Icon.FaTrainSubway} style={{fontSize: '1.75rem'}} />
        } else if (objectSubtype === 'BICYCLE') {
          return <Icon.FA icon={Icon.FaBicycle} style={{fontSize: '1.75rem'}} />
        }
      } else if (objectType === 'PERSON') {
        if (objectSubtype === 'ALL') {
          return <Icon.Walking width="1.75rem" height="1.75rem" />
        }

        if (objectSubtype === 'ADULT') {
          return <Icon.Adult width="1.75rem" height="1.75rem" />
        }

        if (objectSubtype === 'CHILD') {
          return <Icon.Child width="1.75rem" height="1.75rem" />
        }
      }
    },

    setSubCompDisplayNames: () => {
      SubComps.MultiLocationCounts.displayName = 'MultiLocationCounts'
      SubComps.MultiLocationDetailCounts.displayName =
        'MultiLocationDetailCounts'
      SubComps.SingleLocationCounts.displayName = 'SingleLocationCounts'
      SubComps.SingleLocationDetailCounts.displayName =
        'SingleLocationDetailCounts'
      SubComps.ZoneCounts.displayName = 'ZoneCounts'
    }
  }

  const UIState = {
    showInfoButton:
      viewType === 'details' ||
      (viewType === 'compare' &&
        compareType === 'average' &&
        isAllowedResolutionForAverage),
    showCompareCount:
      compareType === 'timerange' ||
      compareType === 'location' ||
      (compareType === 'average' && isAllowedResolutionForAverage),
    showMultiLocationCounts:
      viewType === 'compare' && compareType === 'multiLocation',
    showDetailCounts: viewType === 'details',
    showZoneCounts: viewType === 'zone',
    showSingleZoneCounts: viewType === 'zone' && zone !== 'all',
    showAllZoneCounts: viewType === 'zone' && zone === 'all',
    showVehicleCounts: viewType === 'vehicle',
    showZoneDetails: useZoneDetails().showZoneDetails,
    headerIcon: CountReadingHandlers.iconForObjectType(
      objectType,
      objectSubtype
    ),
    headerCount: CountReadingHandlers.getHeaderCount(
      point,
      objectType,
      sums,
      objectTypeSums,
      dataKey
    )
  }

  const SubComps = {
    MultiLocationCounts: ({ point }) => {
      return (
        <MultiLocationBigCounts>
          {point?.comparePedestrianCounts?.map(
            (comparePedestriansCountObj, index) => {
              if (index === 0) {
                return (
                  <CountFigure
                    key={comparePedestriansCountObj.chartKey}
                    textRight={false}
                    color={comparePedestriansCountObj.color}
                    icon={<Icon.Walking width="1.75rem" height="1.75rem" />}
                    value={point[comparePedestriansCountObj.chartKey]}
                    hint={comparePedestriansCountObj.locationName}
                    dense
                  />
                )
              }
            }
          )}
        </MultiLocationBigCounts>
      )
    },

    MultiLocationDetailCounts: () => {
      return (
        <MoreCounts dense>
          {point?.comparePedestrianCounts?.map(
            (comparePedestriansCountObj, index) => {
              // The first location in Multi-Select is always displayed more prominently
              // than the ones we compare to it (which we create below). Thats
              // why the first one is skipped here.
              if (index === 0) {
                return null
              }
              return (
                <CountFigure
                  key={comparePedestriansCountObj.chartKey}
                  small
                  color={comparePedestriansCountObj.color}
                  icon={<Icon.Walking width="1.5rem" height="1.5rem" />}
                  value={point[comparePedestriansCountObj.chartKey]}
                  hint={comparePedestriansCountObj.locationName}
                  textRight={false}
                  dense
                />
              )
            }
          )}
        </MoreCounts>
      )
    },

    SingleLocationCounts: () => {
      return (
        <BigCounts>
          {/** Single Zone */}
          {UIState.showSingleZoneCounts ? (
            <CountFigure
              textRight={false}
              icon={UIState.headerIcon}
              value={
                point?.zones?.has(Number(zone))
                  ? point?.zones?.get(Number(zone))['pedestriansCount']
                  : filteredZone?.pedestriansCount
              }
            />
          ) : (
            {
              /** All Zones */
            } && (
              <CountFigure
                textRight={false}
                icon={UIState.headerIcon}
                value={UIState.headerCount}
              />
            )
          )}

          {viewType === 'compare' && UIState.showCompareCount && (
            <CountFigure
              textRight={false}
              themeColor="accent"
              icon={
                <CompareIcon
                  type={compareType}
                  width="1.72rem"
                  height="1.2rem"
                />
              }
              value={
                point
                  ? point.comparePedestriansCount
                  : sums.comparePedestriansCount
              }
            />
          )}
        </BigCounts>
      )
    },

    SingleLocationDetailCounts: () => {
      return (
        <MoreCounts>
          {detailsType === 'height' && (
            <>
              <CountFigure
                small
                themeColor="chartAreas[0].text"
                icon={<Icon.Adult width="1.4rem" height="1.4rem" />}
                value={
                  point?.detailsPedestriansCount?.adult ?? sums.details?.adult
                }
              />
              <CountFigure
                small
                themeColor="chartAreas[1].text"
                icon={<Icon.Child width="1.4rem" height="1.1rem" />}
                value={
                  point?.detailsPedestriansCount?.child ?? sums.details?.child
                }
              />
            </>
          )}
          {detailsType === 'direction' && (
            <>
              <CountFigure
                small
                themeColor={
                  swapDirection ? 'chartAreas[1].text' : 'chartAreas[0].text'
                }
                icon={<Icon.DirectionRight width="1.5rem" height="0.625rem" />}
                value={
                  point?.detailsPedestriansCount?.[ltr.dataKey] ??
                  sums.details?.[ltr.dataKey]
                }
                hint={ltr.label}
              />
              <CountFigure
                small
                themeColor={
                  swapDirection ? 'chartAreas[0].text' : 'chartAreas[1].text'
                }
                icon={<Icon.DirectionLeft width="1.5rem" height="0.625rem" />}
                value={
                  point?.detailsPedestriansCount?.[rtl.dataKey] ??
                  sums.details?.[rtl.dataKey]
                }
                hint={rtl.label}
              />
            </>
          )}
        </MoreCounts>
      )
    },

    ZoneCounts: () => {
      return (
        <MoreCounts>
          {zone === 'all' ? (
            <>
              {sums.zones.map(zone => {
                const colorKey = zone?.id == 99 ? 0 : zone?.id
                return (
                  <CountFigure
                    key={zone?.id}
                    small
                    themeColor={`zones[${colorKey}].fill`}
                    icon={<Icon.Zone2 width="1.5rem" height="1.5rem" />}
                    value={
                      point?.zones?.has(zone?.id)
                        ? point?.zones?.get(zone?.id)['pedestriansCount']
                        : zone?.pedestriansCount
                    }
                    hint={zone?.id !== 99 ? `Zone ${zone?.id}` : '\u2014'}
                  />
                )
              })}
            </>
          ) : (
            UIState.showZoneDetails && (
              <>
                <CountFigure
                  small
                  themeColor={`zones[${zone}].fillComplement`}
                  icon={
                    <Icon.DirectionRight width="1.5rem" height="0.625rem" />
                  }
                  value={
                    point?.zones?.has(Number(zone))
                      ? point?.zones?.get(Number(zone))[ltr.dataKey]
                      : filteredZone?.[ltr.dataKey]
                  }
                  hint={ltr.label}
                />
                <CountFigure
                  small
                  themeColor={`zones[${zone}].fill`}
                  icon={<Icon.DirectionLeft width="1.5rem" height="0.625rem" />}
                  value={
                    point?.zones?.has(Number(zone))
                      ? point?.zones?.get(Number(zone))[rtl.dataKey]
                      : filteredZone?.[rtl.dataKey]
                  }
                  hint={rtl.label}
                />
              </>
            )
          )}
        </MoreCounts>
      )
    }
  }

  CountReadingHandlers.setSubCompDisplayNames()

  if (plot.length < 1) return null

  const filteredZone = sums?.zones?.filter(item => item.id === Number(zone))[0]
  return (
    <FullWidthBox>
      {/** Header-Counts in Multi-Location Compare Mode */}
      {UIState.showMultiLocationCounts && (
        <SubComps.MultiLocationCounts point={point} />
      )}

      {/** Header-Counts in Single-Location View */}
      {!UIState.showMultiLocationCounts && <SubComps.SingleLocationCounts />}

      {/** Counts in Details View */}
      {UIState.showDetailCounts && <SubComps.SingleLocationDetailCounts />}

      {/** Counts in Zone View */}
      {UIState.showZoneCounts && <SubComps.ZoneCounts />}

      {/** Detail Counts in Multi-Location Compare Mode */}
      {UIState.showMultiLocationCounts && (
        <SubComps.MultiLocationDetailCounts />
      )}

      {UIState.showInfoButton && viewType === 'compare' && <InfoButtonLine />}

      <RelativeBox>
        <AnimatePresence>
          {UIState.showInfoButton && (
            <InfoButtonContainer
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
            >
              <InfoButton onClick={toggleDetailsInfo} />
            </InfoButtonContainer>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showDetailsInfo && (
            <DetailsInfoContainer
              initial={{ y: -5, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -5, opacity: 0 }}
            >
              <DetailsInfoBox onClose={toggleDetailsInfo}>
                <Trans
                  id={
                    viewType === 'details'
                      ? `location.info_box.${detailsType}`
                      : 'location.average_info'
                  }
                  copy
                />
              </DetailsInfoBox>
            </DetailsInfoContainer>
          )}
        </AnimatePresence>
      </RelativeBox>
    </FullWidthBox>
  )
}

CountReading.propTypes = {
  point: PropTypes.shape({
    pedestriansCount: PropTypes.number,
    comparePedestriansCount: PropTypes.number,
    detailsPedestriansCount: PropTypes.shape({
      adult: PropTypes.number,
      child: PropTypes.number,
      ltr: PropTypes.number,
      rtl: PropTypes.number
    }),
    comparePedestrianCounts: PropTypes.arrayOf(
      PropTypes.shape({
        chartKey: PropTypes.string,
        color: PropTypes.string,
        locationName: PropTypes.string
      })
    )
  })
}

const MultiLocationBigCounts = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  fontSize: '1.5rem',
  fontWeight: 900,

  [props.theme.breakpoints.desktop]: {
    fontSize: '1.25rem',
    svg: {
      transform: 'scale(0.8)'
    }
  },

  [props.theme.breakpoints.tablet]: {
    fontSize: '1rem',
    svg: {
      transform: 'scale(0.6)'
    }
  }
}))

const FullWidthBox = styled.div`
  flex: 1;
`

const RelativeBox = styled.div`
  position: relative;
`

const BigCounts = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1.5rem',
  fontWeight: 900,

  [props.theme.breakpoints.desktop]: {
    fontSize: '1.25rem',
    svg: {
      transform: 'scale(0.8)'
    }
  },

  [props.theme.breakpoints.tablet]: {
    fontSize: '1rem',
    svg: {
      transform: 'scale(0.6)'
    }
  }
}))

const MoreCounts = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '0.8rem',
  paddingLeft: props.dense ? 0 : '0.8rem',
  marginLeft: props.dense ? 0 : '0.8rem',
  borderLeft: '1px solid black',
  borderLeftColor: theme.chroma.lightGrey.css(),

  fontSize: '1rem',
  fontWeight: 700,
  maxHeight: 140,
  flexWrap: 'wrap',

  [props.theme.breakpoints.tablet]: {
    fontSize: '0.8rem',
    marginTop: 0,
    marginLeft: '0.85rem',
    svg: {
      transform: 'scale(0.7)'
    }
  },

  [props.theme.breakpoints.tabletUp]: {
    maxHeight: 140
  }
}))

const InfoButtonContainer = styled(motion.div)(props => ({
  display: 'inline-flex',
  position: 'relative',
  left: '0.2375rem',
  top: '-0.125rem',

  [props.theme.breakpoints.tablet]: {
    display: 'none'
  }
}))

const InfoButtonLine = styled.div(props => ({
  width: '1px',
  height: '1rem',
  marginLeft: '0.8rem',
  borderLeft: '1px solid',
  borderColor: props.theme.chroma.lightGrey.css(),

  [props.theme.breakpoints.tablet]: {
    display: 'none'
  }
}))

const DetailsInfoContainer = styled(motion.div)(props => ({
  position: 'absolute',
  zIndex: 1,
  top: '0.375rem',
  left: '-0.2rem',

  [props.theme.breakpoints.tablet]: {
    display: 'none'
  }
}))

export default CountReading
