import React from 'react'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Figure from '../components/Figure'
import * as Icon from '../components/Icon'
import Section from '../components/Section'

const listItems = [
  'live_data',
  'movement_direction',
  'custom_analysis',
  'adult_child',
  'time_series',
  'weather_data',
  'zone_discrimination'
]

function ApiFeatures() {
  return (
    <Section mid>
      <Section.Wrapper>
        <List>
          {listItems.map((key, i) => {
            const prefix = `pages.developer.api.features.${key}.`
            const IconComponent = Icon[I18n.t(prefix + 'icon')] // eslint-disable-line import/namespace
            const isOddList = !listItems.length % 2 === 0
            const lastItemInOddList = isOddList && listItems.length - 1

            return (
              <ListItem key={key}>
                <Figure
                  icon={<IconComponent width={84} height={84} />}
                  stackingLayoutMobile
                  iconOnRight={i % 2 === 0 && i !== lastItemInOddList}
                >
                  <Figure.Text>
                    <Trans id={prefix + 'text'} />
                  </Figure.Text>
                </Figure>
              </ListItem>
            )
          })}
        </List>
      </Section.Wrapper>
      <ButtonContainer center>
        <Button.Link outline href="/methodology">
          {I18n.t('pages.developer.api.methodology.button_text')}
        </Button.Link>
      </ButtonContainer>
      <Section.Skew position="bottom" mid direction="down" />
    </Section>
  )
}

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-bottom: 2.5rem;
  padding-top: 4rem;

  ${props => props.theme.breakpoints.tabletUp} {
    margin-left: -1rem;
    margin-right: -1rem;
    width: auto;
  }
`

const ListItem = styled.li`
  width: 100%;
  padding: 1rem 0;

  ${props => props.theme.breakpoints.phoneUp} {
    width: 50%;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  ${props => props.theme.breakpoints.tabletUp} {
    width: 50%;
    padding: 1rem 2.8rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0 3rem;

  ${props => props.theme.breakpoints.tabletUp} {
    padding-bottom: 2rem;
  }
`

export default ApiFeatures
