import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Trans } from '../i18n'

import theme from '../theme'

import * as Icon from '../components/Icon'
import Button from '../components/Button'
import Section from '../components/Section'
import TopLocation from '../components/TopLocation'

import PlusIcon from './assets/plus-big.svg'

function TopLocations({ locations }) {
  return (
    <CustomSection>
      <Section.Wrapper>
        <IconWrapper>
          <Icon.PartnerNow width={150} height={150} />
        </IconWrapper>
        <List>
          {locations.map((location, i) => (
            <ListItem key={location.city + location.name} first={i === 0}>
              <TopLocation rank={i + 1} {...location} highlighted={i === 0} />
            </ListItem>
          ))}
        </List>
        <Cta>
          <PlusIcon />
          <Trans id="pages.home.top_locations.cta_text" />
          <Button.Link
            href="/partner"
            css={{
              borderWidth: '0.125rem',
              borderColor: `${theme.chroma.white.hex()}`,
              margin: '2rem auto 0.5rem auto',
              width: 'fit-content',
              fontSize: '0.75rem'
            }}
          >
            <DesktopButtonText>
              <Trans id="pages.home.top_locations.cta_button_text_desktop" />
            </DesktopButtonText>
            <MobileButtonText>
              <Trans id="pages.home.top_locations.cta_button_text_mobile" />
            </MobileButtonText>
          </Button.Link>
        </Cta>
      </Section.Wrapper>
    </CustomSection>
  )
}

TopLocations.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

const CustomSection = styled(Section)`
  padding: 2.25rem 0 5rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 6rem 0 7.5rem;
  }
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
`

const ListItem = styled.div`
  width: 100%;
  display: ${props => (props.first ? 'flex' : 'none')};
  justify-content: center;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 2rem;
    display: flex;
    width: ${props => (props.first ? '100%' : '50%')};
  }

  ${p => p.theme.breakpoints.desktopUp} {
    flex: 1;
  }
`

const Cta = styled.div`
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.425;
  padding: 1.75rem 1.25rem;
  color: ${props => props.theme.chroma.white.hex()};
  background-color: ${props => props.theme.chroma.accent.hex()};
  box-shadow: 0px 0.125rem 0.625rem 0px rgba(0, 0, 0, 0.28);
  width: 100%;
  margin: 0 auto;
  svg {
    display: none;
    position: absolute;
    width: 177px;
    height: 177px;
    left: 0;
    bottom: 0;
    transform: translate(-148px, 28px);
    z-index: -1;
  }
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.4rem;
    padding: 1.75rem;
    width: 80%;
  }
  ${p => p.theme.breakpoints.desktopUp} {
    width: 60%;
    svg {
      display: block;
    }
  }
`

const DesktopButtonText = styled.span`
  ${p => p.theme.breakpoints.tablet} {
    display: none;
  }
`

const MobileButtonText = styled.span`
  ${p => p.theme.breakpoints.tabletUp} {
    display: none;
  }
`

const IconWrapper = styled.div`
  position: relative;
  display: none;
  ${p => p.theme.breakpoints.tabletUp} {
    display: block;
  }
  svg {
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
  }
`

export default TopLocations
