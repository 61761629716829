import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function PersonInfo({ personData }) {
  return (
    <Wrapper>
      <Name>{personData.name}</Name>
      <Role>{personData.role}</Role>
      <Text>{personData.phone}</Text>
      <Text>{personData.email}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const Text = styled.p`
  margin-bottom: 0.5rem;

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 0.3rem;
  }
`

const Role = styled(Text)`
  font-weight: 700;

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 1.25rem;
  }
`

const Name = styled(Text)`
  text-transform: uppercase;
  font-weight: 900;
`

PersonInfo.propTypes = {
  personData: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }).isRequired
}

export default PersonInfo
