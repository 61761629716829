import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import theme from '../theme'
import I18n, { Trans } from '../i18n'
import useMoney from '../lib/use-money'

import { Edit } from './Icon'
import Typography from './Typography'
import CloseButton from './CloseButton'
import Button from './Button'

function PlanSummary({
  plan,
  locations,
  onLocationRemoved,
  allSelected,
  editButtonLink,
  showTotal = false,
  nextButtonLink
}) {
  const { toEuros } = useMoney()

  const { error, price, isFlatrate } = useMemo(() => {
    const maxLocations = plan.yearlyPricesCents.length

    if (allSelected && !plan.flatratePossible) {
      return {
        error: 'flatrate_not_possible',
        price: sT('individual')
      }
    }

    if (locations.length > maxLocations && !plan.flatratePossible) {
      return { error: 'too_many_locations', price: '-' }
    }

    if (allSelected || locations.length > maxLocations) {
      return {
        price: plan.yearlyPricesCents[maxLocations - 1],
        isFlatrate: true
      }
    }

    if (locations.length === 0) {
      return {
        price: plan.yearlyPricesCents[0],
        error: 'no_locations'
      }
    }

    return {
      price: plan.yearlyPricesCents[locations.length - 1],
      isFlatrate: false
    }
  }, [plan, locations, allSelected])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%'
      }}
    >
      <PlanBox>
        <TextLine tag="h4">
          <span>{sT(`${plan.name}`)}</span>
          <span>
            {typeof price !== 'number' ? (
              price
            ) : plan.discountRate === 1 ? (
              `${toEuros(price)} ${sT('per_year')}`
            ) : (
              <>
                <span css={{ fontWeight: 'bold' }}>
                  {toEuros(price * plan.discountRate)}
                </span>{' '}
                <span css={{ fontWeight: 'normal' }}>
                  <span css={{ textDecoration: 'line-through' }}>
                    {toEuros(price)}
                  </span>
                  {sT('per_year')}
                </span>
              </>
            )}
          </span>
        </TextLine>
        <TextLine>
          <span>
            {isFlatrate
              ? sT('all')
              : `${locations.length} ${
                  locations.length === 1 ? sT('location') : sT('locations')
                }`}
          </span>
          <span>
            {typeof price === 'number' &&
              `${toEuros((price * plan.discountRate) / 12)} x 12 ${sT(
                'months'
              )}`}
          </span>
        </TextLine>
        <TextLine>
          <span>
            {plan.discount && (
              <Discount>
                {((1 - plan.discountRate) * 100).toFixed(0)} %{' '}
                {I18n.t('common.discount')}
              </Discount>
            )}
          </span>
          <span>{!showTotal && I18n.t('common.vat_extra')}</span>
        </TextLine>
        {showTotal && (
          <>
            <Divider />
            <TextLine>
              <span>{sT('vat')}</span>
              <span>{toEuros(price * plan.discountRate * 0.19)}</span>
            </TextLine>
            <TextLine tag="h4">
              <span>{sT('total')}</span>
              <span>{toEuros(price * plan.discountRate * 1.19)}</span>
            </TextLine>
          </>
        )}
      </PlanBox>
      <Typography tag="h4">
        {sT('selected_locations')}
        <span css={{ fontWeight: 'normal' }}>
          {' '}
          ({isFlatrate ? `${sT('all')}` : locations.length})
        </span>{' '}
        {editButtonLink && (
          <a href={editButtonLink}>
            <Edit />
          </a>
        )}
      </Typography>
      <div css={{ overflowY: 'auto', margin: '1rem 0' }}>
        {error ? (
          <Trans id={`components.plan_summary.error.${error}`} />
        ) : isFlatrate ? (
          sT('flatrate')
        ) : (
          locations.map(location => (
            <LocationCard key={location.id}>
              <div>
                <Typography tag="p" small>
                  {location.city}
                </Typography>
                <Typography tag="p">{location.name}</Typography>
              </div>
              {onLocationRemoved && (
                <CloseButton onClick={() => onLocationRemoved(location.id)} />
              )}
            </LocationCard>
          ))
        )}
      </div>
      {nextButtonLink && (
        <Button.Link
          css={{ marginTop: 'auto' }}
          href={nextButtonLink}
          disabled={error}
        >
          {sT('next')}
        </Button.Link>
      )}
    </div>
  )
}

const sT = (key, args) => I18n.t(`components.plan_summary.${key}`, args)

function TextLine({ children, tag = 'p' }) {
  return (
    <Typography
      styleOverrides={{ display: 'flex', justifyContent: 'space-between' }}
      tag={tag}
    >
      {children}
    </Typography>
  )
}
TextLine.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired
}

const Divider = styled.hr`
  border: 1px solid #ccc;
`

const PlanBox = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.chroma.darkerGrey.css()};
  margin-bottom: 2rem;
`
const LocationCard = styled.div`
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.chroma.nearlyWhite.css()};
`

const Discount = styled.span`
  background-color: ${theme.chroma.dustyBlue.hex()};
  padding: 0.1rem 0.3rem;
  border-radius: 0.25rem;
  line-height: 1.75rem;
`

PlanSummary.propTypes = {
  plan: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired,
  showTotal: PropTypes.bool,
  onLocationRemoved: PropTypes.func,
  editButtonLink: PropTypes.string,
  nextButtonLink: PropTypes.string
}

export default PlanSummary
