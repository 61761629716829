import { timestampToMonthLabel } from './datetime-transformers'

export function transformLocationsToLocationSelectorOptions(locations) {
  const availableLocations = []
  Object.values(locations).forEach(location => {
    availableLocations.push({
      value: location,
      label: `${location.city} - ${location.name}`,
      abbreviation: `${location.id}`
    })
  })
  return availableLocations
}

export function transformLocationsToColoredLocationSelectorOptions(
  locations,
  colors
) {
  const availableLocations = []
  Object.values(locations).forEach((location, index) => {
    availableLocations.push({
      value: {
        ...location,
        bgColor: colors ? colors[index % colors.length] : null
      },
      label: `${location.city} - ${location.name}`,
      abbreviation: `${location.id}`
    })
  })
  return availableLocations
}

export function transformLocationsToMonhtlyLineChartData(locations, chartKeys) {
  const rawData = [...locations]

  if (chartKeys.length !== locations.length) {
    throw new Error(
      'Cannot transform API responses to plottable Chart-Data with unequal lengths of chartKeys and measurements.'
    )
  }

  // Assign the chartkey to every measurement
  rawData.map((response, index) => {
    response.measurements = response.measurements.map(
      (measurement, measurementIndex) => {
        return {
          timestamp: timestampToMonthLabel(new Date(measurement.timestamp)),
          [chartKeys[index]]: measurement.pedestriansCount
        }
      }
    )
  })

  // Aggregate all measurements together in a single array
  const arrayOfMeasurementArrays = [...rawData].map(response => {
    return response.measurements
  })
  const allMeasurementsWithChartKeys = Array.prototype.concat.apply(
    [],
    arrayOfMeasurementArrays
  )

  const plotData = []

  // Merge array elements with the same month in timestamp
  const NUMBER_OF_MONTHS = 6

  for (let i = 0; i < NUMBER_OF_MONTHS; i++) {
    const mergedObject = Object.assign(
      {},
      allMeasurementsWithChartKeys[i],
      allMeasurementsWithChartKeys[i + NUMBER_OF_MONTHS],
      allMeasurementsWithChartKeys[i + 2 * NUMBER_OF_MONTHS]
    )
    plotData.push(mergedObject)
  }

  return plotData
}

export function transformLocationsToLineChartData(locations, chartKeys) {
  if (chartKeys.length !== locations.length) {
    throw new Error(
      'Cannot transform API responses to plottable Chart-Data with unequal lengths of chartKeys and measurements.'
    )
  }

  if (locations.length === 0) {
    return []
  }

  // Deep copy input parameters to assure idempotency of the transformation
  let rawData = [...locations]

  // Assign the chartkey to every measurement
  rawData.map((location, index) => {
    location.measurements = location.measurements.map(measurement => {
      return {
        timestamp: measurement.timestamp,
        //[chartKeys[index]]: measurement.pedestriansCount
        [chartKeys[index]]: {
          pedestriansCount: measurement.pedestriansCount,
          label: `${location.city} - ${location.name}`,
          belongsToLocation: location.id,
          ...measurement
        }
      }
    })
  })

  const plotData = []

  // Merge array elements with the same month in timestamp
  const NUMBER_OF_MEASUREMENTS = rawData[0].measurements.length

  for (let i = 0; i < NUMBER_OF_MEASUREMENTS; i++) {
    let resultObj = {}
    for (let j = 0; j < rawData.length; j++) {
      resultObj.timestamp = rawData[0].measurements[i].timestamp
      resultObj[chartKeys[j].toString()] =
        rawData[j].measurements[i][chartKeys[j]]
    }
    plotData.push(resultObj)
  }

  return plotData
}
