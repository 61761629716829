import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Trans } from '../i18n'

import Button from '../components/Button'
import Section from '../components/Section'

import BackgroundImage from '../components/assets/images/hero-bg.jpg'

function Hero({ loggedIn }) {
  const url = loggedIn ? '/locations' : '/products'
  const text = loggedIn
    ? 'pages.home.hero.button_text_logged_in'
    : 'pages.home.hero.button_text'

  return (
    <Section>
      <Wrapper>
        <Title>
          <Trans id="pages.home.hero.headline" />
        </Title>
        <Subline>
          <Trans id="pages.home.hero.subline" />
        </Subline>
        <Claim>
          <Trans id="pages.home.hero.claim" />
        </Claim>
        <Button.Link href={url}>
          <Trans id={text} />
        </Button.Link>
      </Wrapper>
      <Section.Skew position="bottom" inset light />
    </Section>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2.5rem 2rem;
  padding-bottom: 4rem;
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: ${p => p.theme.chroma.white.hex()};

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 7.5rem 2rem;
    padding-bottom: 11rem;
  }
`

const Title = styled.h1`
  display: inline-block;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  background-color: ${p => p.theme.chroma.white.hex()};
  color: ${p => p.theme.chroma.base.hex()};
  font-size: 1.125rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 1rem 3.5rem;
    margin-bottom: 0.5rem;
    font-size: 2.85rem;
  }
`

const Subline = styled.p`
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  letter-spacing: 1.45px;
  line-height: 1.5;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 2rem;
  }
`

const Claim = styled.p`
  margin-bottom: 1.25rem;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
    max-width: 27.5rem;
  }
`

Hero.propTypes = {
  loggedIn: PropTypes.bool
}

export default Hero
