import styled from '@emotion/styled'

const SectionsConnector = styled.div`
  margin-bottom: -3rem;

  ${p => p.theme.breakpoints.phoneUp} {
    margin-bottom: -14rem;
  }
`

export default SectionsConnector
