/* eslint-disable react/display-name */
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import Button from '../components/Button'
import LoadingIndicator from '../components/Graph/LoadingIndicator'
import Hide from '../components/Hide'
import InfoBanner from '../components/InfoBanner'
import Breadcrumb from '../components/LocationDetail/Breadcrumb'
import GraphCard from '../components/LocationDetail/GraphCard'
import HeaderBar from '../components/LocationDetail/HeaderBar'
import MobileControlSheet from '../components/LocationDetail/MobileControlSheet'
import Modals from '../components/LocationDetail/Modals'
import Section from '../components/Section'
import AdvertisementBanner from '../components/AdvertisementBanner'
import I18n, { Trans } from '../i18n'
import { AuthorizationProvider } from '../lib/use-authorization'
import { FilterContext, FilterProvider } from '../lib/use-filter'
import { LocationProvider } from '../lib/use-location'
import { MapZoomProvider } from '../lib/use-map-zoom'
import { ModalStateProvider } from '../lib/use-modal-state'
import { PlotProvider } from '../lib/use-plot'
import SearchContextProvider from '../lib/use-search'
import { ZoneDetailsProvider } from '../lib/use-zone-details'
import AdultOrChildLocationDataSection from '../sections/AdultOrChildLocationDataSection'
import PedestrianCountLocationDataSection from '../sections/PedestrianCountLocationDataSection'
import WalkingDirectionLocationDataSection from '../sections/WalkingDirectionLocationDataSection'
import ZoneLocationDataSection from '../sections/ZoneLocationDataSection'
import { useQueryLocationStatistics } from '../lib/api/location/use-location-api'
import { PlotProviderV2 } from '../lib/use-object-type-plot'

const isSSR = typeof window === 'undefined'

function LocationShow({
  id,
  locations,
  mapLocations,
  initialQuery,
  favLocationIds,
  averagesPerDay,
  averagesPerHour,
  map,
  originalUrl,
  userFullName,
  showPartnerBanner,
  showAdvertisingBanner,
  permissions
}) {
  const {
    data: locationStatistics,
    status: locationStatisticsRequestStatus
  } = useQueryLocationStatistics(id, !!map?.swapDirection)

  const SubComps = {
    KPISection: () => {
      return (
        <React.Fragment>
          {locationStatisticsRequestStatus === 'error' && (
            <LocationStatsCard
              headline={I18n.t(
                'location.details.statistics_could_not_be_fetched'
              )}
              message={I18n.t(
                'location.details.statistics_could_not_be_fetched_please_try_again_later'
              )}
            ></LocationStatsCard>
          )}

          {locationStatisticsRequestStatus === 'loading' ? (
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                marginTop: '2rem',
                marginBottom: '2rem'
              }}
            >
              <LoadingIndicator active />
            </div>
          ) : (
            <>
              <PedestrianCountLocationDataSection stats={locationStatistics} />

              {permissions.accessLocationKpis && (
                <>
                  <WalkingDirectionLocationDataSection
                    stats={locationStatistics}
                    map={map}
                  />
                  <ZoneLocationDataSection
                    stats={locationStatistics}
                    hasMap={false} // false since we can't display zones on the map yet.
                  />
                  <AdultOrChildLocationDataSection stats={locationStatistics} />
                </>
              )}
            </>
          )}
        </React.Fragment>
      )
    }
  }

  const UIState = {
    showKPISection: filter => {
      return (
        permissions.accessLocationKpis &&
        filter.objectType === 'PERSON' &&
        !(
          filter.viewType === 'compare' &&
          filter.compareType === 'multiLocation'
        )
      )
    }
  }

  return (
    <AuthorizationProvider initialPermissions={permissions}>
      <FilterProvider originalUrl={originalUrl} location={locations[id]}>
        <LocationProvider
          id={id}
          locations={locations}
          averagesPerDay={averagesPerDay}
          averagesPerHour={averagesPerHour}
          swapDirection={!!map?.swapDirection}
        >
          <ZoneDetailsProvider>
            <PlotProvider>
              <PlotProviderV2>
                <ModalStateProvider>
                  <Section css={{ minHeight: '100%' }} mid>
                    <Helmet>
                      <title>
                        {I18n.t('location.title', {
                          street: locations[id].name,
                          city: locations[id].city
                        })}
                      </title>
                    </Helmet>
                    {showAdvertisingBanner && (
                      <AdvertisementBanner cookieName="advertisement_banner_shown" />
                    )}
                    <LocationWrapper>
                      <SearchContextProvider
                        locations={locations}
                        favLocationIds={favLocationIds}
                        initialQuery={initialQuery || ''}
                      >
                        <MapZoomProvider>
                          <FilterContext.Consumer>
                            {filter => {
                              if (isSSR) {
                                return
                              }

                              return (
                                <Modals
                                  favLocationIds={favLocationIds}
                                  mapLocations={mapLocations}
                                  map={map}
                                  userFullName={userFullName}
                                  isDirectionActive={
                                    filter.viewType === 'details' &&
                                    filter.detailsType === 'direction'
                                  }
                                />
                              )
                            }}
                          </FilterContext.Consumer>
                        </MapZoomProvider>
                      </SearchContextProvider>
                      {showPartnerBanner && (
                        <InfoBanner
                          cookieName="InfoBannerLocation"
                          style={{ marginBottom: '1.5rem' }}
                        >
                          <InfoWrapper>
                            <Heading>
                              <Trans id="location.infoheading" />{' '}
                            </Heading>
                          </InfoWrapper>
                          <ButtonWrapper>
                            <CustomButton href="/partner">
                              <Trans id="location.buttontext" />
                            </CustomButton>
                          </ButtonWrapper>
                        </InfoBanner>
                      )}
                      <BreadcrumbWrapper>
                        <Breadcrumb />
                      </BreadcrumbWrapper>

                      <CardsContainer>
                        <Hide when={bp => bp.tablet}>
                          <HeaderBar hasMap={!!map} />
                        </Hide>
                        <Hide when={bp => bp.tabletUp}>
                          <MobileControlSheet hasMap={!!map} />
                        </Hide>

                        <GraphCard />
                      </CardsContainer>

                      <FilterContext.Consumer>
                        {filterContextProps => {
                          const filter = filterContextProps[0]
                          if (UIState.showKPISection(filter)) {
                            return <SubComps.KPISection />
                          } else {
                            return null
                          }
                        }}
                      </FilterContext.Consumer>
                    </LocationWrapper>
                  </Section>
                </ModalStateProvider>
              </PlotProviderV2>
            </PlotProvider>
          </ZoneDetailsProvider>
        </LocationProvider>
      </FilterProvider>
    </AuthorizationProvider>
  )
}

LocationShow.propTypes = {
  id: PropTypes.number.isRequired,
  locations: PropTypes.object.isRequired,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  averagesPerDay: PropTypes.object.isRequired,
  averagesPerHour: PropTypes.object.isRequired,
  map: PropTypes.object,
  originalUrl: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  showPartnerBanner: PropTypes.bool,
  mapLocations: PropTypes.arrayOf(PropTypes.object),
  initialQuery: PropTypes.string,
  showAdvertisingBanner: PropTypes.bool,
  permissions: PropTypes.shape({
    accessLocationDetails: PropTypes.bool,
    accessLocationWeather: PropTypes.bool,
    accessLocationKpis: PropTypes.bool,
    accessLocationMonthlyReports: PropTypes.bool,
    accessLocationCsvDownloads: PropTypes.bool,
    accessLocationModelledData: PropTypes.bool,
    accessLocationVehicleData: PropTypes.bool,
    accessLocationEvents: PropTypes.bool,
    accessLocationApi: PropTypes.bool,
    accessLocationApiHourlyResolution: PropTypes.bool,
    accessLocationApiDailyResolution: PropTypes.bool,
    accessLocationApiWeeklyResolution: PropTypes.bool,
    accessLocationApiMonthlyResolution: PropTypes.bool,
    accessLocationApiGeojson: PropTypes.bool,
    allowedSecondsBeforeNowInHourlyResolution: PropTypes.number,
    allowedSecondsBeforeNowInDailyResolution: PropTypes.number,
    allowedSecondsBeforeNowInWeeklyResolution: PropTypes.number,
    allowedSecondsBeforeNowInMonthlyResolution: PropTypes.number,
    earliestAccessibleDate: PropTypes.string
  })
}

const BreadcrumbWrapper = styled.div`
  margin-bottom: 1.5rem;

  ${p => p.theme.breakpoints.tablet} {
    margin-left: 1rem;
    margin-bottom: 0.75rem;
  }
`

const LocationWrapper = styled(Section.Wrapper)`
  align-items: stretch;
  padding-top: 2rem;
  padding-bottom: 6.75rem;
  position: relative;
  min-height: 100%;

  ${p => p.theme.breakpoints.tablet} {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;

    /* TODO: Though it works with 100vh on mobile browsers, it's not 100% what
     * we want since it doesn't respect the url bar. It works, but
     * could be better. */
    min-height: calc(100vh - 4.4375rem);
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  max-height: 75rem;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  height: 2.5rem;
`
const CustomButton = styled(Button.Link)`
  ${p => p.theme.breakpoints.tablet} {
    width: 100%;
  }
`
const Heading = styled.h2`
  ${p => p.theme.breakpoints.tablet} {
    font-size: unset;
  }
`

export default LocationShow
