import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Tooltip({ hoverText, children }) {
  return (
    <TooltipWrapper>
      {children}
      <TooltipContent dangerouslySetInnerHTML={{ __html: hoverText }} />
    </TooltipWrapper>
  )
}

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover span {
    visibility: visible;
  }
`

const TooltipContent = styled.span`
  visibility: hidden;
  width: 250px;
  background-color: ${props => props.theme.chroma.base.css()};
  color: ${props => props.theme.chroma.nearlyWhite.css()};
  font-size: .8rem;
  line-height: 1.2rem;

  border-radius: 4px;
  padding: .5rem .75rem;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -125px;

  hyphens: auto;
  word-wrap: break-word;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${props => props.theme.chroma.base.css()} transparent;
  }
`

Tooltip.propTypes = {
  hoverText: PropTypes.string,
  children: PropTypes.node,
}

export default Tooltip
