import React, { createContext, useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import toPairs from 'lodash/toPairs'

const SearchContext = createContext({})
export function useSearch() {
  return useContext(SearchContext)
}

const SearchContextProvider = ({
  locations,
  favLocationIds = [],
  useUrlParams = false,
  initialQuery = '',
  ...rest
}) => {
  const searchHandler = input => {
    getAndSetFilteredLocations(input)
  }

  const urlParams = useRef(
    global.location && new URLSearchParams(global.location.search)
  )

  const [query, setQuery] = useState(initialQuery)
  const [currentFavs, setCurrentFavs] = useState(favLocationIds)
  const [filteredFavs, setFilteredFavs] = useState(() => {
    const [, result] = getFilteredLocations(locations, currentFavs, query)
    return result
  })
  const [filteredLocations, setFilteredLocations] = useState(() => {
    const [result] = getFilteredLocations(locations, [], query)
    return result
  })

  function getAndSetFilteredLocations({
    newCurrentFavs = currentFavs,
    newQuery = query
  }) {
    const normalizedQuery = normalizeQuery(newQuery)
    const [newFilteredLocations, newFilteredFavs] = getFilteredLocations(
      locations,
      newCurrentFavs,
      normalizedQuery
    )
    setQuery(newQuery)
    setCurrentFavs(newCurrentFavs)
    setFilteredLocations(newFilteredLocations)
    setFilteredFavs(newFilteredFavs)

    // in some use cases we don't want to set/use query params, also
    // history.replaceState breaks storybook reloading
    if (useUrlParams) {
      if (normalizedQuery) {
        urlParams.current.set('q', normalizedQuery)
        history.replaceState('', '', '?' + urlParams.current.toString())
      } else {
        urlParams.current.delete('q')
        history.replaceState('', '', global.location.pathname)
      }
    }
  }

  return (
    <SearchContext.Provider
      value={{
        query: query,
        searchHandler: searchHandler,
        currentFavs: currentFavs,
        filteredFavs: filteredFavs,
        filteredLocations: filteredLocations
      }}
      {...rest}
    ></SearchContext.Provider>
  )
}

SearchContextProvider.propTypes = {
  locations: PropTypes.object.isRequired,
  favable: PropTypes.bool,
  isInModal: PropTypes.bool,
  onSelect: PropTypes.func,
  useUrlParams: PropTypes.bool,
  initialQuery: PropTypes.string,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabledLocationIds: PropTypes.arrayOf(PropTypes.number)
}

export function normalizeQuery(searchString) {
  return searchString?.trim()?.toLowerCase()
}

// Takes location object in form { [id]: location, [id]: location }.
// Returns (filtered) array of cities with locations:
//  [
//    [
//      "de",
//      [
//        ["Köln", [location, location]],
//        ["Berlin", [location]]
//      ]
//    ],
//    [
//      // sorted fav ids
//    ]
//  ]
const COUNTRY_ORDER = ['de', 'at', 'ch', 'dk', 'nl', 'lu']
export function getFilteredLocations(locations, favLocationIds, searchString) {
  const filterLocationFn = location =>
    normalizeQuery(location.name)?.includes(normalizeQuery(searchString)) ||
    normalizeQuery(location.city)?.includes(normalizeQuery(searchString))

  const filteredLocations = Object.values(locations).filter(filterLocationFn)
  const sortedLocations = sortBy(filteredLocations, ['city', 'name'])
  const locationsByCountry = groupBy(sortedLocations, 'country')

  const sortedGroupedLocations = COUNTRY_ORDER.filter(
    country => locationsByCountry[country]?.length
  ).map(country => {
    const countryLocations = locationsByCountry[country]

    return [country, toPairs(groupBy(countryLocations, 'city'))]
  })

  const expandedFavs = favLocationIds.map(id => locations[id])
  const filteredFavs = expandedFavs.filter(filterLocationFn)
  const sortedFavs = sortBy(filteredFavs, ['city', 'name'])
  const sortedFavIds = sortedFavs.map(fav => fav.id)

  return [sortedGroupedLocations, sortedFavIds]
}

export default SearchContextProvider
