import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n from '../i18n'
import Headeline from '../components/Headline'
import Button from '../components/Button'
import Section from '../components/Section'

function LegalPageContent({ headline, children, printLink }) {
  return (
    <Section>
      <CustomWrapper>
        <Headeline css={{ marginBottom: '1rem' }} breakout>
          {headline}
        </Headeline>
        <TextWrapper>{children}</TextWrapper>
        {printLink && (
          <div>
            <Button.Link href={printLink}>
              {I18n.t('print_button_text')}
            </Button.Link>
          </div>
        )}
      </CustomWrapper>
    </Section>
  )
}

const TextWrapper = styled.div`
  p {
    margin-bottom: 1rem;
  }
`

const CustomWrapper = styled(Section.Wrapper)`
  padding: 4rem 2rem;
  max-width: 44rem;
  line-height: 1.4;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

LegalPageContent.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  printLink: PropTypes.string
}

export default LegalPageContent
