import React from 'react'
import PropTypes from 'prop-types'

import useFilter from '../../lib/use-filter'
import useModalState from '../../lib/use-modal-state'
import TimeRangeModal from './TimeRangeModal'
import CompareTimerangeModal from './CompareTimerangeModal'
import ComparisonModal from './ComparisonModal'
import MapModal from './MapModal'
import ReportModal from './ReportModal'
import MultiLocationCsvModal from './MultiLocationCsvModal'

function Modals({
  favLocationIds,
  mapLocations,
  map,
  userFullName,
  isDirectionActive
}) {
  const [_filter, dispatchFilter] = useFilter()
  const [activeModal, { closeModal, modals }] = useModalState()

  function handleTimeRangeModalSubmit({ from, to }) {
    dispatchFilter({ type: 'set/timerange', from, to })
    closeModal()
  }

  function handleCompareTimerangeModalSubmit({ from }) {
    dispatchFilter({ type: 'compare/timerange', from })
    closeModal()
  }

  function handleComparisonModalTimeSubmit({ from }) {
    dispatchFilter({ type: 'compare/timerange', from })
    closeModal()
  }

  function handleComparisonModalAverageSubmit() {
    dispatchFilter({ type: 'compare/average' })
    closeModal()
  }

  React.useEffect(() => {
    if (!map && activeModal === modals.map) {
      console.warn('Trying to open the map modal but the Location has no map.') // eslint-disable-line no-console
    }
  }, [map, activeModal, modals])

  return (
    <React.Fragment>
      {!!map && (
        <MapModal
          isOpen={activeModal === modals.map}
          onRequestClose={closeModal}
          map={map}
          isDirectionActive={isDirectionActive}
        />
      )}
      <TimeRangeModal
        isOpen={activeModal === modals.timerange}
        onRequestClose={closeModal}
        onClick={handleTimeRangeModalSubmit}
      />
      <CompareTimerangeModal
        isOpen={activeModal === modals.timerangeComparison}
        onRequestClose={closeModal}
        onClick={handleCompareTimerangeModalSubmit}
      />
      <ComparisonModal
        isOpen={activeModal?.startsWith(modals.comparison)}
        onRequestClose={closeModal}
        onFinish={closeModal}
        favLocationIds={favLocationIds}
        mapLocations={mapLocations}
        onTimeSubmitClick={handleComparisonModalTimeSubmit}
        onAverageSubmitClick={handleComparisonModalAverageSubmit}
      />
      <ReportModal
        isOpen={activeModal === modals.report}
        onRequestClose={closeModal}
      />
      <MultiLocationCsvModal
        isOpen={activeModal === modals.multiLocationCsv}
        onRequestClose={closeModal}
        userFullName={userFullName}
        favLocationIds={favLocationIds}
      />
    </React.Fragment>
  )
}

Modals.propTypes = {
  isDirectionActive: PropTypes.bool,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  mapLocations: PropTypes.arrayOf(PropTypes.object),
  map: PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    rotation: PropTypes.number,
    geojson: PropTypes.object
  }),
  userFullName: PropTypes.string.isRequired,
}

export default Modals
