import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../i18n'
import theme from '../../theme'

function CountFigure({
  icon,
  value,
  color,
  themeColor = 'base',
  small,
  textRight = true,
  hint,
  dense
}) {
  return (
    <CountWrap
      css={{ color: color || get(theme.chroma, themeColor).css() }}
      small={small}
      dense={dense}
      grow={-1}
    >
      {icon}
      <CountValue textRight={textRight} hint={hint} dense={dense}>
        {value != null ? I18n.toNumber(value, { precision: 0 }) : '-'}
      </CountValue>
      {hint && <CountHint dense={dense}>{hint}</CountHint>}
    </CountWrap>
  )
}

CountFigure.propTypes = {
  icon: PropTypes.element.isRequired,
  value: PropTypes.number,
  color: PropTypes.string,
  themeColor: PropTypes.string,
  small: PropTypes.bool,
  textRight: PropTypes.bool,
  hint: PropTypes.string,
  dense: PropTypes.bool
}

const CountWrap = styled.div(props => ({
  display: 'flex',
  alignItems: 'center',
  fontVariantNumeric: 'tabular-nums',
  paddingBottom: '0.25rem',
  flex: '1 1 0%',
  height: props.small ? '1.8rem' : 'auto',
  flexWrap: props.dense ? 'wrap' : undefined,
  flexGrow: props.dense ? 0 : undefined,
  marginLeft: props.dense ? '0.25rem' : undefined,
  svg: {
    flexShrink: 0,
    marginRight: '0.5rem'
  },

  [props.theme.breakpoints.tablet]: {
    height: props.small ? '1.5rem' : 'auto',
    paddingBottom: '0.75rem'
  }
}))

const CountValue = styled.div`
  text-align: ${props => props.textRight && 'right'};
  flex-basis: ${props => (props.dense ? '80%' : undefined)};
`

const CountHint = styled.div(props => ({
  fontSize: props.dense ? '0.75rem ' : '0.86rem',
  color: props.theme.chroma.pencil.css(),
  marginLeft: props.dense ? 0 : '1rem',
  marginRight: '1rem',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  minWidth: props.dense ? '1rem' : '10rem',
  flexBasis: '10rem',

  [props.theme.breakpoints.tablet]: {
    display: props.dense ? 'none' : undefined
  }
}))

export default CountFigure
