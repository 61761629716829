import React from 'react'
import styled from '@emotion/styled'

import { Trans } from '../i18n'
import Headline from '../components/Headline'
import Section from '../components/Section'
import laseLogo from './assets/lase-logo.png'
import laser from './assets/laser.jpg'

function PecoDataSheet() {
  return (
    <Section mid row rowPhoneReverse css={{ maxWidth: '100%' }}>
      <Section.Half basis={56} wrapperWidth="87.5rem">
        <Section.Wrapper css={{ position: 'relative' }}>
          <SectionDecoration>Peco LC</SectionDecoration>
          <TextContainer>
            <Headline>
              <Trans id="pages.methodology.peco_specs.headline" />
            </Headline>
            <Trans id="pages.methodology.peco_specs.content" copy />
          </TextContainer>
        </Section.Wrapper>
      </Section.Half>
      <Section.Half basis={44} image={laser} css={{ minHeight: '21rem' }}>
        <LaseLogoOverlay src={laseLogo} />
      </Section.Half>
    </Section>
  )
}

const SectionDecoration = styled.span`
  position: absolute;
  z-index: -1;
  bottom: 100%;
  left: -3rem;

  color: ${p => p.theme.chroma.nearlyWhite.css()};
  font-size: 16rem;
  font-weight: 900;
  line-height: 12rem;
  white-space: nowrap;

  ${p => p.theme.breakpoints.desktop} {
    font-size: 11rem;
    line-height: 8rem;
    left: -0.5rem;
  }

  ${p => p.theme.breakpoints.tablet} {
    display: none;
  }
`

const TextContainer = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 5rem 3rem 10rem;
  }

  li {
    font-size: 1rem;
    margin: 2em 0;

    ${p => p.theme.breakpoints.tabletUp} {
      font-size: 1.125rem;
    }
  }
`

const LaseLogoOverlay = styled.img`
  width: 5rem;
  height: auto;
  position: absolute;
  top: 0.6rem;
  right: 1rem;

  ${p => p.theme.breakpoints.tabletUp} {
    width: 6.5rem;
  }
`

export default PecoDataSheet
