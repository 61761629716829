import { useQuery } from 'react-query'
import { locationAPI } from './location-api'

export const useQueryLocation = (queryKeys, queryVariables, enabled = true) => {
  return useQuery(queryKeys, queryVariables, locationAPI.getLocation, {
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}

export const useQueryLocations = (
  queryKeys,
  queryVariables,
  enabled = true
) => {
  return useQuery(queryKeys, queryVariables, locationAPI.getLocations, {
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}

export const useQueryLocationStatistics = (queryKeys, queryVariables) => {
  return useQuery(queryKeys, queryVariables, locationAPI.getStatistics, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  })
}

export const useReport = (queryKeys, queryVariables, enabled = true) => {
  return useQuery(queryKeys, queryVariables, locationAPI.getReport, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled: enabled
  })
}
