import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styled from '@emotion/styled'

function MenuButton(props) {
  return (
    <WrapperButton {...props}>
      <svg width="23" height="23" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: 'M 2 2.5 L 20 2.5' },
            open: { d: 'M 3 16.5 L 17 2.5' }
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 }
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 16.346 L 20 16.346' },
            open: { d: 'M 3 2.5 L 17 16.346' }
          }}
        />
      </svg>
    </WrapperButton>
  )
}

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const Path = styled(motion.path)`
  fill: transparent;
  stroke-width: 0.125rem;
  stroke: currentColor;
  stroke-linecap: square;
`

const WrapperButton = styled.button`
  width: 1.75rem;
  height: 1.75rem;
  color: ${p => p.theme.chroma.base.css()};

  > svg {
    width: 100%;
    height: 100%;
  }

  &:focus {
    /* Since this is only visible mobile, we can ignore focus-rings */
    outline: none;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    display: none;
  }
`

export default MenuButton
