import React from 'react'
import PropTypes from 'prop-types'
import I18nJS from 'i18n-js'
import dayjs from 'dayjs'
import styled from '@emotion/styled'

import translations from './translations.js.erb'
import Markdown from './components/Markdown'

I18nJS.defaultLocale = global.I18nConfig
  ? global.I18nConfig.defaultLocale
  : 'de'
I18nJS.locale = global.I18nConfig ? global.I18nConfig.locale : 'de'
I18nJS.fallbacks = true
I18nJS.translations = translations

// The vanilla I18nJS object has some weird behavior when server-side rendering,
// where the current locale gets lost. Overriding those methods fixes this bug.
const I18n = {
  ...I18nJS,
  l: function(...args) {
    I18nJS.locale = global.I18nConfig ? global.I18nConfig.locale : 'de'
    return I18nJS.l.apply(this, args)
  },
  t: function(...args) {
    I18nJS.locale = global.I18nConfig ? global.I18nConfig.locale : 'de'
    return I18nJS.t.apply(this, args)
  },
  currentLocale: function() {
    return global.I18nConfig ? global.I18nConfig.locale : 'de'
  }
}

export const Trans = ({ id, escape = false, copy, children, values = {} }) => {
  const text = I18n.t(id, { defaultValue: children, ...values })
  return (
    <MarkdownWrapper>
      <Markdown content={text} copy={copy} escape={escape} />
    </MarkdownWrapper>
  )
}

const MarkdownWrapper = styled.span`
  a {
    text-decoration: underline;
  }
`

Trans.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  copy: PropTypes.bool,
  escape: PropTypes.bool,
  children: PropTypes.string, // the default message
  values: PropTypes.object
}

const helpers = {
  dateRange(from, to, { format = 'date.formats.long' } = {}) {
    if (dayjs(from).isSame(dayjs(to), 'day')) {
      return I18n.l(format, from)
    }

    return I18n.l(format, from) + ' – ' + I18n.l(format, to)
  },
  hourRange(from, to) {
    return (
      I18n.l('time.formats.tiny', from) +
      ' – ' +
      I18n.l('time.formats.tiny', to)
    )
  },
  abbreviateNumber(value) {
    let newValue = value

    let suffixes = []
    if (I18n.currentLocale() === 'en') {
      suffixes = ['', 'K', 'M.', 'B', 'T']
    } else {
      suffixes = ['', ' Tsd.', ' Mio.', ' Bio.', ' Trill.']
    }
    let suffixNum = 0
    while (newValue >= 1000 || newValue <= -1000) {
      newValue /= 1000
      suffixNum++
    }

    newValue =
      newValue.toString().length > 2
        ? newValue.toPrecision(3)
        : newValue.toPrecision()

    newValue += `${suffixes[suffixNum]}`
    return newValue
  }
}

export default { ...I18n, helpers }
