import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import I18n from '../../i18n'
import useAuthorization from '../../lib/use-authorization'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import useMediaQuery from '../../lib/use-media-query'
import useModalState from '../../lib/use-modal-state'
import useZoneDetails from '../../lib/use-zone-details'
import theme from '../../theme'
import * as Icon from '../Icon'
import { HorizontalSpacer } from '../Spacer'
import {
  Average as AverageIcon,
  Walking as WalkingIcon,
  Adult as AdultIcon,
  Child as ChildIcon
} from '../Icon'
import SingleSelect from '../forms/SingleSelect'
import CompareButton from './CompareButton'
import ActionButton from './ActionButton'
import ActionSelect from './ActionSelect'
import ZoneDetailsDirectionButton from './ZoneDetailsDirectionButton'

const isSSR = typeof window === 'undefined'

const SUPPORTED_OBJECT_TYPES = {
  PERSON: 'PERSON',
  VEHICLE: 'VEHICLE'
}

const SUPPORTED_PERSON_SUBTYPES = {
  ADULT: 'ADULT',
  CHILD: 'CHILD'
}

const SUPPORTED_VEHICLE_SUBTYPES = {
  CAR: 'CAR',
  BICYCLE: 'BICYCLE',
  BUS: 'BUS',
  TRAM: 'TRAM'
}

function ActionBar() {
  const [_activeModal, { openModal, modals }] = useModalState()
  const [filter, dispatchFilter] = useFilter()
  const { location } = useLocation()
  const { showZoneDetails, setShowZoneDetails } = useZoneDetails()
  const toggleZoneDetailsDirection = useCallback(() => {
    setShowZoneDetails(currentValue => !currentValue)
  }, [setShowZoneDetails])
  const [zoneOptions, setZoneOptions] = useState([])
  const [activeObjectType, setActiveObjectType] = useState(filter.objectType)
  const { permissions } = useAuthorization()
  const shouldDisplayActionButtonTitles = useMediaQuery(
    '@media (max-width: 1044px)'
  )

  const UIState = {
    isMultiLocationComparisonModeActive:
      filter.viewType === 'compare' && filter.compareType === 'multiLocation',
    showCompareButtons:
      filter.viewType !== 'zone' &&
      filter.objectType === 'PERSON' &&
      filter.objectSubtype === 'ALL' &&
      !(
        filter.viewType === 'compare' && filter.compareType === 'multiLocation'
      ),
    showLocationCompareButton: permissions.locations.accessibleForComparison.includes(
      location.id
    ),
    showResetButton:
      filter.viewType === 'zone' ||
      (filter.viewType === 'compare' && filter.compareType === 'multiLocation'),
    showDetailsSelect: filter.viewType !== 'zone',
    showZoneSelect: permissions.accessLocationDetails,
    showZoneDetailsSelect: filter.viewType === 'zone' && filter.zone !== 'all',
    showDetailButtons:
      permissions.accessLocationDetails &&
      filter.objectType === 'PERSON' &&
      filter.objectSubtype === 'ALL'
  }

  const objectTypeSelectorItems = [
    {
      label: I18n.t('location.object_type.person'),
      icon: <WalkingIcon height={16} width={16} />,
      options: [
        {
          label: I18n.t('location.object_type.all_persons'),
          value: SUPPORTED_OBJECT_TYPES.PERSON,
          icon: (
            <WalkingIcon
              height={24}
              width={24}
              color={theme.chroma.base.hex()}
            />
          )
        },
        {
          label: I18n.t('location.object_subtype.adult'),
          value: SUPPORTED_PERSON_SUBTYPES.ADULT,
          icon: (
            <AdultIcon height={24} width={24} color={theme.chroma.base.hex()} />
          ),
          isDisabled: !permissions.accessLocationVehicleData
        },
        {
          label: I18n.t('location.object_subtype.child'),
          value: SUPPORTED_PERSON_SUBTYPES.CHILD,
          icon: (
            <ChildIcon height={24} width={24} color={theme.chroma.base.hex()} />
          ),
          isDisabled: !permissions.accessLocationVehicleData
        }
      ]
    },
    {
      label: I18n.t('location.object_type.vehicle'),
      icon: <Icon.FA icon={Icon.FaCarBus} style={{ fontSize: '16px' }} />,
      options: [
        {
          label: I18n.t('location.object_type.all_vehicles'),
          value: SUPPORTED_OBJECT_TYPES.VEHICLE,
          icon: (
            <Icon.FA
              icon={Icon.FaCarBus}
              style={{ fontSize: '24px' }}
              color={theme.chroma.base.hex()}
            />
          ),
          isDisabled: !permissions.accessLocationVehicleData
        },
        {
          label: I18n.t('location.object_subtype.car'),
          value: SUPPORTED_VEHICLE_SUBTYPES.CAR,
          icon: (
            <Icon.FA
              icon={Icon.FaCar}
              style={{ fontSize: '24px' }}
              color={theme.chroma.base.hex()}
            />
          ),
          isDisabled: !permissions.accessLocationVehicleData
        },
        {
          label: I18n.t('location.object_subtype.bicycle'),
          value: SUPPORTED_VEHICLE_SUBTYPES.BICYCLE,
          icon: (
            <Icon.FA
              icon={Icon.FaBicycle}
              style={{ fontSize: '24px' }}
              color={theme.chroma.base.hex()}
            />
          ),
          isDisabled: !permissions.accessLocationVehicleData
        },
        {
          label: I18n.t('location.object_subtype.bus'),
          value: SUPPORTED_VEHICLE_SUBTYPES.BUS,
          icon: (
            <Icon.FA
              icon={Icon.FaBusSimple}
              style={{ fontSize: '24px' }}
              color={theme.chroma.base.hex()}
            />
          ),
          isDisabled: true // not tested yet
        },
        {
          label: I18n.t('location.object_subtype.tram'),
          value: SUPPORTED_VEHICLE_SUBTYPES.TRAM,
          icon: (
            <Icon.FA
              icon={Icon.FaTrainSubway}
              style={{ fontSize: '24px' }}
              color={theme.chroma.base.hex()}
            />
          ),
          isDisabled: true // not tested yet
        }
      ]
    }
  ]

  const ActionBarHandlers = {
    objectTypeValueToSelectorItem: (filter, options) => {
      let currentlyActiveObjectType = null

      if (filter.objectSubtype === 'ALL') {
        currentlyActiveObjectType = filter.objectType
      }

      if (filter.objectSubtype !== 'ALL') {
        currentlyActiveObjectType = filter.objectSubtype
      }

      // Flatten options array with the subItems in each option
      const flatOptions = options
        .map(option => {
          if (option.options) {
            return [option, ...option.options]
          }
          return option
        })
        .flat()

      const activeOption = flatOptions.find(
        option => option.value === currentlyActiveObjectType
      )

      return activeOption
    },

    zoneSelectorItems: zones => {
      if (zones.length > 0) {
        const options = zones
          .filter(zone => zone !== 99)
          .map(zone => ({
            title: `Zone ${zone}`,
            value: String(zone)
          }))
        return options
      }
      return []
    },

    handleObjectTypeSelect: selectedObjectType => {
      selectedObjectType = selectedObjectType.value

      // Reset the filter state
      dispatchFilter({
        type: 'none'
      })

      if (SUPPORTED_OBJECT_TYPES[selectedObjectType]) {
        dispatchFilter({
          type: `set/objectTypes`,
          objectType: selectedObjectType,
          objectSubtype: 'ALL'
        })
      }

      if (SUPPORTED_VEHICLE_SUBTYPES[selectedObjectType]) {
        dispatchFilter({
          type: `set/objectTypes`,
          objectType: 'VEHICLE',
          objectSubtype: selectedObjectType
        })
      }

      if (SUPPORTED_PERSON_SUBTYPES[selectedObjectType]) {
        dispatchFilter({
          type: `set/objectTypes`,
          objectType: 'PERSON',
          objectSubtype: selectedObjectType
        })
      }
    },

    handleDetailsSelect: detailsType => {
      dispatchFilter({ type: `details/${detailsType}` })
    },

    handleDetailsClick: detailsType => {
      if (filter.viewType === 'details') {
        dispatchFilter({ type: 'none' })
      } else {
        dispatchFilter({ type: `details/${detailsType}` })
      }
    },

    handleZonesSelect: zone => {
      dispatchFilter({ type: 'set/zone', zone })
      if (zone === 'all') {
        setShowZoneDetails(false)
      }
    },

    handleZonesClick: zone => {
      if (filter.viewType === 'zone') {
        dispatchFilter({ type: 'set/zone', zone: 'all' })
        dispatchFilter({ type: 'none' })
        setShowZoneDetails(false)
      } else {
        dispatchFilter({ type: 'set/zone', zone })
      }
    },

    handleCompareClick: compareType => {
      switch (compareType) {
        case 'multiLocation':
          dispatchFilter({
            type: 'compare/multiLocation',
            locationIds: [location.id]
          })
          break
        case 'timerange':
          if (
            filter.viewType === 'compare' &&
            filter.compareType === 'timerange'
          ) {
            dispatchFilter({ type: 'none' })
          } else {
            openModal(modals[`timerangeComparison`])
          }
          break
        case 'average':
          if (
            filter.viewType === 'compare' &&
            filter.compareType === 'average'
          ) {
            dispatchFilter({ type: 'none' })
          } else {
            dispatchFilter({ type: 'compare/average' })
          }
          break
      }
    },

    handleResetButtonClick: () => {
      dispatchFilter({ type: 'set/zone', zone: 'all' })
      dispatchFilter({ type: 'none' })
    }
  }

  const SubComps = {
    ResetButton: () => {
      return (
        <div
          css={{
            display: 'flex',
            height: '2.85rem',
            margin: '0.625rem 0',
            flexShrink: 0
          }}
        >
          <ActionButton
            bright
            title={
              UIState.isMultiLocationComparisonModeActive
                ? I18n.t(
                    'location.compare_multiple_locations.turn_comparison_mode_off'
                  )
                : I18n.t('location.reset_button')
            }
            icon={<Icon.CavetLeft width={10} height={12} />}
            onClick={ActionBarHandlers.handleResetButtonClick}
            variant={'link'}
          />
        </div>
      )
    },

    TimeRangeCompareButton: () => {
      return (
        <CompareButton
          data-test-id="timerange_compare_button"
          label={
            shouldDisplayActionButtonTitles
              ? I18n.t('location.compare_timerange.label')
              : ''
          }
          compareType="timerange"
          onClick={ActionBarHandlers.handleCompareClick}
          isActive={
            filter.viewType === 'compare' && filter.compareType === 'timerange'
          }
          icon={
            <Icon.Lines
              height={27}
              width={27}
              color={theme.chroma.accent.css()}
            />
          }
        />
      )
    },

    LocationCompareButton: () => {
      return (
        <CompareButton
          label={
            shouldDisplayActionButtonTitles
              ? I18n.t('location.compare_locations.label')
              : ''
          }
          data-test-id="location_compare_button"
          compareType={'multiLocation'}
          onClick={ActionBarHandlers.handleCompareClick}
          isActive={
            filter.viewType === 'compare' &&
            (filter.compareType === 'location' ||
              filter.compareType === 'multiLocation')
          }
          icon={
            <Icon.Locations
              height={24}
              width={34}
              color={theme.chroma.accent.css()}
            />
          }
        />
      )
    },

    AverageCompareButton: () => {
      return (
        <CompareButton
          data-test-id="average_compare_button"
          compareType="average"
          label={
            shouldDisplayActionButtonTitles
              ? I18n.t('location.compare_average.label')
              : ''
          }
          onClick={ActionBarHandlers.handleCompareClick}
          isActive={
            filter.viewType === 'compare' && filter.compareType === 'average'
          }
          icon={
            <AverageIcon
              width={24}
              height={24}
              color={theme.chroma.accent.css()}
            />
          }
        />
      )
    },

    DetailsSelect: () => {
      return (
        <ActionSelect
          data-test-id="details_selector"
          label={I18n.t('location.details.label')}
          value={filter.detailsType}
          onClick={ActionBarHandlers.handleDetailsClick}
          onChangeValue={ActionBarHandlers.handleDetailsSelect}
          isActive={filter.viewType === 'details'}
          options={[
            {
              title: I18n.t('location.details.height'),
              value: 'height'
            },
            {
              title: I18n.t('location.details.direction'),
              value: 'direction'
            }
          ]}
          icon={
            <Icon.DirectionLeftRight
              height={23}
              width={29}
              color={theme.chroma.lightBlue.css()}
            />
          }
        />
      )
    },

    ZoneSelect: () => {
      return (
        <ActionSelect
          data-test-id="zone_selector"
          label={I18n.t('location.zones.label')}
          value={filter.zone}
          onClick={ActionBarHandlers.handleZonesClick}
          onChangeValue={ActionBarHandlers.handleZonesSelect}
          isActive={filter.viewType === 'zone'}
          options={[
            { title: I18n.t('location.zones.zone_all'), value: 'all' }
          ].concat(zoneOptions)}
          icon={
            <Icon.Zone2
              height={29}
              width={24}
              color={theme.chroma.lightBlue.css()}
            />
          }
        />
      )
    },

    ZoneDetailsButton: () => {
      return (
        <ZoneDetailsDirectionButton
          isActive={showZoneDetails}
          onClick={toggleZoneDetailsDirection}
        />
      )
    },

    ActionBarSpacer: () => {
      return <div style={{ width: '0.625rem' }} />
    }
  }

  SubComps.ActionBarSpacer.displayName = 'ActionBarSpacer'
  SubComps.ResetButton.displayName = 'ResetButton'
  SubComps.TimeRangeCompareButton.displayName = 'TimeRangeCompareButton'
  SubComps.LocationCompareButton.displayName = 'LocationCompareButton'
  SubComps.AverageCompareButton.displayName = 'AverageCompareButton'
  SubComps.DetailsSelect.displayName = 'DetailsSelect'
  SubComps.ZoneSelect.displayName = 'ZoneSelect'
  SubComps.ZoneDetailsButton.displayName = 'ZoneDetailsButton'

  useEffect(() => {
    if (objectTypeSelectorItems.length > 0) {
      setActiveObjectType(
        ActionBarHandlers.objectTypeValueToSelectorItem(
          filter,
          objectTypeSelectorItems
        )
      )
    }
  }, [filter.objectType, filter.objectSubtype])

  useEffect(() => {
    setZoneOptions(ActionBarHandlers.zoneSelectorItems(location.metadata.zones))
  }, [location.metadata.zones])

  return (
    <Container alignLeft={UIState.showResetButton}>
      {!UIState.isMultiLocationComparisonModeActive && (
        <SelectorsContainer>
          {
            // TODO: - Find a way to SSR react-select, so it can be properly tested with Capybara.
            // as shown here: https://github.com/JedWatson/react-select/issues/5555
            // React-Select is not SSR friendly.
          }
          <SingleSelectContainer data-test-id="object_type_selector">
            {!isSSR && (
              <SingleSelect
                options={objectTypeSelectorItems}
                onChange={ActionBarHandlers.handleObjectTypeSelect}
                value={activeObjectType}
                instanceId="object_type_selector"
              ></SingleSelect>
            )}
          </SingleSelectContainer>
          <SubComps.ActionBarSpacer />
          {UIState.showDetailButtons && (
            <DetailButtonsContainer>
              {UIState.showZoneSelect && <SubComps.ZoneSelect />}
              <SubComps.ActionBarSpacer />
              {UIState.showZoneDetailsSelect && <SubComps.ZoneDetailsButton />}
              {UIState.showDetailsSelect && <SubComps.DetailsSelect />}
            </DetailButtonsContainer>
          )}
        </SelectorsContainer>
      )}

      {UIState.showResetButton && <SubComps.ResetButton />}

      {UIState.showCompareButtons && (
        <CompareButtonsContainer>
          <SubComps.AverageCompareButton />
          <HorizontalSpacer size="0.625rem" transparent />
          {UIState.showLocationCompareButton && (
            <React.Fragment>
              <SubComps.LocationCompareButton />
              <HorizontalSpacer size="0.625rem" transparent />
            </React.Fragment>
          )}

          <SubComps.TimeRangeCompareButton />
        </CompareButtonsContainer>
      )}
    </Container>
  )
}

ActionBar.propTypes = {
  hasMap: PropTypes.bool
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${p => (p.alignLeft ? 'flex-start' : 'space-between')};
  align-items: flex-start;
  margin-bottom: 0.5rem;
  background-color: ${p => p.theme.chroma.base.css()};
  padding: 0.625rem 0.625rem 0 0.625rem;

  @media (min-width: 767px) and (max-width: 1044px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const SelectorsContainer = styled.div`
  display: flex;
  min-height: 4rem;
`

const CompareButtonsContainer = styled.div`
  display: flex;
  position: relative;
`

const SingleSelectContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const DetailButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export default ActionBar
