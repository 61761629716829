import React from 'react'
import PropTypes from 'prop-types'
import Select from '../Select'

function PredefinedFilter({ values, activeValue, onTimeRangeChange, light }) {
  function handleQuickSwitchClick(_event, { toggleMenu, item }) {
    if (!activeValue) {
      onTimeRangeChange({
        from: item.from,
        to: item.to
      })
      return
    }

    toggleMenu()
  }

  function handleDropdownChange(value) {
    onTimeRangeChange({
      from: value.from,
      to: value.to
    })
  }

  const baseValue = activeValue || values[0]
  const baseTitle = baseValue.displayFormat

  return (
    <Select
      onChange={handleDropdownChange}
      onBaseClick={handleQuickSwitchClick}
      activeItem={activeValue}
      items={values.map(value => ({ ...value, label: value.displayFormat }))}
      baseTitle={baseTitle}
      variant={'secondary'}
      highlighted={!!activeValue}
    />
  )
}

PredefinedFilter.propTypes = {
  activeValue: PropTypes.shape({
    displayFormat: PropTypes.string.isRequired,
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      displayFormat: PropTypes.string.isRequired,
      from: PropTypes.instanceOf(Date).isRequired,
      to: PropTypes.instanceOf(Date).isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  onTimeRangeChange: PropTypes.func,
  light: PropTypes.bool
}

export default PredefinedFilter
