import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

import Countries from '../components/LocationPicker/Countries'
import LocationSearch from '../components/LocationPicker/LocationSearch'
import LocationSelector from '../components/LocationPicker/LocationSelector'
import PartnerNowCta from '../components/PartnerNowCta'
import Section from '../components/Section'
import Button from '../components/Button'
import AdvertisementBanner from '../components/AdvertisementBanner'

import LocationsMap from '../sections/LocationsMap'

import I18n from '../i18n'

import SearchContextProvider from '../lib/use-search'
import { MapZoomProvider } from '../lib/use-map-zoom'

function Locations({
  locations,
  favLocationIds,
  initialQuery,
  showPartnerBanner,
  locationsCount,
  mapLocations,
  showAdvertisingBanner
}) {
  const isGermanLocale = I18n.currentLocale() === 'de'
  return (
    <SearchContextProvider
      locations={locations}
      favLocationIds={favLocationIds}
      initialQuery={initialQuery || ''}
    >
      <MapZoomProvider>
        <Section mid css={{ minHeight: '100%', paddingBottom: '5rem' }}>
          <Helmet>
            <title>{I18n.t('locations.title')}</title>
          </Helmet>
          {showAdvertisingBanner && (
            <AdvertisementBanner cookieName="advertisement_banner_shown" />
          )}
          <TopWrapper>
            <Top>
              <div css={{ width: '100%' }}>
                <LocationSearch />
                <Countries />
              </div>
              {showPartnerBanner && isGermanLocale && (
                <CtaWrapper>
                  <PartnerNowCta />
                </CtaWrapper>
              )}
            </Top>
          </TopWrapper>
          <DataWrapper>
            <ListWrapper>
              <ListTopBar>
                <LocationsCount>
                  {I18n.t('locations.locations', {
                    locations_count: locationsCount
                  })}
                </LocationsCount>
              </ListTopBar>
              <ListView>
                <IndexButtonLink outline href="/hystreet-index">
                  <span>{I18n.t('locations.hystreet_index_cta.headline')}</span>
                  <span css={{ textTransform: 'none', paddingTop: '0.5rem' }}>
                    {I18n.t('locations.hystreet_index_cta.subline')}
                  </span>
                </IndexButtonLink>
                <LocationSelector
                  locations={locations}
                  mapLocations={mapLocations}
                />
              </ListView>
            </ListWrapper>
            <LocationsMap mapLocations={mapLocations} />
          </DataWrapper>
        </Section>
      </MapZoomProvider>
    </SearchContextProvider>
  )
}

Locations.propTypes = {
  initialQuery: PropTypes.string,
  locations: PropTypes.object.isRequired,
  favLocationIds: PropTypes.array,
  showPartnerBanner: PropTypes.bool,
  mapLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationsCount: PropTypes.number.isRequired,
  showAdvertisingBanner: PropTypes.bool
}

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const ListWrapper = styled.div`
  width: 35%;
  height: 730px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  ${p => p.theme.breakpoints.desktop} {
    width: 100%;
  }
`

const TopWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
  position: relative;
  box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.17);

  ${p => p.theme.breakpoints.phone} {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }
`

const Top = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  width: 60%;
  margin: 0 auto;
  position: relative;
  ${p => p.theme.breakpoints.desktop} {
    width: 80%;
    margin: 0;
  }
  ${p => p.theme.breakpoints.tablet} {
    width: 100%;
  }
`

const ListTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.chroma.white.hex()};
  border-bottom: 1px solid ${props => props.theme.chroma.grey.hex()};
  padding: 1rem 1rem 1rem 2.5rem;
`

const LocationsCount = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`

const ListView = styled.div`
  background-color: ${props => props.theme.chroma.white.hex()};
  padding: 1.5rem 2.5rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`

const CtaWrapper = styled.div`
  position: absolute;
  left: 100%;
  top: -0.7rem;
  ${p => p.theme.breakpoints.tablet} {
    display: none;
  }
`

const IndexButtonLink = styled(Button.Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default Locations
