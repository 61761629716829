import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InfiniteCalendar, { EVENT_TYPE } from 'react-infinite-calendar/es'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en'
import I18n from '../../i18n'

// react-infinite-calendar doesn't use short weekday names form date-fns locales
const weeksdays = {
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
}
const dateFnsLocales = {
  de: deLocale,
  en: enLocale
}

function FullHeightCalendar({
  startDate,
  endDate,
  onDateChange = () => {},
  onDateChangeStart = () => {},
  canSafelySetHeight = true,
  ...passThrough
}) {
  const [calendarVisible, setCalendarVisible] = useState(true)
  const [calendarHeight, setCalenderHeight] = useState(200)
  const calendarWrapperRef = useRef(null)

  // For some reason, inifite-calendar keeps adding 49px to the defined height
  const adjustedCalendarHeight = calendarHeight - 49

  useEffect(() => {
    function checkCalendarHeight() {
      if (!canSafelySetHeight || !calendarWrapperRef.current) return

      setCalendarVisible(false)
      const dimensions = calendarWrapperRef.current.getBoundingClientRect()
      setCalendarVisible(true)
      setCalenderHeight(dimensions.height)
    }
    checkCalendarHeight()

    window.addEventListener('resize', checkCalendarHeight)
    return () => window.removeEventListener('resize', checkCalendarHeight)
  }, [canSafelySetHeight])

  function handleDatePickerDateChange({ start, end, eventType }) {
    if (eventType === EVENT_TYPE.END) {
      onDateChange({ start, end })
    } else if (eventType === EVENT_TYPE.START) {
      onDateChangeStart({ start })
    } else {
      return
    }
  }

  return (
    <div ref={calendarWrapperRef} css={{ flex: '1 auto' }}>
      <div style={{ display: calendarVisible ? 'block' : 'none' }}>
        <InfiniteCalendar
          selected={{ start: startDate, end: endDate }}
          width="100%"
          height={adjustedCalendarHeight}
          onSelect={handleDatePickerDateChange}
          locale={{
            weekStartsOn: 1,
            weekdays: weeksdays[I18n.currentLocale()],
            locale: dateFnsLocales[I18n.currentLocale()]
          }}
          {...passThrough}
        />
      </div>
    </div>
  )
}

FullHeightCalendar.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  onDateChangeStart: PropTypes.func,
  canSafelySetHeight: PropTypes.bool
}

export default FullHeightCalendar
