import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

import I18n from '../../i18n'
import useAuthentication from '../../lib/use-authentication'

import useHystreetIndex from '../../lib/use-hystreet-index'

import Logo from '../assets/logo.svg'

import LineGraph from '../Graph/LineGraph'
import LoadingIndicator from '../Graph/LoadingIndicator'
import Typography from '../Typography'

import { useQueryNationalIndex } from '../../lib/api/index/use-index-api'
import IndexChangeReading from './IndexChangeReading'

const CustomTooltip = ({ active, payload: tooltipPayload }) => {
  if (active && tooltipPayload && tooltipPayload.length) {
    const payload = tooltipPayload[0]
    return (
      <TooltipContainer className="custom-tooltip">
        <Typography tag="p">{`${payload.payload.tooltipTimestamp}`}</Typography>
        <Typography tag="p">{`${payload.name}: ${I18n.toNumber(
          payload.value
        )}`}</Typography>
      </TooltipContainer>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object)
}

function IndexGraph({ preLoadedPlotData = null }, hideChangeReading = false) {
  const { preset } = useHystreetIndex()
  const { loginState, _dispatchLoginStateChange } = useAuthentication()
  const { loggedIn } = loginState
  const { data, status } = useQueryNationalIndex(preset, loggedIn)
  const isLoading = preLoadedPlotData ? false : status !== 'success' || !data
  const plotDataToDisplay = loggedIn ? data : preLoadedPlotData
  return (
    <Container>
      <Readings isLoading={isLoading}>
        {!hideChangeReading && <IndexChangeReading />}
        <LogoWrapper>
          <Logo height={42} />
        </LogoWrapper>
      </Readings>
      <>
        {isLoading ? (
          <LoadingWrapper>
            <LoadingIndicator active text="loading_data" />
          </LoadingWrapper>
        ) : (
          <LineGraph
            aspectRatio={null}
            chartType="line"
            plot={plotDataToDisplay}
            stackedChartKeys={['indexValue']}
            displayLegend={true}
            name={I18n.t('pages.hystreet_index.legend_name')}
            displayTooltip={false}
            tooltipContent={<CustomTooltip />}
            displayLineEndingLabel={false}
            tickInterval="preserveStartEnd"
          />
        )}
      </>
    </Container>
  )
}

IndexGraph.propTypes = {
  preLoadedPlotData: PropTypes.arrayOf(PropTypes.object)
}

const Container = styled.div`
  position: relative;
  padding-top: 1.125rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 35rem;

  ${props => props.theme.breakpoints.tablet} {
    min-height: 20rem;
  }
`

const Readings = styled.div`
  display: flex;
  justify-content: ${props => (props.isLoading ? 'center' : 'space-between')};
  margin-left: ${props => (props.isLoading ? 0 : '3.05rem')};
  align-items: flex-start;
  transition: opacity 400ms;
  opacity: ${p => (p.isLoading ? 0.3 : 1)};
  filter: ${p => (p.isLoading ? 'grayscale(50%)' : undefined)};
  height: 4.75rem; /* height must be set, so the graph can calculate its height */

  ${props => props.theme.breakpoints.tablet} {
    justify-content: unset;
    margin-left: 0.2rem;
  }
`

const LogoWrapper = styled.div`
  display: none;

  ${props => props.theme.breakpoints.desktopUp} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: 1rem;
  }
`
const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  pointer-events: none;
`

const TooltipContainer = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.chroma.white.css()};
  border: 1px solid #e6e6e6;
`

export default IndexGraph
