import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import OutsideClickHandler from 'react-outside-click-handler'
import AnchoredPopover from '../AnchoredPopover'
import CloseButton from '../CloseButton'

function IncidentDetail(props) {
  const IncidentIcon = props.icon

  return (
    <OutsideClickHandler onOutsideClick={props.onClose}>
      <AnchoredPopover anchorTo={props.anchorTo}>
        <Container>
          <div>
            <Name>
              <IncidentIcon
                width="16"
                height="16"
                fill="currentColor"
                css={{ position: 'relative', top: '2px', marginRight: '5px' }}
              />

              {props.incident.name}
            </Name>

            <Description dangerouslySetInnerHTML={{ __html: props.incident.description }}/>
          </div>

          <CloseButton onClick={props.onClose} />
        </Container>
      </AnchoredPopover>
    </OutsideClickHandler>
  )
}

IncidentDetail.propTypes = {
  anchorTo: PropTypes.object,
  onClose: PropTypes.func,
  icon: PropTypes.any,
  incident: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  })
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Name = styled.h4`
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  line-height: 1.4rem;
`

const Description = styled.span`
  line-height: 1.2;

  & a {
    color: inherit;
    text-decoration: underline;
  }
`

export default IncidentDetail
