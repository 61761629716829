import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import I18n from '../i18n'

import Button from '../components/Button'
import Card from '../components/Card'
import Form from '../components/forms/Form'
import Headline from '../components/Headline'
import Input from '../components/forms/Input'
import Section from '../components/Section'
import TextArea from '../components/forms/TextArea'
import Typography from '../components/Typography'

function Contact({ contactMessage }) {
  const { values, errors } = contactMessage

  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section mid row>
        <Section.Wrapper css={{ padding: '3rem 0' }}>
          <Card css={{ maxWidth: '46rem' }}>
            <Headline>
              {sT('headline')}
              <Typography tag="p" smaller>
                {sT('subline')}
              </Typography>
            </Headline>
            <Form as="contact" action="/contact" method="post">
              <Form.Field>
                <Input
                  label={sT('email')}
                  name="email"
                  required
                  defaultValue={values?.email || ''}
                  errors={errors?.email}
                />
              </Form.Field>
              <Form.Field half>
                <Input
                  label={sT('first_name')}
                  name="first_name"
                  required
                  defaultValue={values?.firstName || ''}
                  errors={errors?.first_name}
                />
              </Form.Field>
              <Form.Field half>
                <Input
                  label={sT('last_name')}
                  name="last_name"
                  required
                  defaultValue={values?.lastName || ''}
                  errors={errors?.last_name}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  label={sT('phone_number')}
                  name="phone_number"
                  defaultValue={values?.phoneNumber || ''}
                  errors={errors?.phone_number}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  label={sT('company')}
                  name="company"
                  defaultValue={values?.company || ''}
                  errors={errors?.company}
                />
              </Form.Field>
              <Form.Field>
                <TextArea
                  label={sT('message')}
                  name="message"
                  defaultValue={values?.message || ''}
                  placeholder={sT('message_placeholder')}
                  rows={5}
                  required
                  errors={errors?.message}
                />
              </Form.Field>
              <Form.Actions>
                <Button css={{ alginSelf: 'flex-start' }} type="submit">
                  {sT('submit')}
                </Button>
              </Form.Actions>
            </Form>
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.contact.${key}`)

Contact.propTypes = {
  contactMessage: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default Contact
