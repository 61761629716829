import React from 'react'

const context = React.createContext()

const modals = {
  timerange: 'timerange',
  timerangeComparison: 'timerangeComparison',
  comparison: 'comparison',
  map: 'map',
  multiLocationCsv: 'multiLocationCsv',
  report: 'report'
}

export default function useModalState() {
  return React.useContext(context)
}

export function ModalStateProvider(props) {
  const [activeModal, setActiveModal] = React.useState()

  const openModal = React.useCallback(modal => setActiveModal(modal), [])
  const closeModal = React.useCallback(() => setActiveModal(undefined), [])

  // open modal when url hash changes
  React.useEffect(() => {
    function handleHashChange() {
      if (window.location.hash.startsWith('#modal')) {
        const modalFromHash = window.location.hash.replace('#modal-', '')
        setActiveModal(modalFromHash)
      }
    }

    window.addEventListener('hashchange', handleHashChange, false)

    return () => {
      window.removeEventListener('hashchange', handleHashChange, false)
    }
  }, [])

  // update hash in url when modal opens/closes
  React.useEffect(() => {
    if (!activeModal && window.location.hash.startsWith('#modal')) {
      history.replaceState(null, null, ' ')
    }

    if (activeModal && !window.location.hash.startsWith('#modal')) {
      history.replaceState(null, null, '#modal-' + activeModal)
    }
  }, [activeModal])

  return (
    <context.Provider
      value={[activeModal, { openModal, closeModal, modals }]}
      {...props}
    />
  )
}
