import '../setupEnv'
import React from 'react'
import { hydrateRoot, createRoot } from 'react-dom/client'
import Rails from '@rails/ujs'

// Those can only be imported on client-side and will crash server-side
import OLMap from 'ol/Map'
import { defaults as OLdefaultControls } from 'ol/control'

// Needs to be imported here, otherwise things go horribly, horribly wrong, for reasons 🤷‍♂️
import 'react-infinite-calendar/styles.css'
import 'typeface-lato'
import 'ol/ol.css'
import '../globalStyles'

import App from '../App'

Rails.start()
global.Rails = Rails

var componentRequireContext = require.context('pages', true)
var ReactRailsUJS = require('react_ujs')

global.OpenLayersClient = {
  Map: OLMap,
  defaultControls: OLdefaultControls
}

ReactRailsUJS.useContext(componentRequireContext)

ReactRailsUJS.mountComponents = function(searchSelector) {
  var ujs = ReactRailsUJS
  var nodes = ujs.findDOMNodes(searchSelector)

  for (var i = 0; i < nodes.length; ++i) {
    var node = nodes[i]
    var className = node.getAttribute(ujs.CLASS_NAME_ATTR)
    var PageComponent = ujs.getConstructor(className)
    var propsJson = node.getAttribute(ujs.PROPS_ATTR)
    var props = propsJson && JSON.parse(propsJson)
    var shouldHydrate = node.getAttribute(ujs.RENDER_ATTR)

    if (!PageComponent) {
      var message = "Cannot find component: '" + className + "'"
      throw new Error(
        message + '. Make sure your component is available to render.'
      )
    } else {
      const app = <App PageComponent={PageComponent} pageProps={props} />

      if (shouldHydrate) {
        hydrateRoot(node, app)
      } else {
        const root = createRoot(node)
        root.render(app)
      }
    }
  }
}
