import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { useSearch } from '../../lib/use-search'
import useScrollDirection from '../../lib/use-sticky'

import I18n from '../../i18n'
import Input from '../forms/Input'
import { MagnifyingGlass } from '../Icon'

function LocationSearch({ isInModal }) {
  const { query, searchHandler } = useSearch()
  const [isSticky, scrollDirection, stickyRef] = useScrollDirection()
  const handleSearchChange = event => {
    searchHandler({ newQuery: event.target.value })
  }
  return (
    <StickyContainer
      innerRef={stickyRef}
      isInModal={isInModal}
      isSticky={isSticky}
      scrollDirection={scrollDirection}
    >
      <Input
        name="query"
        onChange={handleSearchChange}
        value={query}
        light
        big
        icon={MagnifyingGlass}
        placeholder={I18n.t('locations.search')}
        autoComplete="off"
      />
    </StickyContainer>
  )
}

const StickyContainer = styled('div')(props => {
  const { isInModal, isSticky, scrollDirection } = props
  const modalPadding = '0.3125rem'
  const cancelModalPadding = `-${modalPadding}`

  return {
    position: 'sticky',
    top: isInModal ? cancelModalPadding : '0',

    marginLeft: isInModal ? cancelModalPadding : '-1.875rem',
    marginRight: isInModal ? cancelModalPadding : '-1.875rem',

    paddingTop: isInModal ? modalPadding : '0.5rem',
    paddingLeft: isInModal ? modalPadding : '1.875rem',
    paddingRight: isInModal ? modalPadding : '1.875rem',
    paddingBottom: isInModal ? modalPadding : '0.5rem',

    backgroundColor: isInModal
      ? props.theme.chroma.white.css()
      : props.theme.chroma.nearlyWhite.css(),
    zIndex: 2,

    [props.theme.breakpoints.phone]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: isInModal ? modalPadding : '0.5rem',
      paddingRight: isInModal ? modalPadding : '0.5rem',
      transform:
        isSticky && scrollDirection === 'down'
          ? 'translateY(-100%)'
          : 'translateY(0)',
      transitionDuration: '0.1s',
      transitionProperty: 'transform'
    }
  }
})

LocationSearch.propTypes = {
  isInModal: PropTypes.bool
}

export default LocationSearch
