import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import CardList from './CardList'

function CardListWithHeadline({ headline, cards }) {
  return (
    <div css={{ width: '100%', marginBottom: '2rem' }}>
      <Headline>{headline}</Headline>
      <CardList>{cards.map(card => card)}</CardList>
    </div>
  )
}

const Headline = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`

CardListWithHeadline.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cards: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default CardListWithHeadline
