import { useContext } from 'react'
import I18n from '../i18n'
import useLocalStorage, { localStorageKeys } from './use-local-storage'
import { UserContext } from './use-user'

export default function useProducts() {
  const user = useContext(UserContext)

  const [_selectedPlanName, setSelectedPlanName] = useLocalStorage(
    localStorageKeys.selectedPlanName,
    null
  )
  const [_selectedFlatrate, setSelectedFlatrate] = useLocalStorage(
    localStorageKeys.selectedFlatrate,
    false
  )

  const planButtonText = planName => {
    if (user?.planNames?.length > 0) {
      return I18n.t('plan_buttons.general.subscribed')
    }

    if (planName === 'free') {
      if (!user) {
        return I18n.t('plan_buttons.free.not_logged_in')
      } else if (!user.freeTerms) {
        return I18n.t('plan_buttons.free.logged_in_not_confirmed')
      } else {
        return I18n.t('plan_buttons.free.logged_in_confirmed')
      }
    } else {
      return I18n.t('plan_buttons.general.not_subscribed')
    }
  }

  const onSelectPlan = (planName, flatrate = false) => {
    if (user?.planNames?.length > 0) {
      return (window.location.href = '/profile')
    }
    setSelectedPlanName(planName)
    setSelectedFlatrate(flatrate)

    if (planName === 'free') {
      if (!user) {
        return (window.location.href = '/signup')
      } else if (!user.freeTerms) {
        return (window.location.href = '/confirm-personal-use')
      } else {
        return (window.location.href = '/locations')
      }
    } else {
      return (window.location.href = '/location-select')
    }
  }

  return { planButtonText, onSelectPlan }
}
