import React from 'react'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Section from '../components/Section'
import Headline from '../components/Headline'
import phoneDemo from './assets/phone-demo.jpg'
import townsquare from './assets/townsquare.jpg'

function MethodologyGrid() {
  return (
    <>
      <Section mid row>
        <Section.Half basis={56} image={townsquare} hideMobile />
        <Section.Half basis={44} wrapperWidth="87.5rem">
          <Section.Wrapper>
            <TextContainer>
              <Headline breakout>
                <Trans id="pages.methodology.grid_section_1.headline" />
              </Headline>
              <Trans id="pages.methodology.grid_section_1.content" copy />
            </TextContainer>
          </Section.Wrapper>
        </Section.Half>
      </Section>
      <Section light row rowPhoneReverse>
        <Section.Half basis={56} wrapperWidth="87.5rem">
          <Section.Wrapper>
            <TextContainer>
              <Headline breakout>
                <Trans id="pages.methodology.grid_section_2.headline" />
              </Headline>
              <Trans id="pages.methodology.grid_section_2.content" copy />
            </TextContainer>
          </Section.Wrapper>
        </Section.Half>
        <Section.Half
          basis={44}
          image={phoneDemo}
          css={{ minHeight: '15rem' }}
        />
      </Section>
    </>
  )
}

const TextContainer = styled.div`
  padding: 3rem 0.5rem;

  ${p => p.theme.breakpoints.phoneUp} {
    min-width: 25rem;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 6rem 3rem;
  }

  p {
    font-size: 1rem;
    margin-top: 1em;
    line-height: 1.5;

    ${p => p.theme.breakpoints.tabletUp} {
      font-size: 1.125rem;
    }
  }
`

export default MethodologyGrid
