import React from 'react'
import styled from '@emotion/styled'
import I18n from '../i18n'
import Section from '../components/Section'
import PersonInfo from '../components/PersonInfo'

function About() {
  return (
    <Section mid>
      <Section.Wrapper>
        <DataWrapper>
          <DataItem>
            <PersonInfo personData={I18n.t('pages.about.nico')} />
          </DataItem>
          <DataItem>
            <PersonInfo personData={I18n.t('pages.about.julian')} />
          </DataItem>
        </DataWrapper>
      </Section.Wrapper>
      <Section.Skew mid direction="up" position="bottom" />
    </Section>
  )
}

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${p => p.theme.breakpoints.tabletUp} {
    flex-direction: row;
  }
`

const DataItem = styled.div`
  &:first-of-type {
    margin-bottom: 2rem;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    &:first-of-type {
      text-align: right;
      margin-bottom: 0;
      margin-right: 1rem;
    }

    &:last-of-type {
      margin-left: 1rem;
    }
  }
`

export default About
