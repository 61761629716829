import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../theme'

function Cursor({ points, className }) {
  return (
    <g className={className}>
      <circle
        cx={points[0].x}
        cy={points[0].y + 2}
        r="6"
        strokeWidth="0.125rem"
        fill="none"
        stroke={theme.chroma.base.css()}
      />
      <line
        x1={points[0].x}
        y1={points[0].y + 8}
        x2={points[1].x}
        y2={points[1].y}
        strokeWidth="0.125rem"
        stroke={theme.chroma.base.css()}
      />
    </g>
  )
}

Cursor.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    })
  ),
  className: PropTypes.string
}

export default Cursor
