import React from 'react'
import PropTypes from 'prop-types'
import ContentCard from '../components/ContentCard'
import I18n from '../i18n'
import ArchivePage from './ArchivePage'

function PressArchive({ pressMentions }) {
  function buildCardsFor(yearData) {
    return yearData.map(data => {
      const { mediaOutlet: title, title: subline, url: link, ...rest } = data
      return (
        <ContentCard
          title={title}
          subline={subline}
          link={link}
          buttonText={I18n.t('pages.press.press_mentions.article_link_title')}
          key={data.id}
          {...rest}
        />
      )
    })
  }

  return (
    <ArchivePage
      title={I18n.t('pages.press_archive.headline')}
      headline={I18n.t('pages.press_archive.headline')}
      backLink="/press"
      backLinkText={I18n.t('pages.press_archive.back_link')}
      yearsData={pressMentions}
      renderCards={buildCardsFor}
    />
  )
}

PressArchive.propTypes = {
  pressMentions: PropTypes.array.isRequired
}

export default PressArchive
