import React from 'react'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Headline from '../components/Headline'
import PressDownload from '../components/assets/icons/press-download.svg'

function PressMediaKit() {
  return (
    <ContentWrapper>
      <div>
        <CustomHeadline breakout>
          <Trans id="pages.press.kit.headline" />
        </CustomHeadline>
      </div>
      <BannerWrapper breakout>
        <ButtonWrapper href="/hystreet_logo_package.zip">
          <PressDownload></PressDownload>
        </ButtonWrapper>
        <Text>
          <Trans id="pages.press.kit.logo_package" />
        </Text>
        <Info>19 KB</Info>
        <Info>ZIP</Info>
      </BannerWrapper>
      <BannerWrapper breakout>
        <ButtonWrapper href="product_screenshots.zip">
          <PressDownload></PressDownload>
        </ButtonWrapper>
        <Text>
          <Trans id="pages.press.kit.product_screenshots" />
        </Text>
        <Info>5,4 MB</Info>
        <Info>ZIP</Info>
      </BannerWrapper>
      <BannerWrapper breakout>
        <ButtonWrapper href="/images.zip">
          <PressDownload></PressDownload>
        </ButtonWrapper>
        <Text>
          <Trans id="pages.press.kit.images" />
        </Text>
        <Info>2 MB</Info>
        <Info>ZIP</Info>
      </BannerWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding-top: 4rem;
  ${p => p.theme.breakpoints.tabletUp} {
    margin-left: 2rem;
    margin-right: -2rem;
    padding-top: 2rem;
  }
`

const CustomHeadline = styled(Headline)`
  margin-bottom: 1rem;
  color: ${p => p.theme.chroma.white.css()};

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 1.5rem;
  }
`
const BannerWrapper = styled.div`
  max-width: 477px;
  display: grid;
  grid-template-columns: 1fr 6fr 2fr 2fr;
  align-items: center;
  margin: 0.6rem 0rem;
  padding: 0.8rem;
  background-color: ${p => p.theme.chroma.white.css()};
  ${p => p.theme.breakpoints.tabletUp} {
    margin-left: ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

const ButtonWrapper = styled.a`
  margin-right: 1.5rem;
  ${p => p.theme.breakpoints.tabletUp} {
    cursor: pointer;
  }
`

const Text = styled.p`
  font-weight: 700;
  color: ${p => p.theme.chroma.accent.css()};
  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`

const Info = styled.p`
  text-align: right;
  margin-right: 1.3rem;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`
PressMediaKit.propTypes = {}

export default PressMediaKit
