import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import PressDownload from '../components/assets/icons/press-download.svg'
import PressMagnifyingGlass from '../components/assets/icons/press-magnifying-glass.svg'

function VitalityIndexCard({
  imageSource,
  title,
  info,
  downloadSource,
  onImageZoomClick
}) {
  return (
    <ContentWrapper>
      <Image imageSource={imageSource}>
        <Button
          onClick={() => {
            onImageZoomClick(downloadSource)
          }}
        >
          <PressMagnifyingGlass></PressMagnifyingGlass>
        </Button>
      </Image>
      <Text>
        <Title>{title}</Title>
        <Info>{info}</Info>
      </Text>
      <ButtonWrapper href={downloadSource} download={downloadSource}>
        <PressDownload></PressDownload>
      </ButtonWrapper>
    </ContentWrapper>
  )
}

const Image = styled.div`
  min-width: 144px;
  min-height: 90px;
  max-width: 144px;
  max-height: 90px;
  width: 144px;
  height: 90px;
  background: url(${p => p.imageSource});
  background-size: cover;
  border: 0.5px solid;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.25rem;
`

const Text = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 1rem;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 150px 1fr 25px;
  align-items: end;
`
const Title = styled.p`
  line-height: 1.3;
  white-space: pre-wrap;
  font-weight: 700;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`
const Info = styled.p`
  padding-top: 0.5rem;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const ButtonWrapper = styled.a`
  margin-right: 1.5rem;
  ${p => p.theme.breakpoints.tabletUp} {
    cursor: pointer;
  }
`
const Button = styled.button`
  ${p => p.theme.breakpoints.tabletUp} {
    cursor: pointer;
  }
`

VitalityIndexCard.propTypes = {
  title: PropTypes.object.isRequired,
  imageSource: PropTypes.string,
  info: PropTypes.string,
  downloadSource: PropTypes.string,
  onImageZoomClick: PropTypes.any
}

export default VitalityIndexCard
