import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Section from '../components/Section'
import Mail from '../components/assets/icons/mail.svg'
import Phone from '../components/assets/icons/phone.svg'

function PressContact() {
  return (
    <Section.Wrapper>
      <ContentWrapper>
        <Title>{<Trans id="pages.press.contact.headline" />}</Title>
        <Text>{<Trans id="pages.press.contact.text" />}</Text>
        <LinkWrapper>
          <IconWrapper>
            <Mail></Mail>
          </IconWrapper>
          <Link href={`mailto:info@hystreet.com`}>info@hystreet.com</Link>
        </LinkWrapper>
        <LinkWrapper>
          <IconWrapper>
            <Phone></Phone>
          </IconWrapper>
          <Link href={`tel:+4922177204636`}>+49 221 77204-636</Link>
        </LinkWrapper>
      </ContentWrapper>
    </Section.Wrapper>
  )
}

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.3;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: ${p => p.theme.chroma.nearlyWhite.hex()};
`
const Text = styled.span`
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 2rem;
  color: ${p => p.theme.chroma.nearlyWhite.hex()};
  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${p => p.theme.chroma.base.hex()};
  text-align: left;
  word-break: break-word;
  hyphens: auto;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 0.3);
  ${p => p.theme.breakpoints.tabletUp} {
    margin-left: ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

const Link = styled.a`
  font-weight: 700;
  color: ${p => p.theme.chroma.accent.css()};
  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  margin: 0.25rem 0rem;
  padding: 0.8rem;
  background-color: ${p => p.theme.chroma.nearlyWhite.css()};
  margin-top: auto;
`

const IconWrapper = styled.div`
  margin-right: 1rem;
`

PressContact.propTypes = {
  blogPostData: PropTypes.object.isRequired
}

export default PressContact
