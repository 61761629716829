import { useState, useEffect, useRef } from 'react'

export default function useSticky() {
  const [direction, setDirection] = useState('down')
  const [isSticky, setSticky] = useState(false)

  const ref = useRef()

  useEffect(() => {
    let previousScrollY = 0
    let throttling = false

    function checkDirection() {
      if (window.scrollY >= previousScrollY) {
        setDirection('down')
      } else {
        setDirection('up')
      }

      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0)
      }

      previousScrollY = window.scrollY
    }

    function scrollHandler() {
      if (!throttling) {
        window.requestAnimationFrame(() => {
          checkDirection(previousScrollY)
          throttling = false
        })

        throttling = true
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return [isSticky, direction, ref]
}
