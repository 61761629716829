import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Trans } from '../../i18n'
import useMapZoom from '../../lib/use-map-zoom'

import ListHeadline from './ListHeadline'
import LocationLabelList from './LocationLabelList'
import { FAV_TYPE } from './LocationPicker'
import LocationLabel from './LocationLabel'

function ListFavs({
  locations = [],
  onFavChange,
  onSelect,
  favable,
  zoomable,
  disabledLocationIds = [],
  locationIdsWithGeoJson
}) {
  const { _mapZoom, setMapZoom } = useMapZoom()

  const handleFavClick = locationId => _event => {
    onFavChange([locationId], { type: FAV_TYPE.REMOVE })
  }

  const handleMainClick = location => _event => {
    onSelect(location)
  }

  const handleZoomableLocationClick = locationId => _event => {
    setMapZoom(locationId)
  }

  return (
    <>
      <ListHeadline>
        <Trans id="locations.favorites" />
      </ListHeadline>
      <FavsCard>
        <LocationLabelList
          locations={locations}
          renderItem={location => (
            <LocationLabel
              title={location.name}
              sup={location.city}
              href={onSelect ? undefined : '/locations/' + location.id}
              favorite
              disabled={disabledLocationIds.includes(location.id)}
              onFavClick={handleFavClick(location.id)}
              favable={favable}
              onMainClick={onSelect ? handleMainClick(location) : undefined}
              zoomable={zoomable}
              zoomableLocation={locationIdsWithGeoJson.includes(location.id)}
              onZoomableLocationClick={handleZoomableLocationClick(location.id)}
              padding
            />
          )}
        />
      </FavsCard>
    </>
  )
}

ListFavs.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      city: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onFavChange: PropTypes.func,
  onSelect: PropTypes.func,
  favable: PropTypes.bool,
  zoomable: PropTypes.bool,
  disabledLocationIds: PropTypes.arrayOf(PropTypes.number),
  locationIdsWithGeoJson: PropTypes.arrayOf(PropTypes.number)
}

const FavsCard = styled.div`
  overflow: hidden;
  padding: 0;
`

export default ListFavs
