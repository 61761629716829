import React from 'react'
import styled from '@emotion/styled'

import TextBlog from '../components/TextBlog'

import I18n from '../i18n'

function PartnerExplainText() {
  return (
    <TextBlogWrapper>
      <TextBlog
        heading={I18n.t('partner_page.heading.exact')}
        content="partner_page.content.exact"
      />
      <TextBlog
        heading={I18n.t('partner_page.heading.morevalue')}
        content="partner_page.content.morevalue"
      />
    </TextBlogWrapper>
  )
}

const TextBlogWrapper = styled.div`
  @media (max-width: 41rem) {
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem;
`

export default PartnerExplainText
