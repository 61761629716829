import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import I18n from '../i18n'

import Spacer from '../components/Spacer'
import AdvertisementBanner from '../components/AdvertisementBanner'

import Hero from '../sections/Hero'
import Contact from '../sections/Contact'
import Intro from '../sections/Intro'
import PartnerLogos from '../sections/PartnerLogos'
import ProductsTable from '../sections/ProductsTable'
import Teaser from '../sections/Teaser'
import Testimonials from '../sections/Testimonials'
import TopLocations from '../sections/TopLocations'
import UserInterests from '../sections/UserInterests'

function Home({
  topLocations,
  locationsCount,
  citiesCount,
  countriesCount,
  user,
  showAdvertisingBanner,
  plans
}) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.home.title')}</title>
      </Helmet>
      <Hero loggedIn={!!user}></Hero>
      {showAdvertisingBanner && (
        <AdvertisementBanner cookieName="advertisement_banner_shown" />
      )}
      <Intro
        citiesCount={citiesCount}
        locationsCount={locationsCount}
        countriesCount={countriesCount}
        loggedIn={!!user}
      />
      <Teaser />
      <TopLocations locations={topLocations} />

      <ProductsTable plans={plans} user={user} withLink />
      <Testimonials />
      <UserInterests />
      <Spacer light />
      <Contact teaser="contact.teaser_partner" service="contact.service" />
      <PartnerLogos />
      <Spacer light />
    </div>
  )
}

Home.propTypes = {
  topLocations: PropTypes.array.isRequired,
  locationsCount: PropTypes.number.isRequired,
  citiesCount: PropTypes.number.isRequired,
  countriesCount: PropTypes.number.isRequired,
  user: PropTypes.object,
  showAdvertisingBanner: PropTypes.bool,
  plans: PropTypes.object.isRequired
}

export default Home
