import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Headline from '../components/Headline'
import Section from '../components/Section'

import dashboardImage from './assets/dashboard-germany.png'
import smartCityImage from './assets/smart-city.png'
import econworxImage from './assets/econworx-partner-image.png'

function ApiExamples() {
  return (
    <Section>
      <Section.Wrapper css={{ paddingTop: '8rem', paddingBottom: '10rem' }}>
        <Headline breakout css={{ marginBottom: '1.25rem' }}>
          {I18n.t('pages.developer.api.examples.headline')}
        </Headline>

        <Content>
          <HalfSectionWithImage image={econworxImage} />
          <HalfSectionWithText>
            <Title>
              {I18n.t('pages.developer.api.examples.econworx.title')}
            </Title>
            <Text>
              <Trans id="pages.developer.api.examples.econworx.text" />
            </Text>
            <ButtonContainer>
              <Button.Link
                outline
                target="_blank"
                href="https://www.econworks.de/"
              >
                {I18n.t('pages.developer.api.examples.econworx.button_text')}
              </Button.Link>
            </ButtonContainer>
          </HalfSectionWithText>
        </Content>

        <Content>
          <HalfSectionWithImage image={smartCityImage} />
          <HalfSectionWithText>
            <Title>
              {I18n.t('pages.developer.api.examples.smart_city_muenster.title')}
            </Title>
            <Text>
              <Trans id="pages.developer.api.examples.smart_city_muenster.text" />
            </Text>
            <ButtonContainer>
              <Button.Link
                outline
                target="_blank"
                href="https://dashboard.smartcity.ms/"
              >
                {I18n.t(
                  'pages.developer.api.examples.smart_city_muenster.button_text'
                )}
              </Button.Link>
            </ButtonContainer>
          </HalfSectionWithText>
        </Content>
        <Content>
          <HalfSectionWithImage image={dashboardImage} />
          <HalfSectionWithText>
            <Title>
              {I18n.t(
                'pages.developer.api.examples.dashboard_deutschland.title'
              )}
            </Title>
            <Text>
              <Trans id="pages.developer.api.examples.dashboard_deutschland.text" />
            </Text>
            <ButtonContainer>
              <Button.Link
                outline
                target="_blank"
                href="https://www.dashboard-deutschland.de"
              >
                {I18n.t(
                  'pages.developer.api.examples.dashboard_deutschland.button_text'
                )}
              </Button.Link>
            </ButtonContainer>
          </HalfSectionWithText>
        </Content>
      </Section.Wrapper>
    </Section>
  )
}

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 900;
  margin-bottom: 0.5rem;

  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`

const Content = styled.div`
  padding: 1rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.tabletUp} {
    padding-left: 1rem;
    flex-direction: row;
    margin-bottom: 3rem;
  }
`

const Text = styled.div`
  line-height: 1.5;
  margin-bottom: 1.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;

  a {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`

const HalfSectionWithImage = styled(Section.Half)`
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 11.375rem;
  margin-bottom: 2rem;

  @media (min-width: 960px) {
    min-height: 20.75rem;
    margin-bottom: 0;
  }
`

const HalfSectionWithText = styled(Section.Half)`
  padding-left: 0;
  @media (min-width: 768px) {
    padding-left: 2rem;
  }
`

export default ApiExamples
