import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import I18n from '../../i18n'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import Button from '../Button'
import useAuthorization from '../../lib/use-authorization'
import Modal from './Modal'
import FullHeightCalendar from './FullHeightCalendar'
import PredefinedFilter from './PredefinedFilter'
import predefinedFilters, {
  onlyFilterValuesWithMeasurements
} from './lib/predefinedFilters'

const ACTIVATED_FILTER_KEYS = ['day', 'month']

function TimeRangeModal({ isOpen, onClick, ...rest }) {
  const { location } = useLocation()
  const [filter] = useFilter()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const { permissions } = useAuthorization()

  // set selected dates to null if container dates change
  useEffect(() => {
    setFrom(null)
    setTo(null)
  }, [filter.from, filter.to])

  function handleSubmit() {
    if (!from || !to) return
    onClick({ from, to })
  }

  function handleDateChangeStart({ start }) {
    setFrom(start)
    setTo(null)
  }

  function handleDateChange({ start, end }) {
    let earlierDate = start
    let laterDate = end
    if (dayjs(laterDate).isBefore(earlierDate, 'day')) {
      earlierDate = end
      laterDate = start
    }

    setFrom(earlierDate)
    setTo(laterDate)
  }

  function handleTimeRangeChange({ from, to }) {
    setFrom(from)
    setTo(to)
    onClick({ from, to })
  }

  // Check for active value in passed props or in state
  function getActiveValue(filter) {
    if (from && to) {
      return filter.values.find(value => {
        return (
          dayjs(value.from).isSame(dayjs(from), 'day') &&
          dayjs(value.to).isSame(dayjs(to), 'day')
        )
      })
    }

    return filter.values.find(value => {
      return (
        dayjs(value.from).isSame(dayjs(filter.from), 'day') &&
        dayjs(value.to).isSame(dayjs(filter.to), 'day')
      )
    })
  }

  function rangeString() {
    if (!from && !to) {
      return I18n.t('location.timerange.choose_date')
    }

    const startf = from ? I18n.l('date.formats.short', from) : ''
    const endf = to ? I18n.l('date.formats.short', to) : ''

    return startf + ' - ' + endf
  }

  // selected calendar dates
  const calendarStartDate = from || filter.from
  const calendarEndDate = from ? to : filter.to

  // selectable date range
  const calendarMinDate = permissions.earliestAccessibleDate
  const calendarMaxDate = dayjs(location.metadata.latestMeasurementAt)
    .endOf('day')
    .toDate()

  // calendar date range (months) to display
  const calendarMin = dayjs(location.metadata.earliestMeasurementAt)
    .subtract(3, 'months')
    .toDate()
  const calendarMax = dayjs()
    .add(2, 'months')
    .toDate()

  return (
    <Modal isOpen={isOpen} fullWidth={true} {...rest}>
      <PredefinedFiltersWrapper>
        {predefinedFilters.map(filter => {
          if (!ACTIVATED_FILTER_KEYS.includes(filter.key)) return null
          const values = onlyFilterValuesWithMeasurements(
            permissions.earliestAccessibleDate,
            filter.values
          )
          if (values.length === 0) return null

          return (
            <FilterWrapper key={filter.key}>
              <PredefinedFilter
                activeValue={getActiveValue(filter)}
                values={values}
                onTimeRangeChange={handleTimeRangeChange}
              />
            </FilterWrapper>
          )
        })}
      </PredefinedFiltersWrapper>
      <FullHeightCalendar
        startDate={calendarStartDate}
        endDate={calendarEndDate}
        min={calendarMin}
        max={calendarMax}
        minDate={calendarMinDate}
        maxDate={calendarMaxDate}
        onDateChangeStart={handleDateChangeStart}
        onDateChange={handleDateChange}
        canSafelySetHeight={isOpen}
      />
      <SubmitButton onClick={handleSubmit} baseColor disabled={!from || !to}>
        {I18n.t('location.timerange.submit')} ({rangeString()})
      </SubmitButton>
    </Modal>
  )
}

TimeRangeModal.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
}

const SubmitButton = styled(Button)`
  width: 100%;

  @media (min-height: 640px) {
    margin-top: 2rem;
  }
`

const FilterWrapper = styled.div`
  margin-right: 0.625rem;
  width: 100%;

  &:not(:first-of-type) {
    margin-right: 0;
  }
`

const PredefinedFiltersWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;

  ${p => p.theme.breakpoints.tabletUp} {
    display: none;
  }

  @media (min-height: 640px) {
    margin-bottom: 2rem;
  }
`

export default TimeRangeModal
