import dayjs from 'dayjs'
import React from 'react'
import { getCalendarWeekLabel } from '../components/LocationDetail/lib/dateUtilities'
import LocationStatsCard, {
  statsObjectPropTypes
} from '../components/LocationDetail/LocationStatsCard/LocationStatsCard'
import { PedestrianCountCard } from '../components/LocationDetail/LocationStatsCard/PedestrianCountCard'
import Section from '../components/Section'
import { default as I18n } from '../i18n'

function PedestrianCountLocationDataSection({ stats }) {
  const {
    today,
    yesterday,
    startOfTwoDaysAgo,
    startOfThreeDaysAgo,
    startOfLastWeek,
    endOfLastWeek,
    startOfTwoWeeksAgo,
    startOfLastWeekAYearAgo,
    startOfLastWeekTwoYearsAgo,
    startOfLastMonth,
    endOfLastMonth,
    startOfTwoMonthsAgo,
    startOfLastMonthAYearAgo,
    startOfLastMonthTwoYearsAgo,
    startOfThisYear,
    startOfLastYear,
    startOfTwoYearsAgo,
    startOfThreeYearsAgo
  } = (stats && stats.dates) || {}

  return (
    <Section
      id="locationData"
      css={{
        marginTop: '1rem',
        marginBottom: '1.3rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      <LocationStatsCard headline={I18n.t('location.details.yesterday')}>
        <PedestrianCountCard
          timerangeLabel={I18n.strftime(yesterday, '%A, %d.%m.%Y')}
          statsObject={stats?.yesterday}
          comparedTimeranges={[
            {
              timerangeLabel: I18n.t('location.details.same_day_a_week_ago'),
              statsObject: stats?.yesterdayAWeekAgo
            },
            {
              timerangeLabel: I18n.strftime(startOfTwoDaysAgo, '%d.%m.%Y'),
              statsObject: stats?.twoDaysAgo
            },
            {
              timerangeLabel: I18n.strftime(startOfThreeDaysAgo, '%d.%m.%Y'),
              statsObject: stats?.threeDaysAgo
            }
          ]}
        ></PedestrianCountCard>
      </LocationStatsCard>

      <LocationStatsCard headline={I18n.t('location.details.past_week')}>
        <PedestrianCountCard
          timerangeLabel={`
              ${I18n.strftime(startOfLastWeek, '%d.%m.%Y')} 
              ${I18n.t('location.details.until')} 
              ${I18n.strftime(endOfLastWeek, '%d.%m.%Y')} 
              ${getCalendarWeekLabel(startOfLastWeek)}
              `}
          statsObject={stats?.lastWeek}
          comparedTimeranges={[
            {
              timerangeLabel: `${I18n.t(
                'location.details.previous_week'
              )} ${getCalendarWeekLabel(startOfTwoWeeksAgo)} `,
              statsObject: stats?.twoWeeksAgo
            },
            {
              timerangeLabel: `${I18n.t(
                'location.details.same_calendar_week_in_year'
              )} ${dayjs(startOfLastWeekAYearAgo).year()}`,
              statsObject: stats?.lastWeekAYearAgo
            },
            {
              timerangeLabel: `${I18n.t(
                'location.details.same_calendar_week_in_year'
              )} ${dayjs(startOfLastWeekTwoYearsAgo).year()}`,
              statsObject: stats?.lastWeekTwoYearsAgo
            }
          ]}
        ></PedestrianCountCard>
      </LocationStatsCard>

      <LocationStatsCard headline={I18n.t('location.details.past_month')}>
        <PedestrianCountCard
          timerangeLabel={`
              ${I18n.strftime(startOfLastMonth, '%d.%m.%Y')}
              ${I18n.t('location.details.until')} 
              ${I18n.strftime(endOfLastMonth, '%d.%m.%Y')}
              `}
          statsObject={stats?.lastMonth}
          comparedTimeranges={[
            {
              timerangeLabel: `${I18n.t(
                'location.details.previous_month'
              )} (${I18n.strftime(startOfTwoMonthsAgo, '%B')})`,
              statsObject: stats?.twoMonthsAgo
            },
            {
              timerangeLabel: `${I18n.t(
                'location.details.same_month_in_year'
              )} ${dayjs(startOfLastMonthAYearAgo).year()}`,
              statsObject: stats?.lastMonthAYearAgo
            },
            {
              timerangeLabel: `${I18n.t(
                'location.details.same_month_in_year'
              )} ${dayjs(startOfLastMonthTwoYearsAgo).year()}`,
              statsObject: stats?.lastMonthTwoYearsAgo
            }
          ]}
        ></PedestrianCountCard>
      </LocationStatsCard>

      <LocationStatsCard
        headline={I18n.t('location.details.current_year_to_date')}
      >
        <PedestrianCountCard
          timerangeLabel={`
              ${I18n.strftime(startOfThisYear, '%d.%m.%Y')}
              ${I18n.t('location.details.until')} 
              ${I18n.strftime(yesterday, '%d.%m.%Y')}
            `}
          statsObject={stats?.thisYearToDate}
          comparedTimeranges={[
            {
              timerangeLabel: `${dayjs(startOfLastYear).year()}`,
              statsObject: stats?.lastYearToDate
            },
            {
              timerangeLabel: ` ${dayjs(startOfTwoYearsAgo).year()}`,
              statsObject: stats?.twoYearsAgoToDate
            },
            {
              timerangeLabel: ` ${dayjs(startOfThreeYearsAgo).year()}`,
              statsObject: stats?.threeYearsAgoToDate
            }
          ]}
        ></PedestrianCountCard>
      </LocationStatsCard>
    </Section>
  )
}

PedestrianCountLocationDataSection.propTypes = {
  stats: statsObjectPropTypes
}

export default PedestrianCountLocationDataSection
