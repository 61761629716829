import React from 'react'
import styled from '@emotion/styled'

import I18n from '../../i18n'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'

function Breadcrumb() {
  const { location } = useLocation()

  const [filter, dispatchFilter] = useFilter()
  const isMultiLocationComparisonModeActive =
    filter.viewType === 'compare' && filter.compareType === 'multiLocation'

  function handleComparisonModeBreadcrumbClick() {
    dispatchFilter({ type: 'none' })
  }
  return (
    <Row>
      <a href="/locations">{I18n.t('location.back_button')}</a>
      <div css={{ margin: '0 0.3rem', lineHeight: 0 }}>{'>'}</div>
      <a href={`/locations?q=${location.city.toLowerCase()}`}>
        {location.city}
      </a>

      {isMultiLocationComparisonModeActive && (
        <React.Fragment>
          <div css={{ margin: '0 0.3rem', lineHeight: 0 }}>{'>'}</div>
          <StyledLink onClick={handleComparisonModeBreadcrumbClick}>
            {I18n.t('location.compare_multiple_locations.comparison_mode')}
          </StyledLink>
        </React.Fragment>
      )}
    </Row>
  )
}

const Row = styled.div({
  display: 'flex',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  letterSpacing: '0.05rem',
  fontWeight: 700,
  alignItems: 'center'
})

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`

export default Breadcrumb
