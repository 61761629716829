import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import I18n from '../../i18n'
import getLocationStateLabel from '../../lib/getLocationStateLabel'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import useModalState from '../../lib/use-modal-state'
import theme from '../../theme'
import Headline from '../Headline'
import * as Icon from '../Icon'
import useAuthorization from '../../lib/use-authorization'
import SingleSelect from '../forms/SingleSelect'
import ActionButton from './ActionButton'
import {
  findActiveTimerangeItem,
  getTimerangeSelectorItems
} from './lib/predefinedFilters'

const isSSR = typeof window === 'undefined'

function HeaderBar({ hasMap }) {
  const [_activeModal, { openModal, modals }] = useModalState()
  const { location } = useLocation()
  const [filter, dispatchFilter] = useFilter()
  const { permissions } = useAuthorization()

  const [timerange, setTimerange] = useState(
    findActiveTimerangeItem(
      filter,
      getTimerangeSelectorItems(
        location,
        permissions.earliestAccessibleDate,
        filter
      ),
      location.metadata.latestMeasurementAt
    )
  )

  const UIState = {
    isMultiLocationComparisonModeActive:
      filter.viewType === 'compare' && filter.compareType === 'multiLocation',
    showCsvButton:
      permissions.accessLocationCsvDownloads &&
      filter.objectType === 'PERSON' &&
      filter.objectSubtype === 'ALL',
    showReportButton:
      permissions.accessLocationMonthlyReport &&
      filter.objectType === 'PERSON' &&
      filter.objectSubtype === 'ALL'
  }

  const HeaderBarHandlers = {
    handleTimeRangeChange: item => {
      setTimerange(item)
      if (item.humanReadableKey === 'customDateRange') {
        openModal(modals.timerange)
        return
      }
      dispatchFilter({ type: 'set/timerange', from: item.from, to: item.to })
    },

    handleCsvButtonClick: () => {
      openModal(modals.multiLocationCsv)
    }
  }

  return (
    <Container withBottomMargin={UIState.isMultiLocationComparisonModeActive}>
      {UIState.isMultiLocationComparisonModeActive && (
        <React.Fragment>
          <Headline centered css={{ color: theme.chroma.accent.css() }}>
            {I18n.t('location.compare_multiple_locations.comparison_mode')}
          </Headline>
          {!isSSR && (
            <SelectWrapper data-test-id="timerange_selector">
              <SingleSelect
                options={getTimerangeSelectorItems(
                  location,
                  permissions.earliestAccessibleDate,
                  filter
                )}
                onChange={HeaderBarHandlers.handleTimeRangeChange}
                value={timerange}
                variant={'SECONDARY'}
              />
            </SelectWrapper>
          )}
        </React.Fragment>
      )}
      {!UIState.isMultiLocationComparisonModeActive && (
        <React.Fragment>
          <Headline css={hasMap}>
            {location.name}

            <CityText>
              <br />
              {location.city}
              {location.population && (
                <span css={{ textTransform: 'none' }}>
                  {' '}
                  &ndash;{' '}
                  {I18n.toNumber(location.population, {
                    precision: 0
                  })}{' '}
                  {I18n.t('location.population')}
                </span>
              )}
              <span> ({getLocationStateLabel(location.state)})</span>
            </CityText>
          </Headline>

          <ButtonsWrapper>
            <HeaderButtonsWrapper>
              {hasMap && (
                <ActionButton
                  title={I18n.t('location.map_action')}
                  onClick={() => openModal(modals.map)}
                  variant="secondary"
                  icon={<Icon.FA icon={Icon.FaMap} />}
                />
              )}

              {UIState.showCsvButton && (
                <ActionButton
                  variant="secondary"
                  title={I18n.t('location.csv_button')}
                  icon={<Icon.FA icon={Icon.FaFileArrowDown} />}
                  onClick={HeaderBarHandlers.handleCsvButtonClick}
                />
              )}

              {UIState.showReportButton && (
                <ActionButton
                  variant="secondary"
                  title="Report"
                  icon={<Icon.FA icon={Icon.FaFileChartColumn} />}
                  onClick={() => openModal(modals.report)}
                />
              )}
            </HeaderButtonsWrapper>

            <SelectWrapper data-test-id="timerange_selector">
              {!isSSR && (
                <SingleSelect
                  options={getTimerangeSelectorItems(
                    location,
                    permissions.earliestAccessibleDate,
                    filter
                  )}
                  onChange={HeaderBarHandlers.handleTimeRangeChange}
                  value={timerange}
                  variant={'SECONDARY'}
                />
              )}
            </SelectWrapper>
          </ButtonsWrapper>
        </React.Fragment>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${p => p.withBottomMargin && '.5rem'};
`

const CityText = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 1rem;
`

const SelectWrapper = styled.div`
  min-width: 16rem;
  padding-bottom: 0.9rem;
  margin-left: 0;
  height: 2.5rem;

  @media (min-width: 1142px) {
    margin-left: 1rem;
  }
`

const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 0.9rem;
  margin-left: 0;

  @media (min-width: 1126px) {
    margin-left: 1rem;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1141px) {
    flex-direction: row-reverse;
    width: 100%;
  }

  @media (min-width: 1142px) {
    flex-direction: row;
  }
`

HeaderBar.propTypes = {
  hasMap: PropTypes.bool
}

export default HeaderBar
