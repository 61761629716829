import styled from '@emotion/styled'

const TextBox = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.chroma.base.alpha(0.15).css()};
  background-color: ${props => props.theme.chroma.white.hex()};
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  padding: 2.75rem 1.5rem 1.5rem 1.5rem;
  margin-bottom: 7rem;

  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
    line-height: 1.4;
    width: calc(33% - 1rem);
    margin-bottom: 4rem;
  }
`

export default TextBox
