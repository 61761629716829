import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FaChevronDown, FaChevronUp } from './Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Collapsible({ title, open, children, ...passTroughProps }) {
  const [isOpen, setIsOpen] = useState(open)
  const handleOpen = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <EntryWrapper {...passTroughProps} >
      <VerticalWrapper>
        <HeaderWrapper row onClick={handleOpen}>
          <Title>{title}</Title>
          {!isOpen ? (
            <IconWithMargin icon={FaChevronDown} />
          ) : (
            <IconWithMargin icon={FaChevronUp} />
          )}
        </HeaderWrapper>
        {isOpen ? <ContentWrapper>{children}</ContentWrapper> : <div></div>}
      </VerticalWrapper>
    </EntryWrapper>
  )
}
Collapsible.propTypes = {
  title: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired
}

function IconWithMargin({ icon }) {
  return (
    <Button style={{marginRight: "2rem"}}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

export default Collapsible

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`

const HeaderWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;

  -webkit-box-shadow: 0px 1px 9px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 8px 0px rgba(156, 156, 156, 0.25);
  background-color: #fff;
`
const EntryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

`
const ContentWrapper = styled.div`
  background-color: #fff;
  padding: 2rem 1.5rem;
  background-color: ${props => props.theme.chroma.nearlyWhite.hex()};

  a {
    text-decoration: underline;
  }
`
const VerticalWrapper = styled.div`
  width: 80%;
  @media (max-width: 60rem) {
    width: 100%;
  }
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Title = styled.h3`
  margin: 1rem;
`
