import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'

/**
 * Function to determine if a passed value is positive or not.
 * @param {Number} stateIndexVariation - The value to check.
 * @returns {Boolean} - True if the value is positive, false otherwise.
 */
export const isPositive = stateIndexVariation => {
  return stateIndexVariation >= 0
}

/**
 * Returns the state index variation for a given state as a formatted string.
 * @param {Object} stateIndices - Object using state labels as keys and an object of indexValue and timestamp as value.
 * @param {String} stateId - The id of the state to get the index variation for.
 * @returns a string with the index variation or '-' if the index value is null.
 */
export const stateIndexFor = (stateIndices, stateId) => {
  return stateIndices[stateId].indexValue
    ? stateIndices[stateId].indexValue.toFixed(2)
    : ' - '
}

/**
 * Returns the state index variation for a given state as a formatted string.
 * @param {Object} stateIndices - Object using state labels as keys and an object of indexValue and timestamp as value.
 * @param {String} stateId
 * @returns a string with the index variation or '-' if the index value is null.
 */
export const indexLabelFor = (stateIndices, stateId) => {
  if (stateIndices[stateId].indexValue === null) {
    return '--'
  }

  const indexValue = stateIndices[stateId].indexValue.toFixed(2)
  return isPositive(indexValue) ? `+${indexValue}` : indexValue
}

/**
 * Returns the label styles for a given state, depending on the index variation (positive or negative).
 * @param {String} stateKey - The id of the state to get the label styles for.
 * @returns {Object} - The label styles for the given state.
 */
export const dynamicLabelStyles = (stateIndices, stateKey) => {
  return isPositive(stateIndexFor(stateIndices, stateKey))
    ? positiveLabelStyles
    : neutralLabelStyles
}

/**
 * Component to render a map of Germany with the state index variations - being the difference between the current and the previous monthly index value.
 * @param {Object} stateIndices - Object using state labels as keys and an object of indexValue and timestamp as value.
 * @returns {React.Component} - The rendered component.
 */
const GermanySvg = ({ stateIndices }) => {
  return (
    <svg
      data-testid="germany-svg"
      version="1.1"
      id="Bundesrepublik_Deutschland"
      width="591.504"
      height="800.504"
      viewBox="0 0 591.504 800.504"
      overflow="visible"
      enableBackground="new 0 0 591.504 800.504"
      className={svgStyles}
    >
      <g data-testid="state-shapes">
        <path
          id="Thüringen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M312.004,351.725l1.845-0.401l3.39,0.274l1.912,0.169
		l2.919,0.688l0.429,0.307l1.961,1.819l1.265-0.54l-1.666,2.241l-0.478,1.607l1.212,2.886l2.09,3.257l1.68,2.591l0.958,1.226
		l-0.147,2.39l-0.133,2.706l0.777,0.467l0.86,0.961l0.842,0.105l0.75-0.073l1.158-0.412l0.629-0.317l6.445,2.04l0.459,0.054
		l6.443,0.137l1.402-0.031l0.703-0.148l2.77,0.116l4.188,1.607l0.233,0.296l0.042,0.338l-0.275,1.872l0.55,1.015l0.021,0.402
		l1.797,0.603l-0.523,0.507l-0.064,0.148l-0.019,0.147l0.49,0.634l0.677,0.646l0.309,0.105l0.396,0.211l1.306,1.173l0.466,0.509
		l0.041,0.274l-0.063,0.148l-0.4,0.687l-0.703,1.11l-1.466,2.146l-0.819,1.152l-0.519,0.593l-0.274,0.2l-0.472,0.18l-0.052-0.011
		l-0.75-0.855l-0.186,0.011l-0.222,0.158l-0.339,0.528l-0.576,1.005l-0.104,0.803l1.554,1.237l1.591,0.042l0.936-0.444l0.243,0.021
		l0.862,0.359l1.571,1.607l1.082,2.452v0.276l-0.533,1.247l-0.275,0.508l-1.316,0.242l0.83,3.426l0.486,0.371l0.904,0.423
		l0.054,0.263l3.874-0.083l0.841-0.709l0.255-0.231l0.342-0.753l0.202-0.179l6.296,0.994l1.561,0.961l0.313,1.218l0.188,1.331
		l0.139,0.562l4.777,1.521l1.805,0.486l2.808-0.581l2.029-0.539l0.835,0.273l3.452,2.199l2.168,1.556l0.254,0.231l2.348,3.013
		l0.18,0.53l0.021,0.242l0.043,0.89l0.956-0.425l0.343-0.083l3.226-0.52l0.244-0.021l3.615,1.195l1.09,0.498l-0.075-0.72
		l0.249-0.273l0.275-0.308l0.676-0.55l0.106,0.104l1.406,1.799l0.444,0.836l0.264,1.076l0.042,0.032l0.577-0.402l0.242-0.124
		l0.714-0.932l3.182-6.872l0.064-0.307l0.042-1.026l-0.475-0.624l-0.382-0.232l-1.306-0.963l-0.248-1.681l1.607-2.505l2.991-1.459
		l0.096-0.021l7.692,2.41l2.896,1.057l0.376,0.244l0.19,0.274l0.242,0.89l-0.127,0.653l-0.072,1.248l0.307,1.767l2.475,1.86
		l2.379,0.899l2.421,0.612l0.339,1.649l0.586,2.272l0.147,0.255l0.661,1.098l0.264,0.254l0.541,0.371l-0.815,1.237l-2.796,0.911
		l-1.422,0.252l-0.561-0.136l-1.894,0.295l-3.209,0.814l-0.264,0.148l-1.455,1.235l-0.031,1.248l-0.924,2.623l-0.201,0.084
		l-1.266,0.37l-0.04-0.064l-0.556-0.729l-0.593-0.221h-1.575l-0.296,0.096l-1.633,1.269l-0.011,0.072l0.021,1.376l-4.357,1.574
		l-1.325-0.148l-0.572-0.739l-0.471,0.963l-0.755,5.443l-0.085,1.048l0.032,0.506l0.021,0.286l2.49,4.429l0.793,0.784l2.955,0.793
		l-0.222,1.292l-1.085,1.437l-0.094,0.104l-2.041,2.136l-1.935,0.443l-0.148-0.02l-3.643,1.565l-3.231,1.417l-0.518,0.221
		l-0.211,0.149l-0.291,0.844l1.967,0.581l0.35-0.316l0.682,0.021l0.095,0.041l0.233,0.403l-0.148,0.54l-0.105,0.084l-0.211,0.105
		l-0.842,0.094l-0.351-0.137l-1.156,0.762l-2.474-0.305l-3.965,0.199l0.459-2.813l-0.438-3.075l-0.751-0.792l-0.376,0.103
		l-0.137,0.087l-1.347,1.235l-0.055,0.814l-2.241,2.317l-0.212,0.083l-0.725,0.338l-0.38,0.225l-3.13,4.396l-0.122,0.34l-0.01,0.095
		l0.745,1.796l0.307,0.507l0.914,0.022l1.126-0.138l0.83,0.763l1.984,1.859l0.237,0.495l0.042,0.268l-0.333,0.527l-1.846,1.733
		l-1.629,0.867l-0.612,0.497l-0.444,0.698l0.138,0.199l0.486,0.487l1.57,1.1l-2.665,1.195l-4.106,1.458l-12.482,1.714h-0.213
		l-0.295-0.168l-0.565-0.53l-0.423-0.486l-0.285-0.211l-0.388-0.189l-1.317,0.284l-0.158,0.094l-0.157,0.255l-0.16,0.771
		l-0.02,0.212l-0.439,0.413l-1.887,0.592l-0.588-0.295l-1.675-1.873l-0.661-1.185l-0.549-2.188l-1.756,0.212l-1.982-1.174
		l-0.105-0.179l-0.086-0.56l-0.035-2.106l0.142-1.575l0.444-0.655l0.444,0.011l0.074-0.105l0.269-0.676v-0.148l-0.565-0.825
		l-0.327-0.476l-0.265-0.221l-3.475-0.668l-1.575,0.21l-0.169,0.065l-1.084,1.152l-0.222,0.751l-2.199,1.776l-1.517,0.698
		l-0.096-0.158l-0.455-0.137l-0.428,0.137l-0.222,0.252l-0.147,0.624v0.201l0.115,2.135l0.692,0.477l0.444,0.159l0.297,0.835
		l1.199,7.56v0.064l-2.005,6.873l-3.112,0.516l-0.937,1.883l-0.096,0.138l-0.819,0.029l-2.21-2.135l-0.696-1.015l-0.032-0.105
		l-0.097-0.486l0.107-0.581l0.422-0.476l0.328-0.286l0.555-0.412l0.423-0.138l0.043-0.613l-1.358-2.368l-1.623-1.364l-0.894-0.01
		l-0.745,0.167l-0.127,0.108l-0.382,1.152l-1.167,0.496l-1.36,0.465l-0.401-0.053l-3.585-0.635l-1.348-1.469l-1.136-2.03
		l-0.549-0.561l-0.19-0.042h-0.16l-6.64,0.042l-7.507,3.066l-0.212,0.94l0.18,3.056l0.086,0.371l0.368,0.308l5.503,3.888
		l0.171,0.055l0.623,0.148l1.283,0.421l0.588,0.309l0.413,0.527l0.053,1.375l-0.223,0.615l-0.137,0.167l-0.684,0.465l-0.696,0.181
		l-0.285-0.042l-2.828-0.647l-2.423-0.559l-0.581-0.116l-0.291,0.224l-0.073,0.211l0.042,1.7l0.143,0.065l0.138,1.755l-0.449,0.76
		l-0.189,0.086l-0.276,0.044l-5.228-1.917l-1.835-0.452l-0.191-0.445l-0.417-1.163l-0.667-2.877l0.34-3.571l0.263-2.813
		l-3.044-0.698l-3.997-1.5l-2.284-2.496l-0.307-1.184l-0.254-1.015l-0.883,0.443l-0.925,0.423l-1.856-0.2l-0.169-0.021l-0.534-0.752
		l-0.021-0.582l-0.391-2.41l-2.431-5.159l-0.504-0.529l-0.548-0.444l-4.675-1.954l-1.455-1.968l-1.84-2.409l-0.539-0.055
		l-6.64,0.709l-1.813,2.009l-0.988,0.983l-0.37,0.179l-0.28,0.087l-0.202-9.706l0.17-0.37l0.396-0.636l0.212-0.232l1.803-1.511
		l-0.74-2.061l-0.777-1.355l-0.296-0.242l-1.273-0.127l-2.158-0.127l-0.481,0.127l-2.431,0.951l-0.243,0.316l0.042,0.614
		l1.232,1.532l-0.54,1.238l-0.925,0.189l-1.085-0.181l-3.272-0.581l-0.063-0.931l0.073-2.652l0.302-0.443l0.678-0.973l1.389-1.715
		l1.254-2.674l0.471-1.238v-0.327l-0.169-1.471l-0.926-1.129l1.349-0.425l0.264-0.095l0.127-0.146l-0.011-0.465l-0.094-0.974
		l-0.382-0.711l-0.01-0.559l0.358-1.797l0.138-0.349l1.096-0.889l0.459-0.327l1.622,0.211l1.38-0.041l0.715-1.154l1.623-4.102
		l-0.021-0.794l-0.968-5.908l-1.465-4.813l0.609-1.268l0.147-0.297l0.148-0.095l0.264-0.138l0.862-0.106l1.433,0.148l2.295,0.317
		l1.284,0.699l0.296,0.358l0.29,0.223l0.465,0.136l0.413-0.053l1.714-0.233l0.241-0.179l0.181,0.043l0.514-0.2l0.274-0.159
		l0.55-0.654l-0.148-0.181l0.588-1.163l0.17-0.497l-0.525-0.941l-0.507-0.528l-0.201-0.212l-0.895-0.604l-1.796-0.653l-0.212-4.314
		l-0.612-1.228l-1.349-2.863l-0.106-0.266l0.456-0.57l2.61-0.635l1.053,1.078l0.359,1.322l0.28,0.443l-0.011,0.044l0.371,0.696
		h0.569l0.186-0.221l0.042-0.784l-0.042-1.786l-0.206,0.031l-0.359-0.528l-0.032-0.264l0.423-1.333l0.334-0.306l0.369-0.181
		l0.614-0.443l0.322-0.794l-0.28-0.612l-1.243-0.296l-1.316-1.29l-0.582-0.476l-0.818-0.497l-1.654-0.562l-4.558-2.314l-0.915-2.897
		l-1.888-0.804L265.601,388l-1.212-1.533l-1.438-3.447l-0.581-2.166l-0.147-1.005l0.199-0.909l0.201,0.138l0.212-0.063l0.492-0.191
		l3.076-2.072l6.662-3.869l5.318-1.078l8.02-5.436l2.592-2.071l0.143-0.117l1.507-2.251l0.147-0.328l0.233-0.814l-0.043-0.096
		l-0.434-0.2l0.909-1.936l0.692-1.162l2.369-0.623l0.191-0.021l0.84,0.274l1.539,0.655l0.417,0.19l0.739,0.465l0.387,0.255
		l1.21,1.797l0.582,0.306l2.433-0.306l4.567-1.163l0.926-1.766l2.082-5.53l0.275,0.043l1.156-0.159L312.004,351.725z"
        />
        <path
          id="Schleswig-Holstein"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M321.984,114.018l0.903,3.002l-0.945,2.136
		l-2.623-0.19l-5.222,3.668l-1.419,2.274l0.021,1.808l0.989,2.474l0.202,0.381l0.331,0.899l0.064,0.507l-0.18,1.501l-0.418,3.119
		l3.807,3.7l1.527,0.931l0.223,0.096l1.252-0.064l0.403-0.116l0.031-0.18l0.216-0.127l0.171-0.042l1.126,0.232l0.898,0.434
		l1.32,1.448l0.711,3.785l-1.402,1.946l-0.401,2.431l-6.557,8.691l-2.146,2.527l-1,1.099l-0.306,0.317l-3.834,2.908l-1.379-0.296
		l-0.649-0.275l-0.509-0.031l-0.999,0.581l-0.608,1.279l-1.78,8.099l0.053,0.042l-0.085,0.053l-0.435,0.255l-0.322,0.148
		l-0.485-0.044l-4.136-1.215l-0.58-0.232l-0.916-0.539l-0.82-0.582l-2.42-1.247l-5.896-2.697l-1.603-0.877l-0.539-1.036
		l-3.731-4.008l-0.244-0.792l0.137-0.624l-0.476,0.063l-0.38-0.085l-3.494-2.441l-0.213-0.202l-0.85-2.421l-0.064-2.083l0.297-1.184
		l0.65-0.635l2.057-1.66l1.252-2.664l0.042-0.105l0.228-0.666l-1.596-2.167l-0.751-0.889l-0.143-4.673l0.607-1.416l0.127-0.857
		l-1.284-0.73l-0.201-0.083l-0.285-0.043l-4.283,2.072l-1.432,0.941l0.497,0.623l-0.804,1.945l-4.881,3.774h-0.75l-0.503-0.053
		l-0.961-0.211l-0.249-0.201l-1.242-0.031l-1.127,0.063l-0.073,0.761l0.011,0.327l-0.327,0.562l-0.371,0.56l-3.527,4.42
		l-0.755,0.369l-0.529-0.232l-1.052-0.972l-1.701-2.072l-0.127-0.667l0.094-0.518l-0.391,0.021l-0.232,0.316l-0.725,2.094
		l-0.254,0.899l-0.423,1.553l-0.449,2.274l-1.677-0.18l-1.649-0.551l-5.359-3.499l-2.265-1.818l-0.434-0.612l-0.406-0.879
		l-0.35-1.248l-0.084-1.607l-0.105-0.465l-0.418-1.024l-0.782-1.882l-0.344-0.593l-0.982-1.1l-2.338-1.734l-0.861-0.792
		l-0.587-0.824l-0.74-1.28l-0.767-1.85l-1.823-3.923l-0.524-0.963l-2.357-3.403l-1.803-1.301l-3.548-1.533l-1.893-0.254
		l-2.072,0.032l-3.633-0.053l-3.192-0.212l-1.01,0.031l-0.841,0.148l-0.265-2.611l-0.169,0.042l-1.48,0.741l-0.317,0.116
		l-0.465-0.011l-0.365-0.149l-1.047-0.855l-1.903-1.555l-0.935-1.322l-0.646-1.712l-0.323-1.1l-1.337-3.203l-2.083-3.31l-0.37,0.053
		l-0.317-0.053l-0.053-0.212l1.506-1.247l1.179-0.655l0.959-0.201l2.495,0.592l0.476,0.327l0.439,0.486l0.653,0.097l0.842-0.223
		l0.508-0.211l0.248-0.191l0.254-0.285l0.222-0.381l0.148-0.57l0.17-0.667l0.011-0.253l-0.033-0.307l-0.104-0.359l-3.225-6.661
		l-0.239-0.296l-0.687-0.211l-0.259-0.053l-0.952,0.189l-0.396,0.254l-1.253,0.824l-0.519-0.327l-1.084-0.697l-0.148-0.18
		l-0.533-1.016l-1.025-2.791l-0.101-0.476l0.021-0.17l0.238-0.603l1.062-2.241l0.645-1.364l0.096-0.423l0.307-3.489l0.052-0.719
		v-0.2l-0.231-0.053l0.18-0.148v-0.296l-0.063-0.582l-0.127-0.423l-0.274-0.518l-0.371-0.476l-0.169-0.127l-0.301-0.085l-3.801-0.01
		l-0.757,0.053l-2.432,0.18l-1.014,0.994l-0.63,0.75l0.053,0.063l0.486,0.117l0.143,0.011l0.085-0.064l0.031,0.022l-0.052,0.116
		l-0.116,0.127l-0.154,0.063l-0.36,0.043l-0.4-0.053l-4.441-2.337l-0.561-0.38l-0.544-1.037l-0.107-0.528l0.055-0.739l0.252-1.438
		l0.223-0.805l0.344-0.877l0.201-0.38l0.105-0.117l0.915-0.476l0.063,0.909l1.23,0.877l0.562-0.053l0.169-0.063l2.607-1.586
		l0.127-0.104l0.083-0.212v-0.909l-0.021-0.18l-0.063-0.55l0.538-2.411l6.63-1.152l1.295,0.138l1.38,0.655l1.095-0.296l1.254-0.57
		l1.093-0.571l5.473-4.621l0.137-0.147l0.646-1.068l0.17-1.1l-0.022-0.349l-0.21-0.781l-1.265-2.813l-6.413-9.113l-2.976-4.229
		l-1.179-0.709l-1.814-0.855l-1.179-1.163l-0.344-0.434l-2.205-5.73l-0.776-3.784l-0.872-1.819l-0.116-0.158l-2.253-2.665
		l-2.114-2.357l-0.032-0.201l0.387-3.616l1.485-3.362l1.829,0.033l0.688,0.084l0.412,0.189l1.2,0.825l5.44-0.285l0.703-0.116
		l0.761-0.085l0.533,0.032l4.076,0.274l2.708,0.232l0.565,0.169l0.254,0.106l3.103,1.438l0.883,0.635l-0.036,0.211l0.439,0.265
		l0.698,0.402l0.883,0.095l1.648,0.116l1.307-0.35l1.357,0.011l0.402,0.075l0.724,0.242l2.95,1.228l2.084,0.962l0.273,0.634
		l-0.369,0.477l-0.211,0.391l0.031,0.423l0.147,0.444l0.678,1.776l4.704,0.465l1.011-0.096l1.46-1.29l0.179-0.18l0.074-0.634
		l2.61-0.508l1.096,0.476l1.359-0.549l1.337-0.371l0.924-0.434l5.14-4.577l0.471-0.096l2.156,0.772l-0.407,0.655l-1.03,1.893
		l4.282,1.756l3.315,1.353l5.904,6.683l1.011,0.539l0.898,0.253l0.514,0.053h0.095l0.105-0.116l1.613-3.479l-0.086-0.211l0.18-0.497
		l0.176-0.307l0.105-0.084l1.057,0.655l1.734,1.174l1.072,1.966l3.379,10.595l-0.063,2.166l-0.169,3.035l-0.025,5.382l-0.044,0.443
		l-1.675,2.877l-0.19,0.263l-0.63,0.73l-1.041,0.877l-1.601,0.931l-2.581,1.322l-1.756,0.866l-0.412,0.096l-0.241-0.169
		l-0.183-0.243l-0.617-0.021l-0.327,0.074l-0.266,0.147l-0.116,0.138l0.709,1.406l0.274,0.465l0.365,0.402l0.486,0.296l0.275,0.073
		l1.998-0.306l0.682-0.148l0.307-0.18l1.894-0.835v-0.073l0.761-0.255l5.108-1.216l3.826-0.75l0.359-0.063l0.519-0.012l0.471,0.086
		l1.485,0.972l0.671,0.539l1.423,1.407l-0.46,0.792l-1.047,1.576l0.18,0.602l0.751,3.765l-1.242,1.047l-0.561,1.131l-0.127,0.613
		l1.348,1.777l0.338-1.228l2.316-5.064l0.459-0.507l4.563-2.252l1.03-0.201l0.556-0.021l0.624,0.106l1.188,0.327l1.703,0.486
		l12.436,6.207l0.725,0.411l1.485,0.952l0.37,0.327l0.48,0.729l0.233,0.518l-0.064,0.054l0.116,0.286l2.041,2.198l0.423,0.349
		l0.243,0.138l0.566,0.159l0.622-0.011l2.671-0.127l0.392-0.053l0.539-0.169l0.746-0.307l0.529-0.317l1.859-1.502l0.725-0.739
		l1.147-1.364l1.761-1.555l2.517-1.935l1.188-0.307l8.159-0.37l-0.254,3.574l-0.46,0.803l-0.064,0.317l0.147,2.315l0.609,6.715
		l0.744,5.666l-0.031,0.105l-0.084,0.074l-2.607,2.125l-0.654,0.465l-3.229,2.264l-2.973,2.737l-2.618,3.289l-0.442,0.37
		l-0.498,0.307l-0.501,0.189l-2.232-0.401l-0.316-0.085l-0.475-0.338l-3.157,3.361l-0.265,0.276l-0.169,0.349l-0.175,0.603
		l-0.063,0.476l0.011,0.285l0.105,0.508l0.693,1.671l0.587,1.099l0.381,0.603l0.423,0.539l0.232,0.223l0.524,0.328l0.453,0.074
		h1.894l0.232-0.032l0.882-0.222l0.318,0.042l0.517,0.243l0.408,0.338l0.316,0.412l0.201,0.519l0.011,0.191l-0.137,1.046
		l0.126,0.793l0.523,0.223l0.306,0.041L321.984,114.018z M168.301,10.266l-1.115,2.865l-0.913,2.591l-0.297,1.416l-0.249,2.03
		l-0.211,2.38l-0.635,9.155l-0.011,0.709l0.55,1.269l0.317,0.412l0.128-0.043l0.11-0.18l0.021-0.412l0.243-7.813l-0.137-0.232
		l-0.139-0.465v-0.169l0.159-1.502l0.434-1.385l0.254-0.328l2.126-1.384l0.606,0.528l0.117,0.158l1.443,0.813l1.4,1.016l0.466,0.158
		h0.216l2.74-1.396l1.749-1.459l-0.084-0.074l-0.35-0.085l-0.529-0.053l-2.156,0.085l-0.534-0.085l-0.275-0.095l-1.844-1.648
		L171.858,17l-1.638-1.681l-0.412-5.951l1.137-2.263l0.507-0.646l0.957-0.761l0.095-0.054l1.211-0.285l1.274-0.794l1.564-3.065
		l-0.021-0.084l-0.11-0.096l-2.12-0.878l-0.576-0.169l-0.338-0.021l-0.18,0.063l-0.514,0.328l-0.137,0.137l-0.423,0.688
		l-1.903,4.482l-0.37,0.94L168.301,10.266z M176.734,31.719l-0.513-0.011l-1.941,0.37l-0.565,0.21l-0.423,0.329l-0.545,0.56
		l-0.401,0.719l-0.528,1.27l-0.079,0.825l0.09,0.423l0.168,0.306l0.202,0.169l4.002,1.967l0.83,0.232l0.968,0.063l3.262,0.127
		l0.422-0.021l0.213-0.095l1.851-3.869l-0.598-2.39l-0.148-0.275l-0.914-0.635l-1.106-0.391l-0.454-0.104l-0.296-0.074l-0.714-0.054
		l-0.83,0.296l-0.317,0.128L176.734,31.719z M165.696,40.79l0.01,0.222l0.301,0.771l1.222,1.967l0.645,0.994l0.734,1.131l0.17,0.084
		l0.35,0.054l1.136-0.01l0.074-0.022l1.189-0.581l0.116-0.127l0.302-0.751l-0.042-0.105l-0.085-0.043l-1.195-0.603l-1.036-1.596
		l-1.264-3.531l-0.142-0.74l0.174-0.497l0.275-0.508l1.02-1.004l-0.085-0.106l-0.163-0.053l-0.402,0.181l-2.453,2.23l-0.316,0.412
		l-0.375,0.931l-0.043,0.835L165.696,40.79z M182.946,44.406l3.651-0.91l0.477-0.285l0.345-0.444l0.189-0.604l0.181-1.015
		l-0.17-0.084l-6.286,2.463l-1.648,0.677l-0.147,0.413l0.052,0.148l1.052,0.856L182.946,44.406z M178.674,49.554l-0.264,0.032
		l-0.201,0.17l0.053,0.179l0.254,0.392l0.169,0.127l1.607,1.194l0.285,0.19l0.852,0.011l0.519-0.148l0.116-0.211l-0.011-0.444
		l-0.751-1.152l-0.11-0.169l-0.107-0.074l-0.327-0.096l-0.661,0.19L178.674,49.554z M184.199,58.299l0.55,0.496l0.312,0.096
		l0.667-0.032l1.02-0.211l0.872-0.254l0.244-0.147l0.348-0.392l1.105-2.442l0.598-1.407l0.085-1.479l-0.232-0.138l-0.619-0.105
		l-0.56,0.074l-2.222,0.698l-0.316,0.095l-2.586,1.078l-0.369,0.19l-0.213,0.274l-0.052,0.265l0.03,1.143l0.128,0.486l0.148,0.315
		l0.232,0.318l0.137,0.105L184.199,58.299z M199.42,55.612l-3.146,2.041l-0.413,0.508v0.273l-0.011,2.581l0.678,0.528l0.587,0.296
		l0.776,0.18l2.174,0.211l0.913-0.009l1.275-1.059l0.555-0.688l0.084-0.115l1.496-2.295l0.031-0.158l-0.242-1.302l-0.316-0.357
		l-0.937-0.213l-2.242-0.085L199.42,55.612z M331.823,54.883l-0.47-0.158l-0.19-0.181l-0.096-0.158l-0.158,0.063l-0.328,0.255
		l-0.788,0.897l-0.607,0.846l-0.358,0.625l-0.698,1.554l-0.346,0.993l-0.094,0.328l-0.307,3.405l0.127,0.56l0.137,0.201l1.571,1.058
		l0.248,0.116l0.392,0.063l0.052-0.053l-0.052-0.043l-0.021,0.043h-0.063l-0.393-0.138l-0.955-0.604l0.01-0.623l0.191-0.18
		l2.314-0.815l1.052,0.34l0.393,0.169l0.411,0.169l0.133,0.264l0.286,0.836l-0.086,0.931l-0.147,1.278l5.064,0.941l7.803,0.074
		l0.069-0.021l0.032-0.095l-0.043-0.592l-1.258-2.706l-1.036-2.189l-3.146-4.811l-3.229-1.682L331.823,54.883z M188.042,104.776
		l-0.179-0.116l-0.349-0.359l-0.504-0.614l0.011-0.401l0.116-0.486l0.208-0.423l0.169-0.232l0.221-0.211l0.202-0.127l0.252-0.106
		l0.249-0.021l0.307,0.255l0.021,0.063l-0.148,2.549l-0.29,0.2L188.042,104.776z M140.778,88.758l1.179-0.116l0.074,0.614
		l-0.867,1.521l-0.735-0.063l-0.285-0.063l-0.883-1.616l0.042-0.244l0.533-0.37l0.435-0.148L140.778,88.758z"
        />
        <path
          id="Sachsen-Anhalt"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M365.987,207.776l1.395-0.571l0.255-0.042
		l0.487,0.042l0.21,0.053l0.228,0.127l0.212,0.18l0.073,0.243l-0.021,0.201l-0.106,0.17l-0.861,1.151l-0.054,0.307l0.054,0.381
		l0.137,0.285l0.244,0.255l3.57,2.844l0.216,0.137l0.528,0.096l0.308-0.085l0.743-0.518l0.99-0.582l0.169-0.032l0.539,0.085
		l0.396,0.139l0.381,0.178l0.223,0.117l5.174,3.003l0.244,0.306l0.095,0.265l0.031,0.275l-0.021,0.243l-0.094,0.275l-0.139,0.19
		l-0.534,0.454l-0.518,0.55l-0.19,0.402l-0.053,0.2l0.063,0.486l0.254,0.497l0.223,0.222l0.349,0.18l3.592,1.459l5.215,1.682
		l0.286,0.053l8.222-0.973l0.746-0.688l-0.133,0.169l-0.253,0.645l-0.033,0.678l0.842,1.987l1.644-0.549l0.915-0.477l1.549-0.698
		l1.48,0.666l0.624,0.413l0.095,0.273l1.233,4.896l-0.202,1.808l-1.465,0.729l-0.673,1.29l-0.348,1.142l-0.424,4.441l0.032,0.105
		l1.243,2.029l1.189,1.788l0.275,0.888l0.021,0.581l-0.412,3.68l-1.629,1.194l-0.423-0.021l-0.413-0.116l-0.904,1.627l-1.775,7.116
		l-0.043,0.75l0.043,0.043l1.829,2.167l0.861,1.026l1.178-0.19l1.116-0.327l0.318-0.467l0.041-0.147l0.18-0.94l5.547,1.924
		l0.369,3.267l-0.307,0.931l-0.576,1.025l-0.866,2.156l0.189,1.65l0.402,2.854l0.651,3.427l-1.19,2.399l-0.248,0.116l-0.106,0.169
		l-1.168,2.57l-0.57,1.787l-0.128,0.476l-0.27,2.474l0.228,4.028l-1.83,4.145l2.926,3.753l2.087,3.215l2.649,3.446l1.664,1.882
		l4.59,2.855l7.21,2.801l3.961,0.603l0.767-0.127l1.822-1.29l0.154-1.354l0.444-0.592l0.926-0.011l1.992,0.921l0.692,0.443
		l1.602,0.899l1.811,0.94l0.349,0.021l0.48,1.437l4.927,2.835l2.422,0.612l0.518,0.021l1.063-0.381l4.874,2.59l3.029,3.131
		l0.296,0.189l0.16,0.021l2.357,0.211l2.051-0.834l0.338-0.213l-0.063-0.665l0.904,0.042l0.412,0.265l0.725,0.719l0.138,0.613
		l0.021,0.623l-1.738,0.276l0.011,0.222l0.009,0.253l3.321,11.704l0.48,0.435l0.275,0.252l0.465,0.941l0.042,0.477v0.138l-1.4,2.516
		l-0.519,0.561l-6.45,4.282l-2.151,0.296l-6.557,0.317h-0.053l-9.525,0.189l-0.926-0.348l-0.104-0.064l-0.704-0.518l-1.006-0.359
		l-0.343-0.105l-0.529-0.096h-1.01l-0.359,0.222l-0.269,0.317l-0.128,0.232l-0.105,0.592l-0.687,1.851l-0.223,0.18l-0.81,0.635
		l-0.829,0.613l-1.571,0.761l-2.028-1.385l-0.329,1.121l-1.987,0.983l-7.528,2.156l-2.294,0.223l-2.538,0.053l-0.661,0.084
		l-5.265,2.771l-3.464,3.087l-0.2,2.39l-2.442,5.307l0.39,1.438l1.01,3.384l0.139,0.063l0.041,0.084l0.223,0.688l0.968,5.467
		l0.181,1.586l-1.328,0.507l-1.602,0.867l1.676,4.493l1.265,1.829l0.4,1.902l0.042,0.266l0.376,2.42l-0.021,7.232l2.035,0.434
		l0.313,0.159l0.539,0.581l-0.054,0.391l-0.666,0.562l2.3,2.019l1.385,0.973l-1.607,2.506l0.254,1.681l1.3,0.963l0.382,0.232
		l0.476,0.624l-0.042,1.025l-0.063,0.306l-3.183,6.872l-0.714,0.92l-0.244,0.139l-0.577,0.401l-0.042-0.032l-0.264-1.078
		l-0.444-0.834l-1.406-1.8l-0.104-0.104l-0.678,0.55l-0.273,0.306l-0.249,0.275l0.073,0.72l-1.085-0.498l-3.62-1.195l-0.244,0.021
		l-3.219,0.52l-0.349,0.084l-0.957,0.422l-0.043-0.888l-0.021-0.242l-0.18-0.53l-2.347-3.013l-0.254-0.232l-2.169-1.554
		l-3.451-2.199l-0.835-0.275l-2.03,0.538l-2.807,0.582l-1.804-0.485l-4.779-1.522l-0.137-0.561l-0.191-1.333l-0.312-1.215
		l-1.56-0.963l-6.297-0.994l-0.2,0.181l-0.345,0.751l-0.254,0.231l-0.84,0.709l-3.875,0.085l-0.054-0.265l-0.902-0.424l-0.486-0.369
		l-0.831-3.426l1.317-0.243l0.274-0.508l0.534-1.248v-0.273l-1.085-2.454l-1.569-1.606l-0.862-0.358l-0.244-0.022l-0.937,0.443
		l-1.59-0.041l-1.555-1.237l0.106-0.804l0.576-1.004l0.338-0.528l0.222-0.159l0.186-0.011l0.751,0.856l0.054,0.011l0.47-0.18
		l0.274-0.201l0.518-0.593l0.82-1.162l1.465-2.136l0.703-1.109l0.401-0.688l0.064-0.147l-0.043-0.274l-0.465-0.508l-1.306-1.174
		l-0.397-0.211l-0.308-0.106l-0.671-0.645l-0.496-0.635l0.021-0.148l0.063-0.147l0.522-0.508l-1.797-0.602l-0.021-0.402
		l-0.551-1.016l0.276-1.871l-0.043-0.338l-0.233-0.296l-4.186-1.607l-2.771-0.116l-0.704,0.148l-1.401,0.031l-6.438-0.138
		l-0.465-0.053l-6.445-2.04l-0.63,0.316l-1.157,0.412l-0.751,0.074l-0.839-0.105l-0.863-0.962l-0.776-0.465l0.132-2.707l0.148-2.39
		l-0.958-1.226l-1.68-2.591l-2.089-3.257l-1.211-2.887l0.476-1.606l1.666-2.242l-1.265,0.539l-1.962-1.817l-0.428-0.307
		l-2.917-0.688l-1.914-0.169l-3.389-0.275l-1.846,0.402l-0.37-0.117l-0.054-0.053l-0.301-0.412l-1.264-2.029l-0.138-0.392
		l-0.222-1.279l-0.084-0.856l0.211-0.528l-0.936-2.23l-3.013-4.452l-1.032-0.57l-0.702-1.471l-0.265-2.822l-0.01-3.457l0.147-0.508
		l0.358-0.465l2.147-1.776l0.386-0.158l0.381-0.021l0.179-0.127l1.021-1.597l0.105-0.349v-0.74l-0.075-0.655l-0.104-0.285
		l-0.523-0.646l-0.804-0.328l-0.894-0.327l-0.021-1.628l0.021-0.181l0.757-1.099l1.273-0.423l-3.732-2.803l-1.686-2.379l4.207-0.443
		l1.771-1.745l0.053-0.137l0.042-0.286l-0.158-0.443l0.105-0.952l1.924-0.581l1.02-0.201l5.035-0.063h0.254l0.946,0.223l1.823-0.054
		l1.386-0.296l0.359-0.147l0.936-0.667l0.518-0.094l3.432-0.107l1.708,0.17l0.11,0.096l0.306-3.088l0.382-1.924l2.029-1.449
		l1.877-1.533l1.19-1.775l0.164-0.275l0.052-1.459l-0.104-0.359l-0.059-0.073l-0.169-0.022l-0.879,0.117L332.51,297l-0.665-0.402
		l-0.376-0.296l-0.18-0.19l-0.137-0.307l-0.063-0.422l0.117-0.751l0.412-1.047l0.291-0.539l0.327-0.212l0.998-0.466l0.698-0.178
		l1.084-0.224l0.586-0.782l0.033-0.063l-0.021-0.085l-0.438-0.963l-1.433-1.998l-1.358-1.364l-1.434-1.574l-0.053-0.096
		l-0.417-4.102l1.227-1.714l0.56,0.138l0.18-0.053l1.127-0.518l0.808-0.646l0.054-0.179l0.041-1.406l-0.052-0.393l-0.618-0.603
		l-0.771-0.665l-0.228-0.106l-0.317-0.053l-1.295-0.804l-4.526-5.772l-0.856-1.84l0.625-1.554l2.239-1.206l1.74,0.074l-1.823-2.113
		l-1.316-1.682l-0.756-1.29l-0.339-0.751l-0.338-1.354l0.18-2.188l0.011-0.042l-2.798-5.424l-3.727-5.319l-2.611-1.924l-2.633-7.189
		l0.085-0.57l0.147-0.603l-0.222-2.273l0.021-0.201l0.509-2.188l2.061-0.581l2.676-0.55l6.063-0.158l2.85-3.099l0.202-0.423
		l0.019-0.158l-0.094-0.454l0.043-0.752l0.169-0.655l0.327-0.38l0.258-0.105l0.34-0.032l9.263,0.401l3.896,0.762l0.746,0.771
		l0.316,0.328l0.127,0.317v0.274l0.043,0.127l0.105,0.074l0.317,0.063l3.155,0.329l1.127-0.318l5.694-2.78l0.147-0.158l0.894-1.163
		l1.761-1.702l1.116-0.635l1.23-0.285l0.862,0.011l-0.798-1.872l0.576-3.204l0.222-1.014l0.339-0.709l0.831-0.158l0.083,0.042
		l0.382,0.529l0.649,0.359l0.74,0.073l0.032-0.053l2.479-3.637l0.011-0.085L365.987,207.776z"
        />
        <path
          id="Sachsen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M461.779,347.263l2.41,3.881l0.576,0.316l0.735-0.476
		l0.328-0.316l0.189-0.307l2.645,1.416l0.448,0.813l1.866,4.093l1.127,2.749l0.222,0.676l0.158,0.677l-0.221,5.213l-0.773,3.689
		l1.528,1.83l0.223,0.634l0.212,0.401l2.282,0.053l0.513-0.127l1.709-0.476l-0.105-1.068l-0.011-0.147l2.621-2.325l4.538-2.433
		l0.592,0.454l2.305,2.053l2.146,1.743l4.916,3.015l2.902,0.866l0.349,0.042l1.988-0.053l3.315-0.095l0.465-0.677l0.339,0.434
		l0.829,0.454l0.509,0.105l3.102,0.381l0.841-0.571l2.057-1.015l1.577-0.592l2.792,0.085l0.326-0.054l7.524-1.543l2.664-3.5
		l1.422-5.518l1.512-4.442l2.121-3.076l0.396-0.063l1.295,0.211l3.067-0.095l4.018,0.518l2.929,1.015l4.346-2.453l3.442-0.443
		l0.328,0.18l-0.086-0.539l-0.011-0.381l0.127-0.36l0.36-0.253l6.947-2.464l2.209,0.857l0.149,0.665l0.438,0.751l0.148,0.169
		l0.424,0.095l0.533,0.074l3.193-0.317l0.169-0.063l0.47-0.549l0.158-0.232l0.107-0.265l0.649-2.348l0.825-0.761l0.978,0.793
		l-0.999,1.955l-0.031,0.158l1.273,2.993l0.438,0.528l1.074,0.391l0.856,0.244l0.882,0.063l0.937-0.073l0.243,0.021l8.199,3.055
		l0.223,0.116l1.65,0.879l0.104,0.095l0.434,0.697l1.265,2.136l0.021,0.412l-0.053,4.028l4.874,10.446l0.661,2.02l-0.032,2.897
		l-1.157,2.695l-0.227,1.195l-1.953,14.348l-0.221,1.638l-0.861,1.216l-0.694,2.484l0.26,0.243l0.021,0.085l-0.217,1.068
		l-0.739,3.224l-1.2,3.659l-0.296,0.507l-1.534,1.956l-1.38,1.744l-1.401,2.739l-0.317,2.41l-0.449,2.421l-0.147,0.71l-0.35,0.211
		l-1.432,0.126l-0.471,0.013l-2.125-0.35l-5.468-2.073l-2.052-0.824l-0.094-0.771l0.84-2.434l-0.84-3.754l-2.115-6.163l-1.327-1.279
		l-5.309-4.483l-0.522,0.35l0.158,0.676l-0.084,1.438l-4.758,0.688l-0.497,0.053l-0.169-0.01l-4.59-1.723l-0.18-0.097l-0.195-0.169
		l-0.963-1.141l-0.206-0.17l-0.148-0.031l-0.708,0.052l-0.364,0.455l-0.275,0.624l-1.855,5.836l0.085,0.19l3.632,3.193l0.232,0.179
		l0.444,0.19l3.506,0.931l0.681-0.189l0.371,0.168l0.307,0.212l0.269,0.413l0.139,0.919l-0.703,2.506l-5.277,1.903l-11.166,5.679
		l-1.95,1.067l-1.117,1.354l-0.585,0.221l-8.412,2.687l-0.809,0.072l-1.846,0.139l0.254,0.465l0.223,3.174l-0.064,0.422
		l-0.327,0.792l-2.473,1.66l-0.609,0.2l-1.179,0.181l-0.138-0.021l-0.423-0.127l-0.554-0.39l-0.308-0.116l-1.887-0.413l-0.154-0.021
		l-6.963,0.921l-0.977,0.136l-7.244,4.334l0.19,0.509l0.231,0.921l-0.04,0.138l-1.831,4.153l-0.92,0.57l-1.902,2.443l-3.875-1.902
		l-2.137-0.201l-5.873,4.505l-3.366,3.71l0.094,0.687l-1.856,4.515l-1.501,0.329l-3.077-0.973l-5.514,0.486l-0.603,0.232
		l-0.153,0.073l-0.043,0.168l-0.424,2.136l-0.147,2.052l0.126,0.72l0.158,0.381l-0.019,0.497l-0.011,0.041l-0.086,0.37l-0.222,0.37
		l-2.01,2.507l-0.4,0.275l-2.104,1.29l-0.746-0.265l-2.917-1.894l-4.178-2.676l-0.655-0.35l-0.063,0.033l-5.271,3.171l-3.706,2.084
		l-1.106,0.146l-5.043,0.646l-4.462,1.248l-7.4,9.271l-2.105,3.098l-1.929,3.786l-0.206,0.76l-0.275,2.76l0.158,0.457l0.128,0.138
		l0.227,0.115l0.211,0.201l0.35,0.717l-0.169,1.883l-0.275,0.201h-0.776l-0.766-0.095l-0.985-0.211l-0.195-0.108l-0.117-0.138
		l-0.802-1.287l-0.27-0.561l0.041-0.253l0.533-0.233l0.18-0.129l0.201-0.801l-0.232-1.63l-5.81-8.352l-1.136-1.301l-0.477-0.021
		l-1.966,0.021l-2.719,0.096l-0.386,0.315l-0.466-0.643l-1.987-0.752l-4.468-1.321l-1.156-0.192l-0.407-0.295l0.311-1.755
		l0.223-1.714l0.043-0.422l-2.56-2.644l-0.683-0.633l-0.925-0.19l-1.57-1.102l-0.486-0.484l-0.132-0.201l0.438-0.696l0.612-0.5
		l1.629-0.866l1.846-1.734l0.333-0.528l-0.042-0.265l-0.237-0.495l-1.984-1.862l-0.83-0.76l-1.126,0.138l-0.914-0.021l-0.307-0.509
		l-0.745-1.797l0.01-0.095l0.128-0.339l3.124-4.398l0.38-0.222l0.725-0.34l0.212-0.084l2.241-2.314l0.055-0.813l1.347-1.238
		l0.137-0.084l0.376-0.105l0.751,0.792l0.438,3.076L399.03,460l3.965-0.2l2.474,0.308l1.156-0.762l0.351,0.138l0.842-0.095
		l0.211-0.107l0.105-0.084l0.148-0.541l-0.233-0.399l-0.095-0.044l-0.682-0.021l-0.35,0.318l-1.967-0.581l0.291-0.846l0.211-0.148
		l0.518-0.223l3.231-1.416l3.643-1.565l0.148,0.021l1.935-0.443l2.046-2.136l0.089-0.105l1.085-1.438l0.222-1.29l-2.955-0.792
		l-0.788-0.783l-2.495-4.431l-0.021-0.284l-0.032-0.509l0.085-1.046l0.755-5.445l0.477-0.962l0.566,0.74l1.325,0.146l4.379-1.574
		l-0.021-1.375l0.011-0.074l1.634-1.269l0.296-0.094h1.575l0.593,0.222l0.556,0.729l0.041,0.063l1.265-0.371l0.2-0.084l0.925-2.622
		l0.031-1.247l1.454-1.249l0.265-0.138l3.21-0.814l1.893-0.295l0.561,0.136l1.421-0.251l2.797-0.91l0.813-1.236l-0.538-0.37
		l-0.265-0.255l-0.661-1.099l-0.147-0.253l-0.586-2.274l-0.34-1.648l-2.421-0.613l-2.379-0.899l-2.475-1.861l-0.307-1.766
		l0.073-1.247l0.127-0.656l-0.242-0.887l-0.186-0.276l-0.38-0.242l-2.897-1.068l-7.693-2.399l-0.095,0.011l-2.991,1.469
		l-1.386-0.972l-2.3-2.021l0.667-0.56l0.053-0.392l-0.54-0.582l-0.312-0.158l-2.035-0.434l0.021-7.231l-0.375-2.422l-0.044-0.264
		l-0.401-1.902l-1.264-1.829l-1.676-4.494l1.602-0.867l1.328-0.507l-0.181-1.586l-0.968-5.467l-0.221-0.687l-0.043-0.085
		l-0.139-0.063l-1.01-3.384l-0.392-1.437l2.444-5.309l0.201-2.389l3.462-3.088l5.266-2.77l0.661-0.085l2.538-0.053l2.294-0.222
		l7.527-2.157l1.989-0.983l0.328-1.12l2.029,1.384l1.571-0.761l0.829-0.612l0.808-0.635l0.224-0.181l0.688-1.851l0.104-0.592
		l0.127-0.232l0.271-0.316l0.359-0.222h1.009l0.529,0.095l0.345,0.105l1.005,0.359l0.703,0.518l0.105,0.063l0.925,0.35l9.525-0.19
		h0.054l6.556-0.317L461.779,347.263z"
        />
        <path
          id="Saarland"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M25.858,560.262l0.481,0.286l7.042,2.198l1.326,0.16
		l4.353,0.297l7.084-1.25l4.271-1.235l0.608-0.341l1.348-0.771l0.18-0.127l0.529-0.54l0.211-0.349l0.566-0.611l0.274-0.189
		l0.766-0.362l3.024-1.214l1.903-0.741h0.434l-0.011-0.085l0.054-0.063l0.127-0.104l3.049-1.788l0.371-0.138l1.987,0.254
		l0.947,0.181l-0.074,0.306l-0.17,0.698l0.011,0.189l0.148-0.041l1.644-0.635l0.534-0.454l0.551-0.622l2.632,1.711l3.295,2.991
		l6.104,3.29l0.757,0.146l0.81-0.274l1.109-0.581l0.429-0.424l0.179,0.308l0.36,0.729l0.46,1.015l0.021,0.199l0.063,0.836
		l0.125,4.409l-0.479,5.223l-1.665,0.509l-0.382,0.276l0.021,0.146l0.181,0.995l0.147,0.391l2.517,1.68l0.105,0.824l-0.273,1.492
		l-0.022,0.371l5.022,2.873l0.724-0.188l1.243-0.563l0.443,0.384l-0.602,0.431l-0.714,4.347l0.021,0.095l0.577,0.393l-0.534,2.283
		l-0.583,0.845l-0.374,0.435l-0.603,0.262l-1.381,0.689l-2.859,1.934l-1,1.321l-0.01,0.16l-0.032,0.592l1.021,3.056l0.894,1.194
		l1.797,2.591l0.159,0.221l0.265,0.214l0.979-0.013l-0.534,0.856l-4.362,3.501l-0.354,0.222l-0.729-0.232l-0.979-0.741l-1.452-0.603
		l-4.123-0.39l-0.445,0.104l-0.661,0.267l-0.592,0.685l-0.232-0.115l-0.842-0.487l-0.48-0.272l-1.284-1.239l-0.963-1.657
		l-0.83,2.547l-1.639,1.616l-0.667,0.225l-0.327-0.116l-0.29-0.286l-0.201-0.497l-0.667-3.756l-0.237-1.426l0.074-0.528
		l-1.243-1.925l-2.205-1.681l-2.335-0.845l-1.396-0.393l-4.014-0.454l-1.433,0.9l-0.138,0.22l0.443,0.381l0.577,0.816l0.561,1.889
		l-0.042,0.31l-0.169,0.421l-0.937,1.479l-1.696,0.298l-5.525-1.003l-1.158-1.258l-0.56-1.354l1.442-1.755l-0.491-1.354
		l-2.131-1.225l0.074-1.831l-0.042-0.188l-1.301-3.204l-1.93-1.662l-1.617-0.897l-0.297-0.308l-0.946-1.185l-0.338-0.475
		l-1.369-2.527l-0.074-0.168l-1.538-5.974l-0.063-0.433l0.104-0.266l0.698-0.549l0.598-0.148l-0.407-0.624l-0.528-0.571
		l-0.487-0.371l-4.292-3.309l-2.327-0.867l-1.676,0.349l-0.343,0.16l-0.666,0.159l-1.253-0.076l-0.492-0.57l-0.401-6.248
		l0.053-0.189l0.264-0.435L25.858,560.262z"
        />
        <path
          id="Rheinland-Pfalz"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M145.208,448.328l-0.883,1.006l-0.147,0.411
		l-0.063,0.55l-0.011,0.93l0.925,4.865l0.126,0.253l0.36,0.213l1.17,0.665l0.01,0.593l-0.381,1.184l-0.735,1.796l-0.274,0.666
		l-0.777,1.853l-0.19,0.431l-0.761,0.108l-1.074-0.212l-1.021-0.349l-0.501-1.046l-1.212-0.487l-0.306-0.032l-0.603,0.17
		l-0.376,0.136l-2.39,2.844l-0.529,1.068l-0.47,2.433l0.005,0.327l0.54,1.29l0.735,0.972l-1.756,4.948l-0.286,0.774l0.508,0.009
		l5.281,3.521l2.655,4.854l0.2,0.399l0.074,0.17l0.735,2.242l0.073,0.399l0.021,1.925l-1.998,1.154l-2.095-0.856l-0.667-0.086
		l-0.893,0.275l-0.988,1.3l0.227,0.477l1.677,1.63l0.37,0.263l0.095,0.168l-1.677,1.861l-0.296,0.011l-0.998-0.242l-1.84-1.079
		l-4.34,1.882l-0.345,0.181l-0.582,0.422l-1.527,2.009l-0.068,0.128l0.058,1.321l0.37,0.517l0.519,0.318l0.788,0.604l0.999,0.898
		l0.127,0.358l-0.382,2.992l-0.301,0.401l-2.314-0.644l-0.382-0.148l-4.16,3.468l-1.988,1.122l-0.946,0.198l0.683,1.935l5.413,7.573
		l0.471,0.327l0.274,0.084l0.35,0.041l3.336-0.104l1.988-0.318l0.295-0.085l7.021-2.685l4.142-1.882l2.526-0.826l0.915-0.063
		l1.052,0.148l1.851,0.392l0.581,0.242l1.157,1.11l0.693,0.749l0.443,0.646l0.777,1.163l1.275,1.161l0.718,0.849l0.375,0.56
		l0.466,0.813l0.481,1.174l0.031,0.95l0.593,1.903l0.126,0.551l-0.02,0.497l-0.064,0.338L157.965,529l-0.348,0.567l-0.133,0.352
		l-0.116,0.527v0.392l0.19,0.792l2.231,5.638l0.301,0.495l1.005,1.438l0.522,0.984l2.792,2.916l0.344,2.339l-0.618,1.014
		l-0.148,0.295l-0.083,0.191l-0.408,0.603l-0.476,0.382l-0.412,0.233l-0.387,0.159l-1.506,0.326l-0.699,0.264l-0.481,0.244
		l-0.549,0.337l-0.63,0.486l-0.116,0.127l-0.2,0.37l-0.075,0.243l-0.169,0.974l0.106,0.454l1.603,5.148l0.502,0.921l1.592,2.39
		l0.506,1.185l-0.084,1.141l-0.402,0.698l-0.127,0.497l-0.021,0.422l0.042,0.37l0.105,0.308l0.424,0.845l1.094,4.42l0.085,0.624
		l0.429,3.193l-0.238,0.181l-0.222,0.232l-0.116,0.306l0.011,0.357l0.126,0.245l0.159,0.179l0.259,0.223l0.231,0.106l0.583,0.104
		l0.66-0.029l1-0.032l0.56,0.104l0.36,0.159l0.343,0.401l0.097,0.318l-0.011,0.317l-0.778,2.095l0.17,2.292l0.053,0.857l-0.042,0.57
		l-0.244,0.93l-0.381,0.847l-0.587,0.868l-0.496,0.516l-0.444,0.341l-0.449,0.306l-0.519,0.486l-0.223,0.316l-0.131,0.327
		l-0.032,0.201l0.032,0.435l0.142,0.378l0.19,0.225l0.212,0.138l1.031,0.349l0.243,0.138l0.35,0.316l0.231,0.36l0.122,0.538
		l-0.047,0.422l-0.107,0.225l-1.358,0.696l-1.761,1.969l-0.491,0.443l-1.442,0.93l-0.551,0.338l-0.512,0.443l-0.36,0.433
		l-0.285,0.541l-0.18,0.613l-0.053,0.707l0.021,0.042h0.096l0.17-0.042l0.158,1.8l-2.412,10.287l-0.18,0.517l-0.448,0.729
		l-0.688,0.846l-0.011,0.011l-0.977,1.058l-0.714,1.058l-0.36,0.656l-0.454,1.185l-0.195,0.897l-0.201,1.406l-0.074,0.297
		l-0.096,0.316l-0.423,0.94l-0.285,0.53l-0.313,0.452l-0.401,0.413l-1.073,0.847l-1.591,1.237l-0.407,0.242l-0.285-0.59
		l-0.169-0.287l-0.148-0.097l-3.485-0.422l-0.317,0.138l-0.988,0.042l-0.85-0.17l-3.015-1.216l-1.628-0.749l-0.116-0.286
		l-0.661-0.804l-0.719-0.719l-4.99-2.412l-3.252-0.93l-1.349-0.116l-0.978-0.465l-4.906-0.74l-3.421,0.783l-0.296,0.073
		l-1.945,0.095l-3.876-0.222l-2.891-1.101l-4.469-2.747l-0.914-0.603l-1.4-1.194L98.741,606l0.012-0.792l0.19-1.238l-0.19-1.416
		l-3.198-1.617l-2.369,0.414l-1.998,0.356l-1.089,0.286l-0.036,0.506l0.058,0.286l0.189,0.287l-0.978,0.01l-0.264-0.212
		l-0.158-0.221l-1.798-2.592l-0.894-1.205l-1.021-3.045l0.032-0.592l0.01-0.157l1-1.322l2.859-1.935l1.379-0.688l0.604-0.266
		l0.375-0.433l0.581-0.845l0.534-2.285l-0.545-0.486l-0.021-0.095l0.714-4.345l0.604-0.435l-0.445-0.38l-1.242,0.561l-0.724,0.188
		l-5.022-2.876l0.021-0.37l0.275-1.479l-0.105-0.832l-2.518-1.685l-0.148-0.391l-0.18-0.993l-0.02-0.148l0.38-0.274l1.665-0.508
		l0.481-5.224l-0.128-4.407l-0.063-0.835l-0.021-0.202l-0.461-1.017l-0.359-0.728l-0.18-0.306l-0.428,0.422l-1.109,0.581
		l-0.81,0.276l-0.757-0.148l-6.105-3.289l-3.294-2.992l-2.634-1.713l-0.549,0.624l-0.535,0.456l-1.644,0.633l-0.147,0.043
		l-0.011-0.191l0.169-0.698l0.074-0.308l-0.946-0.179l-1.988-0.252l-0.369,0.136l-3.052,1.787l-0.127,0.105l-0.053,0.065
		l0.011,0.083h-0.434l-1.902,0.739l-3.023,1.218l-0.767,0.358l-0.275,0.191l-0.565,0.613l-0.213,0.35l-0.528,0.537l-0.18,0.127
		l-1.342,0.773l-0.615,0.336l-4.271,1.238l-7.084,1.247l-4.351-0.295l-1.327-0.157l-7.042-2.201l-0.48-0.284l-1.401-2.179v-0.116
		l0.084-0.168l1.349-1.957l0.428-0.454l0.223-0.116l0.508-0.021l0.274-0.127l0.248-0.255l1.212-1.279l5.819-9.419l1.497-5.128
		l-0.021-0.646l-0.577-4.217l-1.982-1.312l-1.469,0.94l-1.466,0.021l-0.137-0.032l-1.803-1.046l-0.238-0.223l-3.099-3.076
		l-5.609-3.428l-1.781-2.071l-3.347-5.571l-0.429-0.961l-0.337-0.772l-1.18-2.918l-1.518-6.079l0.317-3.005l2.009-7.166l2.463-2.071
		l-0.316-4.231l0.18-1.332l0.011-0.096l0.158-0.221l7.455-4.8l0.359-0.91l1.189-2.525l0.438-0.159l0.957-0.244l2.194,1.122
		l2.828-0.731l1.443,0.106l-0.708-1.078l-0.46-0.813l-0.189-0.53l-0.715-2.663l-0.326-1.333l1.881-0.445l2.162,1.938l0.079,0.082
		l0.72,1.06l4.831-0.74l4.674-1.598l1.285,1.364l0.138,0.201l3.827,1.586l3.173,0.878l3.007-0.93l0.762,0.136l0.555,0.127
		l-0.903-1.544l-0.957-1.776l-0.116-5.972l3.24-2.084l0.37,0.274l2.401,1.606l0.681-0.21l2.402-1.099l1.87-7.751l0.232-1.354
		l2.168,0.338l0.708,0.053l1.714-0.834l0.189-0.139l1.084-1.122l0.878-1.097l2.595-0.436l5.266-1.192l1.646-0.456l1.01-1.015
		l0.073-0.084l0.365-1.449l0.105-0.476l0.276-0.582l0.263-0.127l0.492,0.021l0.063,0.273v0.456l-0.063,1.563l-0.032,0.403
		l2.962,0.021l1.601-0.35l0.82-0.242l2.775-0.857l1.349-0.72l0.189-0.337l0.519-2.104l0.021-1.576l-0.086-1.469l0.472-1.006
		l0.486-0.486l1.935-0.063l2.078,0.688l0.26-0.233l0.888-0.401l13.105-4.664l0.461-0.412l1.071-1.375l0.351-0.854l-0.171-1.588
		l-0.285-0.316l-0.966-0.813l0.644,0.148h0.322l1.561-0.582l0.116-0.043l2.39-2.146l1.253-1.797l-0.561-4.97l0.455-0.55l2.4-2.073
		l0.443-0.231l1.29,0.253l2.343,1.671l-0.498,0.75l-0.434,0.869l-0.543,1.552l-0.086,0.393l-0.011,0.287l0.075,0.473l2.305,0.604
		l1.115,0.286l4.928,2.792l0.254,0.22l0.259,0.297l0.549,1.396l-0.011,0.561l-0.231,2.113l-0.075,0.202l-0.368,0.382l-0.054,0.653
		l-0.068,0.921l0.205,0.951l0.285,0.465l0.614,0.762l0.27,0.298l1.465,1.425l1.453,1.547l0.401,0.433l0.133,1.564l-0.301,1.511
		l2.648-0.921l1.273,0.341l0.688,0.624L145.208,448.328z"
        />
        <path
          id="Nordrhein-Westfalen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M231.536,350.826l-1.294,2.178l-3.844,4.609
		l-3.24,5.318l-4.463,3.489l-4.313,3.479l-0.655,1.046l-0.598,1.005l-0.592,0.095l-2.76-0.179l-0.619-0.201l-0.274-0.18
		l-0.169-0.243l-0.566-1.628l0.714-1.776l-1.179-1.956l-2.924-1.236l-1.169,0.053l-2.22,0.549l-1.591,0.539l-2.855,1.217
		l-0.683,0.687v0.444l0.18,0.656l2.517,4.144l0.545,0.127l0.306,0.168l0.043,0.224l0.212,1.354l-0.825,2.452l-0.147,0.054
		l-3.441-0.223l-4.167,0.096l-3.769,1.004l-4.981,1.533l-2.955,1.882l-1.084,0.708l-1.094,1.354l-2.611,3.754l-0.572,0.855
		l2.067,2.888l0.217,0.211l0.308,0.105l0.755,0.043l0.106-0.033l0.963-0.655l4.567-2.051l0.702-0.063l0.915,0.021l0.856,1.164
		l0.116,0.253l0.745,6.418l0.159,2.041l-0.074,0.38l-0.274,0.656l-2.834,6.216l-3.188,1.227l-0.438,0.022l-1.12-0.266l-0.758-0.306
		l-1.701-0.275l-2.813-0.18l-0.148,0.115l-1.264,0.477l-0.126,0.074l-0.767,0.646l-0.528,0.455l-0.438,0.634v0.19l0.094,0.306
		l1.063,3.277l0.672,1.459l0.371,0.878l-0.477,0.729l-0.386,0.371l-0.994,0.866l-1.439,1.554l-0.855,0.931l-0.085,0.159l0.011,0.243
		l0.266,0.371l-0.159-0.065l-0.598,0.011l-0.096,0.055l-0.401,0.231l-0.053,0.125l-0.597,2.593l-4.288,6.47l-1.127,1.206
		l-0.116,0.127l-0.544,0.116l-0.18-0.052l-4.082-2.157l-0.19-0.064l-0.972-0.03l-0.197,0.03l-0.199,0.167l-9.485,9.263l0.418,1.006
		l0.391,0.825l0.771,1.511l0.8,1.121l0.18,0.95l-0.053,0.351l-2.005,3.723l-0.734,0.992l-1.01-1.246l-0.687-0.624l-1.27-0.341
		l-2.654,0.921l0.307-1.511l-0.138-1.564l-0.401-0.433l-1.454-1.547l-1.464-1.425l-0.27-0.298l-0.613-0.762l-0.285-0.465
		l-0.207-0.951l0.074-0.921l0.047-0.653l0.371-0.382l0.074-0.202l0.232-2.113l0.011-0.561l-0.551-1.396l-0.259-0.297l-0.253-0.22
		l-4.928-2.792l-1.115-0.286l-2.306-0.604l-0.074-0.473l0.012-0.287l0.083-0.393l0.545-1.552l0.435-0.869l0.495-0.75l-2.336-1.671
		l-1.295-0.253l-0.445,0.231l-2.399,2.073l-0.455,0.55l0.562,4.97l-1.254,1.797l-2.389,2.146l-0.117,0.043l-1.559,0.582h-0.323
		l-0.646-0.148l0.969,0.813l0.285,0.316l0.169,1.588l-0.349,0.854l-1.074,1.375l-0.46,0.412l-13.106,4.664l-0.888,0.401
		l-0.259,0.233l-2.078-0.688l-1.936,0.063l-0.484,0.486l-0.471,1.006l0.083,1.469l-0.02,1.576l-0.519,2.104l-0.19,0.337l-1.344,0.72
		l-2.78,0.857l-0.819,0.242l-1.602,0.35l-2.961-0.021l0.032-0.403l0.063-1.563v-0.456l-0.063-0.273l-0.492-0.021l-0.265,0.127
		l-0.274,0.582l-0.106,0.476l-0.364,1.449l-0.074,0.084l-1.01,1.015l-1.645,0.456l-5.265,1.192l-2.597,0.436l-0.877,1.097
		l-1.084,1.122l-0.191,0.139l-1.712,0.834l-0.703-0.053l-2.173-0.338l-0.227,1.354l-1.878,7.751l-2.399,1.099l-0.683,0.21
		l-2.399-1.606l-0.371-0.274l-3.241,2.084l0.117,5.972l0.957,1.776l0.903,1.544l-0.555-0.127l-0.761-0.136l-3.009,0.93l-3.172-0.878
		l-3.828-1.586l-0.138-0.201l-1.284-1.364l-4.673,1.598l-4.833,0.74l-0.714-1.06l-0.085-0.082l-2.162-1.938l-1.882,0.445
		l0.328,1.333l0.714,2.663l0.189,0.53l0.466,0.813l0.703,1.078l-1.443-0.106l0.307-1.204l-0.011-0.116l-0.465-0.889l-2.442-2.864
		l-0.111-2.019l0.587-1.017l0.328-0.793l0.544-2.506l0.084-0.708l-0.084-1.109l-0.19-0.456l-1.703-2.898l-0.484-0.738l-1.117-0.72
		l-0.2-0.272l-0.249-0.225l-2.548-0.443l-1.2,0.021l-0.127,0.043l-0.519,0.358l-0.956,0.413l-0.096,0.021l-0.105-0.044l-1.967-2.768
		l-0.317-0.552l-0.49-1.576l-0.064-1.512l0.085-0.254l3.293-3.108l0.798-3.615l-3.452-0.908l-0.893-1.144l0.158-0.063l1.116-0.434
		l-0.783-0.74l-0.471-0.667l-0.989-1.627l-0.253-0.518l-0.116-0.456l-1.348-3.415l-0.328-0.306l-0.186-0.116l-4.456-2.495
		l-2.67-4.463l1.618-5.181l0.231-0.749l0.999-0.393l1.03-0.306l0.403,0.043l0.084,0.273l1.032,0.52l1.063-2.58l-0.086-0.74
		l-0.307-0.361l-0.259-0.528l-0.073-0.505l0.438-1.481l0.084-0.203l0.371-0.356l0.127-0.266l0.052-0.371l-0.782-0.337l-1.342-0.561
		l-2.411-0.866l-0.128-1.377l0.064-1.404l0.587-1.237l0.285-0.868l-0.032-0.104l-0.519-0.084l-3.933-0.317l-2.537,0.613
		l-0.894-1.037l-1.412-3.118l-0.613-3.014l-0.021-0.519l0.064-0.073l3.034-1.344l6.249-2.02l7.877-5.285l0.99-2.865l2.377-1.005
		l0.403-0.18l-0.952-0.847l-1.073,0.424l-1.703,0.751l-0.496,0.338l-0.117,0.19l-0.2,0.2l-0.366,0.159l-0.327,0.117l-0.444,0.011
		l-0.205-0.022l-0.476-0.137l-0.54-1.068l-0.09-3.225l1.189-3.446l7.217-9.093l2.337-2.537l-0.989-3.574l0.636-3.182l0.512-1.587
		l0.328-2.252l0.011-0.654l-0.486-3.967l-0.661-1.373l-2.58-4.25l-0.692-0.497l-3.263-3.754l0.239-1.068l-0.159-4.503l-3.199-1.48
		l-0.359-0.455L14.29,341.3l-1.875-6.248l-1.486-7.021l1.527-2.496l4.41-1.966l2.188,0.096l4.885-0.656l1.95-1.28l4.939,0.213
		l0.248,0.053l0.708,0.338l0.671,0.434l2.982,1.924l1.945,1.692l1.273,0.729l0.435,0.063l-1.069-3.521l-0.047-0.317l0.28-0.93
		l0.169,0.021l2.116,0.908l4.852,0.053l0.915-0.485l1.476-0.782l2.906-1.132l5.758-1.967l0.62-0.147l0.496,0.307l0.317,0.508
		l1.438,1.438l0.032,0.021l0.677-0.243l1.231-0.813l1.231-0.855l1.265-1.322l2.659-3.426l0.264-0.624l0.021-0.222l-0.105-1.364
		l-0.195-0.771l-4.5-3.827l-2.39-1.279l1.475-3.499l1.539-2.454l5.693-1.828l0.556-0.075l0.782-0.739l0.386-0.401l0.413-1.629
		l0.158-0.813l1.84-1.979l7.677-4.8l2.538-1.5l2.104-0.074l0.693-0.994l0.222-0.265l0.603-0.56l2.137-0.476l2.59,0.284l1.295,0.085
		l4.995,0.265l0.889-1.037l0.408-0.581l0.803-0.084l1.021-0.106l1.041-0.55l0.266-0.497l0.343-0.676l0.614-0.529l3.717-2.748
		l4.207-2.591l1.698-0.974l0.265-0.03l0.321-0.021l2.315-1.152l1.804-3.183l0.126-0.677l-1.38-0.74l-0.676-0.466l0.465-1.511
		l0.38-0.805l0.598-1.035l0.784-0.973l0.235,0.104l4.606,1.81l0.153,0.158l0.2,0.264l0.529,1.121l1.555,4.45l5.075,3.088
		l2.209,0.339l1.327-0.592l0.265-0.169l1.57-0.382l2.786,1.702l0.956,1.682l0.698,2.537l-2.104,2.242l-0.096,0.381l-0.042,0.465
		l0.074,0.592l0.158,0.2l1.364,1.396l-1.052,0.709l-2.733,5.814l-0.011,0.116l0.163,0.772l0.307,0.993l0.286,0.486l1.231,0.878
		l0.063,0.031l2.326,0.455l1.967,1.829l0.19,4.145l-3.188,0.972l-3.695,1.228l-0.937,0.708l-0.043,0.138h0.276l1.405,2.294
		l0.244,1.005l1.031,0.538l1.802,0.763l1.147,0.433l0.629-0.931l0.393-0.528l2.271-1.734l0.17-0.011l1.527,0.116l1.042,0.148
		l1.094,0.286l0.302,0.623l2.269-1.406l1.597-0.37l4.023-2.906l1.702-1.629l1.38-1.026l0.603,0.085l2.923,0.623l2.443,0.889
		l3.014,0.317l-0.244-0.719l-0.116-0.571l0.054-0.337l0.338-0.487l0.428-0.401l2.21-1.471l0.17-0.063l0.645,0.043l0.365-0.063
		l0.529-0.254l0.231-0.19l1.01-1.058l0.476-0.592l0.641-0.846l-1.243-0.646l-1.21-0.391l-0.36-0.021l-0.278-0.116l-0.783-0.687
		l-0.545-1.015l-0.085-0.233l1.117-4.451l0.48-0.972l-0.238-3.331l0.147-1.756l-1.56-4.619l-2.178-1.682l-5.214-3.923l-0.65-3.203
		l-0.495-1.078l0.411-0.265l0.438,0.095l2.369,0.624l0.307,0.307l-0.031,0.191l0.497,0.507l3.356-0.255l0.978-0.137l1.54-1.311
		l0.104-0.339l0.196-1.311l0.138-1.407l3.108-2.389l3.082,1.512l5.905-3.065l2.125,1.659l0.729,0.698l0.223,0.57l0.047,0.307
		l-0.27,1.344l0.238,7.622l0.18,1.47l0.54,0.391l1.84,0.794l6.729-0.063l5.112-1.143l0.191-0.073l2.896-3.765l2.127-2.78
		l0.755-1.406l1.581-2.062l2.336,0.549l2.708,1.228l0.21,0.676l0.05,0.212l-1.799,4.093l0.915,2.335l-0.085,0.698l-0.19,0.486
		l-1.491,3.056l-0.804,1.534l-0.127,0.115l-0.745,0.688l-0.787,0.454l-1.2,0.169l-0.968,0.476l-1.025,0.678l-0.661,0.454
		l-2.264,4.779l0.509,1.554l0.163,0.127l0.286,0.116l1.559,0.434l2.01,0.804l2.548,1.692l-0.063,0.232l-1.989,4.916l-1.452-0.021
		l-2.295-1.206l1.913,2.539l3.452,2.653l3.464,0.667l0.93,0.326l0.228,0.106l0.528,0.581l0.339,0.974l-0.285,2.22l1.348,1.227
		l0.914,1.586l-0.138,0.116l-0.501,0.264l-0.465,0.666l-0.033,0.243l-0.031,1.798l0.274,6.206l0.629,1.533l5.652,4.092l0.878,0.486
		l1.009-0.159l0.064-0.031l0.338,0.053l1.063,0.571l0.063,0.085l0.471,0.824l0.339,1.301l-0.043,0.603l-0.147,1.015l-0.513,2.422
		l-0.233,0.147l1.01-0.635l0.339-0.2l0.618-0.212l0.213,0.011l0.443,0.116l1.48,0.551l0.561,0.327l1.776,1.775l-0.022,3.404
		l-1.241,3.046l-1.808,5.571l-0.863,7.232l0.46,0.338l0.275,0.074h0.211l1.607-0.042l0.138-0.021l0.455-0.243l0.063-0.021
		l0.587-0.189l0.231-0.011L231.536,350.826z"
        />
        <path
          id="Niedersachsen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M176.903,149.425l-0.914-2.178l-1.179-3.024
		l-0.012-2.02v-1.268l0.063-0.656l3.77-11.354l0.523-1.386l1.031-2.622l2.194-2.864l0.84-0.794l1.809-0.539l1.284-0.327l1.105-0.064
		l-0.031,0.54l0.063,0.307l0.147,0.307l1.465,1.776l2.823,2.071l0.968,0.677l0.64,0.296l0.739,0.202l1.556,0.2l5.074,0.412
		l1.634-0.327l0.291-0.105l0.064-0.075l0.011-0.148l0.285-0.104l2.083-0.444l0.528,0.032l2.146,0.517l-0.485-0.634l-0.766-1.967
		l-0.413-1.227l0.813-0.369l0.894-0.308l0.841-0.147l1.009-0.032l3.195,0.213l3.631,0.052l2.072-0.032l1.893,0.255l3.548,1.532
		l1.803,1.301l2.357,3.405l0.524,0.962l1.823,3.922l0.767,1.851l0.741,1.28l0.586,0.824l0.86,0.792l2.338,1.734l0.983,1.099
		l0.344,0.593l0.783,1.882l0.416,1.025l0.106,0.465l0.085,1.597l0.349,1.259l0.407,0.878l0.434,0.613l2.263,1.818l5.36,3.499
		l1.65,0.551l1.676,0.18l2.695,0.37v2.822l-0.306,2.22l0.83,0.857l0.956,2.23l0.074,0.592l3.411,3.511l0.253-0.116l0.83-0.625
		l1.285-1.427l0.253-0.158l0.328,0.222l0.745,0.92l2.114,2.009l1.582,1.321l0.423-0.031l2.817-2.072l0.18-0.908l0.604-1.302
		l0.555-0.739l1.761,1.046l0.513,0.581l0.37,0.509l1.76,2.008l2.543,2.4l0.443,0.296l0.382,0.085l3.21,0.138l0.37-0.053l0.184-0.127
		l0.243-0.254l0.604-1.491l0.19-0.274l0.248-0.255l0.371-0.199l2.22-1.217l0.19-0.095l0.147-0.011l1.603,0.878l5.896,2.694
		l2.42,1.248l0.82,0.581l0.916,0.54l0.581,0.233l4.135,1.216l0.485,0.041l0.322-0.147l0.435-0.254l0.083-0.053l-0.052-0.042
		l0.661-0.412l0.677-0.243l1.051-0.105l3.96-0.021l0.201,0.084l0.228,0.148l1.021,0.803l0.623,0.592l1.223,1.471l0.502,0.655
		l0.349,0.55l1.095,0.993l0.665,0.402l2.659,1.015l0.857-0.485l1.104-0.729l1.127-0.74l0.042-0.275l-0.042-0.095l0.392-0.752
		l1.384-1.077l2.581,0.665l1.792,0.476l2.442,5.562l2.285,4.431l1.146,1.554l0.673,0.678l2.865,2.125l1.169,0.285l0.894,0.211
		l0.564,0.054l0.783,0.433l0.147,0.232l0.043,0.36l-0.296,0.931l-0.064,0.2l-0.549,0.55l-0.545,0.2l3.559,1.428l0.617,0.307
		l0.571,0.401l0.936,1.016l0.212,0.285l0.18,0.35l0.032,1.448l0.03,0.338l0.074,0.201l1.147,1.386l2.987,3.065l0.286,0.158
		l0.233,0.042l0.459-0.01l1.697-0.909l1.03-0.794l0.275-0.158l0.386-0.104l2.035-0.571l0.407-0.043l0.519,0.043l0.412,0.2
		l0.364,0.285l0.708,0.794l1.159,1.226l0.565,0.401l0.636,0.191l4.386,1.057l0.604,0.021l0.091,0.138l-0.012,0.085l-2.479,3.637
		l-0.033,0.053l-0.739-0.074l-0.65-0.359l-0.38-0.528l-0.085-0.042l-0.83,0.158l-0.339,0.708l-0.222,1.016l-0.578,3.203l0.8,1.871
		l-0.862-0.011l-1.231,0.286l-1.114,0.635l-1.762,1.701l-0.894,1.163l-0.147,0.159l-5.693,2.781l-1.128,0.307l-3.154-0.318
		l-0.318-0.063l-0.105-0.074l-0.042-0.126v-0.275l-0.126-0.316l-0.319-0.328l-0.743-0.772l-3.897-0.761l-9.263-0.401l-0.339,0.031
		l-0.259,0.105l-0.328,0.381l-0.17,0.655l-0.04,0.751l0.094,0.455l-0.021,0.158l-0.202,0.423l-2.849,3.098l-6.063,0.159
		l-2.675,0.549l-2.063,0.582l-0.507,2.188l-0.021,0.2l0.222,2.274l-0.148,0.603l-0.084,0.57l2.632,7.19l2.612,1.924l3.727,5.317
		l2.798,5.425l-0.011,0.042l-0.18,2.188l0.339,1.343l0.338,0.761l0.756,1.29l1.317,1.682l1.823,2.113l-1.74-0.073l-2.241,1.205
		l-0.623,1.555l0.855,1.84l4.525,5.771l1.296,0.805l0.317,0.053l0.227,0.105l0.772,0.666l0.617,0.603l0.053,0.392l-0.042,1.406
		l-0.052,0.179l-0.809,0.646l-1.126,0.519l-0.18,0.053l-0.563-0.138l-1.225,1.713l0.417,4.103l0.054,0.095l1.432,1.575l1.359,1.364
		l1.433,1.998l0.438,0.963l0.021,0.084l-0.032,0.063l-0.586,0.782l-1.083,0.222l-0.699,0.18l-0.999,0.465l-0.327,0.213l-0.29,0.539
		l-0.413,1.047l-0.116,0.75l0.064,0.423l0.137,0.307l0.179,0.19l0.377,0.296l0.665,0.402l0.48,0.011l0.878-0.117l0.169,0.021
		l0.06,0.074l0.105,0.359l-0.054,1.458l-0.163,0.276l-1.189,1.775l-1.877,1.533l-2.03,1.448l-0.381,1.925l-0.307,3.088l-0.111-0.096
		l-1.706-0.17l-3.433,0.105l-0.519,0.096l-0.935,0.666l-0.359,0.148l-1.387,0.285l-1.823,0.063l-0.945-0.222h-0.255l-5.033,0.063
		l-1.021,0.2l-1.924,0.582l-0.105,0.952l0.158,0.443l-0.042,0.274l-0.053,0.148l-1.771,1.744l-4.207,0.444l1.685,2.379l3.733,2.802
		l-1.274,0.423l-0.756,1.101l-0.021,0.18l0.021,1.627l0.893,0.327l0.806,0.329l0.522,0.645l0.105,0.285l0.075,0.655v0.741
		l-0.107,0.349l-1.02,1.597l-0.18,0.127l-0.381,0.021l-0.386,0.159l-2.146,1.776l-0.36,0.465l-0.147,0.507l0.012,3.458l0.264,2.822
		l0.703,1.47l1.031,0.571l3.013,4.452l0.936,2.23l-0.211,0.528l0.084,0.855l0.224,1.28l0.136,0.391l1.264,2.03l0.302,0.412
		l0.053,0.053l0.37,0.116l-0.106,0.18l-1.157,0.159l-0.274-0.043l-2.083,5.53l-0.926,1.766l-4.566,1.163l-2.434,0.307l-0.581-0.307
		l-1.211-1.799l-0.386-0.252l-0.74-0.465l-0.417-0.191l-1.538-0.655l-0.843-0.274l-0.19,0.021l-2.368,0.624l-0.697,1.163
		l-0.904,1.934l0.435,0.202l0.04,0.095l-0.231,0.814l-0.147,0.316l-1.507,2.263l-0.143,0.106l-2.591,2.082l-8.025,5.435
		l-5.313,1.078l-6.663,3.871l-3.077,2.072l-0.49,0.188l-0.212,0.063l-0.201-0.136l0.359-0.233l0.043-0.138l-0.043-1.173
		l-0.339-0.593l-1.253-2.029l-0.285-0.169l-3.093,0.411l-3.198,0.455l-0.181,0.042l-0.433,0.275l-4.399,3.7l-1.083,1.501
		l1.775,2.781l1.72-0.824l0.513-0.021l0.073,0.148l0.074,1.363l-0.094,0.477l-0.271,1.184l-2.321,1.195l-0.132-0.232l-3.362-1.66
		l-5.293-2.21l-1.729-0.551l-0.661-1.151l-0.507-0.783l0.074-0.317l0.507-1.998l0.201-0.434l1.073-1.691l0.514-0.095l1.612-0.265
		l1.283,0.053l0.745-1.142l0.097-0.211l-0.756-4.304l-0.349-1.586l-0.545-0.602l-2.066-3.521l-0.054-0.158l0.011-0.097l0.148-0.316
		l2.431-2.717l1.527-0.265l0.181-0.359l0.587-0.794l0.73-0.602l0.66,0.19h0.106l0.116-0.17l0.327-0.835l-0.18-0.402l-0.181-0.19
		l-0.818-0.528l-0.698-0.581l-1.423-1.554l-0.073-0.106l-3.409-1.744l-0.969,0.021l-3.198-0.053l-0.697-0.095l-0.725-0.444
		l-0.307-0.412l-0.042-0.327l0.212-0.825l0.242-0.539l-0.032-0.381l-0.148-0.222l-0.273-0.054l-3.274,0.72l-0.137-0.085
		l-0.231,0.011l-0.587,0.19l-0.064,0.021l-0.455,0.243l-0.137,0.021l-1.606,0.042h-0.213l-0.274-0.073l-0.46-0.339l0.861-7.232
		l1.809-5.57l1.243-3.046l0.021-3.404l-1.776-1.775l-0.56-0.328l-1.48-0.551l-0.445-0.127l-0.211-0.01l-0.619,0.222l-0.338,0.201
		l-1.01,0.635l0.233-0.148l0.512-2.422l0.148-1.015l0.042-0.602l-0.338-1.301l-0.471-0.824l-0.063-0.085l-1.063-0.571l-0.338-0.053
		l-0.063,0.031l-1.01,0.158l-0.872-0.486l-5.658-4.091l-0.629-1.533l-0.275-6.207l0.032-1.797l0.032-0.243l0.465-0.666l0.502-0.265
		l0.138-0.116l-0.915-1.586l-1.348-1.226l0.286-2.22l-0.339-0.974l-0.528-0.581l-0.228-0.105l-0.931-0.328l-3.462-0.667
		l-3.453-2.653l-1.915-2.538l2.297,1.206l1.453,0.021l1.987-4.916l0.063-0.232l-2.547-1.691l-2.01-0.805l-1.559-0.433l-0.286-0.116
		l-0.164-0.127l-0.507-1.555l2.263-4.778l0.665-0.455l1.021-0.676l0.969-0.477l1.2-0.169l0.786-0.465l0.751-0.678l0.111-0.138
		l0.804-1.542l1.491-3.046l0.19-0.486l0.084-0.697l-0.914-2.337l1.803-4.091l-0.054-0.213l-0.21-0.676l-2.708-1.228l-2.336-0.548
		l-1.581,2.062l-0.757,1.405l-2.126,2.781l-2.896,3.763l-0.19,0.074l-5.112,1.143l-6.73,0.063l-1.839-0.794l-0.54-0.391
		l-0.174-1.469l-0.244-7.625l0.27-1.342l-0.047-0.307l-0.223-0.57l-0.729-0.698l-2.125-1.66l-5.905,3.066l-3.082-1.511l-3.109,2.389
		l-0.137,1.405l-0.196,1.312l-0.104,0.339l-1.54,1.311l-0.978,0.138l-3.357,0.254l-0.497-0.508l0.032-0.189l-0.307-0.308
		l-2.368-0.624l-0.438-0.095l-0.413,0.265l0.496,1.077l0.651,3.204l5.213,3.923l2.178,1.681l1.56,4.62l-0.148,1.756l0.238,3.329
		l-0.481,0.974l-1.116,4.451l0.086,0.232l0.545,1.016l0.783,0.687l0.279,0.116l0.359,0.021l1.21,0.392l1.243,0.645l-0.641,0.846
		l-0.476,0.593l-1.01,1.057l-0.232,0.19l-0.528,0.254l-0.364,0.063l-0.646-0.043l-0.17,0.063l-2.21,1.47l-0.428,0.402l-0.339,0.486
		l-0.053,0.339l0.116,0.57l0.244,0.719l-3.015-0.317l-2.442-0.888l-2.923-0.635l-0.603-0.074l-1.38,1.026l-1.702,1.627l-4.023,2.908
		l-1.597,0.37l-2.263,1.406l-0.308-0.623l-1.094-0.285l-1.042-0.149l-1.527-0.116l-0.17,0.011l-2.272,1.734l-0.393,0.528
		l-0.628,0.931l-1.147-0.434l-1.803-0.762l-1.03-0.539l-0.244-1.005l-1.406-2.294h-0.275l0.043-0.138l0.937-0.708l3.694-1.227
		l3.188-0.973l-0.189-4.145l-1.967-1.839l-2.326-0.444l-0.063-0.031l-1.231-0.879l-0.286-0.485l-0.301-0.995l-0.169-0.771
		l0.011-0.116l2.733-5.814l1.052-0.708l-1.363-1.396l-0.161-0.2l-0.072-0.592l0.042-0.465l0.095-0.381l2.104-2.242l-0.698-2.537
		l-0.956-1.682l-2.786-1.701l-1.571,0.38l-0.264,0.169l-1.327,0.592l-2.209-0.338l-5.076-3.087l-1.554-4.451l-0.529-1.121
		l-0.2-0.264l-0.153-0.159l-4.606-1.808l-0.237-0.106l-0.782,0.974l-0.598,1.036l-0.38,0.803l-0.465,1.513l0.675,0.465l1.381,0.74
		l-0.127,0.677l-1.802,3.182l-2.316,1.153l-0.322,0.021l-0.265,0.031l-1.697,0.973l-4.207,2.591l-3.718,2.748l-0.613,0.519
		l-0.343,0.687l-0.266,0.498l-1.041,0.549l-1.021,0.105l-0.804,0.085l-0.407,0.581l-0.883,1.037l-5.012-0.37l-1.295-0.084
		l-2.59-0.287l-2.137,0.476l-0.603,0.562l-0.222,0.264l-0.693,0.995l-2.104,0.073l-1.392-1.575l-0.607-1.639l-0.148-0.771
		l-0.147-0.846l0.053-0.487l1.38-3.13l0.66-1.469l1.063-1.428l0.105-2.136l-0.496-1.871l-4.071-7.823l-0.085-0.149l-0.254-0.222
		l-0.628,0.392l-0.551,1.047l-0.513,1.099l-1.004,0.678l-0.324,0.116l-10.371-2.93l-1.333-0.496l-3.362-2.453l0.043-0.296
		l0.358-1.956l0.244-1.216l0.787-2.887l0.413-4.091l-0.434-4.028l2.113-1.734l1.058-0.338l1.597-0.265l0.434,0.011l6.535,0.391
		l5.297,0.888l1.274,0.434l0.18,0.244l0.021,0.063l0.365,0.211l1.751,0.465l0.073-0.021l0.243-0.243l0.598-0.824l0.83-7.201
		l0.401-4.112l0.55-5.763l1.095-4.018l2.104-2.854l4.123-6.248l1.38-4.208l0.562-1.776l0.221-1.143l-0.527-6.945l0.316-3.447
		l-1.158-0.94l-0.138-0.836l-0.042-0.846l0.862-2.368l0.798-1.417l0.878-2.062l0.387-5.467l1.114-4.746l0.677-2.115l0.084-0.253
		l0.096-0.096l0.534-0.19h2.876l-0.358-0.137l-2.265-0.645l-1.241-0.287l-0.861-0.137l-3.099-0.275l-6.989-0.222l-2.03-0.285
		l-0.401-0.243l-0.28-0.36l-0.741-1.374l-0.189-0.507l0.085-0.285l0.982-1.692l0.133-2.611l1.57-12.751l3.357,1.522l0.977,1.386
		l1.92-1.925l1.063-1.142l-0.999-1.787l-0.677-0.402l-1.649-1.099l-0.508-0.656l0.285-0.898l4.589-4.758l1.212-1.205l2.717-1.978
		l1.104-0.771l2.819-0.994l1.348-0.307l1.23-0.147l1.655-0.148l7.465,0.613l0.355,0.127l0.603,0.35l0.138,0.189l0.512,0.35
		l0.72,0.359l0.459,0.011l1.761-0.328l3.944-1.279l4.214-1.195l0.396-0.074l0.603-0.084l0.443,0.043l0.011,0.158l0.566,0.148
		l1.147,0.03l1.231-0.116l3.156-0.951l6.19-0.55l3.188,0.35l2.854,0.454l0.979,0.498l0.244,0.296l-0.043,0.476l-0.085,0.232
		l-0.449,0.634l-0.096,0.73l0.345,2.272l0.221,0.867l0.53,1.385l2.88,0.401l0.285,0.349l0.519,0.782l0.577,1.121l3.356,7.877v0.105
		l-0.053,0.328l-0.105,0.359l-0.259,0.889l-0.285,0.581l-0.904,0.465l-0.899,0.338l-2.146,0.782l-0.407,0.106l-0.919-0.031
		l-0.989-0.772l0.074,2.062l0.104,0.931l0.127,0.529l0.244,0.666l0.312,0.677l0.614,0.813l0.798,0.867l0.392-0.158l0.83-0.551
		l0.189-0.042l0.894-0.031l0.698,0.2l1,1.28l1.21,1.755l0.639,1.015l0.054,0.105l-0.021,0.244l0.211,0.422l0.138,0.148l0.649,0.275
		l1.08,0.253l0.786,0.096l0.37-0.043l0.62-0.316l0.506-0.381l0.175-0.189l0.678-0.763l0.575-0.813l0.466-0.824l0.734-1.735
		l0.328-0.771l0.127-0.792l0.233-3.722l-0.075-1.555l-0.072-0.222l-0.244-0.497l-0.149-0.169l-0.438-0.254l-1.571,0.613
		l-0.549,0.158l-2.136-0.401l0.485-4.166l0.254-1.66l0.075-0.306l0.417-0.89l0.328-0.465l0.935-0.93l0.937-0.739l1.315-0.487
		l1.201,0.116l1.4,0.92l0.435,0.624l0.744,1.395l0.915,1.364l1.116,0.614l3.727,1.68l0.212,0.044l0.401-0.053l0.46-0.011
		l1.412,0.243l0.756,0.127l1.919,1.184l0.127,0.137l0.096,0.265l0.164,0.962l-0.022,0.074l-0.185,0.169l-0.349,0.254l0.338,0.476
		l1.363,1.818v0.18l-0.058,0.201l-0.369,0.529l0.169,0.232l0.322,0.201l1.823,0.2l0.18-0.011l2.56-2.707l0.095-0.518l-0.898-3.88
		l1.073-4.97l-0.063-0.095l-1.682-0.264l-5.393-0.275L176.903,149.425z M204.517,199.233l-0.266-0.57l-1.38-1.671l-0.682-0.486
		l-0.317-0.011l-1.146,0.729l-4.706-0.138l-3.769-1.914l-4.229-2.071l-6.477-0.771l-7.513-4.051l-0.275-0.866l-0.011-0.677
		l-0.021,0.729l0.223,1.312l0.396,0.855l0.318,0.561l0.734,1.078l1.549,1.248l2.379,1.142l0.799,0.338l0.909,0.128l0.767,0.201
		l0.358,0.307l0.255,0.507l1.575,4.197l0.148,1.321l0.915,0.624l1.96,2.115l0.493,0.676l0.063,0.634l0.063,2.612l0.487,0.508
		l0.544,0.465l0.307,0.074l1.053-0.359l0.369-0.148l1.349,0.011l5.345,1.851l3.109,1.216l3.251-2.781l0.053-1.967l-0.381-4.768
		l-0.788-1.227l0.085-0.127l0.138-0.074l0.967-0.359L204.517,199.233z M137.575,131.304l-1.031-1.259l-0.106-0.115v-0.181
		l0.434-0.381l0.381-0.158l1.337-0.053l0.746,0.031l0.681,0.096l1.762,0.391l0.619,0.18l0.221,0.116l1.231,0.857l0.055,0.476
		l-0.17,0.042l-1.628-0.603l-3.844-0.983l-0.148,0.01l-0.095,0.074l-0.254,0.434L137.575,131.304z M146.854,134.475l-0.105-0.011
		l-0.328-0.147l-0.255-0.168l-0.211-0.243l-0.095-0.233l-0.429-1.681l0.011-0.382l0.724-0.866l0.36,0.444l0.411,2.114l-0.021,1.068
		L146.854,134.475z M126.208,133.153l-0.529-0.095l-0.175-0.105l-0.21-0.403l0.011-0.391l0.273-0.561l0.609-0.761l0.285-0.274
		l0.264-0.169l0.607-0.191l1.054-0.041l1.506,0.041l2.379,0.211l0.434,0.065l1.354,0.369l0.391,0.412l0.053,0.148l-0.053,0.042
		l-3.674,0.688l-3.695-0.233L126.208,133.153z M113.525,135.986l-0.223-0.042l-0.359-0.296l-0.148-0.898l0.021-0.729l0.074-0.286
		l0.718-0.982l0.197-0.169l0.624-0.318l0.433-0.074l3.39-0.021l2.68,0.137l0.973,0.117l0.248,0.073l0.401,0.148l0.359,0.212
		l0.128,0.19l0.032,0.486l-0.021,0.105l-0.042,0.011l-0.793,0.053l-0.011-0.053l0.126-0.127l-0.011-0.053l-0.063-0.021l-2.124-0.137
		H119.9l-1.586,0.137l-2.327,0.223l-0.285,0.095L113.525,135.986z M111.209,136.114l-1.38-0.054l-1.612-0.19l-1.391-0.316
		l-0.323-0.486l0.011-0.105l0.086-0.097l1.47-0.602l0.253-0.053h0.328l2.083,0.455l0.138,0.074l0.316,1.119l0.043,0.159
		L111.209,136.114z M93.805,137.721l-0.639-0.539l-0.266-0.38l-0.054-0.191l0.128-0.232l0.307-0.222l0.375-0.189l1.412-0.635
		l0.926-0.243l0.549-0.074l7.148,0.031l1.072,0.032l0.529,0.138l0.25,0.126l0.179,0.508l-0.043,0.147l-0.884,0.317l-0.598,0.191
		l-7.665,0.951l-1.987,0.169L93.805,137.721z M78.277,140.407l-0.587-0.044l-0.116-0.01l-0.339-0.115l-0.502-0.329l-0.063-0.169
		l0.063-0.138l0.343-0.095l2.101-0.201l6.925-0.687h3.125l0.873,0.19l0.126,0.158l0.053,0.148l-0.01,0.253l-0.169,0.232
		l-0.059-0.158l-0.085-0.042l-1.496-0.096l-0.624-0.021l-0.746,0.021l-3.537,0.127l-4.673,0.719l-0.561,0.17L78.277,140.407z
		 M68.587,150.948l-0.519-1.079l-0.307-0.201l-0.522,0.032l-0.434,0.158l-0.064,0.138v0.296l0.105,0.307l0.127,0.095l-0.011,0.042
		l-0.116,0.032l-0.649-0.106l-2.602-2.07l-0.443-0.678l-0.169-0.856l0.083-0.413l0.158-0.253l0.096-0.096l0.286-0.105l5.24-1.585
		l0.273-0.012l0.55,0.075l0.661,0.284l1.762,1.322l0.522,0.55l0.053,0.159l-5.281,1.49l1.263,1.131l0.672,0.815l-0.054,0.094
		l-0.554,0.424L68.587,150.948z"
        />
        <path
          id="Mecklenburg-Vorpommern"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M321.984,114.018l4.558-3.109
		l2.955-1.766l1.01-0.434l0.434-0.105l2.178-0.528l0.656-0.053l4.938-0.233l0.28,0.169l0.359,0.371l3.367,3.785l-0.211,1.893
		l0.031,0.549l0.338,0.804l0.592,0.55l0.608,0.317l0.55,0.18l0.545-0.053l0.147-0.064l1.19-0.813l0.454-0.338l0.174-0.265
		l1.624-0.38l2.358,1.024l1.253,0.571l-0.063,0.898l0.116,0.233l0.608,0.476l1.326,0.782l0.782,0.401l1.479-2.548l0.349-1.005
		l0.573-5.592l0.901-1.829l0.129-0.191l0.396-0.359l1.804-1.035l0.851-0.287l0.327-0.211l0.477-0.443l0.174-0.243l2.75-4.271
		l0.083-1.164l-0.053-0.252l-0.65-1.048l-0.21-0.232h-0.244l-0.296,0.265l-1.306,1.312l-0.575,1.024l-0.752,1.037l-0.756,0.94
		l-1.725,2.094l-0.063,0.031l-0.073-0.063l-0.147-0.877l-0.021-0.528l0.115-0.508l0.641-1.237l1.739-2.389l7.566-6.229l0.211-0.063
		l0.539-0.053l0.554-0.032l3.401-0.116l0.169,0.021l0.624,0.286l0.734,0.222l0.956,0.223l1.159,0.147l1.009,0.032l0.888-0.063
		l0.556-0.139l1.275-0.4l6.988-2.781l4.139,0.623l2.945,0.382l1.498-3.395l1.976-3.796l0.318-0.454l0.998-0.909l1.613-1.132
		l3.504-2.188l1.147-0.699l0.491-0.359l0.529-0.433l0.631-0.645l0.464-0.572l0.581-0.94l4.209-7.919l2.737-5.403l2.21-3.795
		l1.756,1.438l0.847,0.359l0.46,0.117l4.075,0.528l3.568,0.412l7.255-0.19l4.324-0.307l1.316-0.158l1.798-0.042l1.348,0.147
		l0.117,0.063l0.063,0.116l0.031,0.201l-0.072,0.105l-2.56,1.713l-0.396,0.222l-1.878,0.486l-4.249,0.317l-2.92,0.095l-1.03-0.074
		l-0.126-0.041l-0.223-0.191l-0.449-0.687l-0.254-0.677l-0.266-0.137h-0.053l-1.543,0.073l-3.257,0.835l-3.093,1.946l-8.945,3.256
		l-0.93,0.772l-2.745,6.448l-0.306,1.872l0.031,2.506l0.042,0.073l2.231,1.523l1.063,0.391l1.179,0.074l0.998-0.18l0.36-0.201
		l0.011-0.2l-0.297-0.243l-0.348-0.18l-1.238-0.254h-0.466l-0.179-0.074l-0.587-1.152l-0.074-0.856l0.449-0.994l3.183-3.627
		l4.674-3.604l5.979-2.897l5.061,1.025l4.683,2.137l1.961-0.359l0.376-0.244l1.359-1.205l0.211-0.211l0.212-0.413l0.084-0.454
		l0.01-0.962l0.588-1.544l0.054-0.148l1.391-1.227l0.243-0.19l3.759-1.416l2.22,0.222l4.113,6.005l-0.788,1.799l0.169,0.792
		l1.723,5.582l2.509,2.813l1.727-0.973l0.693-0.337h0.169l6.127,2.927l0.148,0.149l0.43,0.729l1.569,3.289l0.254,0.592l0.223,0.391
		l0.18,0.116l0.185-0.011l0.274,0.604l2.199-0.021l2.221,0.845l0.021,0.096l-1.691,1.49l0.137,0.677l0.19,0.201l0.301,0.031
		l0.519-0.338l0.233-0.232l0.228-0.021l1.231,0.771l0.096,0.074l0.147,0.285l0.656,1.417l0.01,0.92l0.095,0.286l1.127,1.839
		l0.365,0.317l0.368,0.169l1.487,0.265l0.19-0.053l0.248-0.159l0.032-0.063l-0.073-0.242l-0.439-0.592l-0.465-0.656l-0.662-1.565
		l-0.01-0.2l0.925-0.762l0.327-0.116l8.83-2.939l3.536-1.067l3.896,3.879l0.63,0.709l0.549,0.879l0.213,0.655l0.041,0.358
		l0.011,4.188l3.314,6.819l3.919,4.112l0.744,1.047l0.424,0.688l0.127,0.338l-0.032,0.243l-0.38,0.593l-0.33,0.349l-0.104,0.063
		l-0.18-0.053l-0.386,0.011l-0.381,0.169l-0.349,0.307l-0.808,0.878l-1.81,2.083l-1.453,2.125l0.083,0.391l0.577,1.153l0.201,0.188
		l5.821,3.596l2.051,1.522l1.569,1.438l0.381,0.169l6.789,2.421l1.337,0.434l1.041,0.158l3.368,0.075l0.339-0.022l0.211-0.138
		l1.073-0.95l0.841-0.942l0.402-0.612l0.137-0.148l0.43-0.021l0.676,0.158l0.376,0.158l0.835,0.729l0.418,1.143l1.072,5.709
		l1.982,6.376l0.365,3.785l-0.396,2.833l-0.011,0.116l0.252,0.983l0.714,1.956l0.829,2.125l0.171,0.127l1.623,2.273l0.365,2.22
		l1.241,4.927l2.549,7.349l0.286,0.697l-0.444-0.096l-0.312,0.021l-5.022,2.114l-0.16,0.932l-0.617,1.796l-2.025,2.834l-0.809,0.265
		l-2.505,0.401l-5.832-0.2l-1.106-0.127l0.181-1.217l0.104-0.476l0.481-0.803l1.571-1.903l0.733-0.666l1.017-1.068l1.073-1.289
		l0.787-1.047l1.011-1.649l0.424-0.983l0.136-0.477l0.534-1.965l0.021-0.53l-0.242-2.843l-0.42-0.688l-0.729-0.486l-6.334-0.391
		l-0.721,1.396l-3.879,0.126l-2.686-0.423l-3.399,0.603l-0.581,0.254l-1.618-0.655l-1.476-3.151l-0.967-3.309l-5.002-5.794
		l-0.519,0.127l-0.364,0.634l-0.074,0.317l-0.253,1.935l0.011,0.328l0.158,1.385l1.368,1.237l-2.748,2.432l-2.686,0.201
		l-1.745,1.385l-0.952,0.856l-6.587,6.322l-0.211,0.498l-1.181,2.833l-0.47,1.607l0.249,1.3l0.296,1.808l0.011,0.116l-0.064,0.096
		l-2.135,2.876l-4.737,4.979l-0.703,0.307l-0.359-0.105l-1.084-1.629l-0.128-0.888l-0.063-0.202l-0.391-0.538l-0.212-0.116
		l-3.516-0.054l-1.094,0.52l-1.819,1.574l-0.359,0.508l-0.189,0.751l-0.106,1.227l0.011,0.507l-0.128,0.677l-0.49,0.867
		l-1.486-0.222l-0.181-0.201l-0.818-1.756l-0.233-0.634l-0.306-0.57l-1.937,0.253l-0.18,0.032l-2.261,1.1l-0.435,0.401l-7.011,7.645
		l-0.301,0.159l-0.159,0.02l-2.072-0.888l-0.147-0.127l-0.011-0.063l0.27-0.413l0.931-0.729l1.041-0.624l-0.021-0.169l-0.212-0.507
		l-0.04-0.042l-0.685-0.201l-7.284,0.201l-1.782,0.581l-0.084,0.063l-0.021,0.148l-1.156,0.444l-0.831,0.307l-1.169-0.126
		l-0.379-0.688l-2.464-2.559l-3.389-3.161l-0.778-0.138l-7.676-0.719l-1.766,0.381l0.042,0.519l-0.2,0.212l-3.252,0.391
		l-2.476-2.792l-0.508-1.427l-0.978-0.793l-1.86-1.005l-0.739-0.35l-0.608-0.169l-0.551-0.369l-1.986-0.603l-3.866-2.96
		l-0.333,0.021l-5.529,1.131l-1.485,0.54l-1.703-0.232l-1.538-0.286l-2.073,2.961l0.158,1.776l-0.369,0.497l-3.748,2.674
		l-0.386,0.243l-5.013,1.829l-3.209,0.232l1.104,1.861l0.133,0.349l-0.027,0.116l-0.211,0.232l-5.528,1.237l-0.63,0.105
		l-0.697-1.459l-1.704-0.962l-1.021-0.073l-2.738,0.042l-0.566,0.434l-3.874,3.531l0.683,4.092l-0.641,2.537l-0.328,0.982
		l-0.86,0.604l-1.539-0.053l-1.634-0.296l-0.642-0.296l-0.03-0.255l-0.799-0.592l-3.378,0.403l-2.76,2.177l-5.62-0.328l-0.18-0.349
		l-0.21-0.285l-0.938-1.016l-0.57-0.401l-0.618-0.307l-3.558-1.428l0.543-0.199l0.552-0.551l0.062-0.201l0.297-0.94l-0.042-0.35
		l-0.147-0.232l-0.782-0.434l-0.566-0.053l-0.894-0.211l-1.167-0.285l-2.867-2.126l-0.672-0.677l-1.147-1.555l-2.288-4.43
		l-2.438-5.561l-1.791-0.477l-2.581-0.666l-1.392,1.079l-0.385,0.75l0.042,0.096l-0.042,0.274l-1.126,0.741l-1.105,0.729
		l-0.855,0.486l-2.66-1.015l-0.666-0.402l-1.094-0.993l-0.348-0.551l-0.504-0.654l-1.223-1.47l-0.624-0.592l-1.02-0.804
		l-0.232-0.148l-0.194-0.084l-3.96,0.021l-1.053,0.105l-0.677,0.243l-0.661,0.412l1.776-8.099l0.614-1.278l0.999-0.583l0.507,0.032
		l0.649,0.274l1.381,0.297l3.834-2.906l0.301-0.318l1.005-1.101l2.146-2.526l6.556-8.69l0.401-2.432l1.402-1.945l-0.709-3.785
		l-1.328-1.448l-0.893-0.434l-1.127-0.233l-0.169,0.043l-0.221,0.127l-0.033,0.181l-0.396,0.115l-1.253,0.064l-0.222-0.096
		l-1.528-0.931l-3.807-3.701l0.419-3.119l0.18-1.5l-0.064-0.508l-0.338-0.899l-0.196-0.38l-0.994-2.474l-0.021-1.809l1.424-2.273
		l5.223-3.669l2.621,0.19l0.946-2.136L321.984,114.018z M463.021,36.296l-0.582,0.359l-1.765,1.438l-1.285,1.48l-0.222,0.349
		l-2.993,7.454l-0.073,0.201l-0.091,0.878l0.133,0.021l0.477-0.158l0.232-0.19l2.986-2.877l0.656-1.69l-0.159-0.604l-0.265-0.889
		l-0.031-0.138l0.053-0.115l1.654-2.348l1.041-0.296l0.107,0.031l0.2,0.434l0.152,1.217l-0.021,0.306l-0.11,0.592l-0.328,1.005
		l-0.739,1.321l-0.28,0.382l-0.243,0.57l-0.359,2.04l0.043,1.036l2.801-1.331l2.073-1.195l6.831,2.813l0.158,0.043l0.115-0.011
		l1.882-0.233l1.857-0.751l0.115,5.181l-0.137,2.813l-0.148,0.096l-0.37,0.011l-1.692,0.042l-2.675-0.856l-0.691-0.339l-3.992-2.326
		l-0.232-0.264l0.021-0.328l0.327-3.13l-3.125,0.117l-3.33,0.138l-1.126,0.253l-3.294,0.941l-0.894,0.519l0.609,2.664l0.453,0.877
		l2.02-0.115l1.581,0.148l2.506,2.271l-0.857,1.639l-0.237,0.138l-2.242,0.783l-1.156,0.211l-1.274,0.254l-1.158,2.579l0.011,0.55
		l0.043,0.139l0.342,0.189l5.309,1.163l0.672,0.138l-1.444,0.889l-5.936,2.875l-0.154,0.222l-0.137,0.403l-0.444,2.452l0.043,1.046
		l1.676,4.969l0.121,0.244l15.506,5.201l1.274-0.961l0.265-0.328l0.137-0.265l0.032-0.126l-0.074-0.593l-0.413-0.94l-0.851-0.994
		l-0.096-0.043h-0.564l-0.158,0.201l-0.086,0.169l0.054,0.243l-0.043,0.128l-0.645,0.559l-0.787,0.393l-0.371,0.031l-0.364-0.424
		l-0.032-1.204l4.187-4.43l3.294-3.162l1.053-0.782l0.327-0.127l10.046-1.227l0.417,0.074l1.391,2.294l0.042,0.117l0.011,0.465
		l-0.189,0.434l-0.073,0.127l-0.801,0.604l-0.063,0.041l-0.945,0.487l-0.012,1.542l2.138-0.56l0.894-1.776l1.21-2.495l-0.693-1.66
		l-1.771-2.875l-1.264-1.037l-1.28-0.898l-0.116,0.138l-0.221,0.116l-0.646,0.074l-1.242,0.063l-0.196-0.021l-0.284-0.18
		l-0.413-0.37l-1.01-1.194l-1.104-2.083l-0.255-0.655l-0.318-1.12l-0.11-1.46l0.11-0.561l0.191-0.338l0.159-0.223l0.094-0.116
		l1.582-1.786l2.104-1.767l0.56-0.285l0.207-0.042l0.612-0.444l0.566-1.163l0.147-1.057l-0.243-2.114l-0.396-0.509l-0.687-0.634
		l-1.428-0.94l-0.254-0.085h-0.453l-1.776,0.17l-0.423,0.042l-2.274,0.476l-0.316,0.137l-2.042,0.646l-2.886,0.527l-0.641-0.043
		l-1.031-0.252l-0.391-0.139l-0.481-0.317l-0.296-0.2l-0.834-0.846l-0.735-0.973l-0.524-1.089l-0.423-1.522l-0.137-0.857
		l0.043-0.326l0.348-1.037l0.344-0.476l0.17-0.116l0.538-0.255l0.397-0.104l0.392-0.031l0.284-0.127l0.412-0.36l0.248-0.476
		l0.35-1.364l-0.011-0.063l-0.36-0.508l-0.246-0.264l-3.046,0.274l-4.495,1.27L463.021,36.296z M450.555,58.489l0.126,0.315h0.097
		l0.18-0.252l0.18-0.698l1.094-6.091l0.789-3.891l2.283-1.152l-2.976-1.058l-1.814,10.383l-0.138,1.047l-0.01,0.539l0.073,0.338
		L450.555,58.489z M455.408,59.989l4.251-2.347l0.519-0.412l0.116-0.148l-0.286-0.92l-0.254-0.317l-1.306-0.603l-0.238-0.105
		l-0.687-0.084l-0.671,0.169l-0.656,0.443l-0.841,1.121l-0.158,0.359l-1.063,2.484l-0.03,0.667l0.062,0.158l0.138,0.063
		L455.408,59.989z M445.84,60.941l-0.147-0.042l-0.213,0.011l-0.185,0.063l-0.233,0.147l-0.115,0.127l0.011,0.138l0.273,0.264
		l0.249,0.222l0.666-0.042l3.229-1.142l0.667-0.582l0.354-0.38l-0.502-0.55l-0.223,0.053l-2.834,1.068l-0.364,0.422l-0.519,0.624
		L445.84,60.941z M497.063,86.929l-0.508-0.55l-0.243-0.169l-0.514-0.126l-0.327,0.031l-0.66,0.18l-0.729,0.391l-0.482,0.382
		l-0.147,0.211l-0.169,0.359l0.043,1.342l0.349,1.059l0.211,0.264l1.988,2.221l0.212,0.137l0.513,5.023l3.705,5.761l0.105,0.159
		l0.18,0.106l0.191,0.03l3.802-1.839l0.048-0.031l0.4-1.101l-0.021-0.116l-1.252-1.871l-0.371-1.649l-0.011-0.053l0.074-0.105
		l3.479,0.306l1.544,1.333l2.696,3.002l0.253,0.329l0.19,0.38l0.418,1.109l-0.074,5.149l-3.622-0.635l-0.744-1.532l-0.592-1.978
		l-0.154-0.137l-0.455-0.201l-0.744-0.095l-1.887,0.496l-0.139,0.053l-0.127,0.096l-0.365,0.761l-0.169,1.364l0.032,0.243
		l0.18,0.412l0.302,0.254l1.231,1.49l0.36,0.857l0.787,2.305l0.031,0.486l-0.428,1.121l-0.327,0.824l-0.232,0.201l-1.063,0.645
		l-1.253,0.676l-1.285,0.646l-0.418,0.085l-0.401-0.021l-0.265-0.042l-0.757,1.544l0.746,0.719l1.191,0.518l1.399,0.35l1.042-0.106
		l2.601-0.455l2.749-0.75l0.322-0.137l4.161-2.136l7.497-0.782l0.322,0.011l0.211,0.042l1.348-6.026l-3.906-3.204l-4.282-3.934
		l-5.139-4.831l-1.095-0.646l-1.475-0.761l-0.364-0.17l-0.35-0.084l-1.02-0.169l-1.01-0.359l-1.835-1.037l-0.533-0.316l-1.104-0.931
		l-1.097-1.121l-0.783-0.898l-0.242-0.359L497.063,86.929z M356.921,110.972l0.707-2.199l0.218-1.206l-0.055-0.527l-0.077-0.19
		l-0.266-0.127l-0.936-0.223l-1.057,0.159l-0.344,0.095l-0.498,0.201l-2.346,1.236l-1.475,1.132l-0.26,0.593l0.054,1.035
		l0.143,0.655l0.243,0.529l0.231,0.307l0.36,0.296l0.364,0.074l3.537,0.655L356.921,110.972z"
        />
        <path
          id="Hessen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M262.476,379.055l-0.201,0.91l0.147,1.004l0.577,2.168
		l1.443,3.446l1.211,1.522l3.409,1.247l1.888,0.804l0.915,2.897l4.558,2.315l1.654,0.561l0.819,0.497l0.582,0.476l1.311,1.29
		l1.242,0.296l0.286,0.613l-0.327,0.793l-0.608,0.443l-0.371,0.181l-0.333,0.306l-0.423,1.333l0.032,0.254l0.36,0.538l0.205-0.031
		l0.042,1.776l-0.042,0.794l-0.185,0.221h-0.571l-0.371-0.697l0.011-0.043l-0.278-0.443l-0.36-1.322l-1.052-1.079l-2.612,0.636
		l-0.455,0.57l0.107,0.266l1.348,2.864l0.612,1.226l0.207,4.315l1.802,0.654l0.894,0.603l0.201,0.213l0.508,0.527l0.523,0.941
		l-0.17,0.497l-0.587,1.152l0.148,0.191l-0.55,0.655l-0.274,0.159l-0.514,0.199l-0.18-0.042l-0.242,0.178l-1.712,0.233l-0.414,0.054
		l-0.465-0.136l-0.291-0.225l-0.296-0.357l-1.285-0.699l-2.294-0.316l-1.433-0.149l-0.861,0.106l-0.265,0.127l-0.148,0.104
		l-0.147,0.298l-0.608,1.269l1.464,4.812l0.968,5.908l0.021,0.794l-1.624,4.102l-0.713,1.154l-1.38,0.041l-1.623-0.211l-0.46,0.327
		l-1.094,0.89l-0.138,0.348l-0.359,1.796l0.01,0.562l0.381,0.709l0.096,0.974l0.011,0.465l-0.128,0.146l-0.264,0.095l-1.348,0.424
		l0.925,1.131l0.169,1.47v0.327l-0.477,1.239l-1.247,2.674l-1.391,1.713l-0.676,0.974l-0.302,0.443l-0.075,2.652l0.064,0.93
		l3.272,0.582l1.084,0.182l0.925-0.19l0.539-1.248l-1.23-1.523l-0.043-0.613l0.238-0.316l2.431-0.951l0.486-0.127l2.157,0.127
		l1.274,0.127l0.296,0.243l0.777,1.354l0.74,2.061l-1.803,1.513l-0.211,0.231l-0.397,0.635l-0.169,0.371l0.201,9.706l0.043,0.762
		l-0.308,1.649l-0.466,1.309l-0.925,1.388l-3.526,3.793l-1.592,0.922l-1.21,0.981l-1.168,0.719l-1.2,0.497l-1.543,0.182
		l-2.243-0.933l-0.761-0.604l-3.558,1.038l-0.349,1.585l-0.566,2.149l-0.158,0.327l-0.683,0.632l0.693,1.006l0.253,0.561
		l0.085,1.638l-0.085,1.11l-0.116,0.393l-0.433,0.804l-0.767,0.76l-1.612,1.46l-0.725,0.283l-0.096,0.035h-0.683l-1.707,0.611
		l-0.127,0.168l-0.327,0.541l-0.693,1.28l-0.936,2.376l-0.075,0.423l-0.126,0.107l-1.591,0.106l-0.777,0.019l-3.051-0.779
		l-1.089-0.37l-1.189,2.525l-0.354,3.414l0.67,4.062l0.244,1.225l0.434,1.037v0.804l-0.401,1.185l-5.107,0.919l-1.349,0.148h-0.195
		l-0.54-0.433l-1.859-4.314l-0.107-0.127l-0.307-0.085l-9.696-1.892l-13.47,3.701l-0.127,0.094l-0.519,0.611l-0.735,1.186
		l-0.465,2.483l-0.649,1.313l1.295,1.12l0.302-0.065l0.581,0.022l0.513,0.159l0.137,0.064l0.317,0.275l0.139,0.273l0.359,3.891
		l-1.581,0.887l0.349,0.478l0.042,0.221l0.375,3.025l0.149,2.854l0.095,2.061l0.381,3.047l0.105,0.221l0.714,0.951l-0.915-0.104
		l-0.212,0.054l0.031,0.633l0.116,1.438l0.097,0.392l0.264,0.284l4.356,4.357l0.392,0.021l0.776,0.169l0.233,0.072l0.18,0.127
		l1.179,5.308l-1.622,4.314l-0.497,0.243l-1.359,3.723l-0.818,6.967l0.893,1.638l-0.174,1.64l0.089,0.084l-0.385,0.984l-0.148,0.221
		l-0.54,0.433l1.613,0.868l0.348,0.255l0.565,1.384l-0.01,0.422l-0.29,0.424l-0.921,0.244l-1.479-1.723l-1.143-0.268l-5.64,1.81
		l-0.244,0.115l-1.042,0.741l-1.586,1.83l-0.38,0.709l-0.202,0.635l0.741,0.677l0.555,0.843l-6.481,5.921l-0.255,0.182l-0.126,0.032
		l-1.865-0.085l-0.481-0.094l-0.592-0.646l-0.302-0.868l0.374-0.854l1.762-3.745l2.305-0.803l2.325-2.106l-0.2-1.246l0.158-0.644
		h-2.389l-6.498-1.281l-0.46-0.157l-2.305-1.026l-1.454-0.993l-1.871-7.635l0.364-0.2l0.095,0.189l0.435,0.159l0.104-0.115
		l0.514-0.995l-0.127-0.476l-0.968-0.222l-3.933,0.983l-1.709,0.486l-0.296,0.604l0.043,0.814l0.211,0.391l0.63-0.096l0.897,4.06
		v0.507l-0.104,0.539l-0.667,1.091l-1.733,1.362l-0.937,0.043l-0.295-0.084l-0.455-0.18l-0.914-0.688l-0.631-0.857l-0.379-1.184
		l-2.041-2.623l-1.454-1.268l-0.55-0.466l-1-0.115l-0.284,0.021l-0.313,0.074l-1.041,0.507l-0.159,0.083l-0.506-1.184l-1.592-2.39
		l-0.502-0.919l-1.603-5.148l-0.106-0.456l0.169-0.972l0.075-0.242l0.201-0.371l0.116-0.127l0.629-0.486l0.551-0.35l0.48-0.231
		l0.698-0.266l1.506-0.327l0.387-0.159l0.412-0.232l0.476-0.379l0.406-0.603l0.085-0.19l0.148-0.297l0.619-1.017l-0.345-2.335
		l-2.792-2.917l-0.523-0.984l-1.003-1.438l-0.302-0.497l-2.232-5.635l-0.189-0.795v-0.389l0.116-0.528l0.133-0.349l0.348-0.572
		l0.308-0.646l0.064-0.336l0.021-0.497l-0.127-0.549l-0.592-1.903l-0.032-0.951l-0.481-1.176l-0.466-0.814l-0.374-0.56l-0.719-0.846
		l-1.275-1.163l-0.777-1.162l-0.443-0.646l-0.692-0.752l-1.159-1.108l-0.581-0.243l-1.85-0.392l-1.052-0.146l-0.915,0.062
		l-2.528,0.814l-4.139,1.892l-7.021,2.688l-0.296,0.084l-1.988,0.315l-3.335,0.106l-0.351-0.041l-0.274-0.086l-0.471-0.327
		l-5.413-7.57l-0.682-1.934l0.945-0.202l1.993-1.12l4.155-3.468l0.382,0.148l2.315,0.644l0.301-0.402l0.381-2.991l-0.127-0.359
		l-0.999-0.899l-0.788-0.603l-0.519-0.316l-0.364-0.52l-0.064-1.322l0.075-0.125l1.521-2.008l0.583-0.424l0.344-0.18l4.341-1.881
		l1.839,1.078l0.999,0.241l0.297-0.009l1.676-1.86l-0.096-0.17l-0.37-0.265l-1.671-1.628l-0.232-0.476l0.989-1.301l0.892-0.275
		l0.667,0.084l2.095,0.856l1.998-1.151l-0.022-1.925l-0.072-0.402l-0.735-2.239l-0.074-0.171l-0.2-0.401l-2.654-4.854l-5.281-3.521
		l-0.509-0.011l0.287-0.771l1.755-4.947l-0.735-0.974l-0.534-1.291l-0.01-0.328l0.471-2.431l0.528-1.067l2.389-2.845l0.376-0.138
		l0.604-0.168l0.307,0.03l1.21,0.486l0.502,1.047l1.02,0.348l1.074,0.212l0.761-0.104l0.19-0.435l0.777-1.85l0.274-0.665l0.735-1.8
		l0.382-1.185l-0.011-0.59l-1.169-0.668l-0.359-0.21l-0.128-0.255l-0.925-4.863l0.011-0.93l0.063-0.55l0.148-0.413l0.883-1.004
		l0.734-0.994l2.003-3.721l0.055-0.349l-0.181-0.951l-0.804-1.122l-0.767-1.514l-0.391-0.822l-0.418-1.006l9.485-9.261l0.2-0.17
		l0.195-0.031l0.973,0.031l0.185,0.063l4.088,2.156l0.179,0.053l0.545-0.116l0.117-0.125l1.125-1.206l4.288-6.472l0.597-2.589
		l0.055-0.128l0.4-0.233l0.095-0.051l0.598-0.011l0.158,0.062l-0.264-0.37l-0.011-0.241l0.085-0.159l0.856-0.931l1.438-1.554
		l0.989-0.868l0.392-0.368l0.476-0.73l-0.369-0.877l-0.673-1.46l-1.063-3.276l-0.095-0.309v-0.189l0.438-0.634l0.528-0.454
		l0.767-0.646l0.127-0.074l1.264-0.476l0.147-0.116l2.808,0.18l1.708,0.274l0.756,0.307l1.116,0.265l0.443-0.021l3.188-1.228
		l2.835-6.217l0.274-0.655l0.073-0.381l-0.158-2.04l-0.75-6.418l-0.111-0.254l-0.856-1.163l-0.914-0.021l-0.704,0.063l-4.567,2.052
		l-0.962,0.656l-0.105,0.031l-0.757-0.043l-0.306-0.104l-0.218-0.213l-2.067-2.886l0.571-0.857l2.611-3.752l1.095-1.354l1.085-0.708
		l2.955-1.882l4.979-1.533l3.77-1.005l4.166-0.094l3.441,0.222l0.149-0.054l0.823-2.453l-0.21-1.353l-0.043-0.222l-0.308-0.17
		l-0.543-0.126l-2.518-4.146l-0.181-0.655v-0.443l0.684-0.688l2.854-1.216l1.587-0.539l2.225-0.55l1.169-0.054l2.924,1.237
		l1.179,1.956l-0.714,1.776l0.565,1.629l0.17,0.243l0.274,0.179l0.619,0.201l2.76,0.18l0.592-0.095l0.598-1.005l0.65-1.047
		l4.319-3.479l4.462-3.488l3.241-5.318l3.844-4.609l1.295-2.179l3.272-0.709l0.275,0.055l0.147,0.211l0.032,0.381l-0.243,0.538
		l-0.212,0.824l0.042,0.34l0.307,0.401l0.725,0.443l0.698,0.096l3.198,0.053l0.967-0.021l3.411,1.745l0.072,0.105l1.424,1.555
		l0.692,0.581l0.825,0.528l0.179,0.19l0.175,0.401l-0.323,0.836l-0.116,0.169h-0.104l-0.662-0.19l-0.729,0.603l-0.587,0.793
		l-0.181,0.36l-1.527,0.264l-2.433,2.717l-0.147,0.317l-0.011,0.095l0.053,0.16l2.062,3.52l0.55,0.603l0.35,1.586l0.756,4.304
		l-0.095,0.211l-0.745,1.143l-1.286-0.053l-1.611,0.274l-0.513,0.085l-1.073,1.691l-0.201,0.434l-0.508,1.998l-0.073,0.316
		l0.507,0.783l0.661,1.152l1.729,0.561l5.291,2.199l3.358,1.659l0.137,0.233l2.315-1.195l0.274-1.185l0.097-0.476l-0.075-1.364
		l-0.074-0.147l-0.513,0.022l-1.718,0.824l-1.776-2.78l1.084-1.502l4.398-3.7l0.434-0.275l0.174-0.042l3.205-0.455l3.093-0.412
		l0.285,0.17l1.252,2.028l0.338,0.593l0.043,1.173l-0.043,0.138L262.476,379.055z"
        />
        <path
          id="Hamburg"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M250.76,155.304l0.45-2.274l0.423-1.553l0.253-0.899
		l0.725-2.094l0.232-0.316l0.393-0.021l-0.097,0.519l0.128,0.666l1.701,2.072l1.054,0.973l0.527,0.232l0.756-0.37l3.526-4.42
		l0.371-0.56l0.328-0.561l-0.011-0.328l0.072-0.761l1.127-0.063l1.242,0.031l0.249,0.201l0.963,0.211l0.502,0.053h0.75l4.879-3.774
		l0.806-1.944l-0.498-0.624l1.433-0.941l4.283-2.072l0.285,0.043l0.199,0.084l1.286,0.729l-0.127,0.856l-0.608,1.417l0.144,4.673
		l0.75,0.889l1.597,2.167l-0.228,0.666l-0.042,0.105l-1.253,2.664l-2.056,1.66l-0.651,0.634l-0.296,1.185l0.064,2.083l0.85,2.422
		l0.213,0.2l3.495,2.443l0.379,0.083l0.476-0.063l-0.137,0.624l0.243,0.792l3.732,4.008l0.539,1.036l-0.147,0.011l-0.19,0.095
		l-2.221,1.216l-0.37,0.201l-0.248,0.254l-0.191,0.275l-0.603,1.489l-0.242,0.255l-0.187,0.127l-0.368,0.053l-3.21-0.138
		l-0.382-0.084l-0.443-0.297l-2.543-2.399l-1.76-2.01l-0.37-0.507l-0.514-0.581l-1.761-1.047l-0.553,0.74l-0.604,1.3l-0.18,0.909
		l-2.818,2.073l-0.423,0.031l-1.581-1.322l-2.114-2.009l-0.745-0.919l-0.328-0.224l-0.254,0.16l-1.284,1.428l-0.83,0.623
		l-0.254,0.116l-3.41-3.511l-0.074-0.592l-0.957-2.23l-0.83-0.856l0.307-2.221v-2.822L250.76,155.304z"
        />
        <path
          id="Bremen"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M178.358,158.232l0.574-0.37l0.181-0.456l0.074-0.263
		l-0.043-0.783l-0.064-0.338l-0.125-0.307l-2.146-3.774l-0.862-1.343l0.968-1.184l0.925-0.074l5.393,0.274l1.682,0.265l0.063,0.095
		l-1.073,4.969l0.899,3.882l-0.096,0.518l-2.558,2.706l-0.18,0.011l-1.825-0.201l-0.321-0.2l-0.17-0.232l0.369-0.528l0.063-0.191
		v-0.19l-1.369-1.818L178.358,158.232z M204.24,198.642l-1.378-1.671l-0.684-0.486l-0.316-0.011l-1.147,0.729l-4.706-0.138
		l-3.769-1.913l-4.229-2.072l-6.478-0.771l-7.513-4.05l-0.275-0.867l-0.01-0.677l-0.021,0.729l0.223,1.312l0.396,0.856l0.316,0.561
		l0.735,1.078l1.55,1.248l2.378,1.141l0.798,0.34l0.911,0.126l0.767,0.201l0.359,0.307l0.253,0.507l1.576,4.197l0.147,1.322
		l0.915,0.624l1.961,2.114l0.492,0.677l0.063,0.635l0.063,2.611l0.487,0.507l0.544,0.466l0.306,0.074l1.054-0.36l0.37-0.148
		l1.348,0.011l5.345,1.851l3.109,1.217l3.251-2.781l0.053-1.967l-0.381-4.769l-0.787-1.226l0.084-0.128l0.138-0.073l0.968-0.359
		l1.01-0.381L204.24,198.642z"
        />
        <path
          id="Brandenburg"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M538.222,170.708l-0.212,2.051l1.063,3.289
		l0.825-0.021l0.649,0.127l0.328,0.919l0.074,0.497l-0.978,2.157l-2.432,3.679l-0.519,0.244l-0.169,0.148l-0.333,2.103l-0.147,0.963
		l0.011,0.243l0.27,1.058l0.127,0.401l1.041,1.354l-0.259,2.559l-0.075,0.528l-0.549,3.193l-0.672,2.263l-0.583,0.982l-0.252,0.317
		l-3.263,3.415l-1.222,1.175l-1.682,1.426l-0.939,0.498l-0.799,0.263l-2.126,1.438l-1.464,1.396l-0.273,0.919l0.031,0.158
		l0.285,1.651l1.094,3.921l0.349,0.709l-0.105,1.311l-0.745,1.228l-0.793,1.088l-0.472,1.11l0.032,0.645l0.063,0.18l0.354,0.636
		l0.168,0.18l0.688,0.307l1.839,0.147l3.749,1.49l7.809,5.075l1.527,1.459l1.603,1.86l1.702,2.031l0.074,0.095l0.243,0.401
		l0.18,0.698l2.094,1.998l0.978,0.665l1.824,1.206l0.821,0.412l1.432,0.709l0.861,0.434l0.308,0.105l0.49,0.095l0.317,0.17
		l1.729,1.174l0.428,0.465l2.337,2.992l0.158,0.296l0.212,7.781l-0.138,0.666l-0.139,0.265l-1.724,3.013l-0.75,1.015l-1.574,1.1
		l-0.285,0.201l-0.317,0.37l-0.202,0.454l-0.104,0.509l-0.365,2.294l-0.084,0.762l1.041,1.606l0.264,0.551l0.345,2.674l1.496,5.614
		l0.072,0.254l0.191,0.222l0.946,1.058l0.2,0.19l0.413,0.201l0.734-0.043l0.317,0.064l4.716,1.184l0.096,0.042l0.291,0.222
		l0.211,0.275l0.989,1.385l0.116,0.19l0.021,0.232l-0.221,6.407l-0.054,0.444l-0.471,0.92l-0.819,1.035l-0.137,0.266l-0.012,0.349
		l0.301,2.569l0.402,0.834l0.317,0.445l0.502,0.518l0.223,0.148l0.729,0.063l0.619,0.189l0.423,0.223l0.598,0.433l0.412,0.413
		l0.947,1.523l0.031,0.316l-0.433,3.288l-0.055,0.127l-0.354,0.729l-1.422,2.411l0.127,0.074l0.275,0.603l-0.179,5.995l-0.969,3.393
		l-2.938,4.028l-1.157,0.974l-1.697,1.755l-0.355,0.402v0.073l0.031,1.173l0.053,0.52l0.821,1.258l0.2,0.307l0.169,0.116
		l1.021,0.296l2.851,6.291l0.496,1.11l1.538,1.712l2.263,1.703l0.339,0.222l0.692,0.561l0.555,0.655l0.677,1.407l0.502,4.218
		l-0.798,2.696l-0.894,1.364l-0.979-0.794l-0.823,0.762l-0.651,2.347l-0.105,0.265l-0.158,0.232l-0.472,0.55l-0.169,0.063
		l-3.192,0.316l-0.534-0.073l-0.423-0.096l-0.147-0.169l-0.439-0.75l-0.147-0.666l-2.21-0.856l-6.947,2.463l-0.36,0.254l-0.126,0.36
		l0.011,0.38l0.084,0.539l-0.328-0.18l-3.44,0.444l-4.347,2.452l-2.93-1.015l-4.017-0.518l-3.066,0.096l-1.295-0.212l-0.396,0.063
		l-2.122,3.076l-1.511,4.44l-1.422,5.52l-2.666,3.499l-7.521,1.543l-0.328,0.054l-2.791-0.085l-1.582,0.593l-2.052,1.015l-0.84,0.57
		l-3.103-0.38l-0.508-0.106l-0.83-0.454l-0.339-0.433l-0.464,0.676l-3.316,0.095l-1.987,0.054l-0.35-0.043l-2.901-0.866
		l-4.916-3.014l-2.147-1.745l-2.311-2.051l-0.587-0.454l-4.536,2.431l-2.621,2.327l0.009,0.148l0.107,1.067l-1.709,0.477
		l-0.513,0.125l-2.283-0.052l-0.212-0.402l-0.222-0.634l-1.528-1.829l0.771-3.69l0.223-5.212l-0.158-0.676l-0.223-0.678
		l-1.126-2.748l-1.866-4.092l-0.449-0.815l-2.644-1.417l-0.19,0.308l-0.327,0.317l-0.736,0.476l-0.576-0.317l-2.41-3.88l6.45-4.282
		l0.518-0.561l1.396-2.516v-0.138l-0.037-0.477l-0.466-0.941l-0.273-0.252l-0.481-0.435l-3.32-11.704l-0.011-0.253l-0.012-0.222
		l1.74-0.276l-0.021-0.623l-0.137-0.613l-0.725-0.719l-0.413-0.265l-0.905-0.042l0.065,0.665l-0.339,0.213l-2.052,0.834
		l-2.357-0.211l-0.158-0.021l-0.297-0.189l-3.029-3.131l-4.875-2.59l-1.063,0.381l-0.518-0.021l-2.421-0.612l-4.928-2.835
		l-0.481-1.437l-0.348-0.021l-1.81-0.94l-1.601-0.899l-0.693-0.443l-1.994-0.921l-0.924,0.011l-0.444,0.592l-0.158,1.354
		l-1.818,1.29l-0.768,0.127l-3.959-0.603l-7.212-2.801l-4.59-2.855l-1.665-1.882l-2.648-3.446l-2.088-3.215l-2.924-3.753l1.83-4.145
		l-0.232-4.028l0.274-2.474l0.127-0.476l0.571-1.787l1.168-2.57l0.106-0.169l0.247-0.116l1.189-2.399l-0.649-3.427l-0.402-2.854
		l-0.19-1.65l0.868-2.156l0.575-1.025l0.308-0.931l-0.371-3.267l-5.545-1.924l-0.18,0.94l-0.043,0.147l-0.317,0.467l-1.115,0.327
		l-1.178,0.19l-0.864-1.026l-1.828-2.167l-0.042-0.043l0.042-0.75l1.776-7.116l0.904-1.627l0.412,0.116l0.423,0.021l1.628-1.194
		l0.413-3.68l-0.021-0.581l-0.275-0.888l-1.189-1.788l-1.242-2.029l-0.031-0.105l0.423-4.441l0.349-1.142l0.671-1.29l1.467-0.729
		l0.199-1.808l-1.231-4.896l-0.096-0.273l-0.624-0.413l-1.48-0.666l-1.549,0.698l-0.914,0.477l-1.644,0.549l-0.841-1.987
		l0.031-0.678l0.248-0.645l0.138-0.169l-0.745,0.688l-8.222,0.973l-0.285-0.053l-5.218-1.682l-3.59-1.459l-0.35-0.18l-0.221-0.222
		l-0.255-0.497l-0.063-0.486l0.053-0.2l0.19-0.402l0.518-0.55l0.534-0.454l0.138-0.19l0.096-0.275l0.021-0.243l-0.032-0.275
		l-0.096-0.265l-0.242-0.306l-5.177-3.003l-0.222-0.117l-0.381-0.178l-0.396-0.139l-0.54-0.085l-0.169,0.032l-0.988,0.582
		l-0.745,0.518l-0.307,0.085l-0.528-0.096l-0.218-0.137l-3.568-2.844l-0.243-0.255l-0.138-0.285l-0.053-0.381l0.053-0.307
		l0.861-1.151l0.105-0.17l0.021-0.201l-0.073-0.243l-0.212-0.18l-0.232-0.127l-0.206-0.053l-0.486-0.042l-0.254,0.042l-1.4,0.571
		l-0.599-0.021l-4.388-1.058l-0.634-0.189l-0.565-0.402l-1.159-1.226l-0.708-0.794l-0.365-0.285l-0.412-0.201l-0.518-0.042
		l-0.408,0.042l-2.035,0.57l-0.386,0.106l-0.274,0.159l-1.031,0.793l-1.696,0.908l-0.459,0.011l-0.233-0.042l-0.285-0.159
		l-2.988-3.065l-1.147-1.385l-0.074-0.201l-0.032-0.327l-0.03-1.46l5.62,0.328l2.759-2.179l3.377-0.401l0.8,0.592l0.031,0.255
		l0.64,0.296l1.634,0.296l1.539,0.053l0.861-0.604l0.328-0.982l0.641-2.537l-0.683-4.092l3.875-3.531l0.565-0.434l2.737-0.042
		l1.022,0.073l1.702,0.963l0.697,1.458l0.63-0.105l5.529-1.237l0.212-0.232l0.025-0.116l-0.133-0.349l-1.104-1.861l3.209-0.232
		l5.011-1.829l0.388-0.243l3.746-2.675l0.371-0.497l-0.158-1.775l2.072-2.961l1.54,0.285l1.701,0.232l1.485-0.539l5.529-1.132
		l0.334-0.02l3.865,2.96l1.988,0.603l0.549,0.369l0.607,0.171l0.741,0.349l1.86,1.004l0.979,0.793l0.506,1.427l2.476,2.792
		l3.252-0.392l0.201-0.212l-0.043-0.518l1.766-0.381l7.678,0.719l0.776,0.138l3.388,3.161l2.465,2.559l0.38,0.688l1.167,0.127
		l0.832-0.306l1.157-0.445l0.022-0.147l0.083-0.063l1.783-0.582l7.285-0.2l0.681,0.2l0.044,0.043l0.21,0.518l0.021,0.158
		l-1.042,0.624l-0.93,0.73l-0.27,0.411l0.01,0.064l0.148,0.126l2.072,0.888l0.158-0.02l0.302-0.16l7.011-7.644l0.434-0.401
		l2.263-1.1l0.18-0.032l1.934-0.253l0.307,0.57l0.233,0.634l0.818,1.756l0.18,0.2l1.486,0.224l0.493-0.868l0.127-0.676l-0.011-0.508
		l0.104-1.227l0.191-0.75l0.359-0.509l1.817-1.574l1.096-0.519l3.515,0.054l0.212,0.115l0.392,0.539l0.063,0.201l0.126,0.888
		l1.084,1.629l0.36,0.105l0.703-0.307l4.737-4.98l2.137-2.875l0.063-0.096l-0.011-0.116l-0.296-1.808l-0.249-1.301l0.472-1.606
		l1.178-2.834l0.211-0.497l6.589-6.322l0.951-0.857l1.744-1.384l2.686-0.201l2.75-2.432l-1.37-1.237l-0.158-1.385l-0.011-0.328
		l0.254-1.935l0.073-0.317l0.365-0.634l0.518-0.127l5.002,5.794l0.969,3.309l1.475,3.15l1.617,0.656l0.581-0.254l3.399-0.602
		l2.687,0.421l3.881-0.126l0.718-1.396l6.334,0.392l0.73,0.486l0.418,0.688l0.243,2.844l-0.021,0.529l-0.534,1.967l-0.138,0.475
		l-0.423,0.983l-1.01,1.649l-0.787,1.046l-1.08,1.291l-1.009,1.067l-0.735,0.666l-1.57,1.903l-0.48,0.803l-0.106,0.476l-0.18,1.217
		l1.105,0.127l5.832,0.2l2.505-0.401l0.811-0.264l2.023-2.833l0.618-1.798l0.158-0.931l5.024-2.115l0.312-0.021L538.222,170.708z
		 M479.231,243.809l-0.623,1.057l-0.662,0.857l-0.464,0.358l-0.388,0.212l-0.434,0.138l-0.724-0.021l-0.909-0.053l-1.148-0.232
		l-5.598,0.169l-1.126,2.23l-0.031,0.106l0.137,0.306l0.519,0.604l0.364,0.285l0.105,0.148l0.021,0.136l-0.063,0.382l-1.094-0.084
		l-0.503-0.096l-1.243-0.539l-0.496-0.286l-0.52,0.147l-1.669,1.122l-0.043,0.401l-0.423,6.153l0.052,0.612l1.624-0.316l1.576,1.121
		l-2.243,2.928l-0.417,0.307l-0.455,0.678l-0.284,0.55v1.522l0.454,0.528l0.4,0.697l-0.601,1.047l-0.503,0.508l-0.993,0.54
		l0.169,0.771l0.232,0.19l2.062,1.289l1.974,0.128l0.649-0.063l-0.546-0.117l0.086-0.645l2.22-1.396l1.577-0.593l0.21-0.053
		l1.189-0.085l3.368,0.72l0.988,1.3l1.898-0.952l2.945,2.887l2.003,0.148l-0.232-3.225l0.031-0.169l0.096-0.096l1.638-0.771
		l0.72-0.327l0.523-0.159l0.75,1.851l3.786,1.206l7.058,1.998l1.259,0.263l1.591-0.549l0.391-0.697l0.053-0.359l-0.147-0.349
		l-0.243-0.011l-0.523-0.16l0.011-0.253l1.809-1.512l0.682-1.713l0.814-2.474l-1.539-1.597l-0.894-0.063l-2.537-1.66l-1.612-0.465
		l-0.756-3.151l-1.602-3.5l-3.559-3.763l-4.663-8.691l-0.882-0.466l-0.8,0.613l-0.581,0.794l-0.608,1.417l-0.434,1.184l-0.587,0.18
		l-1.067-0.582L479.231,243.809z"
        />
        <path
          id="Berlin"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M479.231,243.809l0.545,0.476l1.067,0.581l0.587-0.18
		l0.434-1.184l0.607-1.417l0.581-0.793l0.8-0.613l0.883,0.466l4.662,8.69l3.559,3.765l1.602,3.498l0.756,3.151l1.612,0.465
		l2.537,1.66l0.894,0.063l1.539,1.596l-0.813,2.475l-0.683,1.712l-1.809,1.513l-0.011,0.254l0.523,0.158l0.244,0.011l0.147,0.35
		l-0.052,0.359l-0.394,0.697l-1.585,0.55l-1.264-0.264l-7.052-1.999l-3.79-1.205l-0.753-1.85l-0.522,0.158l-0.719,0.327
		l-1.639,0.771l-0.095,0.096l-0.032,0.17l0.232,3.224l-2.004-0.147l-2.944-2.886l-1.898,0.951l-0.989-1.301l-3.367-0.719
		l-1.189,0.084l-0.21,0.054l-1.575,0.592l-2.222,1.396l-0.084,0.645l0.544,0.117l-0.649,0.063l-1.973-0.127l-2.062-1.29l-0.233-0.19
		l-0.17-0.772l0.994-0.539l0.503-0.507l0.604-1.048l-0.402-0.697l-0.455-0.528v-1.522l0.284-0.55l0.455-0.676l0.419-0.308
		l2.247-2.929l-1.582-1.121l-1.622,0.317l-0.054-0.613l0.423-6.153l0.043-0.401l1.671-1.12l0.518-0.148l0.498,0.285l1.243,0.539
		l0.501,0.096l1.095,0.084l0.063-0.38l-0.021-0.138l-0.104-0.148l-0.365-0.286l-0.518-0.602l-0.138-0.307l0.031-0.105l1.126-2.231
		l5.6-0.169l1.146,0.232l0.908,0.053l0.725,0.021l0.434-0.138l0.387-0.211l0.465-0.359l0.662-0.857L479.231,243.809z"
        />
        <path
          id="Bayern"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M393.093,474.993l0.924,0.189l0.684,0.634l2.56,2.644
		l-0.043,0.425l-0.223,1.711l-0.312,1.757l0.408,0.295l1.157,0.191l4.467,1.319l1.987,0.752l0.459,0.646l1.498,1.469l1.2,2.652
		l-0.063,0.181l-0.053,0.105l-0.735,0.57l-0.946,0.232l-0.413,0.424l-0.968,1.185l0.149,0.306l1.272,1.144l3.274,1.744l0.254,0.21
		l1.125,1.195l1.401,1.712l1.01,2.084l-0.064,0.371l-0.201,0.592l-0.675,0.665l-0.323,0.983l-0.021,0.506l0.19,1.555l0.248,1.249
		l0.086,0.189l0.549,0.624l0.085,0.052l0.703-0.219l0.507,0.138l1.02,0.781l1.539,1.596l-0.316,2.063l3.855,0.603l3.313,3.363
		l4.112,2.42l1.677,0.042l0.988,0.157l1.423,0.782l0.63,0.911l2.801,4.831l-0.063,3.489l-0.297,0.263l-0.586,0.698l-2.231,4.177
		l-0.592,1.744l-0.306,3.363l-0.153,0.675l-4.257,2.95l-0.043,0.275l0.043,0.244l0.37,1.362l0.46,0.751l1.898,2.663l0.178,0.203
		l0.212,0.127l0.482,0.127l4.462,1.278l0.17,0.074l0.401,1.649l0.778,5.33l2.747,5.93l0.957,1.376l0.412,0.338l-0.338,0.391
		l0.937,0.591l1.243,0.446l0.538,0.054l0.128-0.425l0.105-0.095l0.301,0.02l0.307,0.466l0.085,0.213l0.148,1.384l0.095,2.125v0.563
		l-0.042,0.116l-0.614,0.803l1.729,2.579l6.84,5.424l0.307,1.271l-0.18,0.749l1.496,2.75l0.483,0.251l1.125,0.352l5.021,0.738
		l0.667-0.009l0.133-0.095l-0.196-0.573l-0.37-0.454l-0.084-0.708l0.575-0.073l0.846,0.095l2.956,0.487l0.316,0.072l1.55,0.868
		l2.052,1.922l2.029,2.645l5.842,7.781l0.879,1.196l1.66,1.49l0.926,0.74l0.253,0.125l0.35,0.013l0.072,2.99l0.904,0.934
		l1.073,0.749l0.852,0.497l1.738,0.708l0.63-0.188l0.698-0.287l1.358-0.338l0.406-0.021l0.477,0.2l2.464,1.755l1.38,1.089
		l3.198,3.553l0.57,0.868l0.661,2.271l0.999,4.284l1.085,0.739l4.388,2.93l0.202,0.03l0.645-0.126l-0.033-0.897l-0.137-0.423
		l-0.085-0.53l0.042-0.908l1.475-0.572l3.442,0.148l0.296,0.179l0.175,0.159l2.528,2.79l1.052,1.344l0.75,1.735l0.661,1.46
		l1.146,1.319l0.35,0.138l0.872-0.021l1.856-0.073l0.311-0.159l0.401-0.402l0.603,0.014l5.781,8.521l1.295,2.663l-0.582,2.231
		l-0.683,2.135l-0.898,1.608l1.306,2.273l1.178,5.719l0.033,1.628l-1.327,4.536l-4.589,6.462l-0.212,0.167l-1.438-0.358
		l-2.906-1.513l-0.266-0.221l-5.018-3.161l-5.36-2.032l-0.37,1.619l-0.174,0.327l-0.772,0.857l-1.084,0.908l-0.486,0.231
		l-1.986,0.637l0.761,0.919l0.063,0.105l0.692,2.685l-0.042,0.202l-1.254,3.891l-0.264,0.519l-0.275,0.993l-0.011,0.38l0.043,0.182
		l0.772,1.246l0.137,0.465l0.021,0.295l-0.085,0.382l-1.273,4.98l-0.191,0.486l-0.158,0.265l-0.265,0.273l-5.06,5.215l-0.539,0.422
		l-3.938,2.622l-0.363,0.2l-0.468,0.085l-4.969,0.063l-2.807,1.585l-0.509,0.211l-0.745,0.138l-1.222-0.03l-0.603-0.021
		l-0.566,0.128l-3.927,2.124l-0.339,0.233l-0.322,0.338l-0.18,0.295l-0.329,0.74l-0.211,0.382l-2.315,2.697l-0.358,0.314
		l-0.366,0.245l-0.423,0.189l-0.327,0.072l-1.851,0.096l-2.854,0.478l-0.208,0.063l-0.284,0.2l-0.222,0.306l-0.043,0.192
		l-0.043,0.687l-0.084,0.286l-0.957,2.052l-0.883,1.098l-0.179,0.212l-2.168,1.702l-2.59,2.137l-0.509,0.643l-0.116,0.17
		l-0.021,0.276l0.276,1.841l0.212,0.779l0.315,0.7l0.64,0.824l2.866,2.38l2.009,1.648l0.809,0.835l0.412,0.52l0.286,0.518
		l0.073,0.253l0.914,3.637l0.234,0.454l0.26,0.254l2.399,0.444l0.487,0.21l0.221,0.191l0.925,1.312l2.253,3.542l2.813,5.054
		l0.19,0.465l0.064,0.456l-0.086,0.316l-2.58,4.588l-3.737,7.465l-0.011,0.191l0.074,0.212l0.549,0.603l0.863,0.633l3.346,0.254
		l0.967-0.073l0.286-0.139l0.714-0.507l0.158-0.201l0.074-0.17l-0.074-0.115l0.232-0.443l0.158-0.065l0.492-0.041l1.974,0.962
		l0.274,0.201l1.47,1.881l2.146,5.256l0.115,0.454l-0.177,0.751l-0.302,0.328l-1.287,1.512l-1.844,2.876l-0.101,0.697l-0.561,4.452
		l0.021,0.146l0.137,0.181l0.308,0.263l0.333,0.108l0.296,0.241l0.085,0.254l0.147,2.284l-0.011,0.73l-2.728,2.971l-0.201,0.105
		l-0.18-0.054l-2.638-1.068l-0.805-0.369l-2.944-1.64l-7.676-5.221l-0.683-0.668l-0.767-1.205l-0.349-1.025l0.115-0.359l0.725-1.903
		l0.968-1.035l-2.68-5.572l-1.475-0.443l-3.854-1.067h-0.812l-0.423,0.029l-0.819,0.181l-3.927,0.857l-0.307,0.084l-0.524,0.739
		l-0.903,1.935l-4.832,2.466l-0.921,0.052l-2.426-2.455l-1.802-2.441l-0.117-0.254l-5.239-0.57l-1.794-0.665l-0.221-0.169
		l0.158-0.456l-4.79,1.809l-1.156-3.563l0.359-0.635l0.448-1.098l-0.142-0.456l-0.445-0.604l-0.507,0.182l-4.304,4.207l-0.137,3.469
		l1.157,2.527l0.296,1.015l0.492,1.945l0.011,0.211l-0.059,0.254l-0.127,0.201l-0.159,0.115l-1.368,0.309l-3.729-0.095l-3.251-0.329
		l-1.538-0.889l-2.827,0.794l-3.591-0.443l-3.896,0.518l-3.221,1.247l-1.178,2.039l-0.211,0.13l-1.021-0.076l-2.792-0.505
		l-9.748-0.044l-1.159-0.202l-1.775,1.144l-0.117,0.148l-1.136,2.472l-0.117,1.121l-0.009,0.223l0.021,0.191l0.116,0.265
		l0.053,1.403l-1.074,1.144l-0.242,0.105l-4.382,1.004l-3.453-0.328l-0.662-0.189l-0.476-0.592l-0.238,0.105l-0.242,0.181
		l-1.369,1.576l-0.169,0.379l-1.708,1.954l-5.462,4.854l-0.565,0.995l-0.169,0.381l-0.327,0.992l-0.402,0.509l-0.789,0.465
		l-0.199,0.065l-2.901,0.297l-0.244-0.022l-0.169-0.202l0.104-0.433l1.095-1.893l0.904-0.846l-0.19-0.39l-0.147-0.098l-3.029-0.075
		l-3.421,1.344l-0.36,0.191l-1.296,0.738l-3.451,1.725l-5.302,0.266l-0.667-0.044l-1.19-0.232l-0.629-0.297l-0.126-1.215
		l0.147-0.476l0.085-0.104l0.586-0.108l0.286-0.252l-0.106-0.932l-1.601-1.869l-2.295-2.382l-3.263-0.697l-0.221-0.021l-0.492-0.241
		l-0.138-0.402l0.054-1.363l0.449-0.443l1.962-1.492l-1.359-1.979l-2.199,0.255l-3.887,1.523l-2.358-0.456l-4.884-1.978
		l-0.211-0.633l-0.032-0.318l0.063-0.188l-0.085-0.266l-5.07-0.836l-1.221-0.104l-0.804,0.94l-0.46,0.93l-0.011,0.17l-0.592,1.469
		l-0.296,0.033l-3.104-0.138l-2.454-0.485l-1.966-1.259l-0.467-0.339l0.435-0.646l0.629-0.762l0.222-1.015l-0.042-0.803h-0.264
		l-2.188,0.338l-0.137,0.043l-0.388,0.657l0.128,7.569l0.66,1.753l0.159,0.181l0.201,0.065l0.417-0.161l0.655,0.317l0.481,0.549
		l0.072,0.773l0.34,3.9l-3.991,5.359l-4.849,6.196l-1.507,1.449l-2.96,1.841l-2.755,1.015l-4.468,0.802l-0.29-0.008l-0.117-0.393
		l-0.03-0.508l0.231-0.908l0.238-0.772l2.623-6.07l0.645-0.813l0.734-1.046l0.043-0.106l-0.063-0.856l-0.486-0.654l-0.154-0.055
		l-3.161-0.349l-0.862,0.668l-0.031,1.09l0.042,0.39l-0.265,0.253l-4.589,1.458l-1.569-5.031l0.549-1.078l0.598-0.255l0.116-0.095
		l0.699-1.11l-0.91-1.563l-0.862-1.163l-1.913-2.167l-2.062-0.836l-0.879,0.244l-0.523,0.01l-0.137-0.054l-0.402-0.413l-2.104-3.594
		l-0.011-1.078l0.022-0.666l0.189-0.815l-0.223,0.202l-2.189,1.194l-0.75,0.308l-3.356,0.104l-4.07-1.944l-0.043-0.214l0.148-0.378
		l0.127-0.106l0.143-0.042l0.18-0.233l0.168-0.316l0.106-1.893l-0.264-0.846l-1.47-0.9l-0.148-0.03l-1.623,0.074l-0.811,0.624
		l-0.644,0.93l-2.496,3.437l-3.806-1.195l-3.192-1.321l-0.883-0.57l-0.319-0.847l2.654-2.009l1.243-0.844l2.357,0.189l0.053,0.37
		l0.107,0.22l0.253,0.191l0.417,0.138l3.12-0.148l5.513-5.603l0.075-0.116l4.778-0.268l5.462-0.081l0.296-0.52l1.052-1.311
		l0.243-0.243l0.159-0.084l1.338-0.224l2.591,0.499l0.026,0.03l0.327,0.57l0.032,0.106l0.872,3.552l-0.068-0.306l0.417-1.154
		l0.381-0.431l0.618-0.794l0.444-0.254l0.703,0.148h0.19l1.105-1.025l0.676-2.243l0.01-0.603l-2.548-6.234l-0.053-3.704l1.011-0.021
		l1.327-1.193l-0.275-0.783l-0.449-0.295l-0.518-0.645l-0.529-1.089l-0.471-2.517l-0.148-3.479l-0.011-0.402l0.022-0.35l0.21-1.795
		l0.377-1.428l-0.799-0.519l-0.339-0.275l-0.248-0.273l-0.211-0.403l-0.147-0.611l0.041-0.572l0.117-0.465l0.138-0.243l0.169-0.18
		l0.258-0.159l0.719-0.159l0.662-0.138l0.063-1.033l0.244-1.862l0.401-0.679l0.556-0.56l0.158-0.676l0.264-2.42l-0.053-2.972
		l-0.18-3.405l-0.819-0.749l-0.856-0.922l-0.085-0.136l-0.249-0.646l-0.866-2.801v-0.486l-0.302-0.668l-0.338-1.752l-0.148-0.795
		l-1.221-8.849l-0.968-0.93l-2.864-4.494l-0.811-1.523l-0.39-1.999l1.507-3.539l0.137-0.213l1.231-2.241l0.286-1.714l-0.159-0.707
		l-0.316-0.478l1.592-0.125l3.303-1.882l0.946,0.728l1.148,1.355l2.294-0.487l4.748-4.175l-0.201-0.562l-0.238-0.506l1.555-0.816
		l0.244-0.104l0.485-0.065l0.936,0.414h0.577l0.654-0.085l1.073-0.624l0.085-3.911l-0.053-0.604l-0.968-5.739l-1.305-1.798
		l-1.18-1.649l-0.571-0.803l0.053-1.343l0.053-0.223l0.852-2.444l4.045,1.279l0.618,0.203l4.778-1.036l1.613-0.084l0.349,0.255
		l0.139,0.179l0.053,0.306l-0.994,2.168l-0.429,0.306l-0.104,0.189l0.052,0.245l0.032,0.021l1.009-0.044l2.18-0.696l1.464-1.619
		l0.338-0.433l-0.316-0.635l-0.224,0.013l-0.628-0.617l-0.677-2.567l-0.064-0.855l-2.537-0.834l-0.206,0.021l-0.264-3.279
		l0.692-0.295l1.21-3.141l0.021-0.063l0.349-5.14l0.128-6.164l-0.382-1.871l-0.486-0.624l-0.09-0.116l-1.295-1.342l-0.719-1.968
		l-0.149-0.349l-6.11-4.452l-4.183-1.774l-0.158-1.038l0.011-0.697h0.147l0.424-0.2l-1.327-6.672l-2.072-3.753l-1.719-0.72
		l-1.497-0.781l-2.589-1.766l-0.291-0.348l-0.032-0.17l0.212-6.229l0.956-2.704l-0.539-0.857l-1.966-4.335l-0.097-0.338
		l-0.041-0.287l0.296-3.53l0.317-0.625l2.283-0.073l0.683-0.306l0.274-0.465l0.074-0.212l-0.624-1.691l-1.138-2.285l-1.179-3.92
		l-0.147-0.057l-1.009-1.319l-0.054-0.624l0.042-0.189l0.222-0.476l0.393-0.371l-0.286-1.352l-0.81-1.787l-0.666-1.111l-1.126-0.211
		l-0.338,0.095l-1.522,1.446l-0.053,0.087l0.244,0.327l0.659,0.592l0.629,0.148l-0.533,0.93l-0.19,0.212l-2.157,2.411l-0.358,0.127
		l-4.051,0.252l-1.527-0.357l-0.191-0.107l-0.808-0.813l0.137-0.677l0.408-0.432l0.169-0.393l0.03-0.116l0.158-4.196l-0.158-1.09
		l-0.03-0.116l-0.694-2.23l-0.116-0.275l-0.264-0.064l-0.465,0.043l-0.291,0.052l-1.717-2.704l-0.233-0.994l-2.369-8.363
		l-0.693-0.71l-1.707-1.689l-1.104,0.38l-4.208,1.447l-2.654,1.511l-0.883,0.425l-0.539,0.076l-1.444-0.785l-0.501-0.729
		l0.379-1.384l0.111-0.087l0.551-0.273l0.063-0.308l0.63-5.983l-0.143-0.983l-1.223,0.572l-4.267,4.134l-0.137,0.085l-0.231-0.011
		l-0.26-0.117l-2.569-1.671l-0.285-0.243l-0.233-0.402l-0.063-0.211l0.041-0.379l0.17-0.582l0.011-0.267l-0.084-0.198l-0.28-0.267
		l-0.224-0.189l-0.232-0.065l-1.295,0.138l-0.18,0.16l-0.401,0.529l-0.662,1.047l-1.241-0.508l-5.567,0.264l-2.252,0.255
		l-0.434,0.054l-0.265,0.116l-0.127,0.241v0.573l0.477,1.859l1.507,2.537l0.883,1.343l0.618,0.086l0.729-0.075l0.317-0.084
		l0.042-1.628l-0.148-0.529l0.354-0.572l0.909,0.231l1.032,1.757l0.438,1.693l-0.757,3.307l0.529,0.5l-0.211,0.537l-0.678,0.836
		l-1.48,0.275l-2.331-0.74l-2.977-0.443l-1.264,1.827l-0.692,1.122l0.565,0.803l0.074,0.233l-2.421,3.076l-0.576,0.327l-1.179,0.359
		l-0.604,0.138l-1.295,0.244l-4.948-0.021l-0.197-0.03l-0.971-0.384l-0.894-1.636l0.818-6.969l1.358-3.724l0.498-0.24l1.623-4.315
		l-1.18-5.308l-0.18-0.126l-0.232-0.074l-0.777-0.17l-0.392-0.021l-4.356-4.356l-0.264-0.284l-0.095-0.392l-0.117-1.438
		l-0.031-0.635l0.211-0.054l0.915,0.107l-0.714-0.941l-0.105-0.234l-0.38-3.045l-0.096-2.06l-0.147-2.854l-0.377-3.026l-0.041-0.219
		l-0.349-0.478l1.579-0.887l-0.358-3.892l-0.138-0.275l-0.316-0.273l-0.139-0.064l-0.513-0.159l-0.581-0.021l-0.302,0.064
		l-1.295-1.119l0.649-1.313l0.466-2.484l0.734-1.185l0.519-0.611l0.127-0.095l13.471-3.703l9.696,1.895l0.307,0.083l0.106,0.128
		l1.859,4.315l0.54,0.432h0.195l1.348-0.148l5.108-0.92l0.401-1.184v-0.803l-0.433-1.038l-0.244-1.226l-0.671-4.06l0.354-3.415
		l1.189-2.528l1.09,0.371l3.05,0.781l0.777-0.021l1.591-0.104l0.128-0.107l0.074-0.422l0.936-2.379l0.692-1.279l0.328-0.541
		l0.126-0.167l1.708-0.614h0.682l0.096-0.031l0.724-0.284l1.612-1.46l0.768-0.763l0.434-0.801l0.115-0.392l0.085-1.111l-0.085-1.639
		l-0.253-0.562l-0.692-1.004l0.682-0.633l0.159-0.329l0.564-2.146l0.35-1.584l3.558-1.038l0.762,0.603l2.241,0.93l1.544-0.178
		l1.2-0.498l1.168-0.719l1.211-0.981l1.592-0.922l3.525-3.794l0.927-1.375l0.465-1.322l0.307-1.649l-0.043-0.761l0.281-0.085
		l0.368-0.18l0.99-0.983l1.813-2.009l6.642-0.709l0.538,0.053l1.841,2.411l1.453,1.968l4.673,1.955l0.545,0.443l0.507,0.529
		l2.433,5.15l0.393,2.42l0.02,0.582l0.535,0.751l0.169,0.02l1.85,0.202l0.931-0.423l0.884-0.445l0.254,1.015l0.302,1.184
		l2.283,2.498l4.003,1.5l3.044,0.698l-0.264,2.812l-0.339,3.573l0.66,2.875l0.424,1.165l0.19,0.443l1.829,0.445l5.234,1.923
		l0.274-0.04l0.19-0.087l0.449-0.762l-0.137-1.753l-0.147-0.065l-0.038-1.7l0.07-0.214l0.295-0.221l0.581,0.116l2.422,0.56
		l2.827,0.646l0.286,0.043l0.693-0.181l0.686-0.465l0.14-0.181l0.221-0.603l-0.052-1.374l-0.413-0.529l-0.592-0.306l-1.28-0.422
		l-0.623-0.148l-0.17-0.055l-5.502-3.888l-0.371-0.309l-0.084-0.37l-0.181-3.055l0.213-0.941l7.506-3.066l6.641-0.043h0.157
		l0.191,0.043l0.551,0.561l1.137,2.03l1.343,1.469l3.589,0.635l0.401,0.054l1.36-0.466l1.167-0.497l0.381-1.151l0.122-0.107
		l0.751-0.169l0.893,0.011l1.623,1.364l1.358,2.368l-0.042,0.613l-0.423,0.138l-0.562,0.411l-0.321,0.287l-0.422,0.476l-0.107,0.581
		l0.097,0.486l0.032,0.105l0.691,1.015l2.215,2.135l0.82-0.029l0.095-0.138l0.935-1.894l3.115-0.507l2.004-6.873v-0.063
		l-1.201-7.562l-0.295-0.835l-0.444-0.157l-0.692-0.477l-0.116-2.135v-0.2l0.148-0.625l0.222-0.254l0.428-0.136l0.455,0.136
		l0.095,0.16l1.518-0.7l2.199-1.774l0.222-0.751l1.084-1.152l0.169-0.064l1.575-0.211l3.475,0.668l0.265,0.219l0.327,0.478
		l0.566,0.823v0.15l-0.275,0.677l-0.069,0.104l-0.444-0.011l-0.443,0.655l-0.148,1.575l0.043,2.104l0.084,0.561l0.105,0.18
		l1.983,1.174l1.754-0.212l0.551,2.188l0.66,1.185l1.676,1.873l0.588,0.284l1.888-0.581l0.438-0.414l0.021-0.21l0.158-0.771
		l0.158-0.254l0.158-0.105l1.316-0.275l0.388,0.191l0.285,0.211l0.423,0.486l0.566,0.528l0.296,0.17h0.211l12.482-1.714l4.107-1.46
		L393.093,474.993z"
        />
        <path
          id="Baden_Württemberg"
          fill="#FFEC77"
          stroke="#284566"
          strokeWidth="0.5"
          d="M207.429,563.138l0.973,0.382
		l0.196,0.032l4.948,0.021l1.295-0.244l0.603-0.138l1.18-0.358l0.576-0.327l2.422-3.079l-0.074-0.232l-0.566-0.803l0.693-1.122
		l1.264-1.827l2.977,0.445l2.332,0.738l1.48-0.273l0.676-0.835l0.212-0.541l-0.53-0.497l0.757-3.31l-0.438-1.689l-1.031-1.757
		l-0.909-0.232l-0.354,0.572l0.147,0.528l-0.043,1.63l-0.316,0.081l-0.729,0.075l-0.619-0.084l-0.883-1.344l-1.506-2.535
		l-0.476-1.863v-0.57l0.126-0.243l0.264-0.116l0.435-0.055l2.252-0.253l5.567-0.263l1.242,0.507l0.66-1.046l0.402-0.529l0.18-0.159
		l1.296-0.138l0.231,0.064l0.223,0.189l0.28,0.265l0.084,0.201l-0.011,0.264l-0.169,0.581l-0.041,0.382l0.063,0.211l0.233,0.4
		l0.285,0.246l2.568,1.67l0.265,0.116l0.228,0.009l0.138-0.084l4.267-4.134l1.221-0.571l0.144,0.982l-0.63,5.985l-0.063,0.307
		l-0.55,0.275l-0.11,0.085l-0.381,1.386l0.502,0.729l1.442,0.781l0.54-0.076l0.883-0.422l2.654-1.511l4.208-1.449l1.105-0.381
		l1.708,1.692l0.691,0.708l2.369,8.365l0.232,0.992l1.719,2.706l0.29-0.051l0.466-0.044l0.264,0.064l0.116,0.274l0.692,2.232
		l0.032,0.116l0.158,1.087l-0.158,4.195l-0.032,0.117l-0.168,0.392l-0.407,0.436l-0.139,0.675l0.81,0.814l0.19,0.108l1.527,0.356
		l4.051-0.254l0.358-0.125l2.158-2.411l0.189-0.211l0.534-0.931l-0.629-0.15l-0.661-0.59l-0.243-0.327l0.053-0.086l1.523-1.446
		l0.338-0.098l1.126,0.214l0.666,1.108l0.809,1.786l0.286,1.354l-0.392,0.371l-0.222,0.474l-0.043,0.19l0.053,0.623l1.011,1.322
		l0.148,0.054l1.179,3.923l1.136,2.284l0.625,1.689l-0.075,0.214l-0.274,0.465l-0.681,0.306l-2.286,0.073l-0.317,0.624l-0.296,3.531
		l0.043,0.286l0.095,0.338l1.967,4.337l0.54,0.854l-0.957,2.707l-0.211,6.226l0.031,0.17l0.29,0.349l2.591,1.768l1.496,0.78
		l1.719,0.732l2.072,3.741l1.328,6.67l-0.424,0.203h-0.148l-0.011,0.696l0.159,1.037l4.182,1.775l6.112,4.453l0.147,0.356
		l0.719,1.957l1.296,1.342l0.09,0.118l0.487,0.623l0.379,1.872l-0.127,6.162l-0.349,5.14l-0.021,0.062l-1.21,3.143l-0.693,0.295
		l0.264,3.278l0.207-0.021l2.537,0.835l0.064,0.857l0.675,2.568l0.631,0.613l0.222-0.01l0.317,0.632l-0.34,0.436l-1.464,1.619
		l-2.178,0.697l-1.01,0.041l-0.031-0.02l-0.055-0.245l0.107-0.189l0.427-0.306l0.995-2.169l-0.054-0.308l-0.137-0.179l-0.349-0.254
		l-1.612,0.084l-4.779,1.038l-0.619-0.203l-4.045-1.278l-0.851,2.441l-0.053,0.225l-0.053,1.343l0.571,0.801l1.178,1.65l1.306,1.799
		l0.969,5.74l0.054,0.603l-0.086,3.912l-1.073,0.623l-0.656,0.085h-0.576l-0.935-0.413l-0.488,0.065l-0.242,0.105l-1.553,0.813
		l0.236,0.505l0.202,0.563l-4.749,4.176l-2.295,0.485l-1.146-1.352l-0.947-0.729l-3.304,1.882l-1.591,0.126l0.317,0.477l0.158,0.707
		l-0.285,1.715l-1.231,2.241l-0.139,0.211l-1.506,3.542l0.391,1.997l0.809,1.525l2.866,4.49l0.967,0.931l1.222,8.851l0.147,0.793
		l0.339,1.755l0.302,0.667v0.487l0.866,2.801l0.249,0.644l0.085,0.138l0.856,0.921l0.819,0.749l0.18,3.406l0.053,2.973l-0.265,2.419
		l-0.158,0.677l-0.555,0.559l-0.402,0.677l-0.243,1.873l-0.063,1.025l-0.661,0.138l-0.72,0.159l-0.26,0.159l-0.169,0.179
		l-0.138,0.243l-0.116,0.476l-0.042,0.561l0.147,0.613l0.212,0.399l0.249,0.276l0.338,0.275l0.799,0.518l-0.376,1.429l-0.211,1.796
		l-0.021,0.349l0.011,0.403l0.147,3.477l0.47,2.517l0.528,1.09l0.52,0.644l0.449,0.297l0.274,0.783l-1.326,1.194l-1.011,0.021
		l0.053,3.701l2.549,6.237l-0.011,0.603l-0.677,2.241l-1.104,1.027h-0.191l-0.703-0.149l-0.443,0.253l-0.65,0.753l-0.222,0.401
		l-0.423,1.151l0.073,0.295l-0.872-3.539l-0.031-0.108l-0.328-0.568l-0.031-0.034l-2.587-0.507l-1.337,0.233l-0.158,0.084
		l-0.243,0.243l-1.053,1.312l-0.295,0.509l-5.462,0.094l-4.778,0.265l-0.075,0.117l-5.514,5.604l-3.118,0.146l-0.418-0.138
		l-0.254-0.189l-0.106-0.222l-0.052-0.37l-2.359-0.192l-1.243,0.849l-2.654,2.007h-2.083l-0.839-0.084l-0.34-0.157l-0.316-0.181
		l-1.433-1.565l-0.799-1.893l-0.137-0.866l-0.387-0.783l-0.655-0.897l-0.587-0.476l-4.916-1.555l-0.968-0.276l-0.592-0.029
		l-0.412,0.052l-1.333,0.211l-0.243,0.043l-0.528,0.297l-0.512,0.157l-0.361-0.021l-0.265-0.117l-0.903-0.483l-2.019-1.103
		l-2.698-1.817l-1.03-0.919l-0.782-0.794l-0.523-0.888l-0.19-0.509l-0.138-0.856l0.097-0.179l-0.032-0.37l-0.054-0.105l-0.618-0.56
		l-7.645-5.034l-2.104-1.343l-0.264-0.117l-0.359-0.611l-0.291-0.224l-1.519-0.263l-0.39,0.625l-0.223,0.494l-0.011,0.13
		l0.138,0.241l2.475,2.57l0.761,0.611l0.555,0.392l3.283,2.241l0.127,0.032l0.518-0.085l0.318,0.034l1.66,1.383l2.579,5.446
		l0.401,1.595l-1.591,0.963l-1.359,0.222l-2.537-1.035l-2.294-1.228l-0.396-0.232l-0.488-0.146l-2.463-0.562l-0.443-0.073
		l-1.788-0.191l-0.284-0.009l-0.35,0.083l-1.18,0.465l-1.179,0.623l-0.428,0.298l-0.497,0.465L195,759.402l-1.243,0.611
		l-2.908,0.825h-0.205l-1.265-0.752l-4.588-3.996l-1.004,1.819l-0.279,0.041l-0.572-0.241l-3.833-1.662l-0.085-0.295l-1.01-3.563
		l0.243-0.865l0.645-0.72l-1.141-1.872l-1.618-1.417l-2.326-0.749l0.254,1.131l-0.371,0.635l-0.221,0.275l-0.926,0.611l-0.265-0.116
		l-0.564-0.857l-0.318-3.444l-2.813-0.561l-0.757,1.068l-0.423,1.215l-3.229,0.697l-1.486,0.265l-1.084,0.897l-1.157,1.492
		l-3.24,5.591l-0.486,2.021l0.021,0.148l0.422,0.729l3.961,2.865l0.216,0.096l3.908-1.608l-0.139-0.59l0.424-0.276l1.337-0.56
		l0.607-0.138l2.57-0.202l0.232,0.013l0.364,0.063l1.476,2.019l0.021,0.308l-0.396,0.909l-1.486,3.097l-0.883,0.942l-0.551,0.505
		l-1.041-0.17l-0.254-0.537l0.202-0.72l0.253-0.095l0.085-0.168l-0.053-0.265l-0.127-0.2l-0.739-0.806l-1.28-0.665l-0.042-0.021
		l-1.148-0.265l-3.748,2.96l1.37,1.682l0.363,0.56l0.021,0.117l-0.555,0.369l-1.528,0.509l-1.839,0.486l-0.497,0.065l-6.672-0.552
		l-0.37-0.051l-0.148-0.065l-0.253-0.138l-1.618-1.512l-0.18-0.232l-0.138-0.327v-0.361l0.19-0.908l-0.031-0.117l-0.213-0.211
		l-0.592-0.306l-1.859-0.497l-3.21-0.603l-0.497-0.063l-0.274,0.063l-0.196,0.094l-6.233,3.615l-0.327,0.329l-0.148,0.223
		l-0.074,0.262l-0.095,0.857l-0.191,0.413l-0.438,0.349l-0.264,0.105l-4.61,0.603h-0.18l-4.166-0.919l-3.363-1.373l-1.052-1.567
		l-0.158-0.146l-0.365-0.159l-0.242-0.043l-3.548,0.129l-1.434,1.416l-0.188,0.401l-1.031,1.1l-0.514,0.273l-2.675,0.974
		l-3.844,1.089l-0.275,0.021l-0.83-0.064l-0.767-0.316l-0.147-0.148l-1.242-1.287l-0.678-1.112l0.255-0.137l0.476,0.146l2.495-1.014
		l0.011-0.339l-0.17-0.908l-0.357-0.973l-5.791-1.523l-4.355-5.688l-0.19-0.308l-0.159-0.433l-0.063-0.36l-0.435-2.833l0.086-0.4
		l0.137-0.202l1.581-1.25l0.232-0.251l0.274-0.402l0.248-0.611l0.138-0.711l-0.011-0.349l-0.138-0.538l-0.966-2.886l-0.055-0.423
		l0.055-0.825l0.105-0.36l0.925-2.197l1.231-2.855l0.223-0.688l0.053-0.528l-0.053-0.57l-0.34-0.951l-0.083-0.382v-0.687
		l0.127-0.529l1.813-4.768l1.828-2.549l0.477-0.675l0.512-0.932l0.139-0.497l-0.021-0.646l-0.302-1.237l-0.274-0.633l-0.465-0.784
		l-2.126-2.674l-0.158-0.231l-0.084-0.298l0.011-0.357l0.158-4.325l0.052-0.422l0.619-3.163l0.011-0.466l0.105-0.272l1.57-3.382
		l0.169-0.319l2.188-2.866l2.359-3.214l0.188-0.423l0.328-1.067l0.673-2.294l0.703-3.7l0.083-0.349l0.223-0.486l0.401-0.478
		l0.472-0.358l1.675-0.613l0.486-0.295l0.207-0.202l0.147-0.2l0.169-0.37l0.085-0.316l0.012-0.308l-0.725-3.892l-0.096-0.899v-0.59
		l0.085-0.711l0.138-0.654l0.205-0.646l2.13-5.21l2.306-4.693l0.292-0.424l0.316-0.687l0.105-0.53l0.011-0.413l0.042-3.572
		l-0.073-0.91l-0.212-1.09l-0.011-0.474l0.043-0.318l0.147-0.433l2.687-5.242l0.147-0.225l0.242-0.233l1.2-0.507l1.47-0.823
		l0.509-0.392l4.769-5.499l0.744-3.616l0.149-0.327l0.147-0.146l0.487-0.212l0.396-0.021l2.494-0.635l3.485-2.463l0.771-0.698
		l0.704-0.855l0.433-0.657l0.237-0.55l0.191-0.792l0.266-1.216l0.432-1.29l0.587-1.66l0.351-0.856l0.417-0.888l0.888-1.64
		l3.367-5.297l0.308-0.327l0.301-0.169l2.003-0.635l0.407-0.243l1.593-1.235l1.073-0.849l0.401-0.411l0.312-0.455l0.286-0.528
		l0.423-0.939l0.095-0.319l0.075-0.297l0.199-1.403l0.197-0.899l0.454-1.185l0.358-0.654l0.715-1.06l0.979-1.055l0.01-0.013
		l0.687-0.845l0.449-0.729l0.181-0.519l2.41-10.287l-0.158-1.798l-0.169,0.043h-0.095l-0.021-0.043l0.053-0.708l0.181-0.611
		l0.284-0.541l0.36-0.433l0.512-0.443l0.55-0.34l1.444-0.932l0.49-0.443l1.762-1.967l1.358-0.698l0.101-0.211l0.053-0.433
		l-0.121-0.541l-0.233-0.356l-0.348-0.318l-0.244-0.138l-1.03-0.35l-0.212-0.138l-0.191-0.222l-0.143-0.382l-0.031-0.433l0.031-0.2
		l0.133-0.326l0.223-0.319l0.518-0.487l0.45-0.306l0.443-0.337l0.498-0.52l0.587-0.865l0.379-0.848l0.244-0.931l0.041-0.568
		l-0.052-0.856l-0.169-2.296l0.776-2.093l0.011-0.318l-0.096-0.316l-0.349-0.402l-0.354-0.159l-0.561-0.104l-0.999,0.03l-0.66,0.033
		l-0.581-0.105l-0.234-0.106l-0.259-0.221l-0.158-0.181l-0.128-0.244l-0.011-0.359l0.117-0.306l0.222-0.232l0.237-0.181
		l-0.429-3.191l-0.083-0.615l-1.096-4.429l-0.422-0.847l-0.105-0.306l-0.043-0.37l0.022-0.423l0.126-0.499l0.402-0.697l0.084-1.141
		l0.154-0.084l1.046-0.508l0.313-0.076l0.285-0.02l0.999,0.117l0.55,0.465l1.453,1.269l2.041,2.621l0.381,1.185l0.63,0.856
		l0.914,0.688l0.455,0.179l0.296,0.086l0.937-0.044l1.733-1.362l0.666-1.089l0.105-0.541v-0.505l-0.898-4.062l-0.63,0.096
		l-0.211-0.391l-0.042-0.814l0.297-0.603l1.707-0.486l3.933-0.984l0.968,0.223l0.127,0.476l-0.513,0.992l-0.106,0.117l-0.433-0.157
		l-0.096-0.192l-0.365,0.203l1.873,7.635l1.452,0.991l2.306,1.027l0.459,0.157l6.499,1.279h2.39l-0.159,0.646l0.202,1.247
		l-2.327,2.105l-2.306,0.802l-1.76,3.743l-0.376,0.868l0.303,0.856l0.592,0.644l0.48,0.098l1.866,0.081l0.127-0.03l0.255-0.181
		l6.479-5.92l-0.554-0.845l-0.74-0.679l0.201-0.635l0.38-0.706l1.585-1.83l1.043-0.74l0.243-0.116l5.642-1.809l1.141,0.266
		l1.48,1.724l0.915-0.244l0.296-0.423l0.011-0.423l-0.565-1.387l-0.35-0.251l-1.612-0.868l0.539-0.433l0.149-0.223l0.386-0.982
		l-0.09-0.085L207.429,563.138z M178.262,755.808l-0.734-1.883l-0.233-0.202l-2.549,0.148l0.224,2.189l0.64,0.518l2.066-0.212
		l0.703-0.094L178.262,755.808z"
        />
      </g>
      <g id="_x23_" data-testid="index-value-circles">
        <g transform="matrix(1 0 0 1 184.9314 666.5393)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Baden-Württemberg 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Baden-Württemberg 2022'
            )}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 406.0803 149.1624)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Mecklenburg-Vorpommern 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Mecklenburg-Vorpommern 2022'
            )}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 193.1072 469.5725)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Hessen 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Hessen 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 260.9231 166.6614)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Hamburg 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Hamburg 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 184.9314 214.0491)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Bremen 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Bremen 2022')} %
          </text>
        </g>

        {/* Enable this when we have data for Brandenburg, which currently has no hystreet-locations
        <g transform="matrix(1 0 0 1 496.8293 328.928)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text x="15" y="-10" textAnchor="middle">
            {stateIndices['bundesland-Index Brandenburg 2022'].index_value}
          </text>
        </g>
        */}
        <g transform="matrix(1 0 0 1 353.7317 630.0393)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Bayern 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Bayern 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 470.6223 272.7122)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Berlin 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Berlin 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 257.9231 272.7122)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Niedersachsen 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Niedersachsen 2022')}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 89.6106 381.6067)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Nordrhein-Westfalen 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Nordrhein-Westfalen 2022'
            )}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 68.6101 536.217)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Rheinland-Pfalz 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Rheinland-Pfalz 2022'
            )}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 42.511 594.262)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Saarland 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Saarland 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 460.927 420.5725)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Sachsen 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Sachsen 2022')} %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 348.5354 311.7112)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Sachsen-Anhalt 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Sachsen-Anhalt 2022'
            )}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 222.5359 101.6487)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Schleswig-Holstein 2022'
            )}
          >
            {indexLabelFor(
              stateIndices,
              'bundesland-Index Schleswig-Holstein 2022'
            )}{' '}
            %
          </text>
        </g>
        <g transform="matrix(1 0 0 1 310.7288 441.5725)">
          <circle cx="15" cy="-15" r="30"></circle>
          <text
            x="15"
            y="-10"
            textAnchor="middle"
            letterSpacing="-1.111"
            className={dynamicLabelStyles(
              stateIndices,
              'bundesland-Index Thüringen 2022'
            )}
          >
            {indexLabelFor(stateIndices, 'bundesland-Index Thüringen 2022')} %
          </text>
        </g>
      </g>
    </svg>
  )
}

GermanySvg.propTypes = {
  style: PropTypes.object,
  stateIndices: PropTypes.object.isRequired
}

const svgStyles = css`
  display: block;
  circle {
    fill: #fff;
  }
  text {
    font-size: 0.8rem;
  }
`

const positiveLabelStyles = css`
  fill: #689f38;
`

const neutralLabelStyles = css`
  fill: #ffaa00;
`

export default GermanySvg
