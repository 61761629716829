import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import FooterRegister from '../sections/FooterRegister'
import Section from '../components/Section'
import Headline from '../components/Headline'
import Link from '../components/Link'
import CardListWithHeadline from '../components/CardListWithHeadline'

function ArchivePage({
  title,
  headline,
  backLinkText,
  backLink,
  yearsData,
  renderCards
}) {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomSection mid>
        <Section.Wrapper>
          <Headline breakout css={{ marginBottom: '2rem' }}>
            {headline}
          </Headline>
          {yearsData.map(singleYearData => {
            // Data arrives as an array containing the date and an array of data
            // yearsData = [ ['year', [...data] ], ...]
            const yearNumber = singleYearData[0]
            const yearData = singleYearData[1]

            return (
              <CardListWithHeadline
                key={yearNumber}
                headline={yearNumber}
                cards={renderCards(yearData)}
              />
            )
          })}
          <Link css={{ marginTop: '1rem' }} href={backLink}>
            {backLinkText}
          </Link>
        </Section.Wrapper>
      </CustomSection>
      <FooterRegister />
    </div>
  )
}

const CustomSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 4rem;
  flex: 1 auto;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 4.625rem;
    padding-bottom: 12rem;
  }
`

ArchivePage.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  backLinkText: PropTypes.string.isRequired,
  renderCards: PropTypes.func.isRequired,

  // Data arrives as an array containing the date and an array of data
  // yearsData = [ ['year', [...data] ], ...]
  yearsData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.object)
      ])
    )
  ).isRequired
}

export default ArchivePage
