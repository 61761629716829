import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'

import Headline from '../components/Headline'
import Section from '../components/Section'
import News from '../sections/News'
import PressContact from '../sections/PressContact'
import PressMediaKit from '../sections/PressMediaKit'
import PressMentions from '../sections/PressMentions'
import Analyses from '../sections/Analyses'
import ScientificWork from '../sections/ScientificWork'
import MacbookPro from '../components/assets/images/macbook_pro.png'
import Graph from '../components/assets/images/blurred-graph.png'

function Press({ pressMentions, blogPost, scientificWorks }) {
  return (
    <Section css={{ paddingBottom: '4rem', paddingTop: '4rem' }} mid>
      <Helmet>
        <title>{I18n.t('pages.press.title')}</title>
      </Helmet>
      <Section.Wrapper>
        <CustomHeadline breakout id="press">
          <Trans id="pages.press.news.headline" />
        </CustomHeadline>
      </Section.Wrapper>
      <NewsSectionWrapper>
        {blogPost && <News blogPostData={blogPost} />}
        <PressContact blogPostData={blogPost} />
      </NewsSectionWrapper>
      <Section
        darkerGrey
        css={{
          marginTop: '28em',
          paddingBottom: '4rem'
        }}
      >
        <div css={{ height: '128', marginBottom: '-250px' }}>
          <Section.Skew
            css={{ zIndex: '0' }}
            darkerGrey
            position="top"
            direction="down"
            height="12rem"
          />
        </div>
        <MediaKitSectionWrapper>
          <PressMediaKit />
          <div css={{ zIndex: '10' }}>
            <Image
              src={MacbookPro}
              css={{
                backgroundColor: 'transparent'
              }}
            />
          </div>
        </MediaKitSectionWrapper>
      </Section>
      <Section
        mid
        css={{
          paddingBottom: '8rem'
        }}
      >
        <PressMentions pressData={pressMentions} />
        <div css={{ marginTop: '-12.5rem' }}>
          <Section.Skew mid position="bottom" direction="up" height="12rem" />
        </div>
      </Section>
      <Section darkerGrey id="analyses">
        <ImageBackground>
          <Analyses />
        </ImageBackground>
      </Section>
      <ScientificWork scientificWorkData={scientificWorks} />
    </Section>
  )
}

const NewsSectionWrapper = styled(Section.Wrapper)`
  display: grid;
  gap: 3rem;
  padding: 0rem;
  ${p => p.theme.breakpoints.tabletUp} {
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
    padding: 0;
    gap: 0rem;
  }
`

const ImageBackground = styled.div`
  padding-top: 12.5rem;
  padding-bottom: 5rem;
  background: url(${Graph});
  background-repeat: no-repeat;
  background-position: right -8rem;
  background-size: 60%;
`

const MediaKitSectionWrapper = styled(Section.Wrapper)`
  display: grid;
  gap: 2rem;
  ${p => p.theme.breakpoints.tabletUp} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1.5fr;
    padding: 0;
  }
`

const CustomHeadline = styled(Headline)`
  margin-bottom: 1rem;

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 1.5rem;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

Press.propTypes = {
  pressMentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  scientificWorks: PropTypes.arrayOf(PropTypes.object).isRequired,
  blogPost: PropTypes.object
}

export default Press
