import React from 'react'
import PropTypes from 'prop-types'
import ContentCard from '../components/ContentCard'
import I18n from '../i18n'
import ArchivePage from './ArchivePage'

// TODO: Pass actual link to /news#show to card once implemented
function NewsArchive({ news }) {
  function buildCardsFor(yearData) {
    return yearData.map(data => {
      const { abstract: subline, publicDate: date, ...rest } = data

      return (
        <ContentCard
          key={data.id}
          subline={subline}
          date={date}
          buttonText={I18n.t('pages.news_archive.cards.post_link')}
          link={`/press/news-archive/${data.id}`}
          {...rest}
        />
      )
    })
  }

  return (
    <ArchivePage
      title={I18n.t('pages.news_archive.headline')}
      headline={I18n.t('pages.news_archive.headline')}
      backLink="/press"
      backLinkText={I18n.t('pages.news_archive.back_link')}
      yearsData={news}
      renderCards={buildCardsFor}
    />
  )
}

NewsArchive.propTypes = {
  news: PropTypes.array.isRequired
}

export default NewsArchive
