import React from 'react'
import { Helmet } from 'react-helmet'

import I18n, { Trans } from '../i18n'

import Spacer from '../components/Spacer'

import LegalPageContent from '../sections/LegalPageContent'

function Privacy() {
  return (
    <React.Fragment>
      <Helmet>
        <title>{I18n.t('pages.privacy.title')}</title>
      </Helmet>
      <LegalPageContent
        printLink={`/privacy_${I18n.currentLocale()}.pdf`}
        headline={I18n.t('pages.privacy.headline')}
      >
        <Trans id="pages.privacy.content_new" copy />
      </LegalPageContent>
      <Spacer light />
    </React.Fragment>
  )
}

export default Privacy
