import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import I18n, { Trans } from '../../i18n'
import useLocation from '../../lib/use-location'
import Button from '../Button'
import * as Icon from '../Icon'
import Select from '../Select'
import { HorizontalSpacer } from '../Spacer'
import Modal from './Modal'

/**
 * Displays a success-state of the report modal,
 * which might be needed again when we introduce report subscriptions.
 * in 04/2022. Can be removed otherwise :)
 */
function ReportModalSuccess() {
  return (
    <VerticalWrapper>
      <Circled>
        <Icon.Checkmark />
      </Circled>
      <ExplanatoryParagraph
        css={{ marginTop: '3.25rem', marginBottom: '3.25rem' }}
      >
        <Trans id="location.reports.reports_now_available" />
      </ExplanatoryParagraph>

      <Button.Link baseColor href="/reports">
        <Trans id="location.reports.go_to_reports_page" />
      </Button.Link>

      <Link href="#">
        <Trans id="location.reports.go_back_to_location_page" />
      </Link>
    </VerticalWrapper>
  )
}

const getYearSelectorItems = (
  earliestMeasurementTimestamp,
  latestMeasurementTimestamp
) => {
  const earliestMeasurement = new Date(earliestMeasurementTimestamp)
  const earliestYear = earliestMeasurement.getFullYear()

  const latestMeasurement = new Date(latestMeasurementTimestamp)
  const latestYear = latestMeasurement.getFullYear()

  const numberOfAvailableYears = latestYear - earliestYear + 1
  const selectorItems = []
  for (let yearIndex = 0; yearIndex < numberOfAvailableYears; yearIndex++) {
    const yearKeyAndLabel = latestYear - yearIndex
    selectorItems.push({
      key: yearKeyAndLabel,
      label: yearKeyAndLabel.toString()
    })
  }

  return selectorItems
}

const getMonthSelectorItems = (
  earliestMeasurementTimestamp,
  latestMeasurementTimestamp,
  year
) => {
  const earliestMeasurement = new Date(earliestMeasurementTimestamp)
  const earliestMonth = earliestMeasurement.getMonth()
  const earliestYear = earliestMeasurement.getFullYear()

  const latestMeasurement = new Date(latestMeasurementTimestamp)
  const latestMonth = latestMeasurement.getMonth()
  const latestYear = latestMeasurement.getFullYear()

  const allMonths = Array.from({ length: 12 }, (e, i) => {
    const month = new Date(null, i + 1, null)
    const label = I18n.strftime(month, '%B')
    return { key: month.getMonth() + 1, label: label }
  })

  if (year === latestYear) {
    return allMonths.slice(0, latestMonth)
  }

  if (year === earliestYear) {
    return allMonths.slice(earliestMonth, undefined)
  }

  return allMonths
}

export default function ReportModal({ isOpen, onRequestClose }) {
  const { location } = useLocation()

  const yearSelectorItems = getYearSelectorItems(
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt
  )
  if (yearSelectorItems.length === 0) return null

  const monthSelectorItems = getMonthSelectorItems(
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt,
    yearSelectorItems[0].key
  )

  if (monthSelectorItems.length === 0) return null

  const [selectedYear, setSelectedYear] = useState(yearSelectorItems[0])
  const [availableMonths, setAvailableMonths] = useState(monthSelectorItems)
  const [selectedMonth, setSelectedMonth] = useState(null)

  // Recompute available months whenever the year changes.
  useEffect(() => {
    const availableMonths = getMonthSelectorItems(
      location.metadata.earliestMeasurementAt,
      location.metadata.latestMeasurementAt,
      selectedYear.key
    )
    setAvailableMonths(availableMonths)
  }, [
    location.metadata.earliestMeasurementAt,
    location.metadata.latestMeasurementAt,
    selectedYear
  ])

  // Set initial month as soon as available months are computed.
  useEffect(() => {
    if (!availableMonths) {
      return
    }

    if (!selectedMonth) {
      setSelectedMonth(availableMonths[availableMonths.length - 1])
    }
  }, [availableMonths, selectedMonth])

  // Recompute the selected month based on available months.
  useEffect(() => {
    if (!availableMonths || !selectedMonth) {
      return
    }

    const monthIsAvailableInSelectedYear =
      availableMonths.findIndex(month => {
        return month.key === selectedMonth.key
      }) !== -1

    let monthToSetAfterYearChanged = monthIsAvailableInSelectedYear
      ? selectedMonth
      : availableMonths[0]

    setSelectedMonth(monthToSetAfterYearChanged)
  }, [availableMonths, selectedMonth])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      showCloseButton
      title={'location.reports.headline'}
    >
      <VerticalWrapper>
        <div>
          <ExplanatoryParagraph>
            {I18n.t('location.reports.explanatory_paragraph_1')}
          </ExplanatoryParagraph>

          <SelectWrapper>
            {availableMonths && (
              <StyledSelect
                onChange={item => setSelectedMonth(item)}
                items={availableMonths}
                baseTitle={
                  selectedMonth?.label ||
                  I18n.t('location.reports.select_month')
                }
              />
            )}

            <HorizontalSpacer width={'2rem'} transparent />
            <StyledSelect
              onChange={item => setSelectedYear(item)}
              items={yearSelectorItems}
              baseTitle={
                selectedYear?.label || I18n.t('location.reports.select_year')
              }
            />
          </SelectWrapper>

          <StyledButton
            onClick={() => {
              const targetUrl = new URL(
                `/locations/${location.id}/report/${selectedYear.key}/${selectedMonth.key}`,
                window.location.href
              )

              targetUrl.searchParams.append('locale', I18n.currentLocale())

              window.open(targetUrl, '_blank')
            }}
          >
            {I18n.t('location.reports.generate')}
          </StyledButton>
        </div>
      </VerticalWrapper>
    </Modal>
  )
}

ReportModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
}

const SelectWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledSelect = styled(Select)`
  flex: 1;
`

const StyledButton = styled(Button)`
  margin-top: 2rem;
  width: 100%;
`

const Circled = styled.span`
  position: relative;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 32px;
  color: white;
  border-radius: 50%;
  background-color: #2b8923;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  &:after {
    position: absolute;
    content: '';
    height: 140%;
    width: 140%;
    border-radius: inherit;
    z-index: -1;
    background-color: #2b8923;
    opacity: 40%;
  }
`

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  flex: 1 0 50%;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const Link = styled.a`
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.178544px;
  text-decoration-line: underline;
  color: ${p => p.theme.chroma.base.css()};
  margin-top: 6px;
`

const ExplanatoryParagraph = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.178544px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: ${p => p.theme.chroma.base.css()};
`
