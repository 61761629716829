import PropTypes from 'prop-types'
import React from 'react'
import CollapsibleSection from '../components/CollapsibleSection'
import * as Icon from '../components/Icon'
import ActionButton from '../components/LocationDetail/ActionButton'
import LocationStatsCard, {
  statsObjectPropTypes
} from '../components/LocationDetail/LocationStatsCard/LocationStatsCard'
import { ZoneCard } from '../components/LocationDetail/LocationStatsCard/ZoneCard'
import i18n, { default as I18n } from '../i18n'
import useModalState from '../lib/use-modal-state'

function ZoneLocationDataSection({ stats, hasMap }) {
  const [_activeModal, { openModal, modals }] = useModalState()

  const sectionHeaderRowItems = hasMap && (
    <ActionButton
      title={I18n.t('location.map_action')}
      icon={<Icon.MapLight height={20} width={21} />}
      onClick={() => {
        window.scrollTo(0, 150)
        openModal(modals.map)
      }}
      variant={'link'}
    />
  )

  return (
    <CollapsibleSection
      css={{ marginTop: '1.3rem', marginBottom: '2rem' }}
      title={i18n.t('location.details.display_zones')}
      openByDefault
      headerRowItems={sectionHeaderRowItems}
    >
      <LocationStatsCard
        headline={I18n.t('location.details.yesterday')}
        css={{ paddingBottom: 10 }}
      >
        <ZoneCard statObject={stats.yesterday}></ZoneCard>
      </LocationStatsCard>

      <LocationStatsCard
        headline={I18n.t('location.details.past_week')}
        css={{ paddingBottom: 10 }}
      >
        <ZoneCard statObject={stats.lastWeek}></ZoneCard>
      </LocationStatsCard>
      <LocationStatsCard
        headline={I18n.t('location.details.past_month')}
        css={{ paddingBottom: 10 }}
      >
        <ZoneCard statObject={stats.lastMonth}></ZoneCard>
      </LocationStatsCard>

      <LocationStatsCard
        headline={I18n.t('location.details.current_year_to_date')}
        css={{ paddingBottom: 10 }}
      >
        <ZoneCard statObject={stats.thisYearToDate}></ZoneCard>
      </LocationStatsCard>
    </CollapsibleSection>
  )
}

ZoneLocationDataSection.propTypes = {
  stats: statsObjectPropTypes,
  hasMap: PropTypes.bool
}

export default ZoneLocationDataSection
