import PropTypes from 'prop-types'
import React from 'react'
import {
  calculatePercentage,
  getAbsoluteDifference,
  getDifferenceAsPercentageOrMissingLabel
} from '../../../lib/calculate-location-statistics'
import {
  ChildOrAdultStatsCardItem,
  statsObjectPropTypes,
  WalkingDirectionComparisonStatsCardItem
} from './LocationStatsCard'

export const AdultOrChildCard = ({ statsObject, comparedTimeranges }) => {
  return (
    <>
      <ChildOrAdultStatsCardItem
        percentageOfAdults={calculatePercentage(
          statsObject.adultPedestriansCount,
          statsObject.pedestriansCount
        )}
        absoluteAdults={statsObject.adultPedestriansCount}
        percentageOfChildren={calculatePercentage(
          statsObject.childPedestriansCount,
          statsObject.pedestriansCount
        )}
        absoluteChildren={statsObject.childPedestriansCount}
        displayNoDataPresentPlaceholder={
          statsObject.noDataPresentForAskedTimerange
        }
        displayDataIncompleteNotice={
          statsObject.dataIncompleteForAskedTimerange
        }
      ></ChildOrAdultStatsCardItem>

      {comparedTimeranges !== undefined &&
        comparedTimeranges.length > 0 &&
        comparedTimeranges.map((comparedTimerange, index) => {
          return (
            <WalkingDirectionComparisonStatsCardItem
              key={index}
              timestamp={comparedTimerange.timerangeLabel}
              walkingDirectionReading1={{
                numberOfPedestriansWalkingInDirection:
                  comparedTimerange.statsObject.adultPedestriansCount,
                pedestrianCountDifferenceInAbsoluteNumbers: getAbsoluteDifference(
                  comparedTimerange.statsObject.adultPedestriansCount,
                  statsObject.adultPedestriansCount
                ),
                pedestrianCountDifferenceInPercentages: getDifferenceAsPercentageOrMissingLabel(
                  statsObject.adultPedestriansCount,
                  comparedTimerange.statsObject.adultPedestriansCount
                )
              }}
              walkingDirectionReading2={{
                numberOfPedestriansWalkingInDirection:
                  comparedTimerange.statsObject.childPedestriansCount,
                pedestrianCountDifferenceInAbsoluteNumbers: getAbsoluteDifference(
                  comparedTimerange.statsObject.childPedestriansCount,
                  statsObject.childPedestriansCount
                ),
                pedestrianCountDifferenceInPercentages: getDifferenceAsPercentageOrMissingLabel(
                  statsObject.childPedestriansCount,
                  comparedTimerange.statsObject.childPedestriansCount
                )
              }}
              displayNoDataPresentPlaceholder={
                comparedTimerange.statsObject.noDataPresentForAskedTimerange
              }
              displayDataIncompleteNotice={
                comparedTimerange.statsObject.dataIncompleteForAskedTimerange
              }
            />
          )
        })}
    </>
  )
}

AdultOrChildCard.propTypes = {
  statsObject: statsObjectPropTypes,
  comparedTimeranges: PropTypes.arrayOf(
    PropTypes.shape({
      statsObject: statsObjectPropTypes,
      timerangeLabel: PropTypes.string
    })
  )
}
