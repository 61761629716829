import React from 'react'
import { Helmet } from 'react-helmet'

import I18n from '../i18n'

import FaqSection from '../sections/FaqSection'

function Faq() {
  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <FaqSection />
    </div>
  )
}

const sT = key => I18n.t(`pages.faq.${key}`)

export default Faq
