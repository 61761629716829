import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Card = styled.div`
  background-color: ${p => p.theme.chroma.white.hex()};
  padding: ${p => p.paddingY || p.padding || 1.25}rem
    ${p => p.paddingX || p.padding || 1.25}rem;
  box-shadow: ${p =>
    p.shy
      ? '0px 0.125rem 0.375rem 0px rgba(0, 0, 0, 0.12)'
      : '0px 0.125rem 0.625rem 0px rgba(0, 0, 0, 0.28)'};

  ${p =>
    p.rounded &&
    `
      border-radius: 0.25rem;
    `}

  @media print {
    box-shadow: none;
    background-color: transparent;
  }
`

Card.propTypes = {
  paddingY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rounded: PropTypes.bool,
  shy: PropTypes.bool
}

export default Card
