import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Typography from '../components/Typography'
import Card from '../components/Card'
import Input from '../components/forms/Input'
import Form from '../components/forms/Form'
import Button from '../components/Button'
import Headline from '../components/Headline'
import SimpleSelect from '../components/forms/SimpleSelect'
import Section from '../components/Section'

import I18n from '../i18n'

function CompleteBusinessData({ user = {}, redirectPath }) {
  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section mid row css={{ minHeight: '100%', padding: '4rem 0 6rem' }}>
        <Section.Wrapper css={{ paddingTop: '2rem' }}>
          <Card css={{ width: '50rem', maxWidth: '100%' }}>
            <Headline>
              {sT('headline')}
              <Typography tag="p" smaller>
                {sT('subline')}
              </Typography>
            </Headline>
            <Form as="user" action="/user" method="patch">
              <Input hidden name="redirect_path" value={redirectPath} />
              <Form.Field>
                <Input
                  required
                  label={sT('fields.company_name')}
                  name="company_name"
                  defaultValue={user.companyName}
                />
              </Form.Field>
              <Form.Field>
                <SimpleSelect
                  required
                  label={sT('fields.company_type')}
                  name="company_type"
                  options={Object.entries(
                    I18n.t('company_types')
                  ).map(([value, label]) => ({ value, label }))}
                  defaultValue={user.companyType}
                />
              </Form.Field>

              <Form.Actions>
                <Button type="submit">{sT('fields.continue')}</Button>
              </Form.Actions>
            </Form>
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`sections.complete_business_data.${key}`)

CompleteBusinessData.propTypes = {
  user: PropTypes.object,
  redirectPath: PropTypes.string
}

export default CompleteBusinessData
