import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Card from '../Card'
import I18n from '../../i18n'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import useModalState from '../../lib/use-modal-state'
import { TimeRange as TimeRangeIcon } from '../Icon'
import CompareIcon from './CompareIcon'
import FilterButton from './FilterButton'
import MapButton from './MapButton'

function MobileControlSheet({ hasMap }) {
  const [_activeModal, { openModal, modals }] = useModalState()
  const [filter] = useFilter()
  const { location } = useLocation()

  function compareButtonText() {
    switch (filter.compareType) {
      case 'multiLocation': {
        return I18n.t('location.comparison.multiLocation')
      }
      case 'timerange':
        return I18n.helpers.dateRange(filter.compareFrom, filter.compareTo)
      case 'average':
        return I18n.t('location.comparison.average')
      default:
        return ''
    }
  }

  return (
    <Wrapper rounded shy>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <p
            css={{
              fontSize: '0.75rem',
              textTransform: 'uppercase',
              marginBottom: '0.25rem'
            }}
          >
            {location.city}
          </p>
          <p
            css={{
              fontWeight: 'bold',
              fontSize: '1rem',
              marginBottom: '2rem'
            }}
          >
            {location.name}
          </p>
        </div>
        {hasMap && <MapButton onClick={() => openModal(modals.map)} />}
      </div>

      <div css={{ marginBottom: '0.5em' }}>
        <FilterButton
          onClick={() => openModal(modals.timerange)}
          icon={<TimeRangeIcon />}
        >
          <FilterButton.TopLine>
            {I18n.t('location.timerange.label')}
          </FilterButton.TopLine>
          <FilterButton.Title>
            {I18n.helpers.dateRange(filter.from, filter.to)}
          </FilterButton.Title>
        </FilterButton>
      </div>

      <FilterButton
        onClick={() => {
          openModal(modals.comparison)
        }}
        icon={<CompareIcon type={filter.compareType} />}
      >
        <FilterButton.TopLine>
          {I18n.t('location.comparison.label')}
        </FilterButton.TopLine>
        <FilterButton.Title>{compareButtonText()}</FilterButton.Title>
      </FilterButton>
    </Wrapper>
  )
}

MobileControlSheet.propTypes = {
  hasMap: PropTypes.bool
}

const Wrapper = styled(Card)`
  width: 100%;
  padding: 1rem;
`

export default MobileControlSheet
