import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import Helmet from 'react-helmet'
import dayjs from 'dayjs'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Card from '../components/Card'
import ConditionalWrapper from '../components/ConditionalWrapper'
import { CityScape as CityIcon } from '../components/Icon'
import Headline from '../components/Headline'
import RankingItem from '../components/RankingItem'
import Section from '../components/Section'

import FooterRegister from '../sections/FooterRegister'

import AdvertisementBanner from '../components/AdvertisementBanner'
import munichPanorama from './assets/munich-panorama.jpg'
import teaserBackground from './assets/teaser-graph.jpg'

function Ranking({
  topLocations,
  locationsCount,
  month,
  year,
  loggedIn,
  showAdvertisingBanner
}) {
  const rankingDate = dayjs(`${year}-${month}-01`).toDate() // dayjs is simpler for IE11 support of date parsing
  const measuredDate = dayjs(rankingDate)
    .add(1, 'month')
    .toDate()

  const [isActive, setIsActive] = useState(false)
  const [showAll, setShowAll] = useState(false)

  const topLocationsToShow = showAll ? topLocations : topLocations.slice(0, 10)

  const handleClick = index => {
    if (isActive === index) {
      return setIsActive(null)
    }
    setIsActive(index)
  }

  const handleShowAllClick = () => setShowAll(true)

  return (
    <div css={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <Helmet>
        <title>
          {I18n.t(showAll ? 'ranking.title_all' : 'ranking.title_10', {
            year: year,
            month: I18n.strftime(rankingDate, '%B')
          })}
        </title>
      </Helmet>

      <TitleSection mid>
        <Section.Skew inset position="bottom" direction="down" mid />
      </TitleSection>
      {showAdvertisingBanner && (
        <AdvertisementBanner cookieName="advertisement_banner_shown" />
      )}
      <MainSection mid>
        <Section.Wrapper>
          <Headline breakout>
            <Trans
              id={'ranking.headline_10'}
              values={{ year: year, month: I18n.strftime(rankingDate, '%B') }}
            />
          </Headline>

          <Explanation>
            <Trans
              id="ranking.explanation"
              copy
              values={{
                ranking_date: I18n.l('date.formats.long', measuredDate)
              }}
            />
          </Explanation>

          {topLocationsToShow.length === 0 && (
            <NoLocationsYet>
              <CityIcon width={90} height={90} />
              <Trans id="ranking.no_locations" copy />
            </NoLocationsYet>
          )}

          {topLocationsToShow.length > 0 && (
            <RankingCard>
              <ol>
                <AnimatePresence>
                  {topLocationsToShow.map((location, index) => (
                    <Fragment key={location.locationId}>
                      <ListItem
                        initial={index < 10 ? false : { x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        onClick={!loggedIn ? () => handleClick(index) : null}
                      >
                        <ConditionalWrapper
                          condition={loggedIn}
                          wrapper={children => (
                            <a href={`/locations/${location.locationId}`}>
                              {children}
                            </a>
                          )}
                        >
                          <RankingItem
                            rank={index + 1}
                            percentage={percentageFromMinMax({
                              min:
                                topLocations[topLocations.length - 1]
                                  .pedestriansCount,
                              max: topLocations[0].pedestriansCount,
                              value: location.pedestriansCount
                            })}
                            locationName={location.locationName}
                            cityName={location.cityName}
                            pedestriansCount={location.pedestriansCount}
                            animate={index >= 10}
                          />
                        </ConditionalWrapper>
                      </ListItem>
                      {isActive === index ? (
                        <TeaserWrapper>
                          <TeaserImage src={teaserBackground} />
                          <Teaser>
                            <TeaserTitle>
                              {I18n.t('ranking.teaser.title')}
                            </TeaserTitle>
                            <ul>
                              <TeaserBullet>
                                {I18n.t('ranking.teaser.bullets.first', {
                                  locations_count: locationsCount
                                })}
                              </TeaserBullet>
                              <TeaserBullet>
                                {I18n.t('ranking.teaser.bullets.second')}
                              </TeaserBullet>
                              <TeaserBullet>
                                {I18n.t('ranking.teaser.bullets.third')}
                              </TeaserBullet>
                            </ul>
                            <Container>
                              <Button.Link
                                href="/products"
                                css={{ marginBottom: '0.5rem' }}
                              >
                                {I18n.t('ranking.teaser.button_text')}
                              </Button.Link>
                              <Link href="/login">
                                {I18n.t('pages.signup.already_registered')}
                              </Link>
                            </Container>
                          </Teaser>
                        </TeaserWrapper>
                      ) : null}
                    </Fragment>
                  ))}
                </AnimatePresence>
              </ol>
              {!loggedIn ? (
                <FooterRegister locationsCount={locationsCount} />
              ) : (
                !showAll && (
                  <ShowAllContainer>
                    <Button outline onClick={handleShowAllClick}>
                      <Trans id="ranking.show_all" />
                    </Button>
                  </ShowAllContainer>
                )
              )}
            </RankingCard>
          )}
        </Section.Wrapper>
      </MainSection>
    </div>
  )
}

Ranking.propTypes = {
  month: PropTypes.number.isRequired, // 1 = january
  year: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool,
  topLocations: PropTypes.arrayOf(
    PropTypes.shape({
      locationId: PropTypes.number.iRequired,
      pedestriansCount: PropTypes.number.isRequired,
      locationName: PropTypes.string.isRequired,
      cityName: PropTypes.string.isRequired
    })
  ).isRequired,
  locationsCount: PropTypes.number.isRequired,
  showAdvertisingBanner: PropTypes.bool.isRequired
}

const ListItem = styled(motion.li)`
  border-bottom: 0.125rem solid ${p => p.theme.chroma.nearlyWhite.css()};
  overflow: hidden;
  transform-origin: top;

  :last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
`

const MainSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 5rem;
  flex: 1 0 auto;
`

const TitleSection = styled(Section)`
  height: 22rem;
  background-image: url(${munichPanorama});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;

  ${p => p.theme.breakpoints.phone} {
    display: none;
  }
`

const RankingCard = styled(Card)`
  padding: 0;
  margin-top: 2.5rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
`

const NoLocationsYet = styled.div`
  padding: 6rem;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  opacity: 0.5;

  p {
    margin-top: 1rem;
  }
`

const Explanation = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 53rem;
  line-height: 1.575;

  em {
    color: ${p => p.theme.chroma.accent.css()};
    font-style: normal;
  }
`

const TeaserWrapper = styled.div`
  position: relative;
`

const Teaser = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 4rem;
  background: linear-gradient(
    ${p => p.theme.chroma.base.alpha(0.6).css()},
    ${p => p.theme.chroma.base.alpha(0.6).css()}
  );
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (min-width: 480px) and (max-width: 679px) {
    padding: 0 2rem;
    justify-content: center;
  }
  ${props => props.theme.breakpoints.phone} {
    padding: 0 2rem;
  }
`

const TeaserImage = styled.img`
  display: block;
  max-width: 100%;
`

const TeaserTitle = styled.p`
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 900;
  color: ${props => props.theme.chroma.white.hex()};
  margin-bottom: 1.5rem;
  ${props => props.theme.breakpoints.desktop} {
    font-size: 1.45rem;
  }
  ${props => props.theme.breakpoints.tablet} {
    font-size: 1.1rem;
    margin-bottom: 1.15rem;
  }
  ${props => props.theme.breakpoints.phone} {
    font-size: 1rem;
  }
`

const TeaserBullet = styled.li`
  color: ${props => props.theme.chroma.white.hex()};
  position: relative;
  padding-left: 1.6rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.575;
  &:before {
    content: '';
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: ${p => p.theme.chroma.white.hex()};
    top: 0.5rem;
    left: 0;
  }
  ${props => props.theme.breakpoints.tablet} {
  }
  @media (min-width: 480px) and (max-width: 679px) {
    font-size: 0.8rem;
    line-height: 1.3;
    padding-left: 1.4rem;
    margin-bottom: 0.5rem;
    &:before {
      width: 0.4rem;
      height: 0.4rem;
      top: 0.3rem;
    }
  }
  ${props => props.theme.breakpoints.phone} {
    display: none;
  }
`

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${props => props.theme.breakpoints.desktop} {
    width: 70%;
  }
  ${props => props.theme.breakpoints.tablet} {
    width: 80%;
    padding: 0;
  }
  ${props => props.theme.breakpoints.phone} {
    width: 100%;
    padding: 0;
  }
`

const Link = styled.a`
  color: ${props => props.theme.chroma.white.hex()};
  box-shadow: 0 1px 0 0 currentColor;
  align-self: center;
  @media (min-width: 480px) and (max-width: 679px) {
    font-size: 0.8rem;
  }
`

const ShowAllContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 0;
  background-color: ${props => props.theme.chroma.nearlyWhite.hex()};
`

function percentageFromMinMax({ min, max, value }) {
  return (value - min) / (max - min)
}

export default Ranking
