import React from 'react'
import styled from '@emotion/styled'

import { Trans } from '../i18n'
import Section from '../components/Section'
import Headline from '../components/Headline'
import Button from '../components/Button'
import teaserBackground from './assets/teaser-bg.jpg'
import teaserOverlay from './assets/teaser-overlay.png'

function Teaser() {
  return (
    <TeaserSection row mid>
      <Section.Skew position="top" direction="down" inset light />
      <Section.Half basis={64}>
        <Section.Wrapper css={{ minWidth: '15rem !important' }}>
          <Content>
            <Headline breakout>
              <Trans id="pages.home.teaser.headline" />
            </Headline>
            <Trans id="pages.home.teaser.text" copy />
            <Button.Link href="/methodology" outline>
              <Trans id="pages.home.teaser.button" />
            </Button.Link>
          </Content>
        </Section.Wrapper>
      </Section.Half>
      <Section.Half
        basis={36}
        image={teaserBackground}
        css={{
          minHeight: '25rem',
          overflow: 'hidden',
          backgroundPosition: '7% center',
          backgroundRepeat: 'no-repeat'
        }}
      ></Section.Half>
      <TeaserOverlay src={teaserOverlay} alt="" />
    </TeaserSection>
  )
}

const TeaserSection = styled(Section)`
  min-height: 25rem;
  flex-direction: column-reverse !important;

  ${props => props.theme.breakpoints.desktopUp} {
    flex-direction: row !important;
    justify-content: stretch !important;
  }
`

const TeaserOverlay = styled.img`
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  max-width: 370px;
  height: auto;
  z-index: 2;
  ${props => props.theme.breakpoints.tabletUp} {
    left: calc(50%);
    transform: translateX(-50%);
  }
  ${props => props.theme.breakpoints.desktopUp} {
    top: auto;
    bottom: 0;
    left: 52%;
    transform: none;
    max-width: 678px;
  }
`

const Content = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;

  p {
    font-size: 18px;
    line-height: 1.4;
    margin: 2em 0;

    a {
      box-shadow: 0 1px 0 0 currentColor;
    }
    a::before {
      content: '> ';
    }
  }

  p:last-of-type {
    margin-bottom: 3em;
  }

  ${Button.Link} {
    width: fit-content;
  }

  ${props => props.theme.breakpoints.phone} {
    p {
      font-size: 1rem;
    }

    ${Button.Link} {
      width: 100%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
  ${props => props.theme.breakpoints.desktopUp} {
    padding-top: 8.75rem;
    padding-right: 1.5rem;
  }
`

export default Teaser
