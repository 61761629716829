import React from 'react'
import Section from '../components/Section'
import MissionQuote from '../components/MissionQuote'
import directors from './assets/directors.jpg'
import styled from '@emotion/styled'

function DirectorsImage() {
  return (
    <Section row>
      <ImageHalfSection basis={60} image={directors} />
      <Section.Half basis={40} hideMobile>
        <Section.Wrapper css={{ paddingTop: '2rem', paddingBottom: '5rem' }}>
          <MissionQuote />
        </Section.Wrapper>
      </Section.Half>
      <Section.Skew mid direction="down" position="bottom" inset />
    </Section>
  )
}

const ImageHalfSection = styled(Section.Half)`
  min-height: 21.375rem;

  @media (min-width: 500px) {
    min-height: 36rem;
  }

  @media (min-width: 960px) {
    min-height: 48rem;
  }
`

export default DirectorsImage
