import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import Headline from '../components/Headline'
import Card from '../components/Card'
import PlanSummary from '../components/PlanSummary'
import Section from '../components/Section'
import Checkbox from '../components/forms/Checkbox'
import Input from '../components/forms/Input'
import Typography from '../components/Typography'
import { MagnifyingGlass } from '../components/Icon'

import useLocalStorage, { localStorageKeys } from '../lib/use-local-storage'
import { getFilteredLocations } from '../lib/use-search'
import theme from '../theme'
import I18n from '../i18n'

function LocationSelect({ locations, plans }) {
  const [selectedLocationIds, setSelectedLocationIds] = useLocalStorage(
    'selectedLocationIds',
    []
  )
  const [selectedPlanName, _setSelectedPlanName] = useLocalStorage(
    localStorageKeys.selectedPlanName,
    null
  )
  const [selectedFlatrate, setSelectedFlatrate] = useLocalStorage(
    localStorageKeys.selectedFlatrate,
    false
  )

  const [query, setQuery] = useState('')

  const [groupedLocations] = useMemo(
    () =>
      getFilteredLocations(
        locations.filter(l => l.name && l.city),
        [],
        query
      ),
    [locations, query]
  )

  // can be used with one id for individual locations or with an array of ids for cities
  const toggleLocationsSelect = ids => {
    const select = ids =>
      setSelectedLocationIds(
        locations
          .map(l => l.id)
          .filter(id => ids.includes(id) || selectedLocationIds.includes(id))
      )

    const unselect = ids =>
      setSelectedLocationIds(
        selectedLocationIds.filter(id => !ids.includes(id))
      )

    if (locationsSelected(ids)) {
      if (selectedFlatrate) {
        setSelectedFlatrate(false)
        select(ids)
      } else {
        unselect(ids)
      }
    } else {
      select(ids)
    }
  }

  const locationsSelected = ids =>
    selectedFlatrate || ids.every(id => selectedLocationIds.includes(id))

  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section row mid>
        <Section.Wrapper css={{ paddingTop: '2rem' }}>
          <Headline>
            <Typography tag="p" smaller>
              {sT('step')} 1/3
            </Typography>
            {sT('headline')}
          </Headline>
          <a
            css={{ margin: '1rem 0', textDecoration: 'underline' }}
            href="/products"
          >
            {sT('to_products')}
          </a>
          <SplitCard>
            <div>
              <Input
                name="search"
                onChange={e => setQuery(e.target.value)}
                value={query}
                light
                big
                icon={MagnifyingGlass}
                placeholder={sT('search')}
                autoComplete="off"
              />
              <div css={{ overflowY: 'auto', marginTop: '2rem' }}>
                <Checkbox
                  label={sT('select_all')}
                  name="flatrate"
                  styleOverrides={{
                    padding: '1rem 0.3rem 0.5rem',
                    margin: '0.2rem 0',
                    fontWeight: 'bold'
                  }}
                  onChange={() => setSelectedFlatrate(!selectedFlatrate)}
                  checked={selectedFlatrate}
                />
                {groupedLocations?.map(([country, cities]) => (
                  <div key={country} css={{ marginBottom: '2rem' }}>
                    <Typography>{I18n.t('country')[country]}</Typography>
                    <div>
                      {cities?.map(([city, cityLocations]) => (
                        <React.Fragment key={city}>
                          <Checkbox
                            label={city}
                            key={city}
                            name={city}
                            styleOverrides={{
                              padding: '1rem 0.3rem 0.5rem',
                              margin: '0.2rem 0',
                              fontWeight: 'bold'
                            }}
                            checked={locationsSelected(
                              cityLocations.map(l => l.id)
                            )}
                            onChange={() =>
                              toggleLocationsSelect(
                                cityLocations.map(l => l.id)
                              )
                            }
                          />
                          <div>
                            {cityLocations.map(location => (
                              <Checkbox
                                label={location.name}
                                key={location.id}
                                name={`location[${location.id}]`}
                                styleOverrides={{
                                  backgroundColor: theme.chroma.nearlyWhite.css(),
                                  padding: '0.3rem',
                                  margin: '0.3rem 0'
                                }}
                                checked={locationsSelected([location.id])}
                                onChange={() =>
                                  toggleLocationsSelect([location.id])
                                }
                              />
                            ))}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              {selectedPlanName && (
                <PlanSummary
                  plan={plans[selectedPlanName]}
                  allSelected={selectedFlatrate}
                  locations={locations.filter(l =>
                    selectedLocationIds.includes(l.id)
                  )}
                  onLocationRemoved={id => {
                    setSelectedLocationIds(
                      selectedLocationIds.filter(lId => lId !== id)
                    )
                  }}
                  nextButtonLink="/subscriptions/new"
                />
              )}
            </div>
          </SplitCard>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.location_select.${key}`)

const SplitCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 2rem;
  padding: 2rem 3rem;
  margin: 2rem 0 4rem;

  > * {
    min-height: 30rem;
    max-height: calc(100vh - 35rem);
    overflow-y: none;
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

LocationSelect.propTypes = {
  locations: PropTypes.array.isRequired,
  plans: PropTypes.object.isRequired
}

export default LocationSelect
