import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'
import StripChart from '../../StripChart'
import {
  ComparisonRow,
  ItemContainer,
  PercentageOfPedestriansWalkingInDirectionLabel,
  WalkingDirectionContainer
} from './LocationStatsCardItemBase'
import { NoDataPresentStatsCardItem } from './NoDataPresentStatsCardItem'

const WalkingDirectionLabel = styled.p(props => {
  return { whiteSpace: 'nowrap' }
})

const TotalPedestriansWalkingInDirectionLabel = styled.p(props => {})

export function WalkingDirectionStatsCardItem({
  walkingDirectionReading,
  walkingDirectionReadingComplement,
  displayNoDataPresentPlaceholder = false,
  displayDataIncompleteNotice = false
}) {
  if (displayNoDataPresentPlaceholder || displayDataIncompleteNotice) {
    const message = displayDataIncompleteNotice
      ? I18n.t('location.details.data_incomplete')
      : I18n.t('location.details.data_missing')

    const neighbouringCardsHeight = 175

    return (
      <NoDataPresentStatsCardItem
        message={message}
        variant={'cardHeader'}
        minHeight={neighbouringCardsHeight}
      ></NoDataPresentStatsCardItem>
    )
  }

  return (
    <ItemContainer fullWidth hideBorder>
      <ComparisonRow>
        <WalkingDirectionContainer rtl>
          <div css={{ paddingRight: 8 }}>
            <WalkingDirectionLabel>
              {walkingDirectionReading.walkingDirection}
            </WalkingDirectionLabel>

            <PercentageOfPedestriansWalkingInDirectionLabel>
              {`${I18n.toNumber(
                walkingDirectionReading.percentageOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}%`}
            </PercentageOfPedestriansWalkingInDirectionLabel>

            <TotalPedestriansWalkingInDirectionLabel>
              {`${I18n.toNumber(
                walkingDirectionReading.numberOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}`}
            </TotalPedestriansWalkingInDirectionLabel>
          </div>

          <StripChart
            rtl
            percentage={
              walkingDirectionReading.percentageOfPedestriansWalkingInDirection
            }
          ></StripChart>
        </WalkingDirectionContainer>

        <WalkingDirectionContainer>
          <div css={{ paddingLeft: 8 }}>
            <WalkingDirectionLabel>
              {walkingDirectionReadingComplement.walkingDirection}
            </WalkingDirectionLabel>

            <PercentageOfPedestriansWalkingInDirectionLabel>
              {`${I18n.toNumber(
                walkingDirectionReadingComplement.percentageOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}%`}
            </PercentageOfPedestriansWalkingInDirectionLabel>

            <TotalPedestriansWalkingInDirectionLabel>
              {`${I18n.toNumber(
                walkingDirectionReadingComplement.numberOfPedestriansWalkingInDirection,
                { precision: 0 }
              )}`}
            </TotalPedestriansWalkingInDirectionLabel>
          </div>

          <StripChart
            percentage={
              walkingDirectionReadingComplement.percentageOfPedestriansWalkingInDirection
            }
          ></StripChart>
        </WalkingDirectionContainer>
      </ComparisonRow>
    </ItemContainer>
  )
}

WalkingDirectionStatsCardItem.propTypes = {
  walkingDirectionReading: PropTypes.shape({
    walkingDirection: PropTypes.string,
    numberOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    percentageOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),

  walkingDirectionReadingComplement: PropTypes.shape({
    walkingDirection: PropTypes.string,
    numberOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    percentageOfPedestriansWalkingInDirection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),

  displayNoDataPresentPlaceholder: PropTypes.bool,
  displayDataIncompleteNotice: PropTypes.bool
}
