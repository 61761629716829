import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import {
  Area,
  Bar,
  ComposedChart,
  Customized,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import I18n from '../../i18n'
import calculateIncidentRows from '../../lib/calculate-incident-rows'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import usePlot from '../../lib/use-plot'
import theme from '../../theme'
import useObjectTypePlot from '../../lib/use-object-type-plot'
import Cursor from './Cursor'
import Incident, { INCIDENT_HEIGHT } from './Incident'
import LoadingIndicator from './LoadingIndicator'
import CustomTooltip from './Tooltip'
import XAxisTick from './XAxisTick'

// How the line is interpolated between two values. See http://recharts.org/en-US/api/Area#type
// Changes the smoothness of the graph lines.
const INTERPOLATION_TYPE = 'linear'
const GRAPH_LINE_WIDTH = '0.25rem'

const isSSR = typeof window !== 'object'

export function CurrentLocationGraph({
  chartType,
  onHover = () => {},
  chartColors = [],
  delayRender = false
}) {
  const { location, status } = useLocation()
  const { plot, stackedChartKeys } = usePlot()
  const {
    plot: objectTypePlot,
    chartKeys: objectTypeChartKeys
  } = useObjectTypePlot()

  const incidentRows = React.useMemo(() => {
    if (!location.incidents) return []
    return calculateIncidentRows(location.incidents, location.metadata)
  }, [location])
  const [
    { objectType, objectSubtype, viewType, compareType, resolution }
  ] = useFilter()

  const isMultiLocationComparisonModeActive =
    viewType === 'compare' && compareType === 'multiLocation'

  const shouldUseFrontendApiData =
    objectType === 'VEHICLE' ||
    objectSubtype === 'ADULT' ||
    objectSubtype === 'CHILD'

  const chartKeysToDisplay = shouldUseFrontendApiData
    ? objectTypeChartKeys
    : stackedChartKeys

  const plotToDisplay = shouldUseFrontendApiData ? objectTypePlot : plot
  const dataKey = shouldUseFrontendApiData ? 'count' : 'pedestriansCount'

  return (
    <Graph
      plot={plotToDisplay}
      isLoading={status === 'loading'}
      incidentRows={incidentRows}
      onHover={onHover}
      stackedChartKeys={chartKeysToDisplay}
      locationMetadata={location.metadata}
      chartType={chartType}
      viewType={viewType}
      compareType={compareType}
      displayTooltip={true}
      chartColors={chartColors?.length > 0 ? chartColors : null}
      delayRender={delayRender}
      customTooltipContent={
        isMultiLocationComparisonModeActive && (
          <CustomTooltip
            resolution={resolution}
            chartColors={chartColors}
            stackedChartKeys={stackedChartKeys}
          ></CustomTooltip>
        )
      }
      graphHeight={isMultiLocationComparisonModeActive ? 600 : null}
      dataKey={dataKey}
    />
  )
}

CurrentLocationGraph.propTypes = {
  chartType: PropTypes.string,
  onHover: PropTypes.func,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  delayRender: PropTypes.bool,
  customTooltipContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
}

function Graph({
  plot,
  isLoading,
  incidentRows = [],
  stackedChartKeys = ['pedestriansCount'],
  aspectRatio,
  chartType,
  viewType = 'compare',
  compareType = 'location',
  onHover = () => {},
  displayLegend = false,
  displayTooltip = false,
  customTooltipContent = null,
  xAxis = null,
  chartColors = null,
  delayRender = false,
  graphHeight = null,
  dataKey = 'pedestriansCount'
}) {
  const hasPlot = plot.length > 0
  const tooltipMemoKey = React.useRef('') // handleTooltipChange fires very often, so we do a poor-mans-memoization.
  function handleTooltipChange({ active, payload: tooltipPayload }) {
    if (!tooltipPayload) {
      return
    }
    // This function is passed to the `Tooltip.content` prop, which is technically
    // meant as a way to display a component next to the tooltip. We abuse it
    // to handle changes in the tooltip.
    const timestamp = tooltipPayload[0]
      ? tooltipPayload[0].payload.timestamp
      : ''
    const compareTooltipMemoKey = active + timestamp
    if (tooltipMemoKey.current === compareTooltipMemoKey) return null
    tooltipMemoKey.current = compareTooltipMemoKey

    onHover({ active, payload: tooltipPayload[0] && tooltipPayload[0].payload })

    return null
  }

  function formatYTick(payload) {
    return I18n.toNumber(payload, { precision: 0 })
  }

  const [activeIncidentDetail, setActiveIncidentDetail] = React.useState(null)

  if (delayRender) {
    return null
  }

  return (
    <ChartStyleOverrides>
      <LoadingWrapper>
        <LoadingIndicator active={isLoading} />
      </LoadingWrapper>

      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            width: 0,
            flexShrink: 0,
            flexBasis: '100%',
            height: graphHeight
          }}
        >
          <ResponsiveContainer aspect={aspectRatio} debounce={16}>
            <ComposedChart
              data={plot}
              style={{
                overflow: 'visible',
                opacity: isLoading ? 0.3 : 1,
                filter: isLoading && 'grayscale(50%)',
                transition: 'all 400ms'
              }}
              margin={{
                left: 24,
                right: 24,
                top: 5,

                // Always add some bottom space even if there are no incidents to
                // prevent those ugly graph transitions, where the graph floats up
                // or down. It won't prevent it if there are multiple incidentRows,
                // but that shouldn't happen as often.
                bottom: 15 + INCIDENT_HEIGHT * (incidentRows.length || 1)
              }}
            >
              <defs>
                {[0, 1].map(i => (
                  <pattern
                    key={i}
                    id={'diagonalHatch' + i}
                    patternUnits="userSpaceOnUse"
                    width="7"
                    height="7"
                    patternTransform="rotate(-45 0 0)"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="7"
                      height="7"
                      fill={theme.chroma.chartAreas[i].fill.css()}
                    />
                    <line
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="7"
                      style={{
                        stroke: theme.chroma.chartAreas[i].hatch.css(),
                        strokeWidth: 2
                      }}
                    />
                  </pattern>
                ))}
              </defs>
              {xAxis}
              {!xAxis && (
                <XAxis
                  hide={!hasPlot}
                  dataKey="timestamp"
                  height={40}
                  interval={0}
                  axisLine={{
                    stroke: theme.chroma.pencil.css()
                  }}
                  tick={
                    hasPlot && (
                      <XAxisTick
                        startDate={plot[0].timestamp}
                        endDate={plot[plot.length - 1].timestamp}
                      />
                    )
                  }
                  tickLine={false}
                />
              )}
              <YAxis
                hide={!hasPlot}
                tick={{
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  fill: theme.chroma.pencil.css()
                }}
                axisLine={{
                  stroke: theme.chroma.pencil.css()
                }}
                tickLine={{
                  stroke: theme.chroma.pencil.css()
                }}
                padding={{ top: 10 }}
                width={50}
                tickFormatter={formatYTick}
              />
              {viewType !== 'zone'
                ? stackedChartKeys.map((chartKey, i) => {
                    const colorKey = stackedChartKeys.length - 1 - i

                    if (chartType === 'bar') {
                      return (
                        <Bar
                          key={i}
                          stackId={dataKey}
                          dataKey={point => get(point, chartKey, 0)}
                          fill={`url(#diagonalHatch${colorKey})`}
                          animationDuration={400}
                        />
                      )
                    } else if (chartType === 'multiBar') {
                      return (
                        <Bar
                          key={i}
                          type={INTERPOLATION_TYPE}
                          dataKey={chartKey}
                          fill={
                            chartColors
                              ? chartColors[i]
                              : theme.chroma.KPICharts[colorKey].fill.css()
                          }
                          strokeWidth={GRAPH_LINE_WIDTH}
                          animationDuration={400}
                          maxBarSize={50}
                        />
                      )
                    } else if (chartType === 'multiLine') {
                      return (
                        <Line
                          key={i}
                          type={INTERPOLATION_TYPE}
                          dataKey={`${chartKey}.${dataKey}`}
                          stroke={
                            chartColors[i]
                              ? chartColors[i]
                              : theme.chroma.KPICharts[colorKey].fill.css()
                          }
                          fill={
                            chartColors[i]
                              ? chartColors[i]
                              : theme.chroma.KPICharts[colorKey].fill.css()
                          }
                          strokeWidth={GRAPH_LINE_WIDTH}
                          animationDuration={400}
                          maxBarSize={50}
                          dot={false}
                        />
                      )
                    } else if (chartType === 'line') {
                      return (
                        <Line
                          type={INTERPOLATION_TYPE}
                          dataKey={chartKey}
                          stroke={theme.chroma.KPICharts[i].fill.css()}
                          strokeWidth={GRAPH_LINE_WIDTH}
                          dot={false}
                          animationDuration={400}
                        />
                      )
                    } else {
                      return (
                        <Area
                          key={i}
                          stackId={dataKey}
                          type={INTERPOLATION_TYPE}
                          dataKey={point => get(point, chartKey, undefined)}
                          fill={`url(#diagonalHatch${colorKey})`}
                          fillOpacity={1}
                          stroke={theme.chroma.base.css()}
                          strokeWidth={colorKey === 0 ? GRAPH_LINE_WIDTH : 0}
                          dot={false}
                          activeDot={{
                            fill: theme.chroma.chartAreas[
                              colorKey === 0 || colorKey === 1 ? colorKey : 0
                            ].fill.css()
                          }}
                          animationDuration={400}
                        />
                      )
                    }
                  })
                : stackedChartKeys.map((chartKey, i) => {
                    const colorKey = chartKey[0] == 99 ? 0 : chartKey[0]
                    const tint =
                      chartKey[1] === 'ltr' ? 'fillComplement' : 'fill'
                    if (chartType === 'bar') {
                      return (
                        <Bar
                          key={i}
                          stackId={dataKey}
                          dataKey={point => {
                            const data = get(point, 'zones')
                            return data && data.has(chartKey[0])
                              ? data.get(chartKey[0])[chartKey[1]]
                              : 0
                          }}
                          fill={theme.chroma.zones[colorKey][tint].css()}
                          animationDuration={400}
                        />
                      )
                    } else {
                      return (
                        <Area
                          key={i}
                          stackId={dataKey}
                          type={INTERPOLATION_TYPE}
                          dataKey={point => {
                            const data = get(point, 'zones')
                            return data && data.has(chartKey[0])
                              ? data.get(chartKey[0])[chartKey[1]]
                              : undefined
                          }}
                          fill={theme.chroma.zones[colorKey][tint].css()}
                          fillOpacity={1}
                          strokeWidth={0}
                          dot={false}
                          activeDot={{
                            fill: theme.chroma.zones[colorKey][tint].css()
                          }}
                          animationDuration={400}
                        />
                      )
                    }
                  })}
              )
              {viewType === 'compare' && compareType !== 'multiLocation' && (
                <Line
                  type={INTERPOLATION_TYPE}
                  dataKey="comparePedestriansCount"
                  stroke={theme.chroma.accent.css()}
                  strokeWidth={GRAPH_LINE_WIDTH}
                  dot={false}
                  animationDuration={400}
                />
              )}
              {displayLegend && <Legend />}
              {!isSSR && !isLoading && displayTooltip !== false && (
                <Tooltip
                  offset={0}
                  position={{ y: 0 }}
                  content={customTooltipContent || handleTooltipChange} // handleTooltipChange
                  cursor={<Cursor />}
                />
              )}
              {hasPlot &&
                incidentRows.map((incidentRow, rowIndex) =>
                  incidentRow.map(incident => (
                    <Customized
                      key={incident.id}
                      bottomMargin={
                        INCIDENT_HEIGHT * (incidentRows.length - 1 - rowIndex)
                      }
                      incident={incident}
                      isDetailActive={incident.id === activeIncidentDetail}
                      onCloseDetail={() => setActiveIncidentDetail(null)}
                      onClick={() => {
                        setActiveIncidentDetail(incident.id)
                      }}
                      component={Incident}
                    />
                  ))
                )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </ChartStyleOverrides>
  )
}

Graph.propTypes = {
  plot: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  incidentRows: PropTypes.array,
  onHover: PropTypes.func,
  aspectRatio: PropTypes.number,
  stackedChartKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  dataKey: PropTypes.string,
  chartType: PropTypes.string,
  locationMetadata: PropTypes.object,
  viewType: PropTypes.string,
  compareType: PropTypes.string,
  displayLegend: PropTypes.bool,
  displayTooltip: PropTypes.bool,
  customTooltipContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  xAxis: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  chartColors: PropTypes.arrayOf(PropTypes.string),
  delayRender: PropTypes.bool,
  graphHeight: PropTypes.number
}

const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  pointer-events: none;
`

const ChartStyleOverrides = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;

  .recharts-responsive-container {
    flex: 1 auto;
  }
`

export default Graph
