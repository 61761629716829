import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import CloseButton from '../CloseButton'

import theme from '../../theme'
import I18n from '../../i18n'

ReactModal.setAppElement('#__main')

function Modal({
  children,
  showCloseButton,
  title,
  fullWidth = true,
  fullHeight = true,
  ...rest
}) {
  let contentExtend = null

  function handleCloseButtonClick() {
    rest.onRequestClose()
  }

  return (
    <ReactModal
      className={{
        base: css`
          ${content}
          ${contentExtend}
        `,
        afterOpen: contentAfterOpen,
        beforeClose: contentBeforeClose
      }}
      overlayClassName={{
        base: overlay,
        afterOpen: overlayAfterOpen,
        beforeClose: overlayBeforeClose
      }}
      closeTimeoutMS={120}
      {...rest}
    >
      <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {showCloseButton && (
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseBox>
              <CloseButton onClick={handleCloseButtonClick} />
            </CloseBox>
          </div>
        )}
        {title && (
          <>
            <Header showCloseButton={showCloseButton}>{I18n.t(title)}</Header>
            <Divider />
          </>
        )}
        {children}
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  showCloseButton: PropTypes.bool,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool
}

const Header = styled.div`
  display: flex;
  margin-top: ${props => props.showCloseButton && '-3.5rem'};
  min-height: 3.5rem;
  margin-right: ${props => props.showCloseButton && '3.5rem'};
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.chroma.base.hex()};

  ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`

const Divider = styled.div`
  min-height: 1px;
  height: 1px;
  background-color: ${props => props.theme.chroma.base.hex()};
  margin: 0 -5px;
`

const CloseBox = styled.div(props => ({
  height: '3.5rem',
  width: '3.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [props.theme.breakpoints.tablet]: {
    width: 'auto',
    height: 'auto',
    marginBottom: '0.5rem'
  }
}))

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.chroma.base.alpha(0.6).css()};
  z-index: 5;
  opacity: 0;
  transition: opacity 120ms ease-in-out;
  display: flex;
  justify-content: center;
`

const content = css`
  position: absolute;
  top: 0.75rem;
  max-width: 70rem;
  width: 90%;
  bottom: 0.75rem;
  padding: 0.3125rem;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
  border: 0;
  -webkit-overflow-scrolling: touch;
  outline: none;
  background-color: white;
  transition: transform 120ms ease-in-out, opacity 120ms ease-in-out;
  transform: translateY(-6px);
  opacity: 0;
  overflow: auto;
`

const contentAfterOpen = css`
  transform: translateY(0) !important;
  opacity: 1 !important;
`

const contentBeforeClose = css`
  transform: translateY(6px) !important;
  opacity: 0 !important;
`

const overlayAfterOpen = css`
  opacity: 1 !important;
`

const overlayBeforeClose = css`
  opacity: 0 !important;
`

export default Modal
