import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'

import { Trans } from '../../i18n'

import { useSearch } from '../../lib/use-search'

import { CsrfContext } from '../forms/Form'
import ListFavs from './ListFavs'
import ListAll from './ListAll'

export const FAV_TYPE = {
  ADD: 1,
  REMOVE: 2
}

function LocationSelector({
  locations,
  onSelect,
  favable = true,
  disabledLocationIds = [],
  zoomable = true,
  mapLocations
}) {
  const {
    query,
    searchHandler,
    currentFavs,
    filteredFavs,
    filteredLocations
  } = useSearch()
  const csrfToken = useContext(CsrfContext)

  const locationIdsWithGeoJson = mapLocations
    ?.map(location => location.id)
    ?.sort((a, b) => a - b)

  function handleFavChange(locationIds, { type }) {
    let method
    if (type === FAV_TYPE.ADD) method = 'POST'
    else if (type === FAV_TYPE.REMOVE) method = 'DELETE'
    else throw new Error('Unknown FAV_TYPE ' + type)

    // Optimistic UI: mark them as favorite before getting server response
    let newCurrentFavs = []
    if (type === FAV_TYPE.ADD) {
      newCurrentFavs = uniq([...currentFavs, ...locationIds])
    } else if (type === FAV_TYPE.REMOVE) {
      newCurrentFavs = currentFavs.filter(id => !locationIds.includes(id))
    }
    searchHandler({ newCurrentFavs })

    fetch('/locations/favorites', {
      method,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: locationIds })
    })
      .then(res => res.json())
      .then(responseIds => {
        const responseIsSameAsCurrent = isEqual(
          responseIds.sort(),
          newCurrentFavs.sort()
        )
        if (responseIsSameAsCurrent) return

        searchHandler({ newCurrentFavs: responseIds })
      })
  }

  return (
    <React.Fragment>
      {filteredFavs?.length > 0 && (
        <ListNarrowContainer>
          <ListFavs
            onFavChange={handleFavChange}
            locations={filteredFavs.map(id => locations[id])}
            onSelect={onSelect}
            favable={favable}
            zoomable={zoomable}
            disabledLocationIds={disabledLocationIds}
            locationIdsWithGeoJson={locationIdsWithGeoJson}
          />
        </ListNarrowContainer>
      )}
      <div>
        {filteredLocations.length ? (
          <ListAll
            groupedLocations={filteredLocations}
            onFavChange={handleFavChange}
            favLocationIds={filteredFavs}
            disabledLocationIds={disabledLocationIds}
            onSelect={onSelect}
            favable={favable}
            zoomable={zoomable}
            locationIdsWithGeoJson={locationIdsWithGeoJson}
          />
        ) : (
          <NoResultsText>
            <Trans id="locations.no_results" values={{ query }} escape />
          </NoResultsText>
        )}
      </div>
    </React.Fragment>
  )
}

LocationSelector.propTypes = {
  locations: PropTypes.object.isRequired,
  favable: PropTypes.bool,
  onSelect: PropTypes.func,
  disabledLocationIds: PropTypes.arrayOf(PropTypes.number),
  zoomable: PropTypes.bool,
  mapLocations: PropTypes.arrayOf(PropTypes.object)
}

const NoResultsText = styled.div`
  padding: 2rem;
  font-size: 2rem;
  font-style: italic;
  text-align: center;
`

const ListNarrowContainer = styled.div`
  margin-top: 1.5rem;

  ${p => p.theme.breakpoints.phone} {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

export function normalizeQuery(searchString) {
  return searchString.trim().toLowerCase()
}

export default LocationSelector
