import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Headline from './Headline'
import { ListItem } from './Bullets'

import rehypeRaw from 'rehype-raw'

function Markdown({ escape = false, copy, content }) {
  const htmlProps = copy
    ? {}
    : { disallowedElements: ['p'], unwrapDisallowed: true }

  return (
    <ReactMarkdown
      {...htmlProps}
      rehypePlugins={escape ? [] : [rehypeRaw]}
      components={{
        h1: ({ ...props }) => <Headline breakout {...props} />, // eslint-disable-line react/display-name
        h2: ({ ...props }) => <Headline breakout {...props} />, // eslint-disable-line react/display-name
        h3: ({ ...props }) => <Headline breakout {...props} />, // eslint-disable-line react/display-name
        li: ({ ...props }) => <ListItem {...props} /> // eslint-disable-line react/display-name
      }}
    >
      {content}
    </ReactMarkdown>
  )
}

Markdown.propTypes = {
  copy: PropTypes.bool,
  escape: PropTypes.bool,
  content: PropTypes.string
}

export default Markdown
