import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'
import {
  ItemContainer,
  TimestampLabel,
  TimestampRow
} from './LocationStatsCardItemBase'

export const NoDataPresentForAskedTimeRangeContainer = styled.div(props => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'center',
    textAlign: 'center'
  }
})

export const NoDataPresentHeader = styled.div(props => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'center',
    textAlign: 'center'
  }
})

export const NoDataPresentStatsCardItem = ({
  title,
  message,
  dataPresentSincetimestamp,
  askedtimerange,
  variant,
  minHeight,
  hideBorder,
  fullWidth = true
}) => {
  const minContainerHeight = minHeight || (variant === 'cardHeader' ? 100 : 90)

  return (
    <ItemContainer
      justifyContent={'center'}
      minHeight={minContainerHeight}
      hideBorder={hideBorder || variant === 'cardHeader'}
      fullWidth={fullWidth}
    >
      <TimestampRow>
        <TimestampLabel>{askedtimerange}</TimestampLabel>
      </TimestampRow>
      <NoDataPresentForAskedTimeRangeContainer>
        {title && <TimestampLabel>{title}</TimestampLabel>}

        {message && <TimestampLabel bold>{message}</TimestampLabel>}

        {dataPresentSincetimestamp && (
          <TimestampLabel css={{ paddingLeft: 15 }}>
            {`${I18n.t(
              'location.details.measurements_started_from'
            )}   ${I18n.strftime(
              new Date(dataPresentSincetimestamp),
              '%d.%m.%Y'
            )}   `}
          </TimestampLabel>
        )}
      </NoDataPresentForAskedTimeRangeContainer>
    </ItemContainer>
  )
}

NoDataPresentStatsCardItem.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  dataPresentSincetimestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  askedtimerange: PropTypes.string,
  minHeight: PropTypes.number,
  variant: PropTypes.oneOf(['cardHeader', 'cardItem']),
  hideBorder: PropTypes.bool,
  fullWidth: PropTypes.bool
}
