import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

import I18n, { Trans } from '../i18n'
import { navigationStructure } from '../lib/types'
import theme from '../theme'

import EmailIcon from '../sections/assets/mail.svg'
import PhoneIcon from '../sections/assets/phone.svg'

import { Graph as GraphIcon } from './Icon'
import Section from './Section'

import Logo from './assets/logo.svg'
import StadtretterLogo from './assets/images/stadtretter.png'
import BcsdLogo from './assets/images/bcsd-foerdermitglied.png'

function Footer({ pages }) {
  const navigationPages = pages.slice(0, -2)
  const languageLinks = pages.slice(-2)

  return (
    <FooterBackground>
      <GraphSection>
        <IconWrapper>
          <GraphIcon />
        </IconWrapper>
      </GraphSection>
      <Section.Wrapper>
        <FooterNav>
          <TwoSpanSection>
            <a href="/">
              <Logo
                height={45}
                css={{ marginBottom: '22px', marginTop: '-10px' }}
              />
            </a>
            <ContactWrapper>
              <ContactIconWrapper>
                <EmailIcon />
              </ContactIconWrapper>
              <ContactIconText href="mailto:info@hystreet.com">
                <Trans id="contact.email" />
              </ContactIconText>
            </ContactWrapper>
            <ContactWrapper>
              <ContactIconWrapper>
                <PhoneIcon />
              </ContactIconWrapper>
              <ContactIconText href="tel:+4922177204251">
                <Trans id="contact.phone" />
              </ContactIconText>
            </ContactWrapper>
            <LinkList
              css={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1.5rem',
                gap: '1rem',
                fontSize: '1rem'
              }}
            >
              <ListItem key={I18n.t('footer.contact')}>
                <a href="/contact">{I18n.t('footer.contact')}</a>
              </ListItem>
              <ListItem key={I18n.t('footer.faq')}>
                <a href="/faq">{I18n.t('footer.faq')}</a>
              </ListItem>
            </LinkList>
          </TwoSpanSection>
          <Section>
            <LinkList>
              {navigationPages.map(page => (
                <ListItem key={page.name}>
                  <a href={page.link} {...page.attrs}>
                    {page.name}
                  </a>
                </ListItem>
              ))}
            </LinkList>
          </Section>
          <Section>
            <LinkList>
              <ListItem>
                <a href="/imprint">{I18n.t('footer.imprint')}</a>
              </ListItem>
              <ListItem>
                <a href="/agb">{I18n.t('footer.terms')}</a>
              </ListItem>
              <ListItem>
                <a href="/privacy">{I18n.t('footer.privacy_policy')}</a>
              </ListItem>
            </LinkList>
          </Section>
          <Section>
            <LinkList
              css={{ display: 'flex', alignItems: 'center', marginTop: '0' }}
            >
              {languageLinks.map(link => (
                <LanguageListItem key={link.name}>
                  <LanguageLink href={link.link} isActive={link.isActive}>
                    {link.name}
                  </LanguageLink>
                </LanguageListItem>
              ))}
            </LinkList>
            <ImageContainer>
              <div>
                <a
                  href="https://www.die-stadtretter.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={StadtretterLogo} alt="Stadtretter Logo" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.bcsd.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={BcsdLogo}
                    alt="Logo bcsd.de"
                    css={{
                      backgroundColor: 'transparent',
                      padding: '0 .5rem'
                    }}
                  />
                </a>
              </div>
            </ImageContainer>
          </Section>
        </FooterNav>
      </Section.Wrapper>
      <BottomWrapper>
        <Section.Wrapper>
          <FooterBottom
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Item>
              <address css={{ fontStyle: 'normal' }}>
                © hystreet.com GmbH
              </address>
            </Item>
          </FooterBottom>
        </Section.Wrapper>
      </BottomWrapper>
    </FooterBackground>
  )
}

const TwoSpanSection = styled(Section)`
  ${theme.breakpoints.tablet} {
    grid-area: auto/span 2;
  }

  ${theme.breakpoints.phone} {
    grid-area: auto/span 1;
  }
`

const ListItem = styled.li`
  text-align: left;
`

const LanguageListItem = styled(ListItem)`
  &:last-child {
    margin-left: 1rem;
    position: relative;
    &::before {
      position: absolute;
      left: -0.65rem;
      content: '|';
    }
  }
`

const LanguageLink = styled.a`
  opacity: ${props => (props.isActive ? 0.3 : 1)};
  pointer-events: ${props => props.isActive && 'none'};
`

const GraphSection = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 2rem;
  transform: translateY(-2rem);
`

const FooterBackground = styled.footer`
  background-color: ${props => props.theme.chroma.base.hex()};
  color: ${props => props.theme.chroma.nearlyWhite.hex()};
  padding-bottom: 1.875rem;

  @media print {
    display: none;
  }
`

const FooterNav = styled.nav`
  padding-bottom: 2.5rem;
  display: grid;
  grid: auto/repeat(2, 1fr);
  row-gap: 3rem;

  ${props => props.theme.breakpoints.tabletUp} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${props => props.theme.breakpoints.phone} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const FooterBottom = styled.div`
  display: grid;
  grid: auto/1fr;
  row-gap: 1.25rem;
  ${props => props.theme.breakpoints.tabletUp} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const BottomWrapper = styled.div`
  border-top: 2px solid
    ${props => props.theme.chroma.nearlyWhite.alpha(0.3).hex()};
  padding-top: 1.5rem;
`

const LinkList = styled.ul`
  width: 100%;
  font-size: 1.15rem;
  line-height: 2;
`

const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding: 1.125rem 1.25rem;
  transform: translateY(-2rem);
  border-radius: 50%;
  border: 1.5px solid
    ${props => props.theme.chroma.nearlyWhite.alpha(0.3).hex()};
  background-color: ${props => props.theme.chroma.base.hex()};
  color: ${props => props.theme.chroma.nearlyWhite.alpha(0.3).hex()};
`

const Item = styled.div`
  font-size: 1rem;
  line-height: 2;
  ${props => props.theme.breakpoints.tabletUp} {
    &:nth-of-type(2) {
      grid-column: 2 / 4;
    }
  }
`

const ImageContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  padding-top: 0.5rem;
`

const Image = styled.img`
  background-color: ${props => props.theme.chroma.white.hex()};
  padding: 0.5rem 1rem;
  display: block;
  width: auto;
  height: 90px;
`

const ContactWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContactIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  color: ${props => props.theme.chroma.nearlyWhite.hex()};
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const ContactIconText = styled.a`
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1.25;
  margin-left: 1rem;
`

Footer.propTypes = {
  pages: navigationStructure
}

export default Footer
