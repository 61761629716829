import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Bullets({ children, style }) {
  return (
    <ul style={style}>
      {React.Children.map(children, (child, index) => (
        <ListItem key={index}>{child}</ListItem>
      ))}
    </ul>
  )
}

export const ListItem = styled.li`
  position: relative;
  padding-left: 1.6rem;
  margin-bottom: 1rem;

  &:before {
    content: '';
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    background-color: ${p => p.theme.chroma.accent.hex()};
    top: 0.325rem;
    left: 0;
  }
`

Bullets.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
}

export default Bullets
