import PropTypes from 'prop-types'
import React, { createContext, useContext, useReducer } from 'react'

export const context = createContext()

export default function useAuthentication() {
  return useContext(context)
}

const loginStateReducer = (state, action) => {
  return { ...state, loggedIn: action.loggedIn }
}

export function AuthenticationProvider({ initialLoginState, ...nativeProps }) {
  const [loginState, dispatchLoginStateChange] = useReducer(
    loginStateReducer,
    initialLoginState
  )

  return (
    <context.Provider
      value={{ loginState, dispatchLoginStateChange }}
      {...nativeProps}
    />
  )
}

AuthenticationProvider.propTypes = {
  initialLoginState: PropTypes.shape({
    loggedIn: PropTypes.bool
  })
}
