import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useMedia } from 'use-media'

const Section = styled.section`
  position: relative;

  background-color: ${props => {
    if (props.dark) return props.theme.chroma.base.hex()
    if (props.accent) return props.theme.chroma.accent.hex()
    if (props.mid) return props.theme.chroma.nearlyWhite.hex()
    if (props.light) return props.theme.chroma.white.hex()
    if (props.darkerGrey) return props.theme.chroma.darkerGrey.hex()
  }};

  ${props =>
    props.row &&
    `
      display: flex;
      flex-direction: ${props.rowPhoneReverse ? 'column-reverse' : 'column'};

      ${props.theme.breakpoints.phoneUp} {
        flex-direction: row;
        justify-content: stretch;
      }
    `}
`

Section.propTypes = {
  dark: PropTypes.bool,
  accent: PropTypes.bool,
  mid: PropTypes.bool,
  light: PropTypes.bool,
  row: PropTypes.bool,

  rowPhoneReverse: PropTypes.bool
}

export default Section

Section.Skew = Skew
Section.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin: 0 auto;
`

Section.Half = styled.div`
  display: ${props => (props.hideMobile ? 'none' : 'flex')};
  position: relative;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;

  ${props => props.theme.breakpoints.phoneUp} {
    display: flex;

    /** percentage (basis) of container, plus remaining window width of the half */
    flex-basis: ${props =>
      `calc((100vw - ${props.wrapperWidth}) / 2 + ${
        props.wrapperWidth
      } * ${props.basis / 100})`};
  }

  ${props =>
    props.image &&
    `
      background-image: url(${props.image});
      background-size: cover;
      background-position: center;
    `}

  ${Section.Wrapper} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    ${props => props.theme.breakpoints.phoneUp} {
      min-width: 22rem;
      max-width: ${props =>
        `calc(${props.wrapperWidth} * ${props.basis / 100})`};
    }
  }

  &:first-of-type {
    align-items: flex-end;
  }
`

Section.Half.propTypes = {
  basis: PropTypes.number,
  hideMobile: PropTypes.bool
}
Section.Half.defaultProps = {
  basis: 50,
  wrapperWidth: '75rem'
}

function Skew(props) {
  const {
    position = 'top',
    direction = 'up',
    dark,
    darkerGrey,
    accent,
    mid,
    light,
    inset,
    height,
    innerCss
  } = props
  const opposite = {
    direction: direction === 'up' ? 'down' : 'up',
    position: position === 'top' ? 'bottom' : 'top'
  }
  const isBigScreen = useMedia({ minWidth: 2100 })
  const defaultHeight = isBigScreen ? '4vw' : '10vw'

  const triangles = {
    bottom: {
      up: '0,0 100,0 0,100',
      down: '0,0 100,0 100,100'
    },
    top: {
      up: '0,100 100,100 100,0',
      down: '0,100 100,100 0,0'
    }
  }

  const points = inset
    ? triangles[opposite.position][direction]
    : triangles[position][direction]

  const cssPosition = inset
    ? { [position]: 0 }
    : { [opposite.position]: '100%' }

  // Shift skew 1px up/down to avoid thin lines in some browsers
  const moveUp =
    (position === 'top' && inset) || (position === 'bottom' && !inset)

  const Svg = styled.svg`
    display: block;
    width: 100%;
    height: ${height || defaultHeight};
    position: absolute;
    z-index: 1;
    pointer-events: none;
    left: 0;
    right: 0;

    margin-bottom: ${!moveUp && '-1px'};
    margin-top: ${moveUp && '-1px'};

    fill: ${p => {
      if (dark) return p.theme.chroma.base.hex()
      if (mid) return p.theme.chroma.nearlyWhite.hex()
      if (accent) return p.theme.chroma.accent.hex()
      if (light) return p.theme.chroma.white.hex()
      if (darkerGrey) return p.theme.chroma.darkerGrey.hex()
      console.warn('No Section.Skew type given! Use dark, mid or light.') // eslint-disable-line no-console
      return 'red'
    }};

    ${innerCss}
  `

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
      style={cssPosition}
    >
      <polygon points={points} />
    </Svg>
  )
}

Skew.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  direction: PropTypes.oneOf(['up', 'down']),
  mid: PropTypes.bool,
  accent: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  darkerGrey: PropTypes.bool,
  inset: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerCss: PropTypes.any
}
