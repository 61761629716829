import styled from '@emotion/styled'

const Link = styled.a`
  color: ${p => p.theme.chroma.accent.css()};
  font-weight: 500;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

export default Link
