import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Headline from '../components/Headline'
import Section from '../components/Section'

function ScientificWork({ scientificWorkData }) {
  return (
    <Section.Wrapper>
      <ContentWrapper>
        <CustomHeadline breakout>
          <Trans id="pages.press.scientific_work.headline" />
        </CustomHeadline>
        <List>
          {scientificWorkData.map(p => {
            const { title: title, author: author, url: url } = p
            return (
              <ListItem key={p.id}>
                <Title>{title}</Title>
                <Author>{author}</Author>
                <Link href={url}>{url}</Link>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>
    </Section.Wrapper>
  )
}

const ContentWrapper = styled.div`
  padding-top: 4rem;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ListItem = styled.div`
  margin: 1.5rem 0;
`

const CustomHeadline = styled(Headline)`
  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 1.5rem;
  }
`

const Title = styled.h3`
  font-weight: 900;
  line-height: 1.4;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: ${p => p.theme.chroma.accent.css()};
`

const Author = styled.p`
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const Link = styled.a`
  line-height: 1.563;
  font-style: italic;
  text-decoration: underline;
  overflow-wrap: break-word;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

ScientificWork.propTypes = {
  scientificWorkData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
}

export default ScientificWork
