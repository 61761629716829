import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

export default function ErrorBox({ errors }) {
  return (
    <React.Fragment>
      {errors.map((error, index) => {
        return <ErrorLabel key={index}>{error}</ErrorLabel>
      })}
    </React.Fragment>
  )
}

const ErrorLabel = styled.p`
  color: ${p => p.theme.chroma.bad.css()};
`

ErrorBox.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.String)
}
