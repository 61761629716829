import PropTypes from 'prop-types'
import React from 'react'
import useFilter from './use-filter'
import {
  useQueryLocation,
  useQueryLocations
} from './api/location/use-location-api'
import { locationApiResponseTransformers } from './api/location/location-api'

const context = React.createContext({})

export default function useLocation() {
  return React.useContext(context)
}

export function LocationProvider({
  id,
  locations,
  averagesPerHour,
  averagesPerDay,
  swapDirection,
  ...rest
}) {
  const [filter] = useFilter()
  const preloadedLocation = React.useMemo(
    () => locationApiResponseTransformers.metaDataToDates(locations[id]),
    [locations, id]
  )

  const locationFilter = {
    from: filter.from,
    to: filter.to,
    resolution: filter.resolution,
    object_type: filter.objectType,
    object_subtype: filter.objectSubtype
  }

  const {
    data: location = preloadedLocation,
    status: locationStatus,
    isFetching: isLocationFetching
  } = useQueryLocation(['location', id, locationFilter])

  const {
    data: locationV2 = preloadedLocation,
    status: locationV2Status,
    isFetching: isLocationV2Fetching
  } = useQueryLocation(['location', id, locationFilter, 'v2'])

  const {
    data: compareLocations,
    status: compareLocationsStatus,
    isFetching: isCompareLocationsFetching
  } = useQueryLocations(
    ['compareLocations', filter.compareLocations, locationFilter],
    true
  )

  const isTimerangeCompare =
    filter.viewType === 'compare' && filter.compareType === 'timerange'

  const compareFilter = {
    resolution: filter.resolution,
    from: isTimerangeCompare ? filter.compareFrom || filter.from : filter.from,
    to: isTimerangeCompare ? filter.compareTo || filter.to : filter.to
  }

  const {
    data: compareLocation = locations[id],
    status: compareLocationStatus,
    isFetching: isCompareLocationFetching
  } = useQueryLocation(['location', id, compareFilter])

  let status = 'loading'
  if (
    locationStatus === 'success' &&
    compareLocationStatus === 'success' &&
    compareLocationsStatus === 'success' &&
    locationV2Status === 'success'
  ) {
    status = 'success'
  }
  if (
    locationStatus === 'error' ||
    compareLocationStatus === 'error' ||
    compareLocationsStatus === 'error' ||
    locationV2Status === 'error'
  ) {
    status = 'error'
  }

  const isFetching =
    isLocationFetching ||
    isCompareLocationFetching ||
    isCompareLocationsFetching ||
    isLocationV2Fetching

  // The walking directions in the frontend can be switched, because the
  // rotation of the map isn't necessarily the same as the orientation of the laser.
  const directions = {
    swapDirection: swapDirection,
    ltr: {
      dataKey: swapDirection ? 'rtl' : 'ltr',
      label: swapDirection
        ? location.metadata.rtlLabel
        : location.metadata.ltrLabel
    },
    rtl: {
      dataKey: swapDirection ? 'ltr' : 'rtl',
      label: swapDirection
        ? location.metadata.ltrLabel
        : location.metadata.rtlLabel
    }
  }

  return (
    <context.Provider
      value={{
        // location and compareLocation always contain at least the location_summary
        location,
        locationV2,
        compareLocation,
        compareLocations,
        locations,
        directions,
        averagesPerHour,
        averagesPerDay,
        status,
        isFetching
      }}
      {...rest}
    />
  )
}

LocationProvider.propTypes = {
  id: PropTypes.number.isRequired,
  locations: PropTypes.object.isRequired,
  averagesPerHour: PropTypes.object.isRequired,
  averagesPerDay: PropTypes.object.isRequired,
  swapDirection: PropTypes.bool
}
