import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'
import Headline from '../components/Headline'
import Section from '../components/Section'
import VitalityIndexCard from '../components/VitalityIndexCard'
import PressDownload from '../components/assets/icons/press-download.svg'
import CitiesUnder50k from '../components/assets/images/hystreet_vitality_index/cities_under_50k_2023.jpg'
import Cities50k100k  from '../components/assets/images/hystreet_vitality_index/cities_50k_100k_2023.jpg'
import Cities100k250k from '../components/assets/images/hystreet_vitality_index/cities_100k_250k_2023.jpg'
import Cities250k600k from '../components/assets/images/hystreet_vitality_index/cities_250k_600k_2023.jpg'
import CitiesOver600k from '../components/assets/images/hystreet_vitality_index/cities_over_600k_2023.jpg'

import ImageModal from '../components/ImageModal'

function Analyses() {
  const [modalOpen, setModalOpen] = useState(false)
  const [activeImage, setActiveImage] = useState(null)

  return (
    <Section.Wrapper>
      <CustomHeadline breakout>
        <Trans id="pages.press.analyses.headline" />
      </CustomHeadline>
      <Subtitle breakout>
        <Trans id="pages.press.analyses.subtitle" />
      </Subtitle>
      <ContentWrapper breakout>
        <PressText>
          <TextHeadline>{I18n.t('pages.press.analyses.title')}</TextHeadline>
          <Subline>{I18n.t('pages.press.analyses.subline')}</Subline>
          <ReadMore>{I18n.t('pages.press.analyses.read_more')}</ReadMore>
        </PressText>
        <VitalityIndexCardWrapper>
          {VitalityIndexCards.map((card, index) => {
            return (
              <VitalityIndexCard
                imageSource={card.imgSource}
                title={card.title}
                info={card.info}
                downloadSource={card.downloadSource}
                key={index}
                onImageZoomClick={downloadSource => {
                  setActiveImage(downloadSource)
                  setModalOpen(true)
                }}
              ></VitalityIndexCard>
            )
          })}
          <ImageModal
            isOpen={modalOpen}
            onRequestClose={() => {
              setModalOpen(false)
            }}
            imageSource={activeImage}
          />
          <DownloadAll>
            <ButtonWrapper href="/hystreet_vitality_index/hystreet_vitality_index_evaluations.zip">
              <PressDownload></PressDownload>
              <DownloadAllText>
                <Trans id="pages.press.analyses.download_all" />
              </DownloadAllText>
            </ButtonWrapper>
          </DownloadAll>
        </VitalityIndexCardWrapper>
      </ContentWrapper>
    </Section.Wrapper>
  )
}

const VitalityIndexCards = [
  {
    title: <Trans id="pages.press.analyses.cities_under_50k" />,
    info: '253 KB, JPG',
    imgSource: CitiesUnder50k,
    downloadSource: '/hystreet_vitality_index/cities_under_50k_2023.jpg'
  },
  {
    title: <Trans id="pages.press.analyses.cities_50k_100k" />,
    info: '221 KB, JPG',
    imgSource: Cities50k100k,
    downloadSource: '/hystreet_vitality_index/cities_50k_100k_2023.jpg'
  },
  {
    title: <Trans id="pages.press.analyses.cities_100k_250k" />,
    info: '339 KB, JPG',
    imgSource: Cities100k250k,
    downloadSource: '/hystreet_vitality_index/cities_100k_250k_2023.jpg'
  },
  {
    title: <Trans id="pages.press.analyses.cities_250k_600k" />,
    info: '333 KB, JPG',
    imgSource: Cities250k600k,
    downloadSource: '/hystreet_vitality_index/cities_250k_600k_2023.jpg'
  },
  {
    title: <Trans id="pages.press.analyses.cities_over_600k" />,
    info: '339 KB, JPG',
    imgSource: CitiesOver600k,
    downloadSource: '/hystreet_vitality_index/cities_over_600k_2023.jpg'
  }
]

const ReadMore = ({ children }) => {
  const text = children
  const [readMore, setReadMore] = useState(true)
  const toggleReadMore = () => {
    setReadMore(!readMore)
  }
  return (
    <Text>
      {readMore ? text.slice(0, 610) : text}
      {text.length > 600 ? (
        <ReadOrHide>
          <span onClick={toggleReadMore}>
            {readMore ? (
              <Trans id="pages.press.news.read_more" />
            ) : (
              <Trans id="pages.press.news.show_less" />
            )}
          </span>
        </ReadOrHide>
      ) : (
        ''
      )}
    </Text>
  )
}

const ContentWrapper = styled.div`
  padding: 2rem;
  background: white;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 0.3);
  ${p => p.theme.breakpoints.tabletUp} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

const DownloadAllText = styled.p`
  margin-left: 1rem;
  font-weight: 700;
  color: ${p => p.theme.chroma.accent.css()};
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const DownloadAll = styled.div`
  ${p => p.theme.breakpoints.tabletUp} {
    grid-row: 4;
  }
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1.5rem;
  ${p => p.theme.breakpoints.tabletUp} {
    margin-top: 0rem;
    align-items: flex-end;
    justify-content: flex-end;
  }
`

const ButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const VitalityIndexCardWrapper = styled.div`
  display: grid;
  gap: 2rem;
  ${p => p.theme.breakpoints.tabletUp} {
    margin: 2rem 0rem;
    grid-column: 1/4;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    gap: 20px 70px;
  }
`

const CustomHeadline = styled(Headline)`
  color: ${p => p.theme.chroma.white.css()};
`

const PressText = styled.div`
  grid-column: 1/3;
  margin-bottom: 2rem;
`

const TextHeadline = styled.h3`
  font-weight: 900;
  line-height: 1.8;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const Subtitle = styled.h2`
  font-weight: 900;
  padding-bottom: 0.9rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 0.5rem;
  color: ${p => p.theme.chroma.white.css()};
  ${p => p.theme.breakpoints.tabletUp} {
    line-height: 2rem;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: ${p => (p.breakout ? '-1rem' : '0')};
  }
`

const Subline = styled.p`
  line-height: 1.75;
  white-space: pre-wrap;
  font-weight: 700;
  margin: 1rem 0rem;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const Text = styled.p`
  line-height: 1.75;
  white-space: pre-wrap;
  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

const ReadOrHide = styled.button`
  margin-top: 0.5rem;
  font-weight: 700;
  padding-left: 0.7rem;
  color: ${p => p.theme.chroma.accent.css()};
  cursor: pointer;
`

Analyses.propTypes = {}

ReadMore.propTypes = {
  children: PropTypes.string.isRequired
}

export default Analyses
