import PropTypes from 'prop-types'
import React, { createContext, useContext, useReducer } from 'react'

export const context = createContext()

export default function useAuthorization() {
  return useContext(context)
}

const permissionsReducer = (state, updatedPermissions) => {
  return { ...state, ...updatedPermissions }
}

export function AuthorizationProvider({ initialPermissions, ...nativeProps }) {
  const [permissions, dispatchPermissionChange] = useReducer(
    permissionsReducer,
    initialPermissions
  )

  return (
    <context.Provider
      value={{ permissions, dispatchPermissionChange }}
      {...nativeProps}
    />
  )
}

AuthorizationProvider.propTypes = {
  initialPermissions: PropTypes.object
}
