import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import getLocationStateLabel from '../../lib/getLocationStateLabel'
import I18n from '../../i18n'
import FavStar from '../FavStar'
import { MapLight as MapIcon } from '../../components/Icon'

function LocationLabel({
  title,
  sup,
  favable = true,
  favorite,
  href,
  highlighted,
  onMainClick,
  onFavClick,
  thin,
  disabled,
  margin,
  padding,
  population,
  state,
  zoomable = true,
  zoomableLocation,
  onZoomableLocationClick
}) {
  let ClickableComponent = Clickable
  let clickableProps = {}

  if (onMainClick) {
    ClickableComponent = ClickableComponent.withComponent('button')
    clickableProps = !disabled && { onClick: onMainClick }
  } else if (href) {
    ClickableComponent = ClickableComponent.withComponent('a')
    clickableProps = !disabled && { href }
  }

  return (
    <Container thin={thin} disabled={disabled} margin={margin}>
      {favable && (
        <FavContainer>
          <FavButton
            onClick={onFavClick}
            aria-label={
              favorite
                ? I18n.t('locations.add_fav_a11y')
                : I18n.t('locations.remove_fav_a11y')
            }
          >
            <FavStar filled={favorite} />
          </FavButton>
        </FavContainer>
      )}
      {!favable && favorite && (
        <FavContainer disabled>
          <FavStar filled={favorite} />
        </FavContainer>
      )}
      <ClickableComponent
        {...clickableProps}
        highlighted={highlighted}
        padding={padding}
      >
        {sup && <div className="sup">{sup}</div>}
        <span css={{ marginRight: `${(population || state) && '1rem'}` }}>
          {title}
        </span>
        {population && (
          <span className="info">
            {I18n.toNumber(population, {
              precision: 0
            })}{' '}
            {I18n.t('location.population')}
          </span>
        )}
        {state && (
          <span className="info">({getLocationStateLabel(state)})</span>
        )}
      </ClickableComponent>
      {zoomable && zoomableLocation && (
        <ZoomButton
          onClick={onZoomableLocationClick}
          aria-label={I18n.t('location.map_action')}
        >
          <MapIcon />
        </ZoomButton>
      )}
    </Container>
  )
}

LocationLabel.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  sup: PropTypes.string,
  favorite: PropTypes.bool,
  favable: PropTypes.bool,
  highlighted: PropTypes.bool,
  onMainClick: PropTypes.func,
  onFavClick: PropTypes.func,
  thin: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.bool,
  padding: PropTypes.bool,
  population: PropTypes.number,
  state: PropTypes.string,
  zoomable: PropTypes.bool,
  zoomableLocation: PropTypes.bool,
  onZoomableLocationClick: PropTypes.func
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${p => (p.thin ? '1.5rem' : '3.75rem')};
  opacity: ${p => p.disabled && '0.4'};
  position: relative;
  margin-left: ${p => p.margin && '1.75rem'};
`

const FavButton = styled.button`
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.5rem;
  cursor: pointer;
`

const FavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2.75rem;
  height: 100%;
  opacity: ${p => p.disabled && '0.4'};

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`

const Clickable = styled.div`
  display: flex;
  align-items: ${p => (p.highlighted ? 'baseline' : 'center')};
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex: 1 auto;
  flex-wrap: wrap;
  padding: 0.5rem;
  padding-left: 2.75rem;
  padding-top: ${p => p.padding && '1.75rem'};
  color: ${p => p.theme.chroma.base.css()};
  font-size: 1.125rem;
  text-align: left;
  font-weight: ${p => p.highlighted && '700'};
  text-transform: ${p => p.highlighted && 'uppercase'};
  letter-spacing: ${p => p.highlighted && '0.5px'};
  white-space: ${p => p.highlighted && 'nowrap'};

  ${p => p.theme.breakpoints.tablet} {
    font-size: 1rem;
  }

  .sup {
    position: absolute;
    top: 0.75rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    opacity: 0.4;
  }

  .info {
    color: ${p => p.theme.chroma.grey.css()};
    font-size: 0.75rem;
    text-transform: none;
    padding: 0 2px;
  }
`

const ZoomButton = styled.button`
  display: flex;
  align-items: center;
  color: ${props => props.theme.chroma.grey.css()};
  padding: 0.25rem 1.25rem 0.25rem;
  transition: color 0.25s ease-out;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.chroma.base.css()};
  }
  ${p => p.theme.breakpoints.desktop} {
    display: none;
  }
`

export default LocationLabel
