import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import I18n, { Trans } from '../i18n'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import Button from '../components/Button'
import Section from '../components/Section'
import Headline from '../components/Headline'

function ConfirmationNew({ user }) {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Section.Wrapper
        css={{ maxWidth: '32rem', marginTop: '3rem', marginBottom: '3rem' }}
      >
        <Helmet>
          <title>{I18n.t('confirmations.new.headline')}</title>
        </Helmet>
        <Headline>
          <Trans id="confirmations.new.headline" />
        </Headline>
        <Form
          as="user"
          action="/confirmation"
          method="post"
          css={{ width: '100%' }}
        >
          <Form.Field>
            <Input
              label={I18n.t('confirmations.new.email')}
              defaultValue={user.values.email}
              errors={user.errors.email}
              name="email"
              light
              required
            />
          </Form.Field>
          <Form.Actions>
            <Button type="submit">
              {I18n.t('confirmations.new.send_again_button')}
            </Button>
          </Form.Actions>
        </Form>
      </Section.Wrapper>
    </Section>
  )
}

ConfirmationNew.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default ConfirmationNew
