import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import I18n from '../i18n'

import Section from '../components/Section'
import Spacer from '../components/Spacer'

import ApiDocs from '../sections/ApiDocs'
import ApiExamples from '../sections/ApiExamples'
import ApiFeatures from '../sections/ApiFeatures'
import ApiIntro from '../sections/ApiIntro'
import ApiTest from '../sections/ApiTest'
import Contact from '../sections/Contact'
import PartnerLogos from '../sections/PartnerLogos'

import backgroundImage from './assets/code-snippet.png'

function Developer({ loggedIn }) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.developer.title')}</title>
      </Helmet>

      <Top light>
        <Section.Skew inset position="bottom" light />
      </Top>

      <Main>
        <ApiIntro />
        <ApiFeatures />
        <ApiExamples />
        <ApiTest loggedIn={loggedIn} />
        <ApiDocs />
        <a id="contact"></a>
        <Contact
          teaser="contact.teaser_developer"
          cta="contact.cta_developer"
        />
        <PartnerLogos />
        <Spacer light />
      </Main>
    </div>
  )
}

const Top = styled.div`
  position: relative;
  height: 20rem;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;

  ${props => props.theme.breakpoints.phone} {
    display: none;
  }
`

const Main = styled.div`
  position: relative;
  padding-top: 2rem;
`

Developer.propTypes = {
  loggedIn: PropTypes.bool
}

export default Developer
