import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import PressCard from '../components/PressCard'
import Headline from '../components/Headline'
import Section from '../components/Section'
import Link from '../components/Link'

function PressMentions({ pressData }) {
  return (
    <Section mid>
      <CustomWrapper>
        <CustomHeadline breakout>
          <Trans id="pages.press.press_mentions.headline" />
        </CustomHeadline>
        <List breakout>
          {pressData.map(p => {
            const { mediaOutlet: title, title: subline, url: link, ...rest } = p
            return (
              <ListItem key={p.id}>
                <PressCard
                  title={title}
                  subline={subline}
                  link={link}
                  {...rest}
                  buttonText={
                    <Trans id="pages.press.press_mentions.article_link_title" />
                  }
                />
              </ListItem>
            )
          })}
        </List>
        <ArchiveLinkWrapper breakout>
          <CustomLink href="/press/archive" breakout>
            <Trans id="pages.press.press_mentions.archive_link" />
          </CustomLink>
        </ArchiveLinkWrapper>
      </CustomWrapper>
    </Section>
  )
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${p => p.theme.breakpoints.tabletUp} {
    flex-direction: row;
    margin: 0 ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

const CustomWrapper = styled(Section.Wrapper)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 4.625rem;
    padding-bottom: 4.625rem;
  }
`

const CustomHeadline = styled(Headline)`
  margin-bottom: 1rem;

  ${p => p.theme.breakpoints.tabletUp} {
    margin-bottom: 2.75rem;
  }
`

const ListItem = styled.div`
  margin: 1.5rem 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    margin: 0 1.5rem;
    flex: 1 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const ArchiveLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
  z-index: 10;

  ${p => p.theme.breakpoints.tabletUp} {
    justify-content: flex-end;
    margin-top: 3.125rem;
  }
`

const CustomLink = styled(Link)`
  font-weight: 700;
  ${p => p.theme.breakpoints.tabletUp} {
    margin-right: ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

PressMentions.propTypes = {
  pressData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      mediaOutlet: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
}

export default PressMentions
