import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import I18n from '../../../../i18n'
import { TimestampLabel } from './LocationStatsCardItemBase'
import { NoDataPresentStatsCardItem } from './NoDataPresentStatsCardItem'

const MainPedstrianCountLabel = styled.h4(props => ({
  fontSize: '2.25rem',
  lineHeight: '3rem',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  marginTop: '0.8rem',
  marginBottom: 0
}))

export function PedestrianCountStatsCardItem({
  pedestrianCount,
  timeStampOrTimeRange,
  displayNoDataPresentPlaceholder = false,
  displayDataIncompleteNotice = false
}) {
  if (displayNoDataPresentPlaceholder || displayDataIncompleteNotice) {
    const message = displayDataIncompleteNotice
      ? I18n.t('location.details.data_incomplete')
      : I18n.t('location.details.data_missing')

    return (
      <NoDataPresentStatsCardItem
        askedtimerange={timeStampOrTimeRange}
        message={message}
        variant={'cardHeader'}
      ></NoDataPresentStatsCardItem>
    )
  }

  return (
    <>
      <MainPedstrianCountLabel>
        {I18n.toNumber(pedestrianCount, { precision: 0 })}
      </MainPedstrianCountLabel>
      <div css={{ marginBottom: 12 }}>
        <TimestampLabel>{timeStampOrTimeRange}</TimestampLabel>
      </div>
    </>
  )
}

PedestrianCountStatsCardItem.propTypes = {
  pedestrianCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  timeStampOrTimeRange: PropTypes.string,
  displayNoDataPresentPlaceholder: PropTypes.bool,
  displayDataIncompleteNotice: PropTypes.bool
}
