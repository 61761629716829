import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import I18n from '../../i18n'
import useModalState from '../../lib/use-modal-state'
import useFilter from '../../lib/use-filter'
import useLocation from '../../lib/use-location'
import Button from '../Button'
import useAuthorization from '../../lib/use-authorization'
import Modal from './Modal'
import FullHeightCalendar from './FullHeightCalendar'

function ComparisonTimerangeModal({ isOpen, onClick, ...rest }) {
  const { location } = useLocation()
  const [filter] = useFilter()
  const { permissions } = useAuthorization()
  const [compareFrom, setCompareFrom] = useState(filter.compareFrom)
  const [forceRerenderKey, setForceRerenderKey] = useState(false)
  const [_activeModal, { closeModal }] = useModalState()

  // Update local state when global state changes
  React.useEffect(() => void setCompareFrom(filter.compareFrom), [
    filter.compareFrom
  ])

  const timespan = dayjs(filter.to).diff(dayjs(filter.from), 'day')
  const compareTo = dayjs(compareFrom)
    .add(timespan, 'days')
    .endOf('day')
    .toDate()

  function handleDateChange({ start }) {
    setCompareFrom(start)
    setForceRerenderKey(state => !state)
  }

  function handleTimeCompareSubmitClick() {
    onClick({ from: compareFrom })
  }

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      title="location.compare_timerange.modal.title"
      fullWidth={false}
      {...rest}
    >
      <FullHeightFlexContainer>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '2.5rem 1.5rem',
            height: '100%'
          }}
        >
          <Headline>
            {I18n.t('location.compare_timerange.modal.title')}
          </Headline>
          <FullHeightCalendar
            key={forceRerenderKey}
            onDateChangeStart={handleDateChange}
            canSafelySetHeight={isOpen}
            startDate={compareFrom}
            endDate={compareTo}
            min={dayjs(location.metadata.earliestMeasurementAt)
              .subtract(3, 'month')
              .toDate()}
            max={dayjs()
              .add(2, 'months')
              .toDate()}
            minDate={dayjs(permissions.earliestAccessibleDate).toDate()}
            maxDate={dayjs(location.metadata.latestMeasurementAt)
              .endOf('day')
              .toDate()}
          />
        </div>
        <SubmitButton accentColor onClick={handleTimeCompareSubmitClick}>
          {I18n.t('location.compare_timerange.modal.submit')}
        </SubmitButton>
        <CancelButton onClick={() => closeModal()}>
          {I18n.t('location.compare_timerange.modal.cancel')}
        </CancelButton>
      </FullHeightFlexContainer>
    </Modal>
  )
}

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: fit-content;
  margin: 0 auto 0.75rem;

  @media (min-height: 640px) {
    margin-top: 1rem;
  }
`

const CancelButton = styled.button`
  color: ${props => props.theme.chroma.base.hex()};
  cursor: pointer;
  font-size: 1rem;
  box-shadow: inset 0 -1px 0 0 currentColor;
  margin: 0 auto 2rem;
`

const FullHeightFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Headline = styled.h2`
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: bold;
  color: ${props => props.theme.chroma.base.hex()};
  margin-bottom: 3rem;
`

ComparisonTimerangeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default ComparisonTimerangeModal
