import dayjs from 'dayjs'
import I18n from 'i18n-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CollapsibleSection from '../components/CollapsibleSection'
import * as Icon from '../components/Icon'
import ActionButton from '../components/LocationDetail/ActionButton'
import { getCalendarWeekLabel } from '../components/LocationDetail/lib/dateUtilities'
import LocationStatsCard, {
  statsObjectPropTypes
} from '../components/LocationDetail/LocationStatsCard/LocationStatsCard'
import { WalkingDirectionCard } from '../components/LocationDetail/LocationStatsCard/WalkingDirectionCard'
import MapModal from '../components/LocationDetail/MapModal'
import i18n from '../i18n'
import useMediaQuery from '../lib/use-media-query'
import theme from '../theme'

function WalkingDirectionLocationDataSection({ stats, map }) {
  const { startOfTwoWeeksAgo, startOfTwoMonthsAgo, startOfLastYear } =
    (stats && stats.dates) || {}

  const [mapModalOpen, setMapModalOpen] = useState(false)

  const handleMapModalClose = () => {
    setMapModalOpen(false)
  }

  const isMobile = useMediaQuery(theme.breakpoints.phone)

  return (
    <React.Fragment>
      <CollapsibleSection
        css={{ marginTop: '1.3rem', marginBottom: '2rem' }}
        title={i18n.t('location.details.display_walking_directions')}
        openByDefault
        headerRowItems={
          map ? (
            <ActionButton
              title={isMobile ? null : I18n.t('location.map_action')}
              icon={<Icon.MapLight height={20} width={21} />}
              onClick={() => {
                setMapModalOpen(true)
              }}
              variant={'link'}
            />
          ) : null
        }
      >
        <LocationStatsCard headline={I18n.t('location.details.yesterday')}>
          <WalkingDirectionCard
            statsObject={stats.yesterday}
            comparedTimeranges={[
              {
                timerangeLabel: I18n.t('location.details.same_day_a_week_ago'),
                statsObject: stats?.yesterdayAWeekAgo
              }
            ]}
          ></WalkingDirectionCard>
        </LocationStatsCard>

        <LocationStatsCard headline={I18n.t('location.details.past_week')}>
          <WalkingDirectionCard
            statsObject={stats.lastWeek}
            comparedTimeranges={[
              {
                timerangeLabel: `${I18n.t(
                  'location.details.previous_week'
                )} ${getCalendarWeekLabel(startOfTwoWeeksAgo)} `,
                statsObject: stats?.twoWeeksAgo
              }
            ]}
          ></WalkingDirectionCard>
        </LocationStatsCard>

        <LocationStatsCard headline={I18n.t('location.details.past_month')}>
          <WalkingDirectionCard
            statsObject={stats.lastMonth}
            comparedTimeranges={[
              {
                timerangeLabel: `${I18n.t(
                  'location.details.previous_month'
                )} (${I18n.strftime(startOfTwoMonthsAgo, '%B')})`,
                statsObject: stats?.twoMonthsAgo
              }
            ]}
          ></WalkingDirectionCard>
        </LocationStatsCard>

        <LocationStatsCard
          headline={I18n.t('location.details.current_year_to_date')}
        >
          <WalkingDirectionCard
            statsObject={stats.thisYearToDate}
            comparedTimeranges={[
              {
                timerangeLabel: `${dayjs(startOfLastYear).year()}`,
                statsObject: stats?.lastYearToDate
              }
            ]}
          ></WalkingDirectionCard>
        </LocationStatsCard>
      </CollapsibleSection>
      {!!map && (
        <MapModal
          isDirectionActive
          isOpen={mapModalOpen}
          onRequestClose={handleMapModalClose}
          map={map}
        ></MapModal>
      )}
    </React.Fragment>
  )
}

WalkingDirectionLocationDataSection.propTypes = {
  stats: statsObjectPropTypes,
  map: PropTypes.any
}

export default WalkingDirectionLocationDataSection
