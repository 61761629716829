import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function FilterButton({ onClick, children, light, active, icon }) {
  const ButtonWrapper = styled.button`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    min-height: 2.5rem;
    border-width: 0.125rem;
    border-style: solid;
    border-radius: 0.125rem;
    border-color: ${p => p.theme.chroma.base.css()};
    color: ${p => p.theme.chroma.base.css()};
    cursor: pointer;

    ${p =>
      light &&
      `
        border-color: ${p.theme.chroma.white.css()};
        color: ${p.theme.chroma.white.css()};
      `};

    ${p =>
      active &&
      `
        border-color: ${p.theme.chroma.lightBlue.css()};
        color: ${p.theme.chroma.lightBlue.css()};
      `};
  `

  const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.625rem;
    background-color: ${p => p.theme.chroma.base.css()};
    color: ${p => p.theme.chroma.white.css()};

    svg {
      width: 1.0625rem;
      height: 1.0625rem;
    }

    ${p =>
      light &&
      `
        background-color: ${p.theme.chroma.white.css()};
        color: ${p.theme.chroma.base.css()};
      `};

    ${p =>
      active &&
      `
        background-color: ${p.theme.chroma.lightBlue.css()};
        color: ${p.theme.chroma.white.css()};
      `};
  `

  function handleClick() {
    onClick()
  }

  return (
    <ButtonWrapper onClick={handleClick}>
      <ButtonContent>{children}</ButtonContent>
      <IconWrapper>{icon}</IconWrapper>
    </ButtonWrapper>
  )
}

FilterButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  light: PropTypes.bool,
  active: PropTypes.bool
}

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0.5rem 0.625rem;
`

FilterButton.TopLine = styled.span`
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;
`

FilterButton.Title = styled.span`
  font-weight: 700;
  font-size: 0.75rem;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 0.875rem;
  }
`

export default FilterButton
