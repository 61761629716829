import PropTypes from 'prop-types'
import React from 'react'
import theme from '../theme'

const dynamicStyles = props => {
  let baseStyles

  if (props.tag === 'h1') {
    baseStyles = {
      fontSize: theme.fontSizes.xxl.fontSize,
      lineHeight: theme.fontSizes.xxl.lineHeight, // 32px
      fontWeight: 900,
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    }
  }

  if (props.tag === 'h2') {
    baseStyles = {
      fontSize: theme.fontSizes.xl.fontSize,
      lineHeight: theme.fontSizes.xl.lineHeight,
      fontWeight: 900,
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    }
  }

  if (props.tag === 'h3') {
    baseStyles = {
      fontSize: theme.fontSizes.l.fontSize,
      lineHeight: theme.fontSizes.l.lineHeight
    }
  }

  if (props.tag === 'h4') {
    baseStyles = {
      fontSize: theme.fontSizes.m.fontSize,
      lineHeight: theme.fontSizes.m.lineHeight
    }
  }

  if (props.tag === 'h5') {
    baseStyles = {
      fontSize: theme.fontSizes.s.fontSize,
      lineHeight: theme.fontSizes.s.lineHeight,
      letterSpacing: '0.5px'
    }
  }

  if (props.tag === 'h6') {
    baseStyles = {
      fontSize: theme.fontSizes.xs.fontSize,
      lineHeight: theme.fontSizes.xs.lineHeight
    }
  }

  if (props.tag === 'p') {
    baseStyles = {
      fontSize: theme.fontSizes.xs.fontSize,
      lineHeight: theme.fontSizes.xs.lineHeight
    }
  }

  if (props.tag === 'span') {
    baseStyles = {
      fontSize: '1em' // not changed
    }
  }

  if (props.small) {
    baseStyles.fontSize = `calc(${baseStyles.fontSize} * 0.75)`
  }

  if (props.smaller) {
    baseStyles.fontSize = `calc(${baseStyles.fontSize} * 0.625)`
  }

  if (props.bigger) {
    baseStyles.fontSize = `calc(${baseStyles.fontSize} * 1.5)`
  }

  if (props.strikeThrough) {
    baseStyles.textDecoration = 'line-through'
  }

  if (props.bold) {
    baseStyles.fontWeight = 'bold'
  }

  if (props.underline) {
    baseStyles.textDecoration = 'underline'
  }

  if (props.capitalize) {
    baseStyles.textTransform = 'uppercase'
  }

  return baseStyles
}

export default function Typography({
  tag = 'span',
  children,
  styleOverrides,
  small = false,
  smaller = false,
  bigger = false,
  strikeThrough = false,
  bold = false,
  underline = false,
  capitalize = false
}) {
  const baseStyles = dynamicStyles({
    tag,
    small,
    smaller,
    bigger,
    strikeThrough,
    bold,
    underline,
    capitalize
  })
  const DynamicTypographyTag = `${tag}`

  return (
    <DynamicTypographyTag css={{ ...styleOverrides, ...baseStyles }}>
      {children}
    </DynamicTypographyTag>
  )
}

Typography.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  styleOverrides: PropTypes.any,
  small: PropTypes.bool,
  smaller: PropTypes.bool,
  bigger: PropTypes.bool,
  strikeThrough: PropTypes.bool,
  bold: PropTypes.bool,
  underline: PropTypes.bool
}
