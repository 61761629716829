import React from 'react'
import PropTypes from 'prop-types'

import I18n from '../i18n'

import Button from '../components/Button'
import Section from '../components/Section'
import Typography from '../components/Typography'
import Collapsible from '../components/Collapsible'
import Headline from '../components/Headline'

// use only to define which faq topics should be shown
function FaqSection({ only = [] }) {
  return (
    <Section mid css={{ paddingTop: '4rem', paddingBottom: '5rem' }}>
      <Section.Wrapper>
        <Headline level={1} breakout>{sT('headline')}</Headline>
        <Typography tag="h3">{sT('subline')}</Typography>

        {sT('topics').map(({ headline, key, items }) => {
          const showTopic = only.length === 0 || only.includes(key)

          return (
            showTopic && (
              <div key={headline}>
                <Typography
                  tag="h3"
                  styleOverrides={{ margin: '2rem 0', textAlign: 'center' }}
                >
                  {headline}
                </Typography>
                {items.map(({ key, q, a }) => (
                  <Collapsible key={q} title={q} open={false} id={key}>
                    <span dangerouslySetInnerHTML={{ __html: a }} />
                  </Collapsible>
                ))}
              </div>
            )
          )
        })}

        {only.length > 0 && (
          <div style={{ marginTop: '2rem', alignSelf: 'center' }}>
            <Button.Link outline baseColor href="/faq">
              {sT('show_all')}
            </Button.Link>
          </div>
        )}
      </Section.Wrapper>
    </Section>
  )
}

const sT = key => I18n.t(`sections.faq_section.${key}`)

FaqSection.propTypes = {
  only: PropTypes.array
}

export default FaqSection
