import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const AlertBox = styled.div`
  padding: 1rem;
  background-color: ${p =>
    p.error ? p.theme.chroma.bad.css() : p.theme.chroma.good.css()};
  color: ${p => p.theme.chroma.white.css()};
  box-shadow: 0px 0.125rem 0.375rem 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3rem;
`

AlertBox.propTypes = {
  error: PropTypes.bool
}

export default AlertBox
