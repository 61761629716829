import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Section from '../components/Section'
import SectionsConnector from '../components/SectionsConnector'
import Card from '../components/Card'
import Headline from '../components/Headline'
import NewsletterForm from '../forms/NewsletterForm'
import headerImage from '../sections/assets/newsletter-title.jpg'

function Newsletter({ newsletterForm }) {
  return (
    <React.Fragment>
      <SectionsConnector>
        <Section>
          <ImageContainer />
          <Section.Skew light position="bottom" direction="down" inset />
        </Section>
      </SectionsConnector>
      <Section css={{ marginBottom: '3rem' }}>
        <Section.Wrapper>
          <FormCard>
            <Headline breakout css={{ marginBottom: '1rem' }}>
              <Trans id="pages.home.newsletter_form.headline" />
            </Headline>
            <p
              css={{ fontWeight: 700, lineHeight: 1.375, marginBottom: '2rem' }}
            >
              <Trans id="pages.home.newsletter_form.teaser" />
            </p>
            <NewsletterForm
              errors={newsletterForm.errors}
              values={newsletterForm.values.fields}
            />
          </FormCard>
        </Section.Wrapper>
      </Section>
    </React.Fragment>
  )
}

const FormCard = styled(Card)`
  z-index: 2;
  padding: 1.4rem 2.2rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding: 2.8rem 3.4rem;
  }
`

const ImageContainer = styled.div`
  background-image: url(${headerImage});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
`

Newsletter.propTypes = {
  newsletterForm: PropTypes.shape({
    errors: PropTypes.object,
    values: PropTypes.object
  })
}

export default Newsletter
