import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import I18n from '../i18n'
import Card from './Card'

function PressCard({ date, title, link, subline, buttonText }) {
  const parsedDate = I18n.strftime(new Date(date), '%d.%m.%Y')
  return (
    <Wrapper paddingX={2}>
      <StyledDate>{parsedDate}</StyledDate>
      <Title>{title}</Title>
      <Subline>{subline}</Subline>
      <Link href={link}>{buttonText}</Link>
    </Wrapper>
  )
}

const Wrapper = styled(Card)`
  display: flex;
  height: 100%;
  flex-direction: column;
  text-align: left;
  word-break: break-word;
  hyphens: auto;
`

const StyledDate = styled.span`
  font-weight: 400;
  margin-bottom: 1rem;

  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.3;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const Subline = styled.span`
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 0.5rem;

  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`

const Link = styled.a`
  color: ${p => p.theme.chroma.accent.css()};
  font-weight: 700;
  margin-top: auto;

  ${p => p.theme.breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`

PressCard.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default PressCard
