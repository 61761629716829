import React from 'react'
import { Helmet } from 'react-helmet'
import LegalPageContent from '../sections/LegalPageContent'
import I18n, { Trans } from '../i18n'

function Imprint() {
  return (
    <React.Fragment>
      <Helmet>
        <title>{I18n.t('pages.imprint.title')}</title>
      </Helmet>
      <LegalPageContent headline={I18n.t('pages.imprint.headline')}>
        <Trans id="pages.imprint.content" copy />
      </LegalPageContent>
    </React.Fragment>
  )
}

export default Imprint
