import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Info as InfoIcon, Info2 as InfoWithoutOultineIcon } from '../Icon'

function InfoButton({ onClick, type='info_neutral' }) {
  return type === 'info_neutral' ? (
    <CircleButton onClick={onClick}>
      <InfoIcon width="0.1875rem" height="0.5625rem" />
    </CircleButton>
  ) : (
    <AccentedCircleButton onClick={onClick}>
    <InfoWithoutOultineIcon />
  </AccentedCircleButton>
  );
}

InfoButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['info_neutral', 'info_accent']),
}

const CircleButton = styled.button(props => ({
  display: 'block',
  width: '1.2rem',
  height: '1.2rem',
  borderRadius: '50%',
  color: props.theme.chroma.pencil.css(),
  boxShadow: '0 1px 3px ' + props.theme.chroma.pencil.alpha(0.5).css(),
  backgroundColor: props.theme.chroma.white.css(),

  svg: {
    width: '100%',
    height: '100%',
    display: 'block',
  }
}))

const AccentedCircleButton = styled.button(props => ({
  display: 'block',
  width: '1.2rem',
  height: '1.2rem',
  borderRadius: '50%',
  color: props.theme.chroma.accent.css(),
  backgroundColor: props.theme.chroma.white.css(),

  svg: {
    width: '100%',
    height: '100%',
    display: 'block',
  }
}))

export default InfoButton
