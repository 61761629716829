import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'
import Card from './Card'

function TopLocation({ city, name, rank, count, highlighted }) {
  return (
    <Container>
      <Rank highlighted={highlighted}>
        <span>{rank}</span>
      </Rank>
      <CustomCard>
        <City>{city}</City>
        <Street>{name}</Street>
        <Spacer />
        <Pedestrians>{I18n.toNumber(count, { precision: 0 })}</Pedestrians>
        <Label>
          <Trans id="common.pedestrians" />
        </Label>
      </CustomCard>
    </Container>
  )
}

TopLocation.propTypes = {
  city: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.number.isRequired,
  highlighted: PropTypes.bool
}

const Container = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`

const Rank = styled.div`
  position: relative;
  font-size: 8.75rem;
  font-weight: 900;
  color: ${props =>
    props.highlighted
      ? props.theme.chroma.accent.css()
      : props.theme.chroma.base.alpha(0.15).css()};

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 13.625rem;
  }

  span {
    position: absolute;
    z-index: -1;
    right: -1.6rem;
    bottom: -2.6rem;

    ${p => p.theme.breakpoints.tabletUp} {
      right: -2.2rem;
      bottom: -4rem;
    }
  }
`

const CustomCard = styled(Card)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 15rem;
  min-width: 13.375rem;
  padding: 1.25rem 0.625rem;
  text-align: center;
  margin-right: -4rem;

  ${p => p.theme.breakpoints.tablet} {
    padding: 0.75rem 0.625rem;
  }
`

const City = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
`

const Street = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
`

// always aligns pedestrian count on bottom
const Spacer = styled.div`
  flex: 1;
`

const Pedestrians = styled.div`
  font-size: 3rem;
  font-weight: 900;
  color: ${props => props.theme.chroma.accent.css()};
`

const Label = styled.div`
  font-size: 1.125rem;
  color: ${props => props.theme.chroma.coal.brighten(2.5).css()};
`

export default TopLocation
