import React from 'react'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'
import * as Icon from '../components/Icon'
import Section from '../components/Section'
import Figure from '../components/Figure'

const listItems = [
  'retailer',
  'city',
  'landlord',
  'tourist',
  'consultant',
  'marketing',
  'planner',
  'scientists'
]

function UserInterests() {
  return (
    <Section>
      <Section.Wrapper>
        <List>
          {listItems.map((key, i) => {
            const prefix = `pages.community.interests.${key}.`
            const IconComponent = Icon[I18n.t(prefix + 'icon')] // eslint-disable-line import/namespace

            return (
              <ListItem key={key}>
                <Figure
                  icon={<IconComponent width={84} height={84} />}
                  stackingLayoutMobile
                  iconOnRight={i % 2 === 0}
                >
                  <Figure.Text>
                    <Trans id={prefix + 'text'} />
                  </Figure.Text>
                </Figure>
              </ListItem>
            )
          })}
        </List>
      </Section.Wrapper>
    </Section>
  )
}

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-bottom: 3.5rem;
  padding-top: 4rem;

  ${p => p.theme.breakpoints.phoneUp} {
    padding-top: 7rem;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 10rem;
    margin-left: -1rem;
    margin-right: -1rem;
    width: auto;
  }
`

const ListItem = styled.li`
  width: 100%;
  padding: 1rem 0;

  ${p => p.theme.breakpoints.phoneUp} {
    width: 50%;
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    width: 50%;
    padding: 1rem 2.8rem;
  }
`

const SkewContainer = styled.div`
  ${p => p.theme.breakpoints.tablet} {
    display: none;
  }
`

export default UserInterests
