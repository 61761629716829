import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormContext } from './Form'

// Mirrors Rails' checkbox. Works controlled and uncontrolled.
function Checkbox({ label, name, errors, styleOverrides, ...nativeProps }) {
  const { as: formAs, idPrefix } = useContext(FormContext)
  const inputName = formAs ? formAs + `[${name}]` : name
  const id = (idPrefix || '') + name

  return (
    <div style={{ position: 'relative', ...styleOverrides }}>
      <input
        name={inputName}
        type="hidden"
        value="0"
        checked={!nativeProps.checked}
        readOnly
      />
      <HiddenCheckbox
        id={id}
        type="checkbox"
        value="1"
        name={inputName}
        {...nativeProps}
      />
      <Label
        htmlFor={id}
        invalid={!!errors}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {errors && <ErrorText>{errors.join(', ')}</ErrorText>}
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.array,
  styleOverrides: PropTypes.any
}

const HiddenCheckbox = styled.input`
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`

const Label = styled.label`
  display: inline-block;
  position: relative;
  font-size: 0.875rem;
  padding-left: 1.875rem;
  padding-top: 0.125rem;
  min-height: 1.25rem;
  cursor: pointer;

  a {
    text-decoration: underline;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${p => p.theme.chroma.base.alpha(0.1).css()};
    background-color: ${p => p.theme.chroma.nearlyWhite.hex()};
    border-radius: 0.125rem;
    font-size: 0.8rem;
    line-height: 0px;

    border-color: ${p => p.invalid && p.theme.chroma.accent.css()};
  }

  ${HiddenCheckbox}:checked + &:before {
    content: '✓';
    background-color: ${p => p.theme.chroma.base.hex()};
    color: ${p => p.theme.chroma.white.hex()};
  }

  ${HiddenCheckbox}:focus + &:before {
    box-shadow: 0 0 0.25rem ${p => p.theme.chroma.base.alpha(0.25).css()};
  }
`

const ErrorText = styled.div`
  color: ${p => p.theme.chroma.accent.css()};
  font-size: 0.75rem;
  font-weight: bold;
  margin-left: 1.875rem;
`

export default Checkbox
