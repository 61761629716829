import React from 'react'
import Helmet from 'react-helmet'

import I18n, { Trans } from '../i18n'
import Section from '../components/Section'
import Headline from '../components/Headline'

function ConfirmationShow() {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Section.Wrapper
        css={{ maxWidth: '32rem', marginTop: '3rem', marginBottom: '3rem' }}
      >
        <Helmet>
          <title>{I18n.t('confirmations.show.headline')}</title>
        </Helmet>
        <Headline>
          <Trans id="confirmations.show.headline" />
        </Headline>
      </Section.Wrapper>
    </Section>
  )
}

export default ConfirmationShow
