import dayjs from 'dayjs'

export default function calculateIncidentRows(incidents, metadata) {
  const measuredFrom = dayjs(metadata.measuredFrom)
  const measuredTo = dayjs(metadata.measuredTo)

  return incidents.reduce((rows, incident) => {
    const activeFrom = dayjs(incident.activeFrom)
    const activeTo = dayjs(incident.activeTo)

    const isCutOffFrom = activeFrom.isBefore(measuredFrom)
    const isCutOffTo = activeTo.isAfter(measuredTo)

    const returnValue = {
      isCutOffFrom,
      isCutOffTo,

      id: incident.id,
      name: incident.name,
      icon: incident.icon,
      description: incident.description,
      activeFrom: isCutOffFrom ? metadata.measuredFrom : incident.activeFrom,
      activeTo: isCutOffTo ? metadata.measuredTo : incident.activeTo
    }

    // Find a row where the last incident does not overlap with the current one
    const row = rows.find(row => {
      const lastIncidentInRow = row[row.length - 1]
      return dayjs(lastIncidentInRow.activeTo).isBefore(activeFrom)
    })

    if (row) {
      row.push(returnValue)
    } else {
      // or create a new row
      rows.push([returnValue])
    }

    return rows
  }, [])
}
