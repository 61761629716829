import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Modal from '../components/LocationDetail/Modal.js'

function ImageModal({ isOpen, onRequestClose, imageSource }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} showCloseButton>
      <FullHeightFlexContainer>
        <Image
          src={imageSource}
          css={{
            backgroundColor: 'transparent'
          }}
        />
      </FullHeightFlexContainer>
    </Modal>
  )
}

const FullHeightFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

ImageModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  imageSource: PropTypes.string
}

export default ImageModal
