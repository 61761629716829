import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@emotion/react'
import { GatewayDest, GatewayProvider } from 'react-gateway'
import theme from '../theme'
import { navigationStructure } from '../lib/types'
import I18n from '../i18n'
import Navigation from './Navigation'
import Footer from './Footer'
import Flash from './Flash'
import { CsrfContext } from './forms/Form'

// These controllerPaths won't show flash messages. Useful if you want to
// show them explicitly somewhere else.
const hideFlashPaths = [
  'confirmations/sent',
  'passwords/sent',
  'passwords/update'
]

function Layout({
  children,
  navigationStructure,
  footerNavigationStructure,
  csrfToken,
  flash,
  controllerPath
}) {
  return (
    <CsrfContext.Provider value={csrfToken}>
      <ThemeProvider theme={theme}>
        <GatewayProvider>
          <React.Fragment>
            <Helmet titleTemplate="%s - hystreet.com">
              <meta name="description" content={I18n.t('meta.description')} />
            </Helmet>
            <PageWrapper>
              <NoGrow>
                <Navigation pages={navigationStructure} />
              </NoGrow>
              {!hideFlashPaths.includes(controllerPath) && <Flash {...flash} />}
              <Grow>{children}</Grow>
              <NoGrow>
                <Footer pages={footerNavigationStructure} />
              </NoGrow>

              <GatewayDest name="global" />
            </PageWrapper>
          </React.Fragment>
        </GatewayProvider>
      </ThemeProvider>
    </CsrfContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  navigationStructure: navigationStructure,
  footerNavigationStructure: navigationStructure,
  csrfToken: PropTypes.string.isRequired,
  flash: PropTypes.object.isRequired,
  controllerPath: PropTypes.string.isRequired
}

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`

const Grow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`

const NoGrow = styled.div`
  flex-shrink: 0;
`

export default Layout
