import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import I18n, { Trans } from '../i18n'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import AlertBox from '../components/AlertBox'
import Button from '../components/Button'
import Section from '../components/Section'
import Headline from '../components/Headline'

function PasswordResetEdit({ user, flash = {} }) {
  return (
    <Section mid css={{ minHeight: '100%' }}>
      <Section.Wrapper
        css={{ maxWidth: '32rem', marginTop: '3rem', marginBottom: '3rem' }}
      >
        <Helmet>
          <title>{I18n.t('reset_password.edit.headline')}</title>
        </Helmet>
        <Headline>
          <Trans id="reset_password.edit.headline" />
        </Headline>
        {flash.alert && (
          <AlertBox error css={{ width: '100%', marginTop: '3rem' }}>
            {flash.alert}
          </AlertBox>
        )}
        <Form
          as="user"
          action="/password-reset/confirm"
          method="put"
          css={{ width: '100%' }}
        >
          <Input
            value={user.values.resetPasswordToken}
            name="reset_password_token"
            type="hidden"
          />
          <Form.Field>
            <Input
              label={I18n.t('reset_password.edit.new_password')}
              defaultValue=""
              errors={user.errors.password}
              name="password"
              type="password"
              light
              required
            />
          </Form.Field>
          <Form.Field>
            <Input
              label={I18n.t('reset_password.edit.confirm_password')}
              defaultValue=""
              errors={user.errors.passwordConfirmation}
              name="password_confirmation"
              type="password"
              light
              required
            />
          </Form.Field>
          <Form.Actions>
            <Button type="submit">
              {I18n.t('reset_password.edit.submit_button_text')}
            </Button>
          </Form.Actions>
        </Form>
      </Section.Wrapper>
    </Section>
  )
}

PasswordResetEdit.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  }),
  flash: PropTypes.shape({
    alert: PropTypes.string
  })
}

export default PasswordResetEdit
