import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import styled from '@emotion/styled'

import * as Icon from '../Icon'

function ActionSelect({
  label,
  title,
  icon,
  value,
  options,
  onChangeValue,
  onClick,
  isActive,
  ...passThroughProps
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const activeOption = options.find(option => option.value === value)

  return (
    <Downshift
      itemToString={item => (item ? item.value : '')}
      onChange={item => onChangeValue(item.value)}
      onOuterClick={() => setIsOpen(false)}
      isOpen={isOpen}
      style={{ marginTop: '2rem' }}
      {...passThroughProps}
    >
      {({
        getToggleButtonProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen
      }) => (
        <div
          style={{
            display: 'block',
            height: '4.1rem',
            overflow: 'visible'
          }}
        >
          <ButtonWrapper>
            <div css={{ display: 'flex', height: '2.85rem', flexShrink: 0 }}>
              <button
                css={{
                  display: 'flex',
                  flex: '1 0 auto',
                  textAlign: 'left',
                  alignItems: 'center',
                  opacity: isActive ? 1 : 0.5,
                  filter: !isActive && 'grayscale(90%)',
                  transition: 'all 120ms',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsOpen(prev => !prev)
                  onClick(value)
                }}
              >
                <div
                  css={{
                    width: '3.25rem',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {icon}
                </div>
                <div css={{ paddingRight: '0.5rem' }}>
                  <SelectLabel>{label}</SelectLabel>
                  <ActiveOptionTitle>
                    {title || activeOption?.title}
                  </ActiveOptionTitle>
                </div>
              </button>
              <button
                {...getToggleButtonProps()}
                css={{
                  width: '3.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={() => setIsOpen(prev => !prev)}
              >
                {isOpen ? (
                  <Flip>
                    <Icon.AngleDown />
                  </Flip>
                ) : (
                  <Icon.AngleDown />
                )}
              </button>
            </div>

            <ul
              {...getMenuProps({ ...passThroughProps})}
              css={{
                flexShrink: 0,
                height: isOpen ? 'auto' : 0,
                zIndex: isOpen ? 3 : 0
              }}
            >
              {options.map((option, index) => (
                <SelectItem
                  isSubItem={option.isSubItem}
                  highlighted={index === highlightedIndex}
                  key={option.value}
                  {...getItemProps({
                    index,
                    item: option
                  })}
                >
                  {option.title}
                </SelectItem>
              ))}
            </ul>
          </ButtonWrapper>
        </div>
      )}
    </Downshift>
  )
}

ActionSelect.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  svg: {
    display: block;
  }
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.125rem;
  border-color: ${p => p.theme.chroma.white.css()};
  background-color: ${p => p.theme.chroma.dustyBlue.css()};
  max-width: fit-content;
  min-width: 13rem;
`

const ActiveOptionTitle = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${p => p.theme.chroma.base.css()};
  max-width: 11.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SelectLabel = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${p => p.theme.chroma.grey.css()};
  margin-bottom: 0.2rem;
`

const SelectItem = styled.li`
  display: flex;
  align-items: center;
  padding: .625rem 1rem;
  padding-left: ${p => (p.isSubItem ? '2rem' : '1rem')};
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: ${p => p.theme.chroma.base.css()};
  background-color: ${p =>
    p.highlighted
      ? p.theme.chroma.slate.css()
      : p.theme.chroma.dustyBlue.css()};
`

const Flip = styled.div`
  transform: rotate(180deg);
`

export default ActionSelect
