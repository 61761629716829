import React from 'react'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import useFilter from './use-filter'
import useLocation from './use-location'
import PlotHelpers from './helpers/plot-helpers'
import useAuthorization from './use-authorization'

dayjs.extend(utc)
dayjs.extend(timezone)

const context = React.createContext()

export default function useObjectTypePlot() {
  return React.useContext(context)
}

export function PlotProviderV2({ ...rest }) {
  // Setup
  const fallbackTransformerResult = React.useRef({ plot: [], sums: {} })
  const [filter] = useFilter()
  const { locationV2 } = useLocation()
  const { permissions } = useAuthorization()
  let measurements = locationV2.measurements

  measurements = PlotHelpers.filterMeasurementsBeforeEarliestAccessibleDate(
    measurements,
    permissions.earliestAccessibleDate
  )

  // Transform the measurements into a format that the chart can use
  const transformerResult = React.useMemo(() => {
    return PlotHelpers.aggregateObjectTypeMeasurements(locationV2, measurements)
  }, [measurements, locationV2])

  // Determine which attributes of the result set shall be displayed in the chart
  const chartKeys = React.useMemo(() => {
    return PlotHelpers.getObjectTypeChartKeys(filter.objectType)
  }, [filter.objectType])

  // Provide the chart data to the context, but also provide a fallback in case the data is not yet available
  const { plot, sums } = PlotHelpers.getPlottableOrCachedChart(
    transformerResult,
    fallbackTransformerResult
  )

  return <context.Provider value={{ plot, sums, chartKeys }} {...rest} />
}
