import ky from 'ky'
import camelcaseKeys from 'camelcase-keys'
import dayjs from 'dayjs'
import I18n from '../../../i18n'
import { timestampToMonthLabel } from '../../transformers/datetime-transformers'

/**
 * Index API - Abstraction for the Hystreet Index API
 */
export const indexAPI = {
  /**
   * Get a single index by id
   * @param {string} id - The id of the index
   * @param {object} filter - The filter object
   * @param {string} api_version - The api version
   */
  getIndex: async function(id, filter, api_version = '1') {
    const parsed = await ky
      .get(`/api/indices/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: `application/vnd.hystreet.v${api_version}`
        },
        searchParams: filter,
        timeout: false,
        retry: 0 // react-query will do the retrying
      })
      .json()

    const camelCased = camelcaseKeys(parsed, { deep: true })
    return camelCased
  },

  /**
   * Creates a series of timerange presets for the national index
   * @param {object} maxTimerange - The timerange object, with name, from, to and resolution keys
   * @returns {object} - The timerange presets
   */
  getNationalIndexTimerangePresets: function(maxTimerange) {
    const now = dayjs()
    const endOfPreviousMonth = now
      .add(-1, 'month')
      .endOf('month')
      .toISOString()

    const maxFallback = {
      name: 'max',
      from: now
        .add(-1, 'month')
        .startOf('month')
        .toISOString(),
      to: endOfPreviousMonth,
      resolution: 'month'
    }

    return {
      lastMonth: {
        name: 'lastMonth',
        from: now
          .add(-1, 'month')
          .startOf('month')
          .toISOString(),
        to: endOfPreviousMonth,
        resolution: 'month'
      },
      twoMonthsAgo: {
        name: 'twoMonthsAgo',
        from: now
          .add(-2, 'month')
          .startOf('month')
          .toISOString(),
        to: now
          .add(-2, 'month')
          .endOf('month')
          .toISOString(),
        resolution: 'month'
      },
      sixMonths: {
        name: 'sixMonths',
        from: now
          .add(-6, 'month')
          .startOf('month')
          .toISOString(),
        to: endOfPreviousMonth,
        resolution: 'month'
      },
      oneYear: {
        name: 'oneYear',
        from: now
          .add(-1, 'year')
          .startOf('month')
          .toISOString(),
        to: endOfPreviousMonth,
        resolution: 'month'
      },
      twoYears: {
        name: 'twoYears',
        from: now
          .add(-2, 'year')
          .startOf('month')
          .toISOString(),
        to: endOfPreviousMonth,
        resolution: 'month'
      },
      max: { name: 'max', ...maxTimerange } || maxFallback
    }
  }
}

/**
 * Index API Response Transformers - Collection of transformers for the index API
 */
export const indexAPITransformers = {
  /**
   * Transforms a collection of index values to a chart data object that can be displayed by recharts
   * @param {array} indexValues - The index values
   * @returns {array of objects} - The transformed index values, each carrying a timestamp, indexValue and tooltipTimestamp
   */
  indexValuesToChartData: function(indexValues) {
    return indexValues.map(indexValue => ({
      timestamp: timestampToMonthLabel(new Date(indexValue.timestamp), {
        useYearNumberForJanuary: true,
        useAbbreviatedMonthName: true
      }),
      indexValue: indexValue.indexValue,
      tooltipTimestamp: I18n.strftime(new Date(indexValue.timestamp), '%B %Y')
    }))
  }
}
