import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

import Typography from '../components/Typography'
import Headline from '../components/Headline'
import Button from '../components/Button'
import Card from '../components/Card'
import Section from '../components/Section'
import Markdown from '../components/Markdown'
import * as Icon from '../components/Icon'

import I18n from '../i18n'

function WelcomeToPaidPlans() {
  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section row mid>
        <Section.Wrapper css={{ paddingTop: '4rem' }}>
          <Card
            css={{
              padding: '2rem 3rem',
              margin: '2rem 0 10rem',
              width: '42rem',
              maxWidth: '100%'
            }}>
            <HeadWrapper>
              <div>
                <Headline>
                  {sT('headline')}
                </Headline>
                <div css={{ paddingLeft: '1.25rem' }}>
                  <Typography tag="p" bigger>
                    {sT('subline')}
                  </Typography>
                </div>
              </div>
              <div><Icon.NeuesHystreet width={100} height={100} /></div>
            </HeadWrapper>
            <Content>
              <Markdown content={sT('message')} copy="true" />
            </Content>
            <Button.Link href="/products"
              css={{
                margin: '2rem 0'
              }}>
              {sT('call_to_action')}
            </Button.Link>
            <div css={translationCss} dangerouslySetInnerHTML={{ __html: sT('support') }} />
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const Content = styled.div`
  margin-top: 2rem;
  p {
    margin-bottom: 1rem;
  }
`

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`

const translationCss = css`
  text-align: center;
  a {
    text-decoration: underline;
    font-weight: bold;
  }
`

const sT = key => I18n.t(`pages.welcome_to_paid_plans.${key}`)

export default WelcomeToPaidPlans
