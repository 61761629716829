import dayjs from 'dayjs'
import I18n from '../../i18n'

export function timestampToMonthLabel(
  timestamp,
  options = { useYearNumberForJanuary: false, useAbbreviatedMonthName: false }
) {
  if (options.useYearNumberForJanuary && dayjs(timestamp).month() === 0) {
    return `${I18n.strftime(timestamp, '%Y')}`
  }

  return options.useAbbreviatedMonthName
    ? `${I18n.strftime(timestamp, '%b')}`
    : `${I18n.strftime(timestamp, '%B')}`
}
