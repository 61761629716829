import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React from 'react'

const StripChartContainer = styled.div(props => ({
  width: '100%',
  backgroundColor: props.backgroundColor || '#F4F5F8',
  marginTop: 20,
  marginBottom: 20,
  overflowX: 'hidden'
}))

const StripChartArrow = styled.div(props => {
  const ARROW_BASE_WIDTH_IN_PIXELS = 1
  let arrowWidth = ARROW_BASE_WIDTH_IN_PIXELS + props.percentage
  arrowWidth =
    arrowWidth <= ARROW_BASE_WIDTH_IN_PIXELS
      ? ARROW_BASE_WIDTH_IN_PIXELS
      : arrowWidth
  arrowWidth = arrowWidth > 100 ? 100 : arrowWidth

  const ARROW_BASE_OFFSET_IN_PIXELS = 100
  let arrowOffset = -(ARROW_BASE_OFFSET_IN_PIXELS - props.percentage)
  arrowOffset = arrowOffset >= 0 ? 0 : arrowOffset
  arrowOffset =
    arrowWidth < -ARROW_BASE_OFFSET_IN_PIXELS
      ? -ARROW_BASE_OFFSET_IN_PIXELS
      : arrowOffset

  const baseStyles = {
    height: props.chartHeight || 22,
    position: 'relative',
    width: `${Math.round(arrowWidth)}%`,
    backgroundSize: '100% 50%',
    backgroundRepeat: 'no-repeat'
  }

  const leftSideStyles = {
    background:
      'linear-gradient(135deg, transparent 11px, #F48530 0) top left, linear-gradient(45deg, transparent 11px,  #F48530 0) bottom left',
    right: `${arrowOffset}%`
  }

  const rightSideStyles = {
    background:
      'linear-gradient(-135deg, transparent 10px, #F7A53D 0) top right, linear-gradient(-45deg, transparent 10px, #F7A53D 0) bottom right'
  }

  return props.rtl
    ? { ...leftSideStyles, ...baseStyles, ...props.styleOverrides }
    : { ...rightSideStyles, ...baseStyles, ...props.styleOverrides }
})

const StripChartBar = styled.div(props => {
  const ARROW_BASE_WIDTH_IN_PIXELS = 0
  let arrowWidth = ARROW_BASE_WIDTH_IN_PIXELS + props.percentage
  arrowWidth =
    arrowWidth <= ARROW_BASE_WIDTH_IN_PIXELS
      ? ARROW_BASE_WIDTH_IN_PIXELS
      : arrowWidth
  arrowWidth = arrowWidth > 100 ? 100 : arrowWidth

  const ARROW_BASE_OFFSET_IN_PIXELS = 100
  let arrowOffset = -(ARROW_BASE_OFFSET_IN_PIXELS - props.percentage)
  arrowOffset = arrowOffset >= 0 ? 0 : arrowOffset
  arrowOffset =
    arrowWidth < -ARROW_BASE_OFFSET_IN_PIXELS
      ? -ARROW_BASE_OFFSET_IN_PIXELS
      : arrowOffset

  const baseStyles = {
    height: props.chartHeight || 22,
    position: 'relative',
    width: `${Math.round(arrowWidth)}%`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left'
  }

  const chartColor = props.chartColor || '#F48530'
  const leftSideStyles = {
    background: `linear-gradient(90deg, transparent 0px, ${chartColor} 0)`,
    right: `${arrowOffset}%`
  }

  const rightSideStyles = {
    background: `linear-gradient(90deg, transparent 0px, ${chartColor} 0)`,
    left: -1
  }

  return props.rtl
    ? { ...leftSideStyles, ...baseStyles, ...props.styleOverrides }
    : { ...rightSideStyles, ...baseStyles, ...props.styleOverrides }
})

function StripChart({
  percentage,
  rtl,
  chartHeight,
  variant = 'arrow',
  chartColor,
  ...nativeProps
}) {
  return (
    <StripChartContainer {...nativeProps}>
      {variant === 'arrow' ? (
        <StripChartArrow
          percentage={percentage}
          rtl={rtl}
          chartHeight={chartHeight}
        />
      ) : (
        <StripChartBar
          percentage={percentage}
          rtl={rtl}
          chartHeight={chartHeight}
          chartColor={chartColor}
        />
      )}
    </StripChartContainer>
  )
}

StripChart.propTypes = {
  rtl: PropTypes.bool,
  percentage: PropTypes.number,
  chartHeight: PropTypes.string,
  chartColor: PropTypes.any,
  variant: PropTypes.oneOf(['arrow', 'bar'])
}

export default StripChart
