import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import theme from '../theme'
import I18n from '../i18n'

import Button from './Button'
import CloseButton from './CloseButton'
import Headline from './Headline'
import Typography from './Typography'

function PlanSummary({ subscription }) {
  const planName = subscription?.planName || 'free'

  const notice = sT(`${planName}.notice`)
  const howToChange = sT(`${planName}.how_to_change`)
  const [noticeOpen, setNoticeOpen] = useState(true)

  return (
    <div css={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Headline css={{ display: 'flex', alignItems: 'center' }}>
        {sT('headline')}
        <PlanNameTag small>{sT(`${planName}.name`)}</PlanNameTag>
      </Headline>

      {notice && noticeOpen && (
        <NoticeBox>
          <span dangerouslySetInnerHTML={{ __html: notice }} />
          <CloseButton onClick={() => setNoticeOpen(false)} />
        </NoticeBox>
      )}

      {howToChange && (
        <Typography tag="p" styleOverrides={{ margin: '0.5rem 0' }}>
          {howToChange}
        </Typography>
      )}

      {subscription && (
        <>
          <Typography tag="p" styleOverrides={{ margin: '0.5rem 0' }}>
            {sT('your_product')}:{' '}
            <Typography bold>
              {sT(`${planName}.name`)}-{sT('abo')}
            </Typography>
          </Typography>

          <Typography tag="p" styleOverrides={{ margin: '0.5rem 0' }}>
            {sT('contract_until')}:{' '}
            <Typography bold>
              {dayjs(subscription.endDate).format('DD.MM.YYYY')}
            </Typography>
          </Typography>
        </>
      )}

      <Typography tag="h4" styleOverrides={{ marginTop: '0.5rem' }}>
        {sT('my_locations')}
        {subscription &&
          ` (${
            subscription.flatrate ? sT('all') : subscription.locations.length
          })`}
      </Typography>

      {!subscription ? (
        <Typography tag="p" styleOverrides={{ margin: '1rem 0' }}>
          {sT('free_locations')}
        </Typography>
      ) : subscription.flatrate ? (
        <LocationList>
          <LocationCard>
            <div>
              <Typography tag="p" small>
                {sT('flatrate')}
              </Typography>
              <Typography tag="p" bold>
                {sT('all_locations')}
              </Typography>
            </div>
          </LocationCard>
        </LocationList>
      ) : (
        <LocationList>
          {subscription.locations.map(location => (
            <LocationCard key={location.id}>
              <div>
                <Typography tag="p" small>
                  {location.city}
                </Typography>
                <Typography tag="p" bold>
                  {location.name}
                </Typography>
              </div>
            </LocationCard>
          ))}
        </LocationList>
      )}

      <Button.Link
        outline
        href="/locations"
        css={{ margin: '0.5rem 0', alignSelf: 'flex-start' }}
      >
        {sT('show_all_locations')}
      </Button.Link>
    </div>
  )
}

const sT = key => I18n.t(`components.subscription_summary.${key}`)

const PlanNameTag = styled.span`
  padding: 0.25rem 0.5rem;
  margin: 0 0 0 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background: ${theme.chroma.dustyBlue.css()};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

const NoticeBox = styled.div`
  padding: 0.4rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  background-color: ${theme.chroma.lightRed.css()};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`

const LocationList = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
`

const LocationCard = styled.div`
  padding: 0.5rem 1rem;
  flex: calc(100% / 3 - 1rem);
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.chroma.nearlyWhite.css()};
`

PlanSummary.propTypes = {
  subscription: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired,
    planName: PropTypes.string.isRequired
  })
}

export default PlanSummary
