import React from 'react'
import styled from '@emotion/styled'

import I18n from '../../i18n'

import useHystreetIndex from '../../lib/use-hystreet-index'

function Caption() {
  const { preset } = useHystreetIndex()
  const timerange = I18n.helpers.dateRange(preset.from, preset.to, {
    format: 'date.formats.long'
  })

  return (
    <IndexCaption>
      {I18n.t('pages.hystreet_index.caption', {
        timerange: timerange
      })}
    </IndexCaption>
  )
}

const IndexCaption = styled.div`
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  ${props => props.theme.breakpoints.phone} {
    font-size: 0.875rem;
  }
`

export default Caption
