import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  link: 'link'
}

function ActionButton({ title, icon, onClick, variant = 'primary', ...rest }) {
  if (variant === VARIANTS.link) {
    return (
      <LinkButton
        onClick={onClick}
        icon={icon}
        title={title}
        {...rest}
      ></LinkButton>
    )
  }

  if (variant === VARIANTS.secondary) {
    return (
      <SecondaryButton
        onClick={onClick}
        icon={icon}
        title={title}
        {...rest}
      ></SecondaryButton>
    )
  }

  return (
    <PrimaryButton onClick={onClick}>
      <span
        css={{
          fontSize: '0.625rem',
          letterSpacing: 0.63,
          textTransform: 'uppercase',
          fontWeight: 900,
          marginBottom: 2
        }}
      >
        {title}
      </span>
      {icon}
    </PrimaryButton>
  )
}

function LinkButton({ title, icon, onClick, bright, ...rest}) {
  return (
    <LinkStyleButton onClick={onClick} bright={bright} >
      {icon}
      <span>{title}</span>
    </LinkStyleButton>
  )
}

function SecondaryButton({ title, icon, onClick }) {
  return (
    <LightStyleButton onClick={onClick}>
      {icon}
      <span style={{paddingLeft: '0.5rem'}}>{title}</span>
    </LightStyleButton>
  )
}

const PrimaryButton = styled.button`
  color: ${p => p.theme.chroma.base.hex()};
  border: 0.125rem solid ${p => p.theme.chroma.white.hex()};
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  background-color: ${p => p.theme.chroma.dustyBlue.hex()};
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  cursor: pointer;
  margin: 0.625rem 0;
`

const LightStyleButton = styled.button`
  height: 2.5rem;
  background-color: transparent;
  color: ${p => p.theme.chroma.base.hex()};
  border-color: ${p => p.theme.chroma.base.hex()};
  border: 0.125rem solid ${p => p.theme.chroma.base.hex()};
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0.05rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`

const LinkStyleButton = styled.button`
  display: flex;
  align-items: center;
  color: ${props =>
    props.bright
      ? props.theme.chroma.white.hex()
      : props.theme.chroma.base.hex()};
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
  }
  span {
    font-size: 1rem;
    box-shadow: inset 0 -1px 0 0 currentColor;
  }
`

ActionButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired
}

export default ActionButton
