import React, { createContext, useContext, useState } from 'react'

export const context = createContext()

export default function useMapZoom() {
  return useContext(context)
}

export function MapZoomProvider(props) {
  const [mapZoom, setMapZoom] = useState()

  return <context.Provider value={{ mapZoom, setMapZoom }} {...props} />
}
