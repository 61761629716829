import styled from '@emotion/styled'

const Button = styled.button`
  display: block;
  white-space: nowrap;
  padding: 0.875rem 2.875rem;
  background-color: ${p => p.theme.chroma.accent.css()};
  border-color: ${p => p.theme.chroma.accent.css()};
  border-width: 0;
  border-style: solid;

  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.08em;
  line-height: 1;
  color: ${p => p.theme.chroma.accent.css()};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${p => (p.centered ? 'center' : '')};

  opacity: ${p => p.disabled && 0.6};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};

  ${p =>
    p.baseColor &&
    `
      border-color: ${p.theme.chroma.base.css()};
      color: ${p.theme.chroma.base.css()};
      background-color: ${p.theme.chroma.base.css()};
    `}

  ${p =>
    p.outline &&
    `
      padding: 0.75rem 2.75rem;
      border-width: 0.125rem;
      background-color: transparent;
    `}

  ${p =>
    p.outline &&
    p.inverted &&
    `
      color: ${p.theme.chroma.white.css()};
      border-color: ${p.theme.chroma.white.css()};
    `}

  ${p =>
    !p.outline &&
    `
      color: ${p.theme.chroma.white.css()};
    `}

  ${p =>
    !p.outline &&
    p.inverted &&
    `
      color: ${p.theme.chroma.accent.css()};
      border-color: ${p.theme.chroma.accent.css()};
      background-color: ${p.theme.chroma.white.css()};
    `}
`

// Short helper to use a Button as a Link
Button.Link = Button.withComponent('a')

export default Button
