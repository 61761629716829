import styled from '@emotion/styled'

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  ${p => p.theme.breakpoints.phoneUp} {
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  > div {
    flex: 1 0 auto;
    margin: 0.5rem;
    width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);

    ${p => p.theme.breakpoints.phoneUp} {
      margin: 1.5rem;
      max-width: calc(100% - 3rem);
    }

    ${p => p.theme.breakpoints.tabletUp} {
      width: calc(50% - 3rem);
      max-width: calc(50% - 3rem);
    }

    ${p => p.theme.breakpoints.desktopUp} {
      width: calc(33.3% - 3rem);
      max-width: calc(33.3% - 3rem);
    }
  }
`

export default CardList
