import React from 'react'
import styled from '@emotion/styled'
import TextBlog from '../components/TextBlog'
import I18n from '../i18n'

function PartnerSolutionSection() {
  return (
  <TextBlogWrapper>
    <TextBlog heading={I18n.t('partner_page.heading.solutions')} content='partner_page.content.solutions' />
    <TextBlog heading={I18n.t('partner_page.heading.conditions')} content='partner_page.content.conditions' />
  </TextBlogWrapper>
  )
}


const TextBlogWrapper = styled.div`
  @media (max-width: 41rem) {
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem 2rem 8rem 2rem;
  ul {
    line-height: 1rem;
  }
`

export default PartnerSolutionSection
