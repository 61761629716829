import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import i18n from '../../../../i18n'
import { ArrowFilledLeft, ArrowFilledRight } from '../../../Icon'
import {
  AbsolutePedestrianCountLabel,
  ItemContainer,
  PercentageOfTotalPedestriansLabel
} from './LocationStatsCardItemBase'

const ZoneRow = styled.div(props => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 11,
    marginBottom: 2,
    overflow: 'hidden'
  }
})

const ZoneNameLabel = styled.p(({ color }) => {
  return {
    color: color,
    textDecoration: 'underline',
    fontSize: 10,
    textTransform: 'uppercase'
  }
})

const TotalPedsPerZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
  padding-top: 10;
  padding-bottom: 1.25rem;
  min-width: 4rem;
`

const DirectionLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0px 10px 12px;
`

const DirectionLabelContainer = styled.div(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-end'
  }
})

const DirectionLabel = styled.div(() => {
  return {
    fontSize: 10,
    lineHeight: '25px',
    color: '#183E62',
    paddingLeft: 7,
    paddingRight: 7,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden'
  }
})

const SideBySideBarChartContainer = styled.div(() => {
  return {
    width: '100%',
    minHeight: 86
  }
})

const SideBySideBarChart = styled.div(
  ({ percentage, keyColor, keyColorComplement }) => {
    const gradient =
      percentage < 50
        ? `linear-gradient(to left, ${keyColorComplement} ${percentage}% ${100 -
            percentage}%, ${keyColor} ${100 - percentage}%)`
        : `linear-gradient( to right, ${keyColor} ${percentage}% ${100 -
            percentage}%, ${keyColorComplement} ${100 - percentage}%)`

    return {
      height: '100%',
      width: '100%',
      background: gradient,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
)

const BarChartArea = styled.div(({ rtl }) => {
  return {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: rtl ? 'flex-start' : 'flex-end'
  }
})

const BarChartLabelContainer = styled.div(({ rtl }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: rtl ? 'flex-start' : 'flex-end',
    paddingLeft: rtl ? '10px' : '0px',
    paddingRight: rtl ? '0px' : '10px',
    paddingBottom: rtl ? '0px' : '10px',
    paddingTop: rtl ? '10px' : '0px',
    textAlign: rtl ? 'left' : 'right'
  }
})

const BarChartPercentageLabel = styled.div(props => {
  return {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: props.theme.fontSizes.m.fontSize,
    letterSpacing: '0.5px',
    color: props.color
  }
})

const BarChartAbsoluteNumberLabel = styled.div(({ color }) => {
  return {
    color: color
  }
})
export function PercentagePerZoneStatsCardItem({
  zoneKeyColor,
  zoneTextColor,
  zoneKeyColorComplement,
  zoneTextColorComplement,
  zoneName,
  percentageOfTotalPedestriansInAllZones,
  totalPedestriansInZone,
  direction1Reading,
  direction2Reading,
  displayDirectionLabels
}) {
  return (
    <ItemContainer hideBorder fullWidth>
      {displayDirectionLabels && (
        <DirectionLabelRow>
          <DirectionLabelContainer>
            <ArrowFilledLeft></ArrowFilledLeft>
            <DirectionLabel>{direction1Reading.directionName}</DirectionLabel>
          </DirectionLabelContainer>

          <DirectionLabelContainer>
            <DirectionLabel>{direction2Reading.directionName}</DirectionLabel>
            <ArrowFilledRight></ArrowFilledRight>
          </DirectionLabelContainer>
        </DirectionLabelRow>
      )}
      <ZoneRow>
        <TotalPedsPerZoneContainer>
          <ZoneNameLabel color={zoneKeyColor}>{zoneName}</ZoneNameLabel>
          <PercentageOfTotalPedestriansLabel
            color={zoneKeyColor}
          >{`${i18n.toNumber(percentageOfTotalPedestriansInAllZones, {
            precision: 0
          })}%`}</PercentageOfTotalPedestriansLabel>
          <AbsolutePedestrianCountLabel color={zoneKeyColor}>
            {i18n.toNumber(totalPedestriansInZone, { precision: 0 })}
          </AbsolutePedestrianCountLabel>
        </TotalPedsPerZoneContainer>

        <SideBySideBarChartContainer>
          <SideBySideBarChart
            keyColor={zoneKeyColor}
            keyColorComplement={zoneKeyColorComplement}
            percentage={direction1Reading.percentageOfTotalPedestriansInZone}
          >
            <BarChartArea rtl>
              <BarChartLabelContainer rtl>
                <BarChartPercentageLabel
                  color={zoneTextColor}
                >{`${i18n.toNumber(
                  direction1Reading.percentageOfTotalPedestriansInZone,
                  { precision: 0 }
                )}%`}</BarChartPercentageLabel>
                <BarChartAbsoluteNumberLabel color={zoneTextColor}>
                  {i18n.toNumber(
                    direction1Reading.numberOfTotalPedestriansInZone,
                    { precision: 0 }
                  )}
                </BarChartAbsoluteNumberLabel>
              </BarChartLabelContainer>
            </BarChartArea>
            <BarChartArea>
              <BarChartLabelContainer>
                <BarChartPercentageLabel
                  color={zoneTextColorComplement}
                >{`${i18n.toNumber(
                  direction2Reading.percentageOfTotalPedestriansInZone,
                  { precision: 0 }
                )}%`}</BarChartPercentageLabel>
                <BarChartAbsoluteNumberLabel color={zoneTextColorComplement}>
                  {i18n.toNumber(
                    direction2Reading.numberOfTotalPedestriansInZone,
                    { precision: 0 }
                  )}
                </BarChartAbsoluteNumberLabel>
              </BarChartLabelContainer>
            </BarChartArea>
          </SideBySideBarChart>
        </SideBySideBarChartContainer>
      </ZoneRow>
    </ItemContainer>
  )
}

PercentagePerZoneStatsCardItem.propTypes = {
  zoneKeyColor: PropTypes.string,
  zoneTextColor: PropTypes.string,
  zoneKeyColorComplement: PropTypes.string,
  zoneTextColorComplement: PropTypes.string,

  zoneName: PropTypes.string,
  percentageOfTotalPedestriansInAllZones: PropTypes.number,
  totalPedestriansInZone: PropTypes.number,

  direction1Reading: PropTypes.shape({
    directionName: PropTypes.string,
    percentageOfTotalPedestriansInZone: PropTypes.number,
    numberOfTotalPedestriansInZone: PropTypes.number
  }),

  direction2Reading: PropTypes.shape({
    directionName: PropTypes.string,
    percentageOfTotalPedestriansInZone: PropTypes.number,
    numberOfTotalPedestriansInZone: PropTypes.number
  }),

  displayDirectionLabels: PropTypes.bool
}
