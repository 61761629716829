import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import I18n from '../../i18n'

import useHystreetIndex from '../../lib/use-hystreet-index'
import { indexAPI } from '../../lib/api/index/index-api'

function HeaderBar() {
  const {
    preset,
    setPreset,
    maxTimerange,
    enabledTimeranges
  } = useHystreetIndex()

  function isActivePreset(nationalIndexPreset) {
    return (
      nationalIndexPreset.from === dayjs(preset.from).toISOString() &&
      nationalIndexPreset.to === dayjs(preset.to).toISOString() &&
      nationalIndexPreset.resolution === preset.resolution
    )
  }

  function handlePresetClick(preset) {
    setPreset(preset)
  }

  const presets = indexAPI.getNationalIndexTimerangePresets(maxTimerange)

  return (
    <Bar>
      <PresetList>
        <PresetButton
          disabled={!enabledTimeranges.sixMonths}
          active={isActivePreset(presets.sixMonths)}
          title={I18n.t('pages.hystreet_index.presets.six_months')}
          onClick={() => handlePresetClick(presets.sixMonths)}
        />
        <PresetButton
          disabled={!enabledTimeranges.oneYear}
          active={isActivePreset(presets.oneYear)}
          title={I18n.t('pages.hystreet_index.presets.one_year')}
          onClick={() => handlePresetClick(presets.oneYear)}
        />
        <PresetButton
          disabled={!enabledTimeranges.twoYears}
          active={isActivePreset(presets.twoYears)}
          title={I18n.t('pages.hystreet_index.presets.two_years')}
          onClick={() => handlePresetClick(presets.twoYears)}
        />
        <PresetButton
          disabled={!enabledTimeranges.max}
          active={isActivePreset(presets.max)}
          title={I18n.t('pages.hystreet_index.presets.max')}
          onClick={() => handlePresetClick(presets.max)}
        />
      </PresetList>
    </Bar>
  )
}

function PresetButton({ title, onClick, active, disabled = false }) {
  return (
    <Button onClick={onClick} active={active} disabled={disabled}>
      <ButtonContent>
        <span>{title}</span>
      </ButtonContent>
    </Button>
  )
}

PresetButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool
}

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  background-color: ${p => p.theme.chroma.base.css()};
`

const PresetList = styled.div`
  flex: 0 0 auto;
  display: flex;
  margin-left: auto;

  > button {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
`

const Button = styled.button`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.125rem;
  border-color: ${props => props.theme.chroma.white.css()};
  background-color: ${props => props.theme.chroma.base.css()};
  color: ${props => props.theme.chroma.white.css()};
  cursor: pointer;

  ${props =>
    props.disabled &&
    `
      border-color: ${props.theme.chroma.grey.css()};
      background-color: ${props.theme.chroma.grey.css()};
      color: ${props.theme.chroma.coal.css()};
      cursor: default;
    `};

  ${props =>
    !props.disabled &&
    props.active &&
    `
      border-color: ${props.theme.chroma.lightBlue.css()};
      background-color: ${props.theme.chroma.lightBlue.css()};
      cursor: default;
    `};
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 0.5rem 0.625rem;
  span {
    white-space: nowrap;
    font-weight: 700;
    font-size: 0.75rem;

    ${props => props.theme.breakpoints.tabletUp} {
      font-size: 0.875rem;
    }
  }
`
export default HeaderBar
