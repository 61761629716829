import styled from '@emotion/styled'

const TextBoxIcon = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.chroma.base.alpha(0.15).css()};
  background-color: ${props => props.theme.chroma.lightGreen.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
  }
`

export default TextBoxIcon
