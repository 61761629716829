import React from 'react'
import styled from '@emotion/styled'

import { Trans } from '../i18n'
import Section from '../components/Section'

function FactList() {
  return (
    <FactListSection>
      <Section.Wrapper css={{ maxWidth: '46rem' }}>
        <FactsContent>
          <Trans id="pages.methodology.fact_list" copy />
        </FactsContent>
      </Section.Wrapper>
    </FactListSection>
  )
}

const FactListSection = styled(Section)`
  padding-top: 1rem;
  padding-bottom: 2rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 5rem;
    padding-bottom: 11rem;
  }
`

const FactsContent = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 1em;

    ${p => p.theme.breakpoints.tabletUp} {
      font-size: 1.125rem;
    }
  }

  h2 {
    margin-top: 2.5em;
  }
`

export default FactList
