import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import I18n from '../i18n'

import Spacer from '../components/Spacer'

import AboutContact from '../sections/AboutContact'
import DirectorsData from '../sections/DirectorsData'
import DirectorsImage from '../sections/DirectorsImage'
import FooterRegister from '../sections/FooterRegister'
import MissionQuoteForMobile from '../sections/MissionQuoteForMobile'
import Origin from '../sections/Origin'

function About({ locationsCount }) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.about.title')}</title>
      </Helmet>
      <DirectorsImage />
      <DirectorsData />
      <MissionQuoteForMobile />
      <Origin />
      <AboutContact />
      <FooterRegister locationsCount={locationsCount} />
      <Spacer light />
    </div>
  )
}

About.propTypes = {
  locationsCount: PropTypes.number.isRequired
}

export default About
