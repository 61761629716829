import React from 'react'
import PropTypes from 'prop-types'

import View from 'ol/View'
import OSM from 'ol/source/OSM'
import TileLayer from 'ol/layer/Tile'
import { defaults as defaultInteractions } from 'ol/interaction'
import { fromLonLat } from 'ol/proj'

import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import { Fill, Stroke, Style } from 'ol/style'

import theme from '../theme'

const OpenLayersClient = global.OpenLayersClient || {}
const isSSR = !OpenLayersClient.Map
const context = React.createContext()

export function Base({ view, children, ...rest }) {
  const mountEl = React.useRef()
  const map = React.useRef(
    !isSSR &&
      new OpenLayersClient.Map({
        interactions: defaultInteractions(),
        controls: OpenLayersClient.defaultControls(),
        layers: [new TileLayer({ source: new OSM() })],
        view: new View({ ...view, center: fromLonLat(view.center) })
      })
  )

  React.useEffect(() => {
    const mapView = map.current.getView()
    mapView.setCenter(fromLonLat(view.center))
    mapView.setRotation(view.rotation)
    mapView.setZoom(view.zoom)
  }, [view])

  React.useLayoutEffect(() => {
    map.current.setTarget(mountEl.current)
  }, [])

  return (
    <context.Provider value={map.current}>
      <div ref={mountEl} {...rest} />
      {children}
    </context.Provider>
  )
}

Base.propTypes = {
  children: PropTypes.node,
  view: PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.number.isRequired,
    rotation: PropTypes.number
  })
}

export function Vector({ geometry }) {
  const map = React.useContext(context)
  const layer = React.useRef()
  React.useLayoutEffect(() => {
    const newLayer = new VectorLayer({
      style: styleFunction,
      source: new VectorSource({
        features: new GeoJSON().readFeatures(
          {
            type: 'FeatureCollection',
            features: [{ type: 'Feature', geometry }]
          },
          { featureProjection: 'EPSG:3857' }
        )
      })
    })

    if (layer.current) {
      map.removeLayer(layer.current)
    }
    map.addLayer(newLayer)
    layer.current = newLayer
  }, [geometry, map])

  return null
}

const styles = {
  Polygon: new Style({
    stroke: new Stroke({
      color: theme.chroma.lightBlue,
      width: 2
    }),
    fill: new Fill({
      color: theme.chroma.chartAreas[0].fill.alpha(0.3).css()
    })
  })
}

const styleFunction = feature => {
  return styles[feature.getGeometry().getType()]
}
