import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import useAuthorization from './use-authorization'

export const FilterContext = React.createContext()

function filterReducer(filter, action) {
  switch (action.type) {
    case 'compare/timerange':
      return {
        ...filter,
        viewType: 'compare',
        compareType: 'timerange',
        compareFrom: action.from || filter.compareFrom,
        compareTo: action.from
          ? dayjs(action.from)
              .add(dayjs(filter.to).diff(dayjs(filter.from), 'days'), 'days')
              .endOf('day')
              .toDate()
          : filter.compareTo,
        compareWith: undefined
      }

    case 'compare/average':
      return {
        ...filter,
        viewType: 'compare',
        compareType: 'average',
        compareFrom: filter.from,
        compareTo: filter.to,
        compareWith: undefined
      }

    case 'details/height':
      return {
        ...filter,
        viewType: 'details',
        detailsType: 'height'
      }

    case 'details/direction':
      return {
        ...filter,
        viewType: 'details',
        detailsType: 'direction'
      }

    case 'set/objectType':
      return {
        ...filter,
        objectType: action.objectType
      }

    case 'set/objectSubtype':
      return {
        ...filter,
        objectSubtype: action.objectSubtype
      }

    case 'set/objectTypes':
      return {
        ...filter,
        objectType: action.objectType,
        objectSubtype: action.objectSubtype
      }

    case 'set/zone':
      return {
        ...filter,
        viewType: 'zone',
        zone: action.zone || filter.zone
      }

    case 'set/timerange': {
      const compareIsSame = dayjs(filter.compareFrom).isSame(dayjs(filter.from))
      const dayDiff = dayjs(action.to).diff(dayjs(action.from), 'days')

      const from = action.from
      const to = dayjs(action.to)
        .endOf('day')
        .toDate()

      const { defaultResolution, allowedResolutionOptions } = getResolution(
        from,
        to,
        filter.allowedFrontendResolutionsPerTimerange
      )
      const resolution = allowedResolutionOptions.includes(filter.resolution)
        ? filter.resolution
        : defaultResolution

      return {
        ...filter,
        from,
        to,
        resolution: resolution,
        compareFrom: compareIsSame ? from : filter.compareFrom,
        compareTo: compareIsSame
          ? to
          : dayjs(filter.compareFrom)
              .add(dayDiff, 'days')
              .endOf('day')
              .toDate()
      }
    }

    case 'set/resolution': {
      const resolution = action.resolution
      return {
        ...filter,
        resolution
      }
    }

    case 'compare/multiLocation': {
      return {
        ...filter,
        viewType: 'compare',
        compareType: 'multiLocation',
        compareLocations: action.locationIds
      }
    }

    case 'none':
      return {
        ...filter,
        viewType: 'none'
      }
  }
}

export default function useFilter() {
  return React.useContext(FilterContext)
}

export function FilterProvider({ originalUrl, location, ...rest }) {
  const { permissions } = useAuthorization()
  const initialFilter = React.useMemo(() => {
    const params = new URL(originalUrl).searchParams
    function getDateParam(paramName) {
      const param = params.get(paramName)
      return param ? dayjs(param).toDate() : undefined
    }

    const from = new Date(location.metadata.measuredFrom)
    const to = new Date(location.metadata.measuredTo)

    let viewType = 'compare'
    let detailsType = 'direction'
    if (
      params.get('details') &&
      ['height', 'direction'].includes(params.get('details').toLowerCase())
    ) {
      viewType = 'details'
      detailsType = params.get('details').toLowerCase()
    }

    let compareType = 'average'
    let locationIds = []
    if (getDateParam('compare_from') && getDateParam('compare_to')) {
      compareType = 'timerange'
    } else if (params.get('compare_with')) {
      compareType = 'location'
    } else if (params.get('compare_location_ids[]')) {
      locationIds = params.getAll('compare_location_ids[]')
      compareType = 'multiLocation'
    }

    let zone = 'all'
    if (params.get('zone')) {
      viewType = 'zone'
      zone = params.get('zone')
    }
    const { defaultResolution } = getResolution(
      from,
      to,
      permissions.allowedFrontendResolutionsPerTimerange
    )

    return {
      from,
      to,
      viewType,
      detailsType,
      compareType,
      zone,
      compareFrom:
        compareType === 'timerange' ? getDateParam('compare_from') : from,
      compareTo: compareType === 'timerange' ? getDateParam('compare_to') : to,
      compareLocations: locationIds,
      compareWith: params.get('compare_with') || undefined,
      resolution: defaultResolution,
      objectType: 'PERSON',
      objectSubtype: 'ALL',
      allowedFrontendResolutionsPerTimerange:
        permissions.allowedFrontendResolutionsPerTimerange
    }
  }, [
    originalUrl,
    location,
    permissions.allowedFrontendResolutionsPerTimerange
  ])

  const [filter, dispatch] = React.useReducer(filterReducer, initialFilter)

  React.useEffect(() => {
    const urlParams = new URLSearchParams(global.location.search)

    urlParams.delete('object_type')
    urlParams.delete('object_subtype')
    urlParams.delete('from')
    urlParams.delete('to')
    urlParams.delete('compare_from')
    urlParams.delete('compare_to')
    urlParams.delete('compare_with')
    urlParams.delete('details')
    urlParams.delete('zone')
    urlParams.delete('compare_location_ids[]')

    if (filter.objectType) {
      urlParams.set('object_type', filter.objectType)
    }

    if (filter.objectSubtype) {
      urlParams.set('object_subtype', filter.objectSubtype)
    }

    urlParams.set('from', dayjs(filter.from).toISOString())
    urlParams.set('to', dayjs(filter.to).toISOString())

    if (filter.viewType === 'details') {
      urlParams.set('details', filter.detailsType)
    }

    if (filter.viewType === 'zone') {
      urlParams.set('zone', filter.zone)
    }

    if (filter.viewType === 'compare' && filter.compareType === 'timerange') {
      urlParams.set('compare_from', filter.compareFrom.toISOString())
      urlParams.set('compare_to', filter.compareTo.toISOString())
    }

    if (
      filter.viewType === 'compare' &&
      filter.compareType === 'multiLocation'
    ) {
      if (filter.compareLocations && filter.compareLocations.length > 0) {
        filter.compareLocations.map(locationId => {
          urlParams.append('compare_location_ids[]', locationId)
        })
      }
    }

    const searchParamsString = urlParams.toString()
      ? '?' + urlParams.toString()
      : window.location.pathname

    history.replaceState(null, null, searchParamsString)
  }, [filter])
  return <FilterContext.Provider value={[filter, dispatch]} {...rest} />
}

FilterProvider.propTypes = {
  originalUrl: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export function getResolution(
  from,
  to,
  allowedFrontendResolutionsPerTimerange
) {
  const dayDiff = dayjs(to).diff(dayjs(from), 'days')

  if (dayDiff < 2 && dayjs(from).isToday()) {
    return allowedFrontendResolutionsPerTimerange.today
  }

  if (dayDiff < 2) {
    return allowedFrontendResolutionsPerTimerange.lessThanTwoDays
  }

  if (dayDiff < 27) {
    return allowedFrontendResolutionsPerTimerange.betweenTwoDaysAndFourWeeks
  }

  return allowedFrontendResolutionsPerTimerange.moreThanFourWeeks
}
