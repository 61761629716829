import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import * as Icon from './Icon'

function Quotes({ large, children }) {
  const height = large ? '2.375rem' : '1.875rem'
  const width = height

  return (
    <Container>
      <QuotesStart css={{ width, height }} />
      {children}
      <QuotesEnd css={{ width, height }} />
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const QuotesStart = styled(Icon.QuotesStart)`
  position: absolute;
  left: 0;
  bottom: 0;
  color: ${p => p.theme.chroma.accent.hex()};
`

const QuotesEnd = styled(Icon.QuotesEnd)`
  position: absolute;
  right: 0;
  top: 0;
  color: ${p => p.theme.chroma.accent.hex()};
`

Quotes.propTypes = {
  large: PropTypes.bool,
  children: PropTypes.node
}

const Text = styled.blockquote`
  font-weight: 500;
  font-style: italic;
  padding: 2.5rem 0;

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  p:last-of-type {
    margin-top: 0;
  }
`

Quotes.Text = Text

export default Quotes
