import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import I18n from '../i18n'
import useProducts from '../lib/use-products'

import FaqSection from '../sections/FaqSection'
import ProductsTable from '../sections/ProductsTable'

import Button from '../components/Button'
import Section from '../components/Section'
import Typography from '../components/Typography'
import { Phone, Checkmark, Circled, Info2 } from '../components/Icon'
import Tooltip from '../components/Tooltip'

import useMoney from '../lib/use-money'
import Contact from '../sections/Contact'

function Products({ plans, user }) {
  const { toEuros } = useMoney()
  const { onSelectPlan, planButtonText } = useProducts({ user })

  return (
    <ProductsPageWrapper>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>

      <ProductsTable plans={plans} user={user} />

      <Section mid css={{ paddingTop: '4rem', paddingBottom: '5rem' }}>
        <Section.Wrapper>
          <Halves>
            <ContactSection>
              <Phone />
              <Typography tag="h2">{sT('contact.headline')}</Typography>
              <Button.Link href={`tel:${sT('contact.phone')}`}>
                {sT('contact.phone')}
              </Button.Link>
              <Button.Link href={`mailto:${sT('contact.email')}`} outline>
                {sT('contact.email')}
              </Button.Link>
            </ContactSection>
            <ApiInfoSection>
              <Typography tag="h2">{sT('api.headline')}</Typography>
              <p>{sT('api.description')}</p>

              <Halves>
                <Button.Link href="/contact" inverted>
                  {sT('api.contact')}
                </Button.Link>
                <Button.Link href="/developer" outline inverted>
                  {sT('api.learn_more')}
                </Button.Link>
              </Halves>
            </ApiInfoSection>
          </Halves>
        </Section.Wrapper>
      </Section>

      <FunctionsSection mid>
        <Section.Wrapper>
          <FunctionsTable>
            <FunctionsRow>
              <TitleFunctionsCell>
                <Typography tag="h1" small>
                  {sT('functions.title')}
                </Typography>
              </TitleFunctionsCell>
              <FunctionsCell>
                <Typography tag="h2" small>
                  {sT('functions.free.headline')}
                </Typography>
                {sT('functions.free.subline')}
                <Button onClick={() => onSelectPlan('free')} outline>
                  {planButtonText('free')}
                </Button>
              </FunctionsCell>
              <FunctionsCell>
                <Typography tag="h2" small>
                  {sT('functions.basic.headline')}
                </Typography>
                {sT('functions.basic.subline', {
                  price: toEuros(
                    (plans.basic.yearlyPricesCents[0] / 12) *
                      plans.basic.discountRate
                  )
                })}
                <br />
                {I18n.t('common.vat_extra')}
                <Button onClick={() => onSelectPlan('basic')} outline>
                  {planButtonText('basic')}
                </Button>
              </FunctionsCell>
              <FunctionsCell>
                <Typography tag="h2" small>
                  {sT('functions.premium.headline')}
                </Typography>
                {sT('functions.premium.subline', {
                  price: toEuros(
                    (plans.premium.yearlyPricesCents[0] / 12) *
                      plans.premium.discountRate
                  )
                })}
                <br />
                {I18n.t('common.vat_extra')}
                <Button onClick={() => onSelectPlan('premium')} outline>
                  {planButtonText('premium')}
                </Button>
              </FunctionsCell>
              <FunctionsCell>
                <Typography tag="h2" small>
                  {sT('functions.professional.headline')}
                </Typography>
                {sT('functions.professional.subline', {
                  price: toEuros(
                    (plans.professional.yearlyPricesCents[0] / 12) *
                      plans.professional.discountRate
                  )
                })}
                <br />
                {I18n.t('common.vat_extra')}
                <Button onClick={() => onSelectPlan('professional')} outline>
                  {planButtonText('professional')}
                </Button>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsFullRow>
              <Typography tag="h4" capitalize>{sT('functions.data.headline')}</Typography>
            </FunctionsFullRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.data.hourly')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.data.tooltip.hourly')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>{sT('functions.data.today')}</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.data.daily')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.data.tooltip.daily')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell bold>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.data.weekly')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.data.tooltip.weekly')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.data.monthly')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.data.tooltip.monthly')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.data.historical')}
                </FunctionsLabel>
                <Tooltip hoverText={sT('functions.data.tooltip.historical')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>
                {sT('functions.data.last_year')}
              </FunctionsCell>
              <FunctionsCell bold>
                {sT('functions.data.last_year')}
              </FunctionsCell>
              <FunctionsCell bold>
                {sT('functions.data.last_two_years')}
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsFullRow>
              <Typography tag="h4" capitalize>{sT('functions.main.headline')}</Typography>
            </FunctionsFullRow>

            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.main.pedestrian_frequencies')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT(
                    'functions.main.tooltip.pedestrian_frequencies'
                  )}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  <div>{sT('functions.main.ki_supported_data_quality')}</div>
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT(
                    'functions.main.tooltip.ki_supported_data_quality'
                  )}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.main.events')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.main.tooltip.events')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.main.monthly_reports')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.main.tooltip.monthly_reports')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.main.csv_downloads')}
                </FunctionsLabel>
                <Tooltip hoverText={sT('functions.main.tooltip.csv_downloads')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsFullRow>
              <Typography tag="h4" capitalize> {sT('functions.locations.headline')} </Typography>
            </FunctionsFullRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.locations.median')}
                </FunctionsLabel>
                <Tooltip hoverText={sT('functions.locations.tooltip.median')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.locations.zones')}
                </FunctionsLabel>
                <Tooltip hoverText={sT('functions.locations.tooltip.zones')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.locations.directions')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.locations.tooltip.directions')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.locations.childs_adults')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.locations.tooltip.childs_adults')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.locations.vehicle_types')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.locations.tooltip.vehicle_types')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsFullRow>
              <Typography tag="h4" capitalize>{sT('functions.more.headline')}</Typography>
            </FunctionsFullRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.more.choose_visualization')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.more.tooltip.choose_visualization')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.more.map')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.more.tooltip.map')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.more.kpis')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.more.tooltip.kpis')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>{sT('functions.more.weather')}</FunctionsLabel>
                <Tooltip hoverText={sT('functions.more.tooltip.weather')}>
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
            <FunctionsRow>
              <FunctionsCell rowHeader>
                <FunctionsLabel>
                  {sT('functions.more.external_usage')}
                </FunctionsLabel>
                <Tooltip
                  hoverText={sT('functions.more.tooltip.external_usage')}
                >
                  <InfoIcon />
                </Tooltip>
              </FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell bold>&ndash;</FunctionsCell>
              <FunctionsCell>
                <Circled>
                  <Checkmark />
                </Circled>
              </FunctionsCell>
            </FunctionsRow>
          </FunctionsTable>
        </Section.Wrapper>
      </FunctionsSection>

      <FaqSection only={['subscription']} />
      <Contact
        styleOverrides={{ marginTop: '3rem', marginBottom: 0 }}
        teaser="contact.teaser_partner"
        service="contact.service"
      />
    </ProductsPageWrapper>
  )
}

const sT = (path, args) => I18n.t(`pages.products.${path}`, args)


const ProductsPageWrapper = styled.div`
  background-color: ${props => props.theme.chroma.nearlyWhite.hex()};
`

const FunctionsSection = styled(Section)`
  padding-top: '4rem';
  padding-bottom: '5rem';

  ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`

const InfoIcon = styled(Info2)`
  background-color: ${props => props.theme.chroma.white.hex()};
  color: ${props => props.theme.chroma.greyBlue.hex()};
  min-width: 1.5rem;
`

const FunctionsTable = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: row;
  gap: 0.2rem;
`

const FunctionsRow = styled.div`
  display: grid;
  grid-column: 1 / 6;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
`

const FunctionsCell = styled.div`
  text-align: ${props => (props.rowHeader ? 'left' : 'center')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  background-color: ${props => props.theme.chroma.white.hex()};
  display: flex;
  flex-direction: ${props => (props.rowHeader ? 'row' : 'column')};
  gap: 1rem;
  padding: 0.7rem;
  align-items: center;
  justify-content: ${props => (props.rowHeader ? 'flex-start' : 'center')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};

  > *:last-child {
    margin-top: auto;
  }
`

const FunctionsLabel = styled.div`
  flex-grow: 1;
`

const TitleFunctionsCell = styled(FunctionsCell)`
  background-color: transparent;
  justify-content: flex-end;
`

const FunctionsFullRow = styled.div`
  grid-column: 1 / 6;
  background-color: ${props => props.theme.chroma.base.hex()};
  color: ${props => props.theme.chroma.nearlyWhite.hex()};
  padding: 0.2rem 1rem;
`

const Halves = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }
`

const ApiInfoSection = styled.div`
  background-color: ${props => props.theme.chroma.base.hex()};
  color: ${props => props.theme.chroma.white.hex()};
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`

const ContactSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ComingSoonLable = styled.span`
  display: inline-block;
  margin: 0;
  margin-bottom: 0.25rem;
  padding: 0.25rem;

  font-size: 0.6rem;
  font-weight: bold;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;

  color: ${props => props.theme.chroma.base.hex()};
  background: ${props => props.theme.chroma.dustyBlue.hex()};
  border-radius: 4px;
`

Products.propTypes = {
  plans: PropTypes.object.isRequired,
  user: PropTypes.object
}

export default Products
