import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

export const CsrfContext = React.createContext()
export const FormContext = React.createContext({ as: null, idPrefix: null })

function Form({ as, children, idPrefix, ...formProps }) {
  const csrfToken = useContext(CsrfContext)

  return (
    <FormContext.Provider value={{ as, idPrefix }}>
      <FormContainer {...formProps} method="post">
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input
          type="hidden"
          name="_method"
          value={formProps.method || 'post'}
        />
        {children}
      </FormContainer>
    </FormContext.Provider>
  )
}

Form.propTypes = {
  as: PropTypes.string.isRequired,
  children: PropTypes.node,
  idPrefix: PropTypes.string
}

const FormContainer = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

Form.Field = styled.div`
  flex: 0 0 auto;
  width: 100%;
  margin: ${p => !!p.children && '1rem 0'};

  ${p => p.theme.breakpoints.tabletUp} {
    width: ${p => p.half && 'calc(50% - 1.25rem)'};
  }
`

Form.Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 2rem;

  ${p => p.theme.breakpoints.phone} {
    align-items: center;
    margin-bottom: 1.5rem;
  }
`

export default Form
