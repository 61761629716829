import React from 'react'
import PropTypes from 'prop-types'
import * as Icon from './Icon'

export const weatherIconTypes = {
  'clear-day': Icon.Sun,
  'clear-night': Icon.Moon,
  'partly-cloudy-day': Icon.CloudSun,
  'partly-cloudy-night': Icon.CloudMoon,
  cloudy: Icon.Cloud,
  rain: Icon.CloudRain,
  wind: Icon.Wind,
  fog: Icon.CloudFog,
  snow: Icon.Snowflake,
  sleet: Icon.CloudSleet
}

function WeatherIcon({ type, ...rest }) {
  const IconComponent = weatherIconTypes[type] || Icon.Cloud
  return <IconComponent {...rest} />
}

WeatherIcon.propTypes = {
  type: PropTypes.string
}

export default WeatherIcon
