import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import I18n from '../../i18n'
import { FormContext } from './Form'

function TextArea({ label, name, errors, big, ...nativeProps }) {
  const { as: formAs, idPrefix } = useContext(FormContext)
  const inputName = formAs ? formAs + `[${name}]` : name
  const id = (idPrefix || '') + name

  return (
    <Container>
      {label && (
        <Label htmlFor={id}>
          {label} {!nativeProps.required && `(${I18n.t('optional')})`}
        </Label>
      )}
      <div css={{ position: 'relative' }}>
        <InputBox
          id={id}
          big={big}
          name={inputName}
          invalid={!!errors}
          {...nativeProps}
        />
      </div>
      {errors && <ErrorText>{errors.join(', ')}</ErrorText>}
    </Container>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  light: PropTypes.bool,
  big: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errors: PropTypes.array
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.3125rem;
  color: ${p => p.theme.chroma.base.hex()};
`

const InputBox = styled.textarea`
  width: 100%;
  border-radius: 0.125rem;
  padding: 0.5rem;
  border: 1px solid ${p => p.theme.chroma.base.alpha(0.1).css()};
  background-color: ${p =>
    p.light ? p.theme.chroma.white.hex() : p.theme.chroma.nearlyWhite.hex()};
  color: ${p => p.theme.chroma.coal.hex()};
  outline: none;
  transition: all 120ms;
  transition-property: border, box-shadow;

  border-color: ${p => p.invalid && p.theme.chroma.accent.css()};

  &:focus {
    box-shadow: 0 0 0.25rem ${p => p.theme.chroma.base.alpha(0.25).css()};
    border-color: ${p => p.theme.chroma.base.alpha(0.2).css()};
  }

  &::placeholder {
    font-style: italic;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: ${p => p.big && '1.125rem'};
  }

  ${props =>
    props.disabled &&
    `
      background-color: ${props.theme.chroma.nearlyWhite.desaturate(3).hex()};
      color: ${props.theme.chroma.coal.alpha(0.7).css()};
    `}
`

InputBox.propTypes = {
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  light: PropTypes.bool
}

const ErrorText = styled.div`
  color: ${p => p.theme.chroma.accent.css()};
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 0.25rem;
`

export default TextArea
