import React from 'react'
import PropTypes from 'prop-types'
import I18n, { Trans } from '../i18n'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import Checkbox from '../components/forms/Checkbox'
import Button from '../components/Button'
import AlertBox from '../components/AlertBox'

function NewsletterForm({ values = {}, errors = {} }) {
  return (
    <React.Fragment>
      {Object.keys(errors).length > 0 && (
        <AlertBox error css={{ marginBottom: '1rem' }}>
          <Trans id="newsletter.form.error_headline" />
        </AlertBox>
      )}
      <Form
        as="newsletter_subscription"
        action="/newsletter/subscribe"
        method="post"
      >
        <Form.Field half>
          <Input
            label={I18n.t('newsletter.form.fields.first_name')}
            name="first_name"
            defaultValue={values.firstName}
            errors={errors.firstName}
          />
        </Form.Field>
        <Form.Field half>
          <Input
            label={I18n.t('newsletter.form.fields.last_name')}
            name="last_name"
            defaultValue={values.lastName}
            errors={errors.lastName}
          />
        </Form.Field>
        <Form.Field half>
          <Input
            label={I18n.t('newsletter.form.fields.email')}
            name="email"
            defaultValue={values.email}
            errors={errors.email}
          />
        </Form.Field>
        <Form.Field half>
          <Input
            label={I18n.t('newsletter.form.fields.email_confirmation')}
            name="email_confirmation"
            defaultValue={values.emailConfirmation}
            errors={errors.email_confirmation}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label={I18n.t('newsletter.subscribe_checkbox')}
            name="newsletter_optin"
            errors={errors.newsletterOptin}
          />
        </Form.Field>
        <Form.Actions>
          <Button type="submit">
            <Trans id="newsletter.form.subscribe_button" />
          </Button>
        </Form.Actions>
      </Form>
      <small
        css={{
          display: 'inline-block',
          marginTop: '2rem',
          fontSize: '0.875rem',
          lineHeight: '1.4'
        }}
      >
        <Trans id="newsletter.form.disclaimer" />
      </small>
    </React.Fragment>
  )
}

NewsletterForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object
}

export default NewsletterForm
