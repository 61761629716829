import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Quotes from './Quotes'
import Avatar from './Avatar'

function Testimonial({ image, name, children }) {
  return (
    <Row>
      <ImageContainer>
        <Quotes>
          <Avatar src={image} />
        </Quotes>
      </ImageContainer>
      <Content>
        {children}
        <Name>{name}</Name>
      </Content>
    </Row>
  )
}

Testimonial.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${p => p.theme.breakpoints.tabletUp} {
    flex-flow: row wrap;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 3.25rem;

  ${p => p.theme.breakpoints.tabletUp} {
    flex-flow: row wrap;
    margin-bottom: 0;
    margin-right: 3.25rem;
  }
`

const Content = styled.blockquote`
  flex: 1;
  line-height: 1.5;

  ${p => p.theme.breakpoints.phoneUp} {
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 1em;
  }
`

const Name = styled.span`
  display: block;
  color: ${props => props.theme.chroma.accent.hex()};
  margin-top: 1em;
`

export default Testimonial
