import ky from 'ky'
import camelcaseKeys from 'camelcase-keys'
import dayjs from 'dayjs'
import {
  aggregateStatsMeasuredBetweenTimestamps,
  getLocationStatisticsDates
} from '../../calculate-location-statistics'

/**
 * Location API - Abstraction for the location API, using ky to fetch locations
 */
export const locationAPI = {
  /**
   * Get single location
   * @param {string} _key - react-query key
   * @param {number} id - location id
   * @param {object} filter - filter object
   * @param {string} api_version - api version
   * @param {array} responseTransformers - array of response transformers
   */
  getLocation: async function(
    _key,
    id,
    filter,
    apiVersion = 'v1',
    responseTransformers = [
      response => {
        return locationApiResponseTransformers.metaDataToDates(response)
      }
    ]
  ) {
    const baseUrl =
      apiVersion === 'v2' ? '/frontend-api/locations' : '/api/locations'
    try {
      let response = await ky
        .get(`${baseUrl}/${id}`, {
          headers: {
            'Content-Type': 'application/json'
          },
          searchParams: filter,
          timeout: false,
          retry: 0 // react-query will do the retrying
        })
        .json()

      response = camelcaseKeys(response, { deep: true })

      responseTransformers.forEach(transformer => {
        response = transformer(response)
      })

      return response
    } catch (e) {
      console.log(e)
    }
  },

  /**
   * Get many locations
   * @param {string} _key - react-query key
   * @param {array} locationIds - array of location ids
   * @param {object} filter - filter object
   * @returns {array} - array of locations
   */
  getLocations: async function(_key, locationIds, filter) {
    try {
      if (!locationIds || locationIds.length === 0) {
        return
      }

      let locations = []

      for (const locationId of locationIds) {
        const result = await locationAPI.getLocation(
          'location',
          locationId,
          filter
        )
        locations.push(result)
      }

      return locations
    } catch (e) {
      console.log(e)
    }
  },

  /**
   * Fetch location KPIs, which is a special endpoint that returns the same data as the location endpoint, but without
   * generating tracking events and only visible for requests from our own domain.
   * @param {number} id - location id
   * @param {object} filter - filter object
   * @param {string} api_version - api version
   * @returns {object} - object with kpi counts
   */
  getKPIs: async function(id, filter, api_version = 'v1') {
    const parsed = await ky
      .get(`/api/locations/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: `application/vnd.hystreet.${api_version}`
        },
        searchParams: filter,
        timeout: false,
        retry: 0 // react-query will do the retrying
      })
      .json()

    const camelCased = camelcaseKeys(parsed, { deep: true })
    return camelCased
  },

  /**
   * Fetches a series of historical pedestrian counts for a location
   * @param {number} locationId - Unique identifier for the location
   * @param {boolean} shouldSwapDirections - Flag indicating that the walking directions should be swapped for this location
   * @returns {Promise<Object>} - Promise that resolves to an object
   * that uses locationStatisticsDates as keys and LocationStatistics
   * as values.
   * @see getLocationStatisticsDates
   * @see LocationStatistics
   */
  getStatistics: async function(id, shouldSwapDirections = false) {
    const dates = getLocationStatisticsDates({ format: 'ISO_STRING' })
    const datesAsJSDates = getLocationStatisticsDates({ format: 'JS_DATE' })

    const yesterdayResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfYesterday,
      to: dates.endOfYesterday,
      resolution: 'hour'
    })

    const yesterdayAWeekAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfYesterdayAWeekAgo,
      to: dates.endOfYesterdayAWeekAgo,
      resolution: 'day'
    })

    const twoDaysAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfTwoDaysAgo,
      to: dates.endOfTwoDaysAgo,
      resolution: 'hour'
    })

    const threeDaysAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfThreeDaysAgo,
      to: dates.endOfThreeDaysAgo,
      resolution: 'hour'
    })

    const lastWeekResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastWeek,
      to: dates.endOfLastWeek,
      resolution: 'day'
    })

    const twoWeeksAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfTwoWeeksAgo,
      to: dates.endOfTwoWeeksAgo,
      resolution: 'day'
    })

    const lastMonthsResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastMonth,
      to: dates.endOfLastMonth,
      resolution: 'month'
    })

    const lastTwoMonthsResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfTwoMonthsAgo,
      to: dates.endOfLastMonth,
      resolution: 'day'
    })

    const lastWeekAYearAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastWeekAYearAgo,
      to: dates.endOfLastWeekAYearAgo,
      resolution: 'day'
    })

    const lastWeekTwoYearsAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastWeekTwoYearsAgo,
      to: dates.endOfLastWeekTwoYearsAgo,
      resolution: 'day'
    })

    const lastMonthAYearAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastMonthAYearAgo,
      to: dates.endOfLastMonthAYearAgo,
      resolution: 'month'
    })

    const lastMonthTwoYearsAgoResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastMonthTwoYearsAgo,
      to: dates.endOfLastMonthTwoYearsAgo,
      resolution: 'day'
    })

    const thisYearToDateResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfThisYear,
      to: dates.endOfYesterday,
      resolution: 'month'
    })

    const lastYearToDateResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfLastYear,
      to: dates.yesterdayAYearAgo,
      resolution: 'month'
    })

    const twoYearsAgoToDateRepsonse = await locationAPI.getKPIs(id, {
      from: dates.startOfTwoYearsAgo,
      to: dates.yesterdayTwoYearsAgo,
      resolution: 'month'
    })

    const threeYearsAgoToDateResponse = await locationAPI.getKPIs(id, {
      from: dates.startOfThreeYearsAgo,
      to: dates.yesterdayThreeYearsAgo,
      resolution: 'month'
    })

    const locationStatistics = {
      dates: datesAsJSDates,

      yesterday: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfYesterday,
        dates.endOfYesterday,
        yesterdayResponse,
        shouldSwapDirections
      ),

      yesterdayAWeekAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfYesterdayAWeekAgo,
        dates.endOfYesterdayAWeekAgo,
        yesterdayAWeekAgoResponse,
        shouldSwapDirections
      ),

      twoDaysAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfTwoDaysAgo,
        dates.endOfTwoDaysAgo,
        twoDaysAgoResponse,
        shouldSwapDirections
      ),

      threeDaysAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfThreeDaysAgo,
        dates.endOfThreeDaysAgo,
        threeDaysAgoResponse,
        shouldSwapDirections
      ),

      lastWeek: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastWeek,
        dates.endOfLastWeek,
        lastWeekResponse,
        shouldSwapDirections
      ),

      twoWeeksAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfTwoWeeksAgo,
        dates.endOfTwoWeeksAgo,
        twoWeeksAgoResponse,
        shouldSwapDirections
      ),

      lastWeekAYearAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastWeekAYearAgo,
        dates.endOfLastWeekAYearAgo,
        lastWeekAYearAgoResponse,
        shouldSwapDirections
      ),

      lastWeekTwoYearsAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastWeekTwoYearsAgo,
        dates.endOfLastWeekTwoYearsAgo,
        lastWeekTwoYearsAgoResponse,
        shouldSwapDirections
      ),

      lastMonth: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastMonth,
        dates.endOfLastMonth,
        lastMonthsResponse,
        shouldSwapDirections
      ),

      twoMonthsAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfTwoMonthsAgo,
        dates.endOfTwoMonthsAgo,
        lastTwoMonthsResponse,
        shouldSwapDirections
      ),

      lastMonthAYearAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastMonthAYearAgo,
        dates.endOfLastMonthAYearAgo,
        lastMonthAYearAgoResponse,
        shouldSwapDirections
      ),

      lastMonthTwoYearsAgo: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastMonthTwoYearsAgo,
        dates.endOfLastMonthTwoYearsAgo,
        lastMonthTwoYearsAgoResponse,
        shouldSwapDirections
      ),

      thisYearToDate: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfThisYear,
        dates.endOfYesterday,
        thisYearToDateResponse,
        shouldSwapDirections
      ),

      lastYearToDate: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfLastYear,
        dates.yesterdayAYearAgo,
        lastYearToDateResponse,
        shouldSwapDirections
      ),

      twoYearsAgoToDate: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfTwoYearsAgo,
        dates.yesterdayTwoYearsAgo,
        twoYearsAgoToDateRepsonse,
        shouldSwapDirections
      ),

      threeYearsAgoToDate: aggregateStatsMeasuredBetweenTimestamps(
        dates.startOfThreeYearsAgo,
        dates.yesterdayThreeYearsAgo,
        threeYearsAgoToDateResponse,
        shouldSwapDirections
      )
    }

    return locationStatistics
  },

  /**
   * Creates an object with time ranges used in monthly location reports.
   * @param {Date} reportMonthAsDate - Date object representing the month for which the report is generated
   * @returns {Object} - Object with time ranges
   */
  getReportTimeRanges: function(reportMonthAsDate) {
    const startDate = dayjs(reportMonthAsDate)

    const dates = {
      lastMonthTimeRange: {
        startDate: startDate.startOf('month'),
        endDate: startDate.endOf('month')
      },

      twoMonthsAgoTimeRange: {
        startDate: startDate.add(-1, 'month').startOf('month'),
        endDate: startDate.add(-1, 'month').endOf('month')
      },

      threeMonthsAgoTimeRange: {
        startDate: startDate.add(-2, 'month').startOf('month'),
        endDate: startDate.add(-2, 'month').endOf('month')
      },

      sixMonthTimeRange: {
        startDate: startDate.add(-5, 'month').startOf('month'),
        endDate: startDate.endOf('month')
      },

      sixMonthTimeRangeAYearAgo: {
        startDate: startDate
          .add(-1, 'year')
          .add(-5, 'month')
          .startOf('month'),
        endDate: startDate.add(-1, 'year').endOf('month')
      },

      sixMonthTimeRangeTwoYearsAgo: {
        startDate: startDate
          .add(-2, 'year')
          .add(-5, 'month')
          .startOf('month'),
        endDate: startDate.add(-2, 'year').endOf('month')
      },

      sixMonthTimeRangeThreeYearsAgo: {
        startDate: startDate
          .add(-3, 'year')
          .add(-5, 'month')
          .startOf('month'),
        endDate: startDate.add(-3, 'year').endOf('month')
      },

      lastMonthAYearAgoTimeRange: {
        startDate: startDate.add(-1, 'year').startOf('month'),
        endDate: startDate.add(-1, 'year').endOf('month')
      },

      lastMonthTwoYearsAgoTimeRange: {
        startDate: startDate.add(-2, 'year').startOf('month'),
        endDate: startDate.add(-2, 'year').endOf('month')
      }
    }

    return dates
  },

  /**
   * Fetches a series of historical pedestrian counts for a location, used to generate a monthly report
   * @param {String} id - Unique identifier for the location
   * @param {Date} fromDate - Date object representing the month for which the report is generated
   * @returns {Promise<Object>} - Promise that resolves to an object, with keys for each time range and a response from the location-detials API Endpoint as value.
   */
  getReport: async function(id, fromDate) {
    const reportTimeRanges = locationAPI.getReportTimeRanges(
      fromDate.toISOString()
    )

    try {
      const lastSixMonthsResponse = await locationAPI.getLocation(null, id, {
        from: reportTimeRanges.sixMonthTimeRange.startDate.toISOString(),
        to: reportTimeRanges.sixMonthTimeRange.endDate.toISOString(),
        resolution: 'month'
      })

      const lastSixMonthsAYearAgoResponse = await locationAPI.getLocation(
        null,
        id,
        {
          from: reportTimeRanges.sixMonthTimeRangeAYearAgo.startDate.toISOString(),
          to: reportTimeRanges.sixMonthTimeRangeAYearAgo.endDate.toISOString(),
          resolution: 'month'
        }
      )

      const lastSixMonthsTwoYearsAgoResponse = await locationAPI.getLocation(
        null,
        id,
        {
          from: reportTimeRanges.sixMonthTimeRangeTwoYearsAgo.startDate.toISOString(),
          to: reportTimeRanges.sixMonthTimeRangeTwoYearsAgo.endDate.toISOString(),
          resolution: 'month'
        }
      )

      const twoMonthsAgoResponse = await locationAPI.getLocation(null, id, {
        from: reportTimeRanges.twoMonthsAgoTimeRange.startDate.toISOString(),
        to: reportTimeRanges.twoMonthsAgoTimeRange.endDate.toISOString(),
        resolution: 'month'
      })

      const threeMonthsAgoResponse = await locationAPI.getLocation(null, id, {
        from: reportTimeRanges.threeMonthsAgoTimeRange.startDate.toISOString(),
        to: reportTimeRanges.threeMonthsAgoTimeRange.endDate.toISOString(),
        resolution: 'month'
      })

      const lastMonthResponse = await locationAPI.getLocation(null, id, {
        from: reportTimeRanges.lastMonthTimeRange.startDate.toISOString(),
        to: reportTimeRanges.lastMonthTimeRange.endDate.toISOString(),
        resolution: 'day'
      })

      const lastMonthAYearAgoResponse = await locationAPI.getLocation(
        null,
        id,
        {
          from: reportTimeRanges.lastMonthAYearAgoTimeRange.startDate.toISOString(),
          to: reportTimeRanges.lastMonthAYearAgoTimeRange.endDate.toISOString(),
          resolution: 'day'
        }
      )

      const lastMonthTwoYearsAgoResponse = await locationAPI.getLocation(
        null,
        id,
        {
          from: reportTimeRanges.lastMonthTwoYearsAgoTimeRange.startDate.toISOString(),
          to: reportTimeRanges.lastMonthTwoYearsAgoTimeRange.endDate.toISOString(),
          resolution: 'day'
        }
      )

      const result = {
        lastMonthData: lastMonthResponse,
        twoMonthsAgoData: twoMonthsAgoResponse,
        threeMonthsAgoData: threeMonthsAgoResponse,
        lastMonthAYearAgoData: lastMonthAYearAgoResponse,
        lastMonthTwoYearsAgoData: lastMonthTwoYearsAgoResponse,
        lastSixMonthsData: lastSixMonthsResponse,
        lastSixMonthsAYearAgoData: lastSixMonthsAYearAgoResponse,
        lastSixMonthsTwoYearsAgoData: lastSixMonthsTwoYearsAgoResponse
      }

      return result
    } catch (e) {
      console.log('Error fetching report data', e)
    }
  }
}

/**
 * Location API Response Transformers - Collection of transformer functions that can be applied to locationAPI responses
 * in a pipeline fashion, e.g. response => transformer1(response) => transformer2(response) => transformer3(response)
 */
export const locationApiResponseTransformers = {
  /**
   * Transforms dates contained in the "measurement"-attribute of a location-detials API response to JS Date objects
   * @param {object} locationApiResponse
   * @returns {object} - The transformed locationApiResponse
   */
  measurementTimestampsToDates: function(locationApiResponse) {
    return {
      ...locationApiResponse,
      measurements: locationApiResponse.measurements.map(measurement => {
        return {
          ...measurement,
          measuredAt: new Date(measurement.measuredAt)
        }
      })
    }
  },

  /**
   * Transforms dates contained in the "metadata"-attribute of a location-detials API response to JS Date objects
   * @param {object} locationApiResponse
   * @returns {object} - The transformed locationApiResponse
   */
  metaDataToDates: function(locationApiResponse) {
    return {
      ...locationApiResponse,
      metadata: {
        ...locationApiResponse.metadata,
        earliestMeasurementAt: new Date(
          locationApiResponse.metadata.earliestMeasurementAt
        ),
        latestMeasurementAt: new Date(
          locationApiResponse.metadata.latestMeasurementAt
        ),
        measuredFrom: new Date(locationApiResponse.metadata.measuredFrom),
        measuredTo: new Date(locationApiResponse.metadata.measuredTo)
      }
    }
  }
}
