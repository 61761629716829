import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Section from '../components/Section'
import EmailIcon from '../sections/assets/mail.svg'
import PhoneIcon from '../sections/assets/phone.svg'

function Contact({ teaser, cta, service, styleOverrides }) {
  return (
    <CustomSection accent style={{...styleOverrides}}>
      <Section.Skew accent direction="up" position="top" />
      <Section.Wrapper>
        <Heading>
          <Trans id="contact.heading" />
        </Heading>
        <ContentWrapper>
          <Content>
            <Teaser>
              <Trans id={teaser} />
            </Teaser>
            {cta && (
              <Text>
                <Trans id={cta} />
              </Text>
            )}
            {service && (
              <Text css={{ marginTop: '4rem' }}>
                <Trans id={service} />
              </Text>
            )}
          </Content>
          <Content>
            <InfoWrapper>
              <ContactWrapper>
                <EmailIcon />
                <IconText href="mailto:info@hystreet.com">
                  <Trans id="contact.email" />
                </IconText>
              </ContactWrapper>
              <ContactWrapper>
                <PhoneIcon />
                <IconText href="tel:+4922177204251">
                  <Trans id="contact.phone" />
                </IconText>
              </ContactWrapper>
            </InfoWrapper>
          </Content>
        </ContentWrapper>
      </Section.Wrapper>
    </CustomSection>
  )
}

const CustomSection = styled(Section)`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`

const IconText = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 1.5rem;
  max-width: 70%;
  white-space: nowrap;
  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`

const ContentWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.chroma.white.hex()};
  width: 100%;
  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
  }
`

const Content = styled.div`
  ${props => props.theme.breakpoints.tabletUp} {
    &:first-of-type {
      width: 55%;
    }
    &:last-of-type {
      padding-left: 3rem;
    }
  }
`

const Heading = styled.h3`
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 3rem;
  color: ${props => props.theme.chroma.white.hex()};
  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`

const Teaser = styled.p`
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 2rem;
  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`

const Text = styled.p`
  margin-bottom: 4rem;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 900;
  ${props => props.theme.breakpoints.tabletUp} {
    font-size: 1.5rem;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const ContactWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

Contact.propTypes = {
  teaser: PropTypes.string.isRequired,
  cta: PropTypes.string,
  service: PropTypes.string
}

export default Contact
