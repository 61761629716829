import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import {
  CartesianGrid,
  ComposedChart,
  Customized,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import I18n from '../../i18n'
import theme from '../../theme'
import Incident, { INCIDENT_HEIGHT } from './Incident'
import LoadingIndicator from './LoadingIndicator'

// How the line is interpolated between two values. See http://recharts.org/en-US/api/Area#type
// Changes the smoothness of the graph lines.
const INTERPOLATION_TYPE = 'linear'
const GRAPH_LINE_WIDTH = '0.25rem'

function CustomizedLabel({ x, y, stroke, value, valueIsLastInLine }) {
  if (valueIsLastInLine) {
    return (
      <text
        x={x}
        y={y}
        dy={-5}
        dx={20}
        fill={stroke}
        fontSize={16}
        textAnchor="middle"
      >
        {value}
      </text>
    )
  } else return null
}

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  value: PropTypes.any
}

function LineGraph({
  plot,
  isLoading,
  incidentRows = [],
  stackedChartKeys = ['pedestriansCount'],
  aspectRatio,
  displayLegend,
  name,
  displayTooltip,
  tooltipContent,
  displayLineEndingLabel,
  lineEndingLabels,
  tickInterval = 0
}) {
  const hasPlot = plot.length > 0

  function formatYTick(payload) {
    return I18n.toNumber(payload, { precision: 0 })
  }

  const [activeIncidentDetail, setActiveIncidentDetail] = React.useState(null)

  return (
    <ChartStyleOverrides>
      <LoadingWrapper>
        <LoadingIndicator active={isLoading} />
      </LoadingWrapper>

      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            width: 0,
            flexShrink: 0,
            flexBasis: '100%'
          }}
        >
          <ResponsiveContainer aspect={aspectRatio} debounce={16}>
            <ComposedChart
              data={plot}
              style={{
                overflow: 'visible',
                opacity: isLoading ? 0.3 : 1,
                filter: isLoading && 'grayscale(50%)',
                transition: 'all 400ms'
              }}
              margin={{
                left: 24,
                right: 24,
                top: 5,

                // Always add some bottom space even if there are no incidents to
                // prevent those ugly graph transitions, where the graph floats up
                // or down. It won't prevent it if there are multiple incidentRows,
                // but that shouldn't happen as often.
                bottom: 15 + INCIDENT_HEIGHT * (incidentRows.length || 1)
              }}
            >
              <defs>
                {[0, 1].map(i => (
                  <pattern
                    key={i}
                    id={'diagonalHatch' + i}
                    patternUnits="userSpaceOnUse"
                    width="7"
                    height="7"
                    patternTransform="rotate(-45 0 0)"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="7"
                      height="7"
                      fill={theme.chroma.chartAreas[i].fill.css()}
                    />
                    <line
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="7"
                      style={{
                        stroke: theme.chroma.chartAreas[i].hatch.css(),
                        strokeWidth: 2
                      }}
                    />
                  </pattern>
                ))}
              </defs>
              <XAxis
                hide={!hasPlot}
                dataKey="timestamp"
                height={40}
                interval={tickInterval}
                axisLine={{
                  stroke: theme.chroma.pencil.css()
                }}
                tick={{ fontSize: 14 }}
                tickLine={false}
              />
              <YAxis
                hide={!hasPlot}
                tick={{
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  fill: theme.chroma.pencil.css()
                }}
                axisLine={{
                  stroke: theme.chroma.pencil.css()
                }}
                tickLine={{
                  stroke: theme.chroma.pencil.css()
                }}
                padding={{ top: 10 }}
                width={50}
                tickFormatter={formatYTick}
              />
              {displayTooltip && <Tooltip content={tooltipContent} />}
              {displayLegend && <Legend />}
              <CartesianGrid
                strokeDasharray="1 1"
                stroke={theme.chroma.lightGrey.css()}
              />

              {stackedChartKeys.map((chartKey, i) => {
                return (
                  <Line
                    key={i}
                    name={name}
                    type={INTERPOLATION_TYPE}
                    dataKey={chartKey}
                    stroke={theme.chroma.KPICharts[
                      stackedChartKeys.length - 1 - i
                    ].fill.css()}
                    strokeWidth={GRAPH_LINE_WIDTH}
                    dot={false}
                    animationDuration={400}
                    label={props => {
                      const lastMeasurement = plot[plot.length - 1]
                      const valueIsLastInLine =
                        props.value === lastMeasurement[chartKey]

                      return displayLineEndingLabel ? (
                        <CustomizedLabel
                          {...props}
                          value={lineEndingLabels[i]}
                          stroke={theme.chroma.KPICharts[i].fill.css()}
                          valueIsLastInLine={valueIsLastInLine}
                        ></CustomizedLabel>
                      ) : null
                    }}
                  />
                )
              })}

              {hasPlot &&
                incidentRows.map((incidentRow, rowIndex) =>
                  incidentRow.map(incident => (
                    <Customized
                      key={incident.id}
                      bottomMargin={
                        INCIDENT_HEIGHT * (incidentRows.length - 1 - rowIndex)
                      }
                      incident={incident}
                      isDetailActive={incident.id === activeIncidentDetail}
                      onCloseDetail={() => setActiveIncidentDetail(null)}
                      onClick={() => {
                        setActiveIncidentDetail(incident.id)
                      }}
                      component={Incident}
                    />
                  ))
                )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </ChartStyleOverrides>
  )
}

LineGraph.propTypes = {
  plot: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  incidentRows: PropTypes.array,
  aspectRatio: PropTypes.number,
  stackedChartKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  chartType: PropTypes.string,
  locationMetadata: PropTypes.object,
  viewType: PropTypes.string,
  displayLegend: PropTypes.bool,
  name: PropTypes.string,
  displayTooltip: PropTypes.bool,
  tooltipContent: PropTypes.element,
  displayLineEndingLabel: PropTypes.bool,
  lineEndingLabels: PropTypes.arrayOf(PropTypes.string),
  tickInterval: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  pointer-events: none;
`

const ChartStyleOverrides = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;

  .recharts-responsive-container {
    flex: 1 auto;
  }
`

export default LineGraph
