import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Gateway } from 'react-gateway'
import { css } from '@emotion/css'

import {
  Lightning,
  Christmas,
  Party,
  ManualEntry,
  Calendar,
  Easter
} from '../Icon'
import useClientRect from '../../lib/use-client-rect'
import theme from '../../theme'

import IncidentDetail from './IncidentDetail'

const STROKE_WIDTH = 2
const CAP_SIZE = STROKE_WIDTH * 2
const MAX_ICON_SIZE = 24
const MIN_INCIDENT_WIDTH = STROKE_WIDTH * 4
export const INCIDENT_HEIGHT = 32

const ICONS = {
  lightning: Lightning,
  manual_entry: ManualEntry,
  calendar: Calendar,
  christmas: Christmas,
  party: Party,
  easter: Easter
}

const style = {
  fill: 'none',
  stroke: 'currentColor',
  strokeWidth: STROKE_WIDTH
}

function Incident(props) {
  const IncidentIcon = ICONS[props.incident.icon] || Lightning

  // We use the horizontal "resolution" of the graph by checking each tooltipTick
  const x1 = getEarliestMatch(props.tooltipTicks, props.incident.activeFrom)
    .coordinate
  const x2 = getEarliestMatch(props.tooltipTicks, props.incident.activeTo)
    .coordinate
  const y = props.height - props.bottomMargin - INCIDENT_HEIGHT
  const width = x2 - x1
  const iconSize = Math.min(MAX_ICON_SIZE, width)

  const [incidentRect, incidentContainer] = useClientRect([x1, x2])

  if (width < MIN_INCIDENT_WIDTH) {
    return null
  }

  return (
    <React.Fragment>
      <svg
        width={width}
        height={INCIDENT_HEIGHT}
        viewBox={`0 0 ${width} ${INCIDENT_HEIGHT}`}
        x={x1}
        y={y}
        onClick={props.onClick}
        ref={incidentContainer}
        className={css`
          color: ${props.isDetailActive
            ? theme.chroma.base.css()
            : theme.chroma.lightBlue.css()};
          cursor: pointer;
          fill: currentColor;

          &:hover {
            color: ${theme.chroma.base.css()};
          }
        `}
      >
        <rect
          x="0"
          y="0"
          width={width}
          height={INCIDENT_HEIGHT}
          fill={theme.chroma.white.css()}
        />

        <LinecapFrom showArrow={props.incident.isCutOffFrom} x={0} />
        <LinecapTo showArrow={props.incident.isCutOffTo} x={width} />

        <IncidentIcon
          x={width / 2 - iconSize / 2}
          y={MAX_ICON_SIZE - iconSize}
          width={iconSize}
          height={iconSize}
          fill="currentColor"
        />

        <line
          x1={STROKE_WIDTH / 2}
          y1={INCIDENT_HEIGHT - CAP_SIZE}
          x2={width - STROKE_WIDTH / 2}
          y2={INCIDENT_HEIGHT - CAP_SIZE}
          style={style}
        />
      </svg>

      {typeof window === 'object' && props.isDetailActive && (
        // TODO: Remove the Gateway cause it's outdated, 7 years non maintaned
        <Gateway into="global">
          <IncidentDetail
            anchorTo={incidentRect}
            onClose={props.onCloseDetail}
            incident={props.incident}
            icon={IncidentIcon}
          />
        </Gateway>
      )}
    </React.Fragment>
  )
}

Incident.propTypes = {
  height: PropTypes.number,
  bottomMargin: PropTypes.number,
  isDetailActive: PropTypes.bool,
  onClick: PropTypes.func,
  onCloseDetail: PropTypes.func,
  incident: PropTypes.shape({
    isCutOffFrom: PropTypes.boolean,
    isCutOffTo: PropTypes.boolean,
    icon: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    activeFrom: PropTypes.string,
    activeTo: PropTypes.string
  }),
  tooltipTicks: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string }))
}

function LinecapFrom({ showArrow, x }) {
  const adjustedX = x + STROKE_WIDTH / 2

  if (showArrow) {
    return (
      <polyline
        points={pointsToString([
          [adjustedX + CAP_SIZE, INCIDENT_HEIGHT - CAP_SIZE * 2],
          [adjustedX, INCIDENT_HEIGHT - CAP_SIZE],
          [adjustedX + CAP_SIZE, INCIDENT_HEIGHT]
        ])}
        style={style}
      />
    )
  }

  return (
    <line
      x1={adjustedX}
      y1={INCIDENT_HEIGHT - CAP_SIZE * 2}
      x2={adjustedX}
      y2={INCIDENT_HEIGHT}
      style={style}
    />
  )
}

function LinecapTo({ showArrow, x }) {
  const adjustedX = x - STROKE_WIDTH / 2

  if (showArrow) {
    return (
      <polyline
        points={pointsToString([
          [adjustedX - CAP_SIZE, INCIDENT_HEIGHT - CAP_SIZE * 2],
          [adjustedX, INCIDENT_HEIGHT - CAP_SIZE],
          [adjustedX - CAP_SIZE, INCIDENT_HEIGHT]
        ])}
        style={style}
      />
    )
  }

  return (
    <line
      x1={adjustedX}
      y1={INCIDENT_HEIGHT - CAP_SIZE * 2}
      x2={adjustedX}
      y2={INCIDENT_HEIGHT}
      style={style}
    />
  )
}

LinecapTo.propTypes = LinecapFrom.propTypes = {
  showArrow: PropTypes.bool,
  x: PropTypes.number
}

function pointsToString(points) {
  return points.map(pair => pair.join(',')).join(' ')
}

function getEarliestMatch(tooltipTicks, date) {
  const tick = tooltipTicks.find(tick => afterOrSame(tick.value, date))

  return tick || tooltipTicks[tooltipTicks.length - 1]
}

function afterOrSame(date1, date2) {
  return !dayjs(date1).isBefore(dayjs(date2))
}

export default Incident
