import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import theme from '../theme'
import I18n from '../i18n'

import Card from '../components/Card'
import Headline from '../components/Headline'
import Section from '../components/Section'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import Button from '../components/Button'
import Typography from '../components/Typography'

function Login({ user = {} }) {
  return (
    <div>
      <Helmet>
        <title>{sT('title')}</title>
      </Helmet>
      <Section row mid>
        <Section.Wrapper>
          <Card
            css={{
              padding: '2rem',
              margin: '4rem auto',
              maxWidth: '36rem',
              flex: '1'
            }}
          >
            <Headline>{sT('headline')}</Headline>
            <Typography tag="p" styleOverrides={{ marginTop: '1rem' }}>
              {sT('new')}{' '}
              <a
                href="/products"
                style={{
                  color: theme.chroma.accent,
                  textDecoration: 'underline'
                }}
              >
                {sT('goto_products')}
              </a>
            </Typography>
            <Form
              as="user"
              onSubmit={() => {
                window._paq?.push(['trackEvent', 'User', 'Login'])
              }}
              action="/login"
              method="post"
              idPrefix="login_"
            >
              <Form.Field>
                <Input
                  light
                  label={sT('fields.email')}
                  name="email"
                  defaultValue={user.values.email}
                  errors={user.errors.email}
                  autoFocus
                  required
                />
              </Form.Field>
              <Form.Field>
                <Input
                  light
                  label={sT('fields.password')}
                  name="password"
                  errors={user.errors.password}
                  type="password"
                  required
                />
              </Form.Field>
              <a
                href="/password-reset"
                css={{
                  textAlign: 'right',
                  textDecoration: 'underline',
                  width: '100%'
                }}
              >
                {sT('forgot_password')}
              </a>
              <Form.Actions>
                <Button type="submit">{sT('button')}</Button>
              </Form.Actions>
            </Form>
          </Card>
        </Section.Wrapper>
      </Section>
    </div>
  )
}

const sT = key => I18n.t(`pages.login.${key}`)

Login.propTypes = {
  user: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object
  })
}

export default Login
