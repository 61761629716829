import React from 'react'
import styled from '@emotion/styled'

import { Trans } from '../i18n'
import Section from '../components/Section'
import Headline from '../components/Headline'
import WeatherIcon, { weatherIconTypes } from '../components/WeatherIcon'

function WeatherLegend() {
  return (
    <WeatherSection mid>
      <Section.Wrapper>
        <Content>
          <Headline breakout>
            <Trans id="pages.methodology.weather.headline" />
          </Headline>
          <Trans id="pages.methodology.weather.content" copy />

          <LegendList>
            {Object.keys(weatherIconTypes).map(type => (
              <LegendItem key={type}>
                <WeatherIcon type={type} width={32} />
                <span>
                  <Trans id={`pages.methodology.weather.legend.${type}`} />
                </span>
              </LegendItem>
            ))}
          </LegendList>
        </Content>
      </Section.Wrapper>
    </WeatherSection>
  )
}

const WeatherSection = styled(Section)`
  padding-top: 1rem;
  padding-bottom: 3rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
`

const Content = styled.div`
  ${p => p.theme.breakpoints.tabletUp} {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1em;

    ${p => p.theme.breakpoints.tabletUp} {
      font-size: 1.125rem;
    }
  }

  a {
    text-decoration: underline;
  }
`

const LegendList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`

const LegendItem = styled.li`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  margin-top: 2rem;
  width: 50%;
  min-width: 14rem;

  span {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export default WeatherLegend
