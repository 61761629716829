import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import I18n from '../../i18n'

dayjs.extend(isoWeek)

// prettier-ignore
const POSSBILE_VISIBLE_HOURS = [
  [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
  [0, 4, 8, 12, 16, 20],
  [0, 6, 12, 18],
  [0, 12],
  []
]

// prettier-ignore
const POSSIBLE_VISIBLE_DAYS = [
  [1, 2, 3, 4, 5, 6, 0],
  [1, 3, 5],
  [1],
  []
]

const TYPES = {
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months'
}

function XAxisTick({ width, height, x, y, payload, startDate, endDate }) {
  const currDate = payload.value
  const currDay = dayjs(currDate)
  const dayRange = dayjs(endDate).diff(dayjs(startDate), 'days') + 1
  const weekRange = dayjs(endDate).diff(dayjs(startDate), 'weeks') + 1
  const minTickWidth = 50
  const maxTickAmount = Math.floor(width / minTickWidth)
  const type = React.useMemo(() => {
    if (dayRange < 5) return TYPES.HOURS
    if (dayRange < 14) return TYPES.DAYS
    if (dayRange < 60) return TYPES.WEEKS
    return TYPES.MONTHS
  }, [dayRange])

  const isStartOf = React.useMemo(
    () => ({
      day: currDay.isSame(currDay.startOf('day')),
      week: currDay.isSame(currDay.startOf('week')),
      month: currDay.isSame(currDay.startOf('month'))
    }),
    [currDay]
  )

  const firstLine = React.useMemo(() => {
    switch (type) {
      case TYPES.HOURS:
        return currDay.format('HH:mm')
      case TYPES.DAYS:
        return I18n.strftime(currDay.toDate(), '%a')
      case TYPES.WEEKS: {
        const longFormat = '%d.%m.'
        const shortFormat = '%d.'
        const format =
          payload.index === 0 || isStartOf.week ? longFormat : shortFormat
        return I18n.strftime(currDay.toDate(), format)
      }
      case TYPES.MONTHS:
        return I18n.strftime(currDay.toDate(), '%b')
      default:
        return ''
    }
  }, [currDay, isStartOf, payload.index, type])

  const secondLine = React.useMemo(() => {
    switch (type) {
      case TYPES.HOURS: {
        const longFormat = '%a, %d.%m.'
        const shortFormat = '%a'
        const format = payload.index === 0 ? longFormat : shortFormat
        return I18n.strftime(currDay.toDate(), format)
      }

      case TYPES.DAYS: {
        const longFormat = '%d.%m.'
        const shortFormat = '%d.'
        const format =
          payload.index === 0 || isStartOf.month ? longFormat : shortFormat
        return I18n.strftime(currDay.toDate(), format)
      }

      case TYPES.WEEKS: {
        return `${I18n.t('location.timerange.week.general')} ${dayjs(
          currDay
        ).isoWeek()}`
      }
      default:
        return ''
    }
  }, [currDay, isStartOf, payload.index, type])

  const hasFirstLine = React.useMemo(() => {
    switch (type) {
      case TYPES.HOURS: {
        const visibleHours = POSSBILE_VISIBLE_HOURS.filter(
          candidate => candidate.length * dayRange <= maxTickAmount
        ).shift()
        return visibleHours.includes(currDay.hour())
      }

      case TYPES.DAYS: {
        const visibleDays = POSSIBLE_VISIBLE_DAYS.filter(
          candidate => candidate.length <= maxTickAmount
        ).shift()
        return visibleDays.includes(currDay.day()) && isStartOf.day
      }

      case TYPES.WEEKS: {
        const visibleDays = POSSIBLE_VISIBLE_DAYS.filter(
          candidate => candidate.length * weekRange <= maxTickAmount
        ).shift()
        return visibleDays.includes(currDay.day()) && isStartOf.day
      }

      case TYPES.MONTHS:
        return isStartOf.month

      default:
        return false
    }
  }, [currDay, isStartOf, maxTickAmount, type, dayRange, weekRange])

  const hasSecondLine = React.useMemo(() => {
    switch (type) {
      case TYPES.HOURS:
        return isStartOf.day
      case TYPES.DAYS:
        return hasFirstLine
      case TYPES.WEEKS:
        return isStartOf.week
      default:
        return false
    }
  }, [hasFirstLine, isStartOf, type])

  if (!hasFirstLine && !hasSecondLine) return null

  return (
    <>
      <text
        width={width}
        height={height}
        x={x}
        y={y + 6}
        stroke="none"
        fill="#767778"
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="middle"
        css={{ fontSize: '0.875rem', fontWeight: 'bold' }}
      >
        {hasFirstLine && (
          <tspan x={x} dy="0.71em">
            {firstLine}
          </tspan>
        )}
        {hasSecondLine && (
          <tspan x={x} y={y + 25} dy="0.71em">
            {secondLine}
          </tspan>
        )}
      </text>
    </>
  )
}

XAxisTick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired
  }),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
}

export default XAxisTick
