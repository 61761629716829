import dayjs from 'dayjs'
import I18n from 'i18n-js'

export function getCalendarWeekLabel(date) {
  return `(${I18n.t('location.details.calendarWeek')} 
  ${determineWeekOfYear(date)})`
}

// Helper function, which will become obsolete after the bug on dayjs calendar week calculation is fixed
// Essentially this fixes the bug of incorrect handling of how eg. a leapyear affects the first of the year to not be in CW 1
export function determineWeekOfYear(date) {
  let year = dayjs(date).year()
  let firstOfTheYear = year + '-01-01'
  let weekdayOfFirst = dayjs(firstOfTheYear).weekday()

  //If the 1.1.XXXX is between a monday and a thurday, it will be in calendar week 1
  //Else it will be off by 1
  if (weekdayOfFirst > 3) {
    let fixedDate = dayjs(date).week() - 1
    if (fixedDate == 0) fixedDate = fixEarlyDaysEdgeCases()
    return fixedDate
  } else {
    return dayjs(date).week()
  }
  //There will be an edge case when asking for up to the first 2 days of a year, these should display week 53 or 52 respectively
  function fixEarlyDaysEdgeCases() {
    if (
      (dayjs(year - 1 + '-01-01').isLeapYear() && weekdayOfFirst == 5) ||
      weekdayOfFirst == 4
    ) {
      return 53
    } else {
      return 52
    }
  }
}
