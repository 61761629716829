import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { XAxis } from 'recharts'
import Logo from '../components/assets/logo.svg'
import Button from '../components/Button'
import Card from '../components/Card'
import Graph from '../components/Graph/Graph'
import LineGraph from '../components/Graph/LineGraph'
import LoadingIndicator from '../components/Graph/LoadingIndicator'
import Grid, { Item } from '../components/Grid'
import Headline from '../components/Headline'
import { PedestrianCountComparisonStatsCardItem } from '../components/LocationDetail/LocationStatsCard/LocationStatsCard'
import StripChart from '../components/LocationDetail/StripChart'
import RankingTrendIcon from '../components/RankingTrend'
import Section from '../components/Section'
import Typography from '../components/Typography'
import I18n, { Trans } from '../i18n'
import {
  calculatePercentage,
  getAbsoluteDifference,
  getDifferenceAsPercentageOrMissingLabel,
  isDataPresentForTimeRange,
  measurementPeriodSpansAcrossYearBreak
} from '../lib/calculate-location-statistics'
import theme from '../theme'
import { useReport } from '../lib/api/location/use-location-api'
import { locationAPI } from '../lib/api/location/location-api'

function timestampToMonthLabel(timestamp) {
  return `${I18n.strftime(timestamp, '%B')}`
}

function transformApiResponsesToLineChartData(
  locationDetailApiResponses,
  chartKeys
) {
  const rawData = [...locationDetailApiResponses]

  if (chartKeys.length !== locationDetailApiResponses.length) {
    throw new Error(
      'Cannot transform API responses to plottable Chart-Data with unequal lengths of chartKeys and measurements.'
    )
  }

  // Assign the chartkey to every measurement
  rawData.map((response, index) => {
    response.measurements = response.measurements.map(
      (measurement, measurementIndex) => {
        return {
          timestamp: timestampToMonthLabel(new Date(measurement.timestamp)),
          [chartKeys[index]]: measurement.pedestriansCount
        }
      }
    )
  })

  // Aggregate all measurements together in a single array
  const arrayOfMeasurementArrays = [...rawData].map(response => {
    return response.measurements
  })
  const allMeasurementsWithChartKeys = Array.prototype.concat.apply(
    [],
    arrayOfMeasurementArrays
  )

  const plotData = []

  // Merge array elements with the same month in timestamp
  const NUMBER_OF_MONTHS = 6

  for (let i = 0; i < NUMBER_OF_MONTHS; i++) {
    const mergedObject = Object.assign(
      {},
      allMeasurementsWithChartKeys[i],
      allMeasurementsWithChartKeys[i + NUMBER_OF_MONTHS],
      allMeasurementsWithChartKeys[i + 2 * NUMBER_OF_MONTHS]
    )
    plotData.push(mergedObject)
  }

  return plotData
}

function transformApiResponsesToBarChartData(
  locationDetailApiResponses,
  chartKeys
) {
  const rawData = [...locationDetailApiResponses]

  const chartKeysAsObject = chartKeys.reduce((acc, val) => {
    acc[val] = null
    return acc
  }, {})

  const xAxisTicks = getXAxisTicksForDayComparison(
    I18n.strftime(
      dayjs()
        .isoWeekday(6)
        .toDate(),
      '%A'
    )
  )

  const stackedBarChartData = []

  //Find the month that had the most occurences of a specified day instead - so that
  // we can account for comparisons of months that have an unequal number of these occurences
  // e.g. in every given month, we could have either 4 or 5 occurences of "saturday"
  // and that should be accounted for in the comparison.
  const responseWithMostOccurencesOfTheSelectedDay = rawData.reduce(function(
    prev,
    current
  ) {
    return prev.measurements.length > current.measurements.length
      ? prev
      : current
  })

  // Map over all measurements
  for (
    let i = 0;
    i < responseWithMostOccurencesOfTheSelectedDay.measurements.length;
    i++
  ) {
    // Create one object in results for every measurement that

    // has all chartKeys filled
    const result = {
      ...responseWithMostOccurencesOfTheSelectedDay.measurements[i],
      ...chartKeysAsObject
    }

    chartKeys.map((chartKey, chartKeyIndex) => {
      if (!rawData[chartKeyIndex] || !rawData[chartKeyIndex].measurements[i]) {
        return
      }

      result[chartKey] = rawData[chartKeyIndex].measurements[i].pedestriansCount

      result['tickLabel'] = xAxisTicks[i]
    })

    stackedBarChartData.push(result)
  }

  return stackedBarChartData
}

function filterMeasurementsForDay(measurements, dayIndex) {
  const onlyMeasurementsForDay = measurements.filter(measurement => {
    return dayjs(measurement.timestamp).day() === dayIndex
  })
  return onlyMeasurementsForDay
}

function getLineChartKeysForLastSixMonths(
  periodHasYearBreak,
  mostRecentYear,
  numberOfYearsToInclude
) {
  let chartKeys = []

  if (periodHasYearBreak) {
    chartKeys = [
      `’${(mostRecentYear - 1)
        .toString()
        .substring(2)} / ’${mostRecentYear.toString().substring(2)}`,
      `’${(mostRecentYear - 2).toString().substring(2)} / ’${(
        mostRecentYear - 1
      )
        .toString()
        .substring(2)}`,
      `’${(mostRecentYear - 3).toString().substring(2)} / ’${(
        mostRecentYear - 2
      )
        .toString()
        .substring(2)}`,
      `’${(mostRecentYear - 4).toString().substring(2)} / ’${(
        mostRecentYear - 3
      )
        .toString()
        .substring(2)}`
    ]
  } else {
    chartKeys = [
      `${mostRecentYear}`,
      `${mostRecentYear - 1}`,
      `${mostRecentYear - 2}`,
      `${mostRecentYear - 3}`
    ]
  }

  return chartKeys.slice(0, numberOfYearsToInclude)
}

function getBarChartKeysForDayComparison(
  mostRecentMeasurement,
  numberOfYearsToInclude
) {
  let thisYear = new Date(mostRecentMeasurement.timestamp).getFullYear()
  const lastYear = (thisYear - 1).toString()
  const twoYearsAgo = (thisYear - 2).toString()
  const threeYearsAgo = (thisYear - 3).toString()
  thisYear = thisYear.toString()

  const lastMonthWasDecember =
    dayjs()
      .add(-1, 'month')
      .month() === 11

  const chartKeys = lastMonthWasDecember
    ? [lastYear, twoYearsAgo, threeYearsAgo]
    : [thisYear, lastYear, twoYearsAgo]

  return chartKeys.slice(0, numberOfYearsToInclude)
}

const getXAxisTicksForDayComparison = dayLabel => {
  const dayLabels = [
    `${I18n.t('count.first')} ${dayLabel}`,
    `${I18n.t('count.second')} ${dayLabel}`,
    `${I18n.t('count.third')} ${dayLabel}`,
    `${I18n.t('count.fourth')} ${dayLabel}`,
    `${I18n.t('count.fifth')} ${dayLabel}`
  ]

  return dayLabels
}

const dataPartiallyExistsInTimerange = (earliestMeasurement, timeRangeEnd) => {
  return earliestMeasurement <= timeRangeEnd
}

const getDayDateHourLabel = (from, to) => {
  return `${I18n.l(
    'date.formats.weekday_date',
    new Date(from)
  )} ${I18n.helpers.hourRange(from, to)}`
}

const getStartOfReportMonthAsDate = (reportYear, reportMonth) => {
  const RUBY_DATE_TO_JS_DATE_MONTH_OFFSET = 1

  const reportMonthAsDate = new Date(
    reportYear,
    reportMonth - RUBY_DATE_TO_JS_DATE_MONTH_OFFSET
  )

  return reportMonthAsDate
}

function Report({
  id,
  reportYear,
  reportMonth,
  totalPedestrians,
  monthlyLocationCount,
  placementInMonthlyRanking,
  reportFrequency = 'monthly',
  saturdayFactor,
  topHours,
  topDays,
  monthlyRankingDifference
}) {
  //#region state
  const [lineChartData, setLineChartData] = useState([])
  const [lineChartKeys, setLineChartKeys] = useState([])
  const [barChartData, setBarChartData] = useState([])
  const [barChartKeys, setBarChartKeys] = useState([])

  const [reportMonthAsDate, setReportMonthAsDate] = useState(
    getStartOfReportMonthAsDate(reportYear, reportMonth)
  )
  const [reportTimeRanges, setReportTimeRanges] = useState(
    locationAPI.getReportTimeRanges(reportMonthAsDate)
  )
  //#endregion

  //#region requests
  const {
    data: reportStatistics,
    status: reportStatisticsRequestStatus
  } = useReport(id, reportMonthAsDate)

  const {
    lastMonthData,
    twoMonthsAgoData,
    threeMonthsAgoData,
    lastMonthAYearAgoData,
    lastMonthTwoYearsAgoData,
    lastSixMonthsData,
    lastSixMonthsAYearAgoData,
    lastSixMonthsTwoYearsAgoData
  } = reportStatistics || {}
  //#endregion

  //#region effects
  useEffect(() => {
    if (!reportYear || !reportMonth) {
      return
    }

    const reportMonthAsDate = getStartOfReportMonthAsDate(
      reportYear,
      reportMonth
    )

    const reportTimeRanges = locationAPI.getReportTimeRanges(
      reportMonthAsDate.toISOString()
    )

    setReportMonthAsDate(reportMonthAsDate)
    setReportTimeRanges(reportTimeRanges)
  }, [reportYear, reportMonth])

  useEffect(() => {
    if (
      !lastMonthData ||
      !lastSixMonthsData ||
      !lastSixMonthsAYearAgoData ||
      !lastSixMonthsTwoYearsAgoData ||
      !reportTimeRanges
    ) {
      return
    }

    const earliestMeasurement = new Date(
      lastSixMonthsData.metadata.earliestMeasurementAt
    )

    const rawData = []

    dataPartiallyExistsInTimerange(
      earliestMeasurement,
      reportTimeRanges.sixMonthTimeRange.endDate.toDate()
    ) && rawData.push(lastSixMonthsData)

    dataPartiallyExistsInTimerange(
      earliestMeasurement,
      reportTimeRanges.sixMonthTimeRangeAYearAgo.endDate.toDate()
    ) && rawData.push(lastSixMonthsAYearAgoData)

    dataPartiallyExistsInTimerange(
      earliestMeasurement,
      reportTimeRanges.sixMonthTimeRangeTwoYearsAgo.endDate.toDate()
    ) && rawData.push(lastSixMonthsTwoYearsAgoData)

    const mostRecentMeasurement =
      lastSixMonthsData.measurements[lastSixMonthsData.measurements.length - 1]

    const lastSixMonthsSpanAcrossYearBreak = measurementPeriodSpansAcrossYearBreak(
      lastSixMonthsData.measurements
    )

    const chartKeys = getLineChartKeysForLastSixMonths(
      lastSixMonthsSpanAcrossYearBreak,
      new Date(mostRecentMeasurement.timestamp).getFullYear(),
      rawData.length
    )

    const data = transformApiResponsesToLineChartData(
      rawData.reverse(),
      chartKeys.reverse()
    )

    setLineChartData(data)
    setLineChartKeys(chartKeys)
  }, [
    lastMonthData,
    lastSixMonthsAYearAgoData,
    lastSixMonthsData,
    lastSixMonthsTwoYearsAgoData,
    reportTimeRanges
  ])

  useEffect(() => {
    if (
      !lastMonthData ||
      !lastMonthAYearAgoData ||
      !lastMonthTwoYearsAgoData ||
      !reportTimeRanges
    ) {
      return
    }

    let lastMonthDataCopy = Object.assign({}, lastMonthData)
    let lastMonthAYearAgoDataCopy = Object.assign({}, lastMonthAYearAgoData)
    let lastMonthTwoYearsAgoDataCopy = Object.assign(
      {},
      lastMonthTwoYearsAgoData
    )

    const earliestMeasurement = new Date(
      lastMonthDataCopy.metadata.earliestMeasurementAt
    )

    const rawBarChartData = []
    if (
      dataPartiallyExistsInTimerange(
        earliestMeasurement,
        reportTimeRanges.lastMonthTimeRange.endDate.toDate()
      )
    ) {
      lastMonthDataCopy.measurements = filterMeasurementsForDay(
        lastMonthDataCopy.measurements,
        6
      )
      rawBarChartData.push(lastMonthDataCopy)
    }

    if (
      dataPartiallyExistsInTimerange(
        earliestMeasurement,
        reportTimeRanges.lastMonthAYearAgoTimeRange.endDate.toDate()
      )
    ) {
      lastMonthAYearAgoDataCopy.measurements = filterMeasurementsForDay(
        lastMonthAYearAgoDataCopy.measurements,
        6
      )
      rawBarChartData.push(lastMonthAYearAgoDataCopy)
    }

    if (
      dataPartiallyExistsInTimerange(
        earliestMeasurement,
        reportTimeRanges.lastMonthTwoYearsAgoTimeRange.endDate.toDate()
      )
    ) {
      lastMonthTwoYearsAgoDataCopy.measurements = filterMeasurementsForDay(
        lastMonthTwoYearsAgoDataCopy.measurements,
        6
      )
      rawBarChartData.push(lastMonthTwoYearsAgoDataCopy)
    }

    const mostRecentMeasurement =
      lastMonthDataCopy.measurements[lastMonthDataCopy.measurements.length - 1]

    const chartKeys = getBarChartKeysForDayComparison(
      mostRecentMeasurement,
      rawBarChartData.length
    )

    const aggregatedBarChartData = transformApiResponsesToBarChartData(
      rawBarChartData.reverse(),
      chartKeys.reverse()
    )

    setBarChartKeys(chartKeys)
    setBarChartData(aggregatedBarChartData)
  }, [
    lastMonthAYearAgoData,
    lastMonthData,
    lastMonthTwoYearsAgoData,
    reportTimeRanges
  ])

  const isLoading =
    reportStatisticsRequestStatus !== 'success' ||
    !lastMonthData ||
    !lastMonthAYearAgoData ||
    !lastMonthTwoYearsAgoData ||
    !lastSixMonthsData ||
    !lastSixMonthsAYearAgoData ||
    !lastSixMonthsTwoYearsAgoData

  return (
    <Section
      css={{
        minHeight: '100%',
        '@media print': { backgroundColor: 'white' }
      }}
      mid
    >
      <Helmet>
        <title>
          {I18n.t('location.title', {
            street: lastMonthData && lastMonthData.name,
            city: lastMonthData && lastMonthData.city
          })}
        </title>
      </Helmet>

      {isLoading && (
        <LoadingWrapper>
          <LoadingIndicator active />
        </LoadingWrapper>
      )}

      {!isLoading && (
        <>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              maxWidth: 'calc(210mm + 6rem)',
              marginTop: '2rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              '@media print': { display: 'none' }
            }}
          >
            <Button.Link onClick={() => window.print()}>
              {I18n.t('print_button_text')}
            </Button.Link>
          </div>

          <A4Page shy>
            <ReportWrapper>
              <ReportSection
                css={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Headline>
                  {lastMonthData.city} / {lastMonthData.name}
                  <small>
                    {`${
                      reportFrequency === 'monthly'
                        ? I18n.t('location.reports.monthly_report')
                        : I18n.t('location.reports.weekly_report')
                    } -
                    ${I18n.strftime(reportMonthAsDate, '%B')}
                    ${reportMonthAsDate.getFullYear()}
                  `}
                  </small>
                </Headline>

                <Logo height={36} />
              </ReportSection>

              <ReportSection
                css={{ paddingBottom: '1rem', paddingTop: '1rem' }}
                withBackdrop
              >
                <Grid columns={3}>
                  <StyledItem height={2} width={1}>
                    <RankingCardWrapper>
                      <Typography
                        tag="p"
                        styleOverrides={{
                          fontWeight: 600,
                          textTransform: 'uppercase',
                          paddingTop: '1rem',
                          paddingRight: '0.5rem',
                          whiteSpace: 'pre-line'
                        }}
                      >
                        <Trans id="location.reports.total_pedestrians"></Trans>
                      </Typography>
                      <Typography tag="h1" bigger>
                        {I18n.toNumber(totalPedestrians, { precision: 0 })}
                      </Typography>

                      <RankingBottomLineWrapper>
                        <Typography
                          tag="p"
                          small
                          styleOverrides={{
                            whiteSpace: 'pre',
                            fontWeight: 600
                          }}
                        >
                          {placementInMonthlyRanking
                            ? I18n.t('location.reports.ranking', {
                                placementInRanking: placementInMonthlyRanking,
                                totalParticipantsInRanking: monthlyLocationCount
                              })
                            : I18n.t(
                                'location.reports.no_ranking_placement_yet'
                              )}
                          {'* '}
                        </Typography>

                        <RankingTrendIcon
                          css={{ paddingRight: '0.5rem' }}
                          difference={monthlyRankingDifference}
                        />

                        <Typography
                          styleOverrides={{
                            fontSize: theme.fontSizes.xxs.fontSize * 0.75,
                            lineHeight: theme.fontSizes.xxs.lineHeight,
                            letterSpacing: '-0.1785px',
                            textDecoration: 'underline'
                          }}
                        >
                          {' '}
                          |
                        </Typography>

                        {saturdayFactor !== null && (
                          <Typography
                            tag="p"
                            small
                            styleOverrides={{
                              whiteSpace: 'pre',
                              fontWeight: 600,
                              paddingRight: '0.5rem',
                              paddingLeft: '0.5rem'
                            }}
                          >
                            {` ${I18n.t(
                              'location.reports.saturday_factor'
                            )} ${I18n.toNumber(saturdayFactor, {
                              precision: 0
                            })}%**`}{' '}
                          </Typography>
                        )}
                      </RankingBottomLineWrapper>
                    </RankingCardWrapper>
                  </StyledItem>

                  <StyledItem height={1} width={1}>
                    <ComparisonCard
                      hideMeasuredSince={true}
                      hideAbsoluteCountDifference={true}
                      fullWidth={false}
                      hideTopBorder
                      pedestrianCount={
                        twoMonthsAgoData.statistics.timerangeCount
                      }
                      timestamp={`${I18n.strftime(
                        reportTimeRanges.twoMonthsAgoTimeRange.startDate.toDate(),
                        '%B'
                      )} ${dayjs(
                        reportTimeRanges.twoMonthsAgoTimeRange.startDate
                      ).year()}`}
                      pedestrianCountDifferenceInAbsoluteNumbers={getAbsoluteDifference(
                        twoMonthsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      pedestrianCountDifferenceInPercentages={getDifferenceAsPercentageOrMissingLabel(
                        twoMonthsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      incidents={twoMonthsAgoData.incidents}
                      measurementsStartedAt={
                        twoMonthsAgoData.metadata.earliestMeasurementAt
                      }
                      displayDataIncompleteNotice={
                        !isDataPresentForTimeRange(
                          twoMonthsAgoData,
                          reportTimeRanges.twoMonthsAgoTimeRange.startDate.toDate(),
                          reportTimeRanges.twoMonthsAgoTimeRange.endDate.toDate()
                        )
                      }
                    />
                  </StyledItem>

                  <StyledItem height={1} width={1}>
                    <ComparisonCard
                      hideMeasuredSince={true}
                      hideAbsoluteCountDifference={true}
                      fullWidth={false}
                      hideTopBorder
                      pedestrianCount={
                        lastMonthAYearAgoData.statistics.timerangeCount
                      }
                      timestamp={`${I18n.t(
                        'location.details.same_month_in_year'
                      )} ${reportTimeRanges.lastMonthAYearAgoTimeRange.startDate.year()}`}
                      pedestrianCountDifferenceInAbsoluteNumbers={getAbsoluteDifference(
                        lastMonthAYearAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      pedestrianCountDifferenceInPercentages={getDifferenceAsPercentageOrMissingLabel(
                        lastMonthAYearAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      incidents={lastMonthAYearAgoData.incidents}
                      measurementsStartedAt={
                        lastMonthAYearAgoData.metadata.earliestMeasurementAt
                      }
                      displayDataIncompleteNotice={
                        !isDataPresentForTimeRange(
                          lastMonthAYearAgoData,
                          reportTimeRanges.lastMonthAYearAgoTimeRange.startDate.toDate(),
                          reportTimeRanges.lastMonthAYearAgoTimeRange.endDate.toDate()
                        )
                      }
                    />
                  </StyledItem>

                  <StyledItem height={1} width={1}>
                    <ComparisonCard
                      hideMeasuredSince={true}
                      hideAbsoluteCountDifference={true}
                      fullWidth={false}
                      hideTopBorder
                      pedestrianCount={
                        threeMonthsAgoData.statistics.timerangeCount
                      }
                      timestamp={`${I18n.strftime(
                        reportTimeRanges.threeMonthsAgoTimeRange.startDate.toDate(),
                        '%B'
                      )} ${dayjs(
                        reportTimeRanges.threeMonthsAgoTimeRange.startDate
                      ).year()}`}
                      pedestrianCountDifferenceInAbsoluteNumbers={getAbsoluteDifference(
                        threeMonthsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      pedestrianCountDifferenceInPercentages={getDifferenceAsPercentageOrMissingLabel(
                        threeMonthsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      incidents={threeMonthsAgoData.incidents}
                      measurementsStartedAt={
                        threeMonthsAgoData.metadata.earliestMeasurementAt
                      }
                      displayDataIncompleteNotice={
                        !isDataPresentForTimeRange(
                          threeMonthsAgoData,
                          reportTimeRanges.threeMonthsAgoTimeRange.startDate.toDate(),
                          reportTimeRanges.threeMonthsAgoTimeRange.endDate.toDate()
                        )
                      }
                    />
                  </StyledItem>

                  <StyledItem height={1} width={1}>
                    <ComparisonCard
                      hideMeasuredSince={true}
                      hideAbsoluteCountDifference={true}
                      fullWidth={false}
                      hideTopBorder
                      pedestrianCount={
                        lastMonthTwoYearsAgoData.statistics.timerangeCount
                      }
                      timestamp={`${I18n.t(
                        'location.details.same_month_in_year'
                      )} ${reportTimeRanges.lastMonthTwoYearsAgoTimeRange.startDate.year()}`}
                      pedestrianCountDifferenceInAbsoluteNumbers={getAbsoluteDifference(
                        lastMonthTwoYearsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      pedestrianCountDifferenceInPercentages={getDifferenceAsPercentageOrMissingLabel(
                        lastMonthTwoYearsAgoData.statistics.timerangeCount,
                        totalPedestrians
                      )}
                      incidents={lastMonthTwoYearsAgoData.incidents}
                      measurementsStartedAt={
                        lastMonthTwoYearsAgoData.metadata.earliestMeasurementAt
                      }
                      displayDataIncompleteNotice={
                        !isDataPresentForTimeRange(
                          lastMonthTwoYearsAgoData,
                          reportTimeRanges.lastMonthTwoYearsAgoTimeRange
                            .startDate,
                          reportTimeRanges.lastMonthTwoYearsAgoTimeRange.endDate
                        )
                      }
                    />
                  </StyledItem>
                  <Item
                    height={1}
                    width={3}
                    css={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography tag="p" small>
                      {I18n.t('location.reports.ranking_explanation')} |
                      {I18n.t('location.reports.saturday_factor_explanation')}
                    </Typography>

                    <Typography
                      tag="p"
                      small
                      styleOverrides={{ paddingRight: '1rem' }}
                    >
                      {I18n.t('location.reports.location_measured_since', {
                        measuredSinceDate: I18n.l(
                          'date.formats.long',
                          new Date(lastMonthData.metadata.earliestMeasurementAt)
                        )
                      })}{' '}
                    </Typography>
                  </Item>
                </Grid>
              </ReportSection>

              <ReportSection>
                <Headline level={3} breakout css={{ paddingTop: '1rem' }}>
                  {I18n.t('location.reports.six_month_trend')}
                </Headline>
                <LineChartWrapper>
                  <LineGraph
                    aspectRatio={3}
                    plot={lineChartData}
                    isLoading={!lineChartData}
                    chartType="line"
                    stackedChartKeys={lineChartKeys}
                    displayLegend={true}
                    displayTooltip={false}
                    displayLineEndingLabel={false}
                    //lineEndingLabels={lineChartKeys}
                  />
                </LineChartWrapper>
              </ReportSection>

              <TopListSection withBackdrop>
                <TopTimerangesListWrapper>
                  <Headline
                    level={3}
                    breakout
                    css={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}
                  >
                    {`${I18n.t('location.reports.list_of_top_four_days')} `}
                  </Headline>

                  <TopTimerangesList css={{ paddingRight: '1rem' }}>
                    {topDays.map((topDay, index) => {
                      const percentage = calculatePercentage(
                        topDay.pedestriansCount,
                        topDays[0].pedestriansCount
                      )

                      return (
                        <TopTimerangesListItem key={index}>
                          <Typography
                            tag="p"
                            styleOverrides={{
                              whiteSpace: 'nowrap',
                              paddingRight: '0.5rem',
                              minWidth: '5rem'
                            }}
                          >
                            {I18n.l(
                              'date.formats.weekday_and_date',
                              new Date(topDay.timestamp)
                            )}
                          </Typography>

                          <StripChartWrapper>
                            <StripChart
                              percentage={percentage}
                              css={{
                                backgroundColor: theme.chroma.white.hex(),
                                maxWidth: '180px'
                              }}
                              variant="bar"
                              chartHeight={'14px'}
                              chartColor={theme.chroma.KPICharts[0].fill.hex()}
                            ></StripChart>
                          </StripChartWrapper>
                          <Typography
                            tag="p"
                            styleOverrides={{
                              fontWeight: 700,
                              paddingRight: '1rem',
                              paddingLeft: '1rem'
                            }}
                          >
                            {I18n.toNumber(topDay.pedestriansCount, {
                              precision: 0
                            })}
                          </Typography>
                        </TopTimerangesListItem>
                      )
                    })}
                  </TopTimerangesList>
                </TopTimerangesListWrapper>

                <TopTimerangesListWrapper>
                  <Headline
                    level={3}
                    breakout
                    css={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}
                  >
                    {`${I18n.t('location.reports.list_of_top_four_hours')} `}
                  </Headline>

                  <TopTimerangesList>
                    {topHours.map((topHour, index) => {
                      const percentage = calculatePercentage(
                        topHour.pedestriansCount,
                        topHours[0].pedestriansCount
                      )

                      return (
                        <TopTimerangesListItem key={index}>
                          <Typography
                            tag="p"
                            styleOverrides={{
                              whiteSpace: 'nowrap',
                              paddingLeft: '1rem',
                              paddingRight: '1rem',
                              minWidth: '12rem'
                            }}
                          >
                            {getDayDateHourLabel(
                              dayjs(topHour.timestamp)
                                .add(-2, 'hour')
                                .toDate(),
                              dayjs(topHour.timestamp)
                                .add(-1, 'hour')
                                .toDate()
                            )}
                          </Typography>
                          <StripChartWrapper>
                            <StripChart
                              percentage={percentage}
                              css={{
                                backgroundColor: theme.chroma.white.hex()
                              }}
                              variant="bar"
                              chartHeight={'14px'}
                              chartColor={theme.chroma.KPICharts[0].fill.hex()}
                            ></StripChart>
                          </StripChartWrapper>
                          <Typography
                            tag="p"
                            styleOverrides={{
                              fontWeight: 700,
                              paddingLeft: '1rem',
                              minWidth: '3rem'
                            }}
                          >
                            {I18n.toNumber(topHour.pedestriansCount, {
                              precision: 0
                            })}
                          </Typography>
                        </TopTimerangesListItem>
                      )
                    })}
                  </TopTimerangesList>
                </TopTimerangesListWrapper>
              </TopListSection>

              <ReportSection>
                <Headline
                  level={3}
                  breakout
                  css={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}
                >
                  {`${I18n.t(
                    'location.reports.comparison_of_saturdays_last_month'
                  )} (${I18n.strftime(reportMonthAsDate, '%B')})`}
                </Headline>
                <ContentWrapper>
                  <Graph
                    plot={barChartData}
                    isLoading={!barChartData}
                    stackedChartKeys={barChartKeys}
                    chartType="multiBar"
                    viewType=""
                    aspectRatio={3}
                    displayLegend={true}
                    displayTooltip={false}
                    xAxis={
                      <XAxis interval={0} dataKey="tickLabel" tick={true} />
                    }
                  />
                </ContentWrapper>
              </ReportSection>

              <ReportPrintFooter>{I18n.t('common.tagline')}</ReportPrintFooter>
            </ReportWrapper>
          </A4Page>
        </>
      )}
    </Section>
  )
}

Report.propTypes = {
  id: PropTypes.number.isRequired,
  map: PropTypes.object,
  reportYear: PropTypes.number,
  reportMonth: PropTypes.number,
  totalPedestrians: PropTypes.number.isRequired,
  originalUrl: PropTypes.string.isRequired,
  monthlyLocationCount: PropTypes.number.isRequired,
  placementInMonthlyRanking: PropTypes.number,
  placementInPreviousMonthlyRanking: PropTypes.number,
  reportFrequency: PropTypes.oneOf(['monthly', 'weekly']),
  saturdayFactor: PropTypes.number.isRequired,
  topHours: PropTypes.array.isRequired,
  topDays: PropTypes.array.isRequired,
  monthlyRankingDifference: PropTypes.number.isRequired
}

const A4Page = styled(Card)`
  box-sizing: content-box;
  width: calc(210mm + 6rem);
  height: 297mm;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;

  padding-top: 3rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background: white;
  overflow: hidden;
  page-break-after: always;
  page-break-inside: avoid;

  @media print {
    margin: 0;
    padding: 0;
    padding-top: 3rem;
  }
`

const ReportWrapper = styled(Section.Wrapper)`
  position: relative;
  align-items: stretch;
  padding: 0;
  min-height: 100%;

  ${p => p.theme.breakpoints.tablet} {
    padding: 1rem 0.375rem 0.75rem 0.375rem;

    /* TODO: Though it works with 100vh on mobile browsers, it's not 100% what
     * we want since it doesn't respect the url bar. It works, but
     * could be better. */
    min-height: calc(100vh - 4.4375rem);
  }
`

const ReportSection = styled.div`
  padding: 0rem 3rem;
  background-color: ${p =>
    p.withBackdrop ? p.theme.chroma.nearlyWhite.hex() : ''};
`

const ContentWrapper = styled.div``

const LineChartWrapper = styled.div`
  height: 180px;
  padding-bottom: 3.5rem;

  @media print {
    height: 220px;
    padding-bottom: 1rem;
  }
`

const ReportPrintFooter = styled.div`
  position: relative;
  display: block;
  margin-top: -1.8rem;
  text-align: center;
  font-size: 0.8rem;
  z-index: 1000;

  @media print {
  }
`

const RankingCardWrapper = styled.div`
  padding: 0;
  height: 100%;
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  justify-content: space-between;
`

const RankingBottomLineWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5rem;
`

const ComparisonCard = styled(PedestrianCountComparisonStatsCardItem)``

const StyledItem = styled(Item)`
  background-color: ${p => p.theme.chroma.white.hex()};
`

const TopListSection = styled.div`
  background-color: ${p =>
    p.withBackdrop ? p.theme.chroma.nearlyWhite.hex() : ''};
  padding: 0rem 3rem 0rem 3rem;
  display: flex;

  @media print {
    min-height: 180px;
  }
`

const TopTimerangesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const TopTimerangesList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 160px;
`

const TopTimerangesListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
  align-items: center;
  max-height: 30px;
  width: 100%;
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const StripChartWrapper = styled.div`
  width: 150px;
  flex: 1;
`

export default Report
