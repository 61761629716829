import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function RadioButton({ id, name, label, ...nativeProps }) {
  return (
    <Container {...nativeProps}>
      <input type="radio" id={id} name={name} {...nativeProps} />
      <span>{label}</span>
    </Container>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default RadioButton

const Container = styled.label`
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  cursor: pointer;
  input {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 1em;
    height: 1em;
    border: 1px solid ${props => props.theme.chroma.pencil.css()};
    border-radius: 50%;
    display: grid;
    place-content: center;
    transform: translateY(1px);
    & + span {
      color: ${props => props.theme.chroma.pencil.css()};
    }
    &::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em ${props => props.theme.chroma.base.css()};
    }
    &:checked::before {
      transform: scale(1);
    }
    &:checked {
      border-color: ${props => props.theme.chroma.base.css()};
    }
    &:checked + span {
      color: ${props => props.theme.chroma.base.css()};
    }
    &[disabled] {
      cursor: default;
    }
  }
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
`
