import React from 'react'
import styled from '@emotion/styled'

import I18n from '../i18n'
import * as Icon from '../components/Icon'

export default function PartnerNowCta() {
  return (
    <a href="/partner">
      <Icon.PartnerNow width={130} height={130} />
      <ScreenReaderOnlyText>
        {I18n.t('navigation.partner')}
      </ScreenReaderOnlyText>
    </a>
  )
}

const ScreenReaderOnlyText = styled.span`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`
