import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import head from 'lodash/head'

import I18n from '../../i18n'

import useMapZoom from '../../lib/use-map-zoom'

import ListHeadline from './ListHeadline'
import { FAV_TYPE } from './LocationPicker'
import LocationLabel from './LocationLabel'
import LocationLabelList from './LocationLabelList'

function ListAll({
  groupedLocations,
  favLocationIds,
  onFavChange,
  onSelect,
  disabledLocationIds = [],
  favable = true,
  zoomable = true,
  locationIdsWithGeoJson
}) {
  const { _mapZoom, setMapZoom } = useMapZoom()

  const handleFavLocationClick = locationId => _event => {
    const type = favLocationIds.includes(locationId)
      ? FAV_TYPE.REMOVE
      : FAV_TYPE.ADD
    onFavChange([locationId], { type })
  }

  const handleFavCityClick = locations => _event => {
    const locationIds = locations.map(l => l.id)
    const type = locationIds.every(id => favLocationIds.includes(id))
      ? FAV_TYPE.REMOVE
      : FAV_TYPE.ADD
    onFavChange(locationIds, { type })
  }

  const handleMainClick = locationId => _event => {
    onSelect(locationId)
  }

  const handleZoomableLocationClick = locationId => _event => {
    setMapZoom(locationId)
  }

  return groupedLocations.map(([country, cities]) => (
    <React.Fragment key={country}>
      <ListHeadline id={country}>{I18n.t('country')[country]}</ListHeadline>
      <ListCard>
        <List>
          {cities.map(([city, cityLocations]) => (
            <React.Fragment key={city}>
              <CityContainer>
                <LocationLabel
                  thin
                  title={city}
                  highlighted
                  favorite={cityLocations.every(l =>
                    favLocationIds.includes(l.id)
                  )}
                  favable={favable}
                  onFavClick={handleFavCityClick(cityLocations)}
                  population={get(head(cityLocations), 'population')}
                  state={get(head(cityLocations), 'state')}
                />
              </CityContainer>
              <StreetsContainer>
                <LocationLabelList
                  locations={cityLocations}
                  renderItem={location => (
                    <LocationLabel
                      thin
                      margin
                      title={location.name}
                      href={onSelect ? undefined : '/locations/' + location.id}
                      favorite={favLocationIds.includes(location.id)}
                      favable={favable}
                      disabled={disabledLocationIds.includes(location.id)}
                      onFavClick={handleFavLocationClick(location.id)}
                      onMainClick={
                        onSelect ? handleMainClick(location) : undefined
                      }
                      zoomable={zoomable}
                      zoomableLocation={locationIdsWithGeoJson.includes(
                        location.id
                      )}
                      onZoomableLocationClick={handleZoomableLocationClick(
                        location.id
                      )}
                    />
                  )}
                />
              </StreetsContainer>
            </React.Fragment>
          ))}
        </List>
      </ListCard>
    </React.Fragment>
  ))
}

ListAll.propTypes = {
  groupedLocations: PropTypes.array.isRequired,
  favLocationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabledLocationIds: PropTypes.arrayOf(PropTypes.number),
  onFavChange: PropTypes.func,
  onSelect: PropTypes.func,
  favable: PropTypes.bool
}

const ListCard = styled.div`
  overflow: hidden;
  padding: 0;
`

const List = styled.dl`
  background-color: ${p => p.theme.chroma.white.css()};
`

const CityContainer = styled.dt`
  background-color: ${p => p.theme.chroma.white.css()};

  ${p => p.theme.breakpoints.phone} {
    &:not(:first-of-type) {
      border-top: 1px solid ${p => p.theme.chroma.nearlyWhite.css()};
    }
  }
`

const StreetsContainer = styled.dd`
  margin-bottom: 1.25rem;
`

export default ListAll
