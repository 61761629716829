import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function CompareButton({ label, icon, compareType, onClick, isActive, ...passThroughProps }) {
  return (
    <div
      css={{
        display: 'block',
        height: '4.1rem',
        overflow: 'visible'
      }}
      {...passThroughProps}>
      <ButtonWrapper>
        <div css={{ display: 'flex', height: '2.85rem', flexShrink: 0 }}>
          <button
            css={{
              display: 'flex',
              flex: '1 0 auto',
              textAlign: 'left',
              alignItems: 'center',
              opacity: isActive ? 1 : 0.5,
              filter: !isActive && 'grayscale(90%)',
              transition: 'all 120ms',
              cursor: 'pointer'
            }}
            onClick={() => {
              onClick(compareType)
            }}
          >
            <div
              css={{
                width: '3.25rem',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {icon}
            </div>
            {label && (
              <div css={{ paddingRight: '0.5rem' }}>
                <Label>{label}</Label>
              </div>
            )}
          </button>
        </div>
      </ButtonWrapper>
    </div>
  )
}

CompareButton.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.element.isRequired,
  compareType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  svg: {
    display: block;
  }
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.125rem;
  border-color: ${p => p.theme.chroma.white.css()};
  background-color: ${p => p.theme.chroma.dustyBlue.css()};
  max-width: fit-content;
`

const Label = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${p => p.theme.chroma.base.css()};
  max-width: 11.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default CompareButton
