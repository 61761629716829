import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import OutsideClickHandler from 'react-outside-click-handler'

import CloseButton from '../CloseButton'

function DetailsInfoBox({ onClose, children }) {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Box>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.25rem',
            paddingBottom: 0
          }}
        >
          <CloseButton onClick={onClose} />
        </div>
        <ContentWrapper>{children}</ContentWrapper>
      </Box>
    </OutsideClickHandler>
  )
}

DetailsInfoBox.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

const Box = styled.div(props => ({
  backgroundColor: props.theme.chroma.nearlyWhite.css(),
  borderColor: props.theme.chroma.lightGrey.css(),
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: '1px 1px 6px 2px ' + props.theme.chroma.lightGrey.css(),
  width: '17.5rem'
}))

const ContentWrapper = styled.div({
  paddingLeft: '1.25rem',
  paddingRight: '1.25rem',
  paddingBottom: '1.25rem',

  p: {
    fontSize: '0.875rem',
    lineHeight: 1.4
  },

  a: {
    fontWeight: 700,
    textDecoration: 'underline'
  },

  'p:not(:last-of-type)': {
    marginBottom: '1em'
  }
})

export default DetailsInfoBox
