import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import BlogPost from '../components/BlogPost'
import Section from '../components/Section'
import Link from '../components/Link'

function News({ blogPostData }) {
  return (
    <Section.Wrapper>
      <ContentWrapper breakout>
        <BlogPost {...blogPostData} />
      </ContentWrapper>
      <CustomLink
        css={{ alignSelf: 'flex-end', marginTop: '2rem' }}
        href="press/news-archive"
      >
        <Trans id="pages.press.news.archive_link" />
      </CustomLink>
    </Section.Wrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  padding: 2rem;
  background: white;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 0.3);
  ${p => p.theme.breakpoints.tabletUp} {
    margin-left: ${p => (p.breakout ? '-1.25rem' : '0')};
  }
`

const CustomLink = styled(Link)`
  font-weight: 700;
`
News.propTypes = {
  blogPostData: PropTypes.object.isRequired
}

export default News
