import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../theme'
import * as Icon from './Icon'

function RankingTrend({ difference, ...nativeProps }) {
  return (
    <IconWrapper {...nativeProps}>
      {difference === 0 && (
        <Rotated degrees={45}>
          <Icon.ArrowUpards
            color={theme.chroma.grey.hex()}
            width={12}
            height={12}
          />
        </Rotated>
      )}
      {difference < 0 && (
        <Icon.ArrowUpards
          color={theme.chroma.good.hex()}
          width={12}
          height={12}
        />
      )}
      {difference > 0 && (
        <Rotated degrees={90}>
          <Icon.ArrowUpards
            color={theme.chroma.bad.hex()}
            width={12}
            height={12}
          />{' '}
        </Rotated>
      )}
    </IconWrapper>
  )
}

RankingTrend.propTypes = {
  difference: PropTypes.number
}

const Rotated = styled.div`
  display: inline-block;
  transform: ${p => `rotate(${p.degrees}deg)`};
`

const IconWrapper = styled.span``

export default RankingTrend
