import dayjs from 'dayjs'
import I18n from 'i18n-js'
import { arrayOf, number, shape, string } from 'prop-types'

export const measurements = arrayOf(
  shape({
    timestamp: string.isRequired, // ISO8601 Timestamp
    pedestriansCount: number.isRequired
  }).isRequired
)

/**
 * Collects a series of dates used to calculate location statistics
 * @param {string} format - 'JS_DATE' or 'ISO_STRING'
 * @returns {{
 * today,
 * yesterday,
 * startOfYesterday,
 * endOfYesterday,
 * startOfTwoDaysAgo,
 * endOfTwoDaysAgo,
 * startOfThreeDaysAgo,
 * endOfThreeDaysAgo,
 * startOfYesterdayAWeekAgo,
 * endOfYesterdayAWeekAgo,
 * startOfLastWeek,
 * endOfLastWeek,
 * startOfTwoWeeksAgo,
 * endOfTwoWeeksAgo,
 * startOfLastWeekAYearAgo,
 * startOfLastMonth,
 * startOfTwoMonthsAgo,
 * endOfTwoMonthsAgo,
 * todayAYearAgo,
 * todayTwoYearsAgo,
 * todayThreeYearsAgo,
 * yesterdayAYearAgo,
 * yesterdayTwoYearsAgo,
 * yesterdayThreeYearsAgo,
 * startOfThisYear,
 * startOfLastYear,
 * startOfTwoYearsAgo,
 * startOfThreeYearsAgo
 *  }} dates used to calculate location statistics
 */
export const getLocationStatisticsDates = ({ format = 'JS_DATE' }) => {
  const dates = {
    today: dayjs().startOf('day'),
    yesterday: dayjs().add(-1, 'day'),
    startOfYesterday: dayjs()
      .add(-1, 'day')
      .startOf('day'),
    endOfYesterday: dayjs()
      .add(-1, 'day')
      .endOf('day'),
    startOfTwoDaysAgo: dayjs()
      .add(-2, 'day')
      .startOf('day'),
    endOfTwoDaysAgo: dayjs()
      .add(-2, 'day')
      .endOf('day'),
    startOfThreeDaysAgo: dayjs()
      .add(-3, 'day')
      .startOf('day'),
    endOfThreeDaysAgo: dayjs()
      .add(-3, 'day')
      .endOf('day'),
    startOfYesterdayAWeekAgo: dayjs()
      .add(-1, 'day')
      .add(-1, 'week')
      .startOf('day'),
    endOfYesterdayAWeekAgo: dayjs()
      .add(-1, 'day')
      .add(-1, 'week')
      .endOf('day'),
    startOfLastWeek: dayjs()
      .add(-1, 'week')
      .startOf('week'),
    endOfLastWeek: dayjs()
      .add(-1, 'week')
      .endOf('week'),
    startOfTwoWeeksAgo: dayjs()
      .add(-2, 'week')
      .startOf('week'),
    endOfTwoWeeksAgo: dayjs()
      .add(-2, 'week')
      .endOf('week'),
    startOfLastWeekAYearAgo: dayjs()
      .add(-1, 'week')
      .add(-1, 'year')
      .startOf('week'),
    endOfLastWeekAYearAgo: dayjs()
      .add(-1, 'week')
      .add(-1, 'year')
      .endOf('week'),
    startOfLastWeekTwoYearsAgo: dayjs()
      .add(-1, 'week')
      .add(-2, 'year')
      .startOf('week'),
    endOfLastWeekTwoYearsAgo: dayjs()
      .add(-1, 'week')
      .add(-2, 'year')
      .endOf('week'),
    startOfLastMonth: dayjs()
      .add(-1, 'month')
      .startOf('month'),
    endOfLastMonth: dayjs()
      .add(-1, 'month')
      .endOf('month'),
    startOfTwoMonthsAgo: dayjs()
      .add(-2, 'month')
      .startOf('month'),
    endOfTwoMonthsAgo: dayjs()
      .add(-2, 'month')
      .endOf('month'),
    startOfThreeMonthsAgo: dayjs()
      .add(-3, 'month')
      .startOf('month'),
    endOfThreeMonthsAgo: dayjs()
      .add(-3, 'month')
      .endOf('month'),
    startOfLastMonthAYearAgo: dayjs()
      .add(-1, 'month')
      .add(-1, 'year')
      .startOf('month'),
    endOfLastMonthAYearAgo: dayjs()
      .add(-1, 'month')
      .add(-1, 'year')
      .endOf('month'),
    startOfLastMonthTwoYearsAgo: dayjs()
      .add(-1, 'month')
      .add(-2, 'year')
      .startOf('month'),
    endOfLastMonthTwoYearsAgo: dayjs()
      .add(-1, 'month')
      .add(-2, 'year')
      .endOf('month'),
    todayAYearAgo: dayjs().add(-1, 'year'),
    todayTwoYearsAgo: dayjs().add(-2, 'year'),
    todayThreeYearsAgo: dayjs().add(-3, 'year'),
    yesterdayAYearAgo: dayjs()
      .add(-1, 'day')
      .endOf('day')
      .add(-1, 'year'),
    yesterdayTwoYearsAgo: dayjs()
      .add(-1, 'day')
      .endOf('day')
      .add(-2, 'year'),
    yesterdayThreeYearsAgo: dayjs()
      .add(-1, 'day')
      .endOf('day')
      .add(-3, 'year'),
    startOfThisYear: dayjs().startOf('year'),
    startOfLastYear: dayjs()
      .add(-1, 'year')
      .startOf('year'),
    startOfTwoYearsAgo: dayjs()
      .add(-2, 'year')
      .startOf('year'),
    startOfThreeYearsAgo: dayjs()
      .add(-3, 'year')
      .startOf('year')
  }

  if (format === 'ISO_STRING') {
    for (const key in dates) {
      dates[key] = dates[key].toISOString()
    }
  } else {
    for (const key in dates) {
      dates[key] = dates[key].toDate()
    }
  }

  return dates
}

/**
 * LocationStatistics containing aggregated stats
 * @typedef {Object} LocationStatistics
 * @property {string} timerangeStart - timestamp denoting the start of the timerange described in this object
 * @property {string} timerangeEnd - timestamp denoting the end of the timerange described in this object
 * @property {number} pedestriansCount - number of pedestrians
 * @property {number} ltrPedestriansCount - number of pedestrians walking in direction 1
 * @property {number} rtlPedestriansCount - number of pedestrians walking in direction 2
 * @property {number} adultPedestriansCount - number of adult pedestrians
 * @property {number} childPedestriansCount - number of child pedestrians
 * @property {Object[]} zones - number of child pedestrians
 * @property {any} metadata - Indicates whether the Wisdom component is present.
 * @property {Object[]} incidents - Indicates whether a special occasion - like a national holiday - took place within the timerange
 * @property {bool} noDataPresentForAskedTimerange - Indicates whether or not there was any data that could be aggregated for the given timerange.
 * @property {bool} dataIncompleteForAskedTimerange - Indicates wether or not the data that was aggregated was based on a "complete" set, meaning that there were measurements for the complete timerange that was asked for.
 */

/**
 * Calculates the total of pedestrians measured between two timestamps.
 * @param {string} startDate - ISO8601 Timestamp denoting the start of the timerange that shall be included in the total
 * @param {string} endDate - ISO8601 Timestamp denoting the end of the timerange that shall be included in the total
 * @param {boolean} shouldSwapDirections - Flag indicating that the counrts for walking directions should be swapped.
 * @param {locationDetailApiResponse} locationDetailApiResponse
 * @returns {LocationStatistics} - total number of pedestrians - and details about adult-child differentiation, walking direction and zone - measured between the two timestamps.
 */
export function aggregateStatsMeasuredBetweenTimestamps(
  startDate,
  endDate,
  locationDetailApiResponse,
  shouldSwapDirections
) {
  if (!startDate) {
    throw new Error("Argument 'startDate' missing")
  }

  if (!endDate) {
    throw new Error("Argument 'endDate' missing")
  }

  let startDateAsJsDate
  if (typeof startDate !== Date) {
    startDateAsJsDate = new Date(startDate)
  }

  let endDateAsJsDate
  if (typeof endDate !== Date) {
    endDateAsJsDate = new Date(endDate)
  }

  const measurements = locationDetailApiResponse.measurements

  const locationStats = {
    timerangeStart: startDate,
    timerangeEnd: endDate,

    dataIncompleteForAskedTimerange: false,
    noDataPresentForAskedTimerange: false,

    pedestriansCount: 0,
    ltrPedestriansCount: 0,
    rtlPedestriansCount: 0,

    adultPedestriansCount: 0,
    adultLtrPedestriansCount: 0,
    adultRtlPedestriansCount: 0,

    childPedestriansCount: 0,
    childLtrPedestriansCount: 0,
    childRtlPedestriansCount: 0,

    measurementsTakenIntoAccount: [],
    zones: [],
    metadata: locationDetailApiResponse.metadata || {},
    incidents: locationDetailApiResponse.incidents || []
  }

  if (!measurements || measurements.length === 0) {
    return locationStats
  }

  // If the startdate to aggregate is earlier than the earliest measurement, we have to signal that the data is incomplete for the asked timerange.
  if (startDate <= locationDetailApiResponse.metadata.earliestMeasurementAt) {
    locationStats.dataIncompleteForAskedTimerange = true
  }

  const dataIsPresentForTimeRange = isDataPresentForTimeRange(
    locationDetailApiResponse,
    startDate,
    endDate
  )

  // If there is no overlap between the asked dates and our api response, set a "no data present" flag.
  if (!dataIsPresentForTimeRange) {
    locationStats.noDataPresentForAskedTimerange = true
  }

  // Only pass on incidents that happened between startDate and endDate
  locationStats.incidents = locationDetailApiResponse.incidents.filter(
    incident => {
      return incident.activeFrom >= startDate && !incident.activeFrom >= endDate
    }
  )

  measurements.map(currentMeasurement => {
    const currentMeasurementTimestampAsJSDate = new Date(
      currentMeasurement.timestamp
    )

    if (
      currentMeasurementTimestampAsJSDate >= startDateAsJsDate &&
      currentMeasurementTimestampAsJSDate <= endDateAsJsDate
    ) {
      locationStats.measurementsTakenIntoAccount.push(currentMeasurement)
      locationStats.pedestriansCount += currentMeasurement.pedestriansCount || 0
      locationStats.ltrPedestriansCount +=
        currentMeasurement?.details?.ltrPedestriansCount || 0
      locationStats.rtlPedestriansCount +=
        currentMeasurement?.details?.rtlPedestriansCount || 0

      locationStats.adultPedestriansCount +=
        currentMeasurement?.details?.adultPedestriansCount || 0
      locationStats.adultLtrPedestriansCount +=
        currentMeasurement?.details?.adultLtrPedestriansCount || 0
      locationStats.adultRtlPedestriansCount +=
        currentMeasurement?.details?.adultRtlPedestriansCount || 0

      locationStats.childPedestriansCount +=
        currentMeasurement?.details?.childPedestriansCount || 0
      locationStats.childLtrPedestriansCount +=
        currentMeasurement?.details?.childLtrPedestriansCount || 0
      locationStats.childRtlPedestriansCount +=
        currentMeasurement?.details?.childRtlPedestriansCount || 0

      if (
        !currentMeasurement.details ||
        !currentMeasurement.details.zones ||
        currentMeasurement.details.zones.length === 0
      ) {
        return
      }

      // Iterate over all zones and sum them in locationStats.zones
      currentMeasurement.details.zones.map(
        (currentMeasurementZone, zoneIndex) => {
          if (!locationStats.zones[zoneIndex]) {
            locationStats.zones[zoneIndex] = {
              pedestriansCount: 0,
              ltrPedestriansCount: 0,
              rtlPedestriansCount: 0
            }
          }

          locationStats.zones[zoneIndex].pedestriansCount +=
            currentMeasurementZone.pedestriansCount
          locationStats.zones[zoneIndex].ltrPedestriansCount +=
            currentMeasurementZone.ltrPedestriansCount
          locationStats.zones[zoneIndex].rtlPedestriansCount +=
            currentMeasurementZone.rtlPedestriansCount
        }
      )
    }
  })

  if (shouldSwapDirections) {
    let locationStatsCopy = Object.assign({}, locationStats)

    locationStats.rtlPedestriansCount = locationStatsCopy.ltrPedestriansCount
    locationStats.ltrPedestriansCount = locationStatsCopy.rtlPedestriansCount

    locationStats.adultRtlPedestriansCount =
      locationStatsCopy.adultLtrPedestriansCount
    locationStats.adultLtrPedestriansCount =
      locationStatsCopy.adultRtlPedestriansCount

    locationStats.childRtlPedestriansCount =
      locationStatsCopy.childLtrPedestriansCount
    locationStats.childLtrPedestriansCount =
      locationStatsCopy.childRtlPedestriansCount

    let locationStatsMetaDataCopy = Object.assign({}, locationStats.metadata)

    locationStats.metadata.ltrLabel = locationStatsMetaDataCopy.rtlLabel
    locationStats.metadata.rtlLabel = locationStatsMetaDataCopy.ltrLabel

    locationStats.zones.map(zone => {
      let zoneCopy = Object.assign({}, zone)

      zone.ltrPedestriansCount = zoneCopy.rtlPedestriansCount
      zone.rtlPedestriansCount = zoneCopy.ltrPedestriansCount
    })
  }

  return locationStats
}

/**
 * Calculates the differnece between a referenceValue and a valueToCompare as a percentage
 * @param {number} referenceValue
 * @param {number} valueToCompare
 * @returns {number} difference from referenceValue to valueToCompare as a positive or negative percentage.
 */
export function getDifferenceAsPercentage(referenceValue, valueToCompare) {
  if (referenceValue === undefined || valueToCompare === undefined) {
    throw new Error('Missing argument to getDifferenceAsPercentage')
  }

  if (referenceValue === 0 || valueToCompare === 0) {
    throw new Error('Cannot calculate percentage when one argument is zero.')
  }

  const returnValue =
    ((valueToCompare - referenceValue) / Math.abs(referenceValue)) * 100

  return returnValue
}

/**
 * Calculates the difference between two values as a percentage
 * @param {number} referenceValue
 * @param {number} valueToCompare
 * @returns {number} the calculated percentage as a number or a string that signalizes a that the calculation cannot be performed with the given arguments.
 */
export function getDifferenceAsPercentageOrMissingLabel(
  referenceValue,
  valueToCompare
) {
  if (referenceValue === undefined || valueToCompare === undefined) {
    return getStatMissingLabel()
  }

  if (referenceValue === 0 || valueToCompare === 0) {
    return getStatMissingLabel()
  }

  return getDifferenceAsPercentage(referenceValue, valueToCompare)
}

/**
 * Calculates the percentage that a part is of a whole.
 * @param {number} part
 * @param {number} whole
 * @returns {number} - percentage
 */
export function calculatePercentage(part, whole) {
  if (whole === 0) {
    return 0
  }

  return (part / whole) * 100
}

/**
 * Calculates and returns the absolute difference between two values.
 * @param {number} referenceValue
 * @param {number} comparee
 * @returns the difference between refencevalue and comparedValue
 */
export function getAbsoluteDifference(comparee, referenceValue) {
  const difference = referenceValue - comparee
  return difference
}

/**
 * Returns a value that is used to show that a location-start is missing or can't be calculated.
 */
export function getStatMissingLabel() {
  return '--'
}

/**
 * Creates a percentage-label that respects the current locale (for delminiting, abbreviation..)
 * @param {number} percentage - The percentage that shall be displayed
 * @param {number} precision - The precision ( decimal place after which we want to round up )
 * @param {number} padTo - The length that the label shall be padded to - to ensure equal length no matter how long the label (determined by the percentage) actually is.
 * @returns {string} - A label to display a percentage.
 */
export function internationalizedPercentageOrBlank(
  percentage,
  precision = 0,
  padTo = null
) {
  if (typeof percentage === 'number') {
    const percentageLabelUnpadded =
      percentage > 0
        ? `+${I18n.toNumber(percentage, { precision: precision })}%`
        : `${I18n.toNumber(percentage, { precision: precision })}%`

    return padTo
      ? percentageLabelUnpadded.padEnd(padTo, ' ')
      : percentageLabelUnpadded
  } else {
    return getStatMissingLabel()
  }
}

export function abbreviatedNumberWithSignOrBlank(absoluteNumber) {
  if (typeof absoluteNumber === 'number') {
    {
      return absoluteNumber > 0
        ? `+${I18n.helpers.abbreviateNumber(absoluteNumber)}`
        : `${I18n.helpers.abbreviateNumber(absoluteNumber)}`
    }
  } else {
    return getStatMissingLabel()
  }
}

/**
 * Checks if two timeranges delimited by their start and end dates overlap.
 * @param {object} timerange1
 * @param {object} timerange2
 * @returns {boolean} wether or not the two timeranges overlap.
 */
export function areTimeRangesOverlapping(timerange1, timerange2) {
  if (
    timerange1.startDate >= timerange1.endDate ||
    timerange2.startDate >= timerange2.endDate
  ) {
    throw new Error(
      'Invalid argument - Cannot determine overlap in timerange - given dates do not describe a timerange'
    )
  }

  return (
    (timerange1.startDate >= timerange2.startDate &&
      timerange1.startDate <= timerange2.endDate) ||
    (timerange1.endDate >= timerange2.startDate &&
      timerange1.endDate <= timerange2.endDate) ||
    (timerange1.startDate <= timerange2.startDate &&
      timerange1.endDate >= timerange2.endDate)
  )
}

/**
 * Checks if two timeranges delimited by their start and end dates are fully contained in each other
 * @param {object} timerange1
 * @param {object} timerange2
 * @returns {boolean} wether or not timerange1 is fully contained in timerange2
 */
export function isTimerangeBetween(timerange1, timerange2) {
  if (
    timerange1.startDate >= timerange1.endDate ||
    timerange2.startDate >= timerange2.endDate
  ) {
    throw new Error(
      'Invalid argument - Cannot determine entailment in timeranges - given dates do not describe a timerange'
    )
  }

  return (
    timerange1.startDate >= timerange2.startDate &&
    timerange1.endDate <= timerange2.endDate
  )
}

/**
 * Checks if an API-response contains measurements for a given timerange delimited by startDate and endDate.
 * @param {locationDetailApiResponse} locationDetailApiResponse - Response from the hystreet-location-detail API
 * @param {Date} startDate - Start of the timerange
 * @param {Date} endDate - End of the timerange
 */
export function isDataPresentForTimeRange(
  locationDetailApiResponse,
  startDate,
  endDate
) {
  const timerangesOverlap = areTimeRangesOverlapping(
    { startDate: dayjs(startDate), endDate: dayjs(endDate) },
    {
      startDate: dayjs(
        locationDetailApiResponse?.metadata?.earliestMeasurementAt
      ),
      endDate: dayjs(locationDetailApiResponse?.metadata?.latestMeasurementAt)
    }
  )

  return timerangesOverlap
}

/**
 * Checks if an array of measurements contains measurements for 2 or more years
 * @param {locationDetailApiResponse.measurements} - Array of measurements from the hystreet-location-detail API
 * @returns {boolean} true - if the measurements have timestamps with 2 or more different years, false otherwise.
 */
export function measurementPeriodSpansAcrossYearBreak(measurements) {
  const firstMeasurement = measurements[0]
  const lastMeasurement = measurements[measurements.length - 1]

  const yearDifference =
    dayjs(lastMeasurement.timestamp).year() -
    dayjs(firstMeasurement.timestamp).year()

  return yearDifference !== 0
}
