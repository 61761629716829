import React from 'react'
import PropTypes from 'prop-types'

import I18n from '../i18n'
import Button from '../components/Button'
import Form from '../components/forms/Form'
import Input from '../components/forms/Input'
import SimpleSelect from '../components/forms/SimpleSelect'

function RegisterForm({ values = {}, errors = {}, hasSubscription }) {
  return (
    <Form as="user" action="/signup" method="put">
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.first_name')}
          name="first_name"
          defaultValue={values.firstName}
          errors={errors.firstName}
          reuquired
        />
      </Form.Field>
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.last_name')}
          name="last_name"
          defaultValue={values.lastName}
          errors={errors.lastName}
          required
        />
      </Form.Field>
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.email')}
          name="email"
          defaultValue={values.email}
          errors={errors.email}
          required
        />
      </Form.Field>
      <Form.Field half />
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.company_name')}
          name="company_name"
          defaultValue={values.companyName}
          errors={errors.companyName}
          required={hasSubscription}
        />
      </Form.Field>
      <Form.Field half>
        <SimpleSelect
          label={I18n.t('profile.fields.company_type')}
          name="company_type"
          options={Object.entries(
            I18n.t('company_types')
          ).map(([value, label]) => ({ value, label }))}
          defaultValue={values.companyType}
          errors={errors.companyType}
          required={hasSubscription}
        />
      </Form.Field>
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.change_password')}
          name="password"
          type="password"
          defaultValue=""
          errors={errors.password}
        />
      </Form.Field>
      <Form.Field half>
        <Input
          label={I18n.t('profile.fields.password_confirmation')}
          name="password_confirmation"
          type="password"
          defaultValue=""
          errors={errors.passwordConfirmation}
        />
      </Form.Field>
      <Form.Field>
        <Input
          label={I18n.t('profile.fields.current_password')}
          name="current_password"
          type="password"
          defaultValue=""
          errors={errors.currentPassword}
          required
        />
      </Form.Field>
      <Form.Actions>
        <Button type="submit">{I18n.t('profile.update_account')}</Button>
      </Form.Actions>
    </Form>
  )
}

RegisterForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  hasSubscription: PropTypes.bool
}

export default RegisterForm
