import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'

import Appendix from '../components/Appendix'
import FooterRegister from '../sections/FooterRegister'
import Section from '../components/Section'
import Spacer from '../components/Spacer'

import Testimonials from '../sections/Testimonials'
import UserInterests from '../sections/UserInterests'

function Community({ locationsCount }) {
  return (
    <div>
      <Helmet>
        <title>{I18n.t('pages.community.title')}</title>
      </Helmet>
      <Testimonials />
      <UserInterests />
      <ContactSection mid>
        <ContactSectionWrapper>
          <Appendix
            alignRight
            title={<Trans id="pages.community.appendix.title" />}
            content={<Trans id="pages.community.appendix.content" copy />}
            mail={I18n.t('pages.community.appendix.mail')}
          />
        </ContactSectionWrapper>
      </ContactSection>
      <FooterRegister locationsCount={locationsCount} />
      <Spacer mid />
    </div>
  )
}

Community.propTypes = {
  locationsCount: PropTypes.number.isRequired
}

const ContactSection = styled(Section)`
  padding: 6rem 0;

  ${p => p.theme.breakpoints.tablet} {
    display: none;
  }
`

const ContactSectionWrapper = styled(Section.Wrapper)`
  align-items: flex-end;

  > div {
    max-width: 28rem;
  }
`

export default Community
