import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import I18n, { Trans } from '../i18n'

import Section from '../components/Section'
import LegalPageContent from '../sections/LegalPageContent'

function Agb() {
  const defaultTabIndex = useMemo(() => {
    if (typeof window === 'undefined') return null
    return window?.location?.hash === '#commercial' ? 1 : 0
  }, [typeof window])

  return (
    <React.Fragment>
      <Helmet>
        <title>{I18n.t('pages.agb.title')}</title>
      </Helmet>
      <Section mid>
        <Section.Wrapper css={{ maxWidth: '46rem', paddingTop: '4rem' }}>
          {defaultTabIndex != null && (
            <Tabs defaultIndex={defaultTabIndex} onSelect={index => window.location.hash = index === 1 ? '#commercial' : '#private'}>
              <StyledTabList>
                <StyledTab>{sT('free.tab')}</StyledTab>
                <StyledTab>{sT('commercial.tab')}</StyledTab>
              </StyledTabList>

              <TabPanel>
                <LegalPageContent
                  printLink={`/agb_free_${I18n.currentLocale()}.pdf`}
                  headline={sT('free.headline')}
                >
                  <Trans id={'pages.agb.free.content'} copy />
                </LegalPageContent>
              </TabPanel>
              <TabPanel>
                <LegalPageContent
                  printLink={`/agb_commercial_${I18n.currentLocale()}.pdf`}
                  headline={sT('commercial.headline')}
                >
                  <Trans id={'pages.agb.commercial.content'} copy />
                </LegalPageContent>
              </TabPanel>
            </Tabs>
          )}
        </Section.Wrapper>
      </Section>
    </React.Fragment>
  )
}

const sT = key => I18n.t(`pages.agb.${key}`)

const StyledTabList = styled(TabList)`
  display: inline-flex;
  border: 3px solid ${p => p.theme.chroma.base.css()};
`
StyledTabList.tabsRole = 'TabList'

const StyledTab = styled(Tab)`
  background-color: ${p =>
    p.selected ? p.theme.chroma.base.css() : p.theme.chroma.lightGrey.css()};
  color: ${p =>
    p.selected ? p.theme.chroma.lighterGrey.css() : p.theme.chroma.base.css()};
  font-weight: ${p => (p.selected ? 'bold' : 'normal')};
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  cursor: pointer;
`
StyledTab.tabsRole = 'Tab' // needed for react-tabs

export default Agb
