import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'

import useA11yFocusring from './lib/use-a11y-focusring'
import Layout from './components/Layout'
import ErrorFallback from './components/ErrorFallback'

import { UserContext } from './lib/use-user'

function App({ PageComponent, pageProps }) {
  useA11yFocusring()

  return (
    <Layout {...pageProps}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => (window.location.href = pageProps.originalUrl)}
      >
        <UserContext.Provider value={pageProps.currentUser}>
          <PageComponent {...pageProps} />
        </UserContext.Provider>
      </ErrorBoundary>
    </Layout>
  )
}

App.propTypes = {
  PageComponent: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
}

export default App
