import PropTypes from 'prop-types'
import React from 'react'
import {
  calculatePercentage,
  getAbsoluteDifference,
  getDifferenceAsPercentageOrMissingLabel
} from '../../../lib/calculate-location-statistics'
import {
  statsObjectPropTypes,
  WalkingDirectionComparisonStatsCardItem,
  WalkingDirectionStatsCardItem
} from './LocationStatsCard'

export function WalkingDirectionCard({ statsObject, comparedTimeranges }) {
  return (
    <>
      <WalkingDirectionStatsCardItem
        walkingDirectionReading={{
          walkingDirection: statsObject?.metadata.rtlLabel,
          numberOfPedestriansWalkingInDirection:
            statsObject.rtlPedestriansCount,
          percentageOfPedestriansWalkingInDirection: calculatePercentage(
            statsObject.rtlPedestriansCount,
            statsObject.pedestriansCount
          )
        }}
        walkingDirectionReadingComplement={{
          walkingDirection: statsObject?.metadata.ltrLabel,
          numberOfPedestriansWalkingInDirection:
            statsObject.ltrPedestriansCount,
          percentageOfPedestriansWalkingInDirection: calculatePercentage(
            statsObject.ltrPedestriansCount,
            statsObject.pedestriansCount
          )
        }}
        displayNoDataPresentPlaceholder={
          statsObject.noDataPresentForAskedTimerange
        }
        displayDataIncompleteNotice={
          statsObject.dataIncompleteForAskedTimerange
        }
      ></WalkingDirectionStatsCardItem>

      {comparedTimeranges !== undefined &&
        comparedTimeranges.length > 0 &&
        comparedTimeranges.map(comparedTimerange => {
          return (
            <WalkingDirectionComparisonStatsCardItem
              key={statsObject.rtlPedestriansCount}
              timestamp={comparedTimerange.timerangeLabel}
              walkingDirectionReading1={{
                numberOfPedestriansWalkingInDirection:
                  comparedTimerange.statsObject.rtlPedestriansCount,
                pedestrianCountDifferenceInAbsoluteNumbers: getAbsoluteDifference(
                  comparedTimerange.statsObject.rtlPedestriansCount,
                  statsObject.rtlPedestriansCount
                ),
                pedestrianCountDifferenceInPercentages: getDifferenceAsPercentageOrMissingLabel(
                  comparedTimerange.statsObject.rtlPedestriansCount,
                  statsObject.rtlPedestriansCount
                )
              }}
              walkingDirectionReading2={{
                numberOfPedestriansWalkingInDirection:
                  comparedTimerange.statsObject.ltrPedestriansCount,
                pedestrianCountDifferenceInAbsoluteNumbers: getAbsoluteDifference(
                  comparedTimerange.statsObject.ltrPedestriansCount,
                  statsObject.ltrPedestriansCount
                ),
                pedestrianCountDifferenceInPercentages: getDifferenceAsPercentageOrMissingLabel(
                  comparedTimerange.statsObject.ltrPedestriansCount,
                  statsObject.ltrPedestriansCount
                )
              }}
              displayNoDataPresentPlaceholder={
                comparedTimerange.statsObject.noDataPresentForAskedTimerange
              }
              displayDataIncompleteNotice={
                comparedTimerange.statsObject.dataIncompleteForAskedTimerange
              }
            ></WalkingDirectionComparisonStatsCardItem>
          )
        })}
    </>
  )
}

WalkingDirectionCard.propTypes = {
  statsObject: statsObjectPropTypes,
  comparedTimeranges: PropTypes.arrayOf(
    PropTypes.shape({
      statsObject: statsObjectPropTypes,
      timerangeLabel: PropTypes.string
    })
  )
}
