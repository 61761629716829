import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function LocationLabelList({ locations, renderItem }) {
  return (
    <List>
      {locations.map(location => (
        <ListItem key={location.id}>{renderItem(location)}</ListItem>
      ))}
    </List>
  )
}

LocationLabelList.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ),
  renderItem: PropTypes.func.isRequired
}

const List = styled.ul`
  background-color: ${p => p.theme.chroma.white.css()};
`

const ListItem = styled.li`
  background-color: ${p => p.theme.chroma.nearlyWhite.css()};
  height: 100%;
  display: flex;
  margin-bottom: 0.625rem;
`

export default LocationLabelList
