import React from 'react'
import styled from '@emotion/styled'
import Mail from '../sections/assets/mail.svg'
import Phone from '../sections/assets/phone.svg'

function ContactStatic() {
  return (
    <StaticWrapper>
      <FlexiWrapper>
        <Mail />
        <TextEntry href="mailto:info@hystreet.com">info@hystreet.com</TextEntry>
      </FlexiWrapper>
      <FlexiWrapper>
        <Phone />
        <TextEntry href="tel:+4922177204251">+49 221 77 204 251</TextEntry>
      </FlexiWrapper>
    </StaticWrapper>
  )
}
const StaticWrapper = styled.div`
  z-index: 999;
  position: fixed;
  right: -15rem;
  top: 50%;
`

const FlexiWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.chroma.base.css()};
  margin-bottom: 2px;
  padding: 1.5rem;
  -webkit-box-shadow: 0px 1px 9px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 9px 3px rgba(0, 0, 0, 0.2);
  width: 20rem;
  color: ${p => p.theme.chroma.white.css()};
  :hover {
    transition: transform 0.5s ease-in-out;
    transform: translateX(-15rem);
  }
  @media (max-width: 960px) {
    visibility: hidden;
  }
`
const TextEntry = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 1.5rem;
  margin-left: 1rem;
`

export default ContactStatic
