import React from 'react'
import styled from '@emotion/styled'

import I18n from '../../i18n'

import {
  getDifferenceAsPercentageOrMissingLabel,
  internationalizedPercentageOrBlank
} from '../../lib/calculate-location-statistics'

import {
  PedestrianCountDifferenceInPercentagesLabel,
  TimestampLabel,
  TimestampRow
} from '../LocationDetail/LocationStatsCard/LocationStatsCardItems/LocationStatsCardItemBase'
import { indexAPI } from '../../lib/api/index/index-api'
import { useQueryNationalIndex } from '../../lib/api/index/use-index-api'

function IndexChangeReading() {
  const {
    data: lastMonthData,
    status: lastMonthQueryStatus
  } = useQueryNationalIndex(indexAPI.getNationalIndexTimerangePresets().lastMonth)
  const {
    data: twoMonthsAgoData,
    status: twoMonthsAgoQueryStatus
  } = useQueryNationalIndex(indexAPI.getNationalIndexTimerangePresets().twoMonthsAgo)

  if (
    lastMonthQueryStatus !== 'success' ||
    !lastMonthData ||
    twoMonthsAgoQueryStatus !== 'success' ||
    !twoMonthsAgoData
  ) {
    return null
  }

  const indexChangeInPercentages = getDifferenceAsPercentageOrMissingLabel(
    twoMonthsAgoData[0].indexValue,
    lastMonthData[0].indexValue
  )

  return (
    <IndexChangeCard>
      <TimestampRow css={{ marginRight: '0.5rem' }}>
        <TimestampLabel css={{ marginTop: 0 }}>
          {I18n.t('pages.hystreet_index.index_change_label')} (
          {twoMonthsAgoData[0].tooltipTimestamp})
        </TimestampLabel>
      </TimestampRow>
      <IndexChangeInPercentagesLabel percentage={indexChangeInPercentages}>
        {internationalizedPercentageOrBlank(indexChangeInPercentages, 1, 8)}
      </IndexChangeInPercentagesLabel>
    </IndexChangeCard>
  )
}

const IndexChangeCard = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 2.75rem;
  ${props => props.theme.breakpoints.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const IndexChangeInPercentagesLabel = styled(
  PedestrianCountDifferenceInPercentagesLabel
)``

export default IndexChangeReading
