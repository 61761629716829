import PropTypes from 'prop-types'
import React from 'react'
import {
  getAbsoluteDifference,
  getDifferenceAsPercentageOrMissingLabel
} from '../../../lib/calculate-location-statistics'
import { statsObjectPropTypes } from './LocationStatsCard'
import {
  PedestrianCountComparisonStatsCardItem,
  PedestrianCountStatsCardItem
} from './LocationStatsCardItems/LocationStatsCardItemBase'

export function PedestrianCountCard({
  timerangeLabel,
  statsObject,
  comparedTimeranges
}) {
  if (!comparedTimeranges) {
    return null
  }

  return (
    <>
      <PedestrianCountStatsCardItem
        pedestrianCount={statsObject?.pedestriansCount}
        timeStampOrTimeRange={timerangeLabel}
        displayNoDataPresentPlaceholder={
          statsObject.noDataPresentForAskedTimerange
        }
        displayDataIncompleteNotice={
          statsObject.dataIncompleteForAskedTimerange
        }
      ></PedestrianCountStatsCardItem>

      {comparedTimeranges !== undefined &&
        comparedTimeranges.length > 0 &&
        comparedTimeranges.map((comparedTimerange, index) => {
          return (
            <PedestrianCountComparisonStatsCardItem
              key={index}
              pedestrianCount={comparedTimerange?.statsObject?.pedestriansCount}
              timestamp={comparedTimerange?.timerangeLabel}
              pedestrianCountDifferenceInAbsoluteNumbers={getAbsoluteDifference(
                comparedTimerange?.statsObject?.pedestriansCount,
                statsObject?.pedestriansCount
              )}
              pedestrianCountDifferenceInPercentages={getDifferenceAsPercentageOrMissingLabel(
                comparedTimerange?.statsObject?.pedestriansCount,
                statsObject?.pedestriansCount
              )}
              incidents={statsObject?.incidents}
              measurementsStartedAt={
                statsObject?.metadata?.earliestMeasurementAt
              }
              displayNoDataPresentPlaceholder={
                comparedTimerange.statsObject.noDataPresentForAskedTimerange
              }
              displayDataIncompleteNotice={
                comparedTimerange?.statsObject?.dataIncompleteForAskedTimerange
              }
            ></PedestrianCountComparisonStatsCardItem>
          )
        })}
    </>
  )
}

PedestrianCountCard.propTypes = {
  timerangeLabel: PropTypes.string,
  statsObject: statsObjectPropTypes,
  comparedTimeranges: PropTypes.arrayOf(
    PropTypes.shape({
      statsObject: statsObjectPropTypes,
      timerangeLabel: PropTypes.string
    })
  )
}
