import React from 'react'
import styled from '@emotion/styled'

import I18n from '../../i18n'

import { useSearch } from '../../lib/use-search'

function Countries() {
  const { filteredLocations } = useSearch()
  const countries = filteredLocations.map(([country, _cities]) => (
    <Country key={country}>
      <CountryLink href={`#${country}`}>
        {I18n.t('country')[country]}
      </CountryLink>
    </Country>
  ))

  return (
    <CountryBar>
      <Label>{I18n.t('country.label')}</Label>
      <List>{countries}</List>
    </CountryBar>
  )
}

const Country = styled.li`
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 0.5rem;
`
const CountryLink = styled.a`
  margin-right: 10px;
`

const List = styled.ul`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`

const CountryBar = styled.div`
  margin: 1rem;
  display: flex;
`

const Label = styled.div`
  font-size: 0.75rem;
  margin-right: 10px;
`

export default Countries
