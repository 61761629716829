import React from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import I18n, { Trans } from '../i18n'
import Section from '../components/Section'
import Spacer from '../components/Spacer'

function UserAnnouncementConsentError() {
  return (
    <Section>
      <CustomWrapper>
        <Helmet>
          <title>{I18n.t('announcement.title')}</title>
        </Helmet>
        <Trans id="announcement.error" copy />
      </CustomWrapper>
      <Spacer light />
    </Section>
  )
}

const CustomWrapper = styled(Section.Wrapper)`
  padding-top: 2rem;
  max-width: 44rem;
  line-height: 1.4;

  ${p => p.theme.breakpoints.tabletUp} {
    font-size: 1.125rem;
  }
`

export default UserAnnouncementConsentError
