import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import View from 'ol/View'
import OSM from 'ol/source/OSM'
import TileLayer from 'ol/layer/Tile'
import { defaults as defaultInteractions } from 'ol/interaction'
import FullScreen from 'ol/control/FullScreen'
import { fromLonLat } from 'ol/proj'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Cluster from 'ol/source/Cluster'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style'
import { Point } from 'ol/geom'
import { boundingExtent, getCenter } from 'ol/extent'

import theme from '../theme'

import useMapZoom from '../lib/use-map-zoom'

import Close from '../components/assets/images/close.png'
import Fullscreen from '../components/assets/images/fullscreen.png'
import Minus from '../components/assets/images/minus.png'
import Plus from '../components/assets/images/plus.png'

import germanStates from './data/bundeslaender.geo.json'
import countryBorders from './data/countries.geo.json'

const OpenLayersClient = global.OpenLayersClient || {}
const isSSR = !OpenLayersClient.Map
const MapContext = React.createContext()

const Map = ({ view, children, ...rest }) => {
  const { mapZoom } = useMapZoom()
  const mountEl = useRef()
  const isInitialMount = useRef(true)

  const map = useRef(
    !isSSR &&
      new OpenLayersClient.Map({
        interactions: defaultInteractions(),
        controls: OpenLayersClient.defaultControls(),
        layers: [new TileLayer({ source: new OSM(), name: 'tileLayer' })],
        view: new View({
          ...view,
          center: fromLonLat(view.center)
        })
      })
  )

  useEffect(() => {
    const mapView = map.current.getView()
    mapView.setCenter(fromLonLat(view.center))
    mapView.setRotation(view.rotation)
    mapView.setZoom(view.zoom)
  }, [view])

  useEffect(() => {
    map.current.on('pointermove', e => {
      if (e.dragging) {
        return
      }

      const pixel = map.current.getEventPixel(e.originalEvent)
      const hit = map.current.hasFeatureAtPixel(pixel)
      map.current.getTargetElement().style.cursor = hit ? 'pointer' : ''
    })
  }, [map])

  // NOTE: useEffect code here only to be run on updates not on initial render
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const layers = map.current.getLayers()
      layers.forEach(layer => {
        if (layer.get('name') === 'locationLayer') {
          const center = getCenter(
            layer
              .getSource()
              .getFeatureById(mapZoom)
              .getGeometry()
              .getExtent()
          )
          map.current.getView().setCenter(center)
          map.current.getView().setZoom(16)
        }
      })
    }
  }, [mapZoom])

  useEffect(() => {
    map.current.setTarget(mountEl.current)
  }, [])

  return (
    <MapContext.Provider value={map.current}>
      <div ref={mountEl} {...rest} />
      {children}
    </MapContext.Provider>
  )
}

Map.propTypes = {
  children: PropTypes.node,
  view: PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.number.isRequired,
    rotation: PropTypes.number
  })
}

const ClusterLayer = ({ data }) => {
  const map = useContext(MapContext)
  const layer = useRef()
  const features = new Array(data.length)

  for (let i = 0; i < data.length; i++) {
    const coordinates = [data[i].addressLongitude, data[i].addressLatitude]
    features[i] = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
      id: data[i].id,
      name: data[i].name
    })
  }

  const source = new VectorSource({
    features: features
  })

  const clusterSource = new Cluster({
    source: source,
    distance: 40
  })

  useEffect(() => {
    const newLayer = new VectorLayer({
      source: clusterSource,
      maxZoom: 14.5,
      name: 'clusterLayer',
      style: function(feature) {
        const size = feature.get('features').length
        const radius = Math.sqrt((size * 100) / Math.PI)
        const style = new Style({
          image: new CircleStyle({
            radius: Math.max(10, Math.round(radius)),
            stroke: new Stroke({
              color: theme.chroma.base.css()
            }),
            fill: new Fill({
              color: theme.chroma.lightBlue.css()
            })
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: theme.chroma.white.css()
            }),
            font: 'bold 0.7rem Lato, sans-serif'
          })
        })
        return style
      }
    })

    if (layer.current) {
      map.removeLayer(layer.current)
    }
    map.addLayer(newLayer)
    layer.current = newLayer
  }, [clusterSource, map])

  useEffect(() => {
    map.on('click', e => {
      layer.current.getFeatures(e.pixel).then(clickedFeatures => {
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get('features')
          if (features.length > 1) {
            const extent = boundingExtent(
              features.map(feature => feature.getGeometry().getCoordinates())
            )
            map
              .getView()
              .fit(extent, { duration: 500, padding: [50, 50, 50, 50] })
          } else {
            const center = getCenter(features[0].getGeometry().getExtent())
            map.getView().setCenter(center)
            map.getView().setZoom(17)
          }
        }
      })
    })
  }, [map])

  return null
}

ClusterLayer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

const LocationLayer = ({ data }) => {
  const map = useContext(MapContext)
  const layer = useRef()
  const features = new Array(data.length)

  for (let i = 0; i < data.length; i++) {
    const geometry = data[i].geojson
    const id = data[i].id
    features[i] = {
      type: 'Feature',
      geometry: geometry,
      id: id,
      properties: { id: id }
    }
  }

  const source = new VectorSource({
    features: new GeoJSON().readFeatures(
      {
        type: 'FeatureCollection',
        features: features
      },
      { featureProjection: 'EPSG:3857' }
    )
  })

  useEffect(() => {
    const newLayer = new VectorLayer({
      source: source,
      minZoom: 14.5,
      name: 'locationLayer',
      style: new Style({
        stroke: new Stroke({
          color: theme.chroma.lightBlue.css(),
          width: 2
        }),
        fill: new Fill({
          color: theme.chroma.chartAreas[0].fill.alpha(0.3).css()
        })
      })
    })

    if (layer.current) {
      map.removeLayer(layer.current)
    }
    map.addLayer(newLayer)
    layer.current = newLayer
  }, [source, map])

  useEffect(() => {
    map.on('click', e => {
      map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
        if (layer.get('name') === 'locationLayer') {
          window.location.href = `/locations/${feature.get('id')}`
        }
      })
    })
  }, [map])
  return null
}

LocationLayer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

const StatesLayer = () => {
  const map = useContext(MapContext)
  const layer = useRef()

  const { features } = germanStates

  const source = new VectorSource({
    features: new GeoJSON().readFeatures(
      {
        type: 'FeatureCollection',
        features: features
      },
      { featureProjection: 'EPSG:3857' }
    )
  })

  useEffect(() => {
    const newLayer = new VectorLayer({
      name: 'statesLayer',
      maxZoom: 14.5,
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: theme.chroma.map.stateborder.css(),
          width: 2
        })
      })
    })

    if (layer.current) {
      map.removeLayer(layer.current)
    }
    map.addLayer(newLayer)
    layer.current = newLayer
  }, [map, source])

  return null
}

const CountriesLayer = () => {
  const map = useContext(MapContext)
  const layer = useRef()

  const { features } = countryBorders

  const source = new VectorSource({
    features: new GeoJSON().readFeatures(
      {
        type: 'FeatureCollection',
        features: features
      },
      { featureProjection: 'EPSG:3857' }
    )
  })

  useEffect(() => {
    const newLayer = new VectorLayer({
      name: 'countriesLayer',
      maxZoom: 14.5,
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: theme.chroma.map.countryborder.css(),
          width: 3
        })
      })
    })

    if (layer.current) {
      map.removeLayer(layer.current)
    }
    map.addLayer(newLayer)
    layer.current = newLayer
  }, [map, source])

  return null
}

const FullScreenControl = () => {
  const map = useContext(MapContext)
  useEffect(() => {
    const fullScreenControl = new FullScreen({})

    map.controls.push(fullScreenControl)

    return () => map.controls.remove(fullScreenControl)
  }, [map])

  return null
}

const LocationsMap = ({ mapLocations }) => {
  const DEFAULT_ROTATION = 0
  const GERMANY_CENTER_LON = 10.385672
  const GERMANY_CENTER_LAT = 51.07501
  const GERMANY_CENTER_ZOOM = 6.25
  const center = [GERMANY_CENTER_LON, GERMANY_CENTER_LAT]
  const zoom = GERMANY_CENTER_ZOOM
  const rotation = DEFAULT_ROTATION

  return (
    <MapStyleOverrides>
      <Map
        view={{ center, zoom, rotation }}
        css={{ width: '100%', height: '100%' }}
      >
        <StatesLayer />
        <CountriesLayer />
        <ClusterLayer data={mapLocations} />
        <LocationLayer data={mapLocations} />
        <FullScreenControl />
      </Map>
    </MapStyleOverrides>
  )
}

LocationsMap.propTypes = {
  mapLocations: PropTypes.arrayOf(PropTypes.object).isRequired
}

const MapStyleOverrides = styled.div`
  width: 65%;
  ${p => p.theme.breakpoints.desktop} {
    display: none;
  }
  .ol-attribution {
    font-size: 0.75rem;
    color: ${props => props.theme.chroma.pencil.css()};
    border-radius: 0;
  }

  .ol-zoom {
    top: unset;
    right: 0.75rem;
    bottom: 1.5rem;
    left: unset;
  }

  .ol-full-screen {
    right: 0.75rem;
    top: 0.75rem;
  }

  .ol-zoom.ol-control,
  .ol-full-screen.ol-control {
    border-radius: 0;
    background-color: transparent;
  }

  .ol-zoom.ol-control:hover,
  .ol-full-screen.ol-control:hover {
    background-color: transparent;
  }

  .ol-zoom.ol-control button,
  .ol-full-screen.ol-control button {
    position: relative;
    font-size: 1.75rem;
    margin: 0;
    border-radius: 0;
    color: transparent;
    background-color: ${props => props.theme.chroma.white.alpha(0.8).css()};
    border: 1px solid transparent;
  }

  .ol-zoom.ol-control button:first-of-type {
    border-bottom-color: #c4c4c4;
  }

  .ol-zoom.ol-control button:hover,
  .ol-full-screen.ol-control button:hover {
    background-color: ${props => props.theme.chroma.white.css()};
  }

  .ol-zoom-out::after,
  .ol-zoom-in::after,
  .ol-full-screen-false::after,
  .ol-full-screen-true::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19px;
    height: 19px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }
  .ol-zoom-out::after {
    background-image: url(${Minus});
  }
  .ol-zoom-in::after {
    background-image: url(${Plus});
  }
  .ol-full-screen-false::after {
    width: 24px;
    height: 24px;
    background-image: url(${Fullscreen});
  }
  .ol-full-screen-true::after {
    width: 16px;
    height: 16px;
    background-image: url(${Close});
  }
`

export default LocationsMap
