import React from 'react'
import styled from '@emotion/styled'

import Section from '../components/Section'
import image from './assets/partner-data.jpg'
import { Trans } from '../i18n'

function PartnerHeadSection() {
  return (
    <CustomSection row>
      <Section.Skew mid direction="down" position="top" inset />
      <HeaderWrapper base={50} image={image} />
      <Section.Half base={50}>
        <TextWrapper>
          <Heading>
            <Trans id="partner_page.data.heading" />
          </Heading>
          <Trans id="partner_page.data.content" />
        </TextWrapper>
      </Section.Half>
    </CustomSection>
  )
}
const HeaderWrapper = styled(Section.Half)`
  min-height: 23rem;

  @media (max-width: 500px) {
    min-height: 10rem;
  }

  @media (max-width: 960px) {
    min-height: 7rem;
  }
`
const CustomSection = styled(Section)`
  @media (max-width: 960px) {
    svg {
      visibility: hidden !important;
    }
    flex-direction: column;
  }
`
const TextWrapper = styled.div`
  @media (max-width: 960px) {
    margin: 5rem 2rem 6rem 2rem;
  }
  margin: 12rem 4rem 6rem 4rem;
`

const Heading = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`

export default PartnerHeadSection
