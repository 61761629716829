import PropTypes from 'prop-types'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { indexAPI } from './api/index/index-api'

const context = createContext({})

export function IndexProvider({
  id,
  originalUrl,
  indexValues,
  maxTimerange,
  enabledTimeranges,
  ...rest
}) {
  const initialPreset = useMemo(() => {
    const params = new URL(originalUrl).searchParams

    function getParam(paramName) {
      const param = params.get(paramName)
      return param ? param : undefined
    }

    function getPresetByName(presetName, presets) {
      return presets[presetName]
    }

    const presets = indexAPI.getNationalIndexTimerangePresets(maxTimerange)
    const presetNameFromUrl = getParam('preset') || presets.sixMonths.name
    const activePreset =
      getPresetByName(presetNameFromUrl, presets) || presets.sixMonths

    const name = activePreset.name
    const from = activePreset.from
    const to = activePreset.to
    const resolution = activePreset.resolution

    return {
      name,
      from,
      to,
      resolution
    }
  }, [originalUrl, maxTimerange])

  const [preset, setPreset] = useState(initialPreset)

  useEffect(() => {
    const urlParams = new URLSearchParams(global.location.search)
    urlParams.set('preset', preset.name)

    const searchParamsString = urlParams.toString()
      ? '?' + urlParams.toString()
      : window.location.pathname

    history.replaceState(null, null, searchParamsString)
  }, [preset])

  return (
    <context.Provider
      value={{
        id,
        indexValues,
        preset,
        setPreset,
        maxTimerange,
        enabledTimeranges
      }}
      {...rest}
    />
  )
}

IndexProvider.propTypes = {
  id: PropTypes.number.isRequired,
  originalUrl: PropTypes.string.isRequired,
  indexValues: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      indexValue: PropTypes.number.isRequired
    }).isRequired
  ),
  maxTimerange: PropTypes.object.isRequired,
  enabledTimeranges: PropTypes.shape({
    lastMonth: PropTypes.bool.isRequired,
    twoMonths: PropTypes.bool.isRequired,
    sixMonths: PropTypes.bool.isRequired,
    oneYear: PropTypes.bool.isRequired,
    twoYears: PropTypes.bool.isRequired,
    max: PropTypes.bool.isRequired
  }).isRequired
}

export default function useHystreetIndex() {
  return useContext(context)
}
