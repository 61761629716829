import React from 'react'
import styled from '@emotion/styled'
import { Trans } from '../i18n'
import Section from '../components/Section'
import Headline from '../components/Headline'
import logoAg from './assets/logo_ag.jpg'
import logoAcx from './assets/logo-ac+x.png'

function Origin() {
  return (
    <CustomSection row>
      <Section.Half css={{ marginBottom: '1.5rem' }}>
        <Section.Wrapper>
          <Headline css={{ marginBottom: '1rem' }} breakout>
            <Trans id="pages.about.origin.headline" />
          </Headline>
          <div css={{ lineHeight: '1.7' }}>
            <Trans id="pages.about.origin.text" />
          </div>
        </Section.Wrapper>
      </Section.Half>
      <Section.Half>
        <Section.Wrapper>
          <a href="https://www.aachener-grund.de/" target="_blank" rel="noopener noreferrer">
            <Logo src={logoAg} />
          </a>
          <a href="https://www.acx-invest.de/" target="_blank" rel="noopener noreferrer">
            <Logo src={logoAcx} />
          </a>
        </Section.Wrapper>
      </Section.Half>
    </CustomSection>
  )
}

const CustomSection = styled(Section)`
  padding-top: 3.125rem;
  padding-bottom: 4rem;

  ${p => p.theme.breakpoints.tabletUp} {
    padding-top: 10rem;
    padding-bottom: 8rem;
  }

  ${p => p.theme.breakpoints.desktopUp} {
    padding-top: 14rem;
    padding-bottom: 10rem;
  }
`

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 175px;

  &:first-of-type {
    margin-bottom: 1rem;
  }

  ${p => p.theme.breakpoints.tabletUp} {
    max-width: 275px;

    &:first-of-type {
      margin-bottom: 2rem;
    }
  }
`

export default Origin
