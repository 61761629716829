import React from 'react'
import PropTypes from 'prop-types'

import { TimeRange, Average, Pin } from '../Icon'

const ICON_TYPE_MAP = {
  timerange: TimeRange,
  average: Average,
  location: Pin,
  multiLocation: Pin
}

function CompareIcon({ type, ...rest }) {
  const IconCmp = ICON_TYPE_MAP[type]

  return <IconCmp {...rest} />
}

CompareIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICON_TYPE_MAP))
}

export default CompareIcon
