import React, { createContext, useContext, useState } from 'react'

export const context = createContext()

export default function useZoneDetails() {
  return useContext(context)
}

export function ZoneDetailsProvider(props) {
  const [showZoneDetails, setShowZoneDetails] = useState(false)

  return (
    <context.Provider
      value={{ showZoneDetails, setShowZoneDetails }}
      {...props}
    />
  )
}
