import React from 'react'
import styled from '@emotion/styled'
import I18n, { Trans } from '../i18n'
import Appendix from '../components/Appendix'
import Section from '../components/Section'

function AboutContact() {
  return (
    <DynamicSection mid>
      <Section.Wrapper css={{ paddingBottom: '5rem' }}>
        <Appendix
          title={<Trans id="appendix.title" />}
          content={<Trans id="appendix.content" copy />}
          mail={I18n.t('appendix.mail')}
          alignRight
        />
      </Section.Wrapper>
      <Section.Skew direction="up" position="top" mid />
    </DynamicSection>
  )
}

const DynamicSection = styled(Section)`
  overflow: visible;

  ${p => p.theme.breakpoints.phone} {
    display: none;
  }
`

export default AboutContact
