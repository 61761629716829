import { useState, useEffect } from 'react'

const useMediaQuery = query => {
  const [windowSizeMatchesQuery, setWindowSizeMatchesQuery] = useState(false)

  // We use syntax '@media (min-width: 232px)' for media queries in theme.js.
  // However, matchMedia works only without the @media prefix.
  const queryWithoutAtMedia = query.replace('@media ', '')

  useEffect(() => {
    const media = window.matchMedia(queryWithoutAtMedia)
    if (media.matches !== windowSizeMatchesQuery) {
      setWindowSizeMatchesQuery(media.matches)
    }
    const listener = () => setWindowSizeMatchesQuery(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [query, queryWithoutAtMedia, windowSizeMatchesQuery])

  return windowSizeMatchesQuery
}

export default useMediaQuery
